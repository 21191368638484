import type { Course, CourseEnrollment, Id, Institution, Section, Session, Student, User } from '@acadeum/types';

import { rtkApi } from './rtkApi';

interface FetchEnrollingGradeOutput extends Pick<CourseEnrollment, 'id' | 'type' | 'letterGrade' | 'numericalGrade' | 'gradeNotes'> {
  student: Pick<Student, 'id' | 'hiStudentId' | 'firstName' | 'middleName' | 'lastName' | 'email'> & {
    institution: Pick<Institution, 'id' | 'name' | 'vanityUrl'>
  };
  section: Pick<Section, 'id'> & {
    session: Pick<Session, 'id' | 'term' | 'name' | 'lastAddDate' | 'lastDropDate' | 'startDate' | 'endDate'> & {
      course: Pick<Course, 'id' | 'code' | 'title'> & {
        institution: Pick<Institution, 'id' | 'name' | 'vanityUrl'> & {
          gradingScale?: string;
          gradingScaleUrl?: string;
          gradingScaleNotes?: string;
        }
      }
    }
  };
  studentContact: Pick<User, 'firstName' | 'lastName' | 'email' | 'title' | 'phone' | 'phoneExt'>;
}

interface FetchTeachingGradeOutput extends Pick<CourseEnrollment, 'id' | 'type' | 'letterGrade' | 'numericalGrade' | 'gradeNotes'> {
  student: Pick<Student, 'id' | 'hiStudentId' | 'firstName' | 'middleName' | 'lastName' | 'email'> & {
    institution: Pick<Institution, 'id' | 'name' | 'vanityUrl'>
  };
  section: Pick<Section, 'id'> & {
    session: Pick<Session, 'id' | 'term' | 'name' | 'lastDropDate' | 'lastAddDate' | 'startDate' | 'endDate'> & {
      course: Pick<Course, 'id' | 'code' | 'title'> & {
        institution: Pick<Institution, 'id' | 'name' | 'vanityUrl'> & {
          gradingScale?: string;
          gradingScaleUrl?: string;
          gradingScaleNotes?: string;
        }
      }
    }
  };
}

interface EditGradeInput {
  id: Id;
  letterGrade: string;
  numericalGrade?: number;
  gradeNotes?: string;
}

const gradesApi = rtkApi
  .enhanceEndpoints({ addTagTypes: ['grades-EM', 'grades-TM'] })
  .injectEndpoints({
    endpoints: build => ({
      fetchEnrollingGrade: build.query<FetchEnrollingGradeOutput, Id>({
        query: (id) => ({
          url: `/grades-EM/${id}`,
          params: { includeStudentContact: true }
        }),
        providesTags: (result, error, arg) => [{ type: 'grades-EM', id: arg }]
      }),
      fetchTeachingGrade: build.query<FetchTeachingGradeOutput, Id>({
        query: (id) => `/grades-TM/${id}`,
        providesTags: (result, error, arg) => [{ type: 'grades-TM', id: arg }]
      }),
      editGrade: build.mutation<void, EditGradeInput>({
        query: ({ id, ...rest }) => ({
          url: `/grades/${id}`,
          body: rest,
          method: 'PUT'
        }),
        onQueryStarted: () => {
          Intercom('trackEvent', 'edit grades');
        },
        invalidatesTags: (result, error, arg) => [
          { type: 'grades-EM', id: arg.id },
          { type: 'grades-TM', id: arg.id }
        ]
      })
    })
  });

export const {
  useFetchEnrollingGradeQuery,
  useFetchTeachingGradeQuery,
  useEditGradeMutation
} = gradesApi;
