import React from 'react';
import PropTypes from 'prop-types';

import { FormSubmit as FormSubmit_ } from '@acadeum/core-ui';

import type { ButtonProps } from '../Button';
import { Button } from '../Button';

export type FormSubmitProps = ButtonProps & {
  action?: string;
  disabledWhenInvalid?: boolean;
  skipValidation?: boolean;
}

export const FormSubmit: React.FC<FormSubmitProps> = (props) => {
  return (
    <FormSubmit_
      component={Button}
      // eslint-disable-next-line
      {...props as any}
    />
  );
};

FormSubmit.propTypes = {
  action: PropTypes.string
};
