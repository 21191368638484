import type { FC } from 'react';
import React from 'react';

import { Alert, BackButton } from '@acadeum/ui';
import { useTranslate } from '@acadeum/translate';
import type { FetchPrimaryPaymentSourceOutput } from '@acadeum/api';

import { FINANCIAL_SCREEN, useFinancialNavigationContext } from '../../context';

import type { BankAccountInfoProps } from '../BankAccountInfo';
import { BankAccountInfo } from '../BankAccountInfo';

import styles from './ManageBankAccountScreen.module.scss';

export interface ManageBankAccountScreenProps extends Pick<BankAccountInfoProps, 'useUpdatePrimaryPaymentSourceMutation' | 'useCreatePrimaryPaymentSourceMutation'> {
  type: 'current' | 'new';
  primaryPaymentSource?: FetchPrimaryPaymentSourceOutput;
}

export const ManageBankAccountScreen: FC<ManageBankAccountScreenProps> = ({
  type,
  primaryPaymentSource,
  useCreatePrimaryPaymentSourceMutation,
  useUpdatePrimaryPaymentSourceMutation
}) => {
  const { changeView } = useFinancialNavigationContext();
  const t = useTranslate('shared-admin-ui.ManageBankAccountScreen');

  const goToDashboard = () => changeView({ screen: FINANCIAL_SCREEN.DASHBOARD });

  const primaryPayment = primaryPaymentSource?.[type];

  return (
    <section className={styles.ManageBankAccount}>
      <BackButton onClick={goToDashboard}>
        {t('financial')}
      </BackButton>
      <h2>
        {t('title')}
      </h2>
      {type === 'current' && primaryPaymentSource?.new && (
        <Alert className={styles.ManageBankAccount__warning} variant="warn">
          {t('notificationAboutDeleted')}
        </Alert>
      )}
      {primaryPayment && (
        <BankAccountInfo
          type={type}
          primaryPaymentSource={primaryPaymentSource}
          onAfterRequestDeleteBankAccount={goToDashboard}
          useUpdatePrimaryPaymentSourceMutation={useUpdatePrimaryPaymentSourceMutation}
          useCreatePrimaryPaymentSourceMutation={useCreatePrimaryPaymentSourceMutation}
        />
      )}
    </section>
  );
};
