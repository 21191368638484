import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const fetchFuturePaymentData = redux.action(
  () => (http) => http.get('/payments/data'),
  'futurePayments'
);

export default redux.reducer();
