import { useContext } from 'react';

import { LayoutContext } from './context';

export function useLayoutStyles(propsStyle, { alignItems, gap, justifyItems, cols, rowHeight }) {
  const style = { ...propsStyle, alignItems, gap, justifyItems };
  if (typeof cols === 'number') {
    style.gridTemplateColumns = `repeat(${cols}, minmax(0px, 1fr))`;
  }
  if (typeof rowHeight === 'string') {
    style.gridAutoRows = rowHeight;
  }
  return style;
}

export function useLayoutCell() {
  const layout = useContext(LayoutContext);
  if (!layout) {
    throw new Error('No Layout was provided. <Layout.Cell> components must be wrapped in a <Listbox/>');
  }
  return layout;
}
