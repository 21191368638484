import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const fetchConsortium = redux.action(
  (id) => http => http.get(`/consortia/${id}`),
  'consortium'
);

export const fetchConsortiumForEditing = redux.action(
  (id) => http => http.get(`/consortia/${id}`, { edit: '✓' }),
  'consortium'
);

export const createConsortium = redux.action(
  (values) => async http => http.post('/consortia', values)
);

export const updateConsortium = redux.action(
  (id, values) => async http => http.put(`/consortia/${id}`, values)
);

export const requestToJoinConsortium = redux.action(
  (id) => async http => http.post(`/consortia/${id}/join-requests`)
);

export default redux.reducer();
