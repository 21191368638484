// Pricing Model documentation:
// https://github.com/Acadeum/Tickets/blob/master/Pricing-Model.md

// This file is not supposed to be `import`ed anywhere outside of this repository.

import getLowestPriceAndPricingVariantForCostUsingPricingVariants from './getLowestPriceAndPricingVariantForCostUsingPricingVariants.js';
import getPricingVariantsForCourseSection from './getPricingVariantsForCourseSection.js';

export default function getLowestPriceAndPricingVariantForCourseSection(
  cost,
  pricingVariants,
  parameters
) {
  const {
    credits,
    level,
    teachingInstitutionId
  } = parameters;

  // Validate `cost` argument.
  if (typeof cost !== 'number') {
    throw new Error('`cost` is required');
  }

  // Validate `level`.
  if (!level) {
    throw new Error('`level` parameter is required');
  }

  // Validate `teachingInstitutionId`.
  if (!teachingInstitutionId) {
    throw new Error('`teachingInstitutionId` parameter is required');
  }

  // Validate `credits`.
  if (typeof credits !== 'number') {
    throw new Error('`credits` parameter is required');
  }

  const matchingPricingVariants = getPricingVariantsForCourseSection(pricingVariants, {
    level,
    teachingInstitutionId
  });

  if (matchingPricingVariants.length > 0) {
    return getLowestPriceAndPricingVariantForCostUsingPricingVariants(
      cost,
      matchingPricingVariants,
      { credits },
      { roundTo: 'hundredths' }
    );
  }

  return {};
}
