import React, { memo } from 'react';
import classNames from 'classnames';

import styles from './ActiveColor.module.scss';
import { Checkboard } from '../Checkboard';
import type { HEX } from '../../types';

export interface ActiveColorProps {
  hex: HEX;
  size?: string;
  borderRadius?: string;
}

export const ActiveColor = memo<ActiveColorProps>(({
  hex,
  size = '2rem',
  borderRadius = '.5rem'
}) => {
  return (
    <div
      className={classNames(styles.swatch)}
      style={{
        width: size,
        height: size
      }}
    >
      <Checkboard
        style={{ borderRadius }}
      />
      <div
        className={styles.active}
        style={{
          borderRadius,
          background: hex
        }}
      />
    </div>
  );
});
