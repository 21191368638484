import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import userRoleIds from 'common-lib/constants/userRoleIds.json';

import type { ActionsProps } from '@acadeum/ui';
import { Actions, Button, toast } from '@acadeum/ui';
import { userHasPermission } from '@acadeum/helpers';
import { useTranslate } from '@acadeum/translate';
import type { UserRole } from '@acadeum/types';
import { getAuthSelector } from '@acadeum/auth';
import type { UseDuplicateUserRoleMutation } from '@acadeum/api';

import { useNavigate } from '../../../../providers/useNavigate';
import { useSettingsRoutes } from '../../../../hooks/useSettingsRoutes';

interface UserRoleActionsProps {
  role?: UserRole;
  onDeleteRole: () => void;
  useDuplicateUserRoleMutation: UseDuplicateUserRoleMutation;
}

export const UserRoleActions = ({
  role,
  onDeleteRole,
  useDuplicateUserRoleMutation
}: UserRoleActionsProps) => {
  const t = useTranslate('shared-admin-ui.UserRoleDetailsPage');
  const router = useNavigate();
  const user = useSelector(getAuthSelector('user'));
  const { getUserRoleDetailsUrl } = useSettingsRoutes();

  const [duplicateUserRoleMutation] = useDuplicateUserRoleMutation();

  const duplicateRole = useCallback(() => toast.promise(async () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    const { id } = await duplicateUserRoleMutation({ id: role?.id! }).unwrap();
    void router.push(getUserRoleDetailsUrl(id));
  }), [role]);

  const actions = useMemo<ActionsProps['actions']>(() => {
    const actions: ActionsProps['actions'] = [];

    if (user && userHasPermission(user, 'userRole:create', {
      orgId: user.institution.id,
      ownerId: null
    })) {
      actions.push({
        title: t('duplicateAndEditRole'),
        onClick: duplicateRole
      });
    }

    if (user && userHasPermission(user, 'userRole:delete', {
      orgId: user.institution.id,
      ownerId: null
    })) {
      actions.push({
        title: t('deleteRole'),
        onClick: onDeleteRole
      });
    }

    return actions;
  }, [user]);

  if (!role) {
    return null;
  }

  if (role.type === 'DEFAULT') {
    if (role.id === userRoleIds['Platform Administrator'] || role.id === userRoleIds['Acadeum Administrator']) {
      return null;
    } else {
      if (user && userHasPermission(user, 'userRole:create', {
        orgId: user.institution.id,
        ownerId: null
      })) {
        return (
          <Button variant="secondary" onClick={duplicateRole}>
            {t('duplicateAndEditRole')}
          </Button>
        );
      }
    }
  }

  if (actions.length > 0) {
    return (
      <Actions actions={actions}/>
    );
  }

  return null;
};
