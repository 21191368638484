import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import defaultOnDemandCourseDropDateDaysAfterStartDate
  from 'common-lib/constants/onDemandCourseDropDateDaysAfterStartDate.json';
import defaultOnDemandCourseEndDateDaysAfterStartDate
  from 'common-lib/constants/onDemandCourseEndDateDaysAfterStartDate.json';

import INSTITUTION_LEVELS from 'common-lib/constants/institutionLevels.json';
import isValidDomainName from 'common-lib/lib/isValidDomainName';
import isValidPostalCode from 'common-lib/lib/isValidPostalCode';

import { getValueForJsonEditor, getValueFromJsonEditor } from '@acadeum/ui';
import { useTranslate } from '@acadeum/translate';
import {
  validateInstitutionConsortialEnrollmentHomeInstitutionIds,
  validateInstitutionData,
  validateInstitutionSftpConfiguration,
  validateInstitutionSystemAdministratorContact,
  validateInstitutionWebhooksConfiguration
} from '@acadeum/validate';

import Field from '../Field';
import Form from '../Form';
import FormSubmit from '../FormSubmit';
import ConfirmationModal from '../ConfirmationModal';
import Subsection from '../Subsection';
import Row from '../Row';
import FormButtons from '../FormButtons';
import JSONEditor from '../JSONEditor';

import { YES_OR_NO_OPTIONS } from '../../helpers/select';
import { getCountryRegionLabel } from '../../helpers/format';

const INITIAL_VALUES = {
  data: getValueForJsonEditor({}),
  sftp: getValueForJsonEditor(null),
  webhooks: getValueForJsonEditor(null),
  consortialEnrollmentHomeInstitutionIds: getValueForJsonEditor([]),
  systemAdministratorContact: getValueForJsonEditor(null),
  isActive: true,
  isTeaching: false,
  country: 'US',
  stripeAccountType: 'STANDARD',
  invoiceAutoAdvancement: true,
  featuredInStudentApp: true,
  muteStudentEmails: false,
  studentApp: false,
  studentPersonalDataProtection: false,
  useMarketplace: false,
  qualityMattersDesignation: false,
  costPerCreditHour: 0,
  studentCostPerCreditHour: 0,
  onDemandCourseDropDateDaysAfterStartDate: defaultOnDemandCourseDropDateDaysAfterStartDate,
  onDemandCourseEndDateDaysAfterStartDate: defaultOnDemandCourseEndDateDaysAfterStartDate
};

export default function InstitutionForm({
  institution,
  onSubmit,
  submitTitle,
  className
}) {
  const initialValues = useMemo(() => {
    if (institution) {
      return createFormValues(institution);
    }
    return INITIAL_VALUES;
  }, []);

  const [country, setCountry] = useState(initialValues && initialValues.country);

  const [showInstitutionJsonExample, setShowInstitutionJsonExample] = useState();

  const t = useTranslate('InstitutionForm');

  const onShowInstitutionJsonExample = useCallback((event) => {
    event.preventDefault();
    setShowInstitutionJsonExample(true);
  }, [setShowInstitutionJsonExample]);

  const hideInstitutionJsonExample = useCallback(() => {
    setShowInstitutionJsonExample(false);
  }, [setShowInstitutionJsonExample]);

  const validateDomains = useCallback((value) => {
    const domains = value.trim().split(/,/).map(_ => _.trim());
    if (domains.length === 0) {
      return t('errors.emptyDomain');
    }
    const invalidDomains = domains.filter(_ => !isValidDomainName(_));
    if (invalidDomains.length > 0) {
      return t('errors.invalidDomain', {
        domains: invalidDomains.join(', ')
      });
    }
  }, []);

  const validatePostalCodeForCountry = useCallback((value) => {
    if (!isValidPostalCode(value, country)) {
      return t('errors.invalidPostalCode');
    }
  }, [country]);

  const onSubmit_ = useCallback(async (values) => {
    // Normalize form values.
    values = getFormValues(values);

    // Validate form values.
    validateInstitutionData(values.data);
    validateInstitutionSystemAdministratorContact(values.systemAdministratorContact);
    validateInstitutionSftpConfiguration(values.sftp);
    validateInstitutionWebhooksConfiguration(values.webhooks);
    validateInstitutionConsortialEnrollmentHomeInstitutionIds(values.consortialEnrollmentHomeInstitutionIds);

    // Submit form values.
    await onSubmit(values);
  }, [onSubmit]);

  return (
    <Form
      autoFocus
      values={initialValues}
      className={classNames('form', className)}
      onSubmit={onSubmit_}>
      {({ watch }) => {
        const learningManagementSystemOther = watch('learningManagementSystem') === 'OTHER';
        const studentInformationSystemOther = watch('studentInformationSystem') === 'OTHER';

        const name = watch('name');

        const validateFullName = (value) => {
          if (value === name) {
            return t('errors.invalidFullName');
          }
        };

        const validateShortName = (value) => {
          if (value === name) {
            return t('errors.invalidShortName');
          }
        };

        return (
          <>
            <Row>
              <Field
                required
                name="name"
                label={t('form.name')}
                col={12} />
            </Row>

            <Row>
              <Field
                name="shortName"
                label={t('form.shortName')}
                validate={validateShortName}
                col={4} />

              <Field
                name="fullName"
                label={t('form.fullName')}
                validate={validateFullName}
                col={8} />
            </Row>

            <Row>
              <Field
                required
                name="url"
                label={t('form.website')}
                col={6} />

              <Field
                required
                name="vanityUrl"
                label={t('form.vanityUrl')}
                col={6} />
            </Row>

            <Row>
              <Field
                required
                type="url"
                name="logoUrl"
                label={t('form.logoUrl')}
                col={6} />

              <Field
                type="url"
                name="logoRasterUrl"
                label={t('form.rasterizedLogoUrl')}
                col={6} />
            </Row>

            <Row>
              <Field
                required
                name="isActive"
                disabled={institution ? undefined : true}
                value={institution ? institution.isActive : true}
                label={t('form.isActive')}
                options={YES_OR_NO_OPTIONS}
                col={6} />

              <Field
                required
                name="isTeaching"
                label={t('form.isTeachingMember')}
                options={YES_OR_NO_OPTIONS}
                col={6} />
            </Row>

            <Row>
              <Field
                required
                name="public"
                label={t('form.isPublicInstitution')}
                options={YES_OR_NO_OPTIONS}
                col={4}
              />

              <Field
                required
                name="country"
                autoComplete="off"
                type="country"
                onChange={setCountry}
                label={t('form.country')}
                col={4} />

              {/* "Territory". Examples: Central, Northeast, Southeast. */}
              {/* https://github.com/Acadeum/Tickets/issues/568 */}
              <Field
                name="region"
                label={t('form.territory')}
                col={4} />
            </Row>

            <Row>
              <Field
                name="addressLine1"
                label={t('form.street1')}
                col={8} />

              <Field
                name="addressLine2"
                label={t('form.street2')}
                col={4} />
            </Row>

            <Row>
              <Field
                required
                name="city"
                label={t('form.cityTown')}
                col={4} />

              <Field
                name="state"
                label={getCountryRegionLabel(country)}
                type="country-region"
                country={country}
                col={4} />

              <Field
                name="postalCode"
                validate={validatePostalCodeForCountry}
                label={t('form.postalCode')}
                type="postal-code"
                country={country}
                col={4} />
            </Row>

            <Row>
              <Field
                name="description"
                label={t('form.description')}
                multiline
                col={12} />
            </Row>

            {/* Teaching Student Welcome Letter */}
            <Row>
              <Field
                name="teachingStudentWelcomeLetter"
                label={t('form.welcomeLetter')}
                multiline
                col={12} />
            </Row>

            {/* Teaching Student Welcome Letter for Marketplace */}
            <Row>
              <Field
                name="teachingStudentWelcomeLetterForMarketplace"
                label={t('form.welcomeLetterForMarketplace')}
                multiline
                col={12} />
            </Row>

            {/* Transcript Request URL */}
            <Row>
              <Field
                name="transcriptRequestUrl"
                label={t('form.transcriptRequestUrl')}
                col={12} />
            </Row>

            <Row>
              {/* IPEDS ID */}
              <Field
                name="ipedsId"
                label={t('form.ipedsId')}
                description={<SchoolCodeDescription/>}
                col={12}
              />
            </Row>

            <Row>
              <Field
                name="learningManagementSystem"
                label={t('form.learningManagementSystem')}
                options={LEARNING_MANAGEMENT_SYSTEM_OPTIONS}
                col={4}
              />

              {learningManagementSystemOther &&
                <>
                  <Field
                    required
                    name="learningManagementSystemVendor"
                    label={t('form.learningManagementSystemVendor')}
                    col={4}
                  />
                  <Field
                    required
                    name="learningManagementSystemProduct"
                    label={t('form.learningManagementSystemProduct')}
                    col={4}
                  />
                </>
              }
            </Row>

            <Row>
              <Field
                name="studentInformationSystem"
                label={t('form.studentInformationSystem')}
                options={STUDENT_INFORMATION_SYSTEM_OPTIONS}
                col={4}
              />

              {studentInformationSystemOther &&
                <>
                  <Field
                    required
                    name="studentInformationSystemVendor"
                    label={t('form.studentInformationSystemVendor')}
                    col={4}
                  />
                  <Field
                    required
                    name="studentInformationSystemProduct"
                    label={t('form.studentInformationSystemProduct')}
                    col={4}
                  />
                </>
              }
            </Row>

            <Row>
              <Field
                required
                name="level"
                label={t('form.level')}
                options={LEVEL_OPTIONS}
                col={4}
              />
            </Row>

            <Row>
              <Field
                required
                name="funding"
                options={FUNDING_OPTIONS}
                label={t('form.funding')}
                col={4}
              />

              <Field
                name="regionallyAccredited"
                options={YES_OR_NO_OR_UNKNOWN_OPTIONS}
                label={t('form.regionallyAccredited')}
                col={4}
              />
            </Row>

            <Row>
              <Field
                required
                label="On-Demand Drop Date"
                name="onDemandCourseDropDateDaysAfterStartDate"
                type="number"
                col={4}
              />
              <Field
                required
                label="On-Demand End Date"
                name="onDemandCourseEndDateDaysAfterStartDate"
                type="number"
                col={4}
              />
            </Row>
            {/* Student App enable switch */}
            <Row>
              <Field
                name="studentApp"
                type="switch"
                disabled={initialValues.studentApp}
                label={t('form.studentApp')}
                description={t('form.studentAppDescription')}
                col={12} />
            </Row>

            {/* "Featured in Student App" enable switch */}
            <Row>
              <Field
                name="featuredInStudentApp"
                type="switch"
                label={t('form.featuredInStudentApp')}
                description={t('form.featuredInStudentAppDescription')}
                col={12} />
            </Row>

            <Row>
              <Field
                name="muteStudentEmails"
                type="switch"
                label={t('form.muteStudentEmails')}
                col={12}
              />
            </Row>

            {/* Student Personally Identifiable Information protection laws compliance. */}
            <Row>
              <Field
                name="studentPersonalDataProtection"
                type="switch"
                label={t('form.studentPersonalDataProtection')}
                description={t('form.studentPersonalDataProtectionDescription')}
                col={12} />
            </Row>

            {/* Marketplace App enable switch */}
            <Row>
              <Field
                name="useMarketplace"
                type="switch"
                disabled={initialValues.useMarketplace}
                label={t('form.useMarketplace')}
                description={t('form.useMarketplaceDescription')}
                col={12} />
            </Row>

            <Row>
              <Field
                col={12}
                type="switch"
                name="qualityMattersDesignation"
                label={t('form.qualityMattersDesignation')}
                description={t('form.qualityMattersDesignationDescription')}
              />
            </Row>

            <Row>
              <Field
                required
                name="stripeCustomerId"
                label={t('form.stripeCustomerId')}
                col={4}
              />

              <Field
                name="stripeDestinationId"
                label={t('form.stripeDestinationId')}
                col={4}
              />

              <Field
                required
                name="stripeAccountType"
                options={STRIPE_ACCOUNT_TYPE_OPTIONS}
                label={t('form.stripeAccountType')}
                col={4}
              />
            </Row>

            <Row>
              {/* Payment method. */}
              {/* description="USA-based institutions should use ACH for payments. Canada-based institutions have to use Credit Cards." */}
              <Field
                required
                name="paymentMethod"
                options={PAYMENT_METHOD_OPTIONS}
                label={t('form.paymentMethod')}
                col={4} />

              <Field
                required
                name="acceptedPaymentMethod"
                options={ACCEPTED_PAYMENT_METHOD_OPTIONS}
                label={t('form.acceptedPaymentMethod')}
                col={4}
              />
            </Row>

            <Row>
              <Field
                name="invoiceAutoAdvancement"
                type="switch"
                label={t('form.invoiceAutoAdvancement')}
                col={12}
              />
            </Row>

            {/* Institution email domains */}
            <Row>
              <Field
                required
                name="emailDomains"
                validate={validateDomains}
                label={t('form.emailDomains')}
                description={t('form.emailDomainsDescription')}
                example="concordia.edu, ctx.edu, student.ctx.edu"
                col={12} />
            </Row>

            {/* Consortial Enrollment Home Institutions */}
            <Row>
              <Field
                type="json"
                name="consortialEnrollmentHomeInstitutionIds"
                label={t('form.consortialEnrollmentHomeInstitutionIds')}
                description={t('form.consortialEnrollmentHomeInstitutionIdsDescription')}
                example="[1, 2, 3]"
                height={240}
                col={12}
              />
            </Row>

            {/* Institution data JSON */}
            <Row>
              <Field
                required
                name="data"
                type="json"
                label={t('form.data')}
                onChange={(event) => {
                  console.log('event', event, typeof event);
                }}
                description={(
                  <a
                    href="#"
                    onClick={onShowInstitutionJsonExample}>
                    {t('form.seeExample')}
                  </a>
                )}
                height={400}
                col={12}
              />
            </Row>

            {/* System Administrator Contact */}
            <Row>
              <Field
                name="systemAdministratorContact"
                type="json"
                label={t('form.systemAdministratorContact')}
                description={t('form.systemAdministratorContactDescription')}
                height={160}
                col={12}
              />
            </Row>

            {/* SFTP config */}
            <Row>
              <Field
                name="sftp"
                type="json"
                label={t('form.sftp')}
                height={160}
                col={12}
              />
            </Row>

            {/* Webhooks config */}
            <Row>
              <Field
                name="webhooks"
                type="json"
                label={t('form.webhooks')}
                height={160}
                col={12}
              />
            </Row>

            <Subsection title="Estimates">
              <Row>
                <Field
                  name="costPerCreditHour"
                  type="number"
                  label={t('form.costPerCreditHour')}
                  col={4} />

                <Field
                  name="studentCostPerCreditHour"
                  type="number"
                  label={t('form.studentCostPerCreditHour')}
                  col={4} />
              </Row>
            </Subsection>

            <ConfirmationModal
              title={t('modal.title')}
              onHide={hideInstitutionJsonExample}
              show={showInstitutionJsonExample}
              buttonText={t('modal.hide')}>
              <JSONEditor
                readOnly
                label={t('modal.example')}
                description={t('modal.exampleDescription')}
                name="institution-json-example"
                value={institutionJsonExample}
              />
              <br/>
            </ConfirmationModal>

            <FormButtons>
              <FormSubmit>
                {submitTitle}
              </FormSubmit>
            </FormButtons>
          </>
        );
      }}
    </Form>
  );
}

InstitutionForm.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired
  }),
  onSubmit: PropTypes.func.isRequired,
  submitTitle: PropTypes.string.isRequired,
  institution: PropTypes.object,
  className: PropTypes.string
};

const institutionJsonExample = `{
  "levels": "Associate, Baccalaureate, Masters, Doctorate, and professional degrees",
  "affiliations": [
    "Roman Catholic"
  ],
  "accreditations":
  [
    {
      "name": "Southern Association of Colleges and Schools Commission on Colleges",
      "link": "http://www.sacscoc.org/"
    }
  ],
  "gradingScale":
  [
    {
      "grade": "A",
      "description": "Excellent Scholarship",
      "numeric": "93-100",
      "points": 4
    },
    {
      "grade": "IP",
      "description": "Incomplete work",
      "numeric": "50-60",
      "points": 1
    }
  ],
  "gradingScaleNotes": "The institution only offers non-credit CE courses.",
  "gradingScaleUrl": "http://uiw.edu/registrar/GradingScale.html",
  "academicCalendarUrl": "https://my.uiw.edu/registrar/academics/index.html",
  "academicCalendar":
  {
    "terms":
    [
      {
        "name": "Fall 2024",
        "sessions":
        [
          {
            "name": "Fall I",
            "startDate": "2024-08-22",
            "endDate": "2024-10-14",
            "addDropEnds": "2024-08-26"
          },
          {
            "name": "Fall II",
            "startDate": "2024-10-17",
            "endDate": "2024-12-09",
            "addDropEnds": "2024-10-21"
          }
        ]
      },
      {
        "name": "Spring 2024",
        "sessions":
        [
          {
            "name": "Spring I",
            "startDate": "2024-01-09",
            "endDate": "2024-03-03",
            "addDropEnds": "2024-01-13"
          },
          {
            "name": "Spring II",
            "startDate": "2024-03-06",
            "endDate": "2024-04-28",
            "addDropEnds": "2024-03-10"
          }
        ]
      }
    ]
  }
}`;

// Parses `redux-form` values into an `institution` object
export function getFormValues(values) {
  let {
    ipedsId,
    emailDomains,
    data,
    sftp,
    webhooks,
    consortialEnrollmentHomeInstitutionIds,
    systemAdministratorContact,
    learningManagementSystem,
    /* eslint-disable prefer-const */
    learningManagementSystemVendor,
    learningManagementSystemProduct,
    studentInformationSystem,
    studentInformationSystemVendor,
    studentInformationSystemProduct,
    ...rest
  } = values;

  // Parse JSON fields.
  data = getValueFromJsonEditor(data);
  sftp = getValueFromJsonEditor(sftp);
  webhooks = getValueFromJsonEditor(webhooks);
  consortialEnrollmentHomeInstitutionIds = getValueFromJsonEditor(consortialEnrollmentHomeInstitutionIds);
  systemAdministratorContact = getValueFromJsonEditor(systemAdministratorContact);

  // Parse IPEDS ID.
  if (ipedsId && isFinite(ipedsId)) {
    ipedsId = parseInt(ipedsId);
  }

  // Parse email domains.
  if (emailDomains) {
    emailDomains = emailDomains.split(/,/).map(_ => _.trim());
  } else {
    emailDomains = [];
  }

  // Get `learningManagementSystem` value.
  if (learningManagementSystem === 'OTHER') {
    learningManagementSystem = getTitleFromVendorAndProduct({
      vendor: learningManagementSystemVendor,
      product: learningManagementSystemProduct
    });
  }

  // Get `studentInformationSystem` value.
  if (studentInformationSystem === 'OTHER') {
    studentInformationSystem = getTitleFromVendorAndProduct({
      vendor: studentInformationSystemVendor,
      product: studentInformationSystemProduct
    });
  }

  // When an SVG logo is supplied a rasterized copy
  // should also be provided for social sharing.
  if (/\.svg$/i.test(rest.logoUrl) && !rest.logoRasterUrl) {
    throw new Error('You supplied a "vector" (SVG) logo for the institution. Social sharing also requires a rasterized copy of the logo. Upload a rasterized copy of the SVG logo and paste its URL as a "Rasterized Logo URL" field value.');
  }

  // Result
  return {
    ...rest,
    ipedsId,
    emailDomains,
    data,
    sftp,
    webhooks,
    consortialEnrollmentHomeInstitutionIds,
    systemAdministratorContact,
    learningManagementSystem,
    studentInformationSystem
  };
}

// Parses form `values` into an `institution` object.
function createFormValues({
  ipedsId,
  emailDomains,
  data,
  sftp,
  webhooks,
  consortialEnrollmentHomeInstitutionIds,
  systemAdministratorContact,
  learningManagementSystem,
  learningManagementSystemVendor,
  learningManagementSystemProduct,
  studentInformationSystem,
  studentInformationSystemVendor,
  studentInformationSystemProduct,
  onDemandCourseDropDateDaysAfterStartDate,
  onDemandCourseEndDateDaysAfterStartDate,
  ...rest
}) {
  // Get `learningManagementSystem` value.
  if (learningManagementSystem) {
    if (!LEARNING_MANAGEMENT_SYSTEM_OPTIONS.find(_ => _.value === learningManagementSystem)) {
      const { vendor, product } = getVendorAndProductFromTitle(learningManagementSystem);
      learningManagementSystemVendor = vendor;
      learningManagementSystemProduct = product;
      learningManagementSystem = 'OTHER';
    }
  }

  // Get `studentInformationSystem` value.
  if (studentInformationSystem) {
    if (!STUDENT_INFORMATION_SYSTEM_OPTIONS.find(_ => _.value === studentInformationSystem)) {
      const { vendor, product } = getVendorAndProductFromTitle(studentInformationSystem);
      studentInformationSystemVendor = vendor;
      studentInformationSystemProduct = product;
      studentInformationSystem = 'OTHER';
    }
  }

  return {
    ...rest,
    onDemandCourseDropDateDaysAfterStartDate: onDemandCourseDropDateDaysAfterStartDate ?? defaultOnDemandCourseDropDateDaysAfterStartDate,
    onDemandCourseEndDateDaysAfterStartDate: onDemandCourseEndDateDaysAfterStartDate ?? defaultOnDemandCourseEndDateDaysAfterStartDate,
    ipedsId: ipedsId ? String(ipedsId) : undefined,
    emailDomains: emailDomains.length === 0 ? undefined : emailDomains.join(', '),
    data: getValueForJsonEditor(data),
    sftp: getValueForJsonEditor(sftp),
    webhooks: getValueForJsonEditor(webhooks),
    consortialEnrollmentHomeInstitutionIds: getValueForJsonEditor(consortialEnrollmentHomeInstitutionIds),
    systemAdministratorContact: getValueForJsonEditor(systemAdministratorContact),
    learningManagementSystem,
    learningManagementSystemVendor,
    learningManagementSystemProduct,
    studentInformationSystem,
    studentInformationSystemVendor,
    studentInformationSystemProduct
  };
}

const SchoolCodeDescription = () => (
  <div>
    Enter the institution's
    {' '}
    "IPEDS ID"
    {' '}
    (if it's present in the IPEDS database):
    {' '}
    search for the institution on the
    {' '}
    <a
      href="https://carnegieclassifications.acenet.edu/lookup/lookup.php"
      target="_blank">
      "Carnegie Classification of Institutions of Higher Education"
    </a>
    {' '}
    website
    {' '}
    by its name, click on the search result and look at the URL bar of the web browser — it will show a URL looking like
    {' '}
    <code>
      http://carnegieclassifications.iu.edu/lookup/view_institution.php?unit_id=225627&...
    </code>
    {' '}
    Copy the six digit <code>unit_id</code> parameter value and paste it in this input field.
    <br/>
    {' '}
    Only <strong>if the institution is not found</strong> in the "Carnegie Classification of Institutions of Higher Education" can one leave this field blank. Otherwise, the institution will have a duplicate record in the institution input field options list when signing up on the <a target="_blank" href="https://students.acadeum.com/">student site</a>.
  </div>
);

const LEVEL_OPTIONS = INSTITUTION_LEVELS.map((level) => ({
  value: level,
  label: level
}));

const PAYMENT_METHOD_OPTIONS = [
  {
    value: 'ACH',
    label: 'ACH'
  },
  {
    value: 'CARD',
    label: 'Credit Card'
  }
];

const ACCEPTED_PAYMENT_METHOD_OPTIONS = [
  {
    value: 'ACH',
    label: 'ACH'
  },
  {
    value: 'CARD',
    label: 'Credit Card'
  }
];

// const PAYMENT_SOURCE_PREFERRED_TYPE_OPTIONS = [
//   {
//     value: 'PRIMARY',
//     label: 'Primary (ACH)'
//   },
//   {
//     value: 'BACKUP',
//     label: 'Backup (Credit Card)'
//   }
// ];

const STRIPE_ACCOUNT_TYPE_OPTIONS = [
  {
    value: 'STANDARD',
    label: 'Standard (can make payments)'
  },
  {
    value: 'CUSTOM',
    label: 'Custom (can make and receive payments)'
  }
];

const FUNDING_OPTIONS = [
  {
    value: 'Public, Not-For-Profit',
    label: 'Public, Not-For-Profit'
  },
  {
    value: 'Private, Not-For-Profit',
    label: 'Private, Not-For-Profit'
  },
  {
    value: 'Private, For-Profit',
    label: 'Private, For-Profit'
  }
];

const YES_OR_NO_OR_UNKNOWN_OPTIONS = [
  {
    value: true,
    label: 'Yes'
  },
  {
    value: false,
    label: 'No'
  },
  {
    value: null,
    label: 'Unknown'
  }
];

const STUDENT_INFORMATION_SYSTEMS = [
  {
    'vendor': 'Anthology',
    'product': 'Blackboard'
  },
  {
    'vendor': 'Anthology',
    'product': 'Student'
  },
  {
    'vendor': 'Aptron',
    'product': 'Collegix'
  },
  {
    'vendor': 'Campus Café',
    'product': 'SIS'
  },
  {
    'vendor': 'Campus Mgt',
    'product': 'CampusVue'
  },
  {
    'vendor': 'Comspec',
    'product': 'EmpowerSIS'
  },
  {
    'vendor': 'EDC',
    'product': 'Campus Anyware'
  },
  {
    'vendor': 'Ellucian',
    'product': 'Banner'
  },
  {
    'vendor': 'Ellucian',
    'product': 'Colleague'
  },
  {
    'vendor': 'Ellucian',
    'product': 'Power Campus'
  },
  {
    'vendor': 'Ellucian',
    'product': 'Quercus'
  },
  {
    'vendor': 'Genius',
    'product': 'GeniusSIS'
  },
  {
    'vendor': 'Infinite Campus',
    'product': 'SIS'
  },
  {
    'vendor': 'Institutional',
    'product': 'Prorprietary'
  },
  {
    'vendor': 'Jenzabar',
    'product': 'EX'
  },
  {
    'vendor': 'Jenzabar',
    'product': 'One'
  },
  {
    'vendor': 'Jenzabar',
    'product': 'Sonis'
  },
  {
    'vendor': 'Jenzabar',
    'product': 'CX/CARS'
  },
  {
    'vendor': 'Kellogg',
    'product': 'KRIS'
  },
  {
    'vendor': 'Maruti',
    'product': 'Poise'
  },
  {
    'vendor': 'Oasis Tech',
    'product': 'CampusNext'
  },
  {
    'vendor': 'Oracle PeopleSoft',
    'product': 'Campus Solutions'
  },
  {
    'vendor': 'Populi',
    'product': 'College Mgt Suite'
  },
  {
    'vendor': 'Quinsoft',
    'product': 'OpenCUAS'
  },
  {
    'vendor': 'Sakai',
    'product': 'Student'
  },
  {
    'vendor': 'Unit 4',
    'product': 'CAMS Enterprise'
  },
  {
    'vendor': 'University of Cambridge',
    'product': 'CamSIS'
  },
  {
    'vendor': 'Workday',
    'product': 'Student'
  }
];

const LEARNING_MANAGEMENT_SYSTEMS = [
  {
    'vendor': 'Agilix',
    'product': 'Buzz'
  },
  {
    'vendor': 'Aperero',
    'product': 'Sakai'
  },
  {
    'vendor': 'Blackboard',
    'product': 'Learn'
  },
  {
    'vendor': 'Blackboard',
    'product': 'Ultra'
  },
  {
    'vendor': 'Brightspace',
    'product': 'D2L/Desire2Learn'
  },
  {
    'vendor': 'Coursera Inc.',
    'product': 'Coursera'
  },
  {
    'vendor': 'Dialogedu',
    'product': 'Dialogedu'
  },
  {
    'vendor': 'Google',
    'product': 'Classroom Edition'
  },
  {
    'vendor': 'Google',
    'product': 'Education Plus Edition'
  },
  {
    'vendor': 'Google',
    'product': 'Education Standard'
  },
  {
    'vendor': 'Google',
    'product': 'Teaching & Learning Edition'
  },
  {
    'vendor': 'Google',
    'product': 'Workspace for Edu Fundamentals'
  },
  {
    'vendor': 'Infinite Campus',
    'product': 'LMS'
  },
  {
    'vendor': 'Institutional',
    'product': 'Proprietary'
  },
  {
    'vendor': 'Instructure',
    'product': 'Canvas'
  },
  {
    'vendor': 'Jenzabar',
    'product': 'eL'
  },
  {
    'vendor': 'Moodle HQ',
    'product': 'Moodle'
  },
  {
    'vendor': 'OpenLMS',
    'product': 'Edu'
  },
  {
    'vendor': 'OpenLMS',
    'product': 'Totara'
  },
  {
    'vendor': 'OpenLMS',
    'product': 'Work'
  },
  {
    'vendor': 'Populi',
    'product': 'Populi LMS'
  },
  {
    'vendor': 'Powerschool',
    'product': 'Schoology'
  },
  {
    'vendor': 'Vcamp',
    'product': '360'
  },
  {
    'vendor': 'Wiley',
    'product': 'Engage'
  }
];

const STUDENT_INFORMATION_SYSTEM_OPTIONS = STUDENT_INFORMATION_SYSTEMS.map(({ vendor, product }) => ({
  value: getTitleFromVendorAndProduct({ vendor, product }),
  label: getTitleFromVendorAndProduct({ vendor, product })
})).concat({ value: 'OTHER', label: 'Other' });

const LEARNING_MANAGEMENT_SYSTEM_OPTIONS = LEARNING_MANAGEMENT_SYSTEMS.map(({ vendor, product }) => ({
  value: getTitleFromVendorAndProduct({ vendor, product }),
  label: getTitleFromVendorAndProduct({ vendor, product })
})).concat({ value: 'OTHER', label: 'Other' });

function getTitleFromVendorAndProduct({ vendor, product }) {
  return vendor + ': ' + product;
}

function getVendorAndProductFromTitle(title) {
  const [vendor, product] = title.split(': ');
  return { vendor, product };
}
