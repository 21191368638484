import type { RtkApi } from '@acadeum/helpers';
import type { Id, Institution } from '@acadeum/types';

export interface FetchTransfersOutputListItem {
  id: string;
  destinationPayment: string;
  createdAt: Date;
  type: 'ENROLLMENT_FEE' | 'ANNUAL_FEE';
  sourceTransaction: {
    id: string;
    amount: number;
    amountRefunded: number;
    applicationFeeAmount: number;
    status: string;
    description: string;
    customer: string;
    paymentMethodDetails?: {
      type: string;
    };
    invoice?: {
      id: string;
      amountDue: number;
      createdAt: Date;
      dueDate?: Date;
      status: string;
      number: string;
      invoicePdf: string;
    };
    institution?: Pick<Institution, 'id' | 'vanityUrl' | 'name' | 'logoUrl'>;
  };
}

export interface FetchTransfersInput {
  page?: number;
  pageSize?: number;
  filters?: {
    ids?: Id[];
    type?: 'ENROLLMENT_FEE' | 'ANNUAL_FEE';
    institutionIds?: Id[];
    invoiceShouldExist?: boolean;
    invoiceStatus?: 'draft' | 'open' | 'paid' | 'uncollectible' | 'void';
    invoiceCreatedAt?: {
      from?: Date;
      to?: Date;
    };
  }
}

export interface FetchTransfersOutput {
  results: FetchTransfersOutputListItem[];
  totalCount: number;
  page: number;
  pageSize: number;
}

export interface FetchPaymentIntentsOutputListItem {
  id: string; // PaymentIntent ID
  amount: number; // PaymentIntent amount
  description: string; // PaymentIntent description
  status: string; // PaymentIntent status
  charge?: {
    id: string; // HI Payment ID
    amountRefunded: number; // Amount Refunded
    paymentMethodDetails?: {
      type: string; // Payment Method Type
    }
  };
  invoice?: {
    id: string; // Invoice ID
    createdAt: Date; // Invoice creation date
  };
  type: 'ENROLLMENT_FEE' | 'ANNUAL_FEE';
  institution?: Pick<Institution, 'id' | 'vanityUrl' | 'name' | 'logoUrl'>;
}

export interface FetchPaymentIntentsInput {
  page?: number;
  pageSize?: number;
  search?: string;
  filters?: {
    ids?: string[];
    type?: 'ENROLLMENT_FEE' | 'ANNUAL_FEE';
    institutionIds?: Id[];
    status?: string;
    invoiceCreatedAt?: {
      from?: Date;
      to?: Date;
    };
  }
}

export interface FetchPaymentIntentsOutput {
  results: FetchPaymentIntentsOutputListItem[];
  totalCount: number;
  page: number;
  pageSize: number;
}

export const injectStripeEndpoints = (rtkApi: RtkApi) =>
  rtkApi
    .enhanceEndpoints({ addTagTypes: ['transfer', 'paymentIntent'] })
    .injectEndpoints({
      endpoints: build => ({
        fetchTransfers: build.query<FetchTransfersOutput, FetchTransfersInput>({
          query: (params) => ({
            url: '/stripe/transfers',
            params
          }),
          providesTags: [{ type: 'transfer', id: 'LIST' }]
        }),
        fetchPaymentIntents: build.query<FetchPaymentIntentsOutput, FetchPaymentIntentsInput>({
          query: (params) => ({
            url: '/stripe/payment-intents',
            params
          }),
          providesTags: [{ type: 'paymentIntent', id: 'LIST' }]
        })
      })
    });

export type UseFetchTransfersQuery = ReturnType<typeof injectStripeEndpoints>['useFetchTransfersQuery'];
export type UseLazyFetchTransfersQuery = ReturnType<typeof injectStripeEndpoints>['useLazyFetchTransfersQuery'];
export type UseFetchPaymentIntentsQuery = ReturnType<typeof injectStripeEndpoints>['useFetchPaymentIntentsQuery'];
export type UseLazyFetchPaymentIntentsQuery = ReturnType<typeof injectStripeEndpoints>['useLazyFetchPaymentIntentsQuery'];
