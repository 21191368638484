import checkUserPermissions from 'common-lib/lib/checkUserPermissions.js';

import type {
  User,
  Institution,
  UserProfile,
  UserRolePermissionSubject,
  UserRolePermissionOperation
} from '@acadeum/types';

type RequestedPermission = `${UserRolePermissionSubject}:${UserRolePermissionOperation}`;

interface SubjectInfo {
  // Subject's owner ID.
  ownerId?: User['id'] | null;
  // Subject's owner organization ID.
  orgId: Institution['id'] | null | undefined;
}

interface RequestedPermissionParameters {
  // Whether can perform the action upon "Platform Administrator" users.
  platformAdministrator?: true;

  // Whether can perform the action upon "Acadeum Administrator" users.
  acadeumAdministrator?: true;

  // Whether can update users' "extended" set of properties.
  extended?: true;

  // Whether can delete an entity rather than just deactivate it.
  delete?: true;

  // Whether can also edit user's roles besided editing just the user's info.
  roles?: true;
}

export function userHasPermission(
  user: Pick<UserProfile, 'id' | 'institution' | 'roles'> | undefined,
  requestedPermission: RequestedPermission,
  subjectInfo: SubjectInfo,
  requestedPermissionParameters?: RequestedPermissionParameters
): boolean {
  if (!user) {
    return false;
  }
  const result = checkUserPermissions(
    user.id,
    user.institution.id,
    user.roles.map(_ => _.permissions),
    requestedPermission,
    subjectInfo,
    requestedPermissionParameters
  );
  return Boolean(result.permitted);
}
