import { useTranslate } from '@acadeum/translate';
import { ContentSection, FormField, FormRow } from '@acadeum/ui';
import React from 'react';

import { Mode } from '../helpers';

export interface GeneralSectionValues {
  code: string;
  title: string;
  prerequisitesText?: string;
}

interface GeneralSectionProps {
  mode: Mode;
}

export function GeneralSection({
  mode
}: GeneralSectionProps) {
  const t = useTranslate('shared-admin-ui.CourseForm');
  return (
    <ContentSection title={t('general.title')}>
      <FormRow>
        <FormField
          required
          name="code"
          label={t('general.courseId.label')}
          placeholder={t('general.courseId.placeholder')}
          disabled={mode === Mode.EDIT}
        />
        <FormField
          required
          name="title"
          label={t('general.courseTitle.label')}
          placeholder={t('general.courseTitle.placeholder')}
        />
      </FormRow>
      <FormField
        noMargin
        name="prerequisitesText"
        label={t('general.prerequisites.label')}
        placeholder={t('general.prerequisites.placeholder')}
      />
    </ContentSection>
  );
}
