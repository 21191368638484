import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connectHits, connectStats } from 'react-instantsearch-dom';

import { BaseButton as Button } from '@acadeum/ui';

import InstantSearchPagination from './InstantSearchPagination';
import InstantSearchResult from './InstantSearchResult';

import './InstantSearchResults.sass';

export default function InstantSearchResults(props) {
  return (
    <div className="InstantSearch-Results">
      <InstantSearchPaginatedResults {...props} />
      {/*
      <div className="InstantSearch-ResultsInfiniteScroll">
        <InstantSearchInfiniteScrollResults {...this.props} />
      </div>
      <div className="InstantSearch-ResultsPaginated">
        <InstantSearchPaginatedResults {...this.props} />
      </div>
      */}
    </div>
  );
}

// Feeds are complicated in terms of accessibility.
// https://www.w3.org/TR/wai-aria-practices/#feed
// https://www.w3.org/TR/wai-aria-practices/examples/feed/feed.html
//
// There's a bug in `connectInfiniteHits` in `react-instantsearch@4`.
// The bug happens when clicking on a search result, then "Back", then choose next page.
// The bug is fixed in `react-instantsearch@5`:
// https://github.com/algolia/react-instantsearch/blob/423c00e332439dbe637cbcb2addc04303aa3d59e/packages/react-instantsearch/src/connectors/connectInfiniteHits.js#L46-L47
class InstantSearchInfiniteScrollResults_ extends Component {
  static defaultProps = {
    transform: hit => hit
  };

  render() {
    const {
      isNarrowContent,
      isWideContent,
      component: ItemComponent,
      NoResultsComponent,
      hits,
      transform,
      hasMore,
      refine,
      className,
      ...rest
    } = this.props;

    return (
      <div className={className}>
        <div className="row">
          {hits.map((hit) => (
            <InstantSearchResult
              key={hit.objectID}
              hit={transform(hit)}
              isNarrowContent={isNarrowContent}
              isWideContent={isWideContent}
              component={ItemComponent}
              {...rest} />
          ))}
        </div>

        {hasMore &&
          <div className="center-content">
            <Button
              onClick={refine}
              className="InstantSearch-ShowMoreResults">
              See More
            </Button>
          </div>
        }

        <NoResults Component={NoResultsComponent}/>
      </div>
    );
  }
}

InstantSearchInfiniteScrollResults_.propTypes = {
  isNarrowContent: PropTypes.bool,
  isWideContent: PropTypes.bool,
  component: PropTypes.func.isRequired,
  NoResultsComponent: PropTypes.func,
  hits: PropTypes.array,
  transform: PropTypes.func.isRequired,
  hasMore: PropTypes.bool,
  refine: PropTypes.func,
  className: PropTypes.string
};

// const InstantSearchInfiniteScrollResults = connectInfiniteHits(InstantSearchInfiniteScrollResults_);

class InstantSearchPaginatedResults_ extends Component {
  static defaultProps = {
    transform: hit => hit,
    resultsRowComponent: Row
  };

  render() {
    const {
      floatingHeaderHeight,
      isNarrowContent,
      isWideContent,
      component: ItemComponent,
      NoResultsComponent,
      newCourseSearchDesign,
      // resultsRowComponent: Row,
      hits,
      transform,
      className,
      ...rest
    } = this.props;

    return (
      <div className={className}>
        {hits.length > 0 &&
          <ul className="InstantSearchResults-list row">
            {hits.map((hit) => (
              <InstantSearchResult
                key={hit.objectID}
                hit={transform(hit)}
                isNarrowContent={isNarrowContent}
                isWideContent={isWideContent}
                component={ItemComponent}
                {...rest} />
            ))}
          </ul>
        }

        {hits.length > 0 &&
          <InstantSearchPagination
            floatingHeaderHeight={floatingHeaderHeight}/>
        }
        {!newCourseSearchDesign && (
          <NoResults Component={NoResultsComponent}/>
        )}
      </div>
    );
  }
}

InstantSearchPaginatedResults_.propTypes = {
  floatingHeaderHeight: PropTypes.number,
  isNarrowContent: PropTypes.bool,
  isWideContent: PropTypes.bool,
  newCourseSearchDesign: PropTypes.bool,
  component: PropTypes.elementType.isRequired,
  NoResultsComponent: PropTypes.elementType,
  resultsRowComponent: PropTypes.elementType.isRequired,
  hits: PropTypes.array,
  className: PropTypes.string
};

const InstantSearchPaginatedResults = connectHits(InstantSearchPaginatedResults_);

class NoResults_ extends Component {
  render() {
    const {
      nbHits,
      Component
    } = this.props;

    if (nbHits > 0) {
      return null;
    }

    if (Component) {
      return <Component/>;
    }

    return (
      <div className="InstantSearch-NoResults">
        Nothing found matching your criteria
      </div>
    );
  }
}

NoResults_.propTypes = {
  // This property is always defined and is provided by `connectStats()` "connector".
  nbHits: PropTypes.number,

  Component: PropTypes.elementType
};

const NoResults = connectStats(NoResults_);

// function transformHits(hits, transformResults) {
//   if (transformResults) {
//     return transformResults(hits);
//   }
//   return hits;
// }

function Row({ children }) {
  return (
    <div className="row">
      {children}
    </div>
  );
}

Row.propTypes = {
  children: PropTypes.node.isRequired
};
