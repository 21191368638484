import { getAdminUsersApi } from '@acadeum/api';

import { rtkApi } from '../rtkApi';

export const {
  useAdminRequestUserEmailVerificationMutation,
  useAdminRequestUserPasswordResetMutation,
  useAdminResetUserPasswordMutation,
  useAdminVerifyUserEmailMutation,
  useFetchAdminUsersQuery,
  useAdminChangeOwnInstitutionMutation,
  useFetchUserAdminQuery
} = getAdminUsersApi(rtkApi);
