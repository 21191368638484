import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { toast } from '@acadeum/ui';

import type { ReduxState } from '../../helpers/app.types';
import { formatName } from '../../helpers/format';

import StudentUserForm from '../../components/StudentUserForm';
import FormHeading from '../../components/FormHeading';

import useLocation from '../../hooks/useLocation';

import actions from '../../actions';
import { useUpdateStudentUserMutation } from '../../api/studentUsers';

const {
  fetchStudentUser,
  goto
} = actions;

export default function EditStudentUser() {
  const {
    query: {
      from
    }
  } = useLocation();

  const studentUser = useSelector((state: ReduxState) => state.studentUsers.studentUser);

  const [updateStudentUser] = useUpdateStudentUserMutation();

  const profileLocation = `/student-users/${studentUser.id}`;
  const cancelLocation = from === 'profile' ? profileLocation : '/students';

  const onSubmit = async (values) => {
    delete values.email;
    await updateStudentUser({ id: studentUser.id, body: values });
    toast.success('Student profile has been updated!');
    goto(profileLocation);
  };

  return (
    <div>
      <FormHeading>
        {formatName(studentUser)}
      </FormHeading>
      <StudentUserForm
        values={studentUser}
        cancelLocation={cancelLocation}
        submitText="Save"
        onSubmit={onSubmit}
      />
    </div>
  );
}

EditStudentUser.propTypes = {
  studentUser: PropTypes.object,
  location: PropTypes.shape({
    query: PropTypes.shape({
      from: PropTypes.oneOf(['profile'])
    }).isRequired
  })
};

EditStudentUser.meta = () => ({
  title: 'Edit Student'
});

EditStudentUser.breadcrumbs = (state) => [
  ['Students', '/students'],
  formatName(state.studentUsers.studentUser)
];

EditStudentUser.load = async ({ params: { id } }) => {
  await fetchStudentUser(id);
};
