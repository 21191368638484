import React, { useState, useEffect } from 'react';

let AceEditorComponent;
if (typeof window !== 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  AceEditorComponent = require('./AceEditorComponent').default;
}

// eslint-disable-next-line
export const JSONEditorNew = React.forwardRef<any, any>((props, ref) => {
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  return (
    <>
      {domLoaded && AceEditorComponent && (
        <AceEditorComponent {...props} ref={ref}/>
      )}
    </>
  );
});
