import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAuthSelector, notAuthenticatedAction } from '@acadeum/auth';
import { useTranslate } from '@acadeum/translate';
import { AcceptIcon } from '@acadeum/icons';
import { Button, ButtonGroup, HStack, Text } from '@acadeum/ui';

import { PageLayoutWithLogos } from '../../components/PageLayoutWithLogos';

import styles from './LogoutPage.module.scss';

interface LogoutPageProps {
  redirectTo: (url: string) => void;
  navigateBack: () => void;
  logOut: () => void;
  isCourseShare?: boolean;
}

export const LogoutPage: React.FC<LogoutPageProps> = ({
  redirectTo,
  navigateBack,
  logOut,
  isCourseShare
}) => {
  const dispatch = useDispatch();
  const t = useTranslate('shared-admin-ui.LogoutPage');
  const user = useSelector(getAuthSelector('user'));

  const onLogOut = async () => {
    // The `/logout` page could only be redirected to when logging out the user.
    // Hence, the `user` is supposed to be defined.
    // So, `!user` can't happen normally, but sometimes in development mode
    // the redirect to the institution's home page below is a lengthy procedure
    // because Next.js compiles that page or something.
    // In those cases, when a developer refreshes the page, they'd be presented
    // with an error, so this is a workaround for development mode.
    if (!user) {
      void redirectTo('/');
      return;
    }
    // `user` becomes `undefined` after the `dispatch()` call below,
    // so any `user`-dependent `redirectUrl` should be pre-calculated before it.
    await logOut();

    // Course share website updates the user's state in the own way.
    if (!isCourseShare) {
      dispatch(notAuthenticatedAction());
    }
  };

  return (
    <PageLayoutWithLogos
      className={styles.root}
      title={t('logOut')}
      subtitle={t('subtitle')}
    >
      <PageLayoutWithLogos.Card>
        {user ? (
          <>
            <Text as="h2" variant="headingMd" mb="sm">
              {t('logOut')}
            </Text>
            <Text mb="md">
              {t('description')}
            </Text>
            <HStack justify="end" gap="md">
              <Button variant="secondary" onClick={navigateBack}>
                {t('cancel')}
              </Button>
              <Button onClick={onLogOut}>
                {t('logout')}
              </Button>
            </HStack>
          </>
        ) : (
          <>
            <AcceptIcon className={styles.icon}/>
            <Text
              as="h2"
              mt="lg"
              mb="md"
              alignment="center"
              variant="headingMd"
            >
              {t('success.title')}
            </Text>
            <Text color="grey" alignment="center" mb="lg">
              {t('success.description')}
            </Text>
            <ButtonGroup fullWidth>
              <Button url="/">
                {t('login')}
              </Button>
            </ButtonGroup>
          </>
        )}
      </PageLayoutWithLogos.Card>

      <br/>
      <br/>
    </PageLayoutWithLogos>
  );
};
