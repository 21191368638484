import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import config from 'config';

import InstitutionSearch from './InstitutionSearch_';
import InstitutionSearchResultRow from './InstitutionSearchResultRow';
import InstitutionSearchResult from './InstitutionSearchResult';

import useLocation from '../../hooks/useLocation';

// Must be equal to the `height` of `.Header` CSS rule.
const FLOATING_HEADER_HEIGHT = 64;

export default function CCInstitutionSearch({
  layout,
  ...rest
}) {
  const { isNarrowContent } = useSelector(state => state.app);

  const location = useLocation();

  return (
    <InstitutionSearch
      {...rest}
      layout={layout === 'row' ? 'row' : 'rowGridTwoColumns'}
      component={layout === 'row' ? InstitutionSearchResultRow : InstitutionSearchResult}
      isNarrowContent={isNarrowContent}
      floatingHeaderHeight={FLOATING_HEADER_HEIGHT}
      indexPrefix={config.algolia.prefix}
      appId={config.algolia.appID}
      apiKey={config.algolia.apiKey}
      resultsPerPage={RESULTS_PER_PAGE}
      location={location}
    />
  );
}

CCInstitutionSearch.propTypes = {
  layout: PropTypes.oneOf(['row', 'rowGridTwoColumns'])
};

const RESULTS_PER_PAGE = [30, 60, 90];
