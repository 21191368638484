import React from 'react';

import type { Table, RowData } from '@tanstack/react-table';

import { ResultsPerPageSelect } from '../../../ResultsPerPageSelect';
import { Pagination as BasePagination } from '../../../Pagination';

import styles from './Pagination.module.scss';

interface PaginationProps<TData> {
  table: Table<TData>;
  pageSizeOptions: number[];
}

export const Pagination = <TData extends RowData>({
  table,
  pageSizeOptions
}: PaginationProps<TData>) => {
  const { pageSize, pageIndex } = table.getState().pagination;
  const pageCount = table.getPageCount();

  const onPageChange = page => {
    table.setPageIndex(page - 1);
  };

  const onPerPageChange = pageSize => {
    table.setPageSize(pageSize);
  };

  return (
    <div className={styles.Pagination}>
      <BasePagination
        onPageChange={onPageChange}
        state={{
          currentPage: pageIndex + 1,
          pageCount
        }}
      />

      <ResultsPerPageSelect
        pageSize={pageSize}
        options={pageSizeOptions}
        onChange={onPerPageChange}
      />
    </div>
  );
};
