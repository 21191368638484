import type { FC } from 'react';
import React from 'react';
import classNames from 'classnames';

import { List, QualityMattersBadge, Tag } from '@acadeum/ui';

import { ApprovedBadge } from './ui/ApprovedBadge';
import { ConsortialBadge } from './ui/ConsortialBadge';
import { OnDemandBadge } from './ui/OnDemandBadge';

import styles from './CourseBadges.module.scss';

interface CourseBadgesProps {
  course: any;
  reverse?: boolean;
  isApproved?: boolean;
  isConsortial?: boolean;
  hasCategories?: boolean;
  className?: string;
  dummy?: boolean,
  dummyHours?: number
  variant: 'card' | 'page';
}

export const CourseBadges: FC<CourseBadgesProps> = ({
  course,
  isApproved,
  isConsortial,
  reverse,
  hasCategories,
  className,
  dummy,
  dummyHours,
  variant
}) => {
  const credits = dummy ? dummyHours : course.hours;
  const level = dummy ? 'Status' : course.level;

  return (
    <List
      unstyled className={classNames(className, styles.CourseBadges, {
        [styles.reverse]: reverse
      })}
    >
      {hasCategories && (
        /* Categories */
        course.categories.map((category, i) => (
          <List.Item key={i}>
            <Tag>{category}</Tag>
          </List.Item>
        ))
      )}

      {/* Credit Hours */}
      <List.Item>
        <Tag>{credits} Credit{credits === 1 ? '' : 's'}</Tag>
      </List.Item>

      {/* Level  */}
      <List.Item>
        <Tag>{level}</Tag>
      </List.Item>

      {/* Consortial */}
      {isConsortial && (
        <List.Item>
          <ConsortialBadge/>
        </List.Item>
      )}

      {/* Approved */}
      {isApproved && (
        <List.Item>
          <ApprovedBadge/>
        </List.Item>
      )}

      {course.onDemand && (
        <List.Item>
          <OnDemandBadge/>
        </List.Item>
      )}

      {course.institution.qualityMattersDesignation && (
        <List.Item>
          <QualityMattersBadge variant={variant === 'card' ? 'short' : 'full'}/>
        </List.Item>
      )}
    </List>
  );
};
