export default function formatName(person) {
  if (person.firstName) {
    if (person.lastName) {
      return `${person.firstName} ${person.lastName}`;
    }
    return person.firstName;
  }
  // "Group or Office" user accounts in ASP don't have a name.
  if (person.title) {
    return person.title;
  }
}
