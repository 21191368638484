import React, { useCallback, useState } from 'react';
import classNames from 'classnames';

import { useIsMounted } from '@acadeum/hooks';
import { isPromise } from '@acadeum/helpers';

import { UnstyledButton } from '../UnstyledButton';

import styles from './BaseButton.module.scss';

export interface BaseButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Replaces button text with a spinner while a background action is being performed */
  loading?: boolean;
}

export const BaseButton = React.forwardRef<HTMLButtonElement, BaseButtonProps>(({
  className,
  disabled,
  onClick: propsOnClick,
  loading,
  ...rest
}, ref) => {
  const [wait, setWait] = useState(false);
  const isMounted = useIsMounted();

  const onClick = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (propsOnClick) {
      const result = propsOnClick(event);
      if (isPromise(result)) {
        setWait(true);
        const onEnded = () => isMounted() && setWait(false);
        result.then(onEnded, onEnded);
      }
    }
  }, [isMounted, propsOnClick]);

  return (
    <UnstyledButton
      {...rest}
      ref={ref}
      onClick={onClick}
      loading={loading || wait}
      disabled={disabled || wait}
      className={classNames(className, styles.BaseButton, {
        [styles.wait]: wait
      })}
    />
  );
});
