import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { waitForJob } from '@acadeum/helpers';

import Row from '../../components/Row';
import Outputs from '../../components/Outputs';
import Output from '../../components/Output';
import Table from '../../components/Table';
import Section from '../../components/Section';
import Subsection from '../../components/Subsection';
import Button from '../../components/Button';
import Link from '../../components/Link';

import actions from '../../actions';

import './SearchIndexUpdate.sass';

const {
  runJob,
  getJobStatus,
  goto,
  notify,
  notifyError,
  fetchSearchIndexUpdate
} = actions;

export default function SearchIndexUpdate() {
  const stopPollingJobStatus = useRef();

  useEffect(() => {
    return () => {
      if (stopPollingJobStatus.current) {
        stopPollingJobStatus.current();
      }
    };
  }, []);

  const onRetrySearchIndexUpdate = async (id) => {
    try {
      const { id: newSearchIndexUpdateId } = await waitForJob(
        await runJob('searchIndexUpdates-retry', { id }),
        getJobStatus,
        (cancel) => stopPollingJobStatus.current = cancel
      );
      notify('Retry succeeded');
      goto(`/admin/search-index-updates/${newSearchIndexUpdateId}`);
    } catch (error) {
      console.error(error);
      notifyError('Retry errored');
    }
  };

  const { searchIndexUpdate } = useSelector(state => state.searchIndexUpdates);

  return (
    <Section title={(
      <>
        {searchIndexUpdate.indexType}
        <span style={{ fontSize: '1rem', fontWeight: 'normal', marginLeft: '0.75rem' }}>
          Search Index Update
        </span>
      </>
    )}>
      <Outputs>
        <Row>
          <Output
            label="Status"
            value={searchIndexUpdate.status}
            className={classNames('SearchIndexUpdate-status', {
              'SearchIndexUpdate-status--pending': searchIndexUpdate.status === 'STARTED',
              'SearchIndexUpdate-status--ok': searchIndexUpdate.status === 'FINISHED',
              'SearchIndexUpdate-status--error': searchIndexUpdate.status === 'ERROR' || searchIndexUpdate.status === 'TIMEOUT'
            })}
            col={3}
          />

          <Output
            label="Object IDs"
            value={searchIndexUpdate.objectIds ? searchIndexUpdate.objectIds.join(', ') : undefined}
            col={6}
          />

          <Output
            label="Started At"
            value={searchIndexUpdate.startedAt}
            type="date"
            time
            utc={false}
            col={3}
          />
        </Row>

        {/*
        <Row>
          <Output
            label="Requested At"
            value={searchIndexUpdate.startedAt}
            type="date"
            utc={false}
            col={3}
          />

          <Output
            label="Started At"
            value={searchIndexUpdate.startedAt}
            type="date"
            time
            utc={false}
            col={3}
          />

          <Output
            label="Ended At"
            value={searchIndexUpdate.endedAt}
            type="date"
            time
            utc={false}
            col={3}
          />
        </Row>
        */}
      </Outputs>

      {searchIndexUpdate.retriedSearchIndexUpdateId &&
        <Subsection title="Retry of">
          {'This is a retry of '}
          <Link to={`/admin/search-index-updates/${searchIndexUpdate.retriedSearchIndexUpdateId}`}>
            {searchIndexUpdate.retriedSearchIndexUpdateId}
          </Link>
          <br/>
          <br/>
          <br/>
        </Subsection>
      }

      {(searchIndexUpdate.status === 'ERROR' || searchIndexUpdate.status === 'TIMEOUT') &&
        <Subsection title="Retry">
          <Button
            onClick={async () => await onRetrySearchIndexUpdate(searchIndexUpdate.id)}>
            Retry
          </Button>
          <br/>
          <br/>
          <br/>
        </Subsection>
      }

      <Subsection title="Reason">
        <pre>{JSON.stringify(searchIndexUpdate.reason, null, 2)}</pre>
        <br/>
        <br/>
      </Subsection>

      {searchIndexUpdate.parameters &&
        <Subsection title="Parameters">
          <pre>{JSON.stringify(searchIndexUpdate.parameters, null, 2)}</pre>
          <br/>
          <br/>
        </Subsection>
      }

      {searchIndexUpdate.error &&
        <Subsection title="Error">
          <pre>{JSON.stringify(searchIndexUpdate.error, null, 2)}</pre>
          <br/>
          <br/>
        </Subsection>
      }

      {searchIndexUpdate.operationsLog &&
        <Subsection title="Operations">
          <Table
            data={searchIndexUpdate.operationsLog}
            columns={OPERATIONS_TABLE_COLUMNS}
            selectableRows={false}
            showTotalResultsCount={false}
          />
        </Subsection>
      }
    </Section>
  );
}

SearchIndexUpdate.load = async ({ params: { id } }) => {
  await fetchSearchIndexUpdate(id);
};

SearchIndexUpdate.meta = () => ({
  title: 'Search Index Update'
});

SearchIndexUpdate.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  ['Search Index Updates', '/admin/search-index-updates'],
  'Search Index Update'
];

const OPERATIONS_TABLE_COLUMNS = [
  {
    title: 'Index',
    content: (row) => (
      <code style={{ fontWeight: 'bold' }}>
        {row.index}
      </code>
    )
  },
  {
    title: 'Operation',
    content: (row) => (
      <code>
        {row.operation}
      </code>
    )
  },
  {
    title: 'Object IDs',
    content: (row) => (
      row.objectIds.join(', ')
    )
  }
];
