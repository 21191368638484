import React from 'react';
import { useSelector } from 'react-redux';

import { COURSE_LEVEL_OPTIONS_WITHOUT_OTHER, getTermOptions } from '../../helpers/select';

import Field from '../../components/Field';
import Form from '../../components/Form';
import FormSubmit from '../../components/FormSubmit';
import FormButtons from '../../components/FormButtons';
import Row from '../../components/Row';
import Section from '../../components/Section';

import actions from '../../actions';

const {
  fetchCourse,
  requestCourseSection,
  notify,
  goto
} = actions;

// Could use `useMemo()` for term options.
const termOptions = getTermOptions();

export default function RequestCourseSection() {
  const { course } = useSelector(state => state.courses);

  async function onSubmit(values) {
    await requestCourseSection(values);
    notify('Course Section requested! We will reach out soon.');
    goto('/');
  }

  return (
    <Section
      title="Request a Course Section"
      description="You can request more upcoming sections be made available by the Teaching Institution for this course by providing the following information.">

      <Form
        autoFocus
        className="form"
        onSubmit={onSubmit}>

        <Row>
          <Field
            required
            col={6}
            name="teachingInstitutionId"
            label="Teaching Institution"
            type="institution"
            value={course.institution.id} />

          <Field
            required
            col={6}
            name="course"
            label="Course"
            value={`${course.code} ${course.title}`} />
        </Row>

        <Row>
          <Field
            required
            name="level"
            label="Level"
            col={6}
            placeholder="Select a Level"
            options={COURSE_LEVEL_OPTIONS_WITHOUT_OTHER}
            value={course.level} />

          <Field
            required
            name="term"
            label="Preferred Term"
            col={6}
            placeholder="Select a Term"
            options={termOptions} />
        </Row>

        <Row>
          <Field
            required
            name="seatsCount"
            label="Estimated Number of Enrollments"
            col={6}
            type="integer" />

          <Field
            required
            multiline
            name="notes"
            label="Additional Commentary"
            placeholder="For example, “we are not planning to offer this course locally during the Fall term”"
            col={6} />
        </Row>

        <FormButtons>
          <FormSubmit>
            Request Section
          </FormSubmit>
        </FormButtons>
      </Form>
    </Section>
  );
}

RequestCourseSection.meta = () => ({
  title: 'Request a Course Section'
});

RequestCourseSection.breadcrumbs = (state) => [
  ['Course', `/courses/${state.courses.course.id}`],
  'Request Section'
];

RequestCourseSection.load = async ({ user, params: { id } }) => {
  try {
    await fetchCourse(id, { user });
  }
  catch (error) {
    // If the course doesn't exist
    // then redirect to "Not found" page.
    // (Algolia errors have `.statusCode`)
    if (error.statusCode === 404) {
      return {
        redirect: {
          url: '/not-found'
        }
      };
    }
    throw error;
  }
};
