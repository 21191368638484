import type { FC} from 'react';
import { useEffect } from 'react';

type ElementType = Window | Document | HTMLElement;

export interface EventListenerProps {
  event: string;
  handler: (event: Event) => void;
  element?: ElementType;
  options?: AddEventListenerOptions;
}

export const EventListener: FC<EventListenerProps> = ({
  event,
  handler,
  element = window,
  options
}) => {
  useEffect(() => {
    element.addEventListener(event, handler, options);
    return () => {
      element.removeEventListener(event, handler, options);
    };
  }, [event, element, handler, options]);

  return null;
};

EventListener.displayName = 'EventListener';
