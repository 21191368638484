type ParseCommaSeparatedIds = (value: string) => number[] | undefined;

export const parseCommaSeparatedIds: ParseCommaSeparatedIds = (value) => {
  const ids = value.split(',').map(_ => _.trim()).filter(_ => _);
  if (ids.length > 0) {
    return ids.map((id) => {
      const _id = Number(id);
      if (id !== String(_id)) {
        throw new Error(`Invalid ID: ${id}`);
      }
      return _id;
    });
  }
};
