import { useEffect, useState, useMemo } from 'react';
import { debounce } from 'lodash';

interface ScreenSizes { xl: number; md: number; sm: number; xs: number; lg: number; xxl: number; [key: string]: number }

export function useScreenSize(SCREEN_SIZES: ScreenSizes) {
  const [screenSize, setScreenSize] = useState<undefined | { key: string, minWidth: number }>();
  const [isSmallScreen, setSmallScreen] = useState<undefined | boolean>();
  const [isSmallScreenXS, setSmallScreenXS] = useState<undefined | boolean>();
  const [isLargeScreen, setLargeScreen] = useState<undefined | boolean>();

  useEffect(() => {
    const onResize = () => {
      setScreenSize(getScreenSize());
      setSmallScreen(getSmallScreen());
      setSmallScreenXS(getSmallScreenXS());
      setLargeScreen(getLargeScreen());
    };

    onResize();
    const debouncedHandleResize = debounce(onResize, 200);
    window.addEventListener('resize', debouncedHandleResize);
    return () => window.removeEventListener('resize', debouncedHandleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SCREEN_SIZES_LIST = useMemo(() => {
    return Object.keys(SCREEN_SIZES).map((key) => ({
      key,
      minWidth: SCREEN_SIZES[key]
    })).sort((a, b) => {
      return a > b ? 1 : (a < b ? -1 : 0);
    });
  }, [SCREEN_SIZES]);

  function getScreenSize() {
    const screenWidth = window.innerWidth;
    let currentSize = SCREEN_SIZES_LIST[0];
    for (const [index, size] of Object.entries(SCREEN_SIZES_LIST)) {
      const nextSize = SCREEN_SIZES_LIST[Number(index) + 1];
      if (screenWidth >= size.minWidth && (nextSize ? screenWidth < nextSize.minWidth : true)) {
        currentSize = size;
        break;
      }
    }
    return currentSize;
  }

  const getSmallScreenXS = () => window.innerWidth < SCREEN_SIZES.sm;
  const getSmallScreen = () => window.innerWidth < SCREEN_SIZES.md;
  const getLargeScreen = () => window.innerWidth >= SCREEN_SIZES.lg;

  return {
    screenSize,
    isSmallScreenXS,
    isSmallScreen,
    isLargeScreen
  };
}
