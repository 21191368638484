import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Output, Outputs } from '@acadeum/ui';

import { formatName } from '../../helpers/format';
import type { ReduxState } from '../../helpers/app.types';

import Section from '../../components/Section';
import Subsection from '../../components/Subsection';

import actions from '../../actions';

const { fetchStudentUser } = actions;

export default function StudentUser() {
  const user = useSelector((state: ReduxState) => state.studentUsers.studentUser);

  return (
    <Section
      title={formatName(user)}
      inlineActions
      actions={[{
        icon: 'edit',
        title: 'Edit Profile',
        // link: `/student-users/${user.id}/edit?from=profile`,
        link: `/students/add?values=${encodeURIComponent(JSON.stringify({
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          hiStudentId: user.hiStudentId
        }))}`
      }]}>

      <Subsection title="Personal Information">
        <Outputs>
          <Output
            label="First Name"
            value={user.firstName}
          />
          <Output
            label="Last Name"
            value={user.lastName}
          />
          <Output
            label="Student ID"
            value={user.hiStudentId}
          />
          <Output
            label="Student Email"
            type="email"
            value={user.email}
          />
        </Outputs>
      </Subsection>
    </Section>
  );
}

StudentUser.propTypes = {
  studentUser: PropTypes.object
};

StudentUser.meta = ({ useSelector }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const studentUser = useSelector(state => state.studentUsers.studentUser);
  return {
    title: formatName(studentUser)
  };
};

StudentUser.load = async ({ params: { id } }) => {
  await fetchStudentUser(id);
};

StudentUser.breadcrumbs = (state) => [
  ['Students', '/students'],
  formatName(state.studentUsers.studentUser)
];
