import type { RtkApi } from '@acadeum/helpers';
import type { History, Id, Institution, User, UserRole } from '@acadeum/types';

interface CreateUserInput extends Pick<User,
  'title' |
  'firstName' |
  'middleName' |
  'lastName' |
  'phone' |
  'phoneExt' |
  'sftp'> {
  roleIds: number[];
  redirectToWebsite: 'account' | 'courseshare';
  // An Acadeum Admin can specify an institution when creating a user
  institutionId?: number;
}

interface CreateUserOutput {
  id: User['id'];
}

interface FetchUserInput {
  /**
   * Include user institution in response
   */
  includeInstitution?: boolean;
  id: User['id'];
  allSettings?: boolean;
}

export interface FetchUserOutput extends Pick<User,
  'id' |
  'firstName' |
  'middleName' |
  'lastName' |
  'title' |
  'email' |
  'isActive' |
  'phone' |
  'phoneExt' |
  'sftp' |
  'type' |
  'courseImport'
> {
  institution: Pick<Institution, 'id' | 'name' | 'vanityUrl'>;
  roles: UserRole[];
  api?: boolean;
  singleSignOn?: boolean;
  status?: 'VERIFIED' | 'UNVERIFIED' | 'INACTIVE' | 'PASSWORD_NOT_SET';
}

type UpdateUserInputByInstitutionUser = Pick<User,
  'id' |
  'title' |
  'firstName' |
  'middleName' |
  'lastName' |
  'phone' |
  'phoneExt'
> & {
  roleIds: Id[]
}

type UpdateUserInputByInstitutionUserByAdmin = UpdateUserInputByInstitutionUser & {
  email: string;
  institutionId?: Id;
  sftp: boolean;
}

type UpdateUserInput = UpdateUserInputByInstitutionUser | UpdateUserInputByInstitutionUserByAdmin;

interface DeleteUserInput {
  id: Id;
}

interface DeactivateUserInput {
  id: Id;
}

interface ActivateUserInput {
  id: Id;
}

interface FetchUserLogInput {
  id: Id;
}

type FetchUserLogOutput = History[]

export interface FetchUsersInput {
  filters?: {
    status?: 'INACTIVE' | 'ACTIVE' | 'VERIFIED' | 'UNVERIFIED' | 'PASSWORD_NOT_SET',
    type?: 'GROUP' | 'INDIVIDUAL',
    roleId?: number
  };
}

export type UserListItem =
  Pick<User, 'id' | 'firstName' | 'middleName' | 'lastName' | 'title' | 'email' | 'isActive' | 'sftp'>
  & {
  roles: UserRole[]
  api: boolean;
  singleSignOn: boolean;
  status: 'VERIFIED' | 'UNVERIFIED' | 'INACTIVE' | 'PASSWORD_NOT_SET';
}

export type FetchUsersOutput = UserListItem[];

interface SetUsersToUsersRolesInput {
  roleIds: UserRole['id'][];
  userIds: User['id'][];
}

interface SetUsersRoleToUserInput {
  userId: User['id'];
  roleIds: UserRole['id'][];
}

export const getUsersApi = (rtkApi: RtkApi) => rtkApi
  .enhanceEndpoints({ addTagTypes: ['users', 'userRoles'] })
  .injectEndpoints({
    endpoints: build => ({
      createUser: build.mutation<CreateUserOutput, CreateUserInput>({
        query: (body) => ({
          url: '/users/create',
          method: 'POST',
          body
        }),
        invalidatesTags: [{ type: 'users', id: 'LIST' }]
      }),
      fetchUser: build.query<FetchUserOutput, FetchUserInput>({
        query: ({ id, ...rest }) => ({
          url: `/users/${id}`,
          params: rest
        }),
        providesTags: (result, error, arg) => [{ type: 'users', id: arg.id }]
      }),
      fetchUserLog: build.query<FetchUserLogOutput, FetchUserLogInput>({
        query: ({ id }) => `/users/${id}/auth-history`
      }),
      fetchUsers: build.query<FetchUsersOutput, FetchUsersInput>({
        query: (params) => ({
          url: '/users',
          params
        }),
        providesTags: [{ type: 'users', id: 'LIST' }]
      }),
      updateUser: build.mutation<void, UpdateUserInput>({
        query: ({ id, ...body }) => ({
          url: `/users/${id}`,
          method: 'PUT',
          body
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'users', id: arg.id }]
      }),
      deleteUser: build.mutation<void, DeleteUserInput>({
        query: ({ id }) => ({
          url: `/users/${id}`,
          method: 'DELETE'
        }),
        invalidatesTags: [{ type: 'users', id: 'LIST' }]
      }),
      deactivateUser: build.mutation<void, DeactivateUserInput>({
        query: ({ id }) => ({
          url: `/users/${id}/deactivate`,
          method: 'POST'
        }),
        invalidatesTags: (result, _error, { id }) => [
          { type: 'users', id: 'LIST' },
          { type: 'users', id }
        ]
      }),
      activateUser: build.mutation<void, ActivateUserInput>({
        query: ({ id }) => ({
          url: `/users/${id}/activate`,
          method: 'POST'
        }),
        invalidatesTags: (result, _error, { id }) => [
          { type: 'users', id: 'LIST' },
          { type: 'users', id }
        ]
      }),
      setUsersRolesForUsers: build.mutation<void, SetUsersToUsersRolesInput>({
        query: (body) => ({
          url: '/users/set-roles',
          method: 'POST',
          body
        }),
        invalidatesTags: ['users', 'userRoles']
      }),
      setUsersRoleToUser: build.mutation<void, SetUsersRoleToUserInput>({
        query: ({ userId, ...body }) => ({
          url: `/users/${userId}/roles`,
          method: 'POST',
          body
        }),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        invalidatesTags: (result, _error, { userId, roleIds }) => [
          { type: 'users', id: userId },
          { type: 'users', id: 'LIST' },
          { type: 'userRoles', id: 'LIST' },
          ...roleIds.map(id => ({ type: 'userRoles', id }))
        ]
      })
    })
  });

export type UseCreateUserMutation = ReturnType<typeof getUsersApi>['useCreateUserMutation'];
export type UseFetchUserQuery = ReturnType<typeof getUsersApi>['useFetchUserQuery'];
export type UseFetchUsersQuery = ReturnType<typeof getUsersApi>['useFetchUsersQuery'];
export type UseUpdateUserMutation = ReturnType<typeof getUsersApi>['useUpdateUserMutation'];
export type UseDeleteUserMutation = ReturnType<typeof getUsersApi>['useDeleteUserMutation'];
export type UseDeactivateUserMutation = ReturnType<typeof getUsersApi>['useDeactivateUserMutation'];
export type UseActivateUserMutation = ReturnType<typeof getUsersApi>['useActivateUserMutation'];
export type UseSetUsersRolesForUsersMutation = ReturnType<typeof getUsersApi>['useSetUsersRolesForUsersMutation'];
export type UseSetUsersRoleToUserMutation = ReturnType<typeof getUsersApi>['useSetUsersRoleToUserMutation'];
export type UseFetchUserLogQuery = ReturnType<typeof getUsersApi>['useFetchUserLogQuery'];
