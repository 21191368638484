import type { CSSProperties, ReactNode } from 'react';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import type { RowData, Table } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';

import { SearchIcon } from '@acadeum/icons';

import { Loader } from '../../../Loader';
import { Blank } from '../../../Blank';
import { Text } from '../../../Text';

import { EXPANDING_COLUMN_ID, SELECT_COLUMN_ID } from '../../utils/consts';
import { getCommonCellStyles } from '../../utils/column';
import type { ExportRowCb } from '../../utils/getExportRowFn';

import type { TableTranslate } from '../../Table';

import styles from './TBody.module.scss';
import { useTableContext } from '../../context';

interface TBodyProps<TData> {
  topRow?: ReactNode;
  table: Table<TData>;
  loading?: boolean;
  totalCount: number;
  emptyRows?: number;
  exportRowFn: ExportRowCb<TData>;
  translate: TableTranslate<TData>;
  rearrangeableInner?: boolean;
}

export const TBody = <TData extends RowData>({
  table,
  topRow,
  loading,
  totalCount,
  emptyRows,
  exportRowFn,
  translate,
  rearrangeableInner
}: TBodyProps<TData>) => {
  return (
    <tbody className={styles.TBody}>
      {loading && totalCount === 0 ? (
        <LargeRow table={table}>
          <Loader variant="spinner"/>
        </LargeRow>
      ) : (
        totalCount > 0 || (typeof emptyRows === 'number' && emptyRows > 0) ? (
          <>
            {topRow}
            <TBodyRows
              table={table}
              emptyRows={emptyRows}
              exportRowFn={exportRowFn}
            />
          </>
        ) : (
          rearrangeableInner ? (
            <LargeRow table={table}>
              <Text color="secondary" alignment="center">
                {translate.rearrangeableNoDataMessage}
              </Text>
            </LargeRow>
          ) : (
            <LargeRow table={table}>
              <div className={styles.noDataContent}>
                <SearchIcon/>
                {translate.noDataMessage}
              </div>
            </LargeRow>
          )
        )
      )}
    </tbody>
  );
};

interface TBodyRowsProps<TData> {
  table: Table<TData>;
  emptyRows?: number;
  exportRowFn: ExportRowCb<TData>;
}

const TBodyRows = <TData extends RowData>({
  table,
  emptyRows,
  exportRowFn
}: TBodyRowsProps<TData>) => {
  return (
    <>
      {table.getRowModel().rows.map(row => {
        const rowHasError = table.options.meta?.getRowHasError?.({ row });
        const rowIsDisabled = table.options.meta?.getRowIsDisabled?.({ row });
        return (
          <tr
            key={row.id}
            aria-disabled={rowIsDisabled}
            className={classNames(styles.tr, {
              [styles.isError]: rowHasError,
              [styles.isDisabled]: rowIsDisabled,
              [styles.isSelected]: row.getIsSelected()
            })}
          >
            {row.getVisibleCells().map(cell => {
              const cellIsError = cell.column.columnDef.meta?.getCellHasError?.({ row, cell });
              const cellIsDisabled = cell.column.columnDef.meta?.getCellIsDisabled?.({ row });
              const cellAttributes = cell.column.columnDef.meta?.getCellAttributes?.({ row, cell });
              const isEmpty = cell.column.columnDef.meta?.emptyWhen?.({ row: row.original });

              const style: CSSProperties = getCommonCellStyles({
                table,
                column: cell.column
              });

              function renderCellContent() {
                if (isEmpty) {
                  return <Blank/>;
                }

                const content = flexRender(
                  cell.column.columnDef.cell,
                  Object.assign(cell.getContext(), {
                    downloadRow: exportRowFn(cell.row.original, cell.row.id)
                  })
                );

                const validChildTypes = React.Children.map(content, (child) => {
                  return React.isValidElement(child)
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    && (typeof child.type === 'string' ? child.type : child.type(child.props));
                });

                const isNullOrUndefinedOrEmptyString = (value) => value === null || value === undefined || value === '';
                if (
                  !Array.isArray(validChildTypes)
                  || validChildTypes.length === 0
                  || validChildTypes.every(isNullOrUndefinedOrEmptyString)
                ) {
                  return <Blank/>;
                }

                return content;
              }

              return (
                <td
                  key={cell.id}
                  {...cellAttributes}
                  style={style}
                  aria-disabled={cellIsDisabled}
                  className={classNames(styles.td, {
                    [styles.expand]: cell.column.id === EXPANDING_COLUMN_ID,
                    [styles.select]: cell.column.id === SELECT_COLUMN_ID,
                    [styles.isDisabled]: cellIsDisabled,
                    [styles.isError]: cellIsError
                  })}
                >
                  {renderCellContent()}
                </td>
              );
            })}
          </tr>
        );
      })}
      {typeof emptyRows === 'number' && (
        new Array(emptyRows).fill(null).map((_, index) => (
          <tr key={index} className={styles.tr}>
            <td
              colSpan={table.getVisibleFlatColumns().length}
              className={styles.td}
            />
          </tr>
        ))
      )}
    </>
  );
};

interface EmptyRowProps<TData> {
  table: Table<TData>;
  children: ReactNode;
  className?: string;
}

const LargeRow = <TData extends RowData>({
  table,
  children,
  className
}: EmptyRowProps<TData>) => {
  const colSpan = table.getVisibleFlatColumns().length;
  const { setShowHorizontalScroll } = useTableContext();

  useEffect(() => {
    setShowHorizontalScroll(false);
    return () => {
      setShowHorizontalScroll(true);
    };
  }, []);

  return (
    <tr className={styles.LargeRow}>
      <td colSpan={colSpan} className={className}>
        {children}
      </td>
    </tr>
  );
};
