// https://hoangtrinhj.com/using-google-analytics-with-next-js

export function gaPageView(url: Gtag.ConfigParams['page_path'], GOOGLE_ANALYTICS_ID: string) {
  // Google Analytics is not set up in development mode.
  if (window.gtag) {
    // https://developers.google.com/analytics/devguides/collection/gtagjs/pages
    window.gtag('config', GOOGLE_ANALYTICS_ID, {
      page_path: url
    });
  }
}

interface GaEventOptions {
  action: Gtag.EventNames | (string & NonNullable<unknown>);
  category?: Gtag.EventParams['event_category'];
  label: Gtag.EventParams['event_label'];
  value?: Gtag.EventParams['value'];
}

export function gaEvent({ action, category, label, value }: GaEventOptions) {
  // Google Analytics is not set up in development mode.
  if (window.gtag) {
    // https://developers.google.com/analytics/devguides/collection/gtagjs/events
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value
    });
  }
}
