import React from 'react';
import PropTypes from 'prop-types';

import { Button, FormFooter, Sticky } from '@acadeum/ui';
import { useTranslate } from '@acadeum/translate';

import './StickyFooter.sass';

const StickyFooter = ({
  onCancelProps,
  onBackProps,
  submitProps
}) => {
  const t = useTranslate('StickyFooter');

  return (
    <Sticky position="bottom">
      {({ isSticky }) => (
        <FormFooter marginTop={isSticky ? 'none' : undefined}>
          <>
            <Button
              className="StickyFooter__cancel"
              variant="text"
              url={onCancelProps.url}
              onClick={onCancelProps?.onClick}
            >
              {t('cancel', { global: true })}
            </Button>
            <Button
              className="StickyFooter__back"
              variant="secondary"
              url={onBackProps.url}
              onClick={onBackProps?.onClick}
            >
              {t('back', { global: true })}
            </Button>

            <Button
              type={submitProps?.onSubmit ? undefined : 'submit'}
              onClick={submitProps?.onSubmit}
            >
              {submitProps.children || t('submit', { global: true })}
            </Button>
          </>
        </FormFooter>
      )}
    </Sticky>
  );
};

StickyFooter.propTypes = {
  onCancelProps: PropTypes.shape({
    url: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  }),
  onBackProps: PropTypes.shape({
    url: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  }),
  submitProps: PropTypes.shape({
    onSubmit: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  })
};

export default StickyFooter;
