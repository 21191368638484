import PropTypes from 'prop-types';

import styles from '../ButtonGroup.module.scss';

export const Item = ({ button }) => {
  return (
    <div className={styles.Item}>
      {button}
    </div>
  );
};

Item.propTypes = {
  button: PropTypes.node.isRequired
};
