import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const createCourseSection = redux.action(
  (courseId, values) => (http) => {
    return http.post(`/courses/${courseId}/sections`, values);
  }
);

export const updateCourseSection = redux.action(
  (id, values) => http => {
    return http.patch(`/course-sections/${id}`, values);
  }
);

export default redux.reducer();
