import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import PageLink from './PageLink';

import { CircleExclamationIcon } from '@acadeum/icons';

import './Tab.sass';

export default function Tab({
  link: to,
  alias,
  location,
  count,
  icon: Icon,
  showWarning,
  notificationsCount,
  children
}) {
  if (showWarning) {
    Icon = CircleExclamationIcon;
  }
  return (
    <div className="SecondaryNav__section-link Tab">
      {Icon &&
        <div className={classNames('SecondaryNav__icon', {
          'SecondaryNav__icon--warning': showWarning
        })}>
          <Icon/>
        </div>
      }
      <PageLink
        to={to}
        alias={alias}
        location={location}>
        {children}
      </PageLink>
      {count > 0 &&
        <div className="SecondaryNav__section-counter">
          {count}
        </div>
      }
      {notificationsCount > 0 &&
        <div className="SecondaryNav__section-notifications-counter">
          {notificationsCount}
        </div>
      }
    </div>
  );
}

Tab.propTypes = {
  link: PropTypes.string.isRequired,
  alias: PropTypes.string,
  location: PropTypes.object.isRequired,
  count: PropTypes.number,
  icon: PropTypes.elementType,
  showWarning: PropTypes.bool,
  notificationsCount: PropTypes.number,
  children: PropTypes.node.isRequired
};
