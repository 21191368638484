import { userHasPermission } from '@acadeum/helpers';
import { UserRolesPage } from '@acadeum/shared-admin-ui';

import { useDuplicateUserRoleMutation, useFetchUserRolesQuery } from '../../api/userRoles';
import { useFetchUsersQuery, useSetUsersRolesForUsersMutation } from '../../api/users';
import type { AppPage } from '../../helpers/app.types';

const UserRoleManagement: AppPage = () => {
  return (
    <UserRolesPage
      useSetUsersRolesForUsersMutation={useSetUsersRolesForUsersMutation}
      useFetchUserRolesQuery={useFetchUserRolesQuery}
      useDuplicateUserRoleMutation={useDuplicateUserRoleMutation}
      useFetchUsersQuery={useFetchUsersQuery}
    />
  );
};

UserRoleManagement.meta = () => ({
  title: 'Role Management'
});

UserRoleManagement.when = ({ user }) => userHasPermission(user, 'userRole:read', {
  orgId: user?.institution.id,
  ownerId: null
});

export default UserRoleManagement;
