import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function Icon({ name, className }) {
  return <i className={classNames('fa', `fa-${name}`, className)} aria-hidden="true" />;
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string
};
