// These are Redux helpers for storing authentication state in Redux state.

import type { ExpiresAt, ExpiresIn, Token } from './types';

// In every project, a User should be defined inside `store/auth`.
// Example of how to declare the User:
//
// declare module '@acadeum/auth' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface CurrentUser extends UserProfile {
//   }
// }

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CurrentUser {
}

export interface AuthState {
  isAuthenticationLoading: boolean;
  isLoggingIn: boolean;
  user?: CurrentUser;
  accessToken?: Token;
  accessTokenExpiresAt?: ExpiresAt;
  accessTokenExpiresIn?: ExpiresIn;

  // It's additional state that could be added by extraReducers.
  isSigningUp?: boolean;
  isSigningOut?: boolean;
  isResettingPassword?: boolean;
  isPasswordResetTokenBeingValidated?: boolean;
}

export function getAuthenticationStartState() {
  return {
    isAuthenticationLoading: true,
    isLoggingIn: false
  };
}

export function getLogInStartState() {
  return {
    isAuthenticationLoading: false,
    isLoggingIn: true
  };
}

// `getLogInSuccessState()` function is used for getting a state
// when a user has successfully logged in.
export function getLogInSuccessState({ user, accessTokenExpiresAt }: Pick<AuthState, 'user' | 'accessTokenExpiresAt'>) {
  return {
    user,
    // The state stores "expires at" property rather than "expires in" property
    // because `<AccessTokenExpiryWatcher/>` watches "expires at" state property.
    // If it was watching "expires in" property rather than "expires at" property,
    // then the Redux state hook wouldn't detect any changes in case of token refresh
    // because the "expires in" interval would stay the same.
    accessTokenExpiresAt,
    isAuthenticationLoading: false,
    isLoggingIn: false
  };
}

export function getNotAuthenticatedState() {
  return {
    isAuthenticationLoading: false,
    isLoggingIn: false
  };
}

// `actionPayload` should be the updated `user` object.
export function getUserUpdatedState(state, actionPayload: Partial<AuthState['user']>) {
  return {
    ...state,
    user: actionPayload
  };
}

// `actionPayload` should be `accessTokenExpiresAt` value.
export function getAccessTokenExpiresAtUpdatedState(state, actionPayload: AuthState['accessTokenExpiresAt']) {
  return {
    ...state,
    accessTokenExpiresAt: actionPayload
  };
}

// `actionPayload` should be `isAuthenticationLoading` value.
export function getIsAuthenticationLoadingUpdatedState(state, actionPayload: AuthState['isAuthenticationLoading']) {
  return {
    ...state,
    isAuthenticationLoading: actionPayload
  };
}
