import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function Outputs({ children, section }) {
  return (
    <div
      className={classNames('outputs', {
        'outputs--section': section
      })}>
      {children}
    </div>
  );
}

Outputs.propTypes = {
  children: PropTypes.node.isRequired,
  section: PropTypes.bool
};
