import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const fetchCourseSharingGroups = redux.action(
  () => http => http.get('/course-sharing-groups'),
  'courseSharingGroups'
);

export const fetchCourseSharingGroup = redux.action(
  (id) => http => http.get(`/course-sharing-groups/${id}`),
  'courseSharingGroup'
);

export default redux.reducer();
