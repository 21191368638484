import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import type { ToastId, ToastList, ContainerInstance } from '../../utils/types';
import { onAddToast, onHideToast, onUpdateToast } from '../../utils/toastObserver';

import { Toast } from '../Toast';

import styles from './ToastContainer.module.scss';

export interface ToastContainerProps {
  autoClose?: number | false;
}

export const ToastContainer = memo<ToastContainerProps>(({
  autoClose = 5000
}) => {
  const toasts = useToastContainer({ autoClose });

  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      className={classNames(styles.ToastContainer)}
    >
      {toasts.map(({ key, ...toastProps }) => (
        <Toast key={`toast-${key}`} {...toastProps}/>
      ))}
    </div>
  );
});

function useToastContainer({ autoClose }) {
  const instance = useRef<ContainerInstance>({
    toastKey: 1
  });

  const [toasts, setToasts] = useState<ToastList>([]);

  const deleteToast = useCallback((id?: ToastId) => {
    setToasts((prevList) => prevList.filter((toast) => toast.id !== id));
  }, []);

  useEffect(() => onAddToast(
    (newToast) => setToasts((prevList) => [
      ...prevList,
      { ...newToast, key: instance.current.toastKey++ }
    ])
  ), []);
  useEffect(() => onUpdateToast(
    (newToast) => setToasts((prevState) => prevState.map((item) => {
      return item.id === newToast.id ? { ...item, ...newToast } : item;
    }))
  ), []);
  useEffect(() => onHideToast(
    (toastId) => setToasts((prevState) => prevState.map((item) => {
      return item.id === toastId ? { ...item, autoClose: 0 } : item;
    }))
  ), []);

  return useMemo(() => {
    return toasts.map(_ => ({
      ..._,
      autoClose: typeof _.autoClose !== 'undefined' ? _.autoClose : autoClose,
      deleteToast: () => deleteToast(_.id),
      children: typeof _.content === 'function' ? _.content({ data: _.data }) : _.content
    }));
  }, [toasts]);
}
