import { OnDemandIcon } from '@acadeum/icons';
import { Tag, Tooltip } from '@acadeum/ui';

export const OnDemandBadge = () => {
  return (
    <Tooltip content="Students access materials at their own pace, whenever is most convenient for them">
      <Tag variant="purple-light" icon={OnDemandIcon}>
        On-Demand
      </Tag>
    </Tooltip>
  );
};
