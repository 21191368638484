if (process.env.NODE_ENV !== 'production') {
  // Self-test.
  setTimeout(() => import('./test.js'), 0);
}

// Using `require()` maintains CSS style files order here:
// first include base styles on a page,
// after that apply component styles on top of the base styles.
//
// If the order was different, styles could be applied incorrectly.
//
// Could replace `require()` with `import` after moving all style `import`s
// from component files to a single `components.sass` file.
// https://github.com/Acadeum/Tickets/issues/1009
//
import './styles/style.sass';
import './index.js';

// * Fixes a warning in the console:
//   "Selectors that return the entire state are almost certainly a mistake".
//   The reason for that warning is that `Auth.js` component uses `useState(state => state)`
//   in order to be able to pass a `useSelector()` function to `Component.meta()`.
//
const originalConsoleWarn = console.warn;
console.warn = (...args) => {
  const arg1 = args[0];
  const arg2 = args[1];
  // if (typeof arg1 === 'string' && arg1.includes('Selector unknown returned the root state when called') && typeof arg2 === 'string' && arg2.includes('Auth.js')) {
  //   return;
  // }
  if (
    (typeof arg1 === 'string' && arg1.includes('Selector unknown returned the root state when called')) &&
    (typeof arg2 === 'string' && (arg2.includes('Auth.js') || arg2.includes('MetaUpdater.js')))
  ) {
    return;
  }
  // Fixed via `sassOptions: { quietDeps: true }` in Webpack loader configuration.
  // // * Fixes a warning in the console:
  // //   "Deprecation Using / for division outside of calc() is deprecated and will be removed in Dart Sass 2.0.0."
  // //   "..\..\node_modules\react-credit-cards\lib\styles.scss 84:22  @import"
  // if (typeof arg1 === 'string' && arg1.includes('WARNING in ./src/styles/style.sass') && arg1.includes('react-credit-cards')) {
  //   return;
  // }
  // Fixed via `sassOptions: { quietDeps: true }` in Webpack loader configuration.
  // // * Fixes a warning in the console:
  // //   "Deprecation $weight: Passing a number without unit % (100) is deprecated."
  // //   "..\..\node_modules\bootstrap\scss\_functions.scss 201:11            opaque()"
  // if (typeof arg1 === 'string' && arg1.includes('WARNING in ./src/styles/style.sass') && arg1.includes('bootstrap')) {
  //   return;
  // }
  return originalConsoleWarn.apply(console, args);
};
