import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const createUserAccountRequest = redux.action(
  (values) => (http) => http.post('/user-account-requests', values)
);

export const fetchUserAccountRequest = redux.action(
  (id) => http => http.get(`/user-account-requests/${id}`),
  'request'
);

export const fetchUserAccountRequests = redux.action(
  (parameters) => http => http.get('/user-account-requests', parameters),
  'requests'
);

export const approveUserAccountRequests = redux.action(
  (values) => (http) => http.post('/user-account-requests/approve', values)
);

export const denyUserAccountRequests = redux.action(
  (values) => (http) => http.post('/user-account-requests/deny', values)
);

export default redux.reducer();
