import { Text } from '@acadeum/ui';

import styles from './CourseDescription.module.scss';

export function CourseDescription({
  course
}) {
  return (
    <div>
      <Text as="h3" mb="sm" variant="subtitle1" color="black">
        Description
      </Text>
      {course.description && (
        <Text split color="grey" className={styles.content}>
          {course.description}
        </Text>
      )}
    </div>
  );
}
