import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { InstitutionLink, StepsProgress, SuccessModal } from '@acadeum/ui';
import { useStep } from '@acadeum/hooks';
import { useTranslate, useTranslateDictionary } from '@acadeum/translate';

import Section from '../../components/Section';

import CourseOverview from './ui/CourseOverview';
import AddStudents from './ui/AddStudents';
import Summary from './ui/Summary';

import actions from '../../actions';

import './CreateEnrollment.sass';

const {
  fetchSection,
  fetchStudents,
  fetchCourseSubstitutes,
  resetEnrollmentState,
  setStudents,
  goto
} = actions;

export default function CreateEnrollment() {
  const section = useSelector(state => state.sections.section);
  const enrollmentState = useSelector(state => state.enroll.enrollmentState);

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const t = useTranslate('CreateEnrollment');

  const STEPS = [
    {
      id: 'courseOverview',
      component: CourseOverview
    },
    {
      id: 'enrollStudents',
      component: AddStudents
    },
    {
      id: 'summary',
      component: Summary
    }
  ];

  const steps = STEPS;
  const { step, navigation } = useStep({
    initialStep: enrollmentState ? enrollmentState.currentStepId : 0,
    steps: steps
  });
  const STEPS_IDS = steps.map(step => step.id);

  const props = { navigation, setShowSuccessModal };

  const onHideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <>
      <Section mb="sm" title={t('title', { courseCode: section.course.code, courseTitle: section.course.title })}>
        <InstitutionLink
          className="CreateEnrollment__institutionLink"
          includeLogo
          monochrome
          institution={section.course.institution}
        />
        <StepsProgress
          step={step.id}
          steps={STEPS_IDS}
          stepNames={useTranslateDictionary('CreateEnrollment.steps', STEPS_IDS)}
          riftNumber={3}
          mb="lg"
        />
        {step && <step.component {...props}/>}
      </Section>

      <SuccessModal
        show={showSuccessModal}
        size="narrow"
        onHide={onHideSuccessModal}
        action={{
          content: t('successModal.button'),
          onClick: () => goto('/enrollments/live')
        }}
      >
        {t('successModal.message')}
      </SuccessModal>
    </>
  );
}

CreateEnrollment.meta = () => ({
  title: 'Course Enrollment'
});

CreateEnrollment.breadcrumbs = (state) => [
  ['Course', `/courses/${state.sections.section.session.course.id}`],
  ['Section', `/sections/${state.sections.section.id}`],
  'Course Enrollment'
];

CreateEnrollment.loadStateParameters = {
  enrollmentState: state => state.enroll.enrollmentState
};

CreateEnrollment.load = async ({ user, state, params: { sectionId } }) => {
  sectionId = parseInt(sectionId);
  const { enrollmentState } = state;
  // If a user manually navigated to this second step of student enrollment process
  // without completing the first step before that then redirect to the home page.
  if (enrollmentState && enrollmentState.sectionId !== sectionId) {
    resetEnrollmentState();
  }
  const section = await fetchSection(sectionId, { user });
  await fetchCourseSubstitutes(section.session.course.id);
  setStudents((await fetchStudents({ pageSize: 100000000000 })).results);
};
