import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Auth from '../../components/Auth';
import VisitorLayout from '../../components/VisitorLayout';
import AcadeumBackground from '../../components/AcadeumBackground';

import Home from '../Home';

import { useUpdateEffect } from '@acadeum/hooks';

import './Title.sass';

const HomePage = Auth(Home, {
  margin: false,
  UnauthenticatedComponent: TitlePage
});

export default function Title({
  isPasswordResetLink,
  isAccessTokenInUrl
}) {
  const user = useSelector(state => state.auth.user);

  const [showHomePage, setShowHomePage] = useState(
    isPasswordResetLink !== undefined &&
    isAccessTokenInUrl !== undefined &&
    user
      ? true
      : undefined
  );

  // This part is written using properties rather than `useQueryParameters()`
  // because otherwise components like `<ResetPassword/>` would run their
  // `useQueryParameters()` hooks and strip the relevant query parameters
  // from the URL, and this component wouldn't see those URL query parameters.
  useEffect(() => {
    if (isPasswordResetLink !== undefined && isAccessTokenInUrl !== undefined) {
      setShowHomePage(!(isPasswordResetLink || isAccessTokenInUrl));
    }
  }, [isPasswordResetLink, isAccessTokenInUrl]);

  // If the user gets authenticated either when finishing a password reset procedure
  // or when a non-expired `accessToken` is passed, then start showing the Home page.
  useUpdateEffect(() => {
    // Doesn't show Home page on `!user`
    // because that scenario happens not only when a user logs out
    // but also when `isPasswordResetLink` is `true`.
    if (user) {
      setShowHomePage(true);
    }
  }, [user]);

  // If `showHomePage` is `undefined` (hasn't initialized yet)
  // then show an empty page.
  if (showHomePage === undefined) {
    return null;
  }

  if (showHomePage) {
    return <HomePage/>;
  }

  return (
    <VisitorLayout margin={false}>
      <TitlePage/>
    </VisitorLayout>
  );
}

Title.propTypes = {
  isPasswordResetLink: PropTypes.bool,
  isAccessTokenInUrl: PropTypes.bool
};

// There shouldn't be any `meta()` function defined on the `Title` page component.
// The reason is that this page component uses `Auth()` function to wrap a page content component
// and `Auth()` function requires the `Meta` property to be passed to `<RequiresLogin/>` component
// when `meta` is defined on the page content component.

function TitlePage() {
  return (
    <AcadeumBackground>
      <div className="TitlePage container">
        <h1 className="TitlePage__title">
          Acadeum Course Share
        </h1>

        <p className="TitlePage__subtitle">
          Academic Sharing Platform
        </p>
      </div>
    </AcadeumBackground>
  );
}
