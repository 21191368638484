import React from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from '@acadeum/translate';

import Button from '../Button';

import actions from '../../actions';

const {
  setShowRequestMoreInfoModal
} = actions;

export default function RequestMoreInfoButton({
  onClick,
  ...rest
}) {
  const t = useTranslate('RequestMoreInfoButton');

  return (
    <Button
      border
      secondary
      onClick={async () => {
        if (onClick) {
          await onClick();
        }
        setShowRequestMoreInfoModal(true);
      }}
      {...rest}>
      {t('title')}
    </Button>
  );
}

RequestMoreInfoButton.propTypes = {
  onClick: PropTypes.func
};
