import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ActivityIndicator } from 'react-responsive-ui';
import flatten from 'lodash/flatten';

import { getCourseSectionCostWithDetails } from '@acadeum/helpers';

import Link from '../../components/Link';
import Field from '../../components/Field';
import Form from '../../components/Form';
import Table from '../../components/Table';
import FormHeading from '../../components/FormHeading';
import FormButtons from '../../components/FormButtons';
import FormSubmit from '../../components/FormSubmit';

import { formatCurrency } from '../../helpers/format';

import './ApprovedCourses.sass';

import actions from '../../actions';

const {
  getApprovedCoursesWithPricing
} = actions;

export default function ApprovedCourses() {
  const form = useRef();

  const user = useSelector(state => state.auth.user);

  const [rows, setRows] = useState();

  async function onGetApprovedCoursesWithPricingOfInstitution({ institutionId }) {
    setRows(null);

    const {
      courses,
      homeStudentCourseEnrollmentPricing,
      courseEnrollmentPricing
    } = await getApprovedCoursesWithPricing(institutionId);

    // These values could also be fetched from API in real time.
    // For now, they're just read from the (potentially stale) cached values in `user.institution`.
    const courseEnrollmentPricingConsortia = user && user.institution.courseEnrollmentPricingConsortia;
    const homeInstitutionTeachingInstitutionRelationshipIds = user && user.institution.homeInstitutionTeachingInstitutionRelationshipIds;
    const homeConsortiumTeachingInstitutionRelationships = user && user.institution.homeConsortiumTeachingInstitutionRelationships;

    const rows = flatten(courses.map((course) => {
      const { code, institution, sessions } = course;

      return flatten(sessions.map((session) => {
        return session.sections.map((section) => {

          const {
            isCustomCost,
            costForInstitution,
            costForStudent,
            homeInstitutionPricing,
            homeInstitutionPricingFilters,
            homeInstitutionPricingConsortium,
            homeStudentPricing,
            homeStudentPricingFilters
          } = getCourseSectionCostWithDetails({
            cost: session.cost,
            course: {
              credits: course.hours,
              level: course.level,
              teachingInstitutionId: course.institution.id
            },
            courseCustomCosts: {
              customCostByHomeInstitutionRelationshipId: course.customCostByHomeInstitutionRelationshipId,
              customCostByHomeConsortiumRelationshipId: course.customCostByHomeConsortiumRelationshipId
            },
            courseSectionCustomCosts: {
              // `section.customCosts` is a legacy property name for `section.customCostByHomeInstitutionRelationshipId`.
              customCostByHomeInstitutionRelationshipId: section.customCostByHomeInstitutionRelationshipId || section.customCosts,
              customCostByHomeConsortiumRelationshipId: section.customCostByHomeConsortiumRelationshipId
            },
            courseEnrollmentPricing,
            courseEnrollmentPricingConsortia,
            homeStudentCourseEnrollmentPricing,
            homeInstitutionTeachingInstitutionRelationshipIds,
            homeConsortiumTeachingInstitutionRelationships,
            homeInstitutionId: user.institution.id
          });

          return {
            sectionId: section.id,
            code,
            institution,
            session: session.name,
            term: session.term,
            costForInstitution,
            costForStudent,
            homeInstitutionPricingCustomCost: isCustomCost,
            homeInstitutionPricing,
            homeInstitutionPricingFilters,
            homeInstitutionPricingConsortiumId: homeInstitutionPricingConsortium && homeInstitutionPricingConsortium.id,
            homeStudentPricing,
            homeStudentPricingFilters
          };
        }).filter(_ => _);
      }));
    }));
    setRows(rows);
  }

  const onInstitutionChange = () => {
    form.current.submit();
  };

  return (
    <section className="approved-courses-page">
      <FormHeading>
        View Approved Courses
      </FormHeading>

      <Form
        autoFocus
        className="form"
        ref={form}
        onSubmit={onGetApprovedCoursesWithPricingOfInstitution}>

        <Field
          required
          name="institutionId"
          label="Home Institution"
          type="institution"
          superAdmin
          onChange={onInstitutionChange}
        />

        <FormButtons style={{ display: 'none' }}>
          <FormSubmit>
            Show Approved Courses
          </FormSubmit>
        </FormButtons>
      </Form>

      <div className="approved-courses-page__table-container">
        {rows === undefined &&
          'Select an institution'
        }
        {rows === null &&
          <ActivityIndicator/>
        }
        {rows &&
          <Table
            selectableRows={false}
            columns={COLUMNS}
            data={rows}/>
        }
      </div>
    </section>
  );
}

ApprovedCourses.meta = () => ({
  title: 'View Approved Courses'
});

ApprovedCourses.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'View Approved Courses'
];

const COLUMNS = [
  {
    title: 'TEACHING INSTITUTION',
    content: row => row.institution.id + ' ' + row.institution.name
  },
  {
    title: 'CODE',
    content: row => (
      <Link target="_blank" to={`/sections/${row.sectionId}`}>
        {row.code}
      </Link>
    )
  },
  {
    title: 'SESSION',
    content: row => row.session
  },
  {
    title: 'TERM',
    content: row => row.term
  },
  {
    title: 'HI COST',
    content: row => (
      <Pricing
        cost={row.costForInstitution}
        customCost={row.homeInstitutionPricingCustomCost}
        pricing={row.homeInstitutionPricing}
        filters={row.homeInstitutionPricingFilters}
        consortiumId={row.homeInstitutionPricingConsortiumId}
        defaultPricingValue={'as-is'}
      />
    )
  },
  {
    title: 'STUDENT COST',
    content: row => (
      <Pricing
        cost={row.costForStudent}
        pricing={row.homeStudentPricing}
        filters={row.homeStudentPricingFilters}
        defaultPricingValue={'hide'}
      />
    )
  }
];

// function formatFilterValue(value) {
//   if (Array.isArray(value)) {
//     return value.join(', ');
//   }
//   return value;
// }

function Pricing({
  cost,
  customCost,
  pricing,
  filters,
  consortiumId,
  defaultPricingValue
}) {
  return (
    <>
      <div style={{ textTransform: 'uppercase', letterSpacing: '2px', fontSize: '0.75rem', color: '#7f7f7f' }}>
        cost
      </div>
      <span style={{ fontWieght: 'bolder' }}>
        {formatCurrency(cost)}
      </span>
      <br/>
      <br/>
      <div style={{ textTransform: 'uppercase', letterSpacing: '2px', fontSize: '0.75rem', color: '#7f7f7f' }}>
        pricing
      </div>
      {pricing && (
        <div>
          <code>
            {pricing.model}
          </code>
          {pricing.value &&
            <>
              <span style={{ display: 'inline-block', width: '0.5em' }}/>
              <code style={{ color: '#cc0000' }}>
                {pricing.value}
              </code>
            </>
          }
        </div>
      )}
      {!pricing && customCost && (
        <div>
          <code>
            differential
          </code>
        </div>
      )}
      {!pricing && !customCost && (
        <div>
          <code style={{ color: '#7f7f7f' }}>
            default
            <span style={{ display: 'inline-block', width: '0.5em' }}/>
          </code>
          <code>
            {defaultPricingValue}
          </code>
        </div>
      )}
      {filters && filters.length > 0 && (
        <>
          <br/>
          <div style={{ textTransform: 'uppercase', letterSpacing: '2px', fontSize: '0.75rem', color: '#7f7f7f' }}>
            filters
          </div>
          {filters.map((filter, i) => (
            <div key={i}>
              <code>
                {filter.name}:
              </code>
              <span style={{ display: 'inline-block', width: '0.5em' }}/>
              <code style={{ color: '#cc0000' }}>
                {filter.value}
              </code>
            </div>
          ))}
        </>
      )}
      {consortiumId &&
        <>
          <br/>
          <div style={{ textTransform: 'uppercase', letterSpacing: '2px', fontSize: '0.75rem', color: '#7f7f7f' }}>
            Consortium ID
          </div>
          <div>
            <code style={{ color: '#cc0000' }}>
              {consortiumId}
            </code>
          </div>
        </>
      }
    </>
  );
}
