import React, { memo } from 'react';

import type { UserRole } from '@acadeum/types';

import { UserRolePermissionsTable } from '../../ui/UserRolePermissionsTable';

interface PermissionTabProps {
  role: UserRole;
}

const PermissionTab = memo<PermissionTabProps>(({
  role: userRole
}) => {
  return (
    <UserRolePermissionsTable userRole={userRole}/>
  );
});

PermissionTab.displayName = 'PermissionTab';

export default PermissionTab;
