import React, { useState } from 'react';
import type { FC } from 'react';
import classNames from 'classnames';
import Dropdown from 'react-bootstrap/Dropdown';

import { Button } from '@acadeum/ui';
import type { ButtonProps } from '@acadeum/ui';

import styles from './DropdownContainer.module.scss';

interface DropdownContainerProps {
  title: string;
  className?: string;
  children: React.ReactNode;
}

const DropdownContainer: FC<DropdownContainerProps> = ({
  title,
  className,
  children
}) => {

  const [show, setShow] = useState<boolean>();

  const handleToggle = () => {
    setShow(preventDefault => !preventDefault);
  };
  return (
    <Dropdown show={show} onToggle={handleToggle} className={className}>
      <Dropdown.Toggle children={title} show={show} as={Toggle}/>
      <Dropdown.Menu className={styles.DropdownContainer__menu}>
        {children}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownContainer;

interface ToggleProps extends ButtonProps {
  show?: boolean;
}

const Toggle = React.forwardRef<HTMLButtonElement, ToggleProps>(({
  className,
  show = false,
  children,
  ...rest
}, ref) => {
  return (
    <Button
      ref={ref}
      {...rest}
      className={classNames(styles.DropdownContainer__button, { [styles.active]: show }, className)}
      variant="text"
      children={children}
    />
  );
});
