import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Separator } from '@acadeum/ui';
import { userHasPermission } from '@acadeum/helpers';

import Row from '../Row';
import Col from '../Col';

import { canEnrollInCourse, canEnrollInSection, getCourseCustomSchedule } from '../../helpers/course';

import { CourseHeader } from './ui/CourseHeader/CourseHeader';
import { CourseInfo } from './ui/CourseInfo/CourseInfo';
import { MultipleCourseSections } from './ui/MultipleCourseSections/MultipleCourseSections';

import actions from '../../actions';
import { SingleCourseSection } from './ui/SingleCourseSection/SingleCourseSection';

const {
  fetchCourse,
  fetchSection,
  refreshCourseEnrollmentPricingIfRequired,
  resetAdvisorRecommendationCreationState,
  goto
} = actions;

const Course = ({
  course,
  section
}) => {
  const user = useSelector(state => state.auth.user);

  const isCoursePage = !section;
  const schedule = user && course.customSchedules && getCourseCustomSchedule(course, user.institution.id);

  // Reload course info when the user gets authenticated.
  // The user doesn't get authenticated immediately, so the course or section page
  // initially loads course info from the "public" index which doesn't contain any pricing.
  // When the user authentication process finishes, the `user` object will be defined
  // and this "effect" will re-fetch course info, now from the non-"public" index
  // which also includes prices.
  // Without this effect, the course / section price wouldn't be shown when a user
  // navigates to this page URL directly or refreshes the page.
  // https://github.com/Acadeum/Tickets/issues/1508
  useEffect(() => {
    function loadScheduleAndPricing() {
      if (course.institution.id !== user.institution.id) {
        // Refresh course pricing model if the refresh time interval has passed.
        // If the refresh time interval hasn't passed yet, ignore and use the cached pricing.
        // Doesn't use `await` here because there's no need to wait for it to finish.
        // The pricing is supposed to be updated quickly and the course price will update itself
        // when it detects that the pricing has changed in Redux state.
        refreshCourseEnrollmentPricingIfRequired();
      }
      // If the course / section page was opened "from scratch" in a web browser
      // rather then by clicking a link to a course / section in Course Search,
      // then the `load()` function of that page will load a "public" version
      // of the course / section — NBSP the one without the `cost`s.
      // After the initial page load, the user gets authenticated, and now
      // it's time to re-fetch the course / section from the non-public index
      // that doesn't exclude `cost`s.
      if (section) {
        if (section.session.cost === undefined) {
          fetchSection(section.id, { user });
        }
      } else {
        if (course.sessions.length > 0) {
          if (course.sessions[0].cost === undefined) {
            fetchCourse(course.id, { user });
          }
        }
      }
      // // Fetch course approval, if the course is approved.
      // // This may occasionally be out of sync
      // // because Algolia is not immediately synced
      // // with the SQL database, so in very rare cases
      // // it might throw a "Course not found" error
      // // (though very unlikely, I guess).
      // if (isCourseApprovedWithCustomSchedule(course, user.institution.id)) {
      //   // Course approval is fetched asynchronously for better UX.
      //   fetchCourseApproval(course.id);
      // }
    }
    if (user) {
      loadScheduleAndPricing();
    }
  }, [user]);

  const canEdit = user && user.institution.id === course.institution.id && userHasPermission(user, 'course:update', {
    orgId: course.institution.id
  });

  const canRecommend = user
    && user.institution.studentApp
    && (section ? canEnrollInSection(section, course) : canEnrollInCourse(course))
    && userHasPermission(user, 'homeCourseAdvisorRecommendation:create', {
      orgId: user.institution.id
    });

  const onRecommend = () => {
    resetAdvisorRecommendationCreationState();
    goto(`/courses/${course.id}/recommend`);
  };

  return (
    <div>
      <CourseHeader
        course={course}
        section={section}
        canEdit={canEdit}
        onRecommend={canRecommend ? onRecommend : undefined}
      />
      <Separator/>
      <Row>
        <Col col={6} xs={12}>
          <CourseInfo
            course={course}
            schedule={schedule}
          />
        </Col>

        <Col col={6} xs={12}>
          {isCoursePage ? (
            <MultipleCourseSections
              course={course}
              onRecommend={canRecommend ? onRecommend : undefined}
            />
          ) : (
            <SingleCourseSection
              section={section}
              course={course}
              onRecommend={canRecommend ? onRecommend : undefined}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Course;
