import type { FetchBackupPaymentSourceOutput, FetchPrimaryPaymentSourceOutput } from '@acadeum/api';

export function getCreditCardStatuses(
  backupPaymentSource?: FetchBackupPaymentSourceOutput,
  primaryPaymentSource?: FetchPrimaryPaymentSourceOutput
) {
  const statuses: FetchBackupPaymentSourceOutput['status'][] = [];
  if (backupPaymentSource) {
    statuses.push(backupPaymentSource.status);

    if (backupPaymentSource.status === 'ACTIVE' && !(primaryPaymentSource && primaryPaymentSource.current.status === 'ACTIVE')) {
      // If bank account is not active, credit card is used by default
      statuses.push('DEFAULT');
    }
  }
  return statuses;
}
