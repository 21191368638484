import { createContext } from 'react';
import type { FC, ReactNode } from 'react';

import type { LinkLikeComponent } from './types';

export const LinkContext = createContext<LinkLikeComponent | undefined>(undefined);

interface LinkProviderProps {
  linkComponent: LinkLikeComponent;
  children: ReactNode;
}

export const LinkProvider: FC<LinkProviderProps> = ({
  linkComponent,
  children
}) => {
  return (
    <LinkContext.Provider value={linkComponent}>
      {children}
    </LinkContext.Provider>
  );
};
