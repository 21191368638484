export function activateIntercomWidget(user) {
  let company;
  if (user.email.includes('@collegeconsortium.org') || user.email.includes('@acadeum.com')) {
    company = {
      company_id: 48
    };
  } else {
    company = {
      company_id: user.institution.id,
      name: user.institution.name
      // website: user.institution.url
    };
  }
  // https://developers.intercom.com/installing-intercom/docs/intercom-javascript
  window.Intercom('boot', {
    app_id: window.intercomSettings.app_id,
    // "Group" users don't have a `firstName`/`lastName`.
    name: (user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : user.title),
    email: user.email,
    // phone: user.phone && (user.phone + (user.phoneExt ? ' ext. ' + user.phoneExt : '')),
    job_title: user.title,
    company,
    custom_attributes: {
      job_title: user.title
    }
  });
}

export function deactivateIntercomWidget() {
  // Reset Intercom chat widget.
  // https://www.intercom.com/help/en/articles/16845-how-do-i-end-a-session
  // https://developers.intercom.com/installing-intercom/docs/intercom-javascript
  window.Intercom('shutdown');
}
