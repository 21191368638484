import throttle from './throttle.js';

export default function throttleRefreshAction({
  fetchValue,
  setValue,
  cacheTimeToLive
}) {
  return throttle(
    async (parameters) => {
      if (typeof window === 'undefined') {
        // Can't be throttled on server side
        // because that would result in wrong behavior for different users.
        throw new Error('Can only be called on client side');
      }
      let value;
      if (parameters) {
        const {
          forceValueUpdate,
          valueAlreadyFetched
        } = parameters;
        if (forceValueUpdate !== true || valueAlreadyFetched !== true) {
          throw new Error(`Unsupported argument. Expected an object with properties: \`forceValueUpdate: true\`, \`valueAlreadyFetched: true\`, \`value\`. Got: ${JSON.stringify(parameters)}`);
        }
        value = parameters.value;
      } else {
        value = await fetchValue();
      }
      setValue(value);
    },
    cacheTimeToLive,
    {
      // Executes the function immediately when it's first called,
      // and then caches its result for any futher invocations
      // until the `cacheTimeToLive` time runs out, after which
      // the cycle repeats.
      leading: true,
      trailing: false
    }
  );
}
