import React from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from '@acadeum/translate';

import Field from '../Field';
import Form from '../Form';
import FormSubmit from '../FormSubmit';
import FormButtons from '../FormButtons';

import getErrorData from '../../helpers/getErrorData';

import getLocationUrl from 'common-lib/lib/getLocationUrl';

import actions from '../../actions';

const {
  createUserAccountRequest,
  notifyError,
  goto
} = actions;

export default function SignUpForm({
  email,
  firstName,
  lastName,
  institutionId,
  singleSignOn
}) {
  const t = useTranslate('SignUp');

  const onSubmit = async (values) => {
    values.singleSignOn = singleSignOn || false;

    try {
      const { contacts } = await createUserAccountRequest(values);
      goto(getLocationUrl({
        pathname: '/sign-up/requested',
        query: {
          contacts: JSON.stringify(contacts)
        }
      }));
    } catch (error) {
      console.error(error);
      // eslint-disable-next-line no-ex-assign
      error = getErrorData(error);
      switch (error.code) {
        case 'email_occupied':
          return notifyError(t('emailOccupied'));
        default:
          return notifyError(t('errorVerbose', { global: true }));
      }
    }
  };

  return (
    <Form
      autoFocus
      className="form"
      onSubmit={onSubmit}>

      <Field
        required
        type="name"
        name="firstName"
        value={firstName}
        label={t('form.firstName.label')}
        placeholder={t('form.firstName.hint')}
      />

      <Field
        required
        type="name"
        name="lastName"
        value={lastName}
        label={t('form.lastName.label')}
        placeholder={t('form.lastName.hint')}
      />

      <Field
        disabled={Boolean(email)}
        required
        name="email"
        type="email"
        value={email}
        label={t('form.email.label')}
        placeholder={t('form.email.hint')}
      />

      <Field
        required
        name="phone"
        type="phone"
        label={t('form.phone.label')}
        placeholder={t('form.phone.hint')}
      />

      <Field
        name="phoneExt"
        type="phone-ext"
        label={t('form.phoneExt.label')}
        placeholder={t('form.phoneExt.hint')}
      />

      <Field
        required
        name="title"
        label={t('form.title.label')}
        placeholder={t('form.title.hint')}
      />

      <Field
        required
        disabled={Boolean(institutionId)}
        name="institutionId"
        type="institution"
        value={institutionId}
        label={t('form.institution.label')}
        placeholder={t('form.institution.hint')}
      />

      <FormButtons>
        <FormSubmit>
          {t('form.submit')}
        </FormSubmit>
      </FormButtons>
    </Form>
  );
}

SignUpForm.propTypes = {
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  institutionId: PropTypes.number,
  singleSignOn: PropTypes.bool
};
