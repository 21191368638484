import React, { useState } from 'react';

import VerifyEmailModal from '../../components/VerifyEmailModal';
import ErrorSection from '../../components/Error';

import useQueryParameters from '../../hooks/useQueryParameters';

import actions from '../../actions';

import './Error.sass';

const {
  showSignInModal
} = actions;

export default function ErrorPage() {
  const [type, setType] = useState();
  const [code, setCode] = useState();
  const [url, setUrl] = useState();

  const [emailVerificationTokenExpired, setEmailVerificationTokenExpired] = useState();
  // const [accessTokenExpired, setAccessTokenExpired] = useState();
  const [showErrorPage, setShowErrorPage] = useState();

  useQueryParameters(({ url, type, code }) => {
    if (url === '/verify-email' && code === 'token_expired') {
      return setEmailVerificationTokenExpired(true);
    }
    if (code === 'access_token_expired') {
      showSignInModal({
        // redirectTo: url
      });
      return;
      // return setAccessTokenExpired(true);
    }
    setType(type);
    setCode(code);
    setUrl(url);
    setShowErrorPage(true);
  });

  return (
    <>
      {showErrorPage &&
        <ErrorSection type={type} code={code} url={url}/>
      }
      <VerifyEmailModal verificationTokenExpired={emailVerificationTokenExpired}/>
    </>
  );
}

ErrorPage.meta = () => ({
  title: 'Error'
});
