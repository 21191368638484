import React, { memo, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Actions, Table, Text, EmptyState } from '@acadeum/ui';
import { BookOpenHalfFilledIcon } from '@acadeum/icons';
import { useTranslate } from '@acadeum/translate';

import { useExportDataColumns } from '../../../../pages/Enrollments';
import type { ReduxState } from '../../../../helpers/app.types';
import { getStudentEnrollments, isGradeEnrollment } from '../../../../helpers/enrollments';

import actions from '../../../../actions';

import { TeachingGradeModal } from '../TeachingGradeModal';

import { useCanEditTeachingGrade, useGradesColumns, useGradesFilters } from '../../hooks';

const { fetchEnrollmentRequestsTMForExport } = actions;

export const TeachingGrades = memo(() => {
  const t = useTranslate('TeachingGrades');

  const filters = useGradesFilters('teaching');
  const columns = useGradesColumns({
    institutionType: 'teaching',
    actionsColumn: useMemo(() => ({
      id: 'actions',
      size: 60,
      cell: ({ row, downloadRow }) => <ActionsCell row={row} downloadRow={downloadRow} />
    }), [])
  });
  const exportDataColumns = useExportDataColumns({
    teaching: true,
    finalized: true,
    grades: true
  });

  const enrollmentRequests = useSelector((state: ReduxState) => state.enrollmentRequestsTM.enrollmentRequests);
  const data = getStudentEnrollments(enrollmentRequests).filter(isGradeEnrollment);

  if (data.length === 0) {
    return (
      <EmptyState
        icon={BookOpenHalfFilledIcon}
        children={t('emptyState')}
      />
    );
  }

  return (
    <>
      <Text variant="headingSm" mb="lg">
        {t('description')}
      </Text>

      <Table
        id="teachingGrades"
        enableRowSelection
        hasColumnVisibility
        enableGlobalFilter
        columns={columns}
        columnPinningRight={['actions']}
        data={data}
        clientFilters={filters}
        exportOptions={{
          type: 'xlsx',
          fileName: 'Grades - HI - Teaching Grades',
          exportDataColumns,
          fetchDataForExport: async (selectedRowsIds, { rowsIds }) => {
            const ids = selectedRowsIds.length > 0 ? selectedRowsIds : rowsIds;
            return getStudentEnrollments(await fetchEnrollmentRequestsTMForExport(ids.map(Number)));
          }
        }}
      />
    </>
  );
});

function ActionsCell({
  row,
  downloadRow
}) {
  const [show, setShow] = useState(false);
  const canEdit = useCanEditTeachingGrade();

  return (
    <>
      <Actions
        variant="kebab"
        actions={[
          { title: canEdit ? 'Edit' : 'See Details', onClick: () => setShow(true) },
          { title: 'Download', onClick: downloadRow }
        ]}
      />
      <TeachingGradeModal
        show={show}
        onHide={setShow}
        grade={row.original}
        downloadRow={downloadRow}
      />
    </>
  );
}
