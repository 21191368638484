import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { FormField } from '@acadeum/ui';
import type { UserRolePermissionOperation, UserRolePermissionSubject } from '@acadeum/types';
import { getAuthSelector } from '@acadeum/auth';

import { getPermissionOptions } from '../../lib/getPermissionOptions';

interface PermissionsSelectProps {
  disabled?: boolean;
  subject: UserRolePermissionSubject;
  operation: UserRolePermissionOperation;
}

export const PermissionsSelect = memo<PermissionsSelectProps>(({
  disabled,
  operation,
  subject
}) => {
  const user = useSelector(getAuthSelector('user'));

  const options = useMemo(() => {
    if (!user) {
      throw new Error();
    }
    return getPermissionOptions(
      subject,
      operation,
      user.roles[0].permissions
    );
  }, [subject, operation, user?.roles]);

  return (
    <FormField
      noMargin
      style={{ minWidth: '10rem' }}
      type="select"
      disabled={disabled || options.length === 1}
      name={`${subject}.${operation}`}
      options={options}
    />
  );
});
