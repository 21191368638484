import type { FC } from 'react';
import React, { useEffect, useMemo, useState } from 'react';

import { Page, PageLoader, SuccessModal } from '@acadeum/ui';
import type { UseFetchUserRoleQuery, UseUpdateUserRoleMutation } from '@acadeum/api';

import { useOnError } from '../../../providers/useOnError';

import { prepareFormPermissionsToApiStandard } from '../lib/prepareFormPermissionsToApiStandard';
import { prepareApiPermissionsToFormPermissionsStandard } from '../lib/prepareApiPermissionsToFormPermissionsStandard';
import { ManageRolePermissionsForm } from '../ui/ManageRolePermissionsForm';
import { useNavigate } from '../../../providers/useNavigate';
import { useSettingsRoutes } from '../../../hooks/useSettingsRoutes';

export interface UserRolePermissionsDetailsPageProps {
  roleId: number;
  useFetchUserRoleQuery: UseFetchUserRoleQuery;
  useUpdateUserRoleMutation: UseUpdateUserRoleMutation;
}

export const UserRolePermissionsDetailsPage: FC<UserRolePermissionsDetailsPageProps> = ({
  roleId,
  useUpdateUserRoleMutation,
  useFetchUserRoleQuery
}) => {
  const onError = useOnError();
  const navigate = useNavigate();
  const { getUserRoleDetailsUrl } = useSettingsRoutes();

  const [updateUserRoleMutation] = useUpdateUserRoleMutation();

  const {
    data: role,
    error,
    isLoading
  } = useFetchUserRoleQuery({
    id: roleId
  });

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const onSubmit = async (values) => {
    await updateUserRoleMutation({
      id: roleId,
      permissions: prepareFormPermissionsToApiStandard(values)
    }).unwrap();
    setShowSuccessModal(true);
  };

  const onCancel = async () => navigate.back();

  useEffect(() => {
    if (role?.type === 'DEFAULT') {
      void navigate.push(`/user-roles/${role.id}`);
    }
  }, [role]);

  const defaultValues = useMemo(() => {
    if (role?.permissions) {
      return prepareApiPermissionsToFormPermissionsStandard(role.permissions);
    }
  }, [role]);

  if (isLoading || role?.type === 'DEFAULT') {
    return (
      <PageLoader/>
    );
  }

  if (error) {
    return onError(error);
  }

  if (!role) {
    return onError(new Error('Role not found'));
  }

  return (
    <Page
      title="Manage Permissions"
      breadcrumbs={[[role.name, getUserRoleDetailsUrl(roleId)]]}
    >
      <ManageRolePermissionsForm
        type="edit"
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
      <SuccessModal
        size="narrow"
        show={showSuccessModal}
        onHide={setShowSuccessModal}
        secondaryAction={{
          content: 'Go to overview',
          url: getUserRoleDetailsUrl(role.id)
        }}
      >
        The permissions for this role have been updated successfully.
      </SuccessModal>
    </Page>
  );
};
