export enum Code {
  Escape = 'Escape',
  Enter = 'Enter',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown'
}

export enum KeyCode {
  Escape = 27,
  Enter = 13,
  Delete = 46,
  ArrowLeft = 37,
  ArrowRight = 39,
  ArrowUp = 38,
  ArrowDown = 40
}
