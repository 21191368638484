import React from 'react';

const ID = 'form-submit-announcement';

export const FormSubmitAnnouncer = () => {
  return (
    <div
      id={ID}
      style={{
        position: 'absolute',
        zIndex: -1,
        opacity: 0
      }}
      role="status"
      aria-live="assertive"
    />
  );
};

export function setStatus(status = '') {
  const announcer = document.getElementById(ID);
  if (announcer) {
    announcer.textContent = status;
  }
}

export const PENDING = 'Submitting form';
export const ERROR = 'Form submit error';
export const SUCCESS = 'Form submit success';
