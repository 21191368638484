import React from 'react';

import type { Table, RowData, RowSelectionState } from '@tanstack/react-table';

import { useTranslate } from '@acadeum/translate';

import { Text } from '../../../Text';

import styles from './TotalResultsCount.module.scss';

export interface TotalResultsCountProps<TData> {
  table: Table<TData>;
  totalCount: number;
  isLoading?: boolean;
  isFetching?: boolean;
  rearrangeable?: boolean;
  rowSelection: RowSelectionState;
  resultText: ({ totalCount }: { totalCount: number }) => string;
  selectedResultText: ({ totalCount, selectedRowsCount }: { totalCount: number, selectedRowsCount: number }) => string;
}

export const TotalResultsCount = <TData extends RowData>({
  isLoading,
  isFetching,
  totalCount,
  rowSelection,
  selectedResultText,
  resultText,
  rearrangeable
}: TotalResultsCountProps<TData>) => {
  const t = useTranslate('ui.Table');

  const selectedRowsCount = Object.keys(rowSelection).length;

  return (
    <Text
      as="span"
      role="status"
      variant="subtitle2"
      className={styles.TotalResultsCount}
      isLoading={isLoading || isFetching}
    >
      {isFetching || isLoading ? t('loading') : (
        selectedRowsCount === 0 || rearrangeable ? (
          resultText({ totalCount: totalCount })
        ) : (
          selectedResultText({ totalCount: totalCount, selectedRowsCount: selectedRowsCount })
        )
      )}
    </Text>
  );
};
