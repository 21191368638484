import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { BaseButton as Button, CCPagination } from '@acadeum/ui';

import './Pagination.sass';

function PageButton ({
  active,
  onClick,
  children
}) {
  return (
    <Button
      onClick={onClick}
      disabled={active}
      className={classNames('Pagination-pageButton', {
        'Pagination-pageButton--active': active
      })}>
      {children}
    </Button>
  );
}

PageButton.propTypes = {
  active: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

function PrevNextButton ({ direction, ...rest }) {
  const Icon = direction === 'previous' ? LeftCaretIcon : RightCaretIcon;
  const className = direction === 'previous' ? 'Pagination-prevButton' : 'Pagination-nextButton';
  const iconClassName = direction === 'previous' ? 'Pagination-prevButtonIcon' : 'Pagination-nextButtonIcon';
  return (
    <Button {...rest} className={className}>
      <Icon className={iconClassName}/>
    </Button>
  );
}

PrevNextButton.propTypes = {
  direction: PropTypes.oneOf(['previous', 'next']).isRequired
};

function Ellipsis () {
  return <div className="Pagination-ellipsis">…</div>;
}

function As ({ children }) {
  return (
    <div className="Pagination" style={{ marginTop: '1rem' }}>
      {children}
    </div>
  );
}

As.propTypes = {
  children: PropTypes.node.isRequired
};

function BackToTop({ onClick }) {
  return (
    <Button
      onClick={onClick}
      className="Pagination-backToTop">
      Back to Top
      <svg
        aria-hidden
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        className="Pagination-backToTopArrow">
        <path
          d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
          fill="currentColor"
        />
      </svg>
    </Button>
  );
}

BackToTop.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default function AcadeumPagination ({
  page,
  pageCount,
  setPage,
  getPageUrl,
  maxPageLinks = 8
}) {
  return (
    <CCPagination
      page={page}
      pageCount={pageCount}
      setPage={setPage}
      getPageUrl={getPageUrl}
      maxPageLinks={maxPageLinks}
      scrollToTop={() => {
        scrollTo('scrollToTopAnchor', 'SearchBarAndResultsPerPageAndSortBy');
      }}
      As={As}
      BackToTop={BackToTop}
      PrevNextButton={PrevNextButton}
      PageButton={PageButton}
      Ellipsis={Ellipsis}
    />
  );
}

AcadeumPagination.propTypes = {
  page: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  getPageUrl: PropTypes.func,
  maxPageLinks: PropTypes.number
};

function scrollTo (target, fallbackTarget) {
  const targetElement = document.querySelector(`.${target}`);
  const fallbackTargetElement = document.querySelector(`.${fallbackTarget}`);
  const header = document.querySelector('.Header');

  const element = targetElement || fallbackTargetElement;

  if (element) {
    window.scrollTo({
      top: (element.getBoundingClientRect().top + window.pageYOffset) - header.clientHeight - 16,
      behavior: 'smooth'
    });
  }
}

function LeftCaretIcon (props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path fill="currentColor"
        d="M3.86 8.753l5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"
      />
    </svg>
  );
}

function RightCaretIcon (props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path fill="currentColor"
        d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
      />
    </svg>
  );
}
