import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './RadioButton.module.scss';

let RadioButton = ({
  // React doesn't allow `name` property on `<input type="radio"/>`.
  // "Mixing React and non-React radio inputs with the same `name` is not supported".
  // eslint-disable-next-line no-unused-vars
  name,
  value,
  error,
  children,
  ...rest
}, ref) => {
  return (
    <div>
      <label className={styles.RadioButton}>
        <input
          ref={ref}
          type="radio"
          value={value}
          className={classNames(styles.RadioButton__input)}
          {...rest}
        />
        <span className={classNames(styles.RadioButton__radio, {
          [styles.RadioButton__radio_isError]: error
        })}/>
        <span className={classNames(styles.RadioButton__input__label, {
          [styles.RadioButton__input__label_isError]: error
        })}>
          {children}
        </span>
      </label>
    </div>
  );
};

RadioButton = React.forwardRef(RadioButton);

RadioButton.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  error: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default RadioButton;
