import canEnrollInCourse_ from 'common-lib/lib/canEnrollInCourse';
import getMaxCourseSessionStartDateTimeForStudents from 'common-lib/lib/getMaxCourseSessionStartDateTimeForStudents';

import { isObject } from '@acadeum/helpers';

import isCourseSessionScheduled from './isCourseSessionScheduled';

// Returns a list of course sessions in which a student could enroll.
export function getEnrollableSessions(course) {
  return course.sessions.filter(session => canEnrollInSession(session, course));
}

// Returns a list of course sections in which a student could enroll.
// `sections` is a list of Algolia course session objects.
// `course` is an Algolia course object.
export function getEnrollableSections(sections, course) {
  return sections.filter(section => canEnrollInSection(section, course));
}

// Returns a list of course sections in which a student could enroll.
// `sections` is a list of Algolia course session objects having a "new" structure where "section" and "session" properties have been merged.
// `course` is an Algolia course object.
export function getEnrollableSectionsWhenNewAlgoliaObjectFormat(sections, course) {
  return sections.filter(section => canEnrollInSectionWhenNewAlgoliaObjectFormat(section, course));
}

export function isCourseApproved(course, userInstitutionId) {
  return course.approvedBy.includes(userInstitutionId);
}

export function patchCourseRemoveApproval(course, userInstitutionId) {
  course.approvedBy = course.approvedBy.filter(_ => _ !== userInstitutionId);
}

export function getCourseCustomSchedule(course, userInstitutionId) {
  return course.customSchedules[String(userInstitutionId)];
}

// export function isCourseApprovedWithCustomSchedule(course, userInstitutionId) {
//   return getCourseCustomSchedule(course, userInstitutionId) !== undefined;
// }

// Determines if a course session is "scheduled".
// https://github.com/Acadeum/Tickets/issues/657#issuecomment-993998705
export function isSessionScheduled(session, course, userInstitutionId) {
  if (isCourseApproved(course, userInstitutionId)) {
    const schedule = getCourseCustomSchedule(course, userInstitutionId);
    if (isSessionScheduled_(session, schedule)) {
      return session.startDate < getMaxCourseSessionStartDateTimeForStudents();
    }
  }
  // return session.scheduledBy.includes(userInstitutionId) &&
  //   session.startDate < getMaxCourseSessionStartDateTimeForStudents();
}

function isSessionScheduled_(session, schedule) {
  if (schedule) {
    return isCourseSessionScheduled(session, schedule);
  } else {
    return true;
  }
}

export function isConsortialCourse(course, userInstitutionId) {
  return course.institution.consortialEnrollingInstitutionIds.includes(userInstitutionId);
}

// Tells if a student can enroll in a given course section.
// `session` is an Algolia course session object.
// `course` is an Algolia course object.
export function canEnrollInSession(session, course) {
  return canEnrollInCourse_({
    onDemand: course.onDemand,
    lastAddDate: new Date(session.lastAddDate)
  });
}

// Can enroll in a course if "Add/Drop" date has not yet passed.
// When changing this code also update `JITUApi/approvedCoursesWithPricing/index.js`.
function canEnrollInSectionWhenNewAlgoliaObjectFormat(section, course) {
  return canEnrollInCourse_({
    onDemand: course.onDemand,
    lastAddDate: new Date(section.lastAddDate)
  });
}

// Tells if a student can enroll in a given course section.
// `section` is an Algolia course section object.
// `course` is an Algolia course object.
export function canEnrollInSection(section, course) {
  if (Object.hasOwn(section, 'session') && isObject(section.session)) {
    return canEnrollInSession(section.session, course);
  }
  return canEnrollInCourse_({
    onDemand: course.onDemand,
    lastAddDate: new Date(section.lastAddDate)
  });
}

// Tells if a student can enroll in any section of given course.
// `course` is an Algolia course object.
export function canEnrollInCourse(course) {
  for (const session of course.sessions) {
    if (canEnrollInSession(session, course)) {
      return true;
    }
  }
  return false;
}
