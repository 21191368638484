import formatCountryRegion from 'common-lib/lib/formatCountryRegion';
import formatCountry, { COUNTRIES } from 'common-lib/lib/formatCountry';
import COUNTRY_REGIONS from 'common-lib/constants/countryRegions.json';

import { sortByLabel } from '../sortByLabel';

import type { Language, Options } from '@acadeum/types';

export function getCountryOptions({ language }: { language: Language }): Options<string> {
  return sortByLabel(COUNTRIES.map((country) => ({
    value: country,
    label: formatCountry(country, { language })
  })));
}

interface CountryRegionsOptionsProps {
  country?: string;
  language: Language;
}

export function getCountryRegionOptions({ country, language }: CountryRegionsOptionsProps): Options<string> {
  let options: Options<string> = [];
  if (country && COUNTRY_REGIONS[country]) {
    options = options.concat(COUNTRY_REGIONS[country].map((region) => ({
      label: formatCountryRegion(country, region, { language }),
      value: region
    })));
  } else {
    options.push({
      label: 'N/A',
      value: 'N/A'
    });
  }
  return options;
}
