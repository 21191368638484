import React from 'react';
import { useSelector } from 'react-redux';

import CourseSearch_ from '../../components/CourseSearch';
import WithPublicMarketingPageBanner from '../../components/WithPublicMarketingPageBanner';

function Courses() {
  const { user } = useSelector(state => state.auth);

  const CourseSearch = () => (
    <CourseSearch_
      view="sections"
      searchFieldBanner
      searchFieldBannerFullWidth
    />
  );

  return (
    <>
      {user ? (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <CourseSearch/>
            </div>
          </div>
        </div>
      ) : (
        <CourseSearch/>
      )}
    </>
  );
}

Courses.meta = () => ({
  title: 'Courses'
});

export default WithPublicMarketingPageBanner(Courses);
