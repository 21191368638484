import React from 'react';
import type { ButtonHTMLAttributes, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';

import { useOwnFormContext } from '../Form';

const FORM_ACTION_VALUE_NAME = 'formAction';

export const onSubmitEnd = ({ setValue }) => {
  setValue((FORM_ACTION_VALUE_NAME), undefined as never);
};

export interface FormSubmitProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  // eslint-disable-next-line
  component: any;
  action?: string;
  children: ReactNode;
  onClick?: (event) => void;
  disabledWhenInvalid?: boolean;
  skipValidation?: boolean;
}

export const FormSubmit: React.FC<FormSubmitProps> = ({
  component: Component,
  children,
  action,
  disabled,
  disabledWhenInvalid,
  skipValidation,
  onClick: propOnClick,
  type = 'submit',
  ...rest
}) => {
  const { setValue } = useFormContext();
  const { isSubmitting, errors, isValid } = useFormState();
  const { forceSubmitWithoutValidation } = useOwnFormContext();

  const onClick = (event) => {
    if (typeof action === 'string') {
      setValue(FORM_ACTION_VALUE_NAME, action);
    }

    if (skipValidation) {
      forceSubmitWithoutValidation();
    }
    if (typeof propOnClick === 'function') {
      propOnClick(event);
    }
  };

  const actionValue = useWatch({ name: FORM_ACTION_VALUE_NAME });
  const isCurrentAction = actionValue === action;
  const loading = action ? isCurrentAction && isSubmitting : isSubmitting;

  const hasErrors = Object.keys(errors).length > 0;

  const isValidForm = disabledWhenInvalid ? (!isValid || hasErrors) : false;

  return (
    <Component
      {...rest}
      type={skipValidation ? 'button' : type}
      loading={loading}
      disabled={skipValidation ? (disabled || isSubmitting) : (isSubmitting || isValidForm || disabled)}
      onClick={onClick}
    >
      {children || 'Submit'}
    </Component>
  );
};

FormSubmit.propTypes = {
  action: PropTypes.string
};
