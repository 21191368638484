import React from 'react';
import classNames from 'classnames';

import { TextInput } from 'react-responsive-ui';

import getCurrencyInputParams from './types/currency.js';
import getNumberInputParams from './types/number.js';
import getPercentInputParams from './types/percent.js';
import getJsonInputParams from './types/json.js';
import getPhoneInputParams from './types/phone.js';
import getPhoneExtInputParams from './types/phone-ext.js';
import getDateInputParams from './types/date.js';
import getPostalCodeInputParams from './types/postal-code.js';

export default function renderTextInput({
  type,
  label,
  error,
  required,
  component,
  multiline,
  country,
  currency,
  currencyFractional,
  componentShowsErrorMessage
}, rest) {
  const { step, onChange } = rest;

  let getInputParams;

  if (component) {
    getInputParams = () => ({
      InputComponent: component
    });
    rest.type = type;
  }
  else if (type === 'date') {
    getInputParams = () => getDateInputParams({
      label
    });
  }
  else if (type === 'phone') {
    getInputParams = () => getPhoneInputParams();
  }
  else if (type === 'phone-ext') {
    getInputParams = () => getPhoneExtInputParams();
  }
  else if (type === 'json') {
    getInputParams = () => getJsonInputParams({
      // onChange,
      required
    });
  }
  else if (type === 'percent') {
    getInputParams = () => getPercentInputParams({
      onChange
    });
  }
  else if (type === 'number' || type === 'integer') {
    getInputParams = () => getNumberInputParams({
      onChange,
      step,
      integer: type === 'integer'
    });
  }
  else if (type === 'currency') {
    getInputParams = () => getCurrencyInputParams({
      onChange,
      currency,
      currencyFractional
    });
  }
  else if (type === 'postal-code') {
    getInputParams = () => getPostalCodeInputParams({
      country
    });
  }
  else {
    getInputParams = () => ({
      InputComponent: TextInput
    });
    rest.type = type;
  }

  const { InputComponent, ...props } = getInputParams();
  rest = {
    ...rest,
    ...props
  };

  if (componentShowsErrorMessage) {
    rest.error = error;
  }

  if (type === 'textarea' || multiline) {
    rest.multiline = true;
  }

  // Prevents `PropTypes.func.isRequired` warning
  // for "read only" text inputs
  if ((rest.disabled || rest.readOnly) && !onChange) {
    rest.onChange = () => {};
  }

  // WAI-ARIA.
  rest['aria-label'] = label;

  rest.className = classNames('form-input__input', {
    'form-input__input--invalid': error,
    // Don't show native browser `<input type="number"/>` up/down arrows.
    'rrui-input-type-number--no-arrows': type === 'number' || type === 'integer' || type === 'percent'
  });

  return <InputComponent {...rest}/>;
}
