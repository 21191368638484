import type { UserRolePermissionScopeWithNull } from '@acadeum/types';

export const getScopeLabel = (scope: UserRolePermissionScopeWithNull) => {
  switch (scope) {
    case '*':
      return 'All';
    case 'org':
      return 'Own';
    case 'owner':
      return 'Individual';
    case null:
    default:
      return 'None';
  }
};
