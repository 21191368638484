import { Children, isValidElement } from 'react';


// Returns all children that are valid elements as an array. Can optionally be
// filtered by passing `predicate`.
export function elementChildren(children) {
  return Children.toArray(children).filter((child) => isValidElement(child));
}

export function wrapWithComponent(element, Component, props) {
  if (element == null) {
    return null;
  }
  return isElementOfType(element, Component) ? (
    element
  ) : (
    <Component {...props}>{element}</Component>
  );
}

// Checks whether `element` is a React element of type `Component` (or one of
// the passed components, if `Component` is an array of React components).
export function isElementOfType(element, Component) {
  if (element == null || !isValidElement(element) || typeof element.type === 'string') {
    return false;
  }

  const { type: defaultType } = element;
  // Type override allows components to bypass default wrapping behavior. Ex: Stack
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const type = element.props?.__type__ || defaultType;
  const Components = Array.isArray(Component) ? Component : [Component];

  return Components.some((AComponent) => typeof type !== 'string' && isComponent(AComponent, type));
}

function isComponent(AComponent, AnotherComponent) {
  if (process.env.NODE_ENV === 'development') {
    const componentName = AComponent.name;
    const anotherComponentName = AnotherComponent.displayName;
    return AComponent === AnotherComponent || (Boolean(componentName) && componentName === anotherComponentName);
  }
  return AComponent === AnotherComponent;
}
