import React, { memo } from 'react';

import type { Section } from '@acadeum/types/src/models';

import { Col } from '../../../Col';
import { Row } from '../../../Row';

import { SectionContent } from '../SectionContent';

import styles from '../../SectionCard.module.scss';

export interface TermSessionStartEndDateRowProps {
  section: Pick<Section,
    'term' |
    'session' |
    'startDate' |
    'endDate'
  >;
}

export const TermSessionStartEndDateRow = memo<TermSessionStartEndDateRowProps>(({
  section
}) => {
  return (
    <Row as="dl" className={styles.row}>
      <Col col={6} md={3}>
        <SectionContent label="Term" value={section.term}/>
      </Col>
      <Col col={6} md={3}>
        <SectionContent label="Session" value={section.session}/>
      </Col>
      <Col col={12} md={6}>
        <SectionContent
          type="date"
          label="Start & End Dates"
          value={[section.startDate, section.endDate]}
        />
      </Col>
    </Row>
  );
});
