import React from 'react';

import { Submit } from 'easy-react-form';
import Button from '../Button';

export default function FormSubmit (props) {
  return (
    <Submit
      component={Button}
      {...props}
    />
  );
}
