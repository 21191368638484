import React from 'react';

import { Link, Title } from '@acadeum/ui';

import styles from './MenuSection.module.scss';

export interface MenuSectionProps {
  icon: React.ReactNode;
  title: string;
  menu: Array<{
    icon: React.ReactNode;
    title: string;
    url?: string;
    external?: boolean;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  }>;
}

export const MenuSection: React.FC<MenuSectionProps> = ({
  menu,
  title,
  icon
}) => {
  return (
    <nav className={styles.MenuSection}>
      <Title level={3} className={styles.MenuSection__header}>
        {icon} {title}
      </Title>
      <ul className={styles.MenuSection__list}>
        {menu.map((item, i) => (
          <li key={i}>
            <Link
              className={styles.MenuSection__link}
              external={item.external}
              to={item.url}
              onClick={item.onClick}
            >
              {item.icon} {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};
