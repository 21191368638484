import React from 'react';
import classNames from 'classnames';

import { ChevronDownIcon, ArrowDownIcon, ArrowUpIcon } from '@acadeum/icons';

import { useAccordion } from '../../utils/useAccordion';
import type { MarginsProp } from '../../utils/useMargin';
import { useMargin } from '../../utils/useMargin';

import { BaseButton } from '../BaseButton';
import { Icon } from '../Icon';

import styles from './BorderedAccordion.module.scss';
import { ScreenReaderOnly } from '@acadeum/core-ui';

export interface BorderedAccordionProps extends Pick<MarginsProp, 'my' | 'mt' | 'mb'> {
  name: string;
  leftControl?: React.ReactNode;
  children: React.ReactNode;
  defaultOpen?: boolean;
  canUp?: boolean;
  canDown?: boolean;
  hidden?: boolean;
  moveUp?: () => void;
  moveDown?: () => void;
}

export const BorderedAccordion: React.FC<BorderedAccordionProps> = ({
  hidden,
  leftControl,
  name,
  children,
  defaultOpen = true,
  my,
  mt,
  mb,
  moveUp,
  canUp,
  canDown,
  moveDown
}) => {
  const { marginClassNames } = useMargin({ my, mt, mb });
  const { id, show, onToggle, panelId, controlId, panelStyle } = useAccordion({ defaultOpen });

  const titleId = `title-${id}`;

  return (
    <div hidden={hidden} className={classNames(styles.Accordion, marginClassNames)}>
      <div className={styles.header}>
        {leftControl && (
          <div className={styles.leftControl}>
            {leftControl}
          </div>
        )}

        <h2
          id={titleId}
          className={styles.title}
        >
          {moveUp && (
            <BaseButton
              className={styles.iconButton}
              title="Move Up"
              aria-label="Move Up"
              aria-labelledby={titleId}
              onClick={moveUp}
              disabled={!canUp}
            >
              <Icon icon={ArrowUpIcon}/>
            </BaseButton>
          )}
          {moveDown && (
            <BaseButton
              title="Move Down"
              aria-label="Move Down"
              aria-labelledby={titleId}
              className={styles.iconButton}
              onClick={moveDown}
              disabled={!canDown}
            >
              <Icon icon={ArrowDownIcon}/>
            </BaseButton>
          )}
          <BaseButton
            id={controlId}
            aria-expanded={show}
            aria-controls={panelId}
            aria-labelledby={titleId}
            onClick={onToggle}
            className={classNames(styles.button)}
          >
            <Icon icon={ChevronDownIcon}/>
          </BaseButton>

          <ScreenReaderOnly as="span">{name}</ScreenReaderOnly>
        </h2>
      </div>
      <section
        id={panelId}
        aria-labelledby={controlId}
        style={panelStyle}
        className={classNames(styles.panel)}
      >
        {children}
      </section>
    </div>
  );
};
