import React, { useEffect, useRef } from 'react';

import { waitForJob } from '@acadeum/helpers';

import ConsortiumForm from '../../components/ConsortiumForm';
import Section from '../../components/Section';

import actions from '../../actions';

const {
  goto,
  runJob,
  getJobStatus
} = actions;

export default function CreateConsortium() {
  const stopPollingJobStatus = useRef();

  useEffect(() => {
    return () => {
      if (stopPollingJobStatus.current) {
        stopPollingJobStatus.current();
      }
    };
  }, []);

  async function onSubmit(values) {
    // const consortium = await createConsortium(values);

    const consortium = await waitForJob(
      await runJob('consortia-create', {
        body: values
      }),
      getJobStatus,
      (cancel) => stopPollingJobStatus.current = cancel
    );

    goto(`/consortia/${values.vanityUrl || consortium.id}`);
  }

  return (
    <Section title="Add Consortium">
      <ConsortiumForm
        onSubmit={onSubmit}
        submitTitle="Create" />
    </Section>
  );
}

CreateConsortium.meta = () => ({
  title: 'Create a Consortium'
});

CreateConsortium.breadcrumbs = () => [
  ['Consortia', '/consortia'],
  'Add'
];
