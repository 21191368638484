import React from 'react';
import PropTypes from 'prop-types';

import { UnstyledTooltip } from '@acadeum/ui';

import Icon from '../Icon';

import './InfoTooltip.sass';

export default function InfoTooltip({ children }) {
  return (
    <UnstyledTooltip
      className="UnstyledTooltip"
      tooltipClassName="UnstyledTooltip__content"
      triggerClassName="UnstyledTooltip__trigger"
      placement="right-start"
      tooltipContent={children}
    >
      <Icon name="info-circle" className="info-tooltip__icon"/>
    </UnstyledTooltip>
  );
}

InfoTooltip.propTypes = {
  children: PropTypes.node.isRequired
};
