import React from 'react';
import { useFormState } from 'react-hook-form';

import { StickyFormFooter } from '@acadeum/ui';

import { Mode } from '../helpers';

interface CourseFormFooterProps {
  mode: Mode;
  submitText: string;
  onBack?: () => void;
  cancelUrl?: string;
}

export const CourseFormFooter: React.FC<CourseFormFooterProps> = ({
  cancelUrl,
  submitText,
  onBack,
  mode
}) => {
  const { isDirty } = useFormState();

  return (
    <StickyFormFooter
      onBackProps={onBack ? { onClick: onBack } : undefined}
      onCancelProps={cancelUrl ? { url: cancelUrl } : undefined}
      submitProps={{
        children: submitText,
        disabled: mode === Mode.EDIT && !isDirty
      }}
    />
  );
};
