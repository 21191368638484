import React, { memo } from 'react';
import classNames from 'classnames';

import styles from './Pointer.module.scss';

interface PointerProps {
  top?: number;
  left: number;
  className?: string;
}

export const Pointer = memo<PointerProps>(({ className, top = .5, left }) => {
  return (
    <div
      className={classNames(className, styles.Pointer)}
      style={{
        top: `${top * 100}%`,
        left: `${left * 100}%`
      }}
    />
  );
});
