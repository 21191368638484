import React from 'react';
import classNames from 'classnames';

import type { NumericRange } from '@acadeum/types';

import styles from './Col.module.scss';

type ColCount12 = NumericRange<1, 12>;
type OffsetCount12 = NumericRange<0, 11>;

type ColCount24 = NumericRange<1, 24>;
type OffsetCount24 = NumericRange<0, 23>;

// col - 0+
// lg - ≥1280
// md - ≥768
// sm - ≥414
// xs - <414

interface ColProps12 {
  system?: 12;
  col?: ColCount12;
  lg?: ColCount12;
  md?: ColCount12;
  sm?: ColCount12;
  xs?: ColCount12;
  offset?: OffsetCount12;
  mdOffset?: OffsetCount12;
  smOffset?: OffsetCount12;
  xsOffset?: OffsetCount12;
}

interface ColProps24 {
  system?: 24;
  col?: ColCount24;
  lg?: ColCount24;
  md?: ColCount24;
  sm?: ColCount24;
  xs?: ColCount24;
  offset?: OffsetCount24;
  mdOffset?: OffsetCount24;
  smOffset?: OffsetCount24;
  xsOffset?: OffsetCount24;
}

export type ColProps = (ColProps12 | ColProps24) & {
  className?: string;
  children?: React.ReactNode;
};

export const Col: React.FC<ColProps> = ({
  className,
  children,
  col,
  lg,
  md,
  sm,
  xs,
  offset,
  mdOffset,
  smOffset,
  xsOffset,
  system = 12
}) => {
  return (
    <div data-col className={classNames(className, styles.col, {
      [styles[`col-${system}-col-${col}`]]: typeof col === 'number',
      [styles[`col-${system}-lg-${lg}`]]: typeof lg === 'number',
      [styles[`col-${system}-md-${md}`]]: typeof md === 'number',
      [styles[`col-${system}-sm-${sm}`]]: typeof sm === 'number',
      [styles[`col-${system}-xs-${xs}`]]: typeof xs === 'number',
      [styles[`offset-${system}-lg-${offset}`]]: typeof offset === 'number',
      [styles[`offset-${system}-md-${mdOffset}`]]: typeof mdOffset === 'number',
      [styles[`offset-${system}-sm-${smOffset}`]]: typeof smOffset === 'number',
      [styles[`offset-${system}-xs-${xsOffset}`]]: typeof xsOffset === 'number'
    })}>
      {children}
    </div>
  );
};
