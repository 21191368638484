import { useState, useId } from 'react';
import type { FC } from 'react';
import classNames from 'classnames';

import { CopyIcon } from '@acadeum/icons';

import { Button } from '../Button';

import styles from './CopyToClipboard.module.scss';

export interface CopyToClipboardProps {
  text: string;
  className?: string;
}

export const CopyToClipboard: FC<CopyToClipboardProps> = ({ text, className }) => {
  const id = useId();

  const idButton = `copy-button-${id}`;
  const idText = `text-input-${id}`;

  const [showCopied, setShowCopied] = useState<boolean>();

  const onClick = () => {
    navigator.clipboard.writeText(text).then(() => {
      setShowCopied(true);
      setTimeout(() => {
        setShowCopied(false);
      }, 3000);
    }).catch((error) => {
      console.log(error);
    });
  };

  return (
    <div className={classNames(styles.CopyToClipboard, className)}>
      <span id={idText} className={styles.CopyToClipboard__span}>
        {text}
      </span>
      <Button
        onClick={onClick}
        variant="text"
        id={idButton}
        aria-label="Copy"
        aria-labelledby={`${idButton} ${idText}`}
        className={styles.CopyToClipboard__copy}
        icon={showCopied ? undefined : CopyIcon}
      >
        {showCopied ? 'Copied!' : 'Copy'}
      </Button>
    </div>
  );
};
