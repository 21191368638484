import React from 'react';
import { useSelector } from 'react-redux';

import Course from '../../components/Course';
import WithPublicMarketingPageBanner from '../../components/WithPublicMarketingPageBanner';

import actions from '../../actions';

const {
  fetchSection
} = actions;

function CourseSectionPage() {
  const { section } = useSelector(state => state.sections);
  const { course } = section.session;

  return (
    <Course
      course={course}
      section={section}
    />
  );
}

CourseSectionPage.meta = ({ useSelector }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const course = useSelector(state => state.sections.section.session.course);
  return {
    title: `${course.code} ${course.title}`
  };
};

CourseSectionPage.breadcrumbs = (state) => [
  ['Course', `/courses/${state.sections.section.session.course.id}`],
  'Section'
];

CourseSectionPage.load = async ({ user, params: { sectionId } }) => {
  try {
    await fetchSection(sectionId, { user });
  } catch (error) {
    // If the section doesn't exist
    // then redirect to "Not found" page.
    if (error.statusCode === 404) {
      return {
        redirect: {
          url: '/not-found'
        }
      };
    }
    throw error;
  }
};

export default WithPublicMarketingPageBanner(CourseSectionPage, { margin: true });
