import React from 'react';
import { useSelector } from 'react-redux';

import { useTranslate } from '@acadeum/translate';
import { StudentPortalPage as StudentPortalPage_ } from '@acadeum/shared-admin-ui';
import { AppLoading, Page } from '@acadeum/ui';
import { getAuthSelector } from '@acadeum/auth';

import { useFetchApprovedCoursesWithPricingQuery } from '../../api/course';
import {
  useFetchHomeStudentCourseEnrollmentPricingQuery,
  useSetHomeStudentCourseEnrollmentPricingMutation
} from '../../api/coursePricing';
import { formatCurrency } from '../../helpers/format';
import type { AppPage } from '../../helpers/app.types';

const StudentPortalPage: AppPage = () => {
  const t = useTranslate();
  const user = useSelector(getAuthSelector('user'));

  const { data, isLoading } = useFetchApprovedCoursesWithPricingQuery({});

  const {
    data: homeStudentCourseEnrollmentPricing,
    isLoading: isLoadingHomeStudentCourseEnrollmentPricing
  } = useFetchHomeStudentCourseEnrollmentPricingQuery();

  const [setCourseEnrollmentPricing] = useSetHomeStudentCourseEnrollmentPricingMutation();

  return (
    <Page
      title="Student Portal"
      breadcrumbs={[[t('settings', { global: true }), '/settings'], 'Student Portal']}
    >
      <AppLoading inline={isLoading || isLoadingHomeStudentCourseEnrollmentPricing}/>
      {(user && data && !isLoadingHomeStudentCourseEnrollmentPricing) && (
        <StudentPortalPage_
          user={user}
          courses={data.courses}
          homeStudentCourseEnrollmentPricing={homeStudentCourseEnrollmentPricing}
          formatCurrency={formatCurrency}
          setHomeStudentCourseEnrollmentPricing={setCourseEnrollmentPricing}
        />
      )}
    </Page>
  );
};

StudentPortalPage.meta = () => ({
  title: 'Student Portal'
});

export default StudentPortalPage;
