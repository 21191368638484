// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck TODO: Fix All types

import { createContext } from 'react';

export const ComboboxTextFieldContext = createContext();

export const ComboboxToggleContext = createContext();

export const ComboboxListboxContext = createContext({});

export const ComboboxListboxOptionContext = createContext({});
