import { writeXlsx as writeXlsx_ } from '@acadeum/helpers';

import { formatEthnicity, formatGender, formatPhone, formatRaces } from './format';

const writeXlsx = writeXlsx_({
  formatGender,
  formatRaces,
  formatEthnicity,
  formatPhone
});

export default writeXlsx;


