import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import { Radio } from '../../../Radio';

import styles from './FormRadioInput.module.scss';

export const FormRadioInput = ({
  id,
  label,
  name,
  options,
  defaultValue,
  rules,
  ...rest
}) => {
  const {
    field: { value, ...inputProps }
  } = useController({
    name,
    rules,
    defaultValue
  });

  return (
    <div
      role="radiogroup"
      aria-labelledby={id}
      aria-label={label}
      className={styles.FormRadioInput}
    >
      {options.map((option) => (
        <Radio
          key={option.value}
          {...rest}
          {...inputProps}
          {...option}
          checked={value === option.value}
          onChange={() => inputProps.onChange(option.value)}
        />
      ))}
    </div>
  );
};

FormRadioInput.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  options: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    label: PropTypes.string.isRequired
    // value: PropTypes.string.isRequired
  }).isRequired).isRequired,
  name: PropTypes.string,
  rules: PropTypes.object.isRequired,
  vertical: PropTypes.bool
};
