import React, { useCallback, useMemo } from 'react';

import formatCountry from 'common-lib/lib/formatCountry';
import isValidUsBankAccountNumber from 'common-lib/lib/isValidUsBankAccountNumber';
import isValidUsBankRoutingNumber from 'common-lib/lib/isValidUsBankRoutingNumber';

import { FormField, Text } from '@acadeum/ui';
import { useTranslate } from '@acadeum/translate';

import styles from './BankAccountFormFields.module.scss';

export const BankAccountFormFields = () => {
  const t = useTranslate('shared-admin-ui.BankAccountFormFields');

  const ACCOUNT_HOLDER_TYPE = useMemo(() => [
    { label: t('company'), value: 'company' },
    { label: t('individual'), value: 'individual' }
  ], []);

  const validateBankAccountNumber = useCallback((value) => {
    if (!isValidUsBankAccountNumber(value)) {
      return t('invalidAccountNumber');
    }
  }, []);
  const validateBankRoutingNumber = useCallback((value) => {
    if (!isValidUsBankRoutingNumber(value)) {
      return t('invalidRoutingNumber');
    }
  }, []);
  const validateConfirmBankAccountNumber = useCallback((value, { accountNumber }) => {
    if (value !== accountNumber) {
      return t('accountNumbersNotMatch');
    }
  }, []);

  return (
    <>
      <FormField
        required
        placeholder={t('holderNamePlaceholder')}
        label={t('holderNameHint')}
        name="holderName"
        type="name"
      />

      <FormField
        required
        options={ACCOUNT_HOLDER_TYPE}
        defaultValue={ACCOUNT_HOLDER_TYPE[0].value}
        label={t('holderTypeHint')}
        name="holderType"
        type="select"
      />

      <FormField
        required
        placeholder={'*'.repeat(12)}
        name="accountNumber"
        label={t('accountNumberHint')}
        validate={validateBankAccountNumber}
      />

      <FormField
        required
        placeholder={'*'.repeat(12)}
        name="confirmAccountNumber"
        label={t('confirmAccountNumberHint')}
        validate={validateConfirmBankAccountNumber}
      />

      <FormField
        required
        validate={validateBankRoutingNumber}
        name="routingNumber"
        placeholder={'*'.repeat(16)}
        label={t('routingNumberHint')}
      />

      <InlineControl
        title={t('countryHint')}
        value={formatCountry('US', {})}
      />

      <InlineControl
        title={t('currencyHint')}
        value="USD"
      />

      <Text className={styles.AddBankAccountModal__help}>
        {t('help', { lineBreak: () => <br/> })}
      </Text>
    </>
  );
};

function InlineControl({ title, value }) {
  return (
    <div className={styles.InlineControl}>
      <div>{title}</div>
      <div>{value}</div>
    </div>
  );
}
