import canEditUser_ from 'common-lib/lib/canEditUser.js';

import type {
  Institution,
  User,
  UserProfile,
  UserRole,
  UserRolePermissionOperationWithoutRead
} from '@acadeum/types';

import { userHasPermission } from './userHasPermission';

interface CanEditUserOptionsWithUserInstitutionObject {
  byUser: Pick<UserProfile, 'id' | 'roles' | 'institution'> | undefined;
  user: Pick<User, 'id'> & { institution: Pick<Institution, 'id'>, roles: UserRole[] };
  userInstitutionId?: never;
}

interface CanEditUserOptionsWithUserInstitutionId {
  byUser: Pick<UserProfile, 'id' | 'roles' | 'institution'> | undefined;
  user: Pick<User, 'id'> & { institutionId: Institution['id'], roles: UserRole[] };
  userInstitutionId?: never;
}

interface CanEditUserOptionsWithoutUserInstitutionId {
  byUser: Pick<UserProfile, 'id' | 'roles' | 'institution'> | undefined;
  user: Pick<User, 'id'> & { institutionId?: never, roles: UserRole[] };
  userInstitutionId: Institution['id'];
}

type CanEditUserOptions =
  CanEditUserOptionsWithUserInstitutionObject
  | CanEditUserOptionsWithUserInstitutionId
  | CanEditUserOptionsWithoutUserInstitutionId;

export function canEditUser(operation: UserRolePermissionOperationWithoutRead, {
  user,
  userInstitutionId,
  byUser
}: CanEditUserOptions): boolean {
  return canEditUser_({
    user,
    userInstitutionId: userInstitutionId || ('institution' in user ? user.institution.id : user.institutionId),
    byUser,
    userHasPermission,
    operation,
    permissionParameters: {}
  });
}
