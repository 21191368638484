import { loadEnrollmentsPage } from '../../helpers/routes';

import Enrollments from '../Enrollments';

const EnrollmentsTeaching = () => {
  return (
    <Enrollments type="teaching" status="accepted"/>
  );
};
EnrollmentsTeaching.meta = () => ({
  title: 'Teaching Enrollments'
});

EnrollmentsTeaching.load = async ({ user }) => {
  await loadEnrollmentsPage({ user });
};

EnrollmentsTeaching.breadcrumbs = () => [
  ['Manage Enrollments', '/enrollments'],
  'Teaching Enrollments'
];

export default EnrollmentsTeaching;
