import React from 'react';

import { useTranslate } from '@acadeum/translate';
import { ContentSection, FormField } from '@acadeum/ui';

export interface CategorySectionValues {
  categories: string[];
}

export function CategorySection() {
  const t = useTranslate('shared-admin-ui.CourseForm');

  return (
    <ContentSection title={t('category.title')}>
      <FormField
        required
        name="categories.0"
        label={t('category.department.label')}
        placeholder={t('category.department.placeholder')}
      />
      <FormField
        name="categories.1"
        label={t('category.area.label')}
        placeholder={t('category.area.placeholder')}
      />
      <FormField
        noMargin
        name="categories.2"
        label={t('category.additional.label')}
        placeholder={t('category.additional.placeholder')}
      />
    </ContentSection>
  );
}
