import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'react-responsive-ui';

import './Filters.sass';

export default function Filters({
  filters,
  values,
  onChange
}) {
  return filters.map((filter) => {
    const onFilterChange = (value) => {
      onChange({
        ...values,
        [filter.name]: value
      });
    };

    return (
      <div
        key={filter.name}
        className="Filters-filter"
      >
        <span>
          {filter.label}
        </span>
        <Select
          compact
          aria-label={filter.label}
          options={filter.options}
          value={values[filter.name]}
          onChange={onFilterChange}
          className="Filters-filter-control"
        />
      </div>
    );
  });
}

Filters.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any
    }))
  })).isRequired
};
