import type { Id, InstitutionContact, InstitutionContactUser } from '@acadeum/types';
import type { RtkApi } from '@acadeum/helpers';

export type FetchContactsOutput = (Pick<InstitutionContact, 'id' | 'type'> & { user: InstitutionContactUser })[];

type FetchContactOutput = Pick<InstitutionContact, 'id' | 'type'> & { user: InstitutionContactUser }

interface ContactFields {
  id: Id;
  userId: Id;
  type: InstitutionContact['type'];
}

// eslint-disable-next-line 
interface UpdateContactInput extends ContactFields {
}

type CreateContactInput = Pick<ContactFields, 'userId' | 'type'>

export const getContactsApi = (rtkApi: RtkApi) => rtkApi
  .enhanceEndpoints({
    addTagTypes: ['contacts']
  })
  .injectEndpoints({
    endpoints: build => ({
      fetchContacts: build.query<FetchContactsOutput, void>({
        query: () => ({
          url: '/contacts',
          method: 'GET'
        }),
        providesTags: [{ type: 'contacts', id: 'LIST' }]
      }),
      fetchContact: build.query<FetchContactOutput, { id: Id }>({
        query: ({ id }) => ({
          url: `/contacts/${id}`,
          method: 'GET'
        }),
        providesTags: (_result, _error, arg) => [{ type: 'contacts', id: arg.id }]
      }),
      createContact: build.mutation<{ id: Id }, CreateContactInput>({
        query: (body) => ({
          url: '/contacts',
          method: 'POST',
          body
        }),
        invalidatesTags: [{ type: 'contacts', id: 'LIST' }]
      }),
      updateContact: build.mutation<void, UpdateContactInput>({
        query: ({ id, ...body }) => ({
          url: `/contacts/${id}`,
          method: 'PATCH',
          body
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'contacts', id: arg.id }]
      }),
      removeContact: build.mutation<void, { id: Id }>({
        query: ({ id }) => ({
          url: `/contacts/${id}`,
          method: 'DELETE'
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'contacts', id: arg.id }]
      })
    })
  });

export type UseCreateContactMutation = ReturnType<typeof getContactsApi>['useCreateContactMutation']
export type UseFetchContactsQuery = ReturnType<typeof getContactsApi>['useFetchContactsQuery']
export type UseRemoveContactMutation = ReturnType<typeof getContactsApi>['useRemoveContactMutation']
export type UseUpdateContactMutation = ReturnType<typeof getContactsApi>['useUpdateContactMutation']
