import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import RawDataTable, { compileColumns } from '../../components/RawDataTable';
import Section from '../../components/Section';
import Field from '../../components/Field';
import Form from '../../components/Form';
import FormSubmit from '../../components/FormSubmit';
import FormButtons from '../../components/FormButtons';

import actions from '../../actions';

const {
  fetchInstitution,
  fetchAllCourseSubstitutes
} = actions;

export default function AllCourseSubstitutes() {
  const form = useRef();
  const [loading, setLoading] = useState();

  const courseSubstitutes = useSelector(state => state.courseSubstitutes.allSubstitutes);

  const onInstitutionChange = () => {
    form.current.submit();
  };

  async function onSubmit({ institutionId }) {
    setLoading(true);
    // Get institution info.
    // The institution info will be manually added to course substitute objects.
    const institution = await fetchInstitution(institutionId);
    await fetchAllCourseSubstitutes({ institution });
    setLoading(false);
  }

  return (
    <Section title="All Course Mappings">
      <Form
        autoFocus
        className="form"
        ref={form}
        onSubmit={onSubmit}>

        <Field
          required
          name="institutionId"
          label="Institution"
          type="institution"
          superAdmin
          onChange={onInstitutionChange}
        />

        <FormButtons style={{ display: 'none' }}>
          <FormSubmit>
            Show Course Mappings
          </FormSubmit>
        </FormButtons>
      </Form>

      <br/>

      {loading &&
        'Loading...'
      }

      {(!loading && courseSubstitutes) &&
        <RawDataTable
          data={courseSubstitutes}
          columns={columns}
          actions={tableActions}
        />
      }
    </Section>
  );
}

AllCourseSubstitutes.meta = () => ({
  title: 'All Course Mappings'
});

AllCourseSubstitutes.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Course Mappings'
];

const tableActions = [{
  type: 'download',
  options: {
    fileName: 'Course Mappings'
  }
}];

const columns = compileColumns([
  {
    title: 'ID',
    accessor: 'id'
  },
  {
    title: 'HI ID',
    accessor: 'institution.id'
  },
  {
    title: 'HI NAME',
    accessor: 'institution.name'
  },
  {
    title: 'HI COURSE CODE',
    accessor: 'equivalentCourse.code'
  },
  {
    title: 'HI COURSE TITLE',
    accessor: 'equivalentCourse.title'
  },
  {
    title: 'TI ID',
    accessor: 'course.institution.id'
  },
  {
    title: 'TI NAME',
    accessor: 'course.institution.name'
  },
  {
    title: 'TI COURSE CODE',
    accessor: 'course.code'
  },
  {
    title: 'TI COURSE TITLE',
    accessor: 'course.title'
  },
  // {
  //   title: 'TI COURSE DESCRIPTION',
  //   accessor: 'course.description'
  // },
  {
    title: 'LEVEL',
    accessor: 'course.level'
  },
  {
    title: 'CREDIT HOURS',
    accessor: 'course.hours'
  },
  // {
  //   title: 'PREREQS',
  //   accessor: 'prerequisites'
  // },
  // {
  //   title: 'REQUIRED TEXTS',
  //   accessor: 'course.requiredTexts'
  // },
  // {
  //   title: 'SYLLABUS',
  //   accessor: 'course.pathToSyllabus'
  // },
  // {
  //   title: 'TI COURSE NOTES',
  //   accessor: 'course.notes'
  // },
  {
    title: 'EQUIVALENT CREATED',
    accessor: 'createdAt'
  }
]);
