import { CategoryScale, Chart as ChartJS, LinearScale, LineElement, PointElement, Tooltip, Filler } from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler
  // Legend
);
