// Pricing Model documentation:
// https://github.com/Acadeum/Tickets/blob/master/Pricing-Model.md

// TypeScript typings could be found in:
// https://github.com/Acadeum/frontend/blob/develop/packages/helpers/src/getCourseSectionPricingInfo.ts

import getLowestPriceAndPricingVariantForCourseSection from './getLowestPriceAndPricingVariantForCourseSection_.js';

/**
 * Returns course section price for student.
 * @param {object} parameters
 * @param {object} parameters.course
 * @param {object[]} [parameters.homeStudentCourseEnrollmentPricing]
 * @param {number} parameters.costForInstitution
 * @return {object} priceWithSource — An object of shape `{ price?: number, source: { type: string, value: any } }`. `price: undefined` means "don't show the price to students".
 */
export default function getCourseSectionPriceForStudentBasedOnCostForInstitutionWithDetails({
  course: {
    credits,
    level,
    teachingInstitutionId
  },
  costForInstitution,
  homeStudentCourseEnrollmentPricing
}) {
  // Choose the lowest price of all available prices for this course section for students.
  // (if there is any price available)
  const { price, pricingVariant } = getLowestPriceAndPricingVariantForCourseSection(
    costForInstitution,
    homeStudentCourseEnrollmentPricing,
    {
      credits,
      level,
      teachingInstitutionId
    }
  );

  // If there's any custom pricing that is defined for students
  // then return the price and the pricing that was used to calculate that price.
  if (pricingVariant) {
    return {
      price,
      source: {
        type: 'pricing',
        value: pricingVariant
      }
    };
  }

  // By default, it hides the price from the student in the student app.
  // Return `price: undefined`.
  return {};
}
