import { useReducer, useRef } from 'react';
import type { BaseQueryFn, QueryDefinition } from '@reduxjs/toolkit/query';
import type { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';

import type { SortByState } from '@acadeum/types';

type UseFetch<
  QueryArg,
  BaseQuery extends BaseQueryFn,
  TagTypes extends string,
  ResultType,
  ReducerPath extends string = string
> = UseQuery<
  QueryDefinition<
    QueryArg,
    BaseQuery,
    TagTypes,
    ResultType,
    ReducerPath
  >
>

type QueryParameterSimpleValue = string | number | boolean | Date;

// `Record` produces an error: "Type alias 'QueryParameterObjectValue' circularly references itself".
// type QueryParameterObjectValue = Record<string, QueryParameterSimpleValue | QueryParameterObjectValue>;
// A workaround is using an explicit object definition.
// https://stackoverflow.com/a/70501728/970769
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type QueryParameterObjectValue = {
  [key: string]: QueryParameterSimpleValue | QueryParameterObjectValue;
};

type FiltersParams = Record<string, QueryParameterSimpleValue | QueryParameterObjectValue>;

interface InitialParams {
  search?: string;
  filters?: FiltersParams;
  sort?: SortByState;
  additional?: Record<string, any>;
}

const DEFAULT_PAGE_SIZE = 10;

export const useQueryWithPagination = <QueryArg, BaseQuery extends BaseQueryFn, TagTypes extends string, ResultType, ReducerPath extends string = string>(
  useData: UseFetch<QueryArg, BaseQuery, TagTypes, ResultType, ReducerPath>,
  initialParams?: InitialParams
) => {
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const additionalParamsRef = useRef<Record<string, any>>(initialParams?.additional ?? {});
  const sortRef = useRef<SortByState>(initialParams?.sort ?? []);
  const filtersRef = useRef<FiltersParams>({
    ...initialParams?.filters
  });
  const searchQueryRef = useRef(initialParams?.search ?? '');

  const totalCountRef = useRef(0);
  const paginationRef = useRef({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE
  });

  const fetchParams = {
    ...additionalParamsRef.current,
    filters: Object.keys(filtersRef.current).length === 0 ? undefined : filtersRef.current,
    sort: sortRef.current.length === 0 ? undefined : sortRef.current,
    search: searchQueryRef.current || undefined,
    page: paginationRef.current.pageIndex + 1,
    pageSize: paginationRef.current.pageSize
  };

  const resetPagination = () => {
    paginationRef.current = {
      pageIndex: 0,
      pageSize: DEFAULT_PAGE_SIZE
    };
  };

  const onSortByChange = (updaterOrValue) => {
    sortRef.current = typeof updaterOrValue === 'function'
      ? updaterOrValue(sortRef.current)
      : updaterOrValue;
    resetPagination();
    forceUpdate();
  };

  const onFiltersChange = (filters) => {
    filtersRef.current = filters;
    resetPagination();
    forceUpdate();
  };

  const onSearchQueryChange = (updaterOrValue) => {
    searchQueryRef.current = typeof updaterOrValue === 'function'
      ? updaterOrValue(searchQueryRef.current)
      : updaterOrValue;
    resetPagination();
    forceUpdate();
  };

  // const onPageChange = (page: number) => {
  //   paginationRef.current.pageIndex = page - 1;
  //   forceUpdate();
  // };
  //
  // const onPageSizeChange = (pageSize: number) => {
  //   paginationRef.current = {
  //     pageSize,
  //     pageIndex: 0
  //   };
  //   forceUpdate();
  // };

  const onPaginationChange = (updaterOrValue) => {
    paginationRef.current = typeof updaterOrValue === 'function'
      ? updaterOrValue(paginationRef.current)
      : updaterOrValue;
    forceUpdate();
  };

  const onDataUpdate = (data) => {
    if (data) {
      const results = data.results;
      const page = data.page;

      if (!Array.isArray(results) || typeof page !== 'number') {
        throw new Error('`data` must return an object with properties `results` and `page`.');
      }

      if (results.length === 0 && page > 1) {
        return onPaginationChange({
          pageSize: paginationRef.current.pageSize,
          pageIndex: paginationRef.current.pageIndex - 1
        });
      }

      if (data.totalCount !== totalCountRef.current) {
        totalCountRef.current = data.totalCount;
        forceUpdate();
      }
    }
  };

  const result = useData(fetchParams as Parameters<typeof useData>[0], {});
  onDataUpdate(result.data);

  const _paginationOptions = {
    pageSizeOptions: [10, 20, 30, 40],
    manualPagination: true,
    totalCount: totalCountRef.current,
    pageCount: (totalCountRef.current && Math.ceil(totalCountRef.current / paginationRef.current.pageSize)) ?? -1,
    pagination: paginationRef.current,
    onPaginationChange
  };

  const _sortingOptions = {
    manualSorting: true,
    enableSorting: true,
    sorting: sortRef.current,
    onSortingChange: onSortByChange
  };

  const _globalFilterOptions = {
    enableGlobalFilter: true,
    globalFilter: searchQueryRef.current,
    onGlobalFilterChange: onSearchQueryChange
  };

  const _filtersOptions = {
    filters: filtersRef.current,
    onFiltersChange: onFiltersChange
  };

  const onAdditionalParamsChange = (additionalParams) => {
    additionalParamsRef.current = additionalParams;
    resetPagination();
    forceUpdate();
  };

  const _additionalParamsOptions = {
    additionalParams: additionalParamsRef.current,
    onAdditionalParamsChange
  };

  return Object.assign(result, {
    _fetchParams: fetchParams,
    _totalCount: totalCountRef.current,
    _paginationOptions,
    _sortingOptions,
    _globalFilterOptions,
    _filtersOptions,
    _additionalParamsOptions,
    _isInitialLoading: result.isLoading && !result.data,
    _isHasFiltersOrSearch: Object.keys(filtersRef.current).length > 0 || searchQueryRef.current
  });
};
