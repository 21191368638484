import type { FC, ReactNode } from 'react';
import React from 'react';

import { BackButton } from '@acadeum/ui';

import styles from './StepWrapper.module.scss';

interface StepWrapperType {
  title: string;
  description: string;
  children: ReactNode;
  onBack?: () => void;
}

export const StepWrapper: FC<StepWrapperType> = ({
  title,
  description,
  children,
  onBack
}) => {
  return (
    <div className={styles.StepWrapper}>
      <div className={styles.StepWrapper__sidebar}>
        {title}

        {typeof onBack === 'function' && (
          <BackButton onClick={onBack}/>
        )}
      </div>
      <div className={styles.StepWrapper__content}>
        <p className={styles.StepWrapper__copy}>
          {description}
        </p>
        {children}
      </div>
    </div>
  );
};
