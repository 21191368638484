import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const fetchAllCourseSubstitutes = redux.action(
  ({ institution }) => async (http) => {
    // Courses are expanded into course substitutes here
    // to work around "body size is too long" error.
    // https://github.com/oseibonsu/CollegeConsortiumTickets/issues/233
    const courses = await http.get('/data/course-substitutes', { institutionId: institution.id });
    return courses.reduce((allSubstitutes, course) => {
      return allSubstitutes.concat(course.courseSubstitutes.map((substitute) => ({
        ...substitute,
        institution,
        course
      })));
    }, []);
  },
  'allSubstitutes'
);

export const fetchCourseSubstitutes = redux.action(
  (id) => (http) => http.get(`/courses/${id}/substitutes`),
  'substitutes'
);

export const addCourseSubstitute = redux.action(
  (values) => (http) => {
    Intercom('trackEvent', 'add course substitute');
    return http.post('/course-substitutes', values);
  }
);

export const deleteCourseSubstitute = redux.action(
  (id) => (http) => {
    Intercom('trackEvent', 'delete course substitute');
    return http.delete(`/course-substitutes/${id}`);
  }
);

export default redux.reducer();
