import { TextInput } from 'react-responsive-ui';

import getNumberInputParams from './number.js';

import PrePostFixInput from '../../PrePostFixInput';

export default function getPercentInputParams({ onChange }) {
  const props = getNumberInputParams({
    onChange
  });
  return {
    // `type: "number"` is read by `<PrePostFixInput/>`.
    ...props,
    InputComponent: PrePostFixInput,
    component: TextInput,
    postfix: '%'
  };
}
