import { getStudentUsersApi } from '@acadeum/api';

import { rtkApi } from './rtkApi';

export const {
  useCreateStudentUserMutation,
  useUpdateStudentUserMutation,
  useActivateStudentUserMutation,
  useDeactivateStudentUserMutation,
  useDeleteStudentUserMutation
} = getStudentUsersApi(rtkApi);
