import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const uploadMappings = redux.action(
  (rows) => (http) => http.post('/upload-mappings', rows),
  'result'
);

export default redux.reducer();
