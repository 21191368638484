import React from 'react';

import type { Institution } from '@acadeum/types';

import './GradingScale.sass';

interface GradingScaleProps {
  institutionName: Institution['name'];
  gradingScale: Institution['data']['gradingScale'];
  gradingScaleUrl: Institution['data']['gradingScaleUrl'];
  gradingScaleNotes: Institution['data']['gradingScaleNotes'];
}

export default function GradingScale({
  institutionName,
  gradingScale,
  gradingScaleUrl,
  gradingScaleNotes
}: GradingScaleProps) {
  
  return (
    <div>
      {!gradingScale && !gradingScaleNotes && !gradingScaleUrl &&
        <p style={{ margin: 0 }}>
          To be provided later by the {institutionName}.
        </p>
      }
      {gradingScaleNotes &&
        <p style={{ margin: 0 }}>
          {gradingScaleNotes}
        </p>
      }
      {gradingScale &&
        <p style={{ margin: 0, fontWeight: 'bolder' }}>
          {institutionName} uses the following grading scale:
        </p>
      }
      {gradingScale &&
        <table className="table table--striped grading-scale__table">
          <thead>
            <tr>
              <th>Grade</th>
              <th>Descriptive Grade</th>
              <th>Numeric Grade</th>
              <th>Grade Points</th>
            </tr>
          </thead>
          <tbody>
            {gradingScale.map((grade, i) => {
              return (
                <tr key={i}>
                  <td>{grade.grade}</td>
                  <td>{grade.description !== undefined ? grade.description : '–'}</td>
                  <td>{grade.numeric !== undefined ? grade.numeric : '–'}</td>
                  <td>{grade.points !== undefined ? grade.points : '-'}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      }
      {gradingScaleUrl &&
        <a
          target="_blank"
          href={gradingScaleUrl}
          className="grading-scale__link">
          Source
        </a>
      }
    </div>
  );
}
