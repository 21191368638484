import React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import { GradingScale } from '../../../GradingScale';

export const FormGradingScale = ({
  name,
  defaultValue,
  rules,
  ...rest
}) => {
  const { field } = useController({ name, defaultValue, rules });

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <GradingScale {...rest} {...field}/>
  );
};

FormGradingScale.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.shape({
    grade: PropTypes.string,
    description: PropTypes.string,
    numeric: PropTypes.string,
    points: PropTypes.number
  })),
  rules: PropTypes.object.isRequired
};
