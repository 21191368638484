import type { RowData } from '@tanstack/react-table';
import uniq from 'lodash/uniq';

import parseTerm from 'common-lib/lib/parseTerm';

import { Select } from '../../../Select';

import type { FilterItemObject } from '../types';

export const createTableYearFilter = <TData extends RowData>({
  getTerms,
  getTerm = row => row['section']['session']['term']
}: {
  getTerms?: (row: TData) => string[]
  getTerm?: (row: TData) => string
} = {}): FilterItemObject<TData> => {
  const getYear = row => parseTerm(getTerm(row))[1] as string;
  const getYears = row => {
    if (!getTerms) {
      throw new Error();
    }
    return getTerms(row).map(_ => parseTerm(_)[1]) as string[];
  };
  return {
    id: 'year',
    filter: (row, value) => {
      if (getTerms) {
        return getYears(row).includes(value);
      } else {
        return getYear(row) === value;
      }
    },
    render: ({ setFilterValues, getValue, rows }) => {
      let years;
      if (getTerms) {
        years = rows.reduce<string[]>((all, row) => all.concat(getYears(row)), []);
      } else {
        years = rows.map((row) => getYear(row));
      }
      years = uniq(years).filter(Boolean);

      const options = [
        { label: 'All', value: null },
        ...years.map((year) => ({
          label: String(year),
          value: year
        }))
      ];

      return (
        <Select
          isFilter
          label="Year"
          options={options}
          value={getValue() ?? null}
          onChange={(value, option) => {
            setFilterValues(prev => ({
              ...prev,
              year: option,
              // Reset filter for term.
              term: undefined,
              // Reset filter for session
              session: undefined
            }));
          }}
        />
      );
    }
  };
};
