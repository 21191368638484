import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SearchFilterListItem from '../SearchFilterListItem';
import SearchFilterItem from '../SearchFilterItem';
import InstantSearch from '../InstantSearch';

import InstitutionSearchResult from './InstitutionSearchResult';
import InstitutionSearchResultRow from './InstitutionSearchResultRow';

import {
  sortInstitutionFundingSearchFilterOptions,
  sortInstitutionLevelSearchFilterOptions
} from '../../helpers/sortInstitutionLevelAndFundingSearchFilterOptions.js';

import './InstitutionSearch_.sass';

export default class InstitutionSearch extends Component {
  static propTypes = {
    layout: PropTypes.string,
    consortiumName: PropTypes.string,
    searchFieldBanner: PropTypes.bool,
    className: PropTypes.string
  };

  useFilters = () => {
    const { consortiumName } = this.props;

    return (
      <ul className="FilterList">
        <SearchFilterListItem className="InstantSearch-Toggle">
          <SearchFilterItem
            type="toggle"
            title="Teaching Schools"
            attribute="teaching"
          />
        </SearchFilterListItem>

        <SearchFilterListItem className="InstantSearch-Toggle">
          <SearchFilterItem
            type="toggle"
            title="QM Online Course Sharing"
            attribute="qualityMattersDesignation"
          />
        </SearchFilterListItem>

        <SearchFilterListItem>
          <SearchFilterItem
            type="multiSelectList"
            attribute="level"
            transformItems={sortInstitutionLevelSearchFilterOptions}
            title={FILTER_NAMES['level']}
          />
        </SearchFilterListItem>

        <SearchFilterListItem>
          <SearchFilterItem
            type="multiSelectList"
            attribute="control"
            transformItems={sortInstitutionFundingSearchFilterOptions}
            title={FILTER_NAMES['control']}
          />
        </SearchFilterListItem>

        <SearchFilterListItem>
          <SearchFilterItem
            type="multiSelectList"
            title={FILTER_NAMES['affiliations']}
            attribute="affiliations"
          />
        </SearchFilterListItem>

        <SearchFilterListItem>
          <SearchFilterItem
            type="multiSelectList"
            title={FILTER_NAMES['accreditations.name']}
            attribute="accreditations.name"
          />
        </SearchFilterListItem>

        {/* Alternatively "virtual widgets" could be used for default refinements */}
        {/* https://community.algolia.com/react-instantsearch/guide/Default_refinements.html */}
        <SearchFilterListItem hidden={!!consortiumName}>
          <SearchFilterItem
            type="multiSelectList"
            title={FILTER_NAMES['consortiaNames']}
            attribute="consortiaNames"
            defaultRefinement={consortiumName ? [consortiumName] : undefined}
          />
        </SearchFilterListItem>

        <SearchFilterListItem>
          <SearchFilterItem
            type="multiSelectList"
            title={FILTER_NAMES['state']}
            attribute="state"
          />
        </SearchFilterListItem>

        <SearchFilterListItem>
          <SearchFilterItem
            type="multiSelectList"
            title={FILTER_NAMES['country']}
            attribute="country"
          />
        </SearchFilterListItem>

        <SearchFilterListItem>
          <SearchFilterItem
            type="multiSelectList"
            title={FILTER_NAMES['learningManagementSystem']}
            attribute="learningManagementSystem"
          />
        </SearchFilterListItem>

        <SearchFilterListItem>
          <SearchFilterItem
            type="multiSelectList"
            title={FILTER_NAMES['studentInformationSystem']}
            attribute="studentInformationSystem"
          />
        </SearchFilterListItem>
      </ul>
    );
  };

  canFilterBeCleared = (item) => {
    const { consortiumName } = this.props;

    switch (item.attribute) {
      case 'consortiaNames':
        // If consortium name is pre-defined then it's unclearable.
        if (consortiumName) {
          return false;
        }
        break;
    }

    return true;
  };

  render() {
    const {
      layout,
      searchFieldBanner,
      ...rest
    } = this.props;

    const indexes = [
      { value: 'institutions', label: 'Institutions (A-Z)' },
      { value: 'institutions_desc', label: 'Institutions (Z-A)' }
    ];

    return (
      <InstantSearch
        searchFieldBanner={searchFieldBanner ? 'What institution are you looking for?' : undefined}
        searchFieldLabel="Institution Name"
        component={layout === 'row' ? InstitutionSearchResultRow : InstitutionSearchResult}
        indexes={indexes}
        useFilters={this.useFilters}
        filterNames={FILTER_NAMES}
        canFilterBeCleared={this.canFilterBeCleared}
        layout={layout}
        {...rest} />
    );
  }
}

// `filterNames` (ARIA-related) are passed to `<InstantSearch/>`.
// Toggle filter refinement labels are the same as the filters' names.
// "Multi Select" filter refinement labels though don't have the actual filter name in them,
// and a "screen reader" user might not always get the correct context
// just by hearing the selected value of a "Multi Select" filter.
// That's the reason why `filterNames` are passed for such filters.
const FILTER_NAMES = {
  'level': 'Institution Level',
  'control': 'Institution Type',
  'affiliations': 'Affiliation',
  'accreditations.name': 'Accreditation',
  'consortiaNames': 'Consortium',
  'state': 'State',
  'country': 'Country',
  'learningManagementSystem': 'LMS',
  'studentInformationSystem': 'SIS'
};
