import { userHasPermission } from '@acadeum/helpers';
import { UserRequestsPage } from '@acadeum/shared-admin-ui';

import {
  useApproveUserAccountRequestsMutation,
  useDenyUserAccountRequestsMutation,
  useFetchUserAccountRequestsQuery
} from '../../api/userAccountRequests';
import { useFetchUserRolesQuery } from '../../api/userRoles';
import type { AppPage } from '../../helpers/app.types';

const UserRequests: AppPage = () => {
  return (
    <UserRequestsPage
      useFetchUserRolesQuery={useFetchUserRolesQuery}
      useApproveUserAccountRequestsMutation={useApproveUserAccountRequestsMutation}
      useDenyUserAccountRequestsMutation={useDenyUserAccountRequestsMutation}
      useFetchUserAccountRequestsQuery={useFetchUserAccountRequestsQuery}
    />
  );
};

UserRequests.meta = () => ({
  title: 'User Requests'
});

UserRequests.when = ({ user }) => userHasPermission(user, 'user:create', {
  orgId: user?.institution.id,
  ownerId: null
});


export default UserRequests;
