import React, { memo } from 'react';

export interface BooleanBlockProps {
  value?: boolean;
}

export const BooleanBlock: React.FC<BooleanBlockProps> = memo(({
  value
}) => {
  return (
    <>
      {value ? 'Yes' : 'No'}
    </>
  );
});
