import type { FC, ReactNode } from 'react';
import React, { useImperativeHandle, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import classNames from 'classnames';

import { CaretDownIcon } from '@acadeum/icons';

import type { ColorPickerProps } from '../ColorPicker';
import { ActiveColor, ColorPicker, colorToState } from '../ColorPicker';
import { OnClickOrTapOutside } from '../OnClickOrTapOutside';
import { ReactPortal } from '../ReactPortal';
import { BaseButton } from '../BaseButton';
import { HStack } from '../Stack';
import { Icon } from '../Icon';

import styles from './ColorInput.module.scss';

export interface ColorInputProps {
  className?: string;
  value?: ColorPickerProps['value'];
  onChange?: ColorPickerProps['onChange'];
  onBlur?: (event: Event) => void;
  disabled?: boolean;
  readOnly?: boolean;
  children?: ReactNode;
  label: string;
}

export const ColorInput: FC<ColorInputProps> = React.forwardRef(({
  value,
  onChange,
  disabled,
  readOnly,
  children,
  label
}, ref) => {
  useImperativeHandle(ref, () => ({
    focus: () => {
      /* noop */
    }
  }));

  const [expanded, setExpanded] = useState(false);

  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const modifiers = useMemo(() => [
    {
      name: 'offset',
      enabled: true,
      options: {
        offset: [0, 4]
      }
    }
  ], []);

  const open = () => {
    setExpanded(true);
    popperElement?.focus();
  };

  const close = () => {
    setExpanded(false);
  };

  const {
    styles: popperStyles,
    attributes: popperAttributes
  } = usePopper(referenceElement, popperElement, {
    strategy: 'fixed',
    placement: 'bottom-start',
    modifiers
  });

  const hex = value?.['hex'] || value;
  const color = typeof hex === 'string' ? hex : colorToState(hex, 0).hex;

  return (
    <>
      <BaseButton
        className={styles.Toggle}
        ref={setReferenceElement}
        disabled={disabled || readOnly}
        onClick={expanded ? close : open}
        aria-expanded={expanded}
        aria-label={label}
      >
        <HStack as="span" gap="sm">
          <ActiveColor
            hex={color}
            size="1.25rem"
            borderRadius="0.1875rem"
          />
          {children || color}
        </HStack>
        <Icon
          icon={CaretDownIcon}
          className={classNames({
            [styles.hidden]: readOnly
          })}
        />
      </BaseButton>
      {expanded && (
        <ReactPortal>
          <OnClickOrTapOutside
            containerRef={{ current: popperElement }}
            toggleRef={{ current: referenceElement }}
            onClickOutside={close}
            onTapOutside={close}
          >
            <div
              tabIndex={-1}
              ref={setPopperElement}
              style={popperStyles.popper}
              className={styles.Popper}
              {...popperAttributes.popper}
            >
              <ColorPicker
                label={label}
                value={value}
                onChange={onChange}
                onClose={close}
              />
            </div>
          </OnClickOrTapOutside>
        </ReactPortal>
      )}
    </>
  );
});
