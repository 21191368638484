import type { FC } from 'react';

import { Tag, Tooltip } from '@acadeum/ui';

export const ConsortialBadge: FC = () => {
  return (
    <Tooltip
      content="Your institution is in a consortial relationship with the teaching institution of this course. Your institution can apply financial aid to enrollments and allow students to include grades they receive within their campus GPAs."
    >
      <Tag variant="consortial"/>
    </Tooltip>
  );
};
