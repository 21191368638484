import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Checkbox } from 'react-responsive-ui';

import { BaseButton as Button } from '@acadeum/ui';

import Field from '../../components/Field';
import Form from '../../components/Form';
import FormSubmit from '../../components/FormSubmit';
import FormModal from '../../components/FormModal';
import Loading from '../../components/Loading';
import Row from '../../components/Row';
import Col from '../../components/Col';

import RawDataTable, { compileColumns } from '../../components/RawDataTable';
import Section from '../../components/Section';

import actions from '../../actions';

const {
  fetchAllTeachingEnrollmentsAcadeumAdministrator,
  refundEnrollments,
  notify,
  notifyError
} = actions;

const DEFAULT_PAGE_SIZE = 100;

export default function AllEnrollments() {
  const enrollments = useSelector(state => state.enrollmentRequestsTM.allTeachingEnrollments);
  const totalCount = useSelector(state => state.enrollmentRequestsTM.allTeachingEnrollmentsCount);

  const isMounted = useRef();

  const [isLoading, setLoading] = useState();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const [showRefundEnrollmentsModal, setShowRefundEnrollmentsModal] = useState();
  const [refundedEnrollmentIds, setRefundedEnrollmentIds] = useState();

  const onRefundEnrollments = async (selectedRows) => {
    setRefundedEnrollmentIds(selectedRows.map(_ => _.id));
    setShowRefundEnrollmentsModal(true);
  };

  const onConfirmRefundEnrollments = async ({ amount, notes }) => {
    await refundEnrollments(refundedEnrollmentIds, { amount, notes });
    setShowRefundEnrollmentsModal(false);
    notify(`The refund${refundedEnrollmentIds.length === 1 ? '' : 's'} ha${refundedEnrollmentIds.length === 1
      ? 's'
      : 've'} been issued`);
  };

  const applyPageSize = async ({ pageSize }) => {
    setPageSize(pageSize || DEFAULT_PAGE_SIZE);
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        await fetchAllTeachingEnrollmentsAcadeumAdministrator({ page, pageSize });
      } catch (error) {
        notifyError(error.message);
      } finally {
        setLoading(false);
      }
    }

    if (isMounted.current) {
      void fetchData();
    }
  }, [page, pageSize]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const tableActions = [
    {
      type: 'download',
      options: {
        fileName: 'Enrollments'
      }
    }, {
      title: (selectedRows) => {
        let title = 'Refund';
        if (selectedRows.length > 0) {
          title += ` ${selectedRows.length} enrollment${selectedRows.length > 1 ? 's' : ''}`;
        }
        return title;
      },
      onClick: onRefundEnrollments,
      isActive: (selectedRows) => {
        return selectedRows.filter(_ => !_.paid).length === 0;
      }
    }
  ];

  const pageCount = Math.ceil(totalCount / pageSize);
  const pageNumbers = [];

  let i = 1;
  while (i <= pageCount) {
    pageNumbers.push(i);
    i++;
  }

  return (
    <Section title="All Enrollments">
      {/*
        The table becomes very laggy when not using its own pagination:
        pageSize={pageSize}
        showPagination={false}
      */}

      <RawDataTable
        totalCount={totalCount > enrollments.length ? totalCount : undefined}
        data={enrollments}
        columns={columns}
        actions={tableActions}/>

      <br/>

      <div className="Pagination">
        {pageNumbers.map((pageNumber) => (
          <Button
            key={pageNumber}
            onClick={() => setPage(pageNumber)}
            disabled={isLoading}
            style={{ marginTop: '0.5rem' }}
            className={classNames('Pagination-pageButton', {
              'Pagination-pageButton--selected': pageNumber === page
            })}>
            {pageNumber}
          </Button>
        ))}
      </div>

      <br/>

      {isLoading &&
        <Loading margin="0" width="16px" height="16px"/>
      }

      {!isLoading &&
        <div style={{ height: '16px' }}/>
      }

      <br/>

      <Form
        autoFocus
        className="form"
        onSubmit={applyPageSize}>

        <Row alignChildren="bottom">
          <Field
            name="pageSize"
            type="integer"
            label="Page Size"
            value={pageSize}
            col={10}/>

          <Col col={2} xs={12}>
            <FormSubmit
              busy={isLoading}
              className="form__button--inline-except-xs form__button--wide">
              Apply
            </FormSubmit>
          </Col>
        </Row>
      </Form>

      <FormModal
        title="Refund Enrollments"
        description={`Please explain your reasoning for refunding the enrollment${refundedEnrollmentIds && refundedEnrollmentIds.length === 1
          ? ''
          : 's'}:`}
        submitText="Refund Enrollments"
        onSubmit={onConfirmRefundEnrollments}
        show={showRefundEnrollmentsModal}
        onHide={() => setShowRefundEnrollmentsModal(false)}>
        <RefundFormContent multiple={refundedEnrollmentIds && refundedEnrollmentIds.length > 1}/>
      </FormModal>
    </Section>
  );
}

AllEnrollments.propTypes = {
  enrollments: PropTypes.arrayOf(PropTypes.object),
  totalCount: PropTypes.number
};

AllEnrollments.meta = () => ({
  title: 'All Enrollments'
});

AllEnrollments.load = async () => {
  await fetchAllTeachingEnrollmentsAcadeumAdministrator({ pageSize: DEFAULT_PAGE_SIZE });
};

AllEnrollments.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'All Enrollments'
];

function RefundFormContent({ multiple }) {
  const [partialAmount, setPartialAmount] = useState();
  return (
    <React.Fragment>
      <Field
        required
        name="notes"
        multiline
      />
      <Field
        name="partialAmount"
        component={Checkbox}
        onChange={setPartialAmount}>
        I want to refund a partial amount
      </Field>
      {partialAmount &&
        <Field
          required
          autoFocus
          name="amount"
          label={'Amount' + (multiple ? ' (per enrollment)' : '')}
          type="number"/>
      }
    </React.Fragment>
  );
}

RefundFormContent.propTypes = {
  multiple: PropTypes.bool
};

const columns = compileColumns([
  {
    title: 'ID',
    accessor: 'id'
  },
  {
    title: 'Paid',
    accessor: 'paid'
  },
  {
    title: 'Consortial',
    accessor: 'consortial'
  },
  {
    title: 'Grade',
    accessor: 'letterGrade'
  },
  {
    title: 'EM Name',
    accessor: 'student.institution.name'
  },
  {
    title: 'TM Name',
    accessor: 'section.session.course.institution.name'
  },
  {
    title: 'Status',
    accessor: 'status'
  },
  {
    title: 'Student Id',
    accessor: 'student.hiStudentId'
  },
  {
    title: 'First Name',
    accessor: 'student.firstName'
  },
  {
    title: 'Middle Name',
    accessor: 'student.middleName'
  },
  {
    title: 'Last Name',
    accessor: 'student.lastName'
  },
  {
    title: 'Email',
    accessor: 'student.email'
  },
  {
    title: 'Phone',
    accessor: 'student.phone'
  },
  {
    title: 'Advisor Name',
    accessor: 'student.advisorName'
  },
  {
    title: 'Advisor Email',
    accessor: 'student.advisorEmail'
  },
  {
    title: 'Date Created',
    accessor: 'createdAt'
  },
  {
    title: 'Course Code',
    accessor: 'section.session.course.code'
  },
  {
    title: 'Course title',
    accessor: 'section.session.course.title'
  },
  {
    title: 'Course Category',
    accessor: 'section.session.course.category'
  },
  {
    title: 'Start Date',
    accessor: 'section.session.startDate'
  },
  {
    title: 'End Date',
    accessor: 'section.session.endDate'
  },
  {
    title: 'Drop Date',
    accessor: 'section.session.lastDropDate'
  },
  {
    title: 'Cost',
    accessor: 'costForInstitution'
  },
  {
    title: 'Term',
    accessor: 'section.session.term'
  },
  {
    title: 'Session',
    accessor: 'section.session.name'
  },
  {
    title: 'Internal Term',
    accessor: 'section.session.internalTerm'
  },
  {
    title: 'Internal Session',
    accessor: 'section.session.internalName'
  },
  {
    title: 'Syllabus',
    accessor: 'section.session.course.pathToSyllabus'
  },
  {
    title: 'Required Texts',
    accessor: 'section.session.course.requiredTexts'
  },
  {
    title: 'Substitute Course Code',
    accessor: 'courseSubstitute.equivalentCourse.code'
  },
  {
    title: 'Substitute Course Title',
    accessor: 'courseSubstitute.equivalentCourse.title'
  },
  {
    title: 'Enroll Reason',
    accessor: 'enrollReason'
  },
  {
    title: 'Enroll Reason Notes',
    accessor: 'enrollReasonNotes'
  },
  {
    title: 'Student Drop Reason',
    accessor: 'studentDropReason'
  },
  {
    title: 'Student Drop Reason Notes',
    accessor: 'studentDropReasonNotes'
  },
  {
    title: 'Home Drop Reason',
    accessor: 'homeDropReason'
  },
  {
    title: 'Dome Drop Reason Notes',
    accessor: 'homeDropReasonNotes'
  },
  {
    title: 'Teaching Drop Reason',
    accessor: 'teachingDropReason'
  },
  {
    title: 'Teaching Drop Reason Notes',
    accessor: 'teachingDropReasonNotes'
  },
  {
    title: 'Student Withdraw Reason',
    accessor: 'studentWithdrawReason'
  },
  {
    title: 'Student Withdraw Reason Notes',
    accessor: 'studentWithdrawReasonNotes'
  },
  {
    title: 'Home Withdraw Reason',
    accessor: 'homeWithdrawReason'
  },
  {
    title: 'Home Withdraw Reason Notes',
    accessor: 'homeWithdrawReasonNotes'
  },
  {
    title: 'Teaching Withdraw Reason',
    accessor: 'teachingWithdrawReason'
  },
  {
    title: 'Teaching Withdraw Reason Notes',
    accessor: 'teachingWithdrawReasonNotes'
  },
  {
    title: 'Deny Reason',
    accessor: 'denyReason'
  },
  {
    title: 'Deny Reason Notes',
    accessor: 'denyReasonNotes'
  },
  {
    title: 'Remove Reason',
    accessor: 'removeReason'
  },
  {
    title: 'Remove Reason Notes',
    accessor: 'removeReasonNotes'
  }
]);
