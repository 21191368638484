import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Checkbox } from 'react-responsive-ui';

import Output from '../../components/Output';
import Outputs from '../../components/Outputs';
import Section from '../../components/Section';
import Subsection from '../../components/Subsection';

import { formatUserName } from '../../helpers/format';
import isAcadeumAdministrator from '../../helpers/isAcadeumAdministrator';
import canEditUser from '../../helpers/canEditUser';

import useRolesList from '../../hooks/useRolesList';

import InactiveIcon from '../../../assets/images/icons/cross-medium.svg';

import userRoleIds from 'common-lib/constants/userRoleIds.json';

import '../AdminEditUser/UserForm.sass';
import './UserView.sass';

export default function UserView({
  user,
  userRoles,
  adminPanel
}) {
  const { user: currentUser } = useSelector(state => state.auth);

  const rolesList = useRolesList(userRoles);

  const canViewAcadeumRoles = rolesList.some(_ => _.id === userRoleIds['Acadeum Administrator']);
  const userBeingViewedHasAcadeumRolesAssigned = isAcadeumAdministrator(user);

  const showAcadeumStaffRoleOnly = userBeingViewedHasAcadeumRolesAssigned && !canViewAcadeumRoles;

  return (
    <Section
      title={formatUserName(user)}
      inlineActions
      actions={canEditUser('update', { user, userInstitutionId: currentUser.institution.id, byUser: currentUser })
        ? [{
          icon: 'edit',
          title: 'Edit User',
          link: `/admin/users/${user.id}/edit`
        }]
        : []
      }>

      {!user.isActive &&
        <div className="UserView-deactivated">
          <InactiveIcon className="UserView-deactivatedIcon"/>
          The user has been deactivated
        </div>
      }

      <Outputs>
        <Subsection title="Personal Information">
          <Output
            label="Title"
            value={user.title}
          />

          <Output
            label="Email"
            value={user.email}
            type="email"
          />

          <Output
            type="phone"
            label="Phone"
            value={user.phone}
            ext={user.phoneExt}
          />

          {adminPanel &&
            <Output
              label="Institution"
              value={user.institution.name}
              linkTo={`/institutions/${user.institution.vanityUrl || user.institution.id}`}
            />
          }

          {adminPanel &&
            <Output
              label="SFTP Access"
              value={user.sftp ? 'Yes' : 'No'}
            />
          }
        </Subsection>

        <Subsection title="User Roles">
          {showAcadeumStaffRoleOnly &&
            <Checkbox
              key="acadeum-administrator-role"
              multiline
              disabled
              label="Acadeum Administrator"
              value
              className="user-form__role user-form-radio">
              <h3 className="user-form__role-name">
                Acadeum Staff
              </h3>
              <p className="user-form__role-description">
                Acadeum team member
              </p>
            </Checkbox>
          }

          {!showAcadeumStaffRoleOnly && rolesList.map((pair, i) => (
            <React.Fragment key={i}>
              {pair.map((role, i) => (
                <Checkbox
                  key={i}
                  multiline
                  disabled
                  label={role.name}
                  value={user.roles.some(_ => _.id === role.id)}
                  className="user-form__role user-form-radio">
                  <h3 className="user-form__role-name">
                    {role.name}
                  </h3>
                  <p className="user-form__role-description">
                    {role.description}
                  </p>
                </Checkbox>
              ))}
            </React.Fragment>
          ))}
        </Subsection>
      </Outputs>
    </Section>
  );
}

UserView.propTypes = {
  user: PropTypes.object.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  superAdmin: PropTypes.bool,
  adminPanel: PropTypes.bool
};
