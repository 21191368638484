export function isToday(someDate) {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
}

export function isYesterday(someDate) {
  const yesterday = new Date(); // Today
  yesterday.setDate(yesterday.getDate() - 1); // Set yesterday
  return (
    someDate.getDate() === yesterday.getDate() &&
    someDate.getMonth() === yesterday.getMonth() &&
    someDate.getFullYear() === yesterday.getFullYear()
  );
}

export function isCurrentMonth(someDate) {
  const now = new Date();
  return someDate.getMonth() === now.getMonth() && someDate.getFullYear() === now.getFullYear();
}

export function isPrevMonth(someDate) {
  const now = new Date();
  now.setMonth(now.getMonth() - 1); // Set previous month
  return someDate.getMonth() === now.getMonth() && someDate.getFullYear() === now.getFullYear();
}

export function isCurrentYear(someDate) {
  const now = new Date();
  return now.getFullYear() === someDate.getFullYear();
}
