import PropTypes from 'prop-types';
import { Children, Component } from 'react';
import ReactDOM from 'react-dom';
import { connectScrollTo } from 'react-instantsearch-dom';

/**
 * Scrolls to the top of the search component
 * when a user clicks on a pagination page.
 * Only adds an `offset` property to the standard `<ScrollTo/>` behavior.
 * Is disabled in "development" mode because otherwise
 * Algolia search would use 100% CPU sometimes.
 * In production mode there seem to be no issues.
 * https://github.com/algolia/react-instantsearch/issues/2396
 */
class InstantSearchScrollToOnPageNavigation extends Component {
  static propTypes = {
    value: PropTypes.any,
    children: PropTypes.node,
    hasNotChanged: PropTypes.bool,
    offset: PropTypes.number.isRequired
  };

  static defaultProps = {
    offset: 0
  };

  componentDidUpdate(prevProps) {
    const { value, hasNotChanged } = this.props;
    // If `value` is `null` then it means
    // that pagination hasn't been clicked yet.
    if (value === null) {
      return;
    }
    // `prevProps.value` is `null` the first time.
    const previousPage = prevProps.value || 1;
    if (value !== previousPage && hasNotChanged) {
      // eslint-disable-next-line react/no-find-dom-node
      const offset = ReactDOM.findDOMNode(this).offsetTop;
      window.scrollTo(0, offset - this.props.offset);
    }
  }

  render() {
    const { children } = this.props;
    return Children.only(children);
  }
}

export default connectScrollTo(InstantSearchScrollToOnPageNavigation);

// `<ScrollTo/>` (which internally uses `@connectScroll()` connector)
// frequently results in 100% CPU usage and page freezing
// when `react-hot-loader` is being used.
// In production mode there seem to be no issues.
// https://github.com/algolia/react-instantsearch/issues/2396
export function InstantSearchScrollToOnPageNavigationStub(props) {
  return props.children;
}
