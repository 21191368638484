import { injectStripeEndpoints } from '@acadeum/api';

import { rtkApi } from './rtkApi';

export const {
  useFetchTransfersQuery,
  useFetchPaymentIntentsQuery,
  useLazyFetchTransfersQuery,
  useLazyFetchPaymentIntentsQuery
} = injectStripeEndpoints(rtkApi);
