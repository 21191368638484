import { useState } from 'react';

export default function useCollapsibleSections() {
  const [collapsedSections, setCollapsedSections] = useState({});

  const toggleSection = (i) => {
    setCollapsedSections({
      ...collapsedSections,
      [i]: !collapsedSections[i]
    });
  };

  return [
    collapsedSections,
    toggleSection
  ];
}
