import React from 'react';
import PropTypes from 'prop-types';

import styles from './FormGroup.module.scss';

export interface FormGroupProps {
  title: string;
  children: React.ReactNode;
}

export const FormGroup: React.FC<FormGroupProps> = ({ title, children }) => {
  return (
    <fieldset className={styles.FormGroup}>
      <legend className={styles.FormGroup__title}>{title}</legend>
      {children}
    </fieldset>
  );
};

FormGroup.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node
};
