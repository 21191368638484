import formatEthnicity, { ETHNICITIES } from 'common-lib/lib/formatEthnicity.js';

import type { Language, Options } from '@acadeum/types';

export function getEthnicityOptions({ language, own }: { language: Language; own?: boolean }): Options<string> {
  return ETHNICITIES.concat('-').map((ethnicity) => ({
    value: ethnicity,
    label: formatEthnicity(ethnicity, { language, input: true, own })
  }));
}
