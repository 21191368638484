import config from 'config';

import { getAlgoliaIndex as getAlgoliaIndex_ } from '@acadeum/helpers';

function getAlgoliaIndexName (indexKey, { stagePrefix } = {}) {
  if (stagePrefix === false) {
    return indexKey;
  }
  return (config.algolia.prefix || '') + indexKey;
}

export function getAlgoliaIndex (indexKey, { stagePrefix } = {}) {
  return getAlgoliaIndex_(getAlgoliaIndexName(indexKey, { stagePrefix }), {
    appId: config.algolia.appID,
    apiKey: config.algolia.apiKey
  });
}

// Algolia updates take effect within 10-20 seconds or so.
// Could be implemented as a "job" but that would be too much "moving parts".
export const ALGOLIA_UPDATE_PENDING_NOTE = ' Changes will take place in approximately 20 seconds. Please refresh your browser after that to view the changes.';
