import { ReduxModule } from 'react-pages';

import { getAlgoliaIndex } from '../helpers/algolia';

const redux = new ReduxModule();

// Some pages, like "Change User's Institution" page,
// include an institution select, which is populated from Algolia.
export const fetchInstitutions = redux.action(
  () => async () => {
    return await getAlgoliaIndex('institutions').findAll({ limit: 1000 });
  },
  'institutions'
);

export const fetchInstitution = redux.action(
  (id) => (http) => {
    return http.get(`/institutions/${id}?`, {
      include: JSON.stringify(['primaryContact', 'enrollingStudentContact', 'teachingStudentContact'])
    });
  },
  'institution'
);

export const fetchInstitutionForEditing = redux.action(
  (id) => (http) => {
    return http.get(`/institutions/${id}`, { edit: '✓' });
  },
  'institution'
);

export const createInstitution = redux.action(
  (values) => async (http) => {
    return await http.post('/institutions', values);
  }
);

export const deleteInstitution = redux.action(
  (id) => (http) => http.delete(`/institutions/${id}`)
);

export const getCourseAdditionalInfo = redux.action(
  ({ institutionId, courseId }) => http => http.get(`/institutions/${institutionId}/courses/${courseId}`),
  (state, courseAdditionalInfo) => {
    return {
      ...state,
      courseAdditionalInfo
    };
  }
);

export default redux.reducer();
