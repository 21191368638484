import React, { memo } from 'react';
import classNames from 'classnames';

import type { Session } from '@acadeum/types';

import { DateBlock } from '../DateBlock';
import { Text } from '../../../Text';

import styles from './CourseSessionDateBlock.module.scss';

export interface CourseSessionDateBlockProps {
  session: Pick<Session, 'startDate' | 'endDate' | 'lastDropDate' | 'lastAddDate'>;
  withLastDropDate?: boolean;
}

export const CourseSessionDateBlock = memo<CourseSessionDateBlockProps>(({
  session,
  withLastDropDate
}) => {
  return (
    <div className={classNames(styles.CourseSessionDateBlock)}>
      <div className={styles.firstLine}>
        <DateBlock
          utc
          month="short"
          date={session.startDate}
        />
        {' — '}
        <DateBlock
          utc
          month="short"
          date={session.endDate}
        />
      </div>
      {withLastDropDate && (
        <Text color="grey" variant="bodyMd">
          Drop Date:
          {' '}
          <DateBlock
            utc
            month="short"
            date={session.lastDropDate}
          />
        </Text>
      )}
    </div>
  );
});
