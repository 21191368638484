import type { UserProfile } from '@acadeum/types';

import { isAcadeumAdminRole } from './isAcadeumAdminRole';

export function isAcadeumAdministrator(user?: UserProfile): boolean {
  if (!user) {
    return false;
  }
  return user.roles.some(isAcadeumAdminRole);
}
