import { Accordion, Text } from '@acadeum/ui';

export function CertificatesSection({
  course
}) {
  const hasCertificates = Boolean(course.certificates);
  return (
    <Accordion title="Certificates" defaultOpen={hasCertificates}>
      <Text color="grey">
        {hasCertificates
          ? course.certificates
          : 'This course does not provide professional certificates.'}
      </Text>
    </Accordion>
  );
}
