import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Fieldset.module.scss';

export interface FieldsetProps {
  children?: React.ReactNode;
  type?: 'phone';
  className?: string;
}

export const Fieldset: React.FC<FieldsetProps> = ({
  children,
  type,
  className
}) => {
  const Element = type === 'phone' ? 'div' : 'fieldset';

  return (
    <Element className={classNames(className, styles.Fieldset, {
      [styles['Fieldset--phone']]: type === 'phone'
    })}>
      {children}
    </Element>
  );
};

Fieldset.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['phone'])
};
