import type { FC } from 'react';
import React from 'react';

import { Actions, Tag } from '@acadeum/ui';
import { useTranslate } from '@acadeum/translate';
import type { FetchBackupPaymentSourceOutput, PrimaryPaymentSource } from '@acadeum/api';

import styles from './PaymentCard.module.scss';

interface PaymentCardType {
  title: string;
  description: string;
  statuses: PrimaryPaymentSource['status'][] | FetchBackupPaymentSourceOutput['status'][];
  onSeeDetails?: () => void;
}

export const PaymentCard: FC<PaymentCardType> = ({
  title,
  description,
  statuses,
  onSeeDetails
}) => {
  const t = useTranslate();

  const actions = [
    {
      title: t('seeDetails', { global: true }),
      onClick: onSeeDetails
    }
  ];

  return (
    <div className={styles.PaymentCard}>
      <header className={styles.PaymentCard__title}>
        <div>{title}</div>
        {onSeeDetails && (
          <Actions variant="kebab" actions={actions}/>
        )}
      </header>
      <div>
        {description}
      </div>
      <div className={styles.PaymentCard__paymentMethodStatusContainer}>
        {statuses?.map((status, index) => (
          <Tag key={index} variant={status}/>
        ))}
      </div>
    </div>
  );
};
