import { getAlgoliaIndex } from './algolia';

import {
  getMemberInstitutionById as getMemberInstitutionById_,
  getMemberInstitutionOptionByValue as getMemberInstitutionOptionByValue_,
  getMemberInstitutionOptionsByName as getMemberInstitutionOptionsByName_
} from '@acadeum/helpers';

function getInstitutionsIndex() {
  return getAlgoliaIndex('institutions');
}

export async function getMemberInstitutionOptionsByName(name, parameters) {
  return getMemberInstitutionOptionsByName_(name, { ...parameters, getInstitutionsIndex });
}

export async function getMemberInstitutionOptionByValue(id, parameters) {
  return getMemberInstitutionOptionByValue_(id, { ...parameters, getInstitutionsIndex });
}

export async function getMemberInstitutionById(id) {
  return getMemberInstitutionById_(id, { getInstitutionsIndex });
}
