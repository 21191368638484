const NUMERIC_FORMATTERS = {
  'en': new Intl.NumberFormat('en')
};

/**
 * Formats a number.
 * @param  {Number} number
 * @param  {string} [options.locale] — Is "en" by default.
 * @return {string}
 */
export default function formatNumber(number, options = {}) {
  if (typeof number !== 'number') {
    throw new Error('No number supplied to `formatNumber()`');
  }

  const { locale } = options;
  switch (locale) {
    case 'en':
      if (NUMERIC_FORMATTERS[locale]) {
        return NUMERIC_FORMATTERS[locale].format(number);
      }
      return String(number)
    default:
      // Fall back to "en" locale.
      return formatNumber(number, { ...options, locale: 'en' });
  }
}
