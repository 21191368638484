import PropTypes from 'prop-types';

export interface GAProps {
  gaCategory: string;
  gaAction: string;
  gaLabel?: string;
  gaValue?: number;
}

export const GAPropsTypes = {
  // Google Analytics (Google Tag Manager) Event Tracking.
  // https://www.hallaminternet.com/using-google-analytics-event-tracking/
  gaCategory: PropTypes.string,
  gaAction: PropTypes.string,
  gaLabel: PropTypes.string,
  gaValue: PropTypes.number
};
