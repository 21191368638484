import type { ButtonHTMLAttributes } from 'react';
import React from 'react';
import classNames from 'classnames';

import styles from './Chip.module.scss';

export type ChipProps = ButtonHTMLAttributes<HTMLButtonElement>

export const Chip: React.FC<ChipProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <button type="button" {...rest} className={classNames(className, styles.root)}>
      {children}
    </button>
  );
};
