import React, { useState } from 'react';
import PropTypes from 'prop-types';
import type { RowData } from '@tanstack/react-table';

import ChangeEnrollmentStatusModal from '../ChangeEnrollmentStatusModal';
import ReasonFormField from '../ReasonFormField';

import { useTranslate } from '@acadeum/translate';

import { toast } from '@acadeum/ui';
import type { TableProps } from '@acadeum/ui';

import actions from '../../../../actions';

const {
  // Enrolling
  dropStudentsFromCourseEM,
  dropStudentsFromCourseTM,
  withdrawStudentsFromCourseEM,
  withdrawStudentsFromCourseTM,
  removeStudentsFromCourse,
  completeStudentEnrollments
} = actions;

interface ChildrenProps {
  onChange: (enrollments: TableProps<RowData>['data'], newStatus: string) => void;
}

export interface ChangeStatusProps {
  isTeaching?: boolean;
  onAfterSubmit: () => void;
  exportData?: () => void;
  children: React.FC<ChildrenProps>;
}

export default function ChangeStatus({
  isTeaching,
  exportData,
  onAfterSubmit,
  children
}: ChangeStatusProps) {
  const t = useTranslate('Enrollments');

  // eslint-disable-next-line
  const [enrollments, setEnrollments] = useState<any[]>([]);
  const [newStatus, setNewStatus] = useState<string | null>();
  const [showChangeStatusConfirmationPopup, setShowChangeStatusConfirmationPopup] = useState<boolean>();

  const onSubmitFinished = async () => {
    setNewStatus(null);
    setEnrollments([]);
    onAfterSubmit();
  };

  const onSubmitChangeStatusModal = async ({ grades, reason, reasonNotes }) => {
    // eslint-disable-next-line
    // @ts-ignore
    const ids = enrollments.map(_ => _.id);
    // let mustInputNotesForStatusChange;
    switch (newStatus) {
      case 'COMPLETE':
        await completeStudentEnrollments(ids, { grades });
        break;
      case 'DROPPED':
        if (isTeaching) {
          await dropStudentsFromCourseTM(ids, { reason, reasonNotes });
        } else {
          await dropStudentsFromCourseEM(ids, { reason, reasonNotes });
        }
        break;
      case 'WITHDRAWN':
        if (isTeaching) {
          await withdrawStudentsFromCourseTM(ids, { grades, reason, reasonNotes });
        } else {
          await withdrawStudentsFromCourseEM(ids, { reason, reasonNotes });
        }
        break;
      case 'REMOVED':
        // mustInputNotesForStatusChange = true;
        await removeStudentsFromCourse(ids, { reason, reasonNotes });
        toast.success('Enrollment Request has been removed successfully. ');
        break;
      default:
        throw new Error(`Unsupported target enrollment status: ${newStatus}`);
    }
    setShowChangeStatusConfirmationPopup(false);
    // if (mustInputNotesForStatusChange) {
    //   await waitForModalToClose();
    //   setShowChangeStatusNotesModal(true);
    // } else {
    await onSubmitFinished();
    if (newStatus !== 'REMOVED') {
      toast.success('Success! Enrollment status has been updated.');
    }
    // }
  };

  // const onSubmitChangeStatusNotesModal = async ({ notes }) => {
  //   try {
  //     const ids = enrollments.map(_ => _.id);
  //     switch (newStatus) {
  //       case 'Remove':
  //         await removeStudentsFromCourse(ids, { notes });
  //         break;
  //       default:
  //         notifyError(`Unsupported status: ${newStatus}`);
  //         throw new Error(`Unsupported status: ${newStatus}`);
  //     }
  //     await onSubmitFinished();
  //   } catch (error) {
  //     console.error(error);
  //     notifyError('Couldn\'t change the selected enrollments\' statuses');
  //   }
  // };

  const onStatusChange = (enrollments, newStatus) => {
    // If the selected status is the same then do nothing
    if (enrollments[0].status === newStatus) {
      return;
    }
    setEnrollments(enrollments);
    setNewStatus(newStatus);
    setShowChangeStatusConfirmationPopup(true);
  };

  const mustSupplyGrades = newStatus === 'COMPLETE' || (newStatus === 'WITHDRAWN' && isTeaching);

  // const rationaleFormTitle = newStatus && getStatusTransitionTitle(newStatus, enrollments,
  // isTeaching); const rationaleFormDescription = newStatus && getNotesModalDescription(newStatus,
  // enrollments);

  const labelReasonFormField = t('enrollmentStatusTransitionReasonInputFieldLabel', {
    action: newStatus ? t(`status.transitionTo.${newStatus.toLowerCase()}`) : ''
  });

  const submitText = newStatus === 'REMOVED' ? t(`status.transitionTo.${newStatus.toLowerCase()}`) : 'Submit';

  const closeChangeEnrollmentStatusModal = () => {
    setShowChangeStatusConfirmationPopup(false);
  };

  return (
    <>
      {newStatus &&
        <ChangeEnrollmentStatusModal
          show={showChangeStatusConfirmationPopup}
          onHide={closeChangeEnrollmentStatusModal}
          title={t('enrollmentStatusTransitionDescription', { action: t(`status.transitionTo.${newStatus.toLowerCase()}`) })}
          isTeaching={isTeaching}
          submitText={submitText}
          inputGrades={mustSupplyGrades}
          defaultGrade={newStatus === 'WITHDRAWN' ? 'W' : undefined}
          enrollments={enrollments}
          onSubmit={onSubmitChangeStatusModal}
          danger={newStatus === 'REMOVED'}
          actionsHeader={[
            {
              title: 'Download',
              onClick: exportData
            }
          ]}
        >
          {newStatus === 'DROPPED' && (
            <ReasonFormField
              required
              label={labelReasonFormField}
              labelsPath="EnrollmentsTable.dropReason"
              options={DROP_REASONS}
            />
          )}
          {newStatus === 'WITHDRAWN' && (
            <ReasonFormField
              required
              label={labelReasonFormField}
              labelsPath="EnrollmentsTable.withdrawReason"
              options={WITHDRAW_REASONS}
            />
          )}
          {newStatus === 'REMOVED' && (
            <ReasonFormField
              required
              defaultValue="OTHER"
              label={labelReasonFormField}
              labelsPath="EnrollmentsTable.removeReason"
              options={REMOVE_REASONS}
            />
          )}
        </ChangeEnrollmentStatusModal>
      }

      {children({
        onChange: onStatusChange
      })}
    </>
  );
}

ChangeStatus.propTypes = {
  isTeaching: PropTypes.bool,
  onAfterSubmit: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired
};

// function getStatusTransitionDescription(newStatus, enrollment, isTeaching) {
//   switch (newStatus) {
//     case 'COMPLETE':
//       return 'Please confirm that you would like to mark the following enrollment as Complete. For consortial
// enrollments you will be required to input grades.'; case 'Withdraw': if (isTeaching) { return 'Since this enrollment
// is passed the Add-Drop Date for the course, a grade of “W” is automatically entered. You can edit this grade if you
// deem necessary, or simply leave this field blank and click “Submit.”'; } else { return 'All withdrawn enrollments
// automatically receive a grade of “W.” Please confirm that you want to withdraw the following enrollments:'; }
// default: return `Please confirm you want to ${newStatus.toLowerCase()} the following enrollment request.`;
// } }

// function getNotesModalDescription(action, enrollments) {
//   const several = enrollments.length > 1;
//   let actionDescription;
//   switch (action) {
//     case 'Remove':
//       actionDescription = 'removing';
//       break;
//     default:
//       return;
//   }
//   return `Please tell us why you are ${actionDescription} ${several ? 'these enrollments' : 'this enrollment'} so
// that we may better serve you moving forward.`; }

const REMOVE_REASONS = [];

const DROP_REASONS = [
  'WRONG_CLASS_OR_SECTION',
  'TECHNICAL_ISSUES_OR_COMPUTER_OR_LOGIN',
  'DID_NOT_LIKE_ONLINE_LEARNING',
  'TAKING_CLASS_ON_CAMPUS',
  'FINANCIAL_OR_ABILITY_TO_PAY',
  'NON_ATTENDANCE'
];

const WITHDRAW_REASONS = [
  'DID_NOT_LIKE_ONLINE_INSTRUCTION_DELIVERY',
  'WORKLOAD_OR_COURSE_PACE_TOO_STRESSFUL',
  'POOR_ACADEMIC_PERFORMANCE',
  'PERSONAL_REASONS',
  'NON_ATTENDANCE_OR_PARTICIPATION'
];
