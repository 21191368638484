import React from 'react';
import { useSelector } from 'react-redux';
import config from 'config';

import ConsortiumSearch from './ConsortiumSearch_';
import ConsortiumSearchResult from './ConsortiumSearchResult';

import useLocation from '../../hooks/useLocation';

// Must be equal to the `height` of `.Header` CSS rule.
const FLOATING_HEADER_HEIGHT = 64;

export default function CCInstitutionSearch(props) {
  const { isNarrowContent } = useSelector(state => state.app);

  const location = useLocation();

  return (
    <ConsortiumSearch
      {...props}
      layout="rowGridTwoColumns"
      component={ConsortiumSearchResult}
      isNarrowContent={isNarrowContent}
      floatingHeaderHeight={FLOATING_HEADER_HEIGHT}
      indexPrefix={config.algolia.prefix}
      appId={config.algolia.appID}
      apiKey={config.algolia.apiKey}
      resultsPerPage={[30, 60, 90]}
      location={location}
    />
  );
}
