import { useContext } from 'react';

import { ChoiceListListboxContext, ChoiceListListboxOptionContext } from './context';

export function useChoiceListOption() {
  return useContext(ChoiceListListboxOptionContext);
}

export function useChoiceListListbox() {
  return useContext(ChoiceListListboxContext);
}
