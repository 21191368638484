import { format } from 'date-fns';
import reportError from './reportError.js';

const TIME_FORMATTERS = {
  'en': new Intl.DateTimeFormat('en', {
    hour: '2-digit',
    minute: '2-digit'
  })
};

/**
 * Formats a date.
 * @param  {Date} date
 * @param  {string} [options.locale] — Is "en" by default.
 * @param  {boolean} options.utc — Set to `true` if the date is in UTC+0 timezone.
 * @return {string}
 */
export default function formatTime(date, options = {}) {
  if (!date) {
    throw new Error('No date supplied to `formatTime()`');
  }

  if (options.utc === true) {
    if (typeof window !== 'undefined') {
      reportError('Unsupported `utc: true` option in `formatTime()`');
    } else {
      throw new Error('Unsupported `utc: true` option in `formatTime()`');
    }
  }

  const { locale } = options;
  switch (locale) {
    case 'en':
      if (TIME_FORMATTERS[locale]) {
        return TIME_FORMATTERS[locale].format(date)
      }
      return format(date, 'HH:MM');

    default:
      // Fall back to "en" locale.
      return formatTime(date, { ...options, locale: 'en' });
  }
}
