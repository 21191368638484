import React, { Fragment, useEffect, useState } from 'react';
import { useController } from 'react-hook-form';

import userRoleNotificationGroupsByRole from 'common-lib/constants/userRoleNotificationGroupsByRole.json';

import { useTranslate } from '@acadeum/translate';
import type { FormProps } from '@acadeum/ui';
import { Checkbox, Form, Separator, StickyFormFooter, ToggleCard, toast } from '@acadeum/ui';

type UserRoleNotificationGroupsByRoleKey = keyof typeof userRoleNotificationGroupsByRole;

interface FormData {
  notifications: string[];
}

export interface UserRoleEmailNotificationFormProps {
  defaultValues?: Partial<FormData>;
  onSubmit: FormProps<FormData>['onSubmit'];
  onCancel?: FormProps<FormData>['onSubmit'];
  onBack?: FormProps<FormData>['onSubmit'];
  mode: 'readOnly' | 'edit' | 'create';
  submitText: string;
  disabled?: boolean;
}

export const UserRoleEmailNotificationForm: React.FC<UserRoleEmailNotificationFormProps> = ({
  mode,
  defaultValues,
  onSubmit: propsOnSubmit,
  onBack,
  onCancel,
  submitText,
  disabled
}) => {
  const onSubmit: FormProps<FormData & { formAction: 'submit' | 'cancel' | 'back' }>['onSubmit'] = async (
    values,
    options
  ) => {
    // Even when the "Save" button is not shown, the form can still be submitted
    // via a keyboard, for example.
    if (mode === 'readOnly') {
      return;
    }
    const { formAction, notifications } = values;
    const handler = formAction === 'back' ? onBack : formAction === 'cancel' ? onCancel : propsOnSubmit;
    await handler?.({ notifications }, options);
  };

  // react-hook-form have strange behavior when using `useController`.
  // isDirty is not sync with the formState from useForm/useFormContext.
  // So we need to use useState to track the dirty state from useController.
  // https://github.com/react-hook-form/react-hook-form/issues/10073
  const [isDirty, setIsDirty] = useState(false);

  const [showCard, setShowCard] = useState<UserRoleNotificationGroupsByRoleKey | null>(() => getShowCardNameInitialState(defaultValues));

  const onToggleCard = (userRoleKey: UserRoleNotificationGroupsByRoleKey) => {
    if (showCard === userRoleKey) {
      setShowCard(null);
      return 'reset';
    }

    if (showCard && showCard !== userRoleKey) {
      toast.error('You can only select a single notifications group.');
      return 'none';
    }

    setShowCard(userRoleKey);
    return 'set';
  };

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={defaultValues}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {Object.entries(userRoleNotificationGroupsByRole).map(([userRoleKey, options]) => (
          <NotificationsFields
            key={userRoleKey}
            userRoleKey={userRoleKey as UserRoleNotificationGroupsByRoleKey}
            options={options}
            disabled={mode === 'readOnly' || disabled}
            showCard={showCard === userRoleKey}
            onToggleCard={onToggleCard}
            setIsDirty={setIsDirty}
          />
        ))}
      </div>

      {(mode === 'create' || (mode === 'edit' && isDirty)) && (
        <StickyFormFooter
          onCancelProps={{
            action: 'cancel',
            disabledWhenInvalid: false
          }}
          onBackProps={{
            action: 'back',
            disabledWhenInvalid: false
          }}
          submitProps={{
            action: 'submit',
            children: submitText
          }}
        />
      )}
    </Form>
  );
};

interface NotificationsFieldsProps extends Pick<UserRoleEmailNotificationFormProps, 'disabled'> {
  userRoleKey: UserRoleNotificationGroupsByRoleKey;
  options: string[];
  showCard?: boolean;
  onToggleCard?: (userRoleKey: UserRoleNotificationGroupsByRoleKey) => 'set' | 'reset' | 'none';
  setIsDirty?: (isDirty: boolean) => void;
}

function NotificationsFields({
  userRoleKey,
  options,
  disabled,
  showCard,
  onToggleCard: propsOnToggleCard,
  setIsDirty
}: NotificationsFieldsProps) {
  const t = useTranslate('shared-admin-ui.UserRoleEmailNotificationForm.notifications');

  const name = 'notifications';

  const {
    field: { value: checkboxIds, onChange, ...inputProps },
    formState: { isDirty }
  } = useController({ name });

  useEffect(() => {
    setIsDirty?.(isDirty);
  }, [isDirty]);

  const handleChange = (item) => {
    const newArray = [...(checkboxIds || [])];

    if (newArray.length > 0) {
      const index = newArray.findIndex((x) => x === item);
      if (index === -1) {
        newArray.push(item);
      } else {
        newArray.splice(index, 1);
      }
    } else {
      newArray.push(item);
    }

    onChange(newArray);
  };

  const onToggleCard = () => {
    const result = propsOnToggleCard?.(userRoleKey);
    if (result === 'set') {
      onChange(options);
    } else if (result === 'reset') {
      onChange([]);
    } else if (result === 'none') {
      // ignore
    }
  };

  return (
    <ToggleCard
      label={t(userRoleKey)}
      checked={showCard}
      onChange={onToggleCard}
      disabled={disabled}
    >
      {showCard && (
        options.map((field, index) => (
          <Fragment key={field}>
            {index > 0 && <Separator style={{ marginTop: 16, marginBottom: 8 }}/>}
            <Checkbox
              {...inputProps}
              name={name}
              value={field}
              checked={Boolean(checkboxIds?.some((checked) => checked === field))}
              onChange={() => handleChange(field)}
              label={t(field)}
              disabled={disabled}
            />
          </Fragment>
        ))
      )}
    </ToggleCard>
  );
}

function getShowCardNameInitialState(defaultValues: UserRoleEmailNotificationFormProps['defaultValues']) {
  const matchedNotificationsGroups = Object.entries(userRoleNotificationGroupsByRole)
    .map(([userRoleKey, options]) => {
      const matchedNotifications = options.filter((notification) => defaultValues?.notifications?.includes(notification));
      return [userRoleKey, matchedNotifications.length === options.length, matchedNotifications.length];
    }) as [UserRoleNotificationGroupsByRoleKey, boolean, number][];

  const allMatchedNotificationsGroups = matchedNotificationsGroups.filter(([, isAllMatched]) => isAllMatched);
  if (allMatchedNotificationsGroups.length === 1) {
    return allMatchedNotificationsGroups[0][0];
  }

  const sortedMatchedNotificationsGroups = matchedNotificationsGroups.sort((a, b) => b[2] - a[2]);
  if (sortedMatchedNotificationsGroups[0][2] > 0) {
    return sortedMatchedNotificationsGroups[0][0];
  }

  return null;
}
