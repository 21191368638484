import md5 from 'md5';
import ReactGA from 'react-ga';
import config from 'config';

import { onPageView } from './hubspotUserTracking.js';

export default function initialize(userId) {
  // Google Analytics Token
  const token = config.googleAnalyticsToken;

  // Google Analytics Token is always defined.
  // if (!token) {
  //   return;
  // }

  // This is intended to be a known identifier for a user
  // provided by the site owner/tracking library user.
  // It must not itself be PII (personally identifiable information).
  // The value should never be persisted in GA cookies
  // or other Analytics provided storage.
  let googleAnalyticsUserId;
  if (userId) {
    googleAnalyticsUserId = md5(userId);
  }

  // Initialize Google Analytics
  ReactGA.initialize(token, {
    // debug: true,
    // titleCase: false,
    gaOptions: {
      userId: googleAnalyticsUserId
    }
  });

  // If Google Analytics has been initilialized for the first time,
  // report any pending "page view" events.
  if (!window.__ReactGA_initialized__) {
    window.__ReactGA_initialized__ = true;

    if (window.__ReactGA_deferred_onPageLoad_URL__) {
      ReactGA.pageview(window.__ReactGA_deferred_onPageLoad_URL__);
      window.__ReactGA_deferred_onPageLoad_URL__ = undefined;
    }
  }
}

// Google Analytics: log page views.
export function onNavigate({ url }) {
  // Track page views in Hubspot.
  // Normally this happens automatically, but not until you start identifying
  // users via `_hsq.push(['identify', ...])`, as it's done in `onUserAuthenticatedOrNotAuthenticated()`.
  if (process.env.NODE_ENV === 'production') {
    onPageView(url);

    if (window.__ReactGA_initialized__) {
      ReactGA.pageview(url);
    } else {
      window.__ReactGA_deferred_onPageLoad_URL__ = url;
    }
  }
}
