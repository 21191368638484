import React, { memo } from 'react';
import classNames from 'classnames';

import type { Session } from '@acadeum/types';

import { Text } from '../../../Text';

import styles from './TermAndSessionBlock.module.scss';

export interface TermAndSessionBlockProps {
  className?: string;
  session: Pick<Session, 'name' | 'term'>;
}

export const TermAndSessionBlock = memo<TermAndSessionBlockProps>(({
  className,
  session
}) => {
  return (
    <div className={classNames(className, styles.TermAndSessionBlock)}>
      <Text>
        {session.term}
      </Text>
      <Text color="grey">
        {session.name}
      </Text>
    </div>
  );
});
