/**
 * Remove trailing `*` from `required` columns' titles.
 * https://github.com/Acadeum/Tickets/issues/1217
 * */
export function removeTrailingAsterisk(row) {
  return row.map(title => {
    if (typeof title === 'string') {
      return title.replace(/\*$/, '');
    }
    return title;
  });
}
