// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck TODO: Fix All types

import React, { useMemo, useCallback, useId } from 'react';
import PropTypes from 'prop-types';

import { SearchBar } from '../../../SearchBar';

import { useComboboxTextField } from '../hooks';

const TextField = ({
  value,
  id: idProp,
  onFocus: propsOnFocus,
  onBlur: propsOnBlur,
  onChange: propsOnChange,
  placeholder,
  ...rest
}) => {
  const {
    activeOptionId,
    listboxId,
    expanded,
    setTextFieldFocused,
    onTextFieldFocus,
    onTextFieldChange,
    onTextFieldBlur,
    onTextFieldKeyDown
  } = useComboboxTextField();

  const uniqueId = useId() + 'ComboboxTextField';
  const textFieldId = useMemo(() => idProp || uniqueId, [uniqueId, idProp]);

  const onFocus = useCallback((event) => {
    if (propsOnFocus) {
      propsOnFocus(event);
    }
    if (onTextFieldFocus) {
      onTextFieldFocus();
    }
    if (setTextFieldFocused) {
      setTextFieldFocused(true);
    }
  }, [propsOnFocus, onTextFieldFocus, setTextFieldFocused]);

  const onBlur = useCallback(
    (event) => {
      if (propsOnBlur) {
        propsOnBlur(event);
      }
      if (onTextFieldBlur) {
        onTextFieldBlur(event);
      }
      if (setTextFieldFocused) {
        setTextFieldFocused(false);
      }
    },
    [propsOnBlur, onTextFieldBlur, setTextFieldFocused]
  );

  const onChange = useCallback((value) => {
    if (propsOnChange) {
      propsOnChange(value);
    }
    if (onTextFieldChange) {
      onTextFieldChange(value);
    }
  },
  [propsOnChange, onTextFieldChange]
  );

  return (
    <>
      <SearchBar
        clearNone
        {...rest}
        value={value}
        id={textFieldId}
        type="text"
        role="combobox"
        aria-haspopup="listbox"
        aria-autocomplete="list"
        aria-activedescendant={activeOptionId}
        aria-controls={listboxId}
        aria-expanded={expanded}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onTextFieldKeyDown}
        autoFocus
        placeholder={placeholder}
      />
    </>
  );
};

TextField.propTypes = {
  value: PropTypes.string.isRequired,
  id: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
};

export default TextField;
