export default function useRolesList(roles) {
  return splitIntoPairs(roles);
}

function splitIntoPairs(initialArray) {
  return initialArray.reduce(function(result, value, index, array) {
    if (index % 2 === 0) {
      result.push(array.slice(index, index + 2));
    }
    return result;
  }, []);
}
