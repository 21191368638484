import React, { Fragment, useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import classNames from 'classnames';

import { Email, FormatDate, Phone, Text, Button, CopyToClipboard, DataBlock, SearchBar } from '@acadeum/ui';
import { EditIcon } from '@acadeum/icons';
import { useTranslate } from '@acadeum/translate';
import type { Student } from '@acadeum/types';

import { formatCountry, formatEthnicity, formatGender, formatName, formatRaces } from '../../../helpers/format';

import styles from './Info.module.scss';

interface InfoProps {
  type?: 'EM' | 'TM',
  className?: string;
  student: Student;
}

interface InformationFieldsProps {
  key: string;
  title: string;
  content: ReactNode;
  show?: boolean;
}

const Info = ({ type, student }: InfoProps) => {
  const t = useTranslate('StudentDetails');

  const [search, setSearch] = useState<string>();

  const [personalInformationFields, setPersonalInformationFields] = useState<InformationFieldsProps[]>();
  const [academicInformationFields, setAcademicInformationFields] = useState<InformationFieldsProps[]>();
  const [contactInformationFields, setContactInformationFields] = useState<InformationFieldsProps[]>();
  const [contactNotesInformationFields, setContactNotesInformationFields] = useState<InformationFieldsProps[]>();

  const getShowInfo = (setBlock: (element: InformationFieldsProps[]) => void, arrayInfo: InformationFieldsProps[]) => {
    setBlock(arrayInfo.map(element => {
      // eslint-disable-next-line
      let show: boolean = true;
      if (search !== '' && typeof search === 'string') {
        const regex = new RegExp(search, 'ig');
        show = Boolean(element.title.match(regex));
      }
      return { ...element, show };
    }));
  };

  useEffect(() => {
    const personal = [
      {
        key: 'studentName',
        title: t('studentName'),
        content: <CopyToClipboard text={formatName(student)}/>
      },
      {
        key: 'dateOfBirth',
        title: t('dateOfBirth'),
        content: student.dob ? <FormatDate date={student.dob} utc/> : '—'
      }, {
        key: 'gender',
        title: t('gender'),
        content: <CopyToClipboard text={formatGender(student.gender)}/>
      }, {
        key: 'ethnicity',
        title: t('ethnicity'),
        content: student.ethnicity ? <CopyToClipboard text={formatEthnicity(student.ethnicity)}/> : '—'
      }, {
        key: 'race',
        title: t('race'),
        content: student.races ? <CopyToClipboard text={formatRaces(student.races, { separator: '\n' })}/> : '—'
      }, {
        key: 'USCitizen',
        title: t('USCitizen'),
        content: typeof student.citizenship == 'boolean' ? (student.citizenship ? t('yes') : t('no')) : t('unknown')
      }
    ];
    const academic = getAcademic({ student, type, t });
    const contact = getContact({ student, type, t });
    const contactNotes = [
      {
        key: 'note',
        title: t('note'),
        content: student.notes ? <CopyToClipboard text={student.notes}/>: '-'
      }
    ];

    getShowInfo(setPersonalInformationFields, personal);
    getShowInfo(setAcademicInformationFields, academic);
    getShowInfo(setContactInformationFields, contact);
    getShowInfo(setContactNotesInformationFields, contactNotes);
  }, [student, type, search]);

  return (
    <>
      <div className={styles.Info__header}>
        <Text variant="subtitle2">
          {t('aboutStudent')}
        </Text>
        <div className={styles['Info__header-right']}>
          {type === 'EM' && (
            <Button
              icon={EditIcon}
              variant="tertiary"
              url={`/students/${student.id}/edit`}
            >
              {t('edit', { global: true })}
            </Button>
          )}
          <SearchBar onChange={setSearch}/>
        </div>
      </div>
      <section className={classNames(styles.Info)}>
        {personalInformationFields && Boolean(personalInformationFields.filter(element => element.show).length) && (
          <div className={styles.Info__subsection}>
            <Text variant="subtitle2">
              {t('personalInformation')}
            </Text>
            <FieldsList>
              <Fields>{personalInformationFields}</Fields>
            </FieldsList>
          </div>
        )}
        {academicInformationFields && Boolean(academicInformationFields.filter(element => element.show).length) && (
          <div className={styles.Info__subsection}>
            <Text variant="subtitle2">
              {t('academicInformation')}
            </Text>
            <FieldsList>
              <Fields>{academicInformationFields}</Fields>
            </FieldsList>
          </div>
        )}
        {contactInformationFields && Boolean(contactInformationFields.filter(element => element.show).length) && (
          <div className={styles.Info__subsection}>
            <Text variant="subtitle2">
              {t('contactInformation')}
            </Text>
            <FieldsList>
              <Fields>{contactInformationFields}</Fields>
            </FieldsList>
          </div>
        )}
        {contactNotesInformationFields && Boolean(contactNotesInformationFields.filter(element => element.show).length) && (
          <div className={styles.Info__subsection}>
            <Text variant="subtitle2">
              {t('additional')}
            </Text>
            <FieldsList>
              <Fields>
                {contactNotesInformationFields}
              </Fields>
            </FieldsList>
          </div>
        )}
      </section>
    </>
  );
};
export default Info;

interface FieldsListProps {
  className?: string;
  children?: React.ReactNode;
}

const FieldsList = ({ className, children }: FieldsListProps) => {
  return (
    <dl className={classNames(styles.Info__list, className)}>
      {children}
    </dl>
  );
};

interface FieldsProps {
  className?: string;
  children: InformationFieldsProps[];
}

const Fields = ({ children, className }: FieldsProps) => {
  return children.map(({ title, content, show, key }: InformationFieldsProps) => (
    <Fragment key={key}>
      {show && (
        <div className={classNames(styles.Info__item, className)}>
          <dt className={styles.Info__dt}>{title}</dt>
          <dd className={styles.Info__itemDescription}>{content}</dd>
        </div>
      )}
    </Fragment>
  ));
};

const getAcademic = ({ student, type, t }) => {
  const result: InformationFieldsProps[] = [
    {
      key: 'studentID',
      title: t('studentID'),
      content: <CopyToClipboard text={student.hiStudentId}/>
    }, {
      key: 'studentEmail',
      title: t('studentEmail'),
      content: <Email address={student.email}/>
    }
  ];
  if (type === 'TM') {
    result.push({
      key: 'teachingInstitution',
      title: t('teachingInstitution'),
      content: <DataBlock type="institution" institution={student.institution} hideLogo/>
    });
  }
  return [
    ...result, {
      key: 'level',
      title: t('level'),
      content: student.level ? <CopyToClipboard text={student.level}/> : '—'
    }, {
      key: 'startDateOfSchool',
      title: t('startDateOfSchool'),
      content: student.startDate ? <FormatDate date={student.startDate} utc/> : '—'
    }, {
      key: 'majorOrFieldOfStudy',
      title: t('majorOrFieldOfStudy'),
      content: student.major ? <CopyToClipboard text={student.major}/> : '—'
    }, {
      key: 'advisorName',
      title: t('advisorName'),
      content: student.advisorName ? <CopyToClipboard text={student.advisorName}/> : '—'
    }, {
      key: 'advisorEmail',
      title: t('advisorEmail'),
      content: student.advisorEmail ? <Email address={student.advisorEmail}/> : '—'
    }
  ];
};
const getContact = ({ student, type, t }) => {
  const result: InformationFieldsProps[] = [
    {
      key: 'country',
      title: t('country'),
      content: <CopyToClipboard text={formatCountry(student.country)}/>
    }, {
      key: 'telephone',
      title: t('telephone'),
      content: student.phone ? <Phone number={student.phone}/> : '-'
    }, {
      key: 'streetAddress1',
      title: t('streetAddress1'),
      content: student.addressLine1 || '—'
    }, {
      key: 'streetAddress2',
      title: t('streetAddress2'),
      content: student.addressLine2 || '—'
    }, {
      key: 'city',
      title: t('city'),
      content: <CopyToClipboard text={student.city}/>
    }, {
      key: 'state',
      title: t('state'),
      content: <CopyToClipboard text={student.state}/>
    }, {
      key: 'zip',
      title: t('zip'),
      content: <CopyToClipboard text={student.postalCode}/>
    }, {
      key: 'residency',
      title: t('residency'),
      content: student.residency || '—'
    }
  ];
  if (type === 'TM') {
    result.unshift({
      key: 'school',
      title: t('school'),
      content: <CopyToClipboard text={student.institution.name}/>
    });
  }

  return result;
};
