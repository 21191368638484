import { getMessagesCached, getMessagesWithFallback } from '@acadeum/translate';

import { DEFAULT_LANGUAGE } from './language';

import EN from './locales/en';
// import ES from './locales/es';

export default function getMessages (language) {
  return getMessagesCached(
    language,
    language => getMessagesWithFallback(language, DEFAULT_LANGUAGE, getMessageLabels)
  );
}

function getMessageLabels (language) {
  switch (language) {
    // case 'es':
    //   return ES;
    default:
      return EN;
  }
}
