import { userHasPermission } from '@acadeum/helpers';
import { UserRolePermissionsDetailsPage } from '@acadeum/shared-admin-ui';

import { useFetchUserRoleQuery, useUpdateUserRoleMutation } from '../../api/userRoles';
import type { AppPage } from '../../helpers/app.types';

interface UserRolePermissionsDetailsProps {
  roleId: number;
}

const UserRolePermissionsDetails: AppPage<UserRolePermissionsDetailsProps> = ({ roleId }) => {
  return (
    <UserRolePermissionsDetailsPage
      roleId={roleId}
      useFetchUserRoleQuery={useFetchUserRoleQuery}
      useUpdateUserRoleMutation={useUpdateUserRoleMutation}
    />
  );
};

UserRolePermissionsDetails.load = async ({ params }) => {
  return {
    props: {
      roleId: parseInt(params.id)
    }
  };
};

UserRolePermissionsDetails.meta = () => ({
  title: 'User Role Permissions'
});

UserRolePermissionsDetails.when = ({ user }) => userHasPermission(user, 'userRole:update', {
  orgId: user?.institution.id,
  ownerId: null
});


export default UserRolePermissionsDetails;
