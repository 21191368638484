import { formatPricingModelValue, PRICING_MODEL } from '../../lib/coursePricing';

export const EnrollingStudentCoursePricingDescription = ({ pricing }) => {
  if (!pricing) {
    return (
      <span>
        No price shown. You may charge students without displaying a price point within your student portal.
      </span>
    );
  }
  return (
    <span>
      You have selected to show your students a price of
      {' '}
      <strong>
        {PRICING_MODEL[pricing.model].title}
      </strong>
      {pricing.value !== undefined &&
        <>
          {' '}
          of
          {' '}
          <strong>{formatPricingModelValue(pricing.model, pricing.value)}</strong>
        </>
      }
      {pricing.institution &&
        <>
          {' '}
          for Teaching Institution {pricing.institution}
        </>
      }
      {pricing.hours !== undefined &&
        <>
          {' '}
          for {pricing.hours} course hour{pricing.hours === 1 ? '' : 's'}
        </>
      }
      {pricing.code &&
        <>
          {' '}
          for {pricing.code} course
        </>
      }
      {pricing.term &&
        <>
          {' '}
          for {pricing.term} term
        </>
      }
      {pricing.session &&
        <>
          {' '}
          for {pricing.session} session
        </>
      }
    </span>
  );
};
