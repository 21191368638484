import formatCourseLevel from 'common-lib/lib/formatCourseLevel.js';

import type { Language } from '@acadeum/types';

export function getCourseLevelOptions({ language, other }: { language: Language, other?: boolean }) {
  const levels = ['Undergraduate', 'Graduate', 'Certificate'];
  if (other) {
    levels.push('Other');
  }
  return levels.map((value) => ({
    value,
    label: formatCourseLevel(value, { language })
  }));
}
