import getMinCourseSessionLastAddDateTimeToJoin from './getMinCourseSessionLastAddDateTimeToJoin.js';

export default function canEnrollInCourse({
  onDemand,
  lastAddDate
}) {
  if (onDemand) {
    return true;
  }
  return lastAddDate.getTime() >= getMinCourseSessionLastAddDateTimeToJoin();
}
