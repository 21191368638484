// https://learn.javascript.ru/cookie

export interface CookieAttributes {
  path?: string;
  domain?: string;
  expiresAt?: Date;
  sameSite?: 'strict' | 'Strict' | 'lax' | 'Lax' | 'none' | 'None';
  secure?: boolean;

  // eslint-disable-next-line
  [property: string]: any;
}

export function setCookie(name: string, value: string | number | boolean, {
  path,
  secure,
  sameSite,
  domain,
  expiresAt
}: CookieAttributes = {}) {
  const cookieOptions: CookieAttributes = {
    path: '/',
    secure: true
  };

  if (path) {
    cookieOptions.path = path;
  }

  if (typeof secure === 'boolean') {
    cookieOptions.secure = secure;
  }

  if (sameSite) {
    cookieOptions.samesite = sameSite;
  }

  if (domain) {
    cookieOptions.domain = domain;
  }

  if (expiresAt) {
    cookieOptions.expires = expiresAt.toUTCString();
  } else {
    cookieOptions.expires = EXPIRES_MAX;
  }

  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (const optionName in cookieOptions) {
    updatedCookie += '; ' + optionName;
    const optionValue = cookieOptions[optionName];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  // TODO: Fix it type
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  document.cookie = updatedCookie;
}

// "Browsers have a problem with the dates after 2038-01-19 04:14:07
//  as the Unix epoch time exceeds 32-bit int. This problem is also called
//  the Year 2038 problem (also called Y2038, Apocalypse, Y2k38, or Unix Y2K).
//
//  Hence, the maximum value you can set as an expiry date for a cookie
//  that is supported by the majority of web browsers is:
//  2^31 - 1 = 2147483647 ie. 2038-01-19 04:14:07"
//
// https://www.geeksforgeeks.org/how-to-set-up-a-cookie-that-never-expires-in-javascript/
//
const EXPIRES_MAX = 'Tue, 19 Jan 2038 04:14:07 GMT';

// https://www.w3schools.com/js/js_cookies.asp
export function deleteCookie(name: string, {
  path = '/',
  domain
}: { path?: string; domain: string }) {
  document.cookie = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}; domain=${domain}`;
}

// https://learn.javascript.ru/cookie
export function getCookie(name: string) {
  const matches = document.cookie.match(new RegExp(
    // eslint-disable-next-line
    '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
  ));
  if (matches) {
    return decodeURIComponent(matches[1]);
  }
}
