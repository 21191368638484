import { useMemo } from 'react';

import type { AppTemplateProps } from '@acadeum/shared-admin-ui';
import { isAcadeumAdministrator, userHasPermission } from '@acadeum/helpers';

import Dashboard from './icons/Dashboard.svg';
import EnrollmentRequests from './icons/EnrollmentRequests.svg';
import AdvisorRecommendations from './icons/AdvisorRecommendations.svg';
import ManageEnrollments from './icons/ManageEnrollments.svg';
import Courses from './icons/Courses.svg';
import Students from './icons/Students.svg';
import Institutions from './icons/Institutions.svg';
import Network from './icons/Network.svg';
import Settings from './icons/Settings.svg';
import AdminTools from './icons/AdminTools.svg';
import { useSelector } from 'react-redux';

import type { ReduxState } from '../../helpers/app.types';
import { useNotifications } from '../../api/notifications';

export function useMainMenu({ user }) {
  const studentsNoRaceCount = useSelector((state: ReduxState) => state.students.studentsNoRaceCount);
  const notifications = useNotifications();
  const awaitingGradesCount = notifications?.awaitingGradesCount;
  const newEnrollmentRequestsCount = notifications?.newEnrollmentRequestsCount;
  const newStudentRequestsCount = notifications?.newStudentRequestsCount;

  return useMemo(() => {
    const result: AppTemplateProps['menu'] = [];

    if (!user) {
      return result;
    }

    if (isAcadeumAdministrator(user)) {
      result.push({
        icon: Dashboard,
        title: 'Dashboard',
        url: '/dashboard'
      });
    }

    if (user.institution.teaching
      && userHasPermission(user, 'teachingCourseEnrollment:read', {
        orgId: user.institution.id,
        ownerId: null
      })
    ) {
      result.push({
        icon: EnrollmentRequests,
        url: '/enrollment-requests',
        title: 'Enrollment Requests',
        notificationCount: newEnrollmentRequestsCount
      });
    }

    if (userHasPermission(user, 'homeStudentCourseRegistrationRequest:read', { orgId: user.institution.id })) {
      result.push({
        icon: EnrollmentRequests,
        url: '/course-registration-requests',
        title: 'Student Requests',
        notificationCount: newStudentRequestsCount
      });
    }

    if (user.institution.studentApp && userHasPermission(user, 'homeCourseAdvisorRecommendation:read', { orgId: user.institution.id })) {
      result.push({
        icon: AdvisorRecommendations,
        url: '/advisor-recommendations',
        title: 'Advisor Recommendations'
      });
    }

    if (userHasPermission(user, 'homeCourseEnrollment:read', { orgId: user.institution.id })) {
      result.push({
        icon: ManageEnrollments,
        url: '/enrollments/live',
        title: 'Manage Enrollments'
      });
    } else if (user.institution.teaching && userHasPermission(user, 'teachingCourseEnrollment:read', { orgId: user.institution.id })) {
      result.push({
        icon: ManageEnrollments,
        url: '/enrollments/teaching',
        title: 'Manage Enrollments'
        // notificationCount: awaitingGradesCount // TODO: Check it's realy need here?
      });
    }

    if (userHasPermission(user, 'course:read', {
      orgId: user.institution.id
    })) {
      result.push({
        icon: Courses,
        url: '/',
        title: 'Courses'
      });
    }

    if (userHasPermission(user, 'homeCourseEnrollmentGrade:read', { orgId: user.institution.id })) {
      result.push({
        icon: ManageEnrollments,
        url: '/grades/enrolling',
        title: 'Grades'
      });
    } else if (user.institution.teaching && userHasPermission(user, 'teachingCourseEnrollmentGrade:read', { orgId: user.institution.id })) {
      result.push({
        icon: ManageEnrollments,
        url: '/grades/teaching',
        title: 'Grades'
      });
    }

    if (userHasPermission(user, 'homeStudent:read', { orgId: user.institution.id })) {
      result.push({
        icon: Students,
        url: '/students',
        title: 'Students'
      });
    } else if (user.institution.teaching && userHasPermission(user, 'teachingStudent:read', { orgId: user.institution.id })) {
      result.push({
        icon: Students,
        url: '/students/teaching',
        title: 'Students'
      });
    }

    if (userHasPermission(user, 'institution:read', {
      orgId: user.institution.id
    })) {
      result.push({
        icon: Institutions,
        url: '/institutions',
        title: 'Institutions'
      });
    }

    if (userHasPermission(user, 'consortium:read', {
      orgId: user.institution.id
    })) {
      result.push({
        icon: Network,
        url: '/consortia',
        title: 'Consortia'
      });
    }

    result.push({
      icon: Settings,
      url: '/settings',
      title: 'Settings'
    });

    if (isAcadeumAdministrator(user)) {
      result.push({
        icon: AdminTools,
        url: '/admin',
        title: 'Admin Tools'
      });
    }

    return result;
  }, [
    user,
    newEnrollmentRequestsCount,
    awaitingGradesCount,
    newStudentRequestsCount,
    studentsNoRaceCount
  ]);
}
