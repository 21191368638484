import React from 'react';

import { userHasPermission } from '@acadeum/helpers';
import { InvoicesPage } from '@acadeum/shared-admin-ui';

import { useFetchInvoiceQuery, useFetchInvoicesQuery, useLazyFetchInvoicesQuery } from '../../api/invoices';
import type { AppPage } from '../../helpers/app.types';

const Invoices: AppPage = () => {
  return (
    <InvoicesPage
      useFetchInvoiceQuery={useFetchInvoiceQuery}
      useFetchInvoicesQuery={useFetchInvoicesQuery}
      useLazyFetchInvoicesQuery={useLazyFetchInvoicesQuery}
    />
  );
};

Invoices.meta = () => ({
  title: 'Invoices'
});

Invoices.when = ({ user }) => userHasPermission(user, 'paymentMethod:read', {
  orgId: user?.institution.id,
  ownerId: null
});

export default Invoices;
