import React from 'react';
import { useController } from 'react-hook-form';

import { PictureUpload } from '../../../PictureUpload';

export const FormPictureUpload = ({
  name,
  defaultValue,
  rules,
  label,
  ...rest
}) => {
  const {
    field
  } = useController({ name, defaultValue, rules });
  return (
    <PictureUpload
      label={label}
      {...rest}
      {...field}
    />
  );
};
