import React from 'react';
import PropTypes from 'prop-types';

import ConfirmActionModal from '../../ConfirmActionModal';

import { ALGOLIA_UPDATE_PENDING_NOTE } from '../../../helpers/algolia';

import actions from '../../../actions';

const {
  approveCourse,
  notify
} = actions;

export default function UseDefaultScheduleModal({
  courseId,
  show,
  onHide
}) {
  async function onSubmit() {
    // Not passing a custom `schedule` to "Approve course"
    // has the effect of resetting the `schedule` to `NULL`.
    await approveCourse(courseId);
    notify('All future sections with a start date within 8 months of the current date will be visible within your student portal.' + ALGOLIA_UPDATE_PENDING_NOTE);
  }

  return (
    <ConfirmActionModal
      show={show}
      onHide={onHide}
      title="Use Default Schedule"
      description="All future sections with a start date within 8 months of the current date will be visible within your student portal."
      submitText="Confirm"
      onSubmit={onSubmit}
    />
  );
}

UseDefaultScheduleModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  courseId: PropTypes.number.isRequired
};
