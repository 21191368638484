import type { FC, ReactNode } from 'react';
import { createContext, useContext } from 'react';

import type { UseQueryParameters } from '@acadeum/types';

const queryParametersContext = createContext<UseQueryParameters | undefined>(undefined);

export const useQueryParameters: UseQueryParameters = (callback) => {
  const useQueryParameters_ = useContext(queryParametersContext);

  if (!useQueryParameters_) {
    throw new Error('useQueryParameters must be used within a QueryParametersProvider');
  }

  return useQueryParameters_(callback);
};

interface QueryParametersProviderProps {
  useQueryParameters: UseQueryParameters;
  children: ReactNode;
}

export const QueryParametersProvider: FC<QueryParametersProviderProps> = ({
  useQueryParameters,
  children
}) => {
  return (
    <queryParametersContext.Provider value={useQueryParameters}>
      {children}
    </queryParametersContext.Provider>
  );
};
