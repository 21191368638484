import React, { memo, useState } from 'react';
import classNames from 'classnames';

import styles from './ShowMoreWrapper.module.scss';
import { ShowMoreButton } from '../ShowMoreButton';

export interface ShowMoreWrapperProps {
  className?: string;
  children?: string;
  limit?: number;
}

export const ShowMoreWrapper = memo<ShowMoreWrapperProps>(({
  className,
  children,
  limit = 50
}) => {
  const [showMore, setShowMore] = useState(false);
  const showButton = typeof children === 'string' && children.length >= limit;
  return (
    <div className={classNames(className, styles.ShowMoreWrapper)}>
      {typeof children === 'string' ? (
        showMore || !showButton ? children : `${children.substring(0, limit)}...`
      ) : children}
      {showButton && ' '}
      {showButton && (
        <ShowMoreButton className={styles.button} expanded={showMore} onClick={() => setShowMore(prevState => !prevState)}/>
      )}
    </div>
  );
});
