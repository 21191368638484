// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck TODO: Fix All types
import React, { useCallback, useEffect, useMemo, useState, useId } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useIsMounted } from '@acadeum/hooks';

import TextField from './Combobox/TextField';
import Toggle from './Combobox/Toggle';
import Combobox from './Combobox';
import Tag from './Combobox/Tag';
import { Listbox } from '../Listbox';

import { useFilteredOptions } from '../../utils/useFilteredOptions';

import styles from './Select.module.scss';

export const EMPTY_VALUE = null;

function useSelectTextField({ fetchOptions }) {
  const [inputValue, setInputValue] = useState('');

  const updateText = useCallback(async (value) => {
    setInputValue(value);
    await fetchOptions(value);
  }, [fetchOptions]);

  return { inputValue, updateText };
}

// eslint-disable-next-line
export const Select = React.forwardRef<any, Record<any, any>>(({
  async,
  multiple,
  className,
  fetchOptions,
  findOptionByValue,
  options,
  isLoading,
  style,
  isFilter,
  sameWidth = true,
  loadingLabel = 'Loading...',
  notFoundLabel = 'Not Found',
  noInputLabel = 'Start typing',
  placeholder = 'Select an Option',
  searchPlaceholder = 'Search an Option',
  ...rest
}, ref) => {
  const optionsProps = useFilteredOptions({ async, options, fetchOptions, findOptionByValue, isLoading });
  const Component = useMemo(() => multiple ? MultiSelect : SingleSelect, [multiple]);

  const isSearch = useMemo(() => {
    return async ? true : options && options.length > 8;
  }, [options, async]);

  const id = useId() + 'Select';

  return (
    <div className={classNames(className, styles.Select)} style={style}>
      <Component
        ref={ref}
        async={async}
        id={id}
        loadingLabel={loadingLabel}
        notFoundLabel={notFoundLabel}
        noInputLabel={noInputLabel}
        placeholder={placeholder}
        searchPlaceholder={searchPlaceholder}
        {...optionsProps}
        {...rest}
        isFilter={isFilter}
        sameWidth={isFilter ? false : sameWidth}
        isSearch={isSearch}
      />
    </div>
  );
});

const selectProps = {
  id: PropTypes.string,
  async: PropTypes.bool,
  fetchOptions: PropTypes.func,
  findOptionByValue: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    value: PropTypes.any,
    description: PropTypes.string
  })),
  isFilter: PropTypes.bool,
  sameWidth: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  loadingLabel: PropTypes.string,
  notFoundLabel: PropTypes.string,
  noInputLabel: PropTypes.string,
  placeholder: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  className: PropTypes.string,
  styleSchema: PropTypes.oneOf(['white']),
  createNewOptionProps: PropTypes.shape({
    label: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.node
    ]).isRequired,
    onSubmit: PropTypes.func.isRequired
  }),
  createCustomOptionProps: PropTypes.shape({
    label: PropTypes.string.isRequired,
    option: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string
    }),
    selectedByDefault: PropTypes.bool,
    onActivateCustomOption: PropTypes.func.isRequired,
    onDeactivateCustomOption: PropTypes.func
  })
};

Select.propTypes = {
  ...selectProps,
  multiple: PropTypes.bool
};

const SingleSelect = React.forwardRef(({
  id: selectId,
  async,
  fetchOptions,
  findOptionByValue,
  isLoading,
  isSearch,
  options,
  onChange: propsOnChange,
  onBlur: propsOnBlur,
  value: propsValue,
  disabled,
  readOnly,
  styleSchema,
  appearance,
  error,
  label,
  sameWidth = true,
  loadingLabel = 'Loading...',
  notFoundLabel = 'Not Found',
  noInputLabel = 'Start typing',
  placeholder = 'Select an Option',
  searchPlaceholder = 'Search an Option',
  createNewOptionProps,
  createCustomOptionProps,
  selectCustomOptionByDefault,
  isFilter
}, ref) => {
  appearance = appearance || styleSchema;

  const [selectedOption, setSelectedOption] = useState();

  const { inputValue, updateText } = useSelectTextField({ fetchOptions });

  const hasOptions = Array.isArray(options) && options.length > 0;

  const isMounted = useIsMounted();

  const onChange = async (option, { trigger = true }) => {
    if (option) {
      setSelectedOption(option);

      if (trigger) {
        if (propsOnChange) {
          propsOnChange(option.value, option);
        }
      }
      return;
    }

    if (trigger) {
      if (propsOnChange) {
        propsOnChange(EMPTY_VALUE);
      }
    }
  };

  const selectOptionForValue = async (value, { trigger }) => {
    const matchedOption = options.find(_ => _.value === value);
    if (matchedOption) {
      await onChange(matchedOption, { trigger });
      return;
    }

    if (async) {
      const option = await findOptionByValue(value);

      if (isMounted() && option) {
        await onChange(option, { trigger });
        return;
      }
    }

    await onChange(EMPTY_VALUE, { trigger });
  };

  useEffect(
    () => {
      if (isMounted()) {
        if (propsValue === undefined) {
          setSelectedOption(undefined);
        } else {
          void selectOptionForValue(propsValue, { trigger: false });
        }

        if (selectCustomOptionByDefault) {
          void onCustomOptionClick(createCustomOptionProps.option);
        }
      }
    },
    // TODO: Fix
    // When updating non `async`(async!==true) options, need to re-select a separate option.
    // How reproduce, when creating new options with `createNewOptionProps`
    async ? [propsValue] : [propsValue, options]
  );

  const onSelect = useCallback(async (value) => {
    await selectOptionForValue(value, { trigger: true });
  }, [options]);

  const onCreateNewOption = async (option) => {
    await onChange(option, { trigger: true });
  };

  const onCustomOptionClick = async (option) => {
    await onChange(option, { trigger: true });
  };

  // TODO: Remove condition from onBlur (code smells)
  return (
    <Combobox
      id={selectId}
      sameWidth={sameWidth}
      createNewOptionProps={createNewOptionProps}
      createCustomOptionProps={createCustomOptionProps}
      onCreateNewOption={onCreateNewOption}
      onCustomOptionClick={onCustomOptionClick}
      toggleButton={(
        <Toggle
          ref={ref}
          error={error}
          id={selectId}
          appearance={isFilter ? 'white' : appearance}
          placeholder={placeholder}
          disabled={disabled || readOnly}
          onBlur={hasOptions ? (isSearch ? undefined : propsOnBlur) : propsOnBlur}
          isLoading={isLoading}
          valueLabel={isFilter ? undefined : selectedOption?.label}
          children={isFilter ? label : undefined}
          isFilter={isFilter}
        />
      )}
      textField={isSearch ? (
        <TextField
          placeholder={searchPlaceholder}
          onChange={updateText}
          value={inputValue}
          onBlur={propsOnBlur}
        />
      ) : undefined}
    >
      <Listbox
        listId={`${selectId}Listbox`}
        onSelect={onSelect}
        accessibilityLabel={label}
        loading={isLoading}
      >
        {isLoading ? (
          <Listbox.EmptyOption>
            {loadingLabel}
          </Listbox.EmptyOption>
        ) : (
          hasOptions ? (
            options.map((option) => {
              const { label, value, description, count, disabled } = option;
              return (
                <Listbox.Option
                  key={`option-${value}`}
                  value={value}
                  disabled={disabled}
                  selected={isFilter ? propsValue === value : selectedOption?.value === value}
                  accessibilityLabel={label}
                  description={description}
                  count={count}
                >
                  {label}
                </Listbox.Option>
              );
            })
          ) : (
            <Listbox.EmptyOption>
              {getNotFoundLabel({
                notFoundLabel,
                noInputLabel,
                inputValue
              })}
            </Listbox.EmptyOption>
          )
        )}
      </Listbox>
    </Combobox>
  );
});

SingleSelect.propTypes = {
  ...selectProps
};

const MultiSelect = React.forwardRef(({
  id: selectId,
  async,
  fetchOptions,
  findOptionsByValues,
  findOptionByValue,
  isLoading,
  isSearch,
  options,
  onChange: propsOnChange,
  onBlur: propsOnBlur,
  value: propsValue,
  disabled,
  readOnly,
  styleSchema,
  appearance,
  error,
  label,
  sameWidth = true,
  loadingLabel = 'Loading...',
  notFoundLabel = 'Not Found',
  placeholder = 'Select an Option',
  searchPlaceholder = 'Search an Option',
  createNewOptionProps,
  createCustomOptionProps,
  isFilter,
  onSelect: propsOnSelect,
  hiddenSelect
}, ref) => {
  appearance = appearance || styleSchema;

  const [selectedOptions, setSelectedOptions] = useState([]);

  const { updateText, inputValue } = useSelectTextField({ fetchOptions });

  const isMounted = useIsMounted();

  const hasOptions = Array.isArray(options) && options.length > 0;

  const selectOptionsForValues = async (values) => {
    if (Array.isArray(values) ? values.length === 0 : true) {
      return setSelectedOptions([]);
    }

    const foundOptions = await findOptionsByValues(values);
    if (isMounted()) {
      if (foundOptions && foundOptions.length === values.length) {
        return setSelectedOptions(foundOptions);
      }
    }

    setSelectedOptions([]);
  };

  useEffect(() => {
    if (isMounted()) {
      void selectOptionsForValues(propsValue);
    }
  }, [propsValue]);

  const onChange = async (options) => {
    const prevSelectedOptions = selectedOptions;
    setSelectedOptions(options);
    // await updateText('');
    if (propsOnChange) {
      const values = options.map(_ => _.value);
      const prevValues = prevSelectedOptions.map(_ => _.value);
      propsOnChange(values.length === 0 ? EMPTY_VALUE : values, options, prevValues);
    }
  };

  const onSelect = useCallback(async (value) => {
    if (propsOnSelect) {
      propsOnSelect(value);
    }

    const matchedOption = options.find(_ => _.value === value);

    const getSelectedOptions = (option) => {
      return selectedOptions.some(_ => _.value === option.value)
        ? selectedOptions.filter((_) => _.value !== value)
        : [...selectedOptions, option];
    };

    if (matchedOption) {
      const options = getSelectedOptions(matchedOption);
      await onChange(options);
      return;
    }

    if (async) {
      const foundOption = await findOptionByValue(value);
      if (isMounted()) {
        const options = getSelectedOptions(foundOption);
        await onChange(options);
      }
    }
  }, [options, selectedOptions, updateText]);

  const removeOption = useCallback((option) => {
    void onSelect(option.value);
  }, [onSelect]);

  const onCreateNewOption = async (option) => {
    const options = [...selectedOptions, option];
    await onChange(options);
  };

  return (
    <Combobox
      id={selectId}
      multiple
      sameWidth={sameWidth}
      createNewOptionProps={createNewOptionProps}
      createCustomOptionProps={createCustomOptionProps}
      onCreateNewOption={onCreateNewOption}
      hidden={hiddenSelect}
      toggleButton={(
        <Toggle
          ref={ref}
          id={selectId}
          error={error}
          appearance={(isFilter || selectedOptions.length > 0) ? 'white' : appearance}
          placeholder={placeholder}
          disabled={disabled || readOnly}
          onBlur={hasOptions ? (isSearch ? undefined : propsOnBlur) : propsOnBlur}
          selectedOptions={selectedOptions}
          hasValue={selectedOptions.length > 0}
          isLoading={isLoading}
        >
          {isFilter ? label : ({ expanded }) => {
            const renderTags = expanded ? selectedOptions : selectedOptions.slice(0, 2);
            return (
              renderTags.length > 0 ? (
                <>
                  <div className={styles.Tags}>
                    {renderTags.map(option => (
                      <Tag key={`tag-${option.value}`} onClick={(e) => {
                        e.stopPropagation();
                        removeOption(option);
                      }}>
                        {option.label}
                      </Tag>
                    ))}
                  </div>
                  {!expanded && renderTags.length < selectedOptions.length && (
                    <span className={styles.NumberOfHiddenTags}>
                      + {selectedOptions.length - renderTags.length}
                    </span>
                  )}
                </>
              ) : null
            );
          }}
        </Toggle>
      )}
      textField={isSearch ? (
        <TextField
          placeholder={searchPlaceholder}
          onChange={updateText}
          value={inputValue}
          onBlur={propsOnBlur}
        />
      ) : undefined}
    >
      <Listbox
        listId={`${selectId}Listbox`}
        accessibilityLabel={label}
        loading={isLoading}
        onSelect={onSelect}
      >
        {isLoading ? (
          <Listbox.EmptyOption>
            {loadingLabel}
          </Listbox.EmptyOption>
        ) : (
          hasOptions ? (
            options.map((option) => {
              const { label, value, count, disabled, description } = option;
              return (
                <Listbox.Option
                  key={`option-${value}`}
                  value={value}
                  selected={(selectedOptions.find(option => option.value === value)?.value) === value}
                  accessibilityLabel={label}
                  count={count}
                  disabled={disabled}
                  description={description}
                >
                  {label}
                </Listbox.Option>
              );
            })
          ) : (
            <Listbox.EmptyOption>
              {notFoundLabel}
            </Listbox.EmptyOption>
          )
        )}
      </Listbox>
    </Combobox>
  );
});

MultiSelect.propTypes = {
  ...selectProps
};

function getNotFoundLabel({
  notFoundLabel,
  noInputLabel,
  inputValue
}) {
  if (!inputValue) {
    return noInputLabel;
  }
  return notFoundLabel;
}
