import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Checkbox } from 'react-responsive-ui';

import Action from '../Action';
import TableAction, { ActionPropType } from '../TableAction';
import TableControls from './TableControls';
import CollapsibleSectionTitle from './CollapsibleSectionTitle';
import InfoTooltip from '../InfoTooltip';

import useFilters from './useFilters';
import useDataExport from './useDataExport';
import useRowSelection from './useRowSelection';
import useCollapsibleSections from './useCollapsibleSections';

import './index.sass';

// Data table with checkable rows.
//
// `columns`:
//   `title`             - `<th/>` content
//   `content`           - `<td/>` content
//
// A table can be divided into sections:
//
// `sections[]`:
//   `title`    - NBSP section title
//   `tooltip`  – NBSP section tooltip
//   `filter`   – filters `row`s for this `section`
//
export default function Table({
  title,
  subtitle,
  className,
  noDataMessage = 'Nothing found',
  stickyControls,
  data,
  columns,
  disabled,
  totalRowsCount,
  selectableRows = true,
  maxHeight,
  sort,
  search,
  searchHint,
  searchAriaLabel,
  searchInputWidth,
  showTotalResultsCount = true,
  showEmptySections = true,
  maxSelectableRows,
  initialCheckedRows,
  checkedRows,
  setCheckedRows,
  onSelectRows,
  sections,
  actions,
  fetchDataForExport,
  fetchDataForExportGetObjectId,
  exportData,
  exportDataFileName,
  exportDataColumns,
  design,
  filters,
  controls,
  buttons
}) {
  const _getRows = (section, rows = data) => {
    if (section && section.filter) {
      return rows.filter(section.filter);
    }
    if (!sections) {
      return rows;
    }
    return sections.reduce((_rows, section) => {
      return _rows.concat(rows.filter(section.filter));
    }, []);
  };

  const normalizeAction = ({ title, onClick }) => {
    // if (type === 'download') {
    //   return {
    //     title: 'Download',
    //     onClick: (rows) => download(rows, options)
    //   };
    // }
    return {
      title,
      onClick
    };
  };

  const [
    collapsedSections,
    toggleSection
  ] = useCollapsibleSections();

  // A hacky workaround to access `selectedRows` and `setSelectedRowIds`.
  // let _onFiltersChange;

  // function onFiltersChange(newFilteredRows) {
  // if (_onFiltersChange) {
  // _onFiltersChange(newFilteredRows);
  // }
  // }

  const allRows = _getRows();

  const [
    unfilteredRows,
    filteredRows,
    filterValues,
    setFilterValues,
    searchQuery,
    setSearchQuery
  ] = useFilters(
    filters,
    search,
    allRows
    // onFiltersChange
  );

  const getRows = (section) => _getRows(section, filteredRows);

  const {
    selectedRows,
    // setSelectedRowIds,
    selectRow,
    toggleSelectAllRows,
    areAllRowsSelected
  } = useRowSelection(
    initialCheckedRows,
    checkedRows,
    setCheckedRows,
    maxSelectableRows,
    getRows,
    onSelectRows,
    unfilteredRows
  );

  // A hacky workaround to access `selectedRows` and `setSelectedRowIds`.
  // _onFiltersChange = (newFilteredRows) => {
  // setSelectedRowIds(selectedRows.filter(row => newFilteredRows.includes(row)).map(row => row.id));
  // };

  const [exportData_] = useDataExport(
    fetchDataForExport,
    fetchDataForExportGetObjectId,
    exportData,
    exportDataFileName,
    exportDataColumns,
    filteredRows,
    selectedRows
  );

  let buttons_ = buttons;

  if (exportData || exportDataColumns) {
    // Download selected rows info.
    buttons_ = (
      <>
        <Action
          icon="download"
          iconSize="l"
          onClick={exportData_}>
          Download Info
        </Action>
        {buttons}
      </>
    );
  }

  if (totalRowsCount === undefined) {
    totalRowsCount = getRows().length;
  }

  function renderTable() {
    return (
      <>
        {showTotalResultsCount && (
          <div role="status" className="data-table__total-results-count">
            {selectedRows.length === 0 ? (
              <>
                <span className="data-table__total-results-count-value">
                  {totalRowsCount}
                </span>
                {' '}
                Result{totalRowsCount === 1 ? '' : 's'}
              </>
            ) : (
              <>
                <span className="data-table__total-results-count-value">
                  {selectedRows.length}
                </span>
                {' '}
                of
                {' '}
                <span className="data-table__total-results-count-value">
                  {maxSelectableRows || totalRowsCount}
                </span>
                {' '}
                Result{(maxSelectableRows || totalRowsCount) === 1 ? ' is' : 's are'} selected
              </>
            )}
          </div>
        )}

        <div
          style={maxHeight ? { maxHeight } : undefined}
          className={classNames('data-table__container data-table__horizontal-scrollable', {
            'data-table__container--scrollable': maxHeight
          })}>
          <table className={classNames('data-table', {
            'data-table--empty': totalRowsCount === 0
          })}>
            {(totalRowsCount > 0 || showEmptySections) &&
              <thead>
                <tr className="data-table__header">
                  {selectableRows &&
                    <td className="data-table__header-checkbox">
                      <SelectAllRowsCheckbox
                        disabled={disabled}
                        areAllRowsSelected={areAllRowsSelected}
                        toggleSelectAllRows={toggleSelectAllRows}/>
                    </td>
                  }
                  {columns.map((column, i) => {
                    let colSpan = 1;
                    for (let j = i + 1; j < columns.length; j++) {
                      if (!columns[j].title) {
                        colSpan++;
                      } else {
                        break;
                      }
                    }

                    if (!column.title) {
                      return;
                    }
                    return (
                      <th key={i} colSpan={colSpan}>
                        {column.title}
                      </th>
                    );
                  })}
                </tr>
              </thead>
            }

            <tbody>
              {renderTableBody()}
            </tbody>
          </table>
        </div>

        {actions &&
          <div className="data-table__actions">
            {actions.map((action, id) => (
              <TableAction
                key={id}
                action={normalizeAction(action)}
                data={data}
                selectedRowIds={selectedRows.map(row => row.id)}
                className="data-table__action"/>
            ))}
          </div>
        }
      </>
    );
  }

  const renderRows = (sectionId, rows) => {
    if (sort) {
      rows.sort(sort);
    }

    return rows.map((row, i) => {
      const id = `checkbox:${row.id}`;
      const studentElementId = `student:${row.id}`;
      const institutionElementId = row.institution
        ? `${row.id}:${row.institution.id}`
        : (
          row.section
            ? `${row.id}:${row.section.session.course.institution.id}`
            : ''
        );

      return (
        <tr
          key={`content-${sectionId}-${row.id === undefined ? i : row.id}-main-row`}
          data-id={row.id}
          className={classNames('data-table__row', {
            'data-table__row--selected': selectableRows ? selectedRows.includes(row) : undefined
          })}>
          {selectableRows &&
            <td>
              <RowCheckbox
                id={id}
                row={row}
                selectedRows={selectedRows}
                selectRow={selectRow}
                disabled={disabled}
                aria-labelledby={`${id} ${studentElementId} ${institutionElementId}`}
              />
            </td>
          }
          {columns.map((column, i) => (
            <td key={i}>{column.content(row)}</td>
          ))}
        </tr>
      );
    });
  };

  const renderTableBody = () => {
    if (!sections) {
      return renderRows('root', getRows());
    }

    return sections.map((section, i) => {
      const rows = getRows(section);

      // On student enrollments page, even if `rows.length === 0`
      // the section header is still shown by design.
      // https://github.com/oseibonsu/CollegeConsortiumTickets/issues/213
      if (rows.length === 0 && !showEmptySections) {
        return;
      }

      const header = (
        <tr
          key={`header-${section.title}`}
          className={classNames('data-table__expandable-section', {
            'data-table__expandable-section--first': i === 0,
            'data-table__expandable-section--empty': rows.length === 0,
            'data-table__expandable-section--collapsed': collapsedSections[section.title]
          })}>
          <td colSpan={columns.length + 1}>
            <div className="data-table__expandable-section-title">
              <CollapsibleSectionTitle
                title={section.title}
                rowsCount={rows.length}
                collapsedSections={collapsedSections}
                toggleSection={toggleSection}
              />
              <div className="data-table__expandable-section-title-tooltip-container">
                <InfoTooltip>
                  {section.tooltip}
                </InfoTooltip>
              </div>
            </div>
          </td>
        </tr>
      );

      if (collapsedSections[section.title] || rows.length === 0) {
        return [header];
      }

      const sectionCheckboxRow = (
        <tr
          key={`header-${section.title}-checkbox-row`}
          className="data-table__expandable-section-checkbox-row">
          <td colSpan={columns.length + 1}>
            <SelectAllRowsInSectionCheckbox
              section={section}
              areAllRowsSelected={areAllRowsSelected}
              toggleSelectAllRows={toggleSelectAllRows}
              disabled={disabled}/>
          </td>
        </tr>
      );

      const _rows = renderRows(section.title, rows);
      return selectableRows ? [header, sectionCheckboxRow, _rows] : [header, _rows];
    })
      .filter(_ => _);
  };

  const showData = totalRowsCount > 0 || showEmptySections;

  return (
    <section className={classNames(className, 'data-table__wrapper', {
      'data-table__wrapper--v2': design === 'v2'
    })}>
      {title &&
        <h1 className="data-table__title">
          {title}
        </h1>
      }
      {subtitle &&
        <p className="data-table__subtitle">
          {subtitle}
        </p>
      }
      <TableControls
        design={design}
        sticky={stickyControls}
        rows={allRows}
        filters={filters}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        searchHint={searchHint}
        searchAriaLabel={searchAriaLabel}
        searchInputWidth={searchInputWidth}
        searchQuery={searchQuery}
        setSearchQuery={search && setSearchQuery}
        buttons={buttons_}
        controls={controls}
      >
        {!showData &&
          <div className="data-table__no-data">
            {noDataMessage}
          </div>
        }
        {showData && renderTable()}
      </TableControls>
    </section>
  );
}

Table.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  noDataMessage: PropTypes.string,
  stickyControls: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.func.isRequired
  })).isRequired,
  disabled: PropTypes.bool,
  totalRowsCount: PropTypes.number,
  // Pass `false` to hide checkboxes on table rows.
  selectableRows: PropTypes.bool,
  maxHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  sort: PropTypes.func,
  search: PropTypes.func,
  searchHint: PropTypes.string,
  searchAriaLabel: PropTypes.string,
  searchInputWidth: PropTypes.oneOf(['wide']),
  showTotalResultsCount: PropTypes.bool,
  showEmptySections: PropTypes.bool,
  maxSelectableRows: PropTypes.number,
  initialCheckedRows: PropTypes.arrayOf(PropTypes.number),
  checkedRows: PropTypes.arrayOf(PropTypes.number),
  setCheckedRows: PropTypes.func,
  onSelectRows: PropTypes.func,
  sections: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
    filter: PropTypes.func
  })),
  actions: PropTypes.arrayOf(ActionPropType),
  fetchDataForExport: PropTypes.func,
  fetchDataForExportGetObjectId: PropTypes.func,
  exportData: PropTypes.func,
  exportDataFileName: PropTypes.string,
  exportDataColumns: PropTypes.arrayOf(PropTypes.object),
  design: PropTypes.oneOf(['v2'])
};

function SelectAllRowsCheckbox({
  areAllRowsSelected,
  toggleSelectAllRows,
  ...rest
}) {
  return (
    <Checkbox
      {...rest}
      value={areAllRowsSelected()}
      onChange={toggleSelectAllRows}
      aria-label="Select All Rows"
      className="data-table__checkbox"/>
  );
}

function SelectAllRowsInSectionCheckbox({
  section,
  areAllRowsSelected,
  toggleSelectAllRows,
  ...rest
}) {
  return (
    <Checkbox
      {...rest}
      value={areAllRowsSelected(section)}
      onChange={() => toggleSelectAllRows(section)}
      aria-label={`Select All Rows from "${section.title}" Section`}
      className="data-table__checkbox"/>
  );
}

function RowCheckbox({
  row,
  selectedRows,
  selectRow,
  ...rest
}) {
  return (
    <Checkbox
      {...rest}
      value={selectedRows.includes(row)}
      onChange={() => selectRow(row.id)}
      aria-label="Select Row"
      className="data-table__checkbox"/>
  );
}
