import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import get from 'lodash/get';
import { useSelector } from 'react-redux';

import { getAuthSelector } from '@acadeum/auth';
import { useTranslate } from '@acadeum/translate';
import type { TableExportDataColumns, TabsNavigationProps } from '@acadeum/ui';
import { Actions, Blank, DataBlock, Filters, FormatDate, Link, Page, Table, TabsNavigation, Tag } from '@acadeum/ui';
import type {
  FetchInvoicesInput,
  FetchInvoicesListItem,
  UseFetchInvoiceQuery,
  UseFetchInvoicesQuery,
  UseLazyFetchInvoicesQuery
} from '@acadeum/api';
import { useQueryWithPagination } from '@acadeum/hooks';

import { useLocation } from '../../providers/useLocation';
import { useApp } from '../../providers/useApp';
import { formatCurrency } from '../../helpers/format';
import { useSettingsRoutes } from '../../hooks/useSettingsRoutes';
import { useOnError } from '../../providers/useOnError';

import { InvoiceDetails, UnableDownloadModal } from './ui/InvoiceDetails';

export interface InvoicesPageProps {
  useFetchInvoicesQuery: UseFetchInvoicesQuery;
  useFetchInvoiceQuery: UseFetchInvoiceQuery;
  useLazyFetchInvoicesQuery: UseLazyFetchInvoicesQuery;
}

const columnHelper = createColumnHelper<FetchInvoicesListItem>();

export const InvoicesPage: FC<InvoicesPageProps> = ({
  useFetchInvoicesQuery,
  useFetchInvoiceQuery,
  useLazyFetchInvoicesQuery
}) => {
  const t = useTranslate('shared-admin-ui.financial');
  const { getSettingsUrl } = useSettingsRoutes();
  const { app } = useApp();
  const onError = useOnError();

  const user = useSelector(getAuthSelector('user'));

  const location = useLocation();

  const [showUnableDownloadModal, setShowUnableDownloadModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<FetchInvoicesListItem>();

  const { getTeachingInvoicesUrl, getInvoiceHistoryUrl, getPaymentHistoryUrl } = useSettingsRoutes();

  const columns = useMemo(() => ([
    columnHelper.accessor((originalRow) => formatCurrency(originalRow.amount, { currency: 'USD', cents: true }), {
      id: 'amount',
      header: t('invoiceAmount')
    }),
    columnHelper.accessor(originalRow => t(`paymentTypes.${originalRow.type}`), {
      id: 'type',
      header: t('transactionType')
    }),
    columnHelper.accessor((originalRow) => originalRow.type === 'ANNUAL_FEE'
      ? 'Acadeum'
      : originalRow.institution?.name, {
      id: 'counterparty',
      header: t('counterparty'),
      cell: ({ row }) => {
        return row.original.type === 'ANNUAL_FEE'
          ? t('acadeum')
          : row.original.institution ? <DataBlock type="institution" institution={row.original.institution}/> : null;
      }
    }),
    columnHelper.accessor('created', {
      header: t('invoiceDate'),
      cell: ({ getValue }) => {
        return (
          <FormatDate
            utc={false}
            date={getValue()}
            month="short"
            day="numeric"
          />
        );
      }
    }),
    columnHelper.accessor('dueDate', {
      header: t('dueDate'),
      cell: ({ getValue }) => {
        const dueDate = getValue();
        return dueDate ? (
          <FormatDate
            utc={false}
            date={dueDate}
            month="short"
            day="numeric"
          />
        ) : (
          <Blank/>
        );
      }
    }),
    columnHelper.accessor('status', {
      header: t('invoiceStatus'),
      cell: ({ getValue }) => <Tag variant={getValue()}/>
    }),
    columnHelper.accessor('number', {
      header: t('invoiceNumber'),
      cell: ({ row }) => {
        return (
          <Link
            external
            removeUnderline={false}
            to={row.original.invoicePdf}
            onClick={(event) => {
              if (!row.original.invoicePdf) {
                event.preventDefault();
                setShowUnableDownloadModal(true);
                return;
              }
            }}
          >
            {row.original.number}
          </Link>
        );
      }
    }),
    columnHelper.accessor('id', {
      header: t('stripeInvoiceId')
    }),
    columnHelper.display({
      id: 'actions',
      cell: ({ row, downloadRow }) => (
        <Actions
          variant="kebab"
          actions={[
            {
              title: t('download'),
              onClick: () => downloadRow()
            },
            {
              title: t('seeInvoiceDetails'),
              onClick: () => setSelectedInvoice(row.original)
            },
            ...(row.original.status === 'paid' ? [
              {
                title: t('seePaymentDetails'),
                url: `${getPaymentHistoryUrl()}?search=${row.original.charge}`
              }
            ] : [])
          ]}
        />
      )
    })
  ]), [t]);

  const exportDataColumns = useMemo(() => columns.reduce<TableExportDataColumns<'xlsx', FetchInvoicesListItem>>((res,
    column) => {
    if (column.id === 'actions') {
      return res;
    }

    if (typeof column.header !== 'string') {
      throw new Error('Invalid column header');
    }

    const widthDic = {
      amount: 7,
      created: 25,
      dueDate: 25
    };

    res.push({
      title: column.header,
      width: widthDic[column['accessorKey']] || 18,
      value: row => {
        if (typeof column['accessorKey'] === 'string') {
          return get(row, column['accessorKey']);
        } else if (typeof column['accessorFn'] === 'function') {
          return column['accessorFn'](row);
        }
        throw new Error(`Invalid column accessor: ${column.header}`);
      }
    });

    return res;
  }, []), [columns, t]);

  const fetchDataForExport = useFetchDataForExport(useLazyFetchInvoicesQuery);

  const {
    data,
    error,
    isFetching,
    _globalFilterOptions,
    _paginationOptions,
    _filtersOptions
  } = useQueryWithPagination(useFetchInvoicesQuery, {
    additional: { v: 2 },
    search: location.query.invoice as string
  });

  const menuItems = useMemo(() => {
    const items: TabsNavigationProps['menuItems'] = [
      {
        title: t('enrollingInvoices'),
        url: getInvoiceHistoryUrl(),
        active: true
      }
    ];

    if (user?.institution.teaching) {
      items.push({
        title: t('teachingInvoices'),
        url: getTeachingInvoicesUrl()
      });
    }
    return items;
  }, [user?.institution.teaching, getInvoiceHistoryUrl, getTeachingInvoicesUrl]);

  if (error) {
    return onError(error);
  }

  return (
    <Page
      title={t('invoices')}
      breadcrumbs={[[t(app === 'admin' ? 'general' : 'settings', { global: true }), getSettingsUrl()], t('invoices')]}
    >
      <TabsNavigation menuItems={menuItems}/>

      <Filters
        border
        values={_filtersOptions.filters}
        onFiltersChange={_filtersOptions.onFiltersChange}
      >
        <Filters.Row>
          <Filters.Select
            name="type"
            label={t('type')}
            getFilterLabel={(value) => t(`paymentTypes.${value}`)}
            options={[
              { value: null, label: 'All' },
              { value: 'ENROLLMENT_FEE', label: 'Enrollment fee' },
              { value: 'ANNUAL_FEE', label: 'Annual fee' }
            ]}
          />
          {_filtersOptions.filters.type !== 'ANNUAL_FEE' && (
            <Filters.Institution
              multiple
              type="institution"
              name="institutionIds"
              label="Counterparty"
            />
          )}
          <Filters.Select
            name="status"
            label="Status"
            options={[
              { value: null, label: 'All' },
              { value: 'draft', label: 'Draft' },
              { value: 'open', label: 'Open' },
              { value: 'paid', label: 'Paid' },
              { value: 'uncollectible', label: 'Uncollectible' },
              { value: 'void', label: 'Void' }
            ]}
          />
          <Filters.DateRange
            utc
            name="created"
            label="Invoice Date"
          />
        </Filters.Row>
      </Filters>

      <Table
        enableSorting
        enableRowSelection
        columns={columns}
        data={data?.results}
        loading={isFetching}
        paginationOptions={_paginationOptions}
        globalFilterOptions={_globalFilterOptions}
        translate={{
          resultText: ({ totalCount }) => t('invoicePage.resultText', { totalCount }),
          selectedResultText: ({ totalCount, selectedRowsCount }) => t('invoicePage.selectedResultText', {
            totalCount,
            selectedRowsCount
          }),
          searchPlaceholder: t('invoicePage.searchByInvoiceId')
        }}
        exportOptions={{
          type: 'xlsx',
          fileName: 'enrolling_invoices',
          exportDataColumns,
          fetchDataForExport
        }}
      />

      {selectedInvoice && (
        <InvoiceDetails
          useFetchInvoiceQuery={useFetchInvoiceQuery}
          invoiceId={selectedInvoice.id}
          chargeId={selectedInvoice.charge}
          onHide={() => setSelectedInvoice(undefined)}
        />
      )}

      <UnableDownloadModal
        show={showUnableDownloadModal}
        onHide={() => setShowUnableDownloadModal(false)}
      />
    </Page>
  );
};

function useFetchDataForExport(useLazyFetchInvoicesQuery: UseLazyFetchInvoicesQuery) {
  const [lazyFetchInvoicesQuery] = useLazyFetchInvoicesQuery();

  return useCallback(async (ids) => {
    const pageSize = 1000;
    let page = 1;
    let allRecords: FetchInvoicesListItem[] = [];

    function fetchRecords() {
      const query: FetchInvoicesInput = {
        v: 2,
        page,
        pageSize
      };

      if (ids.length > 0) {
        query.filters = {};
        query.filters.ids = ids;
      }

      return lazyFetchInvoicesQuery(query).unwrap().then((response) => {
        allRecords = allRecords.concat(response.results);
        page += 1;

        if (response.results.length === pageSize) {
          return fetchRecords();
        } else {
          return allRecords;
        }
      });
    }

    return await fetchRecords();
  }, [lazyFetchInvoicesQuery]);
}
