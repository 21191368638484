import isValidEmail from 'common-lib/lib/isValidEmail';
import isValidPhoneNumber from 'common-lib/lib/isValidPhoneNumber';
import isValidName from 'common-lib/lib/isValidName';
import isValidUrl from 'common-lib/lib/isValidUrl';

import isEmpty from 'common-lib/lib/isEmpty';

export default function onField({ type, validate, t }) {
  switch (type) {
    case 'email':
      validate = prependValidation(validate, (value) => {
        if (!isValidEmail(value)) {
          return t('error.invalidEmail');
        }
      });
      break;
    case 'phone':
      validate = prependValidation(validate, (value) => {
        if (!isValidPhoneNumber(value)) {
          return t('error.invalidPhoneNumber');
        }
      });
      break;
    case 'name':
      type = undefined;
      validate = prependValidation(validate, (value) => {
        if (!isValidName(value)) {
          return t('error.invalidName');
        }
      });
      break;
    case 'url':
      type = undefined;
      validate = prependValidation(validate, (value) => {
        if (!isValidUrl(value)) {
          return t('error.invalidUrl');
        }
      });
      break;
    case 'json':
      validate = prependValidation(validate, validateJson);
      break;
  }

  // Skip validation for empty values because there's
  // a separate`required` validation for that.
  if (validate) {
    const originalValidate = validate;
    validate = (value, secondArgument) => {
      if (isEmpty(value)) {
        // Empty.
      } else {
        return originalValidate(value, secondArgument);
      }
    };
  }

  return {
    type,
    validate
  };
}

function prependValidation(validate, validation) {
  // `easy-react-form` library passes a second argument — all field values.
  // It's used in some `validate` functions on the Course Share website.
  // https://www.npmjs.com/package/easy-react-form
  return (value, secondArgument) => {
    const error = validation(value);
    if (error) {
      return error;
    }
    if (validate) {
      return validate(value, secondArgument);
    }
  };
}

function validateJson(value) {
  try {
    JSON.parse(value);
  } catch (error) {
    console.error(error.message);
    return error.message;
  }
}
