import React, { memo } from 'react';

import { Output } from '../../Output';

export interface CardContentProps {
  label?: React.ReactNode;
  type?: 'date';
  // eslint-disable-next-line
  value: any;
}

export const CardContent: React.FC<CardContentProps> = memo(({
  label,
  value,
  type
}) => {
  return (
    <Output
      dl
      inverted
      utc={type === 'date'}
      type={type}
      label={label}
      value={value}
      month="short"
    />
  );
});
