import React from 'react';

import { Select } from '../../Select';

import type { BaseFilterProps } from '../context';
import { useRegisterFilter } from '../context';

export interface SelectFilterProps extends BaseFilterProps {
  [key: string]: any;
}

export const SelectFilter: React.FC<SelectFilterProps> = ({
  name,
  label,
  getFilterLabel: propsGetFilterLabel,
  onChange: propsOnChange,
  ...rest
}) => {
  const getFilterLabel = (value, filter) => {
    if (propsGetFilterLabel) {
      return propsGetFilterLabel(value, filter);
    }
    return filter.label;
  };

  const { option, onChange } = useRegisterFilter({ name, label, propsOnChange, getFilterLabel });

  return (
    <Select
      {...rest}
      isFilter
      label={label}
      value={option ? option.value : undefined}
      onChange={onChange}
    />
  );
};
