import UTC_DATE_TIME_OFFSET from '../constants/utcDateTimeOffset.js';

const DAY = 24 * 60 * 60 * 1000;
const COURSE_ENROLLMENT_REQUEST_REVIEW_LEEWAY = DAY;

// Returns the lower border for a UTC+0 timestamp of a course's "Last Add Date"
// in order for students to be able to enroll.
// If a course's "Last Add Date" is greater or equal to this timestamp,
// students can still enroll. Otherwise, they can't enroll.
//
// The idea for the calculation is that:
// * First, "shift" the UTC+0 timestamp in order for it to be more intuitive
//   for US-based users.
// * Then, allow for some "leeway" for the Teaching Institution to review the
//   enrollment requests and either approve or deny them.
//   A day is assumed to be sufficient. Although what if that "leeway" day
//   is on a weekend due to the course starting on Monday?
//   No one would be reviewing enrollment requests on a weekend, so that could be
//   considered a bug in these calculations.
//
export default function getMinCourseSessionLastAddDateTimeToJoin() {
  return (Date.now() + UTC_DATE_TIME_OFFSET) - COURSE_ENROLLMENT_REQUEST_REVIEW_LEEWAY;
}
