import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './EmptyOption.module.scss';

const EmptyOption = ({ children, className }) => {
  return (
    <li role="presentation" className={classNames(styles.EmptyOption, className)}>
      {children}
    </li>
  );
};

EmptyOption.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default EmptyOption;
