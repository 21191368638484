import React, { useRef, useEffect } from 'react';
import type { InferProps } from 'prop-types';
import PropTypes from 'prop-types';

const propTypes = {
  Component: PropTypes.elementType.isRequired,
  country: PropTypes.string,
  setValue: PropTypes.func.isRequired
};

export type PostalCodeInputProps = React.HTMLAttributes<HTMLInputElement> & InferProps<typeof propTypes>

export const PostalCodeInput = React.forwardRef<HTMLInputElement, PostalCodeInputProps>(({
  country,
  Component,
  setValue,
  ...rest
}, ref) => {
  const hasMounted = useRef(false);

  // When the user selects another `country`,
  // the existing postal code input should be cleared.
  useEffect(() => {
    if (hasMounted.current) {
      setValue();
    }
  }, [country]);

  useEffect(() => {
    hasMounted.current = true;
  }, []);

  return (
    <Component
      ref={ref}
      {...rest} />
  );
});

PostalCodeInput.propTypes = propTypes;
