import React from 'react';
import { useSelector } from 'react-redux';

import { Button, ButtonGroup, InstitutionLink, Link, Title } from '@acadeum/ui';
import { userHasPermission } from '@acadeum/helpers';

import { isConsortialCourse, isCourseApproved, patchCourseRemoveApproval } from '../../../../helpers/course';
import { ALGOLIA_UPDATE_PENDING_NOTE } from '../../../../helpers/algolia';
import getErrorData from '../../../../helpers/getErrorData';
import useLocation from '../../../../hooks/useLocation.js';

import actions from '../../../../actions';

import { CourseBadges } from '../../../CourseBadges';

import styles from './CourseHeader.module.scss';

const { notify, removeCourseApproval } = actions;

export const CourseHeader = ({
  course,
  section,
  onRecommend,
  canEdit
}) => {
  const { user } = useSelector(state => state.auth);

  const isConsortial = user && isConsortialCourse(course, user.institution.id);
  const isApproved = user && isCourseApproved(course, user.institution.id);

  const onRemoveApproval = async ({ notes }) => {
    // Removing an approval can return "Not found"
    // because Algolia isn't updated immediately
    // so an impatient user might try to remove a course twice.
    // Also, an approval will return "Not found"
    // if the course has been approved via the legacy JSON data approach.
    try {
      await removeCourseApproval(course.id, notes);
      patchCourseRemoveApproval(course, user.institution.id);
      notify(`The approval of this course has been removed. ${ALGOLIA_UPDATE_PENDING_NOTE}`);
    } catch (error) {
      const { message } = getErrorData(error);
      if (message === 'COURSE_APPROVAL_NOT_FOUND') {
        notify('Course Approval not found for this course. Maybe someone from your school has just unapproved it. Or, it could be a bug. Please contact Acadeum support for further assistance.');
      } else {
        throw error;
      }
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <CourseTitle
          course={course}
          section={section}
        />
        <CourseActions
          course={course}
          section={section}
          canEdit={canEdit}
          onRecommend={onRecommend}
        />
      </div>

      <InstitutionLink
        monochrome
        includeLogo
        institution={course.institution}
      />

      <div className={styles.badgesAndActionsWrapper}>
        <CourseBadges
          variant="page"
          hasCategories
          course={course}
          isApproved={isApproved}
          isConsortial={isConsortial}
        />

        {isApproved && (
          <div className={styles.actions}>
            <Button variant="black-outline" onClick={onRemoveApproval}>
              Remove Approval
            </Button>
          </div>
        )}

      </div>
    </div>
  );
};

function CourseTitle({ course, section }) {
  const location = useLocation();
  const isHS4CC = location.query.hs4cc === '✓';

  return (
    <Title marginBottom="small">
      {section ? (
        <Link
          to={`/courses/${course.id}${isHS4CC ? '?hs4cc=✓' : ''}`}
          className="CourseHeader-titleLink">
          {course.code} {course.title}
        </Link>
      ) : (
        <>
          {course.code} {course.title}
        </>
      )}
    </Title>
  );
}

function CourseActions({
  course,
  section,
  onRecommend,
  canEdit
}) {
  const user = useSelector(state => state.auth.user);

  const isApproved = user && isCourseApproved(course, user.institution.id);
  const canApprove = user
    && !isApproved
    && course.institution.id !== user.institution.id
    && userHasPermission(user, 'courseApproval:create', {
      orgId: user.institution.id
    });

  if (!canApprove && !onRecommend && !canEdit) {
    return null;
  }

  return (
    <ButtonGroup>
      {!section && onRecommend && (
        <Button
          onClick={onRecommend}
          variant="secondary"
        >
          Recommend
        </Button>
      )}
      {canApprove && (
        <Button url={`/courses/${course.id}/approve`}>
          Approve Course
        </Button>
      )}
      {canEdit && (
        <Button url={`/courses/${course.id}/edit`}>
          Edit
        </Button>
      )}
    </ButtonGroup>
  );
}
