import React, { memo } from 'react';

import type { Locale } from '@acadeum/types';

import { RelativeDateFormat } from '../../../RelativeDateFormat';
import { Blank } from '../../../Blank';

export interface RelativeDateBlockProps {
  date?: string | number | Date;
  locale?: Locale;
}

export const RelativeDateBlock: React.FC<RelativeDateBlockProps> = memo(({
  date,
  locale
}) => {
  if (date) {
    const date_ = date instanceof Date ? date : new Date(date);
    return (
      <RelativeDateFormat date={date_} locale={locale}/>
    );
  }
  return (
    <Blank/>
  );
});
