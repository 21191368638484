import type { FC, ReactNode } from 'react';
import { createContext, useContext } from 'react';

import type { UseLocation } from '@acadeum/types';

const LocationContext = createContext<UseLocation | undefined>(undefined);

export const useLocation: UseLocation = () => {
  const useLocation = useContext(LocationContext);

  if (!useLocation) {
    throw new Error('useLocation must be used within a LocationProvider');
  }

  return useLocation();
};

interface LocationProviderProps {
  useLocation: UseLocation;
  children: ReactNode;
}

export const LocationProvider: FC<LocationProviderProps> = ({ useLocation, children }) => {
  return (
    <LocationContext.Provider value={useLocation}>
      {children}
    </LocationContext.Provider>
  );
};
