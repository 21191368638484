import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Cell } from './Cell';

import { LayoutContext } from './context';
import { useLayoutStyles } from './hooks';

import type { AsProp } from '../../utils/props';

import styles from './Layout.module.scss';

export interface LayoutProps extends AsProp, React.HTMLAttributes<HTMLElement> {
  cols?: number;
  alignItems?: string;
  gap?: string | number;
  justifyItems?: string;
  rowHeight?: string;
}

export const Layout: React.FC<LayoutProps> & {
  Cell: typeof Cell;
} = ({
  as: Component = 'div',
  className,
  children,
  cols = 12,
  alignItems,
  gap = '1.5rem',
  justifyItems,
  rowHeight = 'auto',
  style: propsStyle,
  ...rest
}) => {
  const style = useLayoutStyles(propsStyle, {
    cols,
    alignItems,
    gap,
    justifyItems,
    rowHeight
  });

  const layoutContext = useMemo(() => ({ cols }), [cols]);

  return (
    <Component
      {...rest}
      style={style}
      className={classNames(className, styles.Layout)}
    >
      <LayoutContext.Provider value={layoutContext}>
        {children}
      </LayoutContext.Provider>
    </Component>
  );
};

Layout.Cell = Cell;
Layout.propTypes = {
  alignItems: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  cols: PropTypes.number,
  gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  justifyItems: PropTypes.string,
  rowHeight: PropTypes.string,
  as: PropTypes.any
};
