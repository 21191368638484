import React, { memo } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import type { ButtonProps } from '@acadeum/ui';
import { Button, HStack, Sticky } from '@acadeum/ui';
import { useTranslate } from '@acadeum/translate';

import styles from './StickyFooter.module.scss';

interface StickyFooterProps {
  backProps?: ButtonProps;
  cancelProps?: ButtonProps;
  submitProps: ButtonProps;
}

export const StickyFooter: React.FC<StickyFooterProps> = memo(({
  backProps,
  cancelProps,
  submitProps
}) => {
  const t = useTranslate('shared-admin-ui.DataUploadPage');

  return (
    <Sticky position="bottom">
      <HStack gap="md">
        {cancelProps && (
          <Button
            className={classNames({
              [styles['StickyFooter__cancelLeft']]: !isEmpty(backProps),
              [styles['StickyFooter__cancel']]: isEmpty(backProps)
            })}
            children={t('cancel')}
            variant={isEmpty(backProps) ? 'secondary' : 'text-inline'}
            {...cancelProps}
          />
        )}
        {backProps && (
          <Button
            children={t('back')}
            variant="secondary"
            ml="auto"
            {...backProps}
          />
        )}
        <Button {...submitProps}>
          {submitProps.children || t('submit')}
        </Button>
      </HStack>
    </Sticky>
  );
});
