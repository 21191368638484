import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Select } from 'react-responsive-ui';
import pick from 'lodash/pick';

import PivotTableUIImport from 'react-pivottable/PivotTableUI';
import TableRenderers from 'react-pivottable/TableRenderers';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import { PivotData } from 'react-pivottable/Utilities';

// create Plotly React component via dependency injection
import PlotImport from 'react-plotly.js';

import writeXlsx from '../../helpers/xlsx';

import actions from '../../actions';

import 'react-pivottable/pivottable.css';

// This is an alternative way of using a "custom" bundle of "Plotly".
// Originally, this was how this code was written.
// But it looked too complex so I replaced it with simply `import Plot from 'react-plotly.js'`.
//
// https://plotly.com/javascript/react
//
// Also uncomment in `index.html`:
// <!-- <script src="https://cdn.plot.ly/plotly-latest.min.js"></script> -->
// import createPlotlyComponentImport from 'react-plotly.js/factory';
// // For some weird reason, the exported function is in a `default` property.
// const createPlotlyComponent = createPlotlyComponentImport.default;
// // create Plotly React component via dependency injection
// const Plot = createPlotlyComponent(window.Plotly);

// For some weird reason, because of `type: "module"`, those imports are wrapped in `{ default }`.
const Plot = PlotImport.default ? PlotImport.default : PlotImport;
const PivotTableUI = PivotTableUIImport.default ? PivotTableUIImport.default : PivotTableUIImport;

const { fetchAnalyticsData } = actions;

// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);

const savedReports = {
  'Sum of Costs': {
    'cols': [
      'Home Institution'
    ],
    'rows': [
      'Teaching Institution'
    ],
    'rowOrder': 'key_a_to_z',
    'colOrder': 'key_a_to_z',
    'aggregatorName': 'Sum',
    'rendererName': 'Table Heatmap',
    'valueFilter': {},
    'vals': [
      'Cost'
    ]
  },
  'Home Institution Costs by Year': {
    'cols': [
      'Year'
    ],
    'rows': [
      'Home Institution'
    ],
    'rowOrder': 'key_a_to_z',
    'colOrder': 'key_a_to_z',
    'aggregatorName': 'Sum',
    'rendererName': 'Table',
    'valueFilter': {
      'Paid': {
        'false': true
      },
      'Refunded': {
        'true': true
      }
    },
    'vals': [
      'Cost'
    ]
  },
  'Tom\'s Report': {
    'cols': [],
    'rows': [
      'Year',
      'Category'
    ],
    'rowOrder': 'value_z_to_a',
    'colOrder': 'key_a_to_z',
    'aggregatorName': 'Count',
    'rendererName': 'Table',
    'valueFilter': {
      'Year': {
        '2016': true,
        '2017': true,
        '2018': true
      }
    },
    'vals': []
  },
  'Jim\'s Report': {
    'cols': [
      'Year',
      'Session'
    ],
    'rows': [
      'Home Institution'
    ],
    'rowOrder': 'key_a_to_z',
    'colOrder': 'key_a_to_z',
    'aggregatorName': 'Count',
    'rendererName': 'Table',
    'valueFilter': {},
    'vals': []
  },
  'Paid Inter CIC Consortium Activity': {
    'cols': [
      'Year',
      'Teaching Institution'
    ],
    'rows': [
      'Home Institution'
    ],
    'rowOrder': 'key_a_to_z',
    'colOrder': 'key_a_to_z',
    'aggregatorName': 'Sum',
    'rendererName': 'Table',
    'valueFilter': {
      'HI Consortium Name': {
        '': true,
        'CCCU Online Course Sharing Consortium': true,
        'Yes We Must Coalition: The Wheel Online Consortium': true,
        'Appalachian College Association Consortium': true,
        'Kansas Independent College Association Consortium': true,
        'Kansas Independent College Association Consortium ,CCCU Online Course Sharing Consortium': true,
        'Appalachian College Association Consortium ,Yes We Must Coalition: The Wheel Online Consortium': true
      },
      'TI Consortium Name': {
        '': true,
        'Appalachian College Association Consortium': true,
        'Kansas Independent College Association Consortium ,CCCU Online Course Sharing Consortium': true,
        'Kansas Independent College Association Consortium': true,
        'Appalachian College Association Consortium ,Yes We Must Coalition: The Wheel Online Consortium': true,
        'Georgia Independent College Association Consortium': true
      },
      'Paid': {
        'false': true
      },
      'Refunded': {
        'true': true
      }
    },
    'vals': [
      'Cost'
    ]
  }
};

export default function Analytics() {
  const analyticsData = useSelector(state => state.analytics.analyticsData);

  const [pivot, setPivot] = useState({});
  const [selectedReport, setSelectedReport] = useState();

  const pivotTable = useRef();

  const clear = () => {
    setPivot({});
    setSelectedReport();
  };

  const alert = () => {
    const state = pick(this.state.pivot, 'cols', 'rows', 'rowOrder', 'colOrder', 'aggregatorName', 'rendererName', 'valueFilter', 'vals');
    prompt('Copy this text "Ctrl+C on windows', JSON.stringify(state, null, 2));
  };

  const downloadXlsx = () => {
    const pivotData = new PivotData({
      ...this.state.pivot,
      data: this.pivotTable.current.data
    });

    var rowKeys = pivotData.getRowKeys();
    var colKeys = pivotData.getColKeys();

    if (rowKeys.length === 0) {
      rowKeys.push([]);
    }

    if (colKeys.length === 0) {
      colKeys.push([]);
    }

    var headerRow = pivotData.props.rows.map(_ => _);

    if (colKeys.length === 1 && colKeys[0].length === 0) {
      headerRow.push(this.state.pivot.aggregatorName);
    } else {
      colKeys.map(function (c) {
        return headerRow.push(c.join('-'));
      });
    }

    var result = rowKeys.map(function (r) {
      var row = r.map(function (x) {
        return x;
      });
      colKeys.map(function (c) {
        var v = pivotData.getAggregator(r, c).value();
        row.push(v ? v : '');
      });
      return row;
    });

    result.unshift(headerRow);

    writeXlsx(
      result.map((row, i) => {
        return row.map((cell) => ({
          value: cell || undefined,
          fontWeight: i === 0 ? 'bold' : undefined
        }));
      }),
      {
        fileName: 'analytics.xlsx'
      }
    );
  };

  const useSavedReport = (selectedReport) => {
    setSelectedReport(selectedReport);
    setPivot(savedReports[selectedReport]);
  };

  return (
    <section>
      <Select
        label="Saved Reports"
        value={selectedReport}
        onChange={useSavedReport}
        options={Object.keys(savedReports).map(_ => ({ value: _, label: _ }))} />

      <Button onClick={clear}>
        Clear
      </Button>

      <Button onClick={alert}>
        Give this to Osei
      </Button>

      {pivotTable.current && pivotTable.current.data && (
        <Button onClick={downloadXlsx}>
          Download Excel
        </Button>
      )}

      <div style={{ position: 'relative', zIndex: '0' }}>
        <PivotTableUI
          ref={pivotTable}
          data={analyticsData}
          onChange={setPivot}
          renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          {...pivot}
        />
      </div>
    </section>
  );
}

Analytics.meta = () => ({
  title: 'Data Analysis'
});

Analytics.load = async () => {
  await fetchAnalyticsData();
};

Analytics.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Analytics'
];
