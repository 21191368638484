import React from 'react';
import type { FC } from 'react';

import { Col, Row, Text } from '@acadeum/ui';

import styles from './SectionDate.module.scss';

interface SectionDateProps {
  children?: React.ReactNode;
  title: string;
}

const SectionDate: FC<SectionDateProps> = ({ children, title }) => {
  return (
    <Row className={styles.SectionDate}>
      <Col system={24} col={7} xs={24}>
        <Text color="black">
          {title}
        </Text>
      </Col>
      <Col
        system={24}
        col={title ? 17 : 24}
        xs={24}
      >
        {children ? children : '-'}
      </Col>
    </Row>
  );
};
export default SectionDate;
