import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Title } from '@acadeum/ui';

import DownArrow from './DownArrowIcon.svg';

import './Collapsible.sass';

export default class Collapsible extends Component {
  state = {
    isOpen: true
  };

  constructor(props) {
    super(props);
    if (props.isOpen !== undefined) {
      this.state.isOpen = props.isOpen;
    } else if (props.collapsed !== undefined) {
      this.state.isOpen = !props.collapsed;
    }
  }

  toggle = () => {
    const expand = !this.state.isOpen;
    const { onToggle } = this.props;
    if (onToggle) {
      onToggle(expand);
    }
    this.setState({ isOpen: expand });
  };

  render() {
    const {
      title,
      children,
      toggle,
      className,
      scroll,
      overflow
    } = this.props;

    // If `toggle` is specified,
    // then `isOpen` is fully controlled
    // by the external `isOpen` prop
    let { isOpen } = this.state;
    if (toggle) {
      isOpen = this.props.isOpen;
    }

    return (
      <section
        className={classNames(className, 'collapsible', {
          'collapsible--open': isOpen,
          'collapsible--overflow-visible': overflow === 'visible'
        })}>
        <header style={HEADING_STYLE}>
          <Title level={2} className="collapsible__titleHeading">
            <button
              type="button"
              onClick={toggle || this.toggle}
              aria-expanded={isOpen}
              className="rrui__button-reset collapsible__title">
              {title}
              <DownArrow
                aria-hidden
                className={classNames(
                  'collapsible__arrow',
                  'collapsible__arrow--default', {
                    'collapsible__arrow--open': isOpen
                  }
                )}/>
              <DownArrowTriangle
                aria-hidden
                className={classNames(
                  'collapsible__arrow',
                  'collapsible__arrow--triangle', {
                    'collapsible__arrow--open': isOpen
                  }
                )}/>
            </button>
          </Title>
        </header>
        {/* `pre-wrap` allows newline symbols to be rendered */}
        <div className="collapsible__details-background" aria-hidden={!isOpen}>
          <div
            className={classNames('collapsible__details', {
              // An explicit `.collapsible__details--open` CSS class
              // is to support <Collapsible/>s inside <Collapsible/>s.
              'collapsible__details--open': isOpen,
              'collapsible__details--no-scroll': scroll === false
            })}>
            {typeof children === 'function' ? children({ isExpanded: isOpen }) : children}
          </div>
        </div>
      </section>
    );
  }
}

Collapsible.propTypes = {
  collapsed: PropTypes.bool,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  toggle: PropTypes.func,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func
  ]).isRequired,
  // style: PropTypes.object,
  className: PropTypes.string,
  scroll: PropTypes.bool,
  overflow: PropTypes.string
};

export function Collapsibles({ className, children }) {
  if (!children) {
    return null;
  }
  return (
    <div className={classNames('collapsibles', className)}>
      {children}
    </div>
  );
}

Collapsibles.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

const HEADING_STYLE = {
  margin: 0
};

const DownArrowTriangle = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="8" height="4">
    <path fill="currentColor" d="M4 4L0 0h8z"/>
  </svg>
);
