import React from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

import type { ButtonProps } from '../Button';
import { Button } from '../Button';

export type FormResetProps = ButtonProps

export const FormReset: React.FC<FormResetProps> = ({
  children,
  disabled,
  onClick: propOnClick,
  ...rest
}) => {
  const { reset } = useFormContext();
  const { isSubmitting } = useFormState();

  const onClick = (event) => {
    reset();
    if (typeof propOnClick === 'function') {
      propOnClick(event);
    }
  };

  return (
    <Button
      type="reset"
      {...rest}
      disabled={isSubmitting || disabled}
      onClick={onClick}
    >
      {children || 'Submit'}
    </Button>
  );
};
