import React from 'react';
import classNames from 'classnames';

import { Link } from '@acadeum/ui';

import styles from './Link.module.scss';

const Link_ = React.forwardRef(({
  // Exclude `instantBack` from `...rest`.
  // eslint-disable-next-line no-unused-vars
  instantBack,
  className,
  monochrome,
  ...rest
}, ref) => {
  return (
    <Link
      {...rest}
      ref={ref}
      className={classNames(className, {
        [styles.monochrome]: monochrome
      })}
    />
  );
});

export default Link_;
