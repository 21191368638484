import React from 'react';

import { useTranslate } from '@acadeum/translate';

import ConfirmationModal from '../ConfirmationModal';

export default function RequestPasswordResetConfirmationModal (props) {
  const t = useTranslate('RequestPasswordReset');
  return (
    <ConfirmationModal
      {...props}
      title={t('confirmation.title')}
      text={t('confirmation.text')}
    />
  );
}
