import React, { memo } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { TreeView } from '@lexical/react/LexicalTreeView';

import styles from './TreeViewPlugin.module.scss';

export const TreeViewPlugin = memo(() => {
  const [editor] = useLexicalComposerContext();
  return (
    <TreeView
      treeTypeButtonClassName={''}
      viewClassName={styles.TreeViewPlugin}
      timeTravelPanelClassName={styles.timeTravelPanel}
      timeTravelButtonClassName={styles.timeTravelButton}
      timeTravelPanelSliderClassName={styles.timeTravelPanelSlider}
      timeTravelPanelButtonClassName={styles.timeTravelPanelButton}
      editor={editor}
    />
  );
});
