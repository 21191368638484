import React, { useState, useEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';

export function useSearchBarAndResultsPerPageAndSortByAndPagination ({
  fetchData,
  totalCount,
  sortingOptions,
  defaultSorting,
  pageSizes,
  defaultPageSize,
  onSearchQueryChange: onSearchQueryChange_,
  Pagination,
  SearchBarAndResultsPerPageAndSortBy,
  Filters,
  dispatch
}) {
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [sort, setSort] = useState(defaultSorting);
  const [searchQuery, setSearchQuery] = useState();
  const [filters, setFilters] = useState({});

  const [page, setPage] = useState(1);
  const pages = Math.ceil(totalCount / pageSize);

  const resultsPerPageOptions = useMemo(() => pageSizes.map((pageSize) => ({
    value: pageSize,
    label: String(pageSize)
  })), [pageSizes]);

  const [error, setError] = useState();

  const [loading, setLoading] = useState(false);
  const [hasLoadedInitially, setHasLoadedInitially] = useState(false);
  const isInitialLoad = useRef(true);

  const discardCurrentSearch = useRef<() => void>();

  const [cachedPageNumber, setCachedPageNumber] = useState<number>();
  const [cachedPageCount, setCachedPageCount] = useState<number>();

  const fetch = () => dispatch(fetchData({
    ...filters,
    search: searchQuery,
    page,
    pageSize,
    sort
  }));

  useEffect(() => {
    setLoading(true);
    setError(undefined);

    if (discardCurrentSearch.current) {
      discardCurrentSearch.current();
    }

    let discarded;
    discardCurrentSearch.current = () => {
      discardCurrentSearch.current = undefined;
      discarded = true;
    };

    fetch().then(
      () => {
        if (discarded) {
          return;
        }
        if (isInitialLoad.current) {
          isInitialLoad.current = false;
          setHasLoadedInitially(true);
        }
        setLoading(false);
        setCachedPageNumber(undefined);
        setCachedPageCount(undefined);
      },
      (error) => {
        if (discarded) {
          return;
        }
        setLoading(false);
        setCachedPageNumber(undefined);
        setCachedPageCount(undefined);
        setError(error);
        throw error;
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchQuery,
    page,
    pageSize,
    sort,
    filters
  ]);

  const resetPage = () => {
    setCachedPageNumber(page);
    setCachedPageCount(pages);
    setPage(1);
  };

  const onSearchQueryChange = (searchValue) => {
    if (searchValue) {
      if (onSearchQueryChange_) {
        onSearchQueryChange_(searchValue);
      }
    }
    // The current page is reset on search input value change.
    // Since `onSearchQueryChange()` is not called synchronously
    // on `<input/>` value change but rather waits for the user
    // to stop typing, it calls this function in a `setTimeout()`.
    // In that case, React doesn't "batch" state updates automatically.
    // https://medium.com/swlh/react-state-batch-update-b1b61bd28cd2
    ReactDOM.unstable_batchedUpdates(() => {
      resetPage();
      setSearchQuery(searchValue);
    });
  };

  const onResultsPerPageChange = (pageSize) => {
    resetPage();
    setPageSize(pageSize);
  };

  const onSortByChange = (sort) => {
    resetPage();
    setSort(sort);
  };

  const onPageChange = (page) => {
    setPage(page);
  };

  const displayedPageCount = cachedPageCount === undefined ? pages : cachedPageCount;
  let displayedPageNumber = cachedPageNumber === undefined ? page : cachedPageNumber;
  // Since `page` is maintained as a state variable in this component,
  // it's not necessarily consistent with the external `totalCount` property.
  // The `page` could be passed externally like `totalCount` is, but there
  // seems no need for that with this workaround.
  if (displayedPageNumber > displayedPageCount) {
    displayedPageNumber = displayedPageCount;
  }

  const renderPagination = (props) => (
    <Pagination
      {...props}
      page={displayedPageNumber}
      pageCount={displayedPageCount}
      setPage={onPageChange}
    />
  );

  const renderSearchBarAndResultsPerPageAndSortBy = () => (
    <SearchBarAndResultsPerPageAndSortBy
      searchQuery={searchQuery}
      onSearchQueryChange={onSearchQueryChange}
      resultsPerPageOptions={resultsPerPageOptions}
      resultsPerPage={pageSize}
      onResultsPerPageChange={onResultsPerPageChange}
      sortByOptions={sortingOptions}
      sortBy={sort}
      onSortByChange={onSortByChange}
    />
  );

  const renderFilters = (props) => (
    <Filters
      {...props}
      values={filters}
      onChange={setFilters}
    />
  );

  return {
    renderSearchBarAndResultsPerPageAndSortBy,
    renderPagination,
    renderFilters,
    loading,
    error,
    hasLoadedInitially,
    refresh: fetch
  };
}
