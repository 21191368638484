import React, { memo } from 'react';
import classNames from 'classnames';

import styles from './Swatch.module.scss';
import { BaseButton } from '../../../BaseButton';

export interface SwatchProps {
  color: string;
  onClick: (color: string) => void;
}

export const Swatch = memo<SwatchProps>(({
  onClick,
  color
}) => {
  return (
    <BaseButton
      style={{ background: color }}
      title={color}
      onClick={() => onClick(color)}
      className={classNames(styles.Swatch)}>
    </BaseButton>
  );
});
