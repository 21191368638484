import { useMemo } from 'react';

import { useTranslate } from '@acadeum/translate';

export const useExportDataColumns = () => {
  const t = useTranslate('EnrollmentRequests');

  return useMemo(() => [
    {
      id: 'ID',
      value: row => row.id
    },
    {
      id: 'TI Course ID',
      value: row => row.section.session.course.code
    },
    {
      id: 'TI Course Title',
      value: row => row.section.session.course.title
    },
    {
      id: 'Seats',
      value: row => row.seatsRequested
    },
    {
      id: 'Status',
      value: row => row.status
    },
    {
      id: 'Home Institution',
      value: row => row.institution.name
    },
    {
      id: 'Session',
      value: row => row.section.session.course.onDemand ? null : `${row.section.session.name} ${row.section.session.term}`
    },
    {
      id: 'Session Start Date',
      value: row => row.section.session.course.onDemand ? null : row.section.session.startDate
    },
    {
      id: 'Session Add Date',
      value: row => row.section.session.course.onDemand ? null : row.section.session.lastAddDate
    },
    {
      id: 'Session Drop Date',
      value: row => row.section.session.course.onDemand ? null : row.section.session.lastDropDate
    },
    {
      id: 'Session End Date',
      value: row => row.section.session.course.onDemand ? null : row.section.session.endDate
    },
    {
      id: 'Submitted At',
      value: row => row.createdAt
    },
    {
      id: 'Acadeum Student ID',
      value: row => row.enrollments.length > 0 ? row.enrollments[0].student.id : null
    },
    {
      id: 'HI Student ID',
      value: row => row.enrollments.length > 0 ? row.enrollments[0].student.hiStudentId : null
    },
    {
      id: 'First Name',
      value: row => row.enrollments.length > 0 ? row.enrollments[0].student.firstName : null
    },
    {
      id: 'Last Name',
      value: row => row.enrollments.length > 0 ? row.enrollments[0].student.lastName : null
    },
    {
      id: 'Email',
      value: row => row.enrollments.length > 0 ? row.enrollments[0].student.email : null
    }
  ], [t]);
};
