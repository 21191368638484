import type { FC } from 'react';
import React from 'react';

import type { MenuSectionProps} from './MenuSection';
import { MenuSection } from './MenuSection';

import styles from './MenuSections.module.scss';

export interface MenuSectionsProps {
  sections: MenuSectionProps[];
}

export const MenuSections: FC<MenuSectionsProps> = ({ sections }) => {
  return (
    <div className={styles.root}>
      {sections.map((section, i) => (
        <MenuSection key={i} {...section}/>
      ))}
    </div>
  );
};
