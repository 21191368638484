import { loadEnrollmentsPage } from '../../helpers/routes';

import Enrollments from '../Enrollments';

const EnrollmentsTeachingFinalized = () => {
  return (
    <Enrollments type="teaching" status="finalized"/>
  );
};

EnrollmentsTeachingFinalized.meta = () => ({
  title: 'Finalized Students'
});

EnrollmentsTeachingFinalized.load = async ({ user }) => {
  await loadEnrollmentsPage({ user });
};

EnrollmentsTeachingFinalized.breadcrumbs = () => [
  ['Manage Enrollments', '/enrollments'],
  ['Teaching Enrollments', '/enrollments/teaching'],
  'Finalized'
];

export default EnrollmentsTeachingFinalized;
