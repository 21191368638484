import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TextInput } from 'react-responsive-ui';

export default class PhoneExtensionInput extends React.Component {
  onChange = (value) => {
    const { onChange } = this.props;
    value = value.split('').filter(_ => _ >= '0' && _ <= '9').join('');
    if (!value) {
      value = undefined;
    }
    onChange(value);
  };

  render() {
    const { className } = this.props;
    return (
      <TextInput
        {...this.props}
        onChange={this.onChange}
        className={classNames(className, 'rrui-input-type-number--no-arrows')}/>
    );
  }
}

PhoneExtensionInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string
};
