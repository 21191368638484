import React, { memo, useState } from 'react';
import { capitalize } from 'lodash';

import formatName from 'common-lib/lib/formatName';

import { useTranslate } from '@acadeum/translate';
import type { ModalProps } from '@acadeum/ui';
import {
  Blank,
  Card,
  Col,
  DataBlock,
  Email,
  HStack,
  Link,
  CircularProgress,
  Modal,
  Phone,
  Row,
  Separator,
  ShowMoreButton,
  Text,
  VStack,
  Icon
} from '@acadeum/ui';
import { ProfileIcon, BriefcaseIcon, EnvelopeIcon, PhoneIcon } from '@acadeum/icons';

import { useFetchEnrollingGradeQuery } from '../../../../api/grades';
import getInstitutionLink from '../../../../helpers/getInstitutionLink';

interface EnrollingGradeModalProps extends Pick<ModalProps, 'onHide' | 'show'> {
  gradeId: number;
  downloadRow: () => Promise<void>;
}

export const EnrollingGradeModal = memo<EnrollingGradeModalProps>(({
  show,
  onHide,
  gradeId,
  downloadRow
}) => {
  const t = useTranslate('EnrollingGradeModal');
  return (
    <Modal
      show={show}
      onHide={onHide}
      title={t('Grade Details')}
      actions={[{ title: t('Download'), onClick: downloadRow }]}
    >
      <Modal.Body>
        <GradeDetails gradeId={gradeId}/>
      </Modal.Body>
    </Modal>
  );
});

const GradeDetails = ({
  gradeId
}) => {
  const t = useTranslate('EnrollingGradeModal');

  const [expandedDetails, setExpandedDetails] = useState(false);

  const { data, isLoading } = useFetchEnrollingGradeQuery(gradeId);

  if (!data) {
    if (isLoading) {
      return (
        <HStack justify="center" style={{ height: '20rem' }}>
          <CircularProgress/>
        </HStack>
      );
    }
    return null;
  }

  return (
    <>
      <Card mb="md">
        <VStack gap="md" align="none" mb="sm">
          <HStack justify="between">
            <Text variant="subtitle2">
              {t('Grade')}
            </Text>
            {data.section.session.course.institution.gradingScale ? (
              <Link
                external
                suffixIcon="external"
                to={`${getInstitutionLink(data.section.session.course.institution)}/?initialActiveTab=gradingScale`}
              >
                {t('View Grading Scale')}
              </Link>
            ) : (
              <Text color="secondary">
                {t('No grading scale available')}
              </Text>
            )}
          </HStack>
          {[
            { title: t('Letter Grade'), value: data.letterGrade },
            { title: t('Numerical Grade'), value: data.numericalGrade },
            { title: t('Note'), value: data.gradeNotes }
          ].map(({ title, value }) => (
            <Row key={title}>
              <Col col={5}>{title}</Col>
              <Col col={7}>{value || <Blank/>}</Col>
            </Row>
          ))}
        </VStack>
        <ShowMoreButton
          expanded={expandedDetails}
          onClick={setExpandedDetails}
        />
        {expandedDetails && (
          <>
            <Separator/>
            <VStack gap="md" align="none">
              <Text variant="subtitle2">
                {t('Details')}
              </Text>
              {[
                {
                  title: t('Student'),
                  value: (
                    <DataBlock
                      type="student"
                      student={data.student}
                    />
                  )
                },
                {
                  title: t('Home Institution'),
                  value: (
                    <DataBlock
                      type="institution"
                      hideLogo
                      institution={data.student.institution}
                    />
                  )
                },
                {
                  title: t('Enrollment Type'),
                  value: capitalize(data.type)
                },
                {
                  title: t('Course'),
                  value: (
                    <DataBlock
                      type="course"
                      course={data.section.session.course}
                    />
                  )
                },
                {
                  title: t('Course Dates'),
                  value: (
                    <DataBlock
                      type="courseSessionDates"
                      session={data.section.session}
                    />
                  )
                }
              ].map(({ value, title }) => (
                <Row key={title}>
                  <Col col={5}>{title}</Col>
                  <Col col={7}>{value || <Blank/>}</Col>
                </Row>
              ))}
            </VStack>
          </>
        )}
      </Card>
      <Card variant="gray">
        <VStack gap="md" align="none">
          <Text variant="subtitle2">
            {t('Teaching Student Contact')}
          </Text>
          {[
            { icon: ProfileIcon, title: t('Contact'), value: formatName(data.studentContact) },
            { icon: BriefcaseIcon, title: t('Title'), value: data.studentContact.title },
            { icon: EnvelopeIcon, title: t('Email'), value: <Email address={data.studentContact.email}/> },
            { icon: PhoneIcon, title: t('Phone'), value: <Phone contact={data.studentContact}/> }
          ].map(({ title, value, icon }) => (
            <Row key={title}>
              <Col col={5}>
                <HStack>
                  <Icon icon={icon} mr="sm" size="md" style={{ color: 'var(--color-gray-400)' }}/>
                  {title}
                </HStack>
              </Col>
              <Col col={7}>{value || <Blank/>}</Col>
            </Row>
          ))}
        </VStack>
      </Card>
    </>
  );
};
