import type { FC, HTMLAttributes } from 'react';
import React from 'react';
import classNames from 'classnames';

import styles from './Card.module.scss';

export type CardProps = HTMLAttributes<HTMLDivElement>

const Card: FC<CardProps> = ({ children, className, ...rest }) => {
  return (
    <div {...rest} className={classNames(styles.card, className)}>
      {children}
    </div>
  );
};

export default Card;
