import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';

import { useFirstMountState } from './useFirstMountState';

interface HistoryState<S> {
  history: S[];
  position: number;
  capacity: number;
  back?: (amount?: number) => void;
  forward?: (amount?: number) => void;
  go: (position: number) => void;
}

type UseStateHistoryReturn<S> = [S, Dispatch<SetStateAction<S>>, HistoryState<S>]

interface UseStateHistoryOptions<S, I> {
  initialHistory?: I[];
  capacity?: number;
  onNavigate?: (value: S) => void;
}

export function useStateHistory<S, I extends S>(
  initialState?: S,
  {
    capacity = 10,
    onNavigate,
    initialHistory
  }: UseStateHistoryOptions<S, I> = {}
): UseStateHistoryReturn<S> {
  if (capacity < 1) {
    throw new Error(`Capacity has to be greater than 1, got '${capacity}'`);
  }

  const isFirstMount = useFirstMountState();
  const [state, innerSetState] = useState<S>(initialState as S);
  const history = useRef<S[]>((initialHistory ?? []) as S[]);
  const historyPosition = useRef(0);

  // do the states manipulation only on first mount, no sense to load re-renders with useless calculations
  if (isFirstMount) {
    if (history.current.length) {
      // if last element of history !== initial - push initial to history
      if (history.current[history.current.length - 1] !== initialState) {
        history.current.push(initialState as I);
      }

      // if initial history bigger that capacity - crop the first elements out
      if (history.current.length > capacity) {
        history.current = history.current.slice(history.current.length - capacity);
      }
    } else {
      // initiate the history with initial state
      history.current.push(initialState as I);
    }

    historyPosition.current = history.current.length && history.current.length - 1;
  }

  const setState: Dispatch<SetStateAction<S>> = useCallback((newState) => {
    innerSetState((currentState) => {
      newState = newState instanceof Function ? newState(currentState) : newState;

      // is state has changed
      if (newState !== currentState) {
        // if current position is not the last - pop element to the right
        if (historyPosition.current < history.current.length - 1) {
          history.current = history.current.slice(0, historyPosition.current + 1);
        }

        historyPosition.current = history.current.push(newState as I) - 1;

        // if capacity is reached - shift first elements
        if (history.current.length > capacity) {
          history.current = history.current.slice(history.current.length - capacity);
        }
      }

      return newState;
    });
  }, [state, capacity]);

  const historyState: HistoryState<S> = useMemo(() => ({
    history: history.current,
    position: historyPosition.current,
    capacity,
    back: !historyPosition.current ? undefined : (amount_?: number) => {
      const amount = typeof amount_ === 'number' ? amount_ : 1;

      // don't do anything if we already at the left border
      if (!historyPosition.current) {
        return;
      }

      innerSetState(() => {
        historyPosition.current -= Math.min(amount, historyPosition.current);
        const value = history.current[historyPosition.current];
        onNavigate?.(value);
        return value;
      });
    },
    forward: historyPosition.current === history.current.length - 1 ? undefined : (amount_?: number) => {
      const amount = typeof amount_ === 'number' ? amount_ : 1;

      // don't do anything if we already at the right border
      if (historyPosition.current === history.current.length - 1) {
        return;
      }

      innerSetState(() => {
        historyPosition.current = Math.min(
          historyPosition.current + amount,
          history.current.length - 1
        );

        const value = history.current[historyPosition.current];
        onNavigate?.(value);
        return value;
      });
    },
    go: (position: number) => {
      if (position === historyPosition.current) {
        return;
      }

      innerSetState(() => {
        historyPosition.current =
          position < 0
            ? Math.max(history.current.length + position, 0)
            : Math.min(history.current.length - 1, position);

        const value = history.current[historyPosition.current];
        onNavigate?.(value);
        return value;
      });
    }
  }), [state, onNavigate]);

  return [state, setState, historyState];
}
