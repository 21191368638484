import React, { useCallback, useEffect, useMemo } from 'react';

export interface FilterOption<Value = any> {
  name: string;
  // When the filter value is null, it means the filter should be removed
  value: Value | null;
  label?: string;
  getFilterLabel: (value: Value, item: FilterOption<Value>) => string;
  shouldShow?: (item: FilterOption<Value>) => boolean;
  [key: string]: any;
}

export interface ActiveFilterOptions<Value = any> {
  [key: string]: FilterOption<Value>;
}

export interface BaseFilterProps<Value = any> {
  name: string;
  label: string;
  getFilterLabel?: (value: Value, item: FilterOption<Value>) => string;
}

interface FiltersContextProps {
  activeFilters: ActiveFilterOptions;
  registerFilter: (filter: FilterOption) => void;
  unregisterFilter: (filter: FilterOption) => void;
  onFilterChange: (filter: FilterOption, options?: object) => void;
  clearAll: () => void;
  removeFilter: (filter: FilterOption, value?: FilterOption['value']) => void;
}

const FiltersContext = React.createContext<FiltersContextProps | undefined>(undefined);

export function useFiltersContext() {
  const context = React.useContext(FiltersContext);
  if (!context) {
    throw new Error('useFiltersContext must be used within a <Filters> component');
  }
  return context;
}

interface FiltersProviderProps {
  children?: React.ReactNode;
  values: object;
  onFiltersChange: (values: object) => void;
}

export const FiltersProvider: React.FC<FiltersProviderProps> = ({
  onFiltersChange,
  values,
  children
}) => {
  // Store available filters when the component is mounted
  const [availableFilters, setAvailableFilters] = React.useState<FilterOption[]>([]);

  const registerFilter = useCallback((filter: FilterOption) => {
    setAvailableFilters((prev) => [...prev, filter]);
  }, []);

  const unregisterFilter = useCallback((filter: FilterOption) => {
    setAvailableFilters((prev) => prev.filter((item) => item.name !== filter.name));
  }, []);

  const onFilterChange = useCallback((filter: FilterOption,  options?: object) => {
    const newValues = { ...values };
    if (filter.value === null) {
      // Remove value from values object if it exists
      delete newValues[filter.name];
    } else {
      newValues[filter.name] = filter.value;
    }
    onFiltersChange(newValues);
    setAvailableFilters((prev) => prev.map((item) => {
      if (item.name === filter.name) {
        return {
          ...item,
          ...options,
          value: filter.value
        };
      }
      return item;
    }));
  }, [
    values,
    onFiltersChange,
    setAvailableFilters,
    availableFilters
  ]);

  const clearAll = useCallback(() => {
    onFiltersChange({});
  }, [onFiltersChange]);

  const removeFilter = useCallback((filter: FilterOption, value) => {
    let updatedValue;

    if (Array.isArray(filter.value)) {
      updatedValue = filter.value.filter(_ => _ !== value);
    } else {
      updatedValue = null;
    }

    onFilterChange({
      ...filter,
      value: updatedValue
    });
  }, [onFilterChange]);

  const activeFilters = useMemo<ActiveFilterOptions>(() => {
    return Object.keys(values).reduce((result, key) => {
      const filter = availableFilters.find((item) => item.name === key);
      const shouldShow = filter?.shouldShow ? filter.shouldShow(filter) : true;
      if (filter) {
        result[key] = {
          ...filter,
          shouldShow,
          value: values[key]
        };
      }
      return result;
    }, {});
  }, [values, availableFilters]);

  return (
    <FiltersContext.Provider value={{
      registerFilter,
      unregisterFilter,
      onFilterChange,
      activeFilters,
      removeFilter,
      clearAll
    }}>
      {children}
    </FiltersContext.Provider>
  );
};

interface UseRegisterFilterProps extends BaseFilterProps {
  propsOnChange?: (value: any) => any;
  shouldShow?: (item: FilterOption<any>) => boolean;
}

export function useRegisterFilter({
  name,
  label,
  getFilterLabel = (value) => String(value),
  propsOnChange,
  shouldShow
}: UseRegisterFilterProps) {
  const { registerFilter, unregisterFilter, activeFilters, onFilterChange } = useFiltersContext();

  useEffect(() => {
    const option = { label, name, value: null, getFilterLabel, shouldShow };
    registerFilter(option);
    return () => {
      unregisterFilter(option);
    };
  }, [
    registerFilter,
    unregisterFilter,
    name,
    label
  ]);

  const onChange = useCallback((v: any, additionalOptions?: object) => {
    const value = propsOnChange ? propsOnChange(v) : v;
    onFilterChange({ getFilterLabel, name, value }, additionalOptions);
  }, [
    name,
    onFilterChange,
    propsOnChange,
    getFilterLabel
  ]);

  return {
    option: activeFilters[name],
    onChange
  };
}
