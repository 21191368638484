import React from 'react';
import { bindActionCreators } from 'redux';
import { goBack, goBackTwoPages, goto, redirect } from 'react-pages';
import pickBy from 'lodash/pickBy';
import isFunction from 'lodash/isFunction';

import { userHasPermission } from '@acadeum/helpers';

import NotifyErrorModal from './components/NotifyErrorModal';

import throttleRefreshAction from './helpers/throttleRefreshAction';

import * as actionCreators from './actions/index.js';

import { getStore } from './store.js';

let actions = pickBy(actionCreators, isFunction);

actions.goto = goto;
actions.redirect = redirect;
actions.goBack = goBack;
actions.goBackTwoPages = goBackTwoPages;

actions = bindActionCreators(actions, action => getStore().dispatch(action));

// Periodically refreshes `courseEnrollmentPricing`.
actions.refreshCourseEnrollmentPricingIfRequired = throttleRefreshAction({
  fetchValue: async () => {
    const user = getStore().getState().auth.user;
    if (user && userHasPermission(user, 'homeInstitutionCourseEnrollmentPricing:read', {
      orgId: user.institution.id
    })) {
      return await actions.fetchCourseEnrollmentPricing();
    }
  },
  setValue: actions.setCourseEnrollmentPricing,
  // Refresh course pricing at most once in 5 minutes.
  cacheTimeToLive: 5 * 60 * 1000
});

actions.notifyWarn = (content) => {
  return actions.notify(content, { type: 'warning' });
};

actions.notifyError = (content) => {
  return actions.notify(
    <NotifyErrorModal>
      {content}
    </NotifyErrorModal>
  );
};

export default actions;
