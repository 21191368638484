import {
  getMessagesCached,
  getMessagesWithFallback
} from '@acadeum/translate';

import { DEFAULT_LANGUAGE } from './language';

import EN from './locales/en/index';
// import ES from './locales/es/index';

export default function getMessages(language: string) {
  return getMessagesCached(
    language,
    (language: string) => getMessagesWithFallback(language, DEFAULT_LANGUAGE, getMessageLabels),
    'upload'
  );
}

function getMessageLabels(language: string) {
  switch (language) {
    // case 'es':
    //   return ES;
    default:
      return EN;
  }
}
