import { useContext } from 'react';

import {
  ComboboxListboxContext,
  ComboboxTextFieldContext,
  ComboboxToggleContext,
  ComboboxListboxOptionContext
} from './context';

export function useComboboxTextField() {
  const context = useContext(ComboboxTextFieldContext);
  if (!context) {
    throw new Error('No Combobox was provided. Your component must be wrapped in a <Combobox> component.');
  }
  return context;
}

export function useComboboxToggle() {
  const context = useContext(ComboboxToggleContext);
  if (!context) {
    throw new Error('No Combobox was provided. Your component must be wrapped in a <Combobox> component.');
  }
  return context;
}

export function useComboboxListbox() {
  return useContext(ComboboxListboxContext);
}

export function useComboboxOption() {
  return useContext(ComboboxListboxOptionContext);
}
