import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const createBackupPaymentSource = redux.action(
  ({ sourceId }) => (http) => http.post('/payment-sources/backup', { sourceId }),
  (state, creditCardInfo) => ({
    ...state,
    creditCardInfo
  })
);

export const readBackupPaymentSource = redux.action(
  () => (http) => http.get('/payment-sources/backup'),
  (state, creditCardInfo) => ({
    ...state,
    creditCardInfo
  })
);

export const updateBackupPaymentSource = redux.action(
  ({ sourceId }) => (http) => http.put('/payment-sources/backup', { sourceId }),
  (state, creditCardInfo) => ({
    ...state,
    creditCardInfo
  })
);

export const deleteBackupPaymentSource = redux.action(
  () => (http) => http.delete('/payment-sources/backup'),
  (state) => ({
    ...state,
    creditCardInfo: null
  })
);

export const createPrimaryPaymentSource = redux.action(
  ({ sourceId }) => (http) => http.post('/payment-sources/primary', { sourceId }),
  (state, bankAccountInfo) => ({
    ...state,
    bankAccounts: {
      ...state.bankAccounts,
      bankAccountInfo
    }
  })
);

export const readPrimaryPaymentSource = redux.action(
  () => (http) => http.get('/payment-sources/primary'),
  (state, bankAccounts) => {
    const bankAccountInfo = bankAccounts && bankAccounts.current;
    const newBankAccountInfo = bankAccounts && bankAccounts.new;
    return {
      ...state,
      bankAccounts: {
        bankAccountInfo,
        newBankAccountInfo
      }
    };
  }
);

export const updatePrimaryPaymentSource = redux.action(
  ({ sourceId }) => (http) => http.put('/payment-sources/primary', { sourceId }),
  (state, newBankAccountInfo) => ({
    ...state,
    bankAccounts: {
      ...state.bankAccounts,
      newBankAccountInfo
    }
  })
);

export const verifyPrimaryPaymentSource = redux.action(
  ({ amounts, type }) => (http) => http.post('/payment-sources/primary/verify', { amounts, type })
);

export default redux.reducer({
  creditCardFInfo: null,
  bankAccounts: {
    bankAccountInfo: null,
    newBankAccountInfo: null
  }
});
