import React from 'react';
import { useSelector } from 'react-redux';

import Action from '../../components/Action';
import InstitutionSearch from '../../components/InstitutionSearch';
import FormHeading from '../../components/FormHeading';
import WithPublicMarketingPageBanner from '../../components/WithPublicMarketingPageBanner';

import isAcadeumAdministrator from '../../helpers/isAcadeumAdministrator';

function Institutions() {
  const { user } = useSelector(state => state.auth);

  return (
    <section>
      {user && isAcadeumAdministrator(user) &&
        <div className="instant-search-actions">
          <Action
            icon="add"
            iconSize="l"
            link="/institutions/add">
            Add Institution
          </Action>
        </div>
      }

      <FormHeading>
        Institutions
      </FormHeading>

      <InstitutionSearch />
    </section>
  );
}

Institutions.meta = () => ({
  title: 'Institutions'
});

Institutions.breadcrumbs = () => [
  'Institutions'
];

export default WithPublicMarketingPageBanner(Institutions, { margin: true });
