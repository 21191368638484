import { loadEnrollmentsPage } from '../../helpers/routes';
import Enrollments from '../Enrollments';

const EnrollmentsLive = () => {
  return (
    <Enrollments type="home" status="live"/>
  );
};

EnrollmentsLive.meta = () => ({
  title: 'Live Enrollments'
});

EnrollmentsLive.load = async ({ user }) => {
  await loadEnrollmentsPage({ user });
};

EnrollmentsLive.breadcrumbs = () => [
  ['Manage Enrollments', '/enrollments'],
  'Live'
];
export default EnrollmentsLive;
