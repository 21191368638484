import type { AuthState } from './state';

interface State {
  auth: AuthState;
}

export function getAuthSelector<Key extends keyof AuthState = keyof AuthState>(key: Key): (state: State) => AuthState[Key];
export function getAuthSelector(state: State): AuthState;

export function getAuthSelector(keyOrState) {
  if (typeof keyOrState === 'object') {
    return keyOrState.auth;
  }
  return (state: State) => state.auth[keyOrState];
}

/**
 * @deprecated Use `getAuthSelector('user')` instead.
 * */
export const getUserSelector = getAuthSelector('user');
