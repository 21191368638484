import { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { Avatar } from '@acadeum/core-ui';
import { useTranslate } from '@acadeum/translate';
import { CheckIcon } from '@acadeum/icons';
import type { Institution } from '@acadeum/types';

import { useGetInstitutionInputParams } from '../../utils/institutionAutocomplete';

import { Icon } from '../Icon';
import { Modal } from '../Modal';
import type { InstitutionAutocompleteProps } from '../InstitutionAutocomplete';
import { Button } from '../Button';
import { SearchBar } from '../SearchBar';
import type { ModalProps } from '../Modal';
import { useFilteredOptions } from '../../utils/useFilteredOptions';

import styles from './ChangeInstitutionModal.module.scss';

export type ChangeInstitutionModalProps = Pick<InstitutionAutocompleteProps, 'type' | 'superAdmin'> & Pick<ModalProps, 'show' | 'onHide'> & {
  onSubmit: ({ institutionId }: { institutionId: number }) => void
};

type ChangeInstitutionModalProps_ = Omit<ChangeInstitutionModalProps, 'type' | 'superAdmin'> & {
  options: {
    label: Institution['name'];
    value: Institution['id'];
    logoUrl?: Institution['logoUrl'];
  }[];
  fetchOptions: (value: string | number) => void;
  isLoading?: boolean;
}

function useSelectTextField({ fetchOptions }) {
  const [inputValue, setInputValue] = useState('');

  const updateText = useCallback(async (value) => {
    setInputValue(value);
    await fetchOptions(value);
  }, [fetchOptions]);

  return { inputValue, updateText };
}

const ChangeInstitutionModal_: FC<ChangeInstitutionModalProps_> = ({
  show,
  onHide,
  onSubmit: onSubmit_,
  options,
  fetchOptions,
  isLoading
}) => {
  const t = useTranslate('ui.ChangeInstitutionModal');

  const { inputValue, updateText } = useSelectTextField({ fetchOptions });
  const [institution, setInstitution] = useState<number | undefined>();
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>();

  const onSubmit = async () => {
    setLoadingSubmit(true);
    if (institution) {
      await onSubmit_({ institutionId: institution });
    }
    setLoadingSubmit(false);
  };

  const onChangeRadio = useCallback((value) => {
    setInstitution(Number(value));
  }, []);

  useEffect(() => {
    setInstitution(undefined);
  }, [inputValue]);

  return (
    <Modal
      title={t('title')}
      onHide={onHide}
      show={show}
      subtitle={(
        <SearchBar
          className={styles.search}
          onChange={updateText}
          value={inputValue}
          placeholder="Search institution"
        />
      )}
    >
      <Modal.Body>
        <RadioGroupPrimitive.Root value={String(institution)} onValueChange={onChangeRadio} className={styles.wrapperRadio}>
          {isLoading ? (
            'Loading...'
          ) :
            options.length > 0 ?
              options.map(institution => (
                <RadioGroupPrimitive.Item key={institution.value} className={styles.radio} value={String(institution.value)}>
                  <Avatar type="institution" url={institution.logoUrl} />
                  {institution.label}
                  <RadioGroupPrimitive.Indicator className={styles.radio__checked}>
                    <Icon icon={CheckIcon} />
                  </RadioGroupPrimitive.Indicator>
                </RadioGroupPrimitive.Item>
              ))
              : (<>
                {getNotFoundLabel({
                  notFoundLabel: t('notFound'),
                  noInputLabel: t('noInput'),
                  inputValue: inputValue
                })}</>)
          }
        </RadioGroupPrimitive.Root>
      </Modal.Body>
      <Modal.Footer className={styles.footer}>
        <Button
          className={styles.footer__btn}
          variant="secondary"
        >
          Cancel
        </Button>
        <Button
          loading={loadingSubmit}
          onClick={onSubmit}
          disabled={!institution}
        >
          Change
        </Button>
      </Modal.Footer>
    </Modal >
  );
};

const getNotFoundLabel = ({
  notFoundLabel,
  noInputLabel,
  inputValue
}) => {
  if (!inputValue) {
    return noInputLabel;
  }
  return notFoundLabel;
};

export const ChangeInstitutionModal: FC<ChangeInstitutionModalProps> = ({ type, superAdmin, ...rest }) => {
  const getInstitutionInputParams = useGetInstitutionInputParams();
  if (!getInstitutionInputParams) {
    throw new Error('getInstitutionInputParams is not defined');
  }

  const institutionInputParams = getInstitutionInputParams({ type, superAdmin, limit: 1000, variant: 'valueAndLabelAndLogoUrl' });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const optionsProps = useFilteredOptions({ async: institutionInputParams.async, findOptionByValue: institutionInputParams.findOptionByValue, fetchOptions: institutionInputParams.fetchOptions, options: [], isLoading: false });

  return (
    <ChangeInstitutionModal_ {...optionsProps} {...rest} />
  );
};
