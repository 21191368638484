import config from 'config';

export default function getAuthCookieConfig() {
  // Cookies aren't used on localhost.
  if (window.location.hostname === 'localhost') {
    return;
  }
  return {
    prefix: config.auth.cookie.prefix,
    domain: config.auth.cookie.domain
  };
}
