import { injectUserAccountRequestsEndpoints } from '@acadeum/api';

import { rtkApi } from './rtkApi';

export const {
  useCreateUserAccountRequestMutation,
  useFetchUserAccountRequestsQuery,
  useApproveUserAccountRequestsMutation,
  useDenyUserAccountRequestsMutation
} = injectUserAccountRequestsEndpoints(rtkApi);
