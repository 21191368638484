import React from 'react';

import { useTranslate } from '@acadeum/translate';

import Button from '../Button';

import './RequestCourseBanner.sass';

export default function RequestCourseBanner() {
  const t = useTranslate('RequestCourseBanner');

  return (
    <aside className="RequestCourseBanner">
      {/* This tag has been changed from `h2` to `p` because of accessibility requirements:
        https://github.com/Acadeum/Tickets/issues/1148 */}
      <p className="RequestCourseBanner__title">
        {t('title')}
      </p>
      <Button
        variant="dark"
        link="/courses/request"
        className="RequestCourseBanner__action">
        {t('button')}
      </Button>
    </aside>
  );
}
