import formatRace, { RACES } from 'common-lib/lib/formatRace.js';

import type { Language } from '@acadeum/types';

export function getRaceOptions({ language, own }: { language: Language; own?: boolean }) {
  return RACES.concat('-').map((race) => ({
    value: race,
    label: formatRace(race, { language, input: true, own })
  }));
}
