import React, { memo } from 'react';
import classNames from 'classnames';

import styles from './Divider.module.scss';

export interface DividerProps {
  className?: string;
}

export const Divider = memo<DividerProps>(({
  className
}) => {
  return (
    <div className={classNames(className, styles.Divider)}/>
  );
});
