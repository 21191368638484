import getPriceForCostUsingPricingVariant from './getPriceForCostUsingPricingVariant.js';

/**
 * Calculates lowest price for the original cost using a set of possible "pricing variants".
 * Returns the lowest price and the pricing variant that was used to calculate it.
 * @param {number} cost
 * @param {object[]} pricingVariants
 * @param {object} [parameters] — A object having parameters for pricing variants. Example for course cost: `{ credits: number }`.
 * @param {string} [options.roundTo] — One of: "integer", "hundredths".
 * @return {object} [price] An object of shape `{ price, pricingVariant }`.
 */
export default function getLowestPriceAndPricingVariantForCostUsingPricingVariants(
  cost,
  pricingVariants,
  parameters,
  { roundTo } = {}
) {
  // Validate `cost` argument.
  if (typeof cost !== 'number') {
    throw new Error('`cost` is required');
  }

  // Get a list of all possible prices.
  const pricesAndPricingVariants = pricingVariants.map((pricingVariant) => ({
    pricingVariant,
    price: getPriceForCostUsingPricingVariant(cost, pricingVariant, parameters)
  })).filter(_ => _.price !== undefined);

  if (pricesAndPricingVariants.length > 0) {
    // Get the minimum price.
    const minPrice = Math.min(...pricesAndPricingVariants.map(_ => _.price));

    return {
      pricingVariant: pricesAndPricingVariants.find(_ => _.price === minPrice).pricingVariant,
      price: roundPrice(minPrice, roundTo)
    };
  }

  return {};
}

// Optionally rounds the price.
function roundPrice(price, roundTo) {
  switch (roundTo) {
    case 'integer':
      return Math.round(price);
    case 'hundredths':
      return Math.round(price * 100) / 100;
    default:
      if (roundTo) {
        throw new Error(`Unsupported \`roundTo\`: ${roundTo}`);
      }
      return price;
  }
}
