import type { FC } from 'react';

import { Tag, Tooltip } from '@acadeum/ui';

export const ApprovedBadge: FC  = ( ) => {
  return (
    <Tooltip content="Your institution has approved this course for use by your students">
      <Tag variant="success">
        Approved
      </Tag>
    </Tooltip>
  );
};
