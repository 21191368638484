import getFeeForCostUsingPricingVariant from './getFeeForCostUsingPricingVariant.js';

/**
 * Calculates the price for a cost using a pricing variant.
 * @param {number} cost
 * @param {object} pricing variant
 * @param {object} [parameters] — A object having parameters for pricing variants. Example for course cost: `{ credits: number }`.
 * @return {number} [cost]
 */
export default function getPriceForCostUsingPricingVariant(cost, pricing, { credits } = {}) {
  // Validate `cost` argument.
  if (typeof cost !== 'number') {
    throw new Error('`cost` is required');
  }

  switch (pricing.model) {
    case 'per-credit':
      // Validate `credits` parameter.
      if (typeof credits !== 'number') {
        throw new Error('`credits` parameter not provided for "per-credit" pricing');
      }
      return credits * pricing.value;
    case 'fixed':
      return pricing.value;
    case 'percent-fee':
    case 'fixed-fee':
    case 'as-is':
      return cost + getFeeForCostUsingPricingVariant(cost, pricing);
    default:
      throw new Error(`Unsupported pricing: ${JSON.stringify(pricing, null, 2)}`);
  }
}
