// For some weird reason, Stripe "sources" have different
// possible card `brand` values than "payment methods".
// For example, a Visa card "source" has `brand: "Visa"`
// and a Visa card "payment method" has `brand: "visa"`.
export function convertCardBrandNameToId(brand) {
  switch (brand) {
    case 'Visa':
      return 'visa';
    case 'MasterCard':
      return 'mastercard';
    case 'American Express':
      return 'amex';
    // Unconfirmed:
    case 'China UnionPay':
      return 'unionpay';
    case 'Discover':
      return 'discover';
    case 'Diners Club':
      return 'dinersclub';
    case 'Japan Credit Bureau':
      return 'jcb';
    default:
      return brand;
  }
}

export function formatCreditCardPreviewNumber(lastFourDigits, brand ) {
  if (brand === 'dinersclub') {
    return `**** ******* ${lastFourDigits}`;
  }

  if(brand === 'amex') {
    return `**** ****** *${lastFourDigits}`;
  }

  return `**** **** **** ${lastFourDigits}`;
}

export function getCreditCardExpiresDate(card) {
  const { expiresMonth, expiresYear } = card;
  return `${expiresMonth < 10 ? `0${expiresMonth}` : expiresMonth}/${expiresYear}`;
}
