import React, { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { connectRange } from 'react-instantsearch-dom';

import { Price } from '@acadeum/ui';
import type { PriceProps } from '@acadeum/ui';

interface InstantSearchPriceRangeProps {
  label: string;
  attribute: string;
  className?: string;
}

const InstantSearchPriceRange: FC<InstantSearchPriceRangeProps> = connectRange(({
  label,
  className,
  refine,
  min,
  max,
  currentRefinement
}) => {
  const [mode, setMode] = useState<PriceProps['mode']>('any');

  useEffect(() => {
    if (currentRefinement.min !== min || currentRefinement.max !== max) {
      setMode('range');
    }
  }, [currentRefinement]);

  const onChangeMode = (value: typeof mode) => {
    setMode(value);
  };

  const value = useMemo(() => (currentRefinement), [currentRefinement]);

  return (
    <Price
      mode={mode}
      className={className}
      onChangeMode={onChangeMode}
      label={label}
      value={value}
      min={min}
      max={max}
      onChange={refine}
    />
  );
});

export default InstantSearchPriceRange;
