import React from 'react';
import { useController } from 'react-hook-form';
import { PercentInput } from '../../../PercentInput';

export const FormPercentInput = ({
  name,
  rules,
  defaultValue,
  ...rest
}) => {
  const { field } = useController({ name, rules, defaultValue });

  return (
    <PercentInput
      {...rest}
      {...field}
    />
  );
};
