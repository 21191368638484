import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connectStats } from 'react-instantsearch-dom';

import { Text } from '@acadeum/ui';

import './InstantSearchResultsCount.sass';

class InstantSearchResultsCount extends Component {
  static propTypes = {
    nbHits: PropTypes.number.isRequired,
    hideWhenNoResults: PropTypes.bool,
    className: PropTypes.string
  };

  render() {
    const {
      nbHits,
      hideWhenNoResults,
      className
    } = this.props;
    if (hideWhenNoResults && nbHits === 0) {
      return null;
    }
    return (
      <Text role="status" className={classNames('InstantSearch-ResultsCount', className)}>
        {/* This form of the results displaying is proper for the Screen Readers */}
        {`${nbHits} Result${nbHits === 1 ? '' : 's'}`}
        {/* A "zero width space" is added so that the line height
            doesn't "jump" when it transitions from empty to "N Results". */}
        {'\u200b'}
      </Text>
    );
  }
}

export default connectStats(InstantSearchResultsCount);
