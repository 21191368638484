// Doesn't account for `middleName` because it's not shown in a table.
export default function sortByNameAscending(a, b) {
  const firstNameComparison = compareStrings(a.firstName, b.firstName);
  if (firstNameComparison === 0) {
    return compareStrings(a.lastName, b.lastName);
  }
  return firstNameComparison;
}

function compareStrings(a, b) {
  // Use `String.localeCompare` if it's available.
  // https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
  // Which means everyone except IE <= 10 and Safari <= 10.
  if (String.prototype.localeCompare) {
    return a.localeCompare(b);
  }
  return a < b ? -1 : (a > b ? 1 : 0);
}
