import type { FC } from 'react';
import React from 'react';

import type { OnClickOutsideProps } from '../OnClickOutside';
import { OnClickOutside } from '../OnClickOutside';
import type { OnTapOutsideProps } from '../OnTapOutside';
import { OnTapOutside } from '../OnTapOutside';

export interface OnClickOrTapOutsideProps extends OnClickOutsideProps, OnTapOutsideProps {
}

export const OnClickOrTapOutside: FC<OnClickOrTapOutsideProps> = ({
  children,
  containerRef,
  toggleRef,
  onClickOutside,
  onTapOutside,
  moveThreshold
}) => {
  return (
    <OnClickOutside containerRef={containerRef} toggleRef={toggleRef} onClickOutside={onClickOutside}>
      <OnTapOutside toggleRef={toggleRef} containerRef={containerRef} onTapOutside={onTapOutside} moveThreshold={moveThreshold}>
        {children}
      </OnTapOutside>
    </OnClickOutside>
  );
};
