import {
  useSearchBarAndResultsPerPageAndSortByAndPagination as useSearchBarAndResultsPerPageAndSortByAndPagination_
} from '@acadeum/hooks';

import Filters from '../components/Filters';
import Pagination from '../components/Pagination';
import SearchBarAndResultsPerPageAndSortBy from '../components/SearchBarAndResultsPerPageAndSortBy';

export default function useSearchBarAndResultsPerPageAndSortByAndPagination({
  ...rest
}) {
  // const dispatch = useDispatch();

  return useSearchBarAndResultsPerPageAndSortByAndPagination_({
    Pagination,
    dispatch: (actionResult) => actionResult,
    // dispatch,
    SearchBarAndResultsPerPageAndSortBy,
    Filters,
    pageSizes: [10, 20, 40],
    defaultPageSize: 10,
    ...rest
  });
}
