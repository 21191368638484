import React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import { PhoneExtensionInput } from '../../../PhoneExtensionInput';

export const FormPhoneExtensionInput = ({
  name,
  rules,
  defaultValue,
  ...rest
}) => {
  const { field } = useController({ name, rules, defaultValue });

  return (
    <PhoneExtensionInput
      {...rest}
      {...field}
      value={!field.value ? '' : field.value}
    />
  );
};

FormPhoneExtensionInput.propTypes = {
  name: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.any
};
