import { useCallback, useEffect, useRef } from 'react';
import { useIsMounted } from './useIsMounted';

export const useEyeDropper = () => {
  const isMounted = useIsMounted();
  const isSupported = typeof window !== 'undefined' && 'EyeDropper' in window && window.EyeDropper;
  const controller = useRef<AbortController>();

  const close = useCallback(() => controller.current?.abort(), [controller]);

  const open = useCallback(async () => {
    if (!window.EyeDropper) {
      return;
    }
    close();
    const newController = new AbortController();
    const eyeDropper = new window.EyeDropper();

    controller.current = newController;
    return await eyeDropper.open({
      signal: newController.signal
    });
  }, [controller, isMounted, close]);


  useEffect(() => close, [close]);

  return { open, close, isSupported };
};
