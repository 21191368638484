import React from 'react';

import type { RowData } from '@tanstack/react-table';

import { Select } from '../../../Select';

import type { FilterItemObject } from '../types';

export const createTableTypeFilter = <TData extends RowData>(): FilterItemObject<TData> => {
  return {
    id: 'type',
    filter(row, value) {
      return row['type'] === value;
    },
    render({ getValue, setFilterValues }) {
      const options = [
        { value: 'TRANSACTION_FEE', label: 'Transaction Fee' },
        { value: 'ANNUAL_FEE', label: 'Annual Fee' }
      ];

      return (
        <Select
          isFilter
          label="Type"
          value={getValue() ?? null}
          options={options}
          onChange={(value, option) => {
            setFilterValues(prev => ({
              ...prev,
              type: option
            }));
          }}
        />
      );
    }
  };
};
