import type { RowData, Table, RowSelectionState } from '@tanstack/react-table';
import { useMemo, useRef } from 'react';

// This is necessary to save the selected rows during server-side pagination
export const useGetSelectedRows = <TData extends RowData>({
  table,
  rowSelection
}: {
  table: Table<TData>,
  rowSelection: RowSelectionState
}) => {
  const selectedRowsCacheById = useRef<Record<string, TData>>({});

  return useMemo(() => {
    const { rowsById } = table.getSelectedRowModel();
    const newCache: Record<string, TData> = {};

    for (const id in rowSelection) {
      if (rowsById[id]) {
        newCache[id] = rowsById[id].original;
      } else if (selectedRowsCacheById.current[id]) {
        newCache[id] = selectedRowsCacheById.current[id];
      }
    }

    selectedRowsCacheById.current = newCache;
    return Object.values(newCache);
  }, [rowSelection]);
};
