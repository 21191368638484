import React, { memo } from 'react';
import classNames from 'classnames';

import { UnstyledLink } from '../UnstyledLink';
import { RadixTooltip, RadixTooltipContent, RadixTooltipTrigger } from '../RadixTooltip';

import styles from './TabsNavigation.module.scss';

interface MenuItem {
  url: string;
  title: string;
  active?: boolean;
  disabled?: boolean;
  tooltip?: string;
}

export interface TabsNavigationProps {
  className?: string;
  menuItems: MenuItem[];
}

export const TabsNavigation = memo<TabsNavigationProps>(({
  className,
  menuItems
}) => {
  return (
    <nav className={classNames(className, styles.root)}>
      <ul>
        {menuItems.map((menuItem) => {
          const item = (
            <UnstyledLink
              key={menuItem.url}
              to={menuItem.url}
              disabled={menuItem.disabled}
              className={classNames(styles.link, {
                [styles.active]: menuItem.active
              })}
            >
              {menuItem.title}
            </UnstyledLink>
          );

          if (menuItem.tooltip) {
            return (
              <RadixTooltip key={menuItem.url}>
                <RadixTooltipTrigger asChild>
                  {item}
                </RadixTooltipTrigger>
                <RadixTooltipContent
                  sideOffset={8}
                  side="right"
                >
                  {menuItem.tooltip}
                </RadixTooltipContent>
              </RadixTooltip>
            );
          }

          return item;
        })}
      </ul>
    </nav>
  );
});
