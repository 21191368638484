import React from 'react';
import { useSelector } from 'react-redux';

import getDateDiff from 'common-lib/lib/getDateDiff';

import RawDataTable, { compileColumns } from '../../components/RawDataTable';

import './AnnualFees.sass';

import actions from '../../actions';

const {
  chargeCustomers,
  fetchFuturePaymentData
} = actions;

export default function AnnualFees() {
  const futurePayments = useSelector(state => state.payments.futurePayments);

  async function chargeCustomers_(selectedRows) {
    await chargeCustomers(selectedRows.map(_ => _.id));
  }

  const duePaymentsTableActions = [{
    title: (selectedRows) => {
      let title = 'Charge Customers';
      if (selectedRows.length > 0) {
        title += ` for ${selectedRows.length} enrollment${selectedRows.length > 1 ? 's' : ''}`;
      }
      return title;
    },
    onClick: chargeCustomers_
  }];

  futurePayments.data = futurePayments.data || [];

  return (
    <section>
      <RawDataTable
        title="Upcoming Annual Fee Emails"
        data={futurePayments.data}
        columns={columns} />

      <br/>
      <br/>

      <RawDataTable
        title="Charge Annual Fees"
        data={futurePayments.data.filter(_ => getDateDiff(new Date(), (_.enrollmentRequest || _).section.session.lastDropDate, 'days') > 0)}
        columns={columns}
        actions={duePaymentsTableActions} />

      <br/>
      <br/>

      <RawDataTable
        title="Annual Fees Coming Up In Next 30 Days"
        data={futurePayments.data}
        columns={columns}
        actions={annualFeesComingUpActions} />

      <br/>
      <br/>

      <RawDataTable
        title="Annual Fees Coming Up In Next Month"
        data={futurePayments.data}
        columns={columns}
        actions={annualFeesComingUpActions} />
    </section>
  );
}

AnnualFees.meta = () => ({
  title: 'Annual Fees'
});

AnnualFees.load = async () => {
  await fetchFuturePaymentData();
};

AnnualFees.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Annual Fees'
];

// futurePayments: PropTypes.shape({
//   tm: PropTypes.arrayOf(PropTypes.object).isRequired,
//   em: PropTypes.arrayOf(PropTypes.object).isRequired,
//   data: PropTypes.arrayOf(PropTypes.object).isRequired
// })

const annualFeesComingUpActions = [{
  type: 'download'
}];

const columns = compileColumns([
  {
    title: 'TM',
    accessor: 'section.session.course.institution.name'
  }, {
    title: 'EM',
    accessor: 'student.institution.name'
  }, {
    title: 'FIRST NAME',
    accessor: 'student.firstName'
  }, {
    title: 'LAST NAME',
    accessor: 'student.lastName'
  }, {
    title: 'EMAIL',
    accessor: 'student.email'
  }, {
    title: 'DROP DATE',
    accessor: 'section.session.lastDropDate'
  }, {
    title: 'COST',
    accessor: 'costForInstitution'
  }
]);
