import React from 'react';

import DataUploadPage from '../../components/DataUploadPage';
import Link from '../../components/Link';
import DocumentIcon from '../../../assets/images/icons/document.svg';
import STUDENT_SCHEMA from '../../helpers/studentImportSchema';

import actions from '../../actions';

const {
  uploadStudents
} = actions;

export default function UploadStudents() {
  return (
    <section>
      <h1>Upload Students</h1>

      <div>
        <Link
          to="https://dliov3t26vp8o.cloudfront.net/Data Upload Templates/Students.xlsx"
          target="_blank"
          className="upload-data-page__download-template">
          <DocumentIcon className="upload-data-page__download-template-icon" />
          Download Student Upload Template
        </Link>
        {' — * in column header denotes a field is required'}
      </div>

      <DataUploadPage
        schema={STUDENT_SCHEMA}
        onUpload={uploadStudents}/>
    </section>
  );
}

UploadStudents.meta = () => ({
  title: 'Upload Students'
});

UploadStudents.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Upload Students'
];
