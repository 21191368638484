import React from 'react';

import { Grades } from '../../components/Grades';
import type { AppPage } from '../../helpers/app.types';
import actions from '../../actions';

const { fetchEnrollmentRequestsTM } = actions;

const TeachingGrades: AppPage = () => (
  <Grades institutionType="teaching"/>
);

TeachingGrades.meta = () => ({
  title: 'Grades'
}); 

TeachingGrades.load = async () => {
  await fetchEnrollmentRequestsTM();
};

TeachingGrades.breadcrumbs = () => ['Teaching Grades'];

export default TeachingGrades;
