import { userHasPermission } from '@acadeum/helpers';
import { AddUserRolePage } from '@acadeum/shared-admin-ui';

import {
  useCreateUserRoleMutation,
  useFetchUserRoleQuery,
  useFetchUserRolesQuery,
  useUpdateUserRoleMutation
} from '../../api/userRoles';
import { useFetchUsersQuery, useSetUsersRolesForUsersMutation } from '../../api/users';
import type { AppPage } from '../../helpers/app.types';

const AddUserRole: AppPage = () => {
  return (
    <AddUserRolePage
      useFetchUsersQuery={useFetchUsersQuery}
      useUpdateUserRoleMutation={useUpdateUserRoleMutation}
      useFetchUserRolesQuery={useFetchUserRolesQuery}
      useFetchUserRoleQuery={useFetchUserRoleQuery}
      useSetUsersRolesForUsersMutation={useSetUsersRolesForUsersMutation}
      useCreateUserRoleMutation={useCreateUserRoleMutation}
    />
  );
};

AddUserRole.meta = () => ({
  title: 'Add User Role'
});

AddUserRole.when = ({ user }) => userHasPermission(user, 'userRole:create', { orgId: user?.institution.id, ownerId: null });


export default AddUserRole;
