import React, { memo } from 'react';
import classNames from 'classnames';

import type { Course } from '@acadeum/types';

import { Link } from '../../../Link';
import { Text } from '../../../Text';

import { useGetCourseLink } from '../../../../utils/courseLink';

import styles from './CourseBlock.module.scss';

export interface CourseBlockProps {
  course: Pick<Course, 'title' | 'code' | 'id'>;
  url?: string | false;
}

export const CourseBlock: React.FC<CourseBlockProps> = memo(({
  course,
  url: propsUrl
}) => {
  const getCourseLink = useGetCourseLink();
  const url = propsUrl !== undefined ? propsUrl : getCourseLink?.(course);
  
  return (
    <div className={classNames(styles.CourseBlock)}>
      <Text color="grey">
        {course.code}
      </Text>
      <Text>
        {url ? <Link external to={url} children={course.title}/> : course.title}
      </Text>
    </div>
  );
});
