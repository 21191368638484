import { useState, Fragment, useMemo } from 'react';
import type { FC } from 'react';

import { toast, Title, Text, Button } from '@acadeum/ui';
import type { IconSource } from '@acadeum/types';
import { HStack } from '@acadeum/core-ui';
import { userHasPermission } from '@acadeum/helpers';

import type { StudentPortalPageProps } from '../../StudentPortalPage';

import { EnrollingStudentCoursePricingDescription } from '../EnrollingStudentCoursePricingDescription';
import { FormEnrollingStudentCoursePricing } from '../FormEnrollingStudentCoursePricing';

import { DownloadIcon, ArrowUpRightFromSquareIcon, EditIcon } from '@acadeum/icons';

import styles from './PricingSection.module.scss';

interface PricingSectionProps extends Omit<StudentPortalPageProps, 'courses' | 'onAfterSubmitEditPricing'> {
  onAfterSubmit?: () => void;
}

export const PricingSection: FC<PricingSectionProps> = ({
  user,
  homeStudentCourseEnrollmentPricing,
  formatCurrency,
  setHomeStudentCourseEnrollmentPricing,
  onAfterSubmit
}) => {
  const [isEditing, setEditing] = useState<boolean>();

  const PRICING_HEADER_ACTIONS = userHasPermission(user, 'homeStudentCourseEnrollmentPricing:update', {
    orgId: user.institution.id
  }) && !isEditing
    ? [{
      icon: 'edit',
      title: 'Edit',
      onClick: () => {
        if (homeStudentCourseEnrollmentPricing && homeStudentCourseEnrollmentPricing.length > 1) {
          return toast.default('Several enrollment course pricing models have been defined for your institution. The edit student portal pricing page is unavailable for such configuration.');
        }
        setEditing(true);
      }
    }] : undefined;

  return (
    <section>
      <header>
        <HStack gap="xl" mb="md">
          <Title level={2} marginBottom="none">
            Pricing
          </Title>
          {PRICING_HEADER_ACTIONS && (
            <HStack gap="md">
              {PRICING_HEADER_ACTIONS.map((action, i) => (
                <Action key={i} {...action} />
              ))}
            </HStack>
          )}
        </HStack>
        <Text variant="headingMd" mb="sm">
          This is the current price displayed to students for your approved courses.
        </Text>
      </header>
      <p className={styles['background-block']}>
        {(homeStudentCourseEnrollmentPricing || [undefined]).map((pricing, i) => (
          <Fragment key={i}>
            {i > 0 && <br />}
            <EnrollingStudentCoursePricingDescription pricing={pricing} />
          </Fragment>
        ))}
      </p>
      {isEditing &&
        <FormEnrollingStudentCoursePricing
          homeStudentCourseEnrollmentPricing={homeStudentCourseEnrollmentPricing && homeStudentCourseEnrollmentPricing[0]}
          onCancel={() => setEditing(false)}
          onAfterSubmit={async () => {
            if (onAfterSubmit) {
              onAfterSubmit();
            }
            setEditing(false);
          }}
          formatCurrency={formatCurrency}
          setHomeStudentCourseEnrollmentPricing={setHomeStudentCourseEnrollmentPricing}
        />
      }
    </section>
  );
};

interface ActionProps {
  icon: string | IconSource;
  link?: string;
  linkOpensInNewTab?: boolean;
  onClick?: (event) => void;
  title: string;
}

const Action: FC<ActionProps> = ({
  icon: icon_,
  link,
  linkOpensInNewTab,
  onClick,
  title
}) => {
  const icon = useMemo(() => {
    return typeof icon_ === 'string' ? getIconByName(icon_) : icon;
  }, [icon_]);

  return (
    <Button
      variant="text"
      external={Boolean(link && linkOpensInNewTab)}
      url={link ? link : undefined}
      onClick={!link ? onClick : undefined}
      icon={icon}
    >
      {title}
    </Button>
  );
};

const getIconByName = (iconName) => {
  switch (iconName) {
    case 'edit':
      return EditIcon;
    case 'download':
      return DownloadIcon;
    case 'link':
      return ArrowUpRightFromSquareIcon;
  }
};
