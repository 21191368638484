import { Integer } from 'read-excel-file';

export const MAPPING_SCHEMA = {
  'institutionId': {
    prop: 'institutionId',
    type: Integer,
    required: true
  },
  'code': {
    prop: 'code',
    type: String,
    required: true
  },
  'targetInstitutionId': {
    prop: 'targetInstitutionId',
    type: Integer,
    required: true
  },
  'targetCode': {
    prop: 'targetCode',
    type: String,
    required: true
  },
  'targetTitle': {
    prop: 'targetTitle',
    type: String,
    required: true
  }
};
