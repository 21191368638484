import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const dropStudentsFromCourseEM = redux.action(
  (ids, { reason, reasonNotes }) => async (http) => {
    Intercom('trackEvent', 'Drop students from course (EM)', {
      ids: JSON.stringify(ids),
      reason,
      reasonNotes
    });
    await http.put('/enrollmentrequeststudents/em/drop', {
      ids,
      reason,
      reasonNotes
    });
  }
);

export const dropStudentsFromCourseTM = redux.action(
  (ids, { reason, reasonNotes }) => async (http) => {
    Intercom('trackEvent', 'Drop students from course (TM)', {
      ids: JSON.stringify(ids),
      reason,
      reasonNotes
    });
    await http.put('/enrollmentrequeststudents/tm/drop', {
      ids,
      reason,
      reasonNotes
    });
  }
);

export const withdrawStudentsFromCourseEM = redux.action(
  (ids, { reason, reasonNotes }) => async (http) => {
    Intercom('trackEvent', 'Withdraw students from course (EM)', {
      ids: JSON.stringify(ids),
      reason,
      reasonNotes
    });
    await http.put('/enrollmentrequeststudents/em/withdraw', {
      ids,
      reason,
      reasonNotes
    });
  }
);

export const withdrawStudentsFromCourseTM = redux.action(
  (ids, { grades, reason, reasonNotes }) => async (http) => {
    Intercom('trackEvent', 'Withdraw students from course (TM)', {
      ids: JSON.stringify(ids),
      grades: JSON.stringify(grades),
      reason,
      reasonNotes
    });
    await http.put('/enrollmentrequeststudents/tm/withdraw', {
      ids,
      grades,
      reason,
      reasonNotes
    });
  }
);

export const removeStudentsFromCourse = redux.action(
  (ids, { reason, reasonNotes }) => async (http) => {
    Intercom('trackEvent', 'Remove students from course', {
      ids: JSON.stringify(ids),
      reason,
      reasonNotes
    });
    await http.put('/enrollmentrequeststudents/em/remove', {
      ids,
      reason,
      reasonNotes
    });
  }
);

export const completeStudentEnrollments = redux.action(
  (ids, { grades }) => async (http) => {
    Intercom('trackEvent', 'Complete students enrollments', {
      ids: JSON.stringify(ids),
      grades: JSON.stringify(grades)
    });
    await http.put('/enrollmentrequeststudents/tm/complete', { ids, grades });
  }
);

export const approveStudentEnrollments = redux.action(
  (ids, { enrollmentRequestId }) => async (http) => {
    Intercom('trackEvent', 'Approve student enrollments', {
      ids: JSON.stringify(ids)
    });
    await http.put('/enrollmentrequeststudents/tm/approve', {
      enrollmentRequestId,
      ids
    });
  }
);

export const denyStudentEnrollments = redux.action(
  (ids, { enrollmentRequestId, reason, reasonNotes }) => async (http) => {
    Intercom('trackEvent', 'Deny student enrollments', {
      ids: JSON.stringify(ids),
      reason,
      reasonNotes
    });
    await http.put('/enrollmentrequeststudents/tm/deny', {
      enrollmentRequestId,
      ids,
      reason,
      reasonNotes
    });
  }
);

export const refundEnrollments = redux.action(
  (ids, { amount, notes }) => async (http) => {
    Intercom('trackEvent', 'Refund student enrollments', {
      ids: JSON.stringify(ids),
      amount,
      notes
    });
    await http.post('/enrollments/refund', {
      ids,
      amount,
      notes
    });
  }
);

export default redux.reducer();
