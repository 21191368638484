import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { InstitutionLink, StepsProgress, SuccessModal } from '@acadeum/ui';
import { useStep } from '@acadeum/hooks';
import { useTranslate, useTranslateDictionary } from '@acadeum/translate';

import Section from '../../components/Section';
import CourseOverview from './ui/CourseOverview';
import AddStudents from './ui/AddStudents';
import Summary from './ui/Summary';

import useLocation from '../../hooks/useLocation';

import checkUserPermissions from '../../helpers/checkUserPermissions';

import actions from '../../actions';

import './CreateAdvisorRecommendation.sass';

const {
  fetchAllStudents,
  fetchCourse,
  fetchCourseSubstitutes,
  resetAdvisorRecommendationCreationState,
  setAllStudents,
  goto
} = actions;

export default function CreateAdvisorRecommendation() {
  let {
    query: {
      sectionId
    }
  } = useLocation();

  if (sectionId) {
    sectionId = parseInt(sectionId);
  }
  const { advisorRecommendationCreationState } = useSelector(state => state.advisorRecommendations);
  const { course } = useSelector(state => state.courses);

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const t = useTranslate('CreateAdvisorRecommendation');

  const STEPS = [
    {
      id: 'createAdvisorRecommendationCourseOverview',
      component: CourseOverview
    },
    {
      id: 'createAdvisorRecommendationEnrollStudents',
      component: AddStudents
    },
    {
      id: 'createAdvisorRecommendationSummary',
      component: Summary
    }
  ];

  const steps = STEPS;
  const { step, navigation } = useStep({
    initialStep: advisorRecommendationCreationState ? advisorRecommendationCreationState.currentStepId : 0,
    steps: steps
  });
  const STEPS_IDS = steps.map(step => step.id);
  const props = { navigation, sectionId, setShowSuccessModal };

  const onHideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <>
      <Section
        className="CreateAdvisorRecommendation"
        title={t('title', { courseCode: course.code, courseTitle: course.title })}
        mb="sm"
      >
        <InstitutionLink
          className="CreateAdvisorRecommendation__institutionLink"
          includeLogo
          monochrome
          institution={course.institution}
        />

        <StepsProgress
          step={step.id}
          steps={STEPS_IDS}
          stepNames={useTranslateDictionary('CreateAdvisorRecommendation.steps', STEPS_IDS)}
          riftNumber={3}
          mb="lg"
        />
        {step && <step.component {...props}/>}
      </Section>

      <SuccessModal
        show={showSuccessModal}
        size="narrow"
        onHide={onHideSuccessModal}
        action={{
          content: t('successModal.button'),
          onClick: () => goto('/advisor-recommendations')
        }}
      >
        {t('successModal.message')}
      </SuccessModal>
    </>
  );
}

CreateAdvisorRecommendation.meta = () => ({
  title: 'Recommend Course'
});

CreateAdvisorRecommendation.breadcrumbs = (state) => [
  ['Course', `/courses/${state.courses.course.id}`],
  'Create Advisor Recommendation'
];

CreateAdvisorRecommendation.loadStateParameters = {
  advisorRecommendationCreationState: state => state.advisorRecommendations.advisorRecommendationCreationState
};

CreateAdvisorRecommendation.load = async ({ state, user, params: { id } }) => {
  checkUserPermissions(user, 'homeCourseAdvisorRecommendation:create', {
    orgId: user.institution.id
  });
  id = parseInt(id);
  // Validate advisor recommendation creation state.
  const { advisorRecommendationCreationState } = state;
  if (advisorRecommendationCreationState) {
    if (advisorRecommendationCreationState.courseId !== id) {
      // Reset advisor recommendation creation state because it's for some other course.
      resetAdvisorRecommendationCreationState();
    }
  }
  await fetchCourse(id, { user });
  await fetchCourseSubstitutes(id);
  setAllStudents((await fetchAllStudents({ pageSize: 100000000000 })).results);
};
