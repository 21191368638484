import { useMemo } from 'react';

import getLastDateToDropFromCourseEnrollment from 'common-lib/lib/getLastDateToDropFromCourseEnrollment';

import { useTranslate } from '@acadeum/translate';
import { getEnrollmentTypeLabel } from '@acadeum/helpers';

import { formatCountry, formatReason } from '../../helpers/format';

interface UseExportDataColumnsProps {
  teaching?: boolean;
  finalized?: boolean;
  grades?: boolean;
}

export function useExportDataColumns({
  teaching = false,
  finalized = false,
  grades = false
}: UseExportDataColumnsProps = {}) {
  const t = useTranslate();

  return useMemo(() => {
    const otherInstitutionColumn = teaching
      ? {
        id: 'Home Institution',
        value: row => row.institution.name
      }
      : {
        id: 'Teaching Institution',
        value: row => row.section.session.course.institution.name
      };

    const homeInstitutionCourseColumns = teaching
      ? []
      : [{
        id: 'HI Course ID',
        value: row => row.courseSubstitute ? row.courseSubstitute.equivalentCourse.code : undefined
      }, {
        id: 'HI Course Title',
        value: row => row.courseSubstitute ? row.courseSubstitute.equivalentCourse.title : undefined
      }];

    const refundColumns = teaching || finalized
      ? [{
        id: 'Refunded',
        value: row => row.refunded
      }]
      : [];

    const gradesColumns = (teaching ? false : finalized) || grades
      ? [
        {
          id: 'Letter Grade',
          value: row => row.letterGrade
        },
        {
          id: 'Numerical Grade',
          value: row => row.numericalGrade
        },
        {
          id: 'Grade Notes',
          value: row => row.gradeNotes
        }
      ]
      : [];

    const withdrawColumns = finalized
      ? [
        {
          id: 'Student Withdraw Reason',
          value: row => formatReason(row.studentWithdrawReason, row.studentWithdrawReasonNotes, 'EnrollmentsTable.studentWithdrawReason')
        },
        {
          id: 'Home Withdraw Reason',
          value: row => formatReason(row.homeWithdrawReason, row.homeWithdrawReasonNotes, 'EnrollmentsTable.withdrawReason')
        },
        {
          id: 'Teaching Withdraw Reason',
          value: row => formatReason(row.teachingWithdrawReason, row.teachingWithdrawReasonNotes, 'EnrollmentsTable.withdrawReason')
        }
      ]
      : [];

    const dropColumns = finalized
      ? [
        {
          id: 'Student Drop Reason',
          value: row => formatReason(row.studentDropReason, row.studentDropReasonNotes, 'EnrollmentsTable.studentDropReason')
        },
        {
          id: 'Home Drop Reason',
          value: row => formatReason(row.homeDropReason, row.homeDropReasonNotes, 'EnrollmentsTable.dropReason')
        },
        {
          id: 'Teaching Drop Reason',
          value: row => formatReason(row.teachingDropReason, row.teachingDropReasonNotes, 'EnrollmentsTable.dropReason')
        }
      ]
      : [];

    const denyColumns = finalized
      ? [{
        id: 'Deny Reason',
        value: row => formatReason(row.denyReason, row.denyReasonNotes, 'EnrollmentRequest.denyReason')
      }]
      : [];

    const removeColumns = finalized
      ? [{
        id: 'Remove Reason',
        value: row => formatReason(row.removeReason, row.removeReasonNotes, 'EnrollmentsTable.removeReason')
      }]
      : [];

    return [{
      id: 'Status',
      value: row => row.status
    }, otherInstitutionColumn, {
      id: 'Enrollment Type',
      value: row => getEnrollmentTypeLabel(row)
    }, {
      id: 'TI Course ID',
      value: row => row.section.session.course.code
    }, {
      id: 'TI Course Title',
      value: row => row.section.session.course.title
    }, {
      id: 'TI Course Section ID',
      value: row => row.section.id
    }, {
      id: 'Course Cost',
      value: row => row.costForInstitution
    }, ...homeInstitutionCourseColumns, {
      id: 'Credits',
      value: row => row.section.session.course.hours
    }, {
      id: 'Term',
      value: row => row.section.session.course.onDemand ? 'Open Learning' : row.section.session.term
    }, {
      id: 'Internal Term',
      value: row => row.section.session.internalTerm
    }, {
      id: 'Session',
      value: row => row.section.session.course.onDemand ? null : row.section.session.name
    }, {
      id: 'Internal Session',
      value: row => row.section.session.internalName
    }, {
      id: 'Section',
      value: row => row.section.number
    }, {
      id: 'Session Start Date',
      value: row => row.section.session.course.onDemand ? null : row.section.session.startDate
    }, {
      id: 'Session Add Date',
      value: row => row.section.session.course.onDemand ? null : row.section.session.lastAddDate
    }, {
      id: 'Session Drop Date',
      value: row => {
        if (row.section.session.course.onDemand && !row.startedAt) {
          return null;
        }
        return getLastDateToDropFromCourseEnrollment({
          onDemand: row.section.session.course.onDemand,
          onDemandDropDateDaysAfterStartDate: row.section.session.course.institution.onDemandCourseDropDateDaysAfterStartDate,
          startedAt: row.startedAt,
          lastDropDate: row.section.session.lastDropDate
        });
      }
    }, {
      id: 'Session End Date',
      value: row => row.section.session.course.onDemand ? null : row.section.session.endDate
    }, {
      id: 'HI Student ID',
      value: row => row.student.hiStudentId
    }, {
      id: 'Acadeum Student ID',
      value: row => row.student.id
    }, {
      id: 'First Name',
      value: row => row.student.firstName
    }, {
      id: 'Middle Name',
      value: row => row.student.middleName
    }, {
      id: 'Last Name',
      value: row => row.student.lastName
    }, {
      id: 'Phone',
      value: row => row.student.phone
    }, {
      id: 'Email',
      value: row => row.student.email
    }, {
      id: 'Address Line 1',
      value: row => row.student.addressLine1
    }, {
      id: 'Address Line 2',
      value: row => row.student.addressLine2
    }, {
      id: 'City',
      value: row => row.student.city
    }, {
      id: 'State',
      value: row => row.student.state
    }, {
      id: 'Postal Code',
      value: row => row.student.postalCode
    }, {
      id: 'Country',
      value: row => formatCountry(row.student.country)
    }, {
      id: 'Major',
      value: row => row.student.major
    }, {
      id: 'Level',
      value: row => row.student.level
    }, {
      id: 'Gender',
      value: row => row.student.gender
    }, {
      id: 'Date of Birth',
      value: row => row.student.dob
    }, {
      id: 'Start Date',
      value: row => row.student.startDate
    }, {
      id: 'US Citizenship',
      value: row => row.student.citizenship
    }, {
      id: 'State Residency',
      value: row => row.student.residency
    }, {
      id: 'Ethnicity',
      value: row => row.student.ethnicity
    }, {
      id: 'Race',
      value: row => row.student.races
    }, {
      id: 'Advisor Name',
      value: row => row.student.advisorName
    }, {
      id: 'Advisor Email',
      value: row => row.student.advisorEmail
    }, {
      id: 'Enroll Reason',
      value: row => formatReason(row.enrollReason, row.enrollReasonNotes, 'Enrollments.enrollReason')
    }, ...withdrawColumns, ...dropColumns, ...denyColumns, ...removeColumns, {
      id: 'Paid',
      value: row => row.paid
    }, ...refundColumns, ...gradesColumns];
  }, [t]);
}
