import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from '../Link';

import PencilIcon from '../../../assets/images/icons/pencil.svg';
// import InfoIcon from '../../../assets/images/icons/info.svg';
import CloseIcon from '../../../assets/images/icons/close.svg';

import { CircleInfoIcon as InfoIcon } from '@acadeum/icons';

import './IconButton.sass';

export default function IconButton({
  inline,
  icon: Icon,
  link,
  onClick,
  title,
  className,
  ...rest
}) {
  if (typeof Icon === 'string') {
    Icon = getIconByName(Icon);
  }
  const icon = <Icon className="icon-button__icon"/>;
  className = classNames(className, 'icon-button', {
    'icon-button--inline': inline
  });
  if (link) {
    return (
      <Link
        {...rest}
        to={link}
        title={title}
        aria-label={title}
        className={classNames('icon-button--link', className)}>
        {icon}
      </Link>
    );
  }
  return (
    <button
      {...rest}
      type="button"
      onClick={onClick}
      title={title}
      className={classNames('rrui__button-reset', className)}>
      {icon}
    </button>
  );
}

IconButton.propTypes = {
  inline: PropTypes.bool,
  link: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType
  ]).isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string
};

function getIconByName(iconName) {
  switch (iconName) {
    case 'edit':
      return PencilIcon;
    case 'info':
      return InfoIcon;
    case 'remove':
      return CloseIcon;
  }
}
