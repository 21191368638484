import type { CSSProperties, ReactNode } from 'react';
import React from 'react';
import classNames from 'classnames';

import type { MarginsProp } from '../../utils/useMargin';
import { useMargin } from '../../utils/useMargin';

import { CloseButton } from '../CloseButton';
import { HStack } from '../Stack';
import { Text } from '../Text';
import { Row } from '../Row';
import { Col } from '../Col';
import { Separator } from '../Separator';

import { CardContent } from './CardContent';

import styles from './Card.module.scss';
import type { ActionsProps} from '../Actions';
import { Actions } from '../Actions';

export interface CardProps extends Pick<MarginsProp, 'mt' | 'mb'> {
  variant?: 'white' | 'gray';
  className?: string;
  children: ReactNode;
  title?: ReactNode;
  onClose?: (value: false) => void;
  style?: CSSProperties;
  actions?: ActionsProps['actions'];
}

export const Card: React.FC<CardProps> & {
  Row: typeof Row,
  Col: typeof Col,
  Content: typeof CardContent,
  Separator: typeof Separator
} = ({
  className,
  children,
  variant = 'white',
  mt,
  mb,
  onClose,
  title,
  style,
  actions
}) => {
  const { marginClassNames } = useMargin({ mt, mb });
  return (
    <div
      style={style}
      className={classNames(className, marginClassNames, styles.Card, styles[variant])}
    >
      {(title || onClose || actions) && (
        <HStack gap="md" mb="lg">
          {title && (
            <Text>
              {title}
            </Text>
          )}
          {actions && (
            <Actions
              variant="kebab"
              actions={actions}
              className={styles.mlAuto}
            />
          )}
          {onClose && (
            <CloseButton
              className={actions ? undefined : styles.mlAuto}
              onClick={() => onClose(false)}
            />
          )}
        </HStack>
      )}
      {children}
    </div>
  );
};

Card.Row = (props) => <Row as="dl" {...props}/>;
Card.Col = Col;
Card.Content = CardContent;
Card.Separator = ({ m = 'md', ...rest }) => <Separator m={m} {...rest}/>;
