import React from 'react';
import PropTypes from 'prop-types';

import { Col, FormField, FormModal, Row, waitForModalToClose } from '@acadeum/ui';
import { getErrorData } from '@acadeum/helpers';

import { ALGOLIA_UPDATE_PENDING_NOTE } from '../../../../../helpers/algolia';

import actions from '../../../../../actions';

const {
  notify,
  notifyError,
  addCourseSubstitute,
  deleteCourseSubstitute
} = actions;

export default function MappingForm({
  show,
  onHide,
  courseSubstitute,
  course,
  institutionId
}) {
  const onSubmit = async (values) => {
    if (courseSubstitute) {
      if (
        courseSubstitute.code === values.code &&
        courseSubstitute.title === values.title
      ) {
        // No changes.
        return onHide();
      }
    }
    try {
      // Update a course substitute by adding a new one
      // and deleting the old one.
      // https://github.com/Acadeum/Tickets/issues/979
      const { id } = await addCourseSubstitute({
        homeCourseCode: values.code,
        homeCourseTitle: values.title,
        courseId: course.id
      });
      if (courseSubstitute) {
        await deleteCourseSubstitute(courseSubstitute.id);
      }
      // Update the mappings as if they were re-fetched from the database.
      if (courseSubstitute) {
        course.substitutes = course.substitutes.filter(_ => _.id !== courseSubstitute.id);
      }
      course.substitutes.push({
        id,
        code: values.code,
        title: values.title,
        institutionId
      });
    } catch (error) {
      if (getErrorData(error).message === 'duplicate_course_substitute') {
        return notifyError('This course mapping already exists.');
      }
      throw error;
    }
    onHide();
    await waitForModalToClose();
    notify(`Course Mapping has been ${courseSubstitute ? 'updated' : 'added'}. ${ALGOLIA_UPDATE_PENDING_NOTE}`);
  };

  return (
    <FormModal
      autoFocus
      show={show}
      onHide={onHide}
      title={`${courseSubstitute ? 'Edit' : 'Add'} Mapping`}
      submitText="Save"
      onSubmit={onSubmit}>
      <Row >
        <Col col={4}>
          <FormField
            required
            name="code"
            defaultValue={courseSubstitute && courseSubstitute.code}
            label="Course Code"
            placeholder="Course Code"
          />
        </Col>

        <Col col={8}>
          <FormField
            required
            name="title"
            defaultValue={courseSubstitute && courseSubstitute.title}
            label="Course Title"
            placeholder="Course Title"
          />
        </Col>
      </Row>
    </FormModal>
  );
}

MappingForm.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  courseSubstitute: PropTypes.object,
  course: PropTypes.object.isRequired,
  institutionId: PropTypes.number.isRequired
};
