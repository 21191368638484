import { Integer } from 'read-excel-file';

import { validateSessionName } from './validate/validateSessionName';
import { validateTermName } from './validate/validateTermName';

export const COURSE_APPROVAL_SCHEMA = {
  'HOME INSTITUTION ID': {
    prop: 'institutionId',
    type: Integer,
    required: true
  },
  'TEACHING INSTITUTION ID': {
    prop: 'courseInstitutionId',
    type: Integer,
    required: true
  },
  'CODE': {
    prop: 'courseCode',
    type: String,
    required: true
  },
  'SESSION': {
    prop: 'session',
    type: String,
    validate: validateSessionName
  },
  'TERM': {
    prop: 'term',
    type: String,
    validate: validateTermName
  }
};
