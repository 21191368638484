import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslate } from '@acadeum/translate';

import Modal, { waitForModalToClose } from '../Modal';
import ConfirmationModal from '../ConfirmationModal';
import EmailAlreadyVerifiedModal from '../EmailAlreadyVerifiedModal';
import InstitutionUsesSingleSignOnAuthentication from '../InstitutionUsesSingleSignOnAuthentication';
import Login from '../Login';
import Form from '../Form';
import FormSubmit from '../FormSubmit';
import FormFooter from '../FormFooter';
import Button from '../Button';

import RightCaretIcon from './RightCaretIcon.svg';

import actions from '../../actions';

const {
  notifyError,
  hideSignInModal,
  requestVerifyEmail
  // redirect
} = actions;

export default function LoginModal() {
  const t = useTranslate('LoginModal');

  const { isOpen } = useSelector(state => state.signInModal);

  const hideModal = hideSignInModal;

  const [showAccountNotVerifiedModal, setShowAccountNotVerifiedModal] = useState();
  const [showPasswordNotSetModal, setShowPasswordNotSetModal] = useState();
  const [showPasswordLoginAttemptedOnSingleSignOnInstitutionModal, setShowPasswordLoginAttemptedOnSingleSignOnInstitutionModal] = useState();
  const [showAccountDeactivatedModal, setShowAccountDeactivatedModal] = useState();
  const [showInstitutionDeactivatedModal, setShowInstitutionDeactivatedModal] = useState();
  const [showEmailVerificationLetterSentConfirmation, setShowEmailVerificationLetterSentConfirmation] = useState();
  // const [studentAppNotEnabledByInstitution, setStudentAppNotEnabledByInstitution] = useState();
  // const [showStudentAppNotEnabledModal, setShowStudentAppNotEnabledModal] = useState();
  const [showAlreadyVerifiedModal, setShowAlreadyVerifiedModal] = useState();

  const [verifyEmailAddress, setVerifyEmailAddress] = useState();

  const onSendVerifyEmail = async () => {
    try {
      await requestVerifyEmail({ email: verifyEmailAddress });
      setVerifyEmailAddress();
      setShowAccountNotVerifiedModal(false);
      await waitForModalToClose();
      setShowEmailVerificationLetterSentConfirmation(true);
    } catch (error) {
      if (error.data.code === 'already_verified') {
        setVerifyEmailAddress();
        setShowAccountNotVerifiedModal(false);
        await waitForModalToClose();
        setShowAlreadyVerifiedModal(true);
      } else {
        console.error(error);
        notifyError(t('errorVerbose', { global: true }));
      }
    }
  };

  return (
    <>
      <Modal
        show={isOpen}
        onHide={hideModal}
        onCancelGaLabel="Cancel Login">
        <Login
          onAfterSubmit={() => {
            hideModal();
            // if (redirectTo) {
            //   redirect(redirectTo);
            // }
          }}
          onAccountNotVerified={async (email) => {
            hideModal();
            await waitForModalToClose();
            setVerifyEmailAddress(email);
            setShowAccountNotVerifiedModal(true);
          }}
          onPasswordNotSet={async () => {
            hideModal();
            await waitForModalToClose();
            setShowPasswordNotSetModal(true);
          }}
          onPasswordLoginAttemptedOnSingleSignOnInstitution={async () => {
            hideModal();
            await waitForModalToClose();
            setShowPasswordLoginAttemptedOnSingleSignOnInstitutionModal(true);
          }}
          onAccountInactive={async () => {
            hideModal();
            await waitForModalToClose();
            setShowInstitutionDeactivatedModal(true);
          }}
          onInstitutionInactive={async () => {
            hideModal();
            await waitForModalToClose();
            setShowAccountDeactivatedModal(true);
          }}
          onTooManyLoginAttempts={(nextAttemptTimestamp) => {
            notifyError(t('tooManyAttempts', {
              nextAttemptDate: new Date(nextAttemptTimestamp)
            }));
          }}
          onError={(error) => {
            console.error(error);
            notifyError(t('errorVerbose', { global: true }));
          }}
        />
      </Modal>

      <ConfirmationModal
        title={t('accountNotVerified.title')}
        text={t('accountNotVerified.text')}
        show={showAccountNotVerifiedModal}
        onHide={() => setShowAccountNotVerifiedModal(false)}
        closeButton={false}>
        <Form onSubmit={onSendVerifyEmail}>
          <FormFooter>
            <FormFooter.Actions>
              <FormSubmit>
                {t('sendEmailVerificationLetter')}
              </FormSubmit>
              <Button
                text
                onClick={() => setShowAccountNotVerifiedModal(false)}>
                Continue
                <RightCaretIcon aria-hidden style={{
                  width: '0.5rem',
                  height: '0.5rem',
                  marginTop: '0.25rem',
                  marginLeft: '0.25rem'
                }}/>
              </Button>
            </FormFooter.Actions>
          </FormFooter>
        </Form>
      </ConfirmationModal>

      <ConfirmationModal
        title={t('sentEmailVerificationLetter.title')}
        text={t('sentEmailVerificationLetter.text')}
        show={showEmailVerificationLetterSentConfirmation}
        onHide={() => {
        }}
        closeButton={false}
      />

      <ConfirmationModal
        title={t('passwordNotSet.title')}
        text={t('passwordNotSet.text')}
        show={showPasswordNotSetModal}
        onHide={() => {
        }}
        closeButton={false}
      />

      <InstitutionUsesSingleSignOnAuthentication
        show={showPasswordLoginAttemptedOnSingleSignOnInstitutionModal}
        onHide={() => setShowPasswordLoginAttemptedOnSingleSignOnInstitutionModal()}
      />

      <ConfirmationModal
        title={t('accountDeactivated.title')}
        text={t('accountDeactivated.text')}
        show={showAccountDeactivatedModal}
        onHide={() => setShowAccountDeactivatedModal()}
      />

      <ConfirmationModal
        title={t('institutionDeactivated.title')}
        text={t('institutionDeactivated.text')}
        show={showInstitutionDeactivatedModal}
        onHide={() => setShowInstitutionDeactivatedModal()}
      />

      <EmailAlreadyVerifiedModal
        show={showAlreadyVerifiedModal}
        onHide={() => setShowAlreadyVerifiedModal()}
      />
    </>
  );
}
