/* eslint-disable */
// @ts-nocheck TODO: Remove it
import React, { Children, MutableRefObject, useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { KeypressListener } from '../KeypressListener';

import Option from './Option';

import { useComboboxListbox } from '../Select/Combobox/hooks';
import { ListboxContext, WithinListboxContext } from './context';

import styles from './Listbox.module.scss';
import { useChoiceListListbox } from '../ChoiceList/context';
import EmptyOption from './EmptyOption';
import { Code } from '../../types';

const OPTION_SELECTOR = '[data-listbox-option]';
const OPTION_VALUE_ATTRIBUTE = 'data-listbox-option-value';
const OPTION_VALUE_TYPE_ATTRIBUTE = 'data-listbox-option-value-type';
const OPTION_FOCUS_ATTRIBUTE = 'data-focused';

export interface ListboxProps {
  children?: React.ReactNode;
  enableKeyboardControl?: boolean;
  accessibilityLabel?: string;
  listId?: string;
  onSelect?: (value: any) => void;
  onActiveOptionChange?: (value: any, domId: string) => void;
  loading?: boolean;
  appearance?: 'white';
  className?: string;
}

export const Listbox: React.FC<ListboxProps> & {
  Option: typeof Option;
  EmptyOption: typeof EmptyOption;
} = ({
  children,
  enableKeyboardControl = true,
  accessibilityLabel,
  listId,
  onSelect,
  onActiveOptionChange,
  loading,
  appearance,
  className
}) => {
  const innerId = useId() + 'Listbox';
  listId = listId || innerId;
  const [activeOption, setActiveOption] = useState();
  const [currentOptions, setCurrentOptions] = useState([]);

  const [keyboardEventsEnabled, setKeyboardEventsEnabled] = useState(Boolean(enableKeyboardControl));

  const listboxRef = useRef() as MutableRefObject<HTMLUListElement>;

  const {
    listboxId: comboboxListboxId,
    setListboxId: comboboxSetListboxId,
    textFieldFocused: comboboxTextFieldFocused,
    setActiveOptionId: comboboxSetActiveOptionId,
    onOptionSelected,
    onListboxClick,
    onKeyDown,
    handleFocus,
    handleBlur
  } = useComboboxListbox();

  const {
    listboxId: choiceListListboxId,
    setListboxId: choiceListSetListboxId,
    textFieldFocused: choiceListTextFieldFocused,
    setActiveOptionId: choiceListSetActiveOptionId
  } = useChoiceListListbox();

  const listboxId = comboboxListboxId || choiceListListboxId;
  const setListboxId = comboboxSetListboxId || choiceListSetListboxId;
  const textFieldFocused = comboboxTextFieldFocused || choiceListTextFieldFocused;
  const setActiveOptionId = comboboxSetActiveOptionId || choiceListSetActiveOptionId;

  const inCombobox = Boolean(comboboxSetActiveOptionId);
  // const inChoiceList = Boolean(choiceListSetActiveOptionId);

  useEffect(() => {
    if (setListboxId && !listboxId) {
      setListboxId(listId);
    }
    return () => {
      if (setListboxId) {
        setListboxId(undefined);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listId]);

  const getNavigableOptions = useCallback(() => {
    if (!listboxRef.current) {
      return [];
    }

    return [...new Set(listboxRef.current.querySelectorAll(OPTION_SELECTOR))];
  }, []);

  const getFirstNavigableOption = useCallback((currentOptions) => {
    const hasSelectedOptions = currentOptions.some((option) => option.getAttribute('aria-selected') === 'true');

    let elementIndex = 0;
    const element = currentOptions.find((option, index) => {
      const isInteractable = option.getAttribute('aria-disabled') !== 'true';
      let isFirstNavigableOption;

      if (hasSelectedOptions) {
        const isSelected = option.getAttribute('aria-selected') === 'true';
        isFirstNavigableOption = isSelected && isInteractable;
      } else {
        isFirstNavigableOption = isInteractable;
      }

      if (isFirstNavigableOption) {
        elementIndex = index;
      }

      return isFirstNavigableOption;
    });

    if (!element) {
      return;
    }

    return { element, index: elementIndex };
  }, []);

  const onChangeActiveOption = useCallback((nextOption) => {
    if (!nextOption) {
      return setActiveOption(undefined);
    }

    activeOption?.element.removeAttribute(OPTION_FOCUS_ATTRIBUTE);
    nextOption.element.setAttribute(OPTION_FOCUS_ATTRIBUTE, 'true');
    nextOption.element.scrollIntoView({ block: 'nearest' });
    setActiveOption(nextOption);
    setActiveOptionId?.(nextOption.domId);
    onActiveOptionChange?.(nextOption.value, nextOption.domId);
  }, [
    activeOption,
    setActiveOptionId,
    onActiveOptionChange
  ]);

  const getFormattedOption = useCallback((element, index) => {
    return {
      element,
      index,
      domId: element.id,
      value: getValueByValueType(element.getAttribute(OPTION_VALUE_ATTRIBUTE) || '', element.getAttribute(OPTION_VALUE_TYPE_ATTRIBUTE)),
      disabled: element.getAttribute('aria-disabled') === 'true'
    };
  }, []);

  const resetActiveOption = useCallback(() => {
    let nextOption;
    const nextOptions = getNavigableOptions();
    const nextActiveOption = getFirstNavigableOption(nextOptions);

    if (nextOptions.length === 0 && currentOptions.length > 0) {
      setCurrentOptions(nextOptions);
      onChangeActiveOption();
      return;
    }

    if (nextActiveOption) {
      const { element, index } = nextActiveOption;
      nextOption = getFormattedOption(element, index);
    }

    const optionIsAlreadyActive = activeOption !== undefined && nextOption?.domId === activeOption?.domId;

    const actionContentHasUpdated = activeOption?.isAction && nextOption?.isAction && nextOption?.value !== activeOption?.value;

    const currentValues = currentOptions.map((option) => option.getAttribute(OPTION_VALUE_ATTRIBUTE));

    const nextValues = nextOptions.map((option) => option.getAttribute(OPTION_VALUE_ATTRIBUTE));

    const listIsUnchanged = nextValues.length === currentValues.length && nextValues.every((value, index) => {
      return currentValues[index] === value;
    });

    const listIsAppended = currentValues.length !== 0 && nextValues.length > currentValues.length &&
      currentValues.every((value, index) => {
        return nextValues[index] === value;
      });

    if (listIsUnchanged) {
      if (optionIsAlreadyActive && actionContentHasUpdated) {
        setCurrentOptions(nextOptions);
        onChangeActiveOption(nextOption);
      }

      return;
    }

    if (listIsAppended) {
      setCurrentOptions(nextOptions);
      return;
    }

    setCurrentOptions(nextOptions);

    onChangeActiveOption(nextOption);
  }, [
    currentOptions,
    activeOption,
    getFirstNavigableOption,
    getNavigableOptions,
    getFormattedOption,
    onChangeActiveOption
  ]);

  useEffect(() => {
    if (children && Children.count(children) > 0) {
      resetActiveOption();
    }
  }, [children, activeOption, resetActiveOption]);

  useEffect(() => {
    if (enableKeyboardControl && !keyboardEventsEnabled) {
      setKeyboardEventsEnabled(true);
    }
  }, [enableKeyboardControl, keyboardEventsEnabled]);

  const onOptionSelect = useCallback((option) => {
    onChangeActiveOption(option);

    if (onOptionSelected) {
      onOptionSelected();
    }
    if (onSelect) {
      onSelect(option.value);
    }
  }, [onChangeActiveOption, onSelect, onOptionSelected]);

  const getNextIndex = useCallback((currentIndex, lastIndex, direction) => {
    let nextIndex;

    if (direction === 'down') {
      if (currentIndex === lastIndex) {
        nextIndex = 0;
      } else {
        nextIndex = currentIndex + 1;
      }
    } else {
      nextIndex = currentIndex === 0 ? lastIndex : currentIndex - 1;
    }

    return nextIndex;
  }, []);

  const getNextValidOption = useCallback(async (key) => {
    const lastIndex = currentOptions.length - 1;
    let currentIndex = activeOption?.index || 0;
    let nextIndex = 0;
    let element = activeOption?.element;
    let totalOptions = -1;

    while (totalOptions++ < lastIndex) {
      nextIndex = getNextIndex(currentIndex, lastIndex, key);
      element = currentOptions[nextIndex];
      const isDisabled = element?.getAttribute('aria-disabled') === 'true';

      if (isDisabled) {
        currentIndex = nextIndex;
        element = undefined;
        continue;
      }

      break;
    }
    return { element, nextIndex };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentOptions,
    activeOption,
    getNextIndex,
    getFirstNavigableOption,
    getNavigableOptions
  ]);

  const onArrow = useCallback(async (type, event) => {
    event.preventDefault();
    const { element, nextIndex } = await getNextValidOption(type);
    if (!element) {
      return;
    }
    const nextOption = getFormattedOption(element, nextIndex);
    onChangeActiveOption(nextOption);
  }, [getFormattedOption, getNextValidOption, onChangeActiveOption]);

  const onDownArrow = useCallback(async (event) => {
    await onArrow('down', event);
  }, [onArrow]);

  const onUpArrow = useCallback(async (event) => {
    await onArrow('up', event);
  }, [onArrow]);

  const onEnterKeydown = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    if (activeOption) {
      onOptionSelect(activeOption);
    }
  }, [activeOption, onOptionSelect]);

  const onFocus = useCallback(() => {
    if (enableKeyboardControl) {
      return;
    }
    setKeyboardEventsEnabled(true);
  }, [enableKeyboardControl]);

  const onBlur = useCallback((event) => {
    event.stopPropagation();

    if (keyboardEventsEnabled) {
      const nextActiveOption = getFirstNavigableOption(currentOptions);
      if (nextActiveOption) {
        const { element, index } = nextActiveOption;
        const nextOption = getFormattedOption(element, index);
        onChangeActiveOption(nextOption);
      }
    }
    if (enableKeyboardControl) {
      return;
    }
    setKeyboardEventsEnabled(false);
  }, [
    enableKeyboardControl,
    currentOptions,
    keyboardEventsEnabled,
    getFirstNavigableOption,
    getFormattedOption,
    onChangeActiveOption
  ]);

  const listeners = keyboardEventsEnabled || textFieldFocused ? (
    <>
      <KeypressListener
        keyEvent="keydown"
        code={Code.ArrowDown}
        handler={onDownArrow}
      />
      <KeypressListener
        keyEvent="keydown"
        code={Code.ArrowUp}
        handler={onUpArrow}
      />
      <KeypressListener
        keyEvent="keydown"
        code={Code.Enter}
        handler={onEnterKeydown}
      />
    </>
  ) : null;

  const listboxContext = useMemo(() => ({
    onOptionSelect
  }), [onOptionSelect]);

  return (
    <>
      {listeners}
      <ListboxContext.Provider value={listboxContext}>
        <WithinListboxContext.Provider value>
          {children && (
            <ul
              data-listbox-appearance={appearance || undefined}
              role="listbox"
              className={classNames(styles.Listbox, className)}
              aria-label={inCombobox ? undefined : accessibilityLabel}
              aria-busy={Boolean(loading)}
              aria-activedescendant={activeOption && activeOption.domId}
              id={listId}
              ref={listboxRef}
              onKeyDown={onKeyDown}
              onFocus={inCombobox ? handleFocus : onFocus}
              onBlur={inCombobox ? handleBlur : onBlur}
              onClick={onListboxClick}
            >
              {children}
            </ul>
          )}
        </WithinListboxContext.Provider>
      </ListboxContext.Provider>
    </>
  );
};

Listbox.propTypes = {
  children: PropTypes.node,
  enableKeyboardControl: PropTypes.bool,
  accessibilityLabel: PropTypes.string,
  listId: PropTypes.string,
  onSelect: PropTypes.func,
  onActiveOptionChange: PropTypes.func,
  loading: PropTypes.bool,
  appearance: PropTypes.oneOf(['white'])
};

Listbox.Option = Option;
Listbox.EmptyOption = EmptyOption;

function getValueByValueType(value, valueType) {
  switch (valueType) {
    case 'boolean':
      return value === 'true' ? true : (value === 'false' ? false : value);
    case 'number':
      return parseFloat(value);
    case 'null':
      return null;
    case 'undefined':
      return undefined;
    case 'string':
      return value;
    case 'object':
      return value;
    default:
      throw new Error(`Unsupported Select value type: ${valueType}`);
  }
}
