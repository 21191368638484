import React, { useEffect, useId, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Avatar } from '@acadeum/core-ui';

import InstitutionLink from '../InstitutionLink';
import Button from '../Button';
import Link from '../Link';
import CCPropTypes from '../PropTypes';

import {
  canEnrollInCourse,
  canEnrollInSection,
  getEnrollableSectionsWhenNewAlgoliaObjectFormat,
  getEnrollableSessions,
  isConsortialCourse,
  isCourseApproved
} from '../../helpers/course';

import { formatCurrency, formatDate } from '../../helpers/format';
import convertUtcDateToLocalDateWithSameTime from 'common-lib/lib/convertUtcDateToLocalDateWithSameTime';

import { DUMMY_SECTION } from './constants';


import { CourseBadges } from '../CourseBadges';

import actions from '../../actions';

import './CourseSearchResult.sass';

const { refreshCourseEnrollmentPricingIfRequired } = actions;

export default function CourseSearchResult({
  dummy,
  dummyCost,
  dummyHours,
  section,
  course,
  showPrice,
  instantBack = true,
  className,
  userInstitutionId,
  getCourseSectionCost,
  noSectionsAvailableLabel = 'See Available Sections',
  noSectionsAvailableLink,
  onCourseSelectionChange,
  selectedCourseIds,
  isHS4CC
}) {
  let LinkComponent = Link;
  if (dummy) {
    // TODO: Remove
    // eslint-disable-next-line react-hooks/rules-of-hooks
    section = useMemo(() => ({
      ...DUMMY_SECTION,
      cost: dummyCost
    }), [dummyCost]);
    LinkComponent = NonLink;
  }

  const isSection = Boolean(section);
  course = isSection ? section.session.course : course;

  // This component gets re-rendered every time some filter value gets changed.
  // On each re-render of this component, check if the cached `courseEnrollmentPricing`
  // should be refreshed. If it should, re-fetch `courseEnrollmentPricing` from the server.
  useEffect(() => {
    // Refresh course pricing model if the refresh time interval has passed.
    // If the refresh time interval hasn't passed yet, ignore and use the cached pricing.
    // Doesn't use `await` here because there's no need to wait for it to finish.
    // The pricing is supposed to be updated quickly and the course price will update itself
    // when it detects that the pricing has changed in Redux state.
    if (userInstitutionId && showPrice) {
      refreshCourseEnrollmentPricingIfRequired();
    }
    // refreshTeachingInstitutionRelationshipIdsIfRequired();
  });

  function getCost() {
    // `section` variable will be overwritten.
    const section_ = section;

    // If a custom `cost` calculation function is passed as a property
    // to `<CourseSearch/>`, then use it to calculate the actual cost
    // of this course session, based on the "base" `session.cost`.
    if (getCourseSectionCost) {
      // `section` and `session` objects are being merged in new versions of Algolia objects.
      // https://acadeum.atlassian.net/browse/CS-104
      const isNewAlgoliaObjectFormatWhereSessionIsMergedWithSection = isSection ? Boolean(section_.lastAddDate) : Boolean(course.sections);

      let section;
      let session;

      // When viewing a course section.
      if (isSection) {
        section = section_;
        // Before the new milestone is deployed, use the legacy approach
        // of reading the data from the `session` object.
        if (!isNewAlgoliaObjectFormatWhereSessionIsMergedWithSection) {
          session = section.session;
        }
      } else {
        // If viewing a "course" search result, show the cost for the first available section for it.

        if (isNewAlgoliaObjectFormatWhereSessionIsMergedWithSection) {
          // Can only enroll in a section for which an "Add/Drop" date has not yet passed.
          section = getEnrollableSectionsWhenNewAlgoliaObjectFormat(course.sections, course)[0];
          session = undefined;
        } else {
          // Can only enroll in a section for which an "Add/Drop" date has not yet passed.
          session = getEnrollableSessions(course)[0];
          if (session) {
            // There's supposed to always be at least one `section` in a `session`.
            section = session.sections[0];
          }
        }
      }

      const courseCustomCosts = {
        customCostByHomeInstitutionRelationshipId: course.customCostByHomeInstitutionRelationshipId,
        customCostByHomeConsortiumRelationshipId: course.customCostByHomeConsortiumRelationshipId
      };

      let courseSectionCustomCosts;
      if (section) {
        courseSectionCustomCosts = {
          // `section.customCosts` is a legacy property name for `section.customCostByHomeInstitutionRelationshipId`.
          customCostByHomeInstitutionRelationshipId: section.customCostByHomeInstitutionRelationshipId || section.customCosts,
          customCostByHomeConsortiumRelationshipId: section.customCostByHomeConsortiumRelationshipId
        };
      }

      if (section) {
        return getCourseSectionCost({
          section,
          session,
          course,
          courseCustomCosts,
          courseSectionCustomCosts
        });
      }
    }

    // If this course search result card is used on the
    // "Edit Student App Course Pricing" page in the Settings
    // (`src\pages\Settings\StudentPortal\EnrollingStudentCoursePricing.js`)
    // then show the cost that has been calculated based on the
    // selected pricing model.
    if (dummy) {
      return dummyCost;
    }

    // Otherwise, show the "base" course session cost,
    // without any pricing customization.
    if (isSection) {
      return section.session.cost;
    } else {
      // When viewing a course.
      // Just show the "base" `session.cost` of the first available course session.
      if (course.sessions.length > 0) {
        return course.sessions[0].cost;
      }
    }
  }

  let cost;
  if (showPrice) {
    cost = getCost();
  }

  const isConsortial = userInstitutionId
    ? isConsortialCourse(course, userInstitutionId)
    : undefined;

  const isApproved = userInstitutionId
    ? isCourseApproved(course, userInstitutionId)
    : undefined;

  const linkQuery = isHS4CC ? '?hs4cc=✓' : '';
  const link = isSection ? `/sections/${section.id}${linkQuery}` : `/courses/${course.id}${linkQuery}`;
  const headerId = `header${useId()}`;
  const seeDetailsId = `seeDetails${useId()}`;

  const courseTitle = (
    <div className="course-search-result__heading">
      <h2 id={headerId} className="course-search-result__title">
        <LinkComponent
          to={link}
          className="course-search-result__name"
          gaCategory="Courses"
          gaLabel={course.title}
          instantBack={instantBack}>
          {dummy ? 'Course Name' : `${course.code}: ${course.title}`}
        </LinkComponent>
      </h2>

      <CourseBadges
        reverse
        variant="card"
        course={course}
        isApproved={isApproved}
        isConsortial={isConsortial}
        dummy={dummy}
        dummyHours={dummyHours}
        className="course-search-result__header-badges"
      />
    </div>
  );

  const isSelected = onCourseSelectionChange && selectedCourseIds.includes(course.id);

  const enrollableSessions = isSection ? [section.session] : getEnrollableSessions(course);

  const { onDemand } = course;

  return (
    <section className={classNames('InstantSearchResult--card', 'course-search-result', className, {
      // 'course-search-result--course': !section,
      'course-search-result--with-price': showPrice,
      'course-search-result--consortial-or-approved': isConsortial || isApproved,
      'course-search-result--selected': isSelected
    })}>
      <header>
        {courseTitle}

        <CardInstitutionLogoAndName
          dummy={dummy}
          institution={course.institution}
          LinkComponent={LinkComponent}
          instantBack={instantBack}/>
      </header>

      {!isSection && enrollableSessions.length > 0 && (
        <table className="course-search-result__sessions-table">
          <tbody>
            {enrollableSessions.map((session) => (
              <tr key={session.id}>
                <td>
                  <>
                    {onDemand ? 'On-Demand' : session.term}
                  </>
                </td>
                <>
                  {!onDemand && (
                    <td>
                      {dummy && 'Dates'}
                      {!dummy &&
                                              <>
                                                {formatDate(new Date(session.startDate), {
                                                  month: 'long',
                                                  year: convertUtcDateToLocalDateWithSameTime(new Date(session.startDate)).getYear() === new Date().getYear()
                                                    ? false
                                                    : undefined,
                                                  utc: true
                                                })}
                                                {' — '}
                                                {formatDate(new Date(session.endDate), {
                                                  month: 'long',
                                                  year: convertUtcDateToLocalDateWithSameTime(new Date(session.endDate)).getYear() === new Date().getYear()
                                                    ? false
                                                    : undefined,
                                                  utc: true
                                                })}
                                              </>
                      }
                    </td>
                  )}
                </>
                {showPrice && (
                  <td>
                    {cost === 0 && 'No Charge'}
                    {cost > 0 && <Price value={cost}/>}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {enrollableSessions.length > 0 &&
        <div className={classNames('course-search-result__sessions-list', {
          'course-search-result__sessions-list--course-view': !isSection,
          'course-search-result__sessions-list--show-price': showPrice
        })}>
          {enrollableSessions.map((session) => (
            <div key={session.id} className="course-search-result__sessions-list-row">
              <>
                <p>
                  {onDemand ? 'On-Demand' : session.term}
                </p>
                {!onDemand && (
                  <p>
                    {dummy && 'Dates'}
                    {!dummy &&
                                          <>
                                            {formatDate(new Date(session.startDate), {
                                              month: 'long',
                                              year: convertUtcDateToLocalDateWithSameTime(new Date(session.startDate)).getYear() === new Date().getYear()
                                                ? false
                                                : undefined,
                                              utc: true
                                            })}
                                            {' — '}
                                            {formatDate(new Date(session.endDate), {
                                              month: 'long',
                                              year: convertUtcDateToLocalDateWithSameTime(new Date(session.endDate)).getYear() === new Date().getYear()
                                                ? false
                                                : undefined,
                                              utc: true
                                            })}
                                          </>
                    }
                  </p>
                )}
              </>
              {showPrice &&
                <p>
                  {cost === 0 && 'No Charge'}
                  {cost > 0 && <Price value={cost}/>}
                </p>
              }
            </div>
          ))}
        </div>
      }

      {enrollableSessions.length === 0 &&
        <div className="course-search-result__no-sections-available">
          No Course Sections Available
        </div>
      }

      <div className="course-search-result__actions">
        <CardSeeDetails
          id={seeDetailsId}
          ariaLabelledBy={`${seeDetailsId} ${headerId}`}
          link={link}
          openInNewTab={onCourseSelectionChange ? true : undefined}
          border={onCourseSelectionChange ? false : undefined}
          instantBack={instantBack}
          className="course-search-result__action"
          gaLabel={`See Course Details ${course.title}`}>
          See Details
        </CardSeeDetails>

        {onCourseSelectionChange && isSelected && (
          <Button
            border
            secondary
            onClick={() => onCourseSelectionChange(course, false)}>
            Remove
          </Button>
        )}

        {onCourseSelectionChange && !isSelected && (
          <Button
            onClick={() => onCourseSelectionChange(course, true)}>
            Add
          </Button>
        )}

        {!onCourseSelectionChange && userInstitutionId &&
          <CourseSearchResultButton
            dummy={dummy}
            course={course}
            section={section}
            LinkComponent={LinkComponent}
            instantBack={instantBack}
            noSectionsAvailableLabel={noSectionsAvailableLabel}
            noSectionsAvailableLink={noSectionsAvailableLink}
            className="course-search-result__action"
          />
        }
      </div>
    </section>
  );
}

CourseSearchResult.propTypes = {
  dummy: PropTypes.bool,
  dummyCost: PropTypes.number,
  dummyHours: PropTypes.number,
  course: CCPropTypes.courseAlgolia,
  section: CCPropTypes.sectionAlgolia,
  userInstitutionId: PropTypes.number,
  showPrice: PropTypes.bool,
  instantBack: PropTypes.bool,
  getCourseSectionCost: PropTypes.func,
  noSectionsAvailableLabel: PropTypes.string,
  noSectionsAvailableLink: PropTypes.string,
  onCourseSelectionChange: PropTypes.func,
  selectedCourseIds: PropTypes.arrayOf(PropTypes.number),
  className: PropTypes.string
};

function CourseSearchResultButton({
  dummy,
  course,
  section,
  LinkComponent,
  instantBack,
  noSectionsAvailableLink,
  noSectionsAvailableLabel,
  className
}) {
  // Can enroll in a course if "Add/Drop" date has not yet passed.
  const canEnroll = section ? canEnrollInSection(section, course) : canEnrollInCourse(course);

  function getButtonProps() {
    if (canEnroll || dummy) {
      if (section) {
        return {
          link: `/sections/${section.id}/enroll`,
          gaLabel: `Enroll into ${course.title}`,
          children: 'Enroll'
        };
      } else {
        return {
          link: `/courses/${course.id}`,
          gaLabel: `See sections of ${course.title}`,
          children: 'See Sections'
        };
      }
    } else {
      return {
        link: noSectionsAvailableLink ?
          noSectionsAvailableLink.replace('{id}', course.id) :
          `/courses/${course.id}`,
        gaLabel: `See Available Sections for ${course.title}`,
        children: noSectionsAvailableLabel
      };
    }
  }

  return (
    <CardButton
      {...getButtonProps()}
      LinkComponent={LinkComponent}
      instantBack={instantBack}
      className={className}
    />
  );
}

CourseSearchResultButton.propTypes = {
  dummy: PropTypes.bool,
  course: PropTypes.oneOfType([
    CCPropTypes.courseAlgolia,
    CCPropTypes.sessionCourseAlgolia
  ]).isRequired,
  section: CCPropTypes.sectionAlgolia,
  LinkComponent: PropTypes.elementType.isRequired,
  instantBack: PropTypes.bool,
  noSectionsAvailableLabel: PropTypes.string.isRequired,
  noSectionsAvailableLink: PropTypes.string,
  className: PropTypes.string
};

// const invisibleTextStyle = {
//   fontSize: 0
// };

function NonLink({ className, children }) {
  return (
    <div className={className}>
      {children}
    </div>
  );
}

NonLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

let Price = ({ value }) => {
  const formatted = formatCurrency(value);
  const digitsStartAt = formatted.search(/\d/);
  const currency = formatted.slice(0, digitsStartAt);
  const amount = formatted.slice(digitsStartAt);
  if (currency) {
    return (
      <>
        <span className="course-search-result__price__currency">
          {currency}
        </span>
        <span className="course-search-result__price__amount">
          {amount}
        </span>
      </>
    );
  }
  return (
    <span className="course-search-result__price__amount">
      {amount}
    </span>
  );
};

Price.propTypes = {
  value: PropTypes.number.isRequired
};

Price = React.memo(Price);

function CardButton({
  link,
  gaCategory = 'Buttons',
  gaLabel = 'Card Button',
  instantBack,
  className,
  children
}) {
  return (
    <Button
      link={link}
      className={className}
      gaCategory={gaCategory}
      gaLabel={gaLabel}
      instantBack={instantBack}>
      {children}
    </Button>
  );
}

CardButton.propTypes = {
  link: PropTypes.string.isRequired,
  gaCategory: PropTypes.string,
  gaLabel: PropTypes.string,
  LinkComponent: PropTypes.elementType.isRequired,
  instantBack: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

function CardInstitutionLogoAndName({
  dummy,
  instantBack,
  institution
}) {
  const LinkComponent = dummy ? NonLink : Link;
  return (
    <div className="card__institution-logo-and-name align-children-center-vertically">
      <InstitutionLink
        tabIndex={-1}
        Component={LinkComponent}
        institution={institution}
        gaLabel={`TM ${institution.name}`}
        instantBack={instantBack}
      >
        <div className="card__institution-logo-and-name__logo-container align-children-center">
          <Avatar
            size={96}
            type="institution"
            url={institution.logoUrl}
            name={`${institution.name} logo`}
            className="card__institution-logo-and-name__logo"
          />
        </div>
      </InstitutionLink>

      <div className="card__institution-logo-and-name__name-container">
        <InstitutionLink
          institution={institution}
          Component={LinkComponent}
          className="card__institution-logo-and-name__name"
          gaLabel={`TM ${institution.name}`}
          instantBack={instantBack}>
          {dummy ? 'University Name' : institution.name}
        </InstitutionLink>
      </div>
    </div>
  );
}

CardInstitutionLogoAndName.propTypes = {
  dummy: PropTypes.bool,
  instantBack: PropTypes.bool,
  institution: PropTypes.shape({
    name: PropTypes.string.isRequired,
    logoUrl: PropTypes.string.isRequired
  }).isRequired
};

// const DUMMY_LOGO_URL =
// 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mPs7OiIAwAFNAH5iP99zwAAAABJRU5ErkJggg==';

function CardSeeDetails({
  id,
  border = true,
  ariaLabelledBy,
  instantBack,
  link,
  openInNewTab,
  onClick,
  gaLabel,
  children
}) {
  return (
    <Button
      secondary
      border={border}
      id={id}
      aria-labelledby={ariaLabelledBy}
      link={link}
      openInNewTab={openInNewTab}
      onClick={onClick}
      gaLabel={gaLabel}
      instantBack={instantBack}>
      {children}
    </Button>
  );
}

CardSeeDetails.propTypes = {
  id: PropTypes.string,
  border: PropTypes.bool,
  ariaLabelledBy: PropTypes.string,
  link: PropTypes.string.isRequired,
  openInNewTab: PropTypes.bool,
  instantBack: PropTypes.bool,
  onClick: PropTypes.func,
  gaLabel: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};
