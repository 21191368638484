import defaultOnDemandDropDateDaysAfterStartDate from '../constants/onDemandCourseDropDateDaysAfterStartDate.json' assert { type: 'json' };
import validatePropertyValuesNotUndefined from './validatePropertyValuesNotUndefined.js';

import UTC_DATE_TIME_OFFSET from '../constants/utcDateTimeOffset.js';
import UTC_DATE_TIME_SPAN from '../constants/utcDateTimeSpan.js';

const ONE_DAY = 24 * 60 * 60 * 1000;

export default function getLastDateToDropFromCourseEnrollment({
  onDemand,
  onDemandDropDateDaysAfterStartDate,
  startedAt,
  lastDropDate
}) {
  // Validate parameters.
  validatePropertyValuesNotUndefined({
    onDemand,
    onDemandDropDateDaysAfterStartDate,
    startedAt,
    lastDropDate
  });

  if (onDemand) {
    // Validate that `startedAt` property is not `null`.
    // If `startedAt` is `null` then the course hasn't started yet.
    // In that case, one could drop at any time.
    if (!startedAt) {
      const ON_DEMAND_COURSE_TIMESTAMP = 99999999999000; // Wed Nov 16 5138 09:46:39
      return new Date(ON_DEMAND_COURSE_TIMESTAMP);
    }

    const daysToDrop = typeof onDemandDropDateDaysAfterStartDate === 'number'
      ? onDemandDropDateDaysAfterStartDate
      : defaultOnDemandDropDateDaysAfterStartDate;

    return new Date(startedAt.getTime() + daysToDrop * ONE_DAY);
  }

  return new Date(lastDropDate.getTime() + UTC_DATE_TIME_OFFSET + UTC_DATE_TIME_SPAN)
}
