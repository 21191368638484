import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FormFieldLabel from '../FormFieldLabel';

import './FormFieldWrapper.sass';

export default function FormFieldWrapper({
  required,
  error,
  errorBadge,
  label,
  labelHidden,
  labelInline,
  labelId,
  forInputId,
  description,
  example,
  tooltip,
  style,
  className,
  children
}) {
  return (
    <div
      style={style}
      className={classNames(className, 'form-input', {
        'form-input__label-inline': labelInline
      })}>

      {/* `htmlFor` attribute is not required when a label is wrapping the input */}
      {/* http://stackoverflow.com/questions/27826996/if-an-element-is-wrapped-by-a-label-does-the-label-require-the-for-attribute */}
      {label && !labelHidden &&
        <FormFieldLabel
          id={labelId}
          forInputId={forInputId}
          required={required}
          tooltip={tooltip}
          error={errorBadge}>
          {label}
        </FormFieldLabel>
      }

      {description &&
        <div className="form-input__description">
          {description}
        </div>
      }

      {example &&
        <div className="form-input__example">
          Example: <code>{example}</code>
        </div>
      }

      {children}

      {error &&
        <div className="form-input__error">
          {error}
        </div>
      }
    </div>
  );
}

FormFieldWrapper.propTypes = {
  required: PropTypes.bool,
  error: PropTypes.string,
  errorBadge: PropTypes.bool,
  label: PropTypes.string,
  labelHidden: PropTypes.bool,
  labelId: PropTypes.string,
  // `forInputId` is required even when `aria-labelledby` is used
  // to link the `<label/>` to the `<input/>`. The reason is that
  // clicking on a `<label/>` should focus the `<input/>`, and that
  // only works when `for` HTML attribute is set on the `<label/>`.
  //
  // `forInputId` is not present in case of:
  // * A "multi-checkbox" selection component.
  // * A `type="radio"` component.
  //
  forInputId: PropTypes.string,
  description: PropTypes.node,
  example: PropTypes.node,
  tooltip: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};
