import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getCourseSectionCostWithDetails } from '@acadeum/helpers';

import { formatCurrency } from '../../../helpers/format';
import isAcadeumAdministrator from '../../../helpers/isAcadeumAdministrator';

import Modal from '../../Modal';
import Link from '../../Link';

// import actions from '../../../actions';

// const {
//   getHomeStudentCourseEnrollmentPricing
// } = actions;

export default function SectionCostExplanationModal({
  section,
  session,
  course,
  show,
  onHide
}) {
  const user = useSelector(state => state.auth.user);

  if (!user) {
    throw new Error('SectionCostExplanationModal: Not authenticated');
  }

  if (!isAcadeumAdministrator(user)) {
    throw new Error('Unauthorized');
  }

  // const [homeStudentCourseEnrollmentPricing, setHomeStudentCourseEnrollmentPricing] = useState();
  // const [isLoading, setLoading] = useState(true);

  const homeStudentCourseEnrollmentPricing = undefined;
  const isLoading = false;

  // Don't load `homeStudentCourseEnrollmentPricing` for now because there doesn't seem to be a need to do so:
  // this is an "admin" site and not a "student" site so student-specific prices are of no relevance in this case.
  //
  const showStudentPrice = false;
  //
  // useEffect(() => {
  //   async function fetchData() {
  //     const homeStudentCourseEnrollmentPricing = await getHomeStudentCourseEnrollmentPricing();
  //     setHomeStudentCourseEnrollmentPricing(homeStudentCourseEnrollmentPricing);
  //     setLoading(false);
  //   }
  //   fetchData();
  // }, []);

  // These values could also be fetched from API in real time.
  // For example, from `/course-enrollment-pricing` or `/enrolling-student-course-pricing`.
  // For now, they're just read from the (potentially stale) cached values in `user.institution`.
  const courseEnrollmentPricing = useSelector(state => state.coursePricing.courseEnrollmentPricing);
  const courseEnrollmentPricingConsortia = useSelector(state => state.coursePricing.courseEnrollmentPricingConsortia);
  const homeInstitutionTeachingInstitutionRelationshipIds = useSelector(state => state.coursePricing.homeInstitutionTeachingInstitutionRelationshipIds);
  const homeConsortiumTeachingInstitutionRelationships = useSelector(state => state.coursePricing.homeConsortiumTeachingInstitutionRelationships);

  const courseSectionPricingInfo = useMemo(() => {
    // Validate that `session.cost` exists.
    // There's no `session.cost` for course section objects in `public-sections` Algolia index.
    // This if works around accidental development errors when a developer accidentally uses
    // `public-sections` Algolia index instead of `sections` Algolia index
    // which might happen when calling this function before `user` is authenticated.
    return session.cost && !isLoading ? getCourseSectionCostWithDetails({
      cost: session.cost,
      course: {
        credits: course.hours,
        level: course.level,
        teachingInstitutionId: course.institution.id
      },
      courseCustomCosts: {
        customCostByHomeInstitutionRelationshipId: course.customCostByHomeInstitutionRelationshipId,
        customCostByHomeConsortiumRelationshipId: course.customCostByHomeConsortiumRelationshipId
      },
      courseSectionCustomCosts: {
        // `section.customCosts` is a legacy property name for `section.customCostByHomeInstitutionRelationshipId`.
        customCostByHomeInstitutionRelationshipId: section.customCostByHomeInstitutionRelationshipId || section.customCosts,
        customCostByHomeConsortiumRelationshipId: section.customCostByHomeConsortiumRelationshipId
      },
      courseEnrollmentPricing,
      courseEnrollmentPricingConsortia,
      homeStudentCourseEnrollmentPricing,
      homeInstitutionTeachingInstitutionRelationshipIds,
      homeConsortiumTeachingInstitutionRelationships,
      homeInstitutionId: user.institution.id
    }) : undefined;
  }, [
    section,
    session,
    course,
    courseEnrollmentPricing,
    courseEnrollmentPricingConsortia,
    homeStudentCourseEnrollmentPricing,
    homeInstitutionTeachingInstitutionRelationshipIds,
    homeConsortiumTeachingInstitutionRelationships,
    user
  ]);

  const renderPricingInfo = (pricing, pricingFilters) => {
    return (
      <div>
        Pricing:
        <ul style={{ margin: 0 }}>
          <li>
            Model:
            {' '}
            <strong>
              {pricing.model}
            </strong>
          </li>
          {pricing.value !== undefined && (
            <li>
              Value:
              {' '}
              <strong>
                {pricing.value}
              </strong>
            </li>
          )}
          {pricingFilters.length > 0 && (
            <li>
              Filters:
              <ul style={{ margin: 0 }}>
                {pricingFilters.map((filter, i) => (
                  <li key={i}>
                    <strong>
                      {filter.name}
                    </strong>
                    {': '}
                    <strong>
                      {filter.name === 'teachingInstitutionId'
                        ? (
                          <Link to={`/institutions/${filter.value}`}>
                            #{filter.value}
                          </Link>
                        )
                        : filter.value
                      }
                    </strong>
                  </li>
                ))}
              </ul>
            </li>
          )}
        </ul>
      </div>
    );
  };

  const renderModalContent = () => {
    if (!courseSectionPricingInfo) {
      return null;
    }

    const {
      costForInstitution,
      costForStudent,
      isCustomCost,
      // isCustomCostAtCourseLevel,
      isCustomCostAtCourseSectionLevel,
      isOriginalCost,
      isOwnCourse,
      customCostConsortium,
      homeInstitutionPricing,
      homeInstitutionPricingFilters,
      homeInstitutionPricingConsortium,
      homeStudentPricing,
      homeStudentPricingFilters
    } = courseSectionPricingInfo;

    return (
      <>
        Default Cost:
        {' '}
        <strong>
          {formatCurrency(session.cost)}
        </strong>

        <br/>
        <br/>

        Price for this Home Institution:
        {' '}
        <strong>
          {isOriginalCost ? (isOwnCourse ? 'own course cost' : 'default cost') : formatCurrency(costForInstitution)}
        </strong>

        {homeInstitutionPricingConsortium && (
          <div>
            Pricing is defined by Consortium:
            {' '}
            <Link to={`/consortia/${homeInstitutionPricingConsortium.vanityUrl}`}>
              <strong>
                {homeInstitutionPricingConsortium.name}
              </strong>
            </Link>
          </div>
        )}

        {homeInstitutionPricing && (
          renderPricingInfo(homeInstitutionPricing, homeInstitutionPricingFilters)
        )}

        {isCustomCost && (
          <div>
            Reason: <strong>differential pricing</strong>
            {' for this '}
            <strong>
              {isCustomCostAtCourseSectionLevel ? 'section' : 'course'}
            </strong>
            {customCostConsortium
              ? (
                <>
                  {' for '}
                  <strong>
                    Consortium
                  </strong>
                  {' '}
                  <Link to={`/consortia/${customCostConsortium.vanityUrl}`}>
                    <strong>
                      {customCostConsortium.name}
                    </strong>
                  </Link>
                </>
              )
              : (
                <>
                  {' for the '}
                  <strong>
                    Home Institution
                  </strong>
                </>
              )
            }
          </div>
        )}

        {showStudentPrice && (
          <>
            <br/>
            <br/>

            Price for this Home Institution's Student:
            {' '}
            <strong>
              {typeof costForStudent === 'number' ? formatCurrency(costForStudent) : 'should be hidden'}
            </strong>

            {homeStudentPricing && (
              renderPricingInfo(homeStudentPricing, homeStudentPricingFilters)
            )}
          </>
        )}
      </>
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Section Cost Explanation"
      description="Choose a new Last Add Date for the course session">
      {/*
      // Validate that `session.cost` exists.
      // There's no `session.cost` for course section objects in `public-sections` Algolia index.
      // This if works around accidental development errors when a developer accidentally uses
      // `public-sections` Algolia index instead of `sections` Algolia index
      // which might happen when calling this function before `user` is authenticated.
      */}
      {session.cost && !isLoading ? renderModalContent() : 'Loading...'}
    </Modal>
  );
}

SectionCostExplanationModal.propTypes = {
  section: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired
};
