interface GradingScaleType {
  grade: string;
  description: string;
  numeric: string;
  points: number;
}

class GradingScale {
  grade: string;
  description: string;
  numeric: string;
  points: number;

  constructor(data) {
    this.grade = data.grade;
    this.description = data.description !== '' && data.description !== null ? data.description : '*';
    this.numeric = data.numeric !== '' ? data.numeric : '*';
    this.points = data.points;
  }
}

export class GradingScaleCollection {
  grades: GradingScaleType[] | [];

  constructor(data) {
    this.grades = this.setGrades(data);
  }

  setGrades = (grades) => {
    if (Array.isArray(grades) && grades.length > 0) {
      return grades.map(grade => new GradingScale(grade));
    }

    return [new GradingScale({
      grade: '*',
      description: '*',
      numeric: '*',
      points: '*'
    })];
  };

  onAddRow = () => {
    const copy = [ ...this.grades ];
    copy.push(new GradingScale({
      grade: '*',
      description: '*',
      numeric: '*',
      points: '*'
    }));
    this.grades = copy;
  };

  onRemoveRow = (index) => {
    const updated = [ ...this.grades.slice(0, index), ...this.grades.slice(index + 1)];
    this.grades = updated;
  };

  onRemove = () => {
    this.grades = [new GradingScale({
      grade: '*',
      description: '*',
      numeric: '*',
      points: '*'
    })];
  };

  onSave = (index, column, value) => {
    let updated;
    if (index >= 0 && column) {
      updated = this.grades.map((item, i) => {
        if (i === index) {
          return Object.assign({}, item, {[column]: value !== null ? value : ''});
        }

        return item;
      });

      this.grades = updated;
    }
  };
}
