export function isNumberAssert(value: unknown, errorMessage?: string): asserts value is number {
  if (typeof value !== 'number') {
    throw new Error(errorMessage);
  }
}

export function isStringAssert(value: unknown, errorMessage?: string): asserts value is string {
  if (typeof value !== 'string') {
    throw new Error(errorMessage);
  }
}
