import { Accordion, Text } from '@acadeum/ui';

export function CourseLocationSection({
  course
}) {
  const hasCourseLocation = Boolean(course.location);

  // TODO: Add location to course
  return (
    <Accordion title="Course Location" defaultOpen={hasCourseLocation}>
      <Text color="grey">
        {course.location || 'No course location have been added to this course yet.'}
      </Text>
    </Accordion>
  );
}
