import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const runJob = redux.action(
  (name, parameters) => http => http.post('/job', {
    name,
    parameters
  })
);

export const getJobStatus = redux.action(
  (id) => http => http.get(`/job/${id}`)
);

export default redux.reducer();
