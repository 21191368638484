import {
  detectLanguage as detectLanguage_,
  getTranslationLanguage as getTranslationLanguage_
} from '@acadeum/translate';

export const DEFAULT_LANGUAGE = 'en';

export function detectLanguage() {
  return detectLanguage_(DEFAULT_LANGUAGE);
}

export function getTranslationLanguage() {
  return getTranslationLanguage_(DEFAULT_LANGUAGE);
}
