import React, { memo } from 'react';
import classNames from 'classnames';
import clamp from 'lodash/clamp';

import type {HSVA} from '../../types';

import type { InteractionPosition} from '../Interactive';
import { Interactive } from '../Interactive';
import { Pointer } from '../Pointer';

import styles from './Hue.module.scss';

interface HueProps {
  className?: string;
  hsva: HSVA;
  onChange: (newHue: { h: number }) => void;
}

export const Hue = memo<HueProps>(({ className, hsva, onChange }) => {
  const handleMove = (interaction: InteractionPosition) => {
    onChange({ h: 360 * interaction.left });
  };

  const handleKey = (offset: InteractionPosition) => {
    onChange({
      h: clamp(hsva.h + offset.left * 360, 0, 360)
    });
  };

  return (
    <div className={classNames(className, styles.Hue)}>
      <Interactive
        onMove={handleMove}
        onKey={handleKey}
        aria-label="Hue"
        aria-valuenow={Math.round(hsva.h)}
        aria-valuemax="360"
        aria-valuemin="0"
      >
        <Pointer
          className={styles.Pointer}
          left={hsva.h / 360}
        />
      </Interactive>
    </div>
  );
});
