import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Select } from 'react-responsive-ui';

import { LoadingIndicator } from '../../components/LoadingIndicator';
import Table from '../../components/Table';
import InstitutionLink from '../../components/InstitutionLink';
import Email from '../../components/Email';
import Link from '../../components/Link';
import RelativeDateFormat from '../../components/RelativeDateFormat';
import Section from '../../components/Section';
import AdminUserActions from '../../components/AdminUserActions';
import ListPageResultsLabel from '../../components/ListPageResultsLabel';

import useSearchBarAndResultsPerPageAndSortByAndPagination
  from '../../hooks/useSearchBarAndResultsPerPageAndSortByAndPagination';

import { formatName, formatUserName } from '../../helpers/format';

import actions from '../../actions';
import './AdminStudentUsers.sass';

const {
  adminFetchStudentUsers,
  fetchUsersAdmin
} = actions;

export default function AdminUsersPage() {
  const [students, setStudents] = useState(true);

  const { users: adminUsers } = useSelector(state => state.usersAdmin);
  const { studentUsers } = useSelector(state => state.studentUsersAdmin);

  const data = students ? studentUsers : adminUsers;

  const totalCount = data && data.totalCount;
  const users = data && data.results;

  const dataTypeSelect = (
    <Select
      alignment="right"
      value={students ? 'studentUsers' : 'users'}
      options={[{
        value: 'studentUsers',
        label: 'Student App'
      }, {
        value: 'users',
        label: 'Admin App'
      }]}
      onChange={(value) => {
        if (value === 'studentUsers') {
          setStudents(true);
        } else {
          setStudents(false);
        }
      }}
    />
  );

  return (
    <UsersList
      key={students ? 'studentUsers' : 'users'}
      users={users}
      totalCount={totalCount}
      students={students}
      dataTypeSelect={dataTypeSelect}
    />
  );
}

AdminUsersPage.meta = () => ({
  title: 'User Accounts'
});

AdminUsersPage.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'User Accounts'
];

function UsersList({
  users,
  totalCount,
  students,
  dataTypeSelect
}) {
  const {
    renderSearchBarAndResultsPerPageAndSortBy,
    renderPagination,
    loading,
    error,
    hasLoadedInitially,
    refresh
  } = useSearchBarAndResultsPerPageAndSortByAndPagination({
    fetchData: students ? adminFetchStudentUsers : fetchUsersAdmin,
    totalCount,
    sortingOptions: [
      {
        value: 'latest-login-date-desc',
        label: 'Most Recent Login'
      }
    ],
    defaultSorting: 'latest-login-date-desc'
  });

  const columns = useMemo(() => {
    const columns = [
      {
        title: 'Name',
        content: (row) => (
          <>
            <Link
              to={`/admin/${students ? 'student-' : ''}users/${row.id}`}>
              {students ? formatName(row) : formatUserName(row)}
            </Link>
            {row.emailVerified &&
              <VerifiedIcon
                title="Verified Account"
                className="AdminStudentUsers-verifiedIcon"
              />
            }
            {!row.isActive &&
              <InactiveIcon
                title="Inactive Account"
                className="AdminStudentUsers-inactiveIcon"
              />
            }
          </>
        )
      },
      {
        title: 'Email',
        content: (row) => (
          <Email address={row.email}/>
        )
      },
      {
        title: 'Home Institution',
        content: (row) => (
          <InstitutionLink target="_blank">
            {row.institution}
          </InstitutionLink>
        )
      },
      {
        title: 'Latest Login',
        content: (row) => {
          if (row.latestLoginDate) {
            return <RelativeDateFormat date={row.latestLoginDate}/>;
          }
          return <span style={{ opacity: 0.5, fontWeight: 'lighter' }}>—</span>;
        }
      },
      {
        content: (row) => (
          <AdminUserActions
            seeDetails
            user={row}
            student={students}
            refresh={refresh}
          />
        )
      }
    ];

    return columns;
  }, [
    students,
    refresh
  ]);

  return (
    <Section title={(
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          User Accounts
        </div>

        <div className="AdminStudentUsers-environmentSelectorContainer">
          Environment
          {dataTypeSelect}
        </div>
      </div>
    )}>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <ListPageResultsLabel
          error={error}
          loading={loading}
          totalCount={totalCount}
          hasLoadedInitially={hasLoadedInitially}
          singularLabel="User"
          pluralLabel="Users"
        />

        {renderSearchBarAndResultsPerPageAndSortBy()}
      </div>

      <br/>

      {loading &&
        <LoadingIndicator show/>
      }

      {hasLoadedInitially && !error && totalCount > 0 &&
        <>
          <Table
            data={users}
            columns={columns}
            selectableRows={false}
            showTotalResultsCount={false}
          />

          {renderPagination()}
        </>
      }
    </Section>
  );
}

UsersList.propTypes = {
  dataTypeSelect: PropTypes.node.isRequired,
  students: PropTypes.bool,
  users: PropTypes.arrayOf(PropTypes.object),
  totalCount: PropTypes.number
};

// Copy-pasted from an `*.svg` file because of adding `<title/>`.
function VerifiedIcon({ title, ...rest }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" {...rest}>
      <title>{title}</title>
      <path fill="none" stroke="currentColor" strokeWidth="10" d="M133.8,8l-5.8,3.9c-12.6,10.7-14.5,10.7-26.2,3.9C89.2,7,85.3,8,79.5,22.5c-5.8,11.6-7.7,12.6-22.3,11.6
        c-13.6-1.9-16.5,0-16.5,14.5c0,15.5-1,16.5-14.5,21.3c-8.7,2.9-11.6,4.8-11.6,10.7v3.9l3.9,6.8l3.9,7.7v2.9c0,4.8-1,5.8-7.7,12.6
        C4,126.1,4,130,16.6,138.7c10.7,7.7,11.6,10.7,6.8,23.2c-4.8,12.6-2.9,17.4,8.7,19.4l6.8,1c9.7,1.9,11.6,4.8,12.6,17.4
        c1.9,13.6,5.8,17.4,16.5,14.5c10.7-3.9,12.6-3.9,16.5-2.9c2.9,1,5.8,4.8,8.7,9.7c8.7,14.5,11.6,14.5,26.2,1.9
        c6.8-5.8,11.6-5.8,20.3,0c13.6,7.7,17.4,6.8,23.2-4.8c4.8-13.6,7.7-15.5,22.3-13.6c8.7,1,11.6,0,14.5-3.9l1-1.9v-9.7
        c0-14.5,1-16.5,14.5-20.3c13.6-4.8,14.5-8.7,7.7-20.3c-6.8-12.6-5.8-15.5,3.9-25.2c9.7-10.7,9.7-14.5-1.9-22.3
        c-10.7-8.7-11.6-10.7-6.8-26.2c4.8-11.6,2.9-14.5-11.6-17.4c-12.6-2.9-15.5-4.8-16.5-17.4c-1.9-14.5-4.8-17.4-17.4-13.6
        c-14.5,3.9-16.5,2.9-25.2-11.6C142.5,8,138.6,5.1,133.8,8"/>
      <path fill="currentColor" d="M169.8,74.8c-1.8,0.9-1.7,0-28.7,28l-34.9,34.1c-2.6,2.3-0.9,2.9-17.2-12.3c-13.1-13.1-14.6-12.9-18.2-9.4
        c-3.4,3.3-2.4,5,0.5,8.4c2.3,2.7,28.9,26.7,31.4,28.8c3.7,2.8,2.1,3.8,27.7-23l34.4-34.9c14-14,14.1-14.6,10.4-18.4
        C172.9,73.8,171.6,74.2,169.8,74.8"/>
    </svg>
  );
}

VerifiedIcon.propTypes = {
  title: PropTypes.string.isRequired
};

// Copy-pasted from an `*.svg` file because of adding `<title/>`.
function InactiveIcon({ title, ...rest }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...rest}>
      <title>{title}</title>
      <line stroke="currentColor" strokeWidth="5" x1="2" y1="2" x2="98" y2="98"/>
      <line stroke="currentColor" strokeWidth="5" x1="2" y1="98" x2="98" y2="2"/>
    </svg>
  );
}

InactiveIcon.propTypes = {
  title: PropTypes.string.isRequired
};
