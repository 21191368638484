import type { HSLA, HSVA, RGBA } from '../types';

export const hsvaToHsla = ({ h, s, v, a }: HSVA): HSLA => {
  const hh = ((200 - s) * v) / 100;

  return {
    h: Math.round(h),
    s: Math.round(hh > 0 && hh < 200 ? ((s * v) / 100 / (hh <= 100 ? hh : 200 - hh)) * 100 : 0),
    l: Math.round(hh / 2),
    a: Math.round(Math.pow(10, 2) * a) / Math.pow(10, 2)
  };
};

export const hsvaToHslString = (hsva: HSVA): string => {
  const { h, s, l } = hsvaToHsla(hsva);
  return `hsl(${h}, ${s}%, ${l}%)`;
};

export const hsvaToHslaString = (hsva: HSVA): string => {
  const { h, s, l, a } = hsvaToHsla(hsva);
  return `hsla(${h}, ${s}%, ${l}%, ${a})`;
};

const format = (number: number) => {
  const hex = number.toString(16);
  return hex.length < 2 ? '0' + hex : hex;
};

export const rgbaToHex = ({ r, g, b, a }: RGBA): string => {
  const alphaHex = a < 1 ? format(Math.round(a * 255)) : '';
  return '#' + format(r) + format(g) + format(b) + alphaHex;
};
