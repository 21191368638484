import React, { useEffect, useState } from 'react';
import { Select } from 'react-responsive-ui';
import { useSelector } from 'react-redux';
import { Line } from 'react-chartjs-2';
import { sortBy, uniq } from 'lodash-es';
import Iframe from 'react-iframe';

import '../../helpers/initializeCharts';
import actions from '../../actions';
import { getGraphOptions } from '../Reports/helpers';

const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'Septmber', 'October', 'November', 'December'];

const {
  fetchConsortiaMonthlyEnrollments,
  fetchChartConsortiaToken
} = actions;

export default function ConsortiaAnalytics() {
  const monthlyEnrollmentsReport = useSelector(state => state.reports.consortiaMonthlyEnrollments);
  const chartToken = useSelector(state => state.reports.chartConsortiaToken);

  const [height, setHeight] = useState(1200);
  const [institutionId, setInstitutionId] = useState();

  useEffect(() => {
    const listener = (message) => {
      if (message.origin !== 'https://embed.chartio.com') {
        return;
      }
      if (message.data && message.data.height) {
        setHeight(message.data.height);
      }
    };
    window.addEventListener('message', listener);
    () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  const onSelectInstitution = (institutionId) => {
    setInstitutionId(institutions);
    fetchConsortiaMonthlyEnrollments(institutionId);
  };

  if (!monthlyEnrollmentsReport) {
    return null;
  }

  const monthlyEnrollments = Object.values(monthlyEnrollmentsReport[0]);
  const institutions = Object.values(monthlyEnrollmentsReport[1]);

  const now = new Date();
  const currentYear = (now).getFullYear();
  const currentMonth = (now).getMonth() + 1;

  const years = uniq(monthlyEnrollments.map(_ => _.year)).sort().reverse();

  const datasets = years.map(year => {
    const data = sortBy(monthlyEnrollments.filter(_ => _.year === year), 'month');
    const returnData = [];
    for (let i = 1; i <= 12; i++) {
      const d = data.filter(_ => _.month === i)[0];
      if (d) {
        returnData.push(d.count);
      } else if (year === currentYear && i > currentMonth) {
        returnData.push(null);
      }
      else {
        returnData.push(0);
      }
    }
    return getGraphOptions(year, returnData);
  });

  const data = {
    labels: MONTHS,
    datasets
  };

  const datasetsSum = years.map(year => {
    const data = sortBy(monthlyEnrollments.filter(_ => _.year === year), 'month');
    const returnData = [];
    let sum = 0;
    for (let i = 1; i <= 12; i++) {
      const d = data.filter(_ => _.month === i)[0];
      if (d) {
        sum += d.count;
      }
      if (year === currentYear && i > currentMonth) {
        returnData.push(null);
      } else {
        returnData.push(sum);
      }
    }
    return getGraphOptions(year, returnData);
  });

  const dataSum = {
    labels: MONTHS,
    datasets: datasetsSum
  };

  // const datasetsDerivative = years.map(year => {
  //   const data = sortBy(monthlyEnrollments.filter(_ => _.year === year), 'month');
  //   const returnData = [];
  //   let pastChange = 0;
  //   for (let i = 1; i <= 12; i++) {
  //     const d = data.filter(_ => _.month === i)[0];
  //     if (d) {
  //       returnData.push(d.count-pastChange);
  //       pastChange = d.count;
  //     } else if (year === currentYear && i > currentMonth) {
  //       returnData.push(null);
  //       pastChange = 0;
  //     }
  //     else {
  //       returnData.push(0);
  //       pastChange = 0;
  //     }
  //   }
  //   return getGraphOptions(year, returnData);
  // });

  // const dataDerivative = {
  //   labels: MONTHS,
  //   datasets: datasetsDerivative
  // };

  return (
    <div>
      <h1>Enrolling Institution</h1>

      <Select
        options={[{ label: 'All' }].concat(institutions.map((institution) => ({
          value: institution.institutionId,
          label: institution.name
        })))}
        onChange={onSelectInstitution}
        value={institutionId}
      />

      <br/>

      <h1>Monthly Enrollment Activity: Cumulative</h1>

      <Line data={dataSum} />

      <h1>Monthly Enrollment Activity: Monthly</h1>

      <Line data={data} />

      {chartToken &&
        <Iframe url={`https://embed.chartio.com/d/acadeum/client-consortia/?embed_token=${chartToken}`}
          width="100%"
          height={height + 'px'}
          frameBorder="0"
          id="chartio-embed-iframe"
          display="initial"
          position="relative" />
      }
    </div>
  );
}

ConsortiaAnalytics.meta = () => ({
  title: 'Consortia Analytics'
});

ConsortiaAnalytics.load = async () => {
  await fetchConsortiaMonthlyEnrollments();
  await fetchChartConsortiaToken();
};

ConsortiaAnalytics.breadcrumbs = () => ['Consortium Analytics'];
