import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

// This action is separate and not imported into redux/index.js
// to avoid redux state changes and re-rendering the Course Search component,
// which leads to filter resets as a result of re-rendering.
// https://github.com/Acadeum/Tickets/issues/977
export const exportCourseSearchResults = redux.action(
  ({ mode, returnResult, searchParameters }) => (http) => {
    return http.post(`/courses/search/export?mode=${mode}&returnResult=${returnResult ? 1 : 0}`, searchParameters);
  }
);

export default redux.reducer();
