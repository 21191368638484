// Copy-pasted from `react-instantsearch`.
// https://github.com/algolia/react-instantsearch/blob/81a6f1856ae28b4ab00ba660c548037818749220/packages/react-instantsearch-dom/src/components/Select.js
// v 5.5
//
// Changes:
//
// * Added `ariaLabel` property: `aria-label={ariaLabel}`.
// * Replaced `import { has } from 'lodash'` with `import has from 'lodash/has'`.

import has from 'lodash/has';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Select extends Component {
  static propTypes = {
    cx: PropTypes.func.isRequired,
    ariaLabel: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,

        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
        disabled: PropTypes.bool
      })
    ).isRequired,
    selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired
  };

  onChange = e => {
    this.props.onSelect(e.target.value);
  };

  render() {
    const { cx, ariaLabel, items, selectedItem } = this.props;

    return (
      <select
        className={cx('select')}
        aria-label={ariaLabel}
        value={selectedItem}
        onChange={this.onChange}
      >
        {items.map(item => (
          <option
            className={cx('option')}
            key={has(item, 'key') ? item.key : item.value}
            disabled={item.disabled}
            value={item.value}
          >
            {has(item, 'label') ? item.label : item.value}
          </option>
        ))}
      </select>
    );
  }
}
