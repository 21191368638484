import { Integer, URL } from 'read-excel-file';

import courseLevels from 'common-lib/constants/courseLevels.json';
import courseDeliveryMethods from 'common-lib/constants/courseDeliveryMethods.json';

import { validateSessionName, validateCCEraDate, validateTermName } from './validate';

export const COURSE_SCHEMA = {
  'INSTITUTION ID': {
    prop: 'institutionId',
    type: Integer,
    required: true
  },
  'COURSE ID': {
    prop: 'code',
    type: String,
    required: true
  },
  'COURSE TITLE': {
    prop: 'title',
    type: String,
    required: true
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'CATEGORY 1': {
    prop: 'c1',
    type: String,
    required: true
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'CATEGORY 2': {
    prop: 'c2',
    type: String
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'CATEGORY 3': {
    prop: 'c3',
    type: String
  },
  'FACULTY 1 NAME': {
    prop: 'f1Name',
    type: String
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'FACULTY 2 NAME': {
    prop: 'f2Name',
    type: String
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'FACULTY 3 NAME': {
    prop: 'f3Name',
    type: String
  },
  'FACULTY 4 NAME': {
    prop: 'f4Name',
    type: String
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'FACULTY 5 NAME': {
    prop: 'f5Name',
    type: String
  },
  'FACULTY 1 URL': {
    prop: 'f1Url',
    type: String
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'FACULTY 2 URL': {
    prop: 'f2Url',
    type: String
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'FACULTY 3 URL': {
    prop: 'f3Url',
    type: String
  },
  'FACULTY 4 URL': {
    prop: 'f4Url',
    type: String
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'FACULTY 5 URL': {
    prop: 'f5Url',
    type: String
  },
  'ASSESSMENT 1 NAME': {
    prop: 'a1Name',
    type: String
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'ASSESSMENT 2 NAME': {
    prop: 'a2Name',
    type: String
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'ASSESSMENT 3 NAME': {
    prop: 'a3Name',
    type: String
  },
  'ASSESSMENT 1 URL': {
    prop: 'a1Url',
    type: String
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'ASSESSMENT 2 URL': {
    prop: 'a2Url',
    type: String
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'ASSESSMENT 3 URL': {
    prop: 'a3Url',
    type: String
  },
  'COURSE DESCRIPTION': {
    prop: 'description',
    parse(value) {
      if (!value) {
        return null;
      }
      return value.replace(/\\n/g, '\n');
    },
    required: true
  },
  'COURSE LEVEL': {
    prop: 'level',
    type: String,
    required: true,
    oneOf: courseLevels
  },
  'CREDITS': {
    prop: 'hours',
    type: Number,
    required: true,
    validate: (value) => {
      if (value < 0) {
        throw new Error('Credit hours must be a non-negative number');
      }
    }
  },
  'SYLLABUS': {
    prop: 'pathToSyllabus',
    type: URL,
    required: true
  },
  'PRE-REQUISITES': {
    prop: 'prerequisiteText',
    parse(value) {
      if (value === 'None') {
        return null;
      }
      return value;
    }
  },
  'COURSE MATERIAL ISBNS': {
    prop: 'courseMaterialISBNs',
    type: String
  },
  'COURSE MATERIAL TITLES': {
    prop: 'courseMaterialTitles',
    type: String
  },
  'COURSE LOCATION': {
    prop: 'location',
    type: String
  },
  'COURSE DELIVERY METHOD': {
    prop: 'deliveryMethod',
    type: String,
    oneOf: courseDeliveryMethods
  },
  'ADDITIONAL COSTS': {
    prop: 'additionalCosts',
    type: Boolean
  },
  'TEXTBOOK COST': {
    prop: 'hasTextbookCost',
    type: Boolean
  },
  'PREREQUISITES': {
    prop: 'prerequisiteText',
    type: String
  },
  'CERTIFICATES': {
    prop: 'certificates',
    type: String
  },
  'NOTES': {
    prop: 'notes',
    type: String
  },
  'COURSE COST': {
    prop: 'cost',
    required: true,
    type: Number
  },
  'ON-DEMAND COURSE': {
    prop: 'onDemand',
    type: Boolean
  }
};

export const SESSION_SCHEMA = {
  'START DATE': {
    prop: 'startDate',
    type: Date,
    validate: validateCCEraDate
  },
  'END DATE': {
    prop: 'endDate',
    type: Date,
    validate: validateCCEraDate
  },
  'ADD DATE': {
    prop: 'lastAddDate',
    type: Date,
    validate: validateCCEraDate
  },
  'DROP DATE': {
    prop: 'lastDropDate',
    type: Date,
    validate: validateCCEraDate
  },
  'TERM': {
    prop: 'term',
    type: String,
    validate: validateTermName
  },
  'INTERNAL TERM': {
    prop: 'internalTerm',
    type: String
  },
  'SESSION': {
    prop: 'name',
    type: String,
    validate: validateSessionName
  },
  'INTERNAL SESSION': {
    prop: 'internalName',
    type: String
  },
  'COST': {
    prop: 'cost',
    type: Number
  }
};

export const SECTION_SCHEMA = {
  'SECTION': {
    prop: 'number',
    type: String,
    required: true
  },
  'AVAILABLE SEATS': {
    prop: 'availableSeats',
    type: Integer
  },
  'ACTIVE': {
    prop: 'isActive',
    type: Boolean
  },
  'DATE & TIME': {
    prop: 'schedule',
    parse(value) {
      if (!value) {
        return null;
      }
      if (value.toLowerCase() === 'asynchronous') {
        return null;
      }
      return value;
    }
  },
  'INSTRUCTOR FIRST NAME': {
    prop: 'instructorFirstName',
    type: String
  },
  'INSTRUCTOR MIDDLE NAME': {
    prop: 'instructorMiddleName',
    type: String
  },
  'INSTRUCTOR LAST NAME': {
    prop: 'instructorLastName',
    type: String
  }
};

export const COURSE_UPLOAD_SCHEMA = {
  ...COURSE_SCHEMA,
  '<SESSION>': {
    prop: 'session',
    type: {
      ...SESSION_SCHEMA,
      '<SECTION>': {
        prop: 'section',
        type: SECTION_SCHEMA
      }
    }
  }
};

export function transformCourseUploadData(rows) {
  return rows.map(row => {
    // Set `category` property.
    //
    // "category" is a concatenation of "category 1", "category 2", "category 3".
    //
    // The property is historically called `category` because in the `courses` SQL table
    // the column is incorrectly called `category` instead of `categories`.
    //
    row.category = [row.c1, row.c2, row.c3].filter(_ => _).join(', ');
    delete row.c1;
    delete row.c2;
    delete row.c3;

    // Invert `zeroTextbookCost` property.
    row.zeroTextbookCost = invertBoolean(row.hasTextbookCost);
    delete row.hasTextbookCost;

    // Set `resources` property.

    row.resources = [];

    const facultyCredentialsAndLearningAssessments = [
      'f1',
      'f2',
      'f3',
      'f4',
      'f5',
      'a1',
      'a2',
      'a3'
    ];

    facultyCredentialsAndLearningAssessments.map((prefix) => {
      if (row[prefix + 'Name'] && row[prefix + 'Url']) {
        row.resources.push({
          name: row[prefix + 'Name'],
          url: row[prefix + 'Url'],
          type: prefix.startsWith('f') ? 'FACULTY_CREDENTIAL' : 'LEARNING_ASSESSMENT'
        });
      }
      delete row[prefix + 'Name'];
      delete row[prefix + 'Url'];
    });

    // Set `session.cost` from `course.cost` because there's only one "COST" column.
    if (row.session && (row.session.cost === undefined || row.session.cost === null)) {
      row.session.cost = row.cost;
    }
    return row;
  });
}

function invertBoolean(value) {
  if (value === true) {
    return false;
  }
  if (value === false) {
    return true;
  }
  return value;
}
