import { useMemo } from 'react';

import { useTranslate } from './useTranslate';

/**
 * Returns an object with translated labels for the keys.
 *
 * @param {string} path
 * @param {string[]} keys
 * @return {object} Translated labels for the keys.
 */
export function useTranslateDictionary<Key extends string>(path: string, keys: ReadonlyArray<Key>) {
  const t = useTranslate();
  return useMemo(() => {
    return keys.reduce((translatedKeys, key) => {
      translatedKeys[key] = t(path + '.' + key);
      return translatedKeys;
    }, {} as Record<Key, string>);
  }, [t, path, keys]);
}
