export enum Mode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export const ONLY_FOR_TEST_defaultValues = {
  resources: [{}],
  code: 'ACCT 201',
  title: 'Intermediate Accounting',
  description: 'Continuation of ACCT 2311',
  level: 'Undergraduate',
  hours: 3,
  pathToSyllabus: 'https://www.acadeum.com',
  category: ['Business'],
  cost: 100,
  zeroTextbookCost: true,
  additionalCosts: false,
  session: {
    lastDropDate: new
    Date('2020-08-24'), lastAddDate: new Date('2020-08-24'), name: 'Fall 2020', startDate: new Date('2020-08-24'),
    endDate: new Date('2020-12-12'), term: 'Fall', section: { number: 'ACS1' }
  }
};
