import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const showSignInModal = redux.simpleAction(
  (state) => ({
    ...state,
    isOpen: true
  })
);

export const hideSignInModal = redux.simpleAction(
  (state) => ({
    ...state,
    isOpen: false
  })
);

export default redux.reducer({
  isOpen: false
});
