import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './FormDescription.scss';

export default function FormDescription ({ children, className }) {
  return (
    <p className={classNames('FormDescription', className)}>
      {children}
    </p>
  );
}

FormDescription.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};
