// This function should be the same as the one in `ASP-API` repo.
export default function isCourseSessionScheduled(session, schedule) {
  // `schedule` format:
  // [{
  //   term: 'Term 1',
  //   sessions: ['Session 1', 'Session 2', ...]
  // }, {
  //   term: 'Term 2'
  //   // Missing `sessions` means all sessions are scheduled.
  // }]
  // Missing `schedule` means all terms/sessions are scheduled.
  const termName = stripYearFromTerm(session.term);
  const termIndex = schedule.findIndex(termData => termData.term === termName);
  if (termIndex < 0) {
    return false;
  }
  const termData = schedule[termIndex];
  const termSessions = termData.sessions;
  return !termSessions || termSessions.includes(session.name);
}

function stripYearFromTerm(term) {
  return term.replace(/ \d{4}$/, '');
}
