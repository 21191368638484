import React, { memo } from 'react';

import { ISO_date_matcher as ISO_DATE_MATCHER } from '@acadeum/helpers';

import type { FormatDateProps } from '../../../FormatDate';
import { FormatDate } from '../../../FormatDate';

export interface DateBlockProps extends Pick<FormatDateProps, 'month' | 'day' | 'utc' | 'time'> {
  date: string | number | Date | [(Date | number), (Date | number)];
  withTime?: boolean;
}

export const DateBlock: React.FC<DateBlockProps> = memo(({
  date,
  withTime,
  ...rest
}) => {
  if (Array.isArray(date)) {
    return (
      <>
        <FormatDate
          date={convertToDate(date[0])}
          time={Boolean(withTime)}
          {...rest}
        />
        {' — '}
        <FormatDate
          date={convertToDate(date[1])}
          time={Boolean(withTime)}
          {...rest}
        />
      </>
    );
  }

  return (
    <FormatDate
      date={convertToDate(date)}
      time={Boolean(withTime)}
      {...rest}
    />
  );
});

function convertToDate(date) {
  // Convert a number (timestamp) to a `Date`.
  if (typeof date === 'number') {
    return new Date(date);
  }
  // Convert a ISO string format to a `Date`.
  if (typeof date === 'string' && ISO_DATE_MATCHER.test(date)) {
    return new Date(date);
  }
  return date;
}
