import React from 'react';
import { useController } from 'react-hook-form';

import { CurrencyInput } from '../../../CurrencyInput';

export const FormCurrencyInput = ({
  name,
  rules,
  defaultValue,
  currency,
  ...rest
}) => {
  const { field } = useController({ name, rules, defaultValue });

  return (
    <CurrencyInput
      {...rest}
      {...field}
      currency={currency}
      defaultValue={field.value}
    />
  );
};
