import type { InferProps } from 'prop-types';
import PropTypes from 'prop-types';

export const propTypes = {
  height: PropTypes.number,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string
};

export type JSONEditorProps = InferProps<typeof propTypes>

export interface JSONEditorRef {
  focus: () => void;
  getDOMNode: () => HTMLDivElement
}
