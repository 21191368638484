import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { useSettingsRoutes } from '@acadeum/shared-admin-ui';

import UserForm from './UserForm';
import ConfirmActionModal from '../../components/ConfirmActionModal';
import FormHeading from '../../components/FormHeading';
import { getRoleIdsFromRoleFormValues } from '../../components/RoleFormField';
import { waitForModalToClose } from '../../components/Modal';

import { formatUserName } from '../../helpers/format';
import isAcadeumAdministrator from '../../helpers/isAcadeumAdministrator';
import getErrorData from '../../helpers/getErrorData';

import actions from '../../actions';

const {
  updateUser,
  goto,
  notify,
  notifyError
} = actions;

export default function EditUser_() {
  const { user, userRoles } = useSelector(state => state.users);
  const { user: currentUser } = useSelector(state => state.auth);

  const { getUserDetailsUrl, getUserManagementUrl } = useSettingsRoutes();

  if (!user || !userRoles) {
    return null;
  }

  return (
    <EditUser
      user={user}
      userRoles={userRoles}
      currentUser={currentUser}
      updateUser={updateUser}
      redirectTo={getUserDetailsUrl('{0}')}
      cancelLink={getUserManagementUrl()}
    />
  );
}

export function EditUser({
  user,
  userRoles,
  adminPanel,
  currentUser,
  updateUser,
  redirectTo,
  cancelLink
}) {
  const [submitFormValues, setSubmitFormValues] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState();
  const [confirmModalText, setConfirmModalText] = useState();

  const getConfirmModalText = (fromRoles, toRoles) => {
    const getRolesText = (roles) => {
      if (roles.length === 0) {
        return 'none';
      }
      return `"${roles.map(_ => _.name).join('", "')}"`;
    };
    return `Please confirm that you would like to change ${formatUserName(user)}'s role from ${getRolesText(fromRoles)} to ${getRolesText(toRoles)}.`;
  };

  const updateUser_ = async (values) => {
    try {
      await updateUser(user.id, values);
    }
    catch (error) {
      if (getErrorData(error).status === 409 || getErrorData(error).code === 'email_occupied') {
        return notifyError('A user with this email address already exists.');
      }
      throw error;
    }
  };

  const onAfterUpdateUser = () => {
    goto(redirectTo.replace('{0}', user.id));
    notify('User has been updated!');
  };

  const onUpdateUser = async (values) => {
    const roleIds = getRoleIdsFromRoleFormValues(values);
    if (roleIds.length < 1) {
      return notifyError('You must choose at least one role');
    }
    // Check if user's roles have changed.
    const prevRoles = user.roles;
    const roles = userRoles.filter(role => roleIds.includes(role.id));
    // Sort role lists.
    roleIds.sort((a, b) => a.id - b.id);
    prevRoles.sort((a, b) => a.id - b.id);
    // Set `roleIds` in `values`.
    values.roleIds = roleIds;
    // If user's roles have changed then show the confirmation modal first,
    // and only after the user approves the new roles of the user, update the user.
    if (!isEqual(roleIds, prevRoles.map(_ => _.id))) {
      setSubmitFormValues(values);
      setConfirmModalText(getConfirmModalText(prevRoles, roles));
      setShowConfirmModal(true);
      return;
    }
    // If user's roles haven't changed then update the user without showing
    // any confirmation modal.
    await updateUser_(values);
    onAfterUpdateUser();
  };

  const onProceedWithUpdatingUser = async () => {
    await updateUser_(submitFormValues);
  };

  const onAfterProceedWithUpdatingUser = async () => {
    await waitForModalToClose();
    onAfterUpdateUser();
  };

  return (
    <section>
      <FormHeading>
        Edit User
      </FormHeading>

      <UserForm
        autoFocus
        userRoles={userRoles}
        adminPanel={adminPanel}
        onSubmit={onUpdateUser}
        user={user}
        superUser={isAcadeumAdministrator(currentUser)}
        submitText="Save changes"
        cancelLink={cancelLink.replace('{0}', user.id)}
      />

      {/* Confirm changing user's roles modal. */}
      <ConfirmActionModal
        title="Confirm changing user's role"
        description={confirmModalText}
        submitText="Confirm"
        onSubmit={onProceedWithUpdatingUser}
        onAfterSubmit={onAfterProceedWithUpdatingUser}
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
      />
    </section>
  );
}

EditUser.propTypes = {
  user: PropTypes.object.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  adminPanel: PropTypes.bool,
  currentUser: PropTypes.object,
  cancelLink: PropTypes.string.isRequired,
  updateUser: PropTypes.func.isRequired,
  redirectTo: PropTypes.string.isRequired
};
