import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { Checkbox } from '../../../Checkbox';

export const FormCheckboxGroup = ({
  id,
  label,
  name,
  defaultValue,
  rules,
  options,
  ...rest
}) => {
  const { register } = useFormContext();

  return (
    <div
      role="group"
      aria-labelledby={id}
      aria-label={label}
    >
      {options.map((option, index) => (
        <Checkbox
          {...rest}
          {...option}
          key={index}
          required={false}
          defaultChecked={defaultValue ? defaultValue.includes(option.value) : undefined}
          {...register(name, rules)}
        />
      ))}
    </div>
  );
};

FormCheckboxGroup.propTypes = {
  name: PropTypes.string,
  rules: PropTypes.object.isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    description: PropTypes.node
  }).isRequired).isRequired
};
