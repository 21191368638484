import LABELS_EN from '../messages/en/StudentLevel.json' assert { type: 'json' };

export default function formatStudentLevel(level, { language }) {
  const LABELS = LABELS_EN;
  if (LABELS[level]) {
    return LABELS[level]
  }
  console.error(`Message for student level "${level}" for language "${language}" not found`);
  return level;
}
