import { loadEnrollmentsPage } from '../../helpers/routes';

import Enrollments from '../Enrollments';

const EnrollmentsFinalized = () => {
  return (
    <Enrollments type="home" status="finalized"/>
  );
};

export default EnrollmentsFinalized;

EnrollmentsFinalized.meta = () => ({
  title: 'Finalized Enrollments'
});

EnrollmentsFinalized.load = async ({ user }) => {
  await loadEnrollmentsPage({ user });
};

EnrollmentsFinalized.breadcrumbs = () => [
  ['Manage Enrollments', '/enrollments'],
  'Finalized'
];
