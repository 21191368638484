import get from 'lodash/get';

import type { RowData, RowSelectionState, Table, TableOptions } from '@tanstack/react-table';

import { formatDate } from '../../../utils/format';

import { ACTIONS_COLUMN_ID, EXPANDING_COLUMN_ID, SELECT_COLUMN_ID } from '../utils/consts';
import type { ExportDataColumnCsv, ExportDataColumnXlsx } from '../utils/exportTableData';
import { exportTableData } from '../utils/exportTableData';

export type ExportDataColumns<Type extends ('xlsx' | 'csv'), TData extends RowData> = Type extends 'xlsx'
  ? ExportDataColumnXlsx<TData>[]
  : ExportDataColumnCsv<TData>[]

interface TableOptionsParam<TData> {
  table: Table<TData>;
  columns: TableOptions<TData>['columns'];
  rowSelection: RowSelectionState;
  selectedRows: TData[];
  getSubRows: TableOptions<TData>['getSubRows'];
}

export type ExportPropOptions<TData> = {
  hideDownloadButton?: boolean;
  fileName?: string;
  exportBySelection?: boolean;
  fetchDataForExport?: (selectedRowsIds: string[], {
    selectedRows,
    rowsIds,
    rows
  }: {
    selectedRows: TData[]
    rows: TData[],
    rowsIds: string[]
  }) => Promise<TData[]>;
} & ({
  type: 'xlsx';
  exportDataColumns?: ExportDataColumnXlsx<TData>[];
} | {
  type: 'csv';
  exportDataColumns?: ExportDataColumnCsv<TData>[];
})

type UseDataExport = <TData extends RowData>(
  propOptions: ExportPropOptions<TData> | undefined,
  tableOptions: TableOptionsParam<TData>
) => ({
  hasExport?: boolean;
  disabledExport?: boolean;
  hideDownloadButton?: boolean;
  exportData?: () => Promise<void>
});

export const useDataExport: UseDataExport = <TData extends RowData>(propOptions, {
  columns,
  selectedRows,
  rowSelection,
  table,
  getSubRows
}) => {
  if (!propOptions) {
    return { hasExport: false };
  }

  const {
    type,
    fileName = 'data',
    exportDataColumns,
    fetchDataForExport,
    exportBySelection,
    hideDownloadButton
  } = propOptions as ExportPropOptions<TData>;

  const selectedRowsIds = Object.keys(rowSelection);

  const getRowsForExport = (rows) => {
    if (!getSubRows) {
      return rows;
    }
    return rows.reduce((acc, row) => acc.concat([row, ...getSubRows(row)]), []);
  };

  const getExportedData = async (): Promise<TData[]> => {
    /**
     * `selectedRows` does not always match `selectedRowsIds`,
     * so need to make sure that all the selected information is there
     * */
    if (fetchDataForExport) {
      const { rows } = table.getFilteredRowModel();

      return await fetchDataForExport(selectedRowsIds, {
        selectedRows,
        rows: rows.map(_ => _.original),
        rowsIds: rows.map(_ => _.id)
      });
    }

    if (selectedRows.length > 0) {
      if (selectedRows.length === selectedRowsIds.length) {
        return getRowsForExport(selectedRows);
      }
    }

    return getRowsForExport(table.getFilteredRowModel().rows.map(_ => _.original));
  };

  const exportData = async () => {
    const rows = await getExportedData();
    await exportTableData({
      type,
      fileName,
      data: rows,
      exportDataColumns: exportDataColumns || convertColumnFormatForDownload(columns)
    });
  };

  const disabledExport = exportBySelection && selectedRowsIds.length === 0;
  return {
    exportData,
    hasExport: true,
    disabledExport,
    hideDownloadButton
  };
};

const convertColumnFormatForDownload = <TData extends RowData>(columns: TableOptions<TData>['columns']) => {
  return columns
    .filter(column => ![EXPANDING_COLUMN_ID, SELECT_COLUMN_ID, ACTIONS_COLUMN_ID].includes(column.id as string))
    .map((column) => {
      if (column.meta?.exportValue) {
        // Ignore.
      } else {
        if (!Object.hasOwn(column, 'accessorKey')) {
          throw new Error(`\`accessorKey\` is not defined in \`${column.id}\`. You can explicitly define \`exportDataColumns\` to export`);
        }
      }
      if (!Object.hasOwn(column, 'header') || typeof column.header !== 'string') {
        throw new Error(`\`header\` is not string in \`${column.id}\`. You can explicitly define \`exportDataColumns\` to export`);
      }
      return {
        title: column['header'],
        value: row => {
          if (column.meta?.emptyWhen && column.meta?.emptyWhen?.({ row })) {
            return null;
          }
          if (column.meta?.exportValue) {
            return column.meta?.exportValue({ row });
          }
          return formatValue(get(row, column['accessorKey']));
        }
      };
    });
};

function formatValue(value) {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }

  if (value instanceof Date) {
    return formatDate(value, { utc: true });
  }

  return value;
}
