import type { Id, CoursePricingVariant, CoursePricing  } from '@acadeum/types';

import getCourseSectionCostForInstitutionWithDetails from 'common-lib/lib/getCourseSectionCostForInstitutionWithDetails.js';
import getCourseSectionPriceForStudentBasedOnCostForInstitutionWithDetails from 'common-lib/lib/getCourseSectionPriceForStudentBasedOnCostForInstitutionWithDetails.js';

interface ConsortiumInfo {
  id: Id,
  name: string,
  vanityUrl: string
}

interface CourseSectionCostWithDetails {
  costForInstitution: number,
  costForStudent?: number,
  isCustomCost: boolean,
  isCustomCostAtCourseLevel: boolean,
  isCustomCostAtCourseSectionLevel: boolean,
  isOriginalCost: boolean,
  isOwnCourse: boolean,
  customCostConsortium?: ConsortiumInfo,
  homeInstitutionPricing?: CoursePricingVariant,
  homeInstitutionPricingFilters?: CoursePricingVariantFilter[],
  homeInstitutionPricingConsortium?: ConsortiumInfo,
  homeStudentPricing?: CoursePricingVariant,
  homeStudentPricingFilters?: CoursePricingVariantFilter[]
}

interface CostForInstitutionWithDetails {
  cost: number,
  source: CostForInstitutionSource
}

interface CostForInstitutionSourceDefault {
  type: 'cost',
  value: number
}

interface CostForInstitutionSourceCustomCost {
  type: 'courseCustomCost' | 'courseSectionCustomCost',
  value: {
    homeInstitutionId: Id,
    // `undefined` here fixes TypeScript error: Property 'homeConsortiumId' does not exist on type ...
    homeConsortiumId: undefined,
    cost: number
  } | {
    homeConsortiumId: Id,
    cost: number
  }
}

interface CostForInstitutionSourcePricing {
  type: 'pricing',
  value: CoursePricingVariant
}

type CostForInstitutionSource =
  CostForInstitutionSourceDefault |
  CostForInstitutionSourceCustomCost |
  CostForInstitutionSourcePricing;

type PriceForStudentWithDetails =
  PriceForStudentWithDetailsDefault |
  PriceForStudentWithDetailsNonDefault;

interface PriceForStudentWithDetailsDefault {
  price: undefined,
  source: undefined
}

interface PriceForStudentWithDetailsNonDefault {
  price: number,
  source: PriceForStudentSource
}

type PriceForStudentSource =
  PriceForStudentSourcePricing;

interface PriceForStudentSourcePricing {
  type: 'pricing',
  value: CoursePricingVariant
}

type CoursePricingVariantFilterValue = string | number;

interface CoursePricingVariantFilter {
  name: string;
  value: CoursePricingVariantFilterValue | CoursePricingVariantFilterValue[];
}

export function getCourseSectionCostWithDetails({
  cost,
  courseCustomCosts: courseCustomCosts_,
  courseSectionCustomCosts: courseSectionCustomCosts_,
  course: {
    credits,
    level,
    teachingInstitutionId
  },
  homeStudentCourseEnrollmentPricing,
  courseEnrollmentPricing,
  courseEnrollmentPricingConsortia,
  homeInstitutionTeachingInstitutionRelationshipIds,
  homeConsortiumTeachingInstitutionRelationships,
  homeInstitutionId
}: {
  cost: number,
  courseCustomCosts?: {
    customCostByHomeInstitutionRelationshipId?: Record<string, number>,
    customCostByHomeConsortiumRelationshipId?: Record<string, number>
  },
  courseSectionCustomCosts?: {
    // `section.customCosts` is a legacy property name for `section.customCostByHomeInstitutionRelationshipId`.
    customCostByHomeInstitutionRelationshipId?: Record<string, number>,
    customCostByHomeConsortiumRelationshipId?: Record<string, number>
  },
  course: {
    credits: number,
    level: string,
    teachingInstitutionId: Id
  },
  homeStudentCourseEnrollmentPricing?: CoursePricing,
  courseEnrollmentPricing?: CoursePricing,
  courseEnrollmentPricingConsortia?: ConsortiumInfo[],
  homeInstitutionId: Id,
  homeInstitutionTeachingInstitutionRelationshipIds: Id[],
  homeConsortiumTeachingInstitutionRelationships?: {
    consortium: ConsortiumInfo,
    homeConsortiumTeachingInstitutionRelationshipIds: Id[]
  }[]
}): CourseSectionCostWithDetails {
  // Validate that `session.cost` exists.
  // There's no `session.cost` for course section objects in `public-sections` Algolia index.
  // This if works around accidental development errors when a developer accidentally uses
  // `public-sections` Algolia index instead of `sections` Algolia index
  // which might happen when calling this function before `user` is authenticated.
  if (cost === undefined) {
    throw new Error('`cost` is required');
  }

  // If someone from a teaching instituiton finds their own course
  // then show them the "base" course session cost for this course section,
  // without applying any custom course pricing specific for this instituiton.
  //
  // This makes sense because a teaching institution won't enroll into its own courses,
  // and showing the "original" unmodified "base" course session cost is the one
  // that they most likely expect to see.
  //
  // For example, the teaching institution could be a member of some consortium
  // that provides free-of-charge courses to its fellow member institutions,
  // which would result in that teaching institution seeing all their own courses
  // as "Free of Charge" on the platform, which would be confusing to them.
  //
  if (!teachingInstitutionId) {
    throw new Error('`course.teachingInstitutionId` is required');
  }
  const isOwnCourse = teachingInstitutionId === homeInstitutionId;

  if (!homeInstitutionTeachingInstitutionRelationshipIds) {
    throw new Error('`homeInstitutionTeachingInstitutionRelationshipIds` is required');
  }

  // These are custom costs for Home Institutions and Home Consortia mapped by "relationship ID".
  // The code below will convert these maps from using "relationship ID"s to using normal IDs.
  //
  // `customCostByHomeInstitutionRelationshipId` property is not yet present in Algolia objects
  // as of Apr 15th, 2023. Pull request: https://github.com/Acadeum/ASP-API/pull/519
  //
  const customCostsForHomeInstitutions = courseSectionCustomCosts_?.customCostByHomeInstitutionRelationshipId;
  const customCostsForHomeConsortia = courseSectionCustomCosts_?.customCostByHomeConsortiumRelationshipId;
  const customCostsForHomeInstitutionsCourseWide = courseCustomCosts_?.customCostByHomeInstitutionRelationshipId;
  const customCostsForHomeConsortiaCourseWide = courseCustomCosts_?.customCostByHomeConsortiumRelationshipId;

  // Course-wide custom costs.
  const courseCustomCosts: {
    cost: number,
    homeInstitutionId?: Id,
    homeConsortiumIds?: Id[]
  }[] = [];

  // Section-wide custom costs.
  const courseSectionCustomCosts: {
    cost: number,
    homeInstitutionId?: Id,
    homeConsortiumIds?: Id[]
  }[] = [];

  // Collects any Home Consortium IDs in encounters along the way.
  const homeConsortiumIds: Id[] = [];
  const homeConsortia: ConsortiumInfo[] = [];

  // Collects Home Consortium IDs from consortium-specific pricing.
  // This part is not required at all and could be removed. I just added it because it doesn't result in any errors.
  if (courseEnrollmentPricing) {
    for (const pricingVariant of courseEnrollmentPricing) {
      if (pricingVariant.consortiumId) {
        if (!homeConsortiumIds.includes(pricingVariant.consortiumId)) {
          homeConsortiumIds.push(pricingVariant.consortiumId);
        }
      }
    }
  }

  // If there's custom price defined for this course section and this Home Institution
  // then return that custom price, even if it's higher than a consortium-calculated price.
  // As per Caleb's comment:
  // "Differential Pricing should take precedence over consortium-based pricing models".
  //
  // `customCosts` property was added in Aug 2023 milestone.
  // The `if` operator preserves backwards compatibility.
  //
  const fillInInstitutionSpecificCustomCosts = (customCosts, customCostsForHomeInstitutions) => {
    if (customCostsForHomeInstitutions) {
      for (const relationshipId of homeInstitutionTeachingInstitutionRelationshipIds) {
        if (typeof customCostsForHomeInstitutions[relationshipId] === 'number') {
          // Add the custom cost to the list.
          customCosts.push({
            homeInstitutionId,
            cost: customCostsForHomeInstitutions[relationshipId]
          });
        }
      }
    }
  };
  fillInInstitutionSpecificCustomCosts(courseSectionCustomCosts, customCostsForHomeInstitutions);
  fillInInstitutionSpecificCustomCosts(courseCustomCosts, customCostsForHomeInstitutionsCourseWide);

  const fillInConsortiumSpecificCustomCosts = (customCosts, customCostsForHomeConsortia) => {
    if (customCostsForHomeConsortia) {
      // `homeConsortiumTeachingInstitutionRelationships` property is not yet present in Algolia objects
      // as of Apr 15th, 2023. Pull request: https://github.com/Acadeum/ASP-API/pull/519
      for (const relationship of (homeConsortiumTeachingInstitutionRelationships || [])) {
        const { consortium, homeConsortiumTeachingInstitutionRelationshipIds } = relationship;
        for (const relationshipId of homeConsortiumTeachingInstitutionRelationshipIds) {
          if (typeof customCostsForHomeConsortia[relationshipId] === 'number') {
            // Add the consortium ID to the list of `homeConsortiumIds`.
            // That list will be used later by `getCourseSectionCostForInstitution()` function
            // to correctly apply this custom cost.
            if (!homeConsortiumIds.includes(consortium.id)) {
              homeConsortiumIds.push(consortium.id);
              homeConsortia.push(consortium);
            }
            // Add the custom cost to the list.
            customCosts.push({
              homeConsortiumIds: [consortium.id],
              cost: customCostsForHomeConsortia[relationshipId]
            });
          }
        }
      }
    }
  };
  fillInConsortiumSpecificCustomCosts(courseSectionCustomCosts, customCostsForHomeConsortia);
  fillInConsortiumSpecificCustomCosts(courseCustomCosts, customCostsForHomeConsortiaCourseWide);

  const costForInstitutionWithDetails: CostForInstitutionWithDetails = getCourseSectionCostForInstitutionWithDetails({
    cost,
    course: {
      credits,
      level,
      teachingInstitutionId
    },
    courseEnrollmentPricing,
    courseCustomCosts,
    courseSectionCustomCosts,
    homeInstitutionId,
    homeConsortiumIds
  });

  const costForInstitution = costForInstitutionWithDetails.cost;

  const isCustomCostAtCourseLevel = costForInstitutionWithDetails.source.type === 'courseCustomCost';
  const isCustomCostAtCourseSectionLevel = costForInstitutionWithDetails.source.type === 'courseSectionCustomCost';
  const isCustomCost = isCustomCostAtCourseLevel || isCustomCostAtCourseSectionLevel;

  const customCostConsortiumId: Id | undefined = costForInstitutionWithDetails.source.type === 'courseCustomCost' || costForInstitutionWithDetails.source.type === 'courseSectionCustomCost'
    ? costForInstitutionWithDetails.source.value.homeConsortiumId
    : undefined;

  const customCostConsortium: ConsortiumInfo | undefined = customCostConsortiumId ? (homeConsortia.find(_ => _.id === customCostConsortiumId) || {
    id: customCostConsortiumId,
    name: '#' + customCostConsortiumId,
    vanityUrl: String(customCostConsortiumId)
  }) : undefined;

  const costForStudentWithDetails: PriceForStudentWithDetails = getCourseSectionPriceForStudentBasedOnCostForInstitutionWithDetails({
    course: {
      credits,
      level,
      teachingInstitutionId
    },
    costForInstitution,
    homeStudentCourseEnrollmentPricing
  });

  const costForStudent = costForStudentWithDetails.price;

  const homeInstitutionPricing: CoursePricingVariant | undefined = costForInstitutionWithDetails.source.type === 'pricing'
    ? costForInstitutionWithDetails.source.value
    : undefined;

  const isOriginalCost = isCustomCost ? false : !homeInstitutionPricing;

  const homeStudentPricing: CoursePricingVariant | undefined = costForStudentWithDetails.source?.type === 'pricing'
    ? costForStudentWithDetails.source?.value
    : undefined;

  // Currently, there're one supported pricing filter:
  // * level
  let homeInstitutionPricingFilters: CoursePricingVariantFilter[] | undefined;
  if (homeInstitutionPricing) {
    homeInstitutionPricingFilters = [];
    for (const key of Object.keys(homeInstitutionPricing)) {
      if (key === 'model' || key === 'value' || key === 'consortiumId') {
        continue;
      }
      homeInstitutionPricingFilters.push({ name: key, value: homeInstitutionPricing[key] });
    }
  }

  // Currently, there're no supported student pricing filters.
  let homeStudentPricingFilters: CoursePricingVariantFilter[] | undefined;
  if (homeStudentPricing) {
    homeStudentPricingFilters = [];
    for (const key of Object.keys(homeStudentPricing)) {
      if (key === 'model' || key === 'value') {
        continue;
      }
      homeStudentPricingFilters.push({ name: key, value: homeStudentPricingFilters[key] });
    }
  }

  const homeInstitutionPricingConsortium = homeInstitutionPricing && homeInstitutionPricing.consortiumId
    ? courseEnrollmentPricingConsortia && courseEnrollmentPricingConsortia.find(_ => _.id === homeInstitutionPricing.consortiumId) || {
      id: homeInstitutionPricing.consortiumId,
      name: '#' + homeInstitutionPricing.consortiumId,
      vanityUrl: String(homeInstitutionPricing.consortiumId)
    }
    : undefined;

  return {
    costForInstitution,
    costForStudent,
    isOriginalCost,
    isCustomCost,
    isCustomCostAtCourseLevel,
    isCustomCostAtCourseSectionLevel,
    isOwnCourse,
    customCostConsortium,
    homeInstitutionPricing,
    homeInstitutionPricingFilters,
    homeInstitutionPricingConsortium,
    homeStudentPricing,
    homeStudentPricingFilters
  };
}
