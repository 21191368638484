import writeXlsxFile from '../../helpers/xlsx';

import actions from '../../actions';

const {
  notify,
  notifyError
} = actions;

export default function useDataExport(
  fetchDataForExport,
  fetchDataForExportGetObjectId = (row) => row.id,
  exportData,
  exportDataFileName,
  exportDataColumns,
  rows,
  selectedRows
) {
  async function getExportedData() {
    const _rows = selectedRows.length > 0 ? selectedRows : rows;
    if (fetchDataForExport) {
      return await fetchDataForExport(_rows.map(fetchDataForExportGetObjectId));
    }
    return _rows.sort((a, b) => a.id - b.id);
  }

  const defaultExportData = (rows) => {
    writeXlsxFile(rows, {
      columns: exportDataColumns,
      fileName: exportDataFileName
    });
    notify('Success! Check your downloads folder for your information.');
  };

  // Custom data export extension point (not CSV).
  // This is just a placeholder for some potential future use.
  async function onExportData() {
    try {
      const rows = await getExportedData();
      (exportData || defaultExportData)(rows);
    } catch (error) {
      console.error(error);
      notifyError('Couldn\'t export data.');
    }
  }

  return [onExportData];
}
