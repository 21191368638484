import React from 'react';
import { useSelector } from 'react-redux';

import { getUserSelector } from '@acadeum/auth';
import { CircularProgress, HStack, Title } from '@acadeum/ui';
import { userHasPermission } from '@acadeum/helpers';
import { UploadCoursesPage } from '@acadeum/shared-admin-ui';
import type { UserProfile } from '@acadeum/types';

import actions from '../../actions';

import { useCreateCourseMutation } from '../../api/course';
import { useFileDataImportErrorMutation } from '../../api/fileDataImportError';
import { useUpdateCourseSettings, useBulkCreateOrUpdate } from '../../api/jobs';
import { useFetchInstitutionQuery } from '../../api/institutions';

import type { AppPage } from '../../helpers/app.types';

const { goto } = actions;

const UploadCourses: AppPage = () => {
  const user = useSelector(getUserSelector);

  const { isLoading, data: userInstitution } = useFetchInstitutionQuery({
    institutionId: user?.institution.id as number
  });

  return (
    <>
      <Title>Upload Courses</Title>
      {isLoading ? (
        <HStack justify="center" style={{ height: '20rem' }}>
          <CircularProgress/>
        </HStack>
      ) : (
        <UploadCoursesPage
          user={user}
          cancelUrl="/"
          goToCoursesPage={() => goto('/')}
          goToFinishPage={() => goto('/')}
          goToCoursePage={courseId => goto(`/courses/${courseId}`)}
          useFileDataImportErrorMutation={useFileDataImportErrorMutation}
          useBulkCreateOrUpdate={useBulkCreateOrUpdate}
          useCreateCourseMutation={useCreateCourseMutation}
          useUpdateCourseSettings={useUpdateCourseSettings}
          onDemandCourseDropDateDaysAfterStartDate={userInstitution?.onDemandCourseDropDateDaysAfterStartDate}
          onDemandCourseEndDateDaysAfterStartDate={userInstitution?.onDemandCourseEndDateDaysAfterStartDate}
        />
      )}
    </>
  );
};

UploadCourses.when = ({ user }) => getCanUploadCourses(user);

UploadCourses.meta = () => ({
  title: 'Upload Courses'
});

UploadCourses.breadcrumbs = () => [
  'Upload Courses'
];

export default UploadCourses;

export function getCanUploadCourses(user?: UserProfile) {
  return user && user.courseImport && user.institution.teaching && userHasPermission(user, 'course:create', {
    orgId: user.institution.id, ownerId: null
  });
}
