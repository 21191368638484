// import schemaValidation from 'flexible-json-schema';

import userRolePermissionSchema from '../schema/userRolePermission.json' assert { type: 'json' };
import userRolePermissionSubjects from '../constants/userRolePermissionSubjects.json' assert { type: 'json' };

userRolePermissionSchema.schema.subject.oneOf = userRolePermissionSubjects;

// const validatePermissionUsingSchema = schemaValidation(userRolePermissionSchema);

export default function checkUserPermissions(
  userId,
  userOrgId,
  userPermissionSets,
  requestedPermission,
  subjectInfo,
  requestedPermissionParameters
) {
  // In case of multiple `requestedPermissions`.
  // requestedPermissions = requestedPermissions.map((requestedPermission) => {
  //   if (typeof requestedPermission === 'string') {
  //     requestedPermission = parsePermissionString(requestedPermission);
  //   }
  //   validatePermission(requestedPermission);
  //   return requestedPermission;
  // });

  if (subjectInfo) {
    validateSubjectInfo(subjectInfo);
  }

  // if (typeof requestedPermission === 'string') {
  requestedPermission = parsePermissionString(requestedPermission);
  // }

  // // Validate the required permission.
  // const requestedPermissionForSchemaValidation = {
  //   ...requestedPermission,
  //   operations: [requestedPermission.operation],
  //   scope: 'owner'
  // };
  // delete requestedPermissionForSchemaValidation.operation;
  // validatePermissionUsingSchema(requestedPermissionForSchemaValidation);

  // Validate the required permission.
  validatePermission(requestedPermission);

  // For each of the user's sets of permissions,
  // see if those permissions permit the requested operation.
  const possibleMatches = [];
  for (const userPermissions of userPermissionSets) {
    for (const userPermission of userPermissions) {
      const {
        permitted,
        ...rest
      } = checkPermission(userId, userOrgId, userPermission, requestedPermission, subjectInfo, requestedPermissionParameters);
      if (permitted) {
        return {
          permitted: true
        };
      }
      if (rest.reason !== 'SUBJECT_DOES_NOT_MATCH' && rest.reason !== 'OPERATION_DOES_NOT_MATCH') {
        possibleMatches.push(rest);
      }
    }
  }
  const result = {
    permitted: false
  };
  if (possibleMatches.length > 0) {
    result.possibleMatches = possibleMatches;
  }
  return result;
}

// Checks if a requested permission is covered by a user's permission.
//
// Returns:
//
// {
//   permitted: boolean,
//   reason?: string,
//   parameter?: {
//     name: string,
//     value: true
//   }
// }
//
function checkPermission(
  userId,
  userOrgId,
  permission,
  requestedPermission,
  subjectInfo,
  requestedPermissionParameters
) {
  if (permission.subject !== requestedPermission.subject) {
    return {
      permitted: false,
      reason: 'SUBJECT_DOES_NOT_MATCH'
    }
  }

  if (!permission.operations.includes(requestedPermission.operation)) {
    return {
      permitted: false,
      reason: 'OPERATION_DOES_NOT_MATCH'
    };
  }

  // Special options.
  // Example: only Platform Administrator can create or update Acadeum Administrator user accounts,
  // so Platform Administrator role's `users` permission has `"options": { "acadeumAdmin": true }`.
  if (requestedPermissionParameters) {
    const optionsNames = Object.keys(requestedPermissionParameters);
    for (const optionName of optionsNames) {
      const optionValue = requestedPermissionParameters[optionName];
      if (optionValue !== true) {
        throw new Error(`Only boolean (\`true\`, to be more specific) permission options are supported when calling \`checkUserPermissions()\` function. The function was called with a permission option "${optionName}" having value ${typeof optionValue === 'string' ? '"' : ''}${optionValue}${typeof optionValue === 'string' ? '"' : ''}${optionValue ? ' of type "'+ typeof optionValue + '"' : ''}`);
      }
      if (!permission.options || !permission.options[optionName]) {
        return {
          permitted: false,
          reason: 'PARAMETER_NOT_COVERED',
          parameter: {
            name: optionName,
            value: optionValue
          }
        }
      }
    }
  }

  switch (permission.scope) {
    case '*':
      return {
        permitted: true
      };
    case 'owner':
      if (!userId) {
        throw new Error('`userId` parameter is required');
      }
      if (!subjectInfo || !subjectInfo.ownerId) {
        return {
          permitted: false,
          reason: 'OWNER_NOT_SPECIFIED'
        };
      }
      if (userId === subjectInfo.ownerId) {
        return {
          permitted: true
        };
      }
      return {
        permitted: false,
        reason: 'OWNER_DOES_NOT_MATCH',
        proposedOwnerId: userId,
        requiredOwnerId: subjectInfo.ownerId
      };
    case 'org':
      if (!userOrgId) {
        throw new Error('`userOrgId` parameter is required');
      }
      if (!subjectInfo || !subjectInfo.orgId) {
        return {
          permitted: false,
          reason: 'ORG_NOT_SPECIFIED'
        };
      }
      if (userOrgId === subjectInfo.orgId) {
        return {
          permitted: true
        };
      }
      return {
        permitted: false,
        reason: 'ORG_DOES_NOT_MATCH',
        proposedOrgId: userOrgId,
        requiredOrgId: subjectInfo.orgId
      };
    default:
      throw new Error(`Unknown permission scope: "${permission.scope}"`);
  }
}

// Example of a permission string: "user:create".
function parsePermissionString(permissionString) {
  // Initially it supported permission strings in format: "owner/user:create".
  // Then it was considered a redundant unused feature.

  // const [scope, permissionStringRest] = permissionString.split('/');

  // if (!scope) {
  //   throw new Error(`No \`scope\` in permission string: "${permissionString}"`);
  // }

  // if (permissionString.length !== scope + '/'.length + permissionStringRest.length) {
  //   throw new Error(`Unsupported character "/" in permission string: "${permissionString}"`);
  // }

  const permissionStringRest = permissionString;

  const [subject, operation] = permissionStringRest.split(':');

  if (!subject) {
    throw new Error(`No \`subject\` in permission string: "${permissionString}"`);
  }

  if (!operation) {
    throw new Error(`No \`operation\` in permission string: "${permissionString}"`);
  }

  if (permissionStringRest.length !== subject.length + ':'.length + operation.length) {
    throw new Error(`Unsupported second character ":" in permission string: "${permissionString}"`);
  }

  return {
    subject,
    operation,
    // scope
  };
}

function validateSubjectInfo(subjectInfo) {
  if (!subjectInfo.orgId) {
    throw new Error('Invalid `subjectInfo`: `orgId` parameter is required');
  }
}

function validatePermission({ subject, operation }) {
  if (!userRolePermissionSchema.schema.subject.oneOf.includes(subject)) {
    throw new Error(`Unsupported requested permission subject: "${subject}"`);
  }
  if (!userRolePermissionSchema.schema.operations.arrayOf.oneOf.includes(operation)) {
    throw new Error(`Unsupported requested permission operation: "${operation}"`);
  }
}
