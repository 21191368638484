import React from 'react';

import { Grades } from '../../components/Grades';
import type { AppPage } from '../../helpers/app.types';
import actions from '../../actions';

const { fetchEnrollmentRequests } = actions;

const EnrollingGrades: AppPage = () => (
  <Grades institutionType="enrolling"/>
);

EnrollingGrades.meta = () => ({
  title: 'Enrolling Grades'
});

EnrollingGrades.load = async () => {
  await fetchEnrollmentRequests();
};

EnrollingGrades.breadcrumbs = () => ['Enrolling Grades'];

export default EnrollingGrades;
