import { Accordion, Text } from '@acadeum/ui';

export function CourseMaterialsSection({
  course
}) {
  const hasCourseMaterials = Boolean(course.zeroTextbookCost === false || course.courseMaterialTitles || course.courseMaterialISBNs);

  let content;
  if (hasCourseMaterials) {
    content = (
      <>
        <Text color="grey">
          {course.zeroTextbookCost
            ? 'No costs are associated with these course materials.'
            : 'Costs are associated with these course materials.'}
        </Text>

        <Text color="grey">
          Title(s):<br/>
          {course.courseMaterialTitles || 'No titles are associated with these course materials.'}
        </Text>

        <Text color="grey">
          {course.courseMaterialISBNs && !course.courseMaterialISBNs.includes('ISBN') && (
            <>
              ISBN(s):<br/>
            </>
          )}
          {course.courseMaterialISBNs || 'No ISBNs are associated with these course materials.'}
        </Text>
      </>
    );
  } else {
    content = (
      <Text color="grey">
        No costs, titles or ISBNs are associated with these course materials.
      </Text>
    );
  }

  return (
    <Accordion title="Course Materials" defaultOpen={hasCourseMaterials}>
      {content}
    </Accordion>
  );
}
