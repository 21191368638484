import type { FC, JSX } from 'react';
import type React from 'react';
import { useEffect } from 'react';

export interface OnClickOutsideProps {
  children: JSX.Element;
  containerRef: React.RefObject<HTMLElement>;
  toggleRef: React.RefObject<HTMLElement>;
  onClickOutside?: (event: MouseEvent) => void;
}

export const OnClickOutside: FC<OnClickOutsideProps> = ({
  children,
  containerRef,
  toggleRef,
  onClickOutside
}) => {
  const onClick = (event: MouseEvent) => {
    const containerNode = containerRef.current;
    const toggleNode = toggleRef.current;
    const target = event.target as HTMLElement;

    // Do nothing if clicking ref's element or descendent elements
    if (!containerNode || containerNode.contains(target) || (toggleNode && toggleNode.contains(target))) {
      return;
    }

    if (onClickOutside) {
      onClickOutside(event);
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', onClick);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', onClick);
    };
  }, [onClickOutside]);

  return children;
};
