import React from 'react';
import PropTypes from 'prop-types';

export interface FileUploadInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'accept'> {
  accept?: string | string[];
  error?: string | boolean;
}

export const FileUploadInput = React.forwardRef<HTMLInputElement, FileUploadInputProps>(({
  error,
  accept,
  required,
  ...rest
}, ref) => {
  return (
    <input
      {...rest}
      ref={ref}
      type="file"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TODO: Fix this
      accept={accept}
      required={required}
      aria-required={required ? true : undefined}
      aria-invalid={error ? true : undefined}
    />
  );
});

FileUploadInput.propTypes = {
  multiple: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  required: PropTypes.bool,
  accept: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string.isRequired)
  ])
};
