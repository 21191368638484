import React from 'react';
import { useSelector } from 'react-redux';

import DefaultLayout from '../DefaultLayout';
import VisitorLayout from '../VisitorLayout';
import LoadingScreen from '../LoadingScreen';

import hasAccessToken from '../../helpers/hasAccessToken.js';

export default function Public(Component, {
  layout,
  background,
  margin,
  marginTop,
  marginBottom
} = {}) {
  function PublicComponent(props) {
    const { user, isAuthenticationLoading } = useSelector(state => state.auth);
    const { userDataLoaded } = useSelector(state => state.app);

    const children = <Component {...props}/>;

    if (layout === 'any') {
      if (hasAccessToken() && isAuthenticationLoading) {
        return (
          <LoadingScreen/>
        );
      } else if (user) {
        if (userDataLoaded === undefined) {
          return (
            <LoadingScreen/>
          );
        }
        return (
          <DefaultLayout margin={margin} marginTop={marginTop} getBreadcrumbs={Component.breadcrumbs}>
            {children}
          </DefaultLayout>
        );
      }
    }

    return (
      <VisitorLayout margin={margin} background={background} marginTop={marginTop} marginBottom={marginBottom}>
        {children}
      </VisitorLayout>
    );
  }

  if (Component.load) {
    PublicComponent.load = async ({
      location,
      useSelector,
      params
    }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const user = useSelector(state => state.auth.user);
      return await Component.load({
        location,
        params,
        user
        // Doesn't pass the `state` parameter to `.load()` function of `public: true` pages.
        // state: ...
      });
    };
  }

  PublicComponent.meta = Component.meta;

  PublicComponent.__public = true;

  return PublicComponent;
}

export function isPublic(PublicComponent) {
  return PublicComponent.__public;
}
