import React, { memo } from 'react';
import clamp from 'lodash/clamp';

import type { HSVA } from '../../types';

import { hsvaToHslString } from '../../helpers/convert';

import type { InteractionPosition } from '../Interactive';
import { Interactive } from '../Interactive';
import { Pointer } from '../Pointer';

import styles from './Saturation.module.scss';

interface Saturation2Props {
  hsva: HSVA;
  onChange: (newColor: Pick<HSVA, 's' | 'v'>) => void;
}

export const Saturation = memo(({ hsva, onChange }: Saturation2Props) => {
  const onMove = (interaction: InteractionPosition) => {
    onChange?.({
      s: interaction.left * 100,
      v: 100 - interaction.top * 100
    });
  };

  const onKey = (offset: InteractionPosition) => {
    onChange?.({
      s: clamp((hsva.s + offset.left) * 100, 0, 100),
      v: clamp((hsva.v - offset.top) * 100, 0, 100)
    });
  };

  return (
    <div
      className={styles.Saturation}
      style={{
        backgroundColor: hsvaToHslString({ h: hsva.h, s: 100, v: 100, a: 1 })
      }}
    >
      <Interactive
        onMove={onMove}
        onKey={onKey}
        aria-label="Color"
        aria-valuetext={`Saturation ${Math.round(hsva.s)}%, Brightness ${Math.round(hsva.v)}%`}
      >
        <Pointer
          className={styles.Pointer}
          top={1 - hsva.v}
          left={hsva.s}
        />
      </Interactive>
    </div>
  );
});
