import React, { memo, useState } from 'react';
import { toast } from '@acadeum/ui';

import type { OnSubmit } from '@acadeum/ui';
import {
  Actions,
  Alert,
  Button,
  ButtonGroup,
  Col,
  ConfirmActionModal,
  ContentSection,
  FormField,
  FormModal,
  Link,
  List,
  Row,
  Separator,
  Text
} from '@acadeum/ui';
import { PlusIcon } from '@acadeum/icons';
import { convertFileToBase64, isFileUrl } from '@acadeum/helpers';

import { ALGOLIA_UPDATE_PENDING_NOTE } from '../../../../helpers/algolia';

import actions from '../../../../actions';

import styles from './FacultyCredentialsTab.module.scss';

const {
  addCourseResource,
  updateCourseResource,
  deleteCourseResource
} = actions;

interface FacultyCredentialsTabProps {
  // eslint-disable-next-line
  credentials: any[];
  refresh: () => Promise<void>;
  courseId: number;
}

export const FacultyCredentialsTab = memo<FacultyCredentialsTabProps>(({
  credentials,
  courseId,
  refresh
}) => {
  const [showAddFacultyCredentialModal, setShowAddFacultyCredentialModal] = React.useState(false);

  const [showRemoveFacultyCredentialModal, setShowRemoveFacultyCredentialModal] = React.useState(false);
  const [showEditFacultyCredentialModal, setShowEditFacultyCredentialModal] = React.useState(false);

  const [selectedCredential, setSelectedCredential] = React.useState(null);

  const onCloseRemoveFacultyCredentialModal = () => {
    setSelectedCredential(null);
    setShowRemoveFacultyCredentialModal(false);
  };

  const onCloseEditFacultyCredentialModal = () => {
    setSelectedCredential(null);
    setShowEditFacultyCredentialModal(false);
  };

  return (
    <>
      <ContentSection
        title="Faculty Credentials"
        description="Faculty credentials ensure that all instructors of record are qualified to teach their assigned course. Once added, faculty credentials will be displayed on the course page.
The maximum of 5 faculty credentials can be added to the page."
      >
        <div className={styles.header}>
          <Text as="h3" variant="subtitle2" color="graphiteDark">
            {credentials.length === 0 ? (
              'No Faculty Credentials'
            ) : (
              `${credentials.length} Faculty Credential${credentials.length > 1 ? 's' : ''}`
            )}
          </Text>
          <Button
            icon={PlusIcon}
            variant="secondary"
            onClick={() => setShowAddFacultyCredentialModal(true)}
          >
            Add Faculty Credential
          </Button>
        </div>

        {credentials.map((credential) => {
          const isFile = isFileUrl(credential.url);
          return (
            <Row key={credential.id} className={styles.credential}>
              <Col col={2}>
                {isFile ? 'Document' : 'Link'}
              </Col>
              <Col col={10} className={styles.credential__header}>
                <Link
                  external
                  icon={isFile ? 'file' : 'link'}
                  to={credential.url}
                >
                  {credential.name}
                </Link>
                <Actions
                  variant="kebab"
                  actions={[
                    {
                      title: 'Edit',
                      onClick: () => {
                        setSelectedCredential(credential);
                        setShowEditFacultyCredentialModal(true);
                      }
                    },
                    {
                      title: 'Remove',
                      onClick: () => {
                        setSelectedCredential(credential);
                        setShowRemoveFacultyCredentialModal(true);
                      }
                    }
                  ]}
                />
              </Col>
            </Row>
          );
        })}
      </ContentSection>

      <AddFacultyCredentialModal
        refresh={refresh}
        courseId={courseId}
        show={showAddFacultyCredentialModal}
        onHide={() => setShowAddFacultyCredentialModal(false)}
      />

      {selectedCredential && (
        <RemoveFacultyCredentialModal
          refresh={refresh}
          credential={selectedCredential}
          show={showRemoveFacultyCredentialModal}
          onHide={onCloseRemoveFacultyCredentialModal}
        />
      )}

      {selectedCredential && (
        <EditFacultyCredentialModal
          refresh={refresh}
          credential={selectedCredential}
          show={showEditFacultyCredentialModal}
          onHide={onCloseEditFacultyCredentialModal}
        />
      )}
    </>
  );
});

const FILE_UPLOAD_EXTENSIONS = [
  '.pdf'
];

interface AddFacultyCredentialFormValuesWithFile {
  name: string;
  file: File;
  url?: never;
}

interface AddFacultyCredentialFormValuesWithUrl {
  name: string;
  url: string;
  file?: never;
}

type AddFacultyCredentialFormValues = AddFacultyCredentialFormValuesWithFile | AddFacultyCredentialFormValuesWithUrl;

function AddFacultyCredentialModal({
  show,
  onHide,
  courseId,
  refresh
}) {
  const [uploadType, setUploadType] = useState<'file' | 'url' | null>(null);

  const onClose = () => {
    setUploadType(null);
    onHide();
  };

  const onSubmit: OnSubmit<AddFacultyCredentialFormValues> = async ({ url, name, file }) => {
    if (!url && !file) {
      throw new Error('Please choose a file or enter a URL');
    }

    await addCourseResource({
      name,
      courseId,
      type: 'FACULTY_CREDENTIAL',
      url: url || undefined,
      file: file ? await convertFileToBase64(file) : undefined
    });
    await refresh();
    toast.success('Faculty credential added! ' + ALGOLIA_UPDATE_PENDING_NOTE);
  };

  const onChooseFile = (event) => {
    const { value } = event.target;
    setUploadType(value ? 'file' : null);
  };

  const onChooseUrl = (event) => {
    const { value } = event.target;
    setUploadType(value ? 'url' : null);
  };

  return (
    <FormModal
      show={show}
      onHide={onClose}
      title="Add Faculty Credential"
      submitText="Add"
      onSubmit={onSubmit}
    >
      <FormField
        required
        name="name"
        label="Faculty Name"
        placeholder="e.g. Josh Smith"
      />
      <FormField
        type="file"
        name="file"
        disabled={uploadType === 'url'}
        accept={FILE_UPLOAD_EXTENSIONS}
        onChange={onChooseFile}
      />

      <Separator mt="none">or</Separator>

      <FormField
        name="url"
        type="url"
        disabled={uploadType === 'file'}
        onChange={onChooseUrl}
        label="Faculty URL"
        placeholder="e.g. https://www.acadeum.com/files/faculty-credential.pdf"
      />
      <Alert>
        Accepted Documents: <br />
        <List unstyled>
          <List.Item>
            - Faculty information form used for an accrediting body (SACS Four-column Form),
          </List.Item>
          <List.Item>
            - Curriculum Vitae,
          </List.Item>
          <List.Item>
            - Completed version of the Faculty Information Form.
          </List.Item>
        </List>
      </Alert>
    </FormModal>
  );
}

function EditFacultyCredentialModal({
  show,
  onHide,
  credential,
  refresh
}) {
  const [enterUrl, setEnterUrl] = useState(false);

  // eslint-disable-next-line
  const [uploadNewFile, setUploadNewFile] = useState(false);
  const [uploadType, setUploadType] = useState<'file' | 'url' | null>(credential.url ? 'url' : 'file');

  const onClose = () => {
    setEnterUrl(false);
    setUploadNewFile(false);
    setUploadType(null);
    onHide();
  };

  const onSubmit: OnSubmit<AddFacultyCredentialFormValues> = async ({ url, name, file }) => {
    if ((uploadType === 'url' && !url) || (uploadType === 'file' && !file)) {
      throw new Error('Please choose a file or enter a URL');
    }

    // eslint-disable-next-line
    const payload: any = {
      name,
      type: 'FACULTY_CREDENTIAL'
    };
    if (uploadType === 'url' && url) {
      payload.url = url;
    }
    if (uploadType === 'file' && file) {
      payload.file = await convertFileToBase64(file);
    }
    await updateCourseResource(credential.id, payload);
    await refresh();
    toast.success('Faculty credential updated! ' + ALGOLIA_UPDATE_PENDING_NOTE);
  };

  const onEnterUrl = () => {
    setEnterUrl(true);
  };

  return (
    <FormModal
      show={show}
      onHide={onClose}
      title="Edit Faculty Credential"
      submitText="Update"
      onSubmit={onSubmit}
    >
      <FormField
        required
        name="name"
        label="Faculty Name"
        placeholder="e.g. Josh Smith"
        defaultValue={credential.name}
      />

      {enterUrl ? (
        <FormField
          name="url"
          type="url"

          label="Faculty URL"
          placeholder="e.g. https://www.acadeum.com/files/faculty-credential.pdf"
          defaultValue={credential.url}
        />
      ) : (
        <>
          <FormField
            name="file"
            type="file"
            accept={FILE_UPLOAD_EXTENSIONS}
          />


          <Separator mt="none" mb="sm">or</Separator>

          <ButtonGroup fullWidth>
            <Button
              variant="text"
              onClick={onEnterUrl}
            >
              Enter url
            </Button>
          </ButtonGroup>
          <br />
        </>
      )}

      <Alert>
        Accepted Documents: <br />
        <List unstyled>
          <List.Item>
            - Faculty information form used for an accrediting body (SACS Four-column Form),
          </List.Item>
          <List.Item>
            - Curriculum Vitae,
          </List.Item>
          <List.Item>
            - Completed version of the Faculty Information Form.
          </List.Item>
        </List>
      </Alert>
    </FormModal>
  );
}


function RemoveFacultyCredentialModal({
  show,
  onHide,
  credential,
  refresh
}) {
  const onRemove = async () => {
    await deleteCourseResource(credential.id);
    await refresh();
    toast.success('Faculty credential removed! ' + ALGOLIA_UPDATE_PENDING_NOTE);
    onHide();
  };

  return (
    <ConfirmActionModal
      danger
      title="Remove Faculty Credential"
      onSubmit={onRemove}
      show={show}
      onHide={onHide}
      onCancel={onHide}
      submitText="Remove"
      description={(
        <Text>
          Are you sure you want to remove faculty credential for <strong>{credential.name}</strong>?
          {' '}
          <Text as="span" color="danger">
            This action cannot be undone.
          </Text>
        </Text>
      )}
    />
  );
}
