/**
 * Gets the fee amount for a given cost and a fee calculation formula ("pricing variant").
 * @param {number} cost
 * @param {object} pricing variant
 * @return {number} fee
 */
export default function getFeeForCostUsingPricingVariant(cost, pricing) {
  // Validate `cost` argument.
  if (typeof cost !== 'number') {
    throw new Error('`cost` is required');
  }

  switch (pricing.model) {
    case 'percent-fee':
      return cost * (pricing.value / 100);
    case 'fixed-fee':
      return pricing.value;
    case 'as-is':
      return 0;
    default:
      throw new Error(`Unsupported pricing: ${JSON.stringify(pricing, null, 2)}`);
  }
}

// This function is currently not used but it could potentially be used in some future.
//
// /**
//  * (exported just for tests)
//  * Calculates the lowest fee for the original cost using a set of possible "pricing variants".
//  * @param {number} cost
//  * @param {object[]} pricingVariants
//  * @param {object} [parameters] — A object having parameters for pricing variants. Example for course cost: `{ credits: number }`.
//  * @return {number} [fee]
//  */
// export default function getLowestFeeForCostUsingPricingVariants(cost, pricingVariants, parameters) {
//   // Validate `cost` parameter.
//   if (typeof cost !== 'number') {
//     throw new Error('`cost` is required');
//   }
//
//   const fees = pricingVariants.map((pricingVariant) => {
//     return getFeeForCostUsingPricingVariant(cost, pricingVariant, parameters);
//   }).filter(_ => _ !== undefined);
//
//   if (fees.length > 0) {
//     return Math.min(...fees);
//   }
// }
