import type { MutableRefObject } from 'react';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Scrollable } from '../Scrollable';

import { useUpdateEffect } from '@acadeum/hooks';

import styles from './Tabs.module.scss';

export interface TabsProps<K extends string = string> {
  title: string;
  initialActiveTab?: number | K;
  tabs: Array<{
    key: K;
    title: string;
    body?: React.ReactNode | React.ComponentType
  }>;
  activeTab?: number | K | null;
  onChangeTab?: (options: { key: K; index: number; }) => void;
  theme?: 'black';
  // Always keep the not active tabs in the DOM. Default: true
  keepMounted?: boolean;
}

export const Tabs = <K extends string>({
  title,
  tabs,
  initialActiveTab,
  activeTab: propsActiveTab,
  onChangeTab: propsOnChangeTab,
  theme,
  keepMounted = true
}: TabsProps<K>) => {
  const listRef = useRef() as MutableRefObject<HTMLDivElement>;

  const getInitialActiveTab = (): number => {
    if (typeof propsActiveTab === 'number') {
      return propsActiveTab;
    }

    if (typeof propsActiveTab === 'string') {
      const index = tabs.findIndex(tab => tab.key === propsActiveTab);
      return index > -1 ? index : 0;
    }

    if (typeof initialActiveTab === 'number') {
      return initialActiveTab;
    }

    if (typeof initialActiveTab === 'string') {
      const index = tabs.findIndex(tab => tab.key === initialActiveTab);
      return index > -1 ? index : 0;
    }
    return 0;
  };

  const [activeTab, setActiveTab] = useState<number>(getInitialActiveTab);

  useUpdateEffect(() => {
    if (propsActiveTab && propsActiveTab !== activeTab) {
      setActiveTab(getInitialActiveTab());
    }
  }, [propsActiveTab]);

  const onChangeTab = (index: number) => {
    setActiveTab(index);
    if (propsOnChangeTab) {
      propsOnChangeTab({ key: tabs[index].key, index });
    }
  };

  const moveIndex = (step) => {
    let index = activeTab;
    const tabs = [...listRef.current.children] as HTMLButtonElement[];
    if (step === 'start') {
      index = 0;
    } else if (step === 'end') {
      index = tabs.length - 1;
    } else {
      const nextIndex = index + step;
      const tabsLength = tabs.length - 1;

      if (nextIndex > tabsLength) {
        index = 0;
      } else if (nextIndex < 0) {
        index = tabsLength;
      } else {
        index = nextIndex;
      }
    }

    onChangeTab(index);
    tabs[index].focus();
  };

  const onKeyDown = (event) => {
    switch (event.keyCode) {
      case 35: // End
        event.preventDefault();
        return moveIndex('end');
      case 36: // Home
        event.preventDefault();
        return moveIndex('start');
      case 37: // Arrow Left
        return moveIndex(-1);
      case 39: // Arrow right
        return moveIndex(1);
    }
  };

  return (
    <div className={styles.tabs}>
      <Scrollable>
        <div
          className={classNames(styles.tabs__list, {
            [styles['tabs__listBlack']]: theme === 'black'
          })}
          role="tablist"
          ref={listRef}
          aria-label={title}
        >
          {tabs.map((tab, index) => (
            <button
              role="tab"
              type="button"
              key={tab.key}
              id={tab.key}
              tabIndex={activeTab === index ? 0 : -1}
              aria-selected={activeTab === index}
              aria-controls={tab.key + '-tab'}
              className={styles.tabs__link}
              onClick={() => onChangeTab(index)}
              onKeyDown={onKeyDown}
            >
              {tab.title}
            </button>
          ))}
        </div>
      </Scrollable>
      {tabs.map((tab, index) => {
        if (!keepMounted && activeTab !== index) {
          return null;
        }

        return (
          <div
            key={tab.key}
            id={tab.key + '-tab'}
            role="tabpanel"
            tabIndex={0}
            aria-labelledby={tab.key}
            className={styles.tabs__section}
            hidden={activeTab !== index}
          >
            {typeof tab.body === 'function' ? <tab.body/> : tab.body}
          </div>
        );
      })}
    </div>
  );
};

Tabs.propTypes = {
  title: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    body: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.elementType
    ])
  }).isRequired).isRequired,
  initialActiveTab: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
};
