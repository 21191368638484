import { useSelector } from 'react-redux';

import { useTranslate, useTranslateOptions } from '@acadeum/translate';
import { ToggleButton } from '@acadeum/ui';

import { getStudentEnrollments, LIVE_ENROLLMENT_STATUSES_GROUPED_BY_TYPE } from '../../../../helpers/enrollments';
import type { ReduxState } from '../../../../helpers/app.types';
import actions from '../../../../actions';

import { useExportDataColumns } from '../../useExportDataColumns';
import styles from '../../Enrollments.module.scss';

import EnrollmentsTable from '../EnrollmentsTable';

import canDropFromCourseEnrollment from 'common-lib/lib/canDropFromCourseEnrollment.js';
import canWithdrawFromCourseEnrollment from 'common-lib/lib/canWithdrawFromCourseEnrollment.js';

const { fetchEnrollmentRequestsEMForExport, goto } = actions;

type EnrollmentType = 'live' | 'finalized';

const STATUSES: { [Key in EnrollmentType]: string[] } = {
  live: ['REQUESTED', 'ACTIVE', 'PENDING', 'DUE'],
  finalized: ['COMPLETE', 'DENIED', 'WITHDRAWN', 'DROPPED', 'REMOVED']
};

export interface HomeEnrollmentsProps {
  status: EnrollmentType;
}

export const HomeEnrollments = ({ status = 'live' }: HomeEnrollmentsProps) => {
  const t = useTranslate('Enrollments');

  const enrollmentRequests = useSelector((state: ReduxState) => state.enrollmentRequests.enrollmentRequests);

  const toggleOptions = useTranslateOptions<EnrollmentType>('Enrollments', ['live', 'finalized']);

  const exportDataColumnsLive = useExportDataColumns();
  const exportDataColumnsFinalized = useExportDataColumns({ finalized: true });
  const exportDataColumns = status === 'live' ? exportDataColumnsLive : exportDataColumnsFinalized;
  const exportDataFileName = status === 'live' ? t('fileName.HILive') : t('fileName.HIFinalized');

  const sections = getSections(t, status);

  return (
    <>
      <ToggleButton
        value={status}
        onChange={(type) => goto(`/enrollments/${type}`)}
        label={t('title')}
        options={toggleOptions}
        className={styles.Enrollments__toggle}
      />
      <EnrollmentsTable
        institutionType="home"
        enrollmentType={status}
        data={getStudentEnrollments(enrollmentRequests).filter(_ => STATUSES[status].includes(_.status))}
        sections={sections}
        fetchDataForExport={fetchDataForExport(status)}
        exportDataColumns={exportDataColumns}
        exportDataFileName={exportDataFileName}
      />
    </>
  );
};

const fetchDataForExport = (status) => async (ids, { rowsIds }) => {
  return getStudentEnrollments(
    await fetchEnrollmentRequestsEMForExport(ids.length > 0 ? ids : rowsIds)
  ).filter(_ => STATUSES[status].includes(_.status));
};

const getSections = (t, status) => {
  if (status === 'live') {
    return [
      {
        title: t('status.home.live.requested.title'),
        tooltip: t('status.home.live.requested.tooltip'),
        filter: _ => LIVE_ENROLLMENT_STATUSES_GROUPED_BY_TYPE.Requested.includes(_.status),
        getStatusTransitions() {
          return statusTransitions.REQUESTED;
        }
      }, {
        title: t('status.home.live.accepted.title'),
        tooltip: t('status.home.live.accepted.tooltip'),
        filter: _ => LIVE_ENROLLMENT_STATUSES_GROUPED_BY_TYPE.Accepted.includes(_.status),
        getStatusTransitions(studentEnrollment) {
          switch (studentEnrollment.status) {
            case 'ACTIVE':
              if (canDropFromCourseEnrollment({
                status: studentEnrollment.status,
                onDemand: studentEnrollment.section.session.course.onDemand,
                onDemandDropDateDaysAfterStartDate: studentEnrollment.section.session.course.institution.onDemandCourseDropDateDaysAfterStartDate,
                startedAt: studentEnrollment.startedAt,
                lastDropDate: studentEnrollment.section.session.lastDropDate
              })) {
                return statusTransitions.ACTIVE_CAN_DROP;
              } else if (canWithdrawFromCourseEnrollment({
                status: studentEnrollment.status,
                onDemand: studentEnrollment.section.session.course.onDemand,
                onDemandDropDateDaysAfterStartDate: studentEnrollment.section.session.course.institution.onDemandCourseDropDateDaysAfterStartDate,
                startedAt: studentEnrollment.startedAt,
                lastDropDate: studentEnrollment.section.session.lastDropDate,
                lastWithdrawalDate: studentEnrollment.section.session.lastWithdrawalDate
              })) {
                return statusTransitions.ACTIVE_CAN_WITHDRAW;
              } else {
                return [];
              }
            case 'PENDING':
              return statusTransitions.PENDING;
            default:
              return [];
          }
        }
      }
    ];
  }
  if (status === 'finalized') {
    return [
      {
        title: t('status.home.finalized.complete.title'),
        tooltip: t('status.home.finalized.complete.tooltip'),
        filter: _ => _.status === 'COMPLETE'
      }, {
        title: t('status.home.finalized.denied.title'),
        tooltip: t('status.home.finalized.denied.tooltip'),
        filter: _ => _.status === 'DENIED'
      }, {
        title: t('status.home.finalized.withdrawn.title'),
        tooltip: t('status.home.finalized.withdrawn.tooltip'),
        filter: _ => _.status === 'WITHDRAWN'
      }, {
        title: t('status.home.finalized.dropped.title'),
        tooltip: t('status.home.finalized.dropped.tooltip'),
        filter: _ => _.status === 'DROPPED'
      }, {
        title: t('status.home.finalized.removed.title'),
        tooltip: t('status.home.finalized.removed.tooltip'),
        filter: _ => _.status === 'REMOVED'
      }
    ];
  }
  return [];
};

const statusTransitions = {
  REQUESTED: ['REMOVED'],
  ACTIVE_CAN_DROP: ['DROPPED'],
  ACTIVE_CAN_WITHDRAW: ['WITHDRAWN'],
  PENDING: ['DROPPED']
};
