import type { FC } from 'react';
import React from 'react';

import { useTranslate } from '@acadeum/translate';
import { Page, PageLoader } from '@acadeum/ui';
import type { UseFetchUserRolesQuery } from '@acadeum/api';

import { useOnError } from '../../../providers/useOnError';

import type { AddUserFormProps } from './ui/AddUserForm';
import { AddUserForm } from './ui/AddUserForm';
import { useSettingsRoutes } from '../../../hooks/useSettingsRoutes';
import { useApp } from '../../../providers/useApp';

export interface AddUserPageProps extends Pick<AddUserFormProps, 'useCreateUserMutation'> {
  useFetchUserRolesQuery: UseFetchUserRolesQuery;
}

export const AddUserPage: FC<AddUserPageProps> = ({
  useFetchUserRolesQuery,
  useCreateUserMutation
}) => {
  const t = useTranslate('shared-admin-ui.AddUserPage');
  const onError = useOnError();
  const { getSettingsUrl, getUserManagementUrl } = useSettingsRoutes();
  const { app } = useApp();

  const { data, error } = useFetchUserRolesQuery({
    pageSize: 1000
  });
  const roles = data?.results;

  if (error) {
    onError(error);
  }

  return (
    <Page
      title={t('title')}
      breadcrumbs={[
        [t(app === 'admin' ? 'general' : 'settings', { global: true }), getSettingsUrl()],
        [t('userManagement'), getUserManagementUrl()],
        t('title')
      ]}
    >
      {roles ? (
        <AddUserForm
          roles={roles}
          useCreateUserMutation={useCreateUserMutation}
        />
      ) : (
        <PageLoader/>
      )}
    </Page>
  );
};
