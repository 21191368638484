import React from 'react';
import PropTypes from 'prop-types';

import RemoveIcon from './RemoveIcon.svg';

import styles from './Tag.module.scss';

const Tag = ({
  children,
  onClick
}) => {
  // prevents lost of focus on Textfield
  const onMouseDown = (event) => {
    event.preventDefault();
  };

  return (
    <span className={styles.Tag}>
      <span title={children}>
        {children}
      </span>
      <button
        type="button"
        aria-label={'Remove ' + children}
        onClick={onClick}
        onMouseDown={onMouseDown}
      >
        <RemoveIcon aria-hidden="true" focusable="false"/>
      </button>
    </span>
  );
};

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Tag;
