import { ReduxModule } from 'react-pages';

import { getAlgoliaIndex } from '../helpers/algolia';

const redux = new ReduxModule();

export const fetchSection = redux.action(
  (id, { user }) => async () => {
    const section = await getAlgoliaIndex(user ? 'sections' : 'public-sections').get(id);
    if (!section) {
      const error = new Error(`Section not found: ${id}`);
      error.statusCode = 404;
      throw error;
    }
    section.session.startDate = new Date(section.session.startDate);
    section.session.endDate = new Date(section.session.endDate);
    section.session.lastAddDate = new Date(section.session.lastAddDate);
    section.session.lastDropDate = new Date(section.session.lastDropDate);
    for (const session of section.session.course.sessions) {
      session.startDate = new Date(session.startDate);
      session.endDate = new Date(session.endDate);
      session.lastAddDate = new Date(session.lastAddDate);
      // session.lastDropDate = new Date(session.lastDropDate);
    }
    return section;
  },
  'section'
);

export const deactivateSection = redux.action(
  (id, { notes }) => async (http) => {
    await http.post(`/sections/${id}/deactivate`, { notes });
  }
);

export const requestCourseSection = redux.action(
  (data) => (http) => {
    Intercom('trackEvent', 'Course Section Request', data);
    return http.post('/sections/request', data);
  }
);

export default redux.reducer();
