import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const fetchSearchIndexUpdates = redux.action(
  ({ page, pageSize }) => (http) => http.get('/search-index-updates', {
    page,
    pageSize
  }),
  'searchIndexUpdates'
);

export const fetchSearchIndexUpdate = redux.action(
  (id) => (http) => http.get(`/search-index-updates/${id}`),
  'searchIndexUpdate'
);

export default redux.reducer();
