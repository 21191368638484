import React, { useState } from 'react';

import { EyeIcon, EyeSlashIcon } from '@acadeum/icons';

import type { InputProps } from '../Input';
import { BaseButton } from '../BaseButton';
import { Input } from '../Input';
import { Icon } from '../Icon';

export const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const [type, setType] = useState<'password' | 'text'>('password');
  const onClick = () => setType(type === 'password' ? 'text' : 'password');

  return (
    <Input
      {...props}
      ref={ref}
      type={type}
      addonSuffix={(
        <BaseButton
          onClick={onClick}
          title={type === 'password'
            ? 'Show password as plain text. Warning: this will display your password on the screen.'
            : 'Hide password.'
          }
        >
          <Icon icon={type === 'password' ? EyeSlashIcon : EyeIcon}/>
        </BaseButton>
      )}
    />
  );
});
