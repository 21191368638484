export default function validatePassword (value) {
  if (value.length < 8) {
    return 'tooShort';
  }
  if (
    // Test for having an Arabic digit.
    // https://en.wikipedia.org/wiki/Arabic_numerals#See_also
    !/\d/.test(value) ||
    // Test for having an uppercase latin letter.
    // https://www.lonelyplanet.com/thorntree/forums/speaking-in-tongues/topics/why-do-some-languages-have-upper-lower-case-and-some-don-t-r
    !/[A-Z]/.test(value) ||
    // Test for having a "special" character (non-Arabic digit, non-latin letter).
    !/[^A-Za-z0-9]/.test(value)
  ) {
    return 'tooWeak';
  }
}
