// Alternatively to Ace, the editor component could be using
// https://www.npmjs.com/package/react-simple-code-editor

import type { MutableRefObject } from 'react';
import React, { useRef, useImperativeHandle } from 'react';
import classNames from 'classnames';

import AceEditor from 'react-ace';

// import brace from 'brace';
import 'brace/mode/json.js';
import 'brace/theme/github.js';

// import Button from './Button.js';

import type { JSONEditorRef, JSONEditorProps} from './types';
import { propTypes } from './types';

import styles from './JSONEditor.module.scss';

const aceEditorSettings = {
  $blockScrolling: true
};

// `width` and `height` can't be set via CSS
// because this editor calculates upon those two.
// `fontSize` is similar in that sense.
//
// `onBlur` handler of `redux-form` is fixed via a workaround.
// https://github.com/securingsincity/react-ace/issues/283
// As of 24.10.2017 there's no way of obtaining `value` from `onBlur(event)`.
const JSONEditor = React.forwardRef<JSONEditorRef, JSONEditorProps>(({
  height = 512,
  disabled,
  // required,
  value,
  onChange,
  className,
  ...rest
}, ref) => {
  const editorRef = useRef<AceEditor>(null);
  const containerRef = useRef() as MutableRefObject<HTMLDivElement>;

  // `focus()` and `getDOMNode()` functions are used by `easy-react-form`:
  // * `focus()` focuses the field if it's not valid.
  // * `getDOMNode()` is used to scroll to the input field if it's not visible.
  //
  // `<AceEditor/>` doesn't accept a `ref`.
  // https://github.com/securingsincity/react-ace/blob/master/src/ace.tsx
  //
  useImperativeHandle(ref, () => ({
    focus: () => editorRef.current?.editor.focus(),
    getDOMNode: () => containerRef.current
  }), [editorRef, containerRef]);

  // const [isEditing, setEditing] = useState(!required && value === 'null' ? false : true);

  // const onEdit = () => {
  //   setEditing(true);
  // };

  // const onClear = () => {
  //   onChange('null');
  //   setEditing(false);
  // };

  return (
    <>
      {/*
      {!required && value === 'null' && !isEditing &&
        <div>
          Value not set
          <Button onClick={onEdit}>
            Edit
          </Button>
        </div>
      }
      {!required && isEditing &&
        <div>
          <Button onClick={onClear}>
            Clear Value
          </Button>
        </div>
      }
      */}
      <div ref={containerRef} className={classNames(styles.JSONEditor, className)}>
        <AceEditor
          ref={editorRef}
          className={styles.JSONEditor__editor}
          mode="json"
          theme="github"
          editorProps={aceEditorSettings}
          wrapEnabled
          showPrintMargin={false}
          tabSize={2}
          fontSize={16}
          width="100%"
          height={`${height}px`}
          readOnly={!!disabled}
          value={value ? value : undefined}
          onChange={onChange}
          {...rest}
        />
      </div>
    </>
  );
});

JSONEditor.propTypes = propTypes;

export default JSONEditor;
