import React, { useState } from 'react';

import Field from '../../components/Field';
import Form from '../../components/Form';
import FormButtons from '../../components/FormButtons';
import FormSubmit from '../../components/FormSubmit';
import Section from '../../components/Section';

import actions from '../../actions';

const {
  changeEnrollmentStatus,
  fetchEnrollment,
  notify
} = actions;

export default function ChangeEnrollmentStatus() {
  const [enrollment, setEnrollment] = useState();

  async function onSubmitFindEnrollment({ id }) {
    setEnrollment(await fetchEnrollment(id));
  }

  async function onSubmit({ status }) {
    await changeEnrollmentStatus(enrollment.id, status);
    notify('The status has been updated');
    setEnrollment();
    // Reload the page to get the current user re-fetched
    // window.location = '/';
  }

  return (
    <Section title="Change Enrollment Status">
      {!enrollment &&
        <Form
          autoFocus
          className="form"
          onSubmit={onSubmitFindEnrollment}>

          <Field
            name="id"
            required
            label="Enrollment ID"
          />

          <FormButtons>
            <FormSubmit>
              Find
            </FormSubmit>
          </FormButtons>
        </Form>
      }

      {enrollment &&
        <>
          <strong>ID</strong>: {enrollment.id}
          <br/>
          <strong>Status</strong>: {enrollment.status}
          <br/>
          <strong>Name</strong>: {enrollment.student.firstName} {enrollment.student.lastName}
          <br/>
          <strong>Email</strong>: {enrollment.student.email}
          <br/>
          <strong>School</strong>: {(enrollment.enrollmentRequest || enrollment.student).institution.name}
          <br/>
          <strong>Section</strong>: {(enrollment.enrollmentRequest || enrollment).section.session.term} {(enrollment.enrollmentRequest || enrollment).section.session.name} {(enrollment.enrollmentRequest || enrollment).section.number}
          <br/>
          <strong>Course</strong>: {(enrollment.enrollmentRequest || enrollment).section.session.course.code} {(enrollment.enrollmentRequest || enrollment).section.session.course.title}
          <br/>
          <br/>
        </>
      }

      {enrollment &&
        <Form
          autoFocus
          className="form"
          onSubmit={onSubmit}>

          <Field
            required
            name="status"
            label="New Status"
            placeholder="Select a status"
            options={STATUSES.map((status) => ({
              label: status,
              value: status
            }))}
          />

          <FormButtons>
            <FormSubmit>
              Save
            </FormSubmit>
          </FormButtons>
        </Form>
      }
    </Section>
  );
}

ChangeEnrollmentStatus.meta = () => ({
  title: 'Change Enrollment Status'
});

ChangeEnrollmentStatus.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Change Enrollment Status'
];

const STATUSES = [
  'ACTIVE',
  'CANCELLED',
  'COMPLETE',
  'DENIED',
  'DROPPED',
  'DUE',
  'PENDING',
  'REMOVED',
  'REQUESTED',
  'WITHDRAWN'
];
