import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button as RRUIButton } from 'react-responsive-ui';
import Link from '../Link';

import actions from '../../actions';

import './Button.sass';

const { notifyError } = actions;

export default function Button({
  disabled,
  secondary,
  danger,
  border,
  variant,
  link,
  openInNewTab,
  wide,
  text,
  inline,
  tableRowAction,
  onClick,
  wait: waitProperty,
  className,
  children,
  ...rest
}) {
  const [wait, setWait] = useState();
  const isMounted = useRef();

  disabled = disabled || wait || waitProperty;

  useEffect(() => {
    isMounted.current = true;
    return () => isMounted.current = false;
  }, []);

  className = classNames(className, 'AcadeumButton', 'form__button', {
    'form__button--primary': !secondary && !danger && !text && variant !== 'dark' && variant !== 'light',
    'form__button--secondary': secondary && !text,
    'form__button--danger': danger,
    'form__button--textSecondary': secondary && text,
    'form__button--border': border && !variant,
    'form__button--wide': wide,
    'form__button--link': link,
    'form__button--text': text,
    'form__button--inline': inline,
    'form__button--dark': !border && variant === 'dark',
    'form__button--light': !border && variant === 'light',
    'form__button--borderDark': border && variant === 'dark',
    'form__button--tableRowAction': tableRowAction
  });

  if (link) {
    return (
      <Link
        {...rest}
        target={openInNewTab ? '_blank' : undefined}
        disabled={disabled}
        onClick={onClick}
        to={link}
        className={className}>
        {children}
      </Link>
    );
  }

  function onClickHandler(event) {
    const result = onClick(event);
    // If `onClick` returned a `Promise`
    // then track this `Promise`'s progress.
    if (result && typeof result.then === 'function') {
      setWait(true);
      result.then(
        () => {
          if (isMounted.current) {
            setWait(false);
          }
        },
        (error) => {
          if (isMounted.current) {
            setWait(false);
          }
          console.error(error);
          notifyError(error.message);
        }
      );
    }
  }

  return (
    <RRUIButton
      {...rest}
      type={onClick ? 'button' : 'submit'}
      onClick={onClick ? onClickHandler : undefined}
      wait={wait || waitProperty}
      disabled={disabled}
      className={className}>
      {children}
    </RRUIButton>
  );
}

Button.propTypes = {
  // `wait` property is passed by `easy-react-form`
  // when the form is being submitted.
  wait: PropTypes.bool,
  wide: PropTypes.bool,
  secondary: PropTypes.bool,
  danger: PropTypes.bool,
  border: PropTypes.bool,
  disabled: PropTypes.bool,
  link: PropTypes.string,
  openInNewTab: PropTypes.bool,
  text: PropTypes.bool,
  inline: PropTypes.bool,
  variant: PropTypes.oneOf(['dark', 'light']),
  tableRowAction: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};
