import type { FC } from 'react';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import classNames from 'classnames';

import { CloseButton } from '../CloseButton';
import { Button } from '../Button';
import { FormRow } from '../FormRow';
import { PlusIcon } from '@acadeum/icons';

import useScreenSize from '../../utils/useScreenSize';

import styles from './FormFieldArray.module.scss';
import { Alert } from '../Alert';

export interface FormFieldArrayProps {
  children: (props: { index: number }) => React.ReactNode;
  name: string;
  border?: boolean;
  allowEmpty?: boolean;
  addButtonText?: string;
  max?: number;
}

export const FormFieldArray: FC<FormFieldArrayProps> = ({
  children,
  name,
  addButtonText = 'Add More',
  border = true,
  allowEmpty,
  max
}) => {
  const { fields, append, remove } = useFieldArray({ name });

  return (
    <div className={classNames(styles.FormFieldArray, {
      [styles.withBorder]: border
    })}>
      {fields.map((item, index) => (
        <fieldset key={item.id} className={styles.fieldWrapper}>
          {(allowEmpty || fields.length > 1) && (
            <CloseButton
              size="sm"
              onClick={() => remove(index)}
              className={styles.closeButton}
            />
          )}

          <FormRow className={styles.FormRow}>
            {children({ index })}
          </FormRow>
        </fieldset>
      ))}

      {(typeof max === 'number' ? max > fields.length : true) && (
        <AddButton
          addButtonText={addButtonText}
          onClick={() => append({})}
        />
      )}

      <Alert show={typeof max === 'number' && max === fields.length}>
        You’ve reached the limit. It’s possible to add up to {max} fields.
      </Alert>
    </div>
  );
};

FormFieldArray.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  addButtonText: PropTypes.string.isRequired,
  border: PropTypes.bool
};

function AddButton({
  addButtonText,
  onClick: propsOnClick
}) {
  const { isSmallScreen } = useScreenSize();

  const onClick = useCallback(() => {
    if (propsOnClick) {
      propsOnClick();
    }
  }, [propsOnClick]);

  return (
    <Button
      icon={PlusIcon}
      variant={isSmallScreen ? 'secondary' : 'text'}
      size={isSmallScreen ? undefined : 'small'}
      onClick={onClick}
      className={styles.addButton}
    >
      {addButtonText}
    </Button>
  );
}
