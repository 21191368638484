/**
 * The `t()` function is for simpler cases
 * when advanced `react-intl` features
 * (such as plurals, number formatting, React "tags")
 * aren't required.
 * @param  {string} key
 * @param  {object} [params]
 * @param  {function} getMessages
 * @param  {function} getTranslationLanguage
 * @return {string}
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function t(key: string, params, {
  getMessages,
  getTranslationLanguage
}) {
  const language = getTranslationLanguage();
  const messages = getMessages(language);
  let value = messages[key];
  if (value !== undefined) {
    // Substitute params.
    if (params) {
      for (const param of Object.keys(params)) {
        // Avoids infinite recursion.
        if (params[param] === '{' + param + '}') {
          continue;
        }
        while (value.indexOf('{' + param + '}') >= 0) {
          value = value.replace('{' + param + '}', params[param]);
        }
      }
    }
    return value;
  }
  const message = `Translation not found for language "${language}" for key: ${key}`;
  console.error(message);
  // Report client-side errors to sentry.io
  if (typeof window !== 'undefined') {
    setTimeout(() => {
      throw new Error(message);
    }, 0);
  }
  return key;
}
