import React from 'react';
import { Autocomplete } from 'react-responsive-ui';

export default function renderAutocomplete({
  options,
  error
}, rest) {
  return (
    <Autocomplete
      autoComplete="off"
      options={options}
      highlightFirstOption={false}
      error={error}
      {...rest}
    />
  );
}
