import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

// import Header from '../Header';
import RequestCourseBanner from '../RequestCourseBanner';
import AcadeumBackground from '../AcadeumBackground';
import RequestMoreInfoModal from '../RequestMoreInfoModal';

import useLocation from '../../hooks/useLocation';
import normalizePath from '../../helpers/normalizePath';

import './VisitorLayout.sass';

export default function VisitorLayout({
  background,
  margin = true,
  marginTop,
  marginBottom,
  children
}) {
  const location = useLocation();
  const path = normalizePath(location.pathname);

  const { user } = useSelector(state => state.auth);
  // const { institution } = useSelector(state => state.institutions.institution);

  // Show "Request course" footer if it's a teaching institution page
  const showRequestCourseBanner = user ? (
    // // If it's the main page
    // path === '/'
    //  ||
    // If it's the "Courses" page
    path === '/courses/'
    // ||
    // // If it's a teaching institution profile page
    // (path !== '/institutions/add/' && /\/institutions\/[^\/]+\/$/.test(path) && institution && institution.isTeaching)
  ) : undefined;

  children = (
    <div className="main-content">
      {children}
    </div>
  );

  if (background) {
    children = (
      <AcadeumBackground>
        {children}
      </AcadeumBackground>
    );
  }

  return (
    <div className={classNames('Layout', 'VisitorLayout')}>
      <div
        className={classNames('WebpageContent', {
          'WebpageContent--withVerticalMargins': margin === true || margin === 'vertical',
          'WebpageContent--noTopMargin': marginTop === false,
          'WebpageContent--noBottomMargin': marginBottom === false,
          'WebpageContent--marginTopLarge': marginTop === 'large',
          'container': margin === true
        })}>
        {children}
      </div>

      {/* Footer ("Request a Course"). */}
      {showRequestCourseBanner && (
        <RequestCourseBanner/>
      )}

      <RequestMoreInfoModal/>
    </div>
  );
}

VisitorLayout.propTypes = {
  background: PropTypes.bool,
  margin: PropTypes.oneOf([false, true, 'vertical']),
  marginTop: PropTypes.oneOf([false, 'large']),
  marginBottom: PropTypes.oneOf([false]),
  children: PropTypes.node
};
