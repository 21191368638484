import LABELS_EN from '../messages/en/Race.json' assert { type: 'json' };

export default function formatRace(race, { language, input, own }) {
  const LABELS = LABELS_EN;
  if (race === '-') {
    return input && own ? LABELS.preferUnspecified : LABELS.unspecified;
  }
  return LABELS.race[race] || race;
}

export const RACES = Object.keys(LABELS_EN.race);
