import React from 'react';
import uniq from 'lodash/uniq';
import capitalize from 'lodash/capitalize';

import type { RowData } from '@tanstack/react-table';

import { Select } from '../../../Select';

import type { FilterItemObject } from '../types';

export const createTableStatusFilter =  <TData extends RowData>({
  sort,
  getStatus = (row) => row['status']
}: {
  sort: (a: string, b: string) => number,
  getStatus?: (row: TData) => string
}): FilterItemObject<TData> => {
  return {
    id: 'status',
    filter(row, value) {
      return row['status'] === value;
    },
    render({ getValue, rows, setFilterValues }) {
      const statuses: string[] = uniq(rows.map(getStatus));
      if (sort) {
        statuses.sort(sort);
      }

      const options = [
        { label: 'All', value: null },
        ...statuses.map((status) => ({
          label: capitalize(status.replaceAll('_', ' ')),
          value: status
        }))
      ];

      return (
        <Select
          isFilter
          label="Status"
          value={getValue() ?? null}
          options={options}
          onChange={(value, option) => {
            setFilterValues(prev => ({
              ...prev,
              status: option
            }));
          }}
        />
      );
    }
  };
};
