// Splits a term into term name and year (if present).
export default function parseTerm(term) {
  const yearMatch = term.match(/ (\d{4})$/);
  if (!yearMatch) {
    return [term];
  }
  const year = parseInt(yearMatch[1]);
  const name = term.slice(0, -yearMatch[0].length);
  return [name, year];
}
