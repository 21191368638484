import { TextInput } from 'react-responsive-ui';

import PrePostFixInput from '../../PrePostFixInput';

export default function getCurrencyInputParams({
  onChange,
  currency,
  currencyFractional
}) {
  return {
    onChange: getCurrencyOnChange({
      onChange,
      currency,
      currencyFractional
    }),
    component: TextInput,
    InputComponent: PrePostFixInput,
    prefix: getCurrencyPrefix(currency)
  };
}

function getCurrencyPrefix(currency) {
  switch (currency) {
    case 'USD':
      return '$';
    default:
      throw new Error(`Unsupported currency: ${currency}`);
  }
}

function getCurrencyOnChange({
  onChange
}) {
  return (value) => {
    if (value) {
      value = fixCurrencyInput(value);
    }
    onChange(value);
  };
}

function fixCurrencyInput(input) {
  const hasDot = input.includes('.');
  const parts = input.split('.');
  if (parts.length === 0) {
    return;
  }
  // eslint-disable-next-line prefer-const
  let [leftPart, rightPart] = parts.map(text => text.replace(/\D/g, ''));
  // If the user has inserted a second "." somewhere in the middle
  // then discard everything after that ".".
  if (parts.length > 2) {
    return leftPart + '.';
  }
  if (rightPart) {
    if (rightPart.length > 2) {
      rightPart = rightPart.slice(0, 2);
    }
    return leftPart + '.' + rightPart;
  }
  return leftPart + (hasDot ? '.' : '');
}
