import React from 'react';
import { useSelector } from 'react-redux';

import { Link, List, Text } from '@acadeum/ui';
import { getAuthSelector } from '@acadeum/auth';

import CourseSearch_ from '../../components/CourseSearch';
import type { AppPage } from '../../helpers/app.types';

import image from './hs4cc.jpg';

import styles from './HS4CC.module.scss';

// https://acadeum.atlassian.net/browse/CS-400
const prefilters = {
  filters: [
    // An institution that should be excluded
    // https://acadeum.atlassian.net/browse/CS-400?focusedCommentId=12604
    'NOT session.course.institution.id:448',
    'session.course.institution.affiliations:\'Dual Enrollment Serving Institution\'',
    'session.course.division >= 0 AND session.course.division <= 200'
  ].join(' AND ')
};

const HS4CC: AppPage = () => {
  const { user } = useSelector(getAuthSelector);

  const CourseSearch = () => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <CourseSearch_
      view="sections"
      isHS4CC
      searchFieldBanner
      searchFieldBannerFullWidth
      prefilters={prefilters}
    />
  );

  return (
    <div>
      <div className={styles.banner}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={styles.columns}>
                <div>
                  <Text
                    as="h1"
                    mb="md"
                    variant="headingMd"
                  >
                    Welcome to the New HS4CC Portal for College Courses!
                  </Text>
                  <Text mb="sm">
                    Welcome to the HS4CC Dual Enrollment Member Portal which includes thousands of college courses from
                    dozens of regionally accredited colleges and universities! To join, your student must meet three
                    simple criteria:
                  </Text>
                  <List>
                    <List.Item>Homeschooled as per your state's laws.</List.Item>
                    <List.Item>Currently in grades 9-12.</List.Item>
                    <List.Item>A high school unweighted GPA of 3.0 or above.</List.Item>
                  </List>
                  <Text mt="sm">
                    Become a HS4CC member now and bypass traditional dual enrollment hurdles. Our members receive
                    discounted tuition, are exempt from placement testing, and not subject to grade level or GPA
                    requirements of individual colleges. Click
                    {' '}
                    <Link
                      external
                      monochrome
                      removeUnderline={false}
                      to="https://docs.google.com/forms/d/e/1FAIpQLSeAT7PTdTNzzW_dLtKUwACYx9pXzSiJmlMLR_SwMxtETY8jLw/viewform"
                    >
                      HERE
                    </Link>
                    {' '}
                    to register for membership and to gain access to
                    college courses within 2 business days.
                  </Text>
                </div>
                <div>
                  <img
                    alt=""
                    className={styles.logo}
                    src={image}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {user ? (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <CourseSearch/>
            </div>
          </div>
        </div>
      ) : (
        <CourseSearch/>
      )}
    </div>
  );
};

HS4CC.meta = () => ({
  title: 'HS4CC'
});

export default HS4CC;
