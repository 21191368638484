import type { FC } from 'react';
import React, { useMemo, useRef } from 'react';
import type { ColumnDef } from '@tanstack/react-table';

import formatUserName from 'common-lib/lib/formatUserName.js';

import { useTranslate } from '@acadeum/translate';
import {
  Actions,
  AppLoading,
  FormField,
  FormFieldWrapper,
  FormModal,
  Page,
  Select,
  Table,
  Tag,
  Text
} from '@acadeum/ui';
import { useQueryWithPagination, useToggle } from '@acadeum/hooks';
import type {
  FetchUserAccountRequestsOutput,
  UseApproveUserAccountRequestsMutation,
  UseDenyUserAccountRequestsMutation,
  UseFetchUserAccountRequestsQuery,
  UseFetchUserRolesQuery
} from '@acadeum/api';

import { useOnError } from '../../../providers/useOnError';

import styles from './UserRequestsPage.module.scss';
import { useSettingsRoutes } from '../../../hooks/useSettingsRoutes';
import { useApp } from '../../../providers/useApp';

type TableRow = FetchUserAccountRequestsOutput['results'][number];


export interface UserRequestsProps {
  useApproveUserAccountRequestsMutation: UseApproveUserAccountRequestsMutation;
  useDenyUserAccountRequestsMutation: UseDenyUserAccountRequestsMutation;
  useFetchUserAccountRequestsQuery: UseFetchUserAccountRequestsQuery;
  useFetchUserRolesQuery: UseFetchUserRolesQuery;
}

export const UserRequestsPage: FC<UserRequestsProps> = ({
  useFetchUserRolesQuery,
  useFetchUserAccountRequestsQuery,
  useDenyUserAccountRequestsMutation,
  useApproveUserAccountRequestsMutation
}) => {
  const t = useTranslate('shared-admin-ui.UserRequestsPage');
  const { getSettingsUrl } = useSettingsRoutes();
  const { app } = useApp();

  const onError = useOnError();
  const currentId = useRef<number>(0);

  const {
    value: showApproveUserModal,
    setTrue: openApproveUserModal,
    setFalse: closeApproveUserModal
  } = useToggle(false);

  const {
    value: showDenyUserModal,
    setTrue: openDenyUserModal,
    setFalse: closeDenyUserModal
  } = useToggle(false);

  const [approveUserAccountRequestsMutation] = useApproveUserAccountRequestsMutation();
  const [denyUserAccountRequestsMutation] = useDenyUserAccountRequestsMutation();

  const { data: rolesData } = useFetchUserRolesQuery({
    pageSize: 1000
  });
  const roles = rolesData?.results;

  const roleOptions = useMemo(() => {
    return roles?.map((role) => ({
      value: role.id,
      label: role.name,
      description: role.description
    }));
  }, [roles]);

  const {
    data,
    error,
    isFetching,
    isLoading,
    _globalFilterOptions,
    _paginationOptions,
    _additionalParamsOptions
  } = useQueryWithPagination(useFetchUserAccountRequestsQuery);
  const userAccountRequests = data?.results;

  const cols = useMemo<ColumnDef<TableRow>[]>(() => [
    {
      id: 'name',
      accessorFn: (row) => formatUserName(row),
      header: t('name'),
      cell: ({ row }) => formatUserName(row.original)
    },
    { header: t('email', { global: true }), accessorKey: 'email' },
    { header: t('requestDate'), accessorKey: 'createdAt' },
    { header: t('lastModerated'), accessorKey: 'updatedAt' },
    {
      header: t('status', { global: true }),
      accessorKey: 'status',
      cell: ({ row }) => <Tag variant={row.original.status}/>
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        return (
          <Actions
            disabled={row.original.status === 'APPROVED'}
            actions={[
              {
                title: t('approveAs'),
                onClick: () => {
                  currentId.current = row.original.id;
                  openApproveUserModal();
                }
              },
              {
                title: t('deny'),
                disabled: row.original.status === 'DENIED',
                onClick: () => {
                  currentId.current = row.original.id;
                  openDenyUserModal();
                }
              }
            ]}
          />
        );
      }
    }
  ], [openApproveUserModal, openDenyUserModal, t]);

  const onChooseStatus = (value) => {
    _additionalParamsOptions.onAdditionalParamsChange({
      types: value === 'ALL' ? undefined : [value]
    });
  };

  const onSubmitApproveUser = async ({ roleId }) => {
    await approveUserAccountRequestsMutation({
      ids: [currentId.current],
      roleIds: [roleId]
    }).unwrap();
    closeApproveUserModal();
  };

  const onSubmitDenyUser = async ({ notes }) => {
    await denyUserAccountRequestsMutation({
      ids: [currentId.current],
      notes
    });
    closeDenyUserModal();
  };

  if (error) {
    return onError(error);
  }

  return (
    <Page
      title={t('title')}
      breadcrumbs={[[t(app === 'admin' ? 'general' : 'settings', { global: true }), getSettingsUrl()], t('title')]}
    >
      {roles ? (
        <>
          <FormFieldWrapper id="doemmd-e3" label={t('status')}>
            <Select
              className={styles.UserAccountRequestsPage__status}
              name="status"
              options={[
                { value: 'ALL', label: t('all') },
                { value: 'PENDING', label: t('pending') },
                { value: 'APPROVED', label: t('approved') },
                { value: 'DENIED', label: t('denied') }
              ]}
              value={_additionalParamsOptions.additionalParams?.types?.[0] || 'ALL'}
              onChange={onChooseStatus}
            />
          </FormFieldWrapper>
          <Table
            enableSorting
            loading={isLoading}
            isFetching={isFetching}
            data={userAccountRequests}
            globalFilterOptions={_globalFilterOptions}
            paginationOptions={_paginationOptions}
            columns={cols}
            translate={{
              searchPlaceholder: t('searchPlaceholder'),
              resultText: ({ totalCount }) => t('resultText', { totalCount }),
              selectedResultText: ({ totalCount, selectedRowsCount }) => t('selectedResultText', {
                totalCount,
                selectedRowsCount
              })
            }}
          />
          <FormModal
            title={t('approveModalTitle')}
            submitText={t('save', { global: true })}
            show={showApproveUserModal}
            onHide={closeApproveUserModal}
            onCancel={closeApproveUserModal}
            onSubmit={onSubmitApproveUser}
          >
            <Text className={styles.UserAccountRequestsPage__subtitle}>
              {t('approveModalSubTitle')}
            </Text>
            <FormField
              required
              label={t('label')}
              type="select"
              name="roleId"
              placeholder={t('placeholder')}
              options={roleOptions}
            />
          </FormModal>

          <FormModal
            title={t('denyModalTitle')}
            show={showDenyUserModal}
            onHide={closeDenyUserModal}
            onCancel={closeDenyUserModal}
            onSubmit={onSubmitDenyUser}
          >
            <Text className={styles.UserAccountRequestsPage__subtitle}>
              {t('denyModalSubTitle')}
            </Text>
            <FormField
              required
              multiline
              label="Notes"
              name="notes"
            />
          </FormModal>
        </>
      ) : (
        <AppLoading inline/>
      )}
    </Page>
  );
};
