import {
  getCountryOptions,
  getCourseLevelOptions,
  getEthnicityOptions,
  getGenderOptions,
  getRaceOptions,
  getStudentLevelOptions,
  getTermOptions as getTermOptions_,
  getYesOrNoOptions
} from '@acadeum/helpers';

import { getTranslationLanguage } from '../translate';

const LANGUAGE = getTranslationLanguage();

export const YES_OR_NO_OPTIONS = getYesOrNoOptions({ language: LANGUAGE });
export const COUNTRY_OPTIONS = getCountryOptions({ language: LANGUAGE });
export const STUDENT_LEVEL_OPTIONS = getStudentLevelOptions({ language: LANGUAGE, other: true });
export const COURSE_LEVEL_OPTIONS_WITHOUT_OTHER = getCourseLevelOptions({ language: LANGUAGE });
export const GENDER_OPTIONS = getGenderOptions({ language: LANGUAGE });
export const RACE_OPTIONS = getRaceOptions({ language: LANGUAGE });
export const ETHNICITY_OPTIONS = getEthnicityOptions({ language: LANGUAGE });

export function getTermOptions() {
  return getTermOptions_({ language: LANGUAGE });
}
