import reportError from './reportError.js';

export default function validatePropertyValuesNotUndefined(properties) {
  for (const key of Object.keys(properties)) {
    if (properties[key] === undefined) {
      if (typeof window !== 'undefined') {
        reportError(`\`${key}\` property value is \`undefined\``);
      } else {
        throw new Error(`\`${key}\` property value is \`undefined\``);
      }
    }
  }
}
