export interface HttpError extends Error {
  message: string;
  status: number;
  data: {
    code?: string;
    errorMessage?: string;
    message?: string;
    type?: string;
    field?: string
    // If `type` is `"rate_limit"` then `value` is a timestamp of next attempt.
    value?: unknown;
  };
}

export class HTTPError extends Error implements HttpError {
  status: number;
  data: HttpError['data'];

  constructor(status: number, message: HttpError['message'], data: HttpError['data']) {
    super(message);
    this.status = status;
    this.data = data;
  }
}
