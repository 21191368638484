import React from 'react';
import PropTypes from 'prop-types';

import './FormHeading.sass';

export default function FormHeading({ children }) {
  return (
    <h1 className="form__heading">
      {children}
    </h1>
  );
}

FormHeading.propTypes = {
  children: PropTypes.node.isRequired
};
