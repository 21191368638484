import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import Link from '../Link';

import { useNotifications } from '../../api/notifications';

import './Notifications.sass';

export default function Notifications() {
  // Mostly the same as in the `<SiteIcon/>` component.
  const studentsNoRaceCount = useSelector((state) => state.students.studentsNoRaceCount);
  const notifications = useNotifications();
  const awaitingGradesCount = notifications?.awaitingGradesCount;
  const newEnrollmentRequestsCount = notifications?.newEnrollmentRequestsCount;
  const newStudentRequestsCount = notifications?.newStudentRequestsCount;

  return (
    <div
      className={classNames('notifications', {
        'notifications--any': newEnrollmentRequestsCount ||
          newStudentRequestsCount ||
          awaitingGradesCount ||
          studentsNoRaceCount
      })}>
      <div>
        {/* New enrollment requests notification */}
        {newEnrollmentRequestsCount > 0 &&
          <Link
            to="/enrollment-requests"
            className="notification-bar notification-bar--notice">
            {/*<Icon className="notification-bar__icon" name="exclamation-triangle" />*/}
            <div className="notification-bar__icon-circle"/>
            <div className="notification-bar__message">
              You have {newEnrollmentRequestsCount} Enrollment Request{newEnrollmentRequestsCount === 1 ? '' : 's'}
            </div>
          </Link>
        }

        {/* New student requests notification */}
        {newStudentRequestsCount > 0 &&
          <Link
            to="/course-registration-requests"
            className="notification-bar notification-bar--notice">
            <div className="notification-bar__icon-circle"/>
            <div className="notification-bar__message">
              You have {newStudentRequestsCount} Student Request{newStudentRequestsCount === 1 ? '' : 's'}
            </div>
          </Link>
        }

        {/* Supply grades notification */}
        {awaitingGradesCount > 0 &&
          <Link
            to="/enrollments/teaching/accepted"
            className="notification-bar notification-bar--notice">
            <div className="notification-bar__icon-circle"/>
            <div className="notification-bar__message">
              You have {awaitingGradesCount} Student Enrollment{awaitingGradesCount > 1 ? 's' : ''} awaiting grades
            </div>
          </Link>
        }

        {/* https://github.com/Acadeum/Tickets/issues/265 */}
        {studentsNoRaceCount > 0 &&
          <Link
            to="/students?raceAndEthnicityNotSpecified=✓"
            className="notification-bar notification-bar--notice">
            <div className="notification-bar__icon-circle"/>
            <div className="notification-bar__message">
              You have {studentsNoRaceCount} enrolled student{studentsNoRaceCount === 1 ? '' : 's'} missing required information
            </div>
          </Link>
        }
      </div>
    </div>
  );
}
