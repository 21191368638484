import React from 'react';
import { useSelector } from 'react-redux';

import './Report.sass';

import actions from '../../actions';

const { fetchPartnerRevenue } = actions;

export default function PartnerRevenue() {
  const data = useSelector(state => state.reports.partnerRevenue);

  if (data.length === 0) {
    return 'No data';
  }

  const header = Object.keys(data[0]);

  const Table = ({ data }) => {
    data = Object.values(data);
    return (
      <div className="accounting-table-wrapper">
        <table className="accounting-table">
          <thead>
            <tr>
              {header.map((_, i) => (
                <th key={i}>
                  {_}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr></tr>
            {data.map((d, i) => (
              <tr key={i}>
                {header.map((_, i) => (
                  <td key={i}>
                    {d[_]}
                  </td>
                ))}
              </tr>
            ))}
            <tr></tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="reports-page reports-page--session-over-session">
      <h1>Partner Revenue</h1>
      <Table data={data}/>
    </div>
  );
}

PartnerRevenue.meta = () => ({
  title: 'Partner Revenue'
});

PartnerRevenue.load = async () => {
  await fetchPartnerRevenue();
};

PartnerRevenue.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Partner Revenue'
];
