// Pricing Model documentation:
// https://github.com/Acadeum/Tickets/blob/master/Pricing-Model.md

import reportError_ from './reportError.js';

/**
 * (this function is imported in Course Share website and Admin Center website on "Approved Courses" pages)
 * Returns pricing variants that match a certain course section.
 * @param {object[]} pricingVariants
 * @param {object} parameters
 * @param {string} parameters.level
 * @param {number} parameters.teachingInstitutionId
 * @return {object[]} [pricingVariants]
 */
export default function getPricingVariantsForCourseSection(pricingVariants, { level, teachingInstitutionId }) {
  // Validate `level`.
  if (!level) {
    throw new Error('`level` not passed to `getCourseSectionCost()`');
  }

  // Validate `teachingInstitutionId`.
  if (!teachingInstitutionId) {
    throw new Error('`teachingInstitutionId` not passed to `getCourseSectionCost()`');
  }

  if (!pricingVariants) {
    // Not an error actually: not all institutions seem to
    // show prices to students in the first place.
    // // Report the error.
    // reportError(`"pricingVariants" not passed. Defaulting to "hide"`);
    // Default to "hide".
    return [];
  }

  return pricingVariants.filter((pricing) => {
    // // `session` filter.
    // if (pricing.session) {
    //   if (!matchesStringFilter(pricing.session, session.name)) {
    //     return false;
    //   }
    // }
    // // `term` filter.
    // if (pricing.term) {
    //   if (!matchesStringFilter(pricing.term, session.term)) {
    //     return false;
    //   }
    // }
    // // `credits` filter.
    // if (pricing.credits) {
    //   if (!matchesNumericFilter(pricing.credits, course.hours)) {
    //     return false;
    //   }
    // }
    // // `code` filter.
    // if (pricing.code) {
    //   if (!matchesStringFilter(pricing.code, course.code)) {
    //     return false;
    //   }
    // }
    // `level` filter.
    if (pricing.level) {
      if (!matchesStringFilter(pricing.level, level)) {
        return false;
      }
    }
    // `teachingInstitutionId`.
    if (pricing.teachingInstitutionId) {
      if (!matchesNumericFilter(pricing.teachingInstitutionId, teachingInstitutionId)) {
        return false;
      }
    }
    for (const key of Object.keys(pricing)) {
      if (!SUPPORTED_PRICING_KEYS.includes(key)) {
        // Report the error to `sentry.io`.
        reportError(`Unsupported pricing key "${key}" in pricing\n${JSON.stringify(pricingVariants, null, 2)}`);
      }
    }
    return true;
  });
}

// Reports the error to `sentry.io`.
// Only on client side, because it would crash the server side.
// https://github.com/oseibonsu/CollegeConsortiumTickets/issues/216
function reportError(error) {
  if (typeof window !== 'undefined') {
    if (window.TESTING_COURSE_PRICING) {
      return;
    }
  }
  reportError_(error);
}

export function matchesNumericFilter(filter, value) {
  if (value === undefined) {
    // throw new Error(`"value" is missing while matching a numeric filter: ${JSON.stringify(filter)}`);
    reportError(`"value" is missing while matching a numeric filter: ${JSON.stringify(filter)}`);
    return false;
  }
  // "in" operator is omitted.
  if (Array.isArray(filter)) {
    for (const i of filter) {
      if (matchesNumericFilter(i, value)) {
        return true;
      }
    }
    return false;
  }
  // "=" operator is omitted.
  if (typeof filter === 'number') {
    return filter === value;
  }
  // "and" filter isn't actually used.
  // if (filter.and) {
  //   for (const key of Object.keys(filter.and)) {
  //     if (!matchesNumericFilter(filter.and[key], value)) {
  //       return false;
  //     }
  //   }
  //   return true;
  // }
  // "or" filter isn't actually used.
  // if (filter.or) {
  //   for (const key of Object.keys(filter.and)) {
  //     if (matchesNumericFilter(filter.and[key], value)) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }
  if (filter['>']) {
    return value > filter['>'];
  }
  if (filter['>=']) {
    return value >= filter['>='];
  }
  if (filter['<']) {
    return value < filter['<'];
  }
  if (filter['<=']) {
    return value <= filter['<='];
  }
  // Report "Unknown filter" error.
  reportError(`Unknown filter:\n${JSON.stringify(filter, null, 2)}`);
  return false;
}

export function matchesStringFilter(filter, value) {
  if (value === undefined) {
    // throw new Error(`"value" is missing while matching a string filter: ${JSON.stringify(filter)}`);
    reportError(`"value" is missing while matching a string filter: ${JSON.stringify(filter)}`);
    return false;
  }
  // "in" operator is omitted.
  if (Array.isArray(filter)) {
    for (const i of filter) {
      if (matchesStringFilter(i, value)) {
        return true;
      }
    }
    return false;
  }
  // "=" operator is omitted.
  return filter === value;
}

const SUPPORTED_PRICING_FILTERS = [
  'level',
  'teachingInstitutionId'
];

const SUPPORTED_PRICING_KEYS = [
  'model',
  'value',
  'consortiumId'
].concat(SUPPORTED_PRICING_FILTERS);
