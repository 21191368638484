import React from 'react';
import PropTypes from 'prop-types';

import type { Email as EmailType } from '@acadeum/types';

import type { LinkProps } from '../Link';
import { Link } from '../Link';

export interface EmailProps extends Omit<LinkProps, 'to'> {
  address: EmailType;
  subject?: string;
  children?: React.ReactNode;
}

export const Email = React.forwardRef<HTMLAnchorElement, EmailProps>(({
  address,
  subject,
  children,
  ...rest
}, ref) => {
  return (
    <Link
      {...rest}
      ref={ref}
      to={`mailto:${address}${subject ? '?subject=' + encodeURIComponent(subject) : ''}`}
    >
      {children || address}
    </Link>
  );
});

Email.propTypes = {
  address: PropTypes.string.isRequired,
  subject: PropTypes.string,
  children: PropTypes.node
};
