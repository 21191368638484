import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from 'react-responsive-ui';

import { PostalCodeInput as PostalCodeInput_ } from '@acadeum/ui';

let PostalCodeInput = ({ onChange, ...rest }, ref) => {
  return (
    <PostalCodeInput_
      ref={ref}
      Component={TextInput}
      setValue={onChange}
      onChange={onChange}
      {...rest} />
  );
};

PostalCodeInput = React.forwardRef(PostalCodeInput);

PostalCodeInput.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default PostalCodeInput;
