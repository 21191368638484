import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ScreenReaderOnly } from '@acadeum/core-ui';

import Action, { actionPropType } from '../SectionHeaderAction';

import './Section.sass';

export default function Section({
  title,
  titleHidden,
  marginTop,
  description,
  descriptionSmallMargin,
  inlineActions,
  actions,
  // step,
  // of,'lg'
  className,
  children,
  smallHeading,
  mb = 'lg'
}) {
  return (
    <section className={classNames('Section', className, {
      'Section--marginTopLarge': marginTop === 'large'
    })}>
      {title && (
        <header className={classNames('Section__header', {
          'Section__header--inlineActions': inlineActions,
          'Section__header--smallHeading': smallHeading,
          'Section__header--lg': mb === 'lg',
          'Section__header--md': mb === 'md',
          'Section__header--sm': mb === 'sm'
        })}>
          {titleHidden ? (
            <ScreenReaderOnly as="h1">
              {title}
            </ScreenReaderOnly>
          ) : (
            <h1 className={classNames('Section__heading', {
              'Section__heading--smallHeading': smallHeading
            })}>
              {title}
            </h1>
          )}
          {actions && (
            <div className={classNames('Section__headerActions', {
              'Section__headerActions--inline': inlineActions
            })}>
              {actions.map((action, i) => (
                <Action key={i} {...action} iconSize={inlineActions ? undefined : 'l'}/>
              ))}
            </div>
          )}
          {/*step &&
            <StepsProgressBar step={step} of={of} />
          */}
        </header>
      )}
      {description &&
        <p className={classNames('Section__description', {
          'Section__description--smallMargin': descriptionSmallMargin,
          'Section__description--smallHeading': smallHeading

        })}>
          {description}
        </p>
      }
      {children}
    </section>
  );
}

Section.propTypes = {
  smallHeading: PropTypes.bool,
  title: PropTypes.node,
  titleHidden: PropTypes.bool,
  description: PropTypes.node,
  descriptionSmallMargin: PropTypes.bool,
  marginTop: PropTypes.oneOf(['large']),
  inlineActions: PropTypes.bool,
  actions: PropTypes.arrayOf(actionPropType),
  // step: PropTypes.number,
  // of: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.node,
  mb: PropTypes.oneOf(['lg', 'md', 'sm'])
};
