import sortTerms from './sortTerms.js';

export default function getTermsAndSessions({ termsAndSessions, courseApproval }) {
  // `terms` and `termSessions` will be mutated.
  let terms = sortTerms(Object.keys(termsAndSessions));
  let termSessions = terms.map(term => termsAndSessions[term].slice());

  // Course Approvals are defined in such a way that they can be uploaded
  // even for courses that don't currently exist on the platform.
  // Therefore, it would be technically legal to upload course approvals
  // for non-existent course terms or session names.
  // In those cases, the corresponding `courseApproval.schedule` objects
  // would contain non-existent course terms or session names
  // which should be appended to the existing ones when rendering
  // a checkbox-selection component for terms and session names,
  // otherwise those non-existent course terms or session names
  // would disappear when a user re-saves the course approval from the UI.
  if (courseApproval && courseApproval.schedule) {
    for (const { term, sessions } of courseApproval.schedule) {
      let termIndex = terms.indexOf(term);
      // Add non-existent terms.
      if (termIndex < 0) {
        terms = sortTerms(terms.concat([term]));
        termIndex = terms.indexOf(term);
        termSessions = termSessions.slice(0, termIndex)
          .concat([[]])
          .concat(termSessions.slice(termIndex));
      }
      if (sessions) {
        for (const session of sessions) {
          // Add non-existent session names.
          if (!termSessions[termIndex].includes(session)) {
            termSessions[termIndex].push(session);
          }
        }
      }
    }
  }

  return {
    terms,
    termSessions
  };
}
