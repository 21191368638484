import React, { useCallback, useId } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Radio.module.scss';

export interface RadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string | boolean;
  border?: boolean | 'large';
  label?: React.ReactNode;
  description?: React.ReactNode;
}

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(({
  id: propsId,
  disabled,
  readOnly,
  border,
  error,
  className,
  label,
  description,
  checked,
  ...rest
}, ref) => {
  const defaultId = useId();
  const id = propsId || defaultId;

  const onClick = useCallback((event) => {
    if (readOnly || disabled) {
      event.preventDefault();
    }
    rest.onClick?.(event);
  }, [
    readOnly,
    disabled,
    rest.onClick
  ]);

  return (
    <label htmlFor={id} className={classNames(className, styles.Radio, {
      [styles['Radio--disabled']]: disabled,
      [styles['Radio--readOnly']]: readOnly,
      [styles['Radio--border']]: border,
      [styles['Radio--borderLarge']]: border === 'large' || description,
      [styles['Radio--checked']]: checked,
      [styles['Radio--error']]: error,
      [styles['Radio--description']]: description
    })}>
      <RadioInput
        {...rest}
        id={id}
        ref={ref}
        disabled={disabled || readOnly}
        checked={checked}
        onClick={onClick}
        border={border}
      />
      <span>
        <span>
          {label}
        </span>
        {description && (
          <span className={styles.description}>
            {description}
          </span>
        )}
      </span>
    </label>
  );
});

Radio.propTypes = {
  checked: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  border: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['large'])
  ]),
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.node.isRequired,
  description: PropTypes.node
} as NonNullable<unknown>;

const RadioInput = React.forwardRef<HTMLInputElement, RadioProps>(({ border, ...rest }, ref) => {
  return (
    <span className={styles.RadioInput}>
      <input
        {...rest}
        className={styles.input}
        ref={ref}
        type="radio"
      />
      <span className={classNames(styles.toggle, {
        [styles['toggle-border']]: border
      })}></span>
    </span>
  );
});
