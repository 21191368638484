const ONE_SECOND = 1000;
const ONE_MINUTE = 60 * ONE_SECOND;
const ONE_HOUR = 60 * ONE_MINUTE;
const ONE_DAY = 24 * ONE_HOUR;
const ONE_WEEK = 7 * ONE_DAY;

/**
 * Returns the difference between two dates
 * @param date1 {Date} - now/future date
 * @param date2 {Date} - previous date
 * @param units {'years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'}
 * @return number
 * */
export default function getDateDiff(date1, date2, units) {
  const timeDifference = date1.getTime() - date2.getTime();

  switch (units) {
    case 'years':
      return date1.getFullYear() - date2.getFullYear();
    case 'months':
      return (date1.getFullYear() * 12 + date1.getMonth()) - (date2.getFullYear() * 12 + date2.getMonth());
    case 'weeks':
      return Math.floor(timeDifference / ONE_WEEK);
    case 'days':
      return Math.floor(timeDifference / ONE_DAY);
    case 'hours':
      return Math.floor(timeDifference / ONE_HOUR);
    case 'minutes':
      return Math.floor(timeDifference / ONE_MINUTE);
    case 'seconds':
      return Math.floor(timeDifference / ONE_SECOND);
    default:
      throw new Error(`Unsupported diff unit: ${units}`);
  }
}
