// Reports the error to `sentry.io`.
// Only on client side, because it would crash the server side.
// https://github.com/oseibonsu/CollegeConsortiumTickets/issues/216
export default function reportError(error) {
  // if (typeof window !== 'undefined') {
  //   // Don't throw errors when course pricing tests are run.
  //   // (`./src/helpers/getCourseSectionCost.test.js`)
  //   if (window.TESTING_COURSE_PRICING) {
  //     return;
  //   }
  // }
  console.error(error);
  if (process.env.NODE_ENV === 'production') {
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        throw new Error(error);
      }, 0);
    }
  }
}
