import React, { memo } from 'react';

import type { Section } from '@acadeum/types/src/models';

import { Text } from '../../../Text';
import { CloseButton } from '../../../CloseButton';
import type { ActionsProps } from '../../../Actions';
import { Actions } from '../../../Actions';
import { Link } from '../../../Link';

import styles from './SectionCardHeader.module.scss';

export interface SectionCardHeaderProps {
  section: Pick<Section, 'name'>;
  sectionUrl?: string;
  onRemove?: () => void;
  actions?: ActionsProps['actions'];
}

export const SectionCardHeader = memo<SectionCardHeaderProps>(({
  section,
  onRemove,
  actions,
  sectionUrl
}) => {
  return (
    <div className={styles.SectionCardHeader}>
      <Text
        as="h3"
        variant="subtitle2"
        color="graphiteDark"
      >
        {sectionUrl ? (
          <Link to={sectionUrl} monochrome>
            Section {section.name}
          </Link>
        ) : (
          <>
            Section {section.name}
          </>
        )}
      </Text>

      {onRemove && (
        <CloseButton
          aria-label="Remove Course Section"
          size="sm"
          onClick={onRemove}
        />
      )}

      {actions && (
        <Actions
          variant="kebab"
          actions={actions}
        />
      )}
    </div>
  );
});
