import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const fetchStudentUser = redux.action(
  (id) => http => http.get(`/student-users/${id}`),
  'studentUser'
);

export default redux.reducer();
