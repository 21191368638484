import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Form from '../Form';
import FormHeader from '../FormHeader';
import FormTitle from '../FormTitle';
import FormDescription from '../FormDescription';
import FormField from '../FormField';
import FormFooter from '../FormFooter';
import FormSubmit from '../FormSubmit';
import Modal, { waitForModalToClose } from '../Modal';

export default function TextInputModal({
  title,
  description,
  multiline = true,
  onSubmit,
  submitText,
  onAfterSubmit,
  defaultValue,
  label,
  placeholder,
  onHide,
  ...rest
}) {
  if (!label) {
    label = 'Notes';
  }

  const onSubmit_ = useCallback(async (values) => {
    const result = await onSubmit(values);
    onHide();
    if (onAfterSubmit) {
      await waitForModalToClose();
      onAfterSubmit(result);
    }
  }, [
    onSubmit,
    onAfterSubmit
  ]);

  return (
    <Modal
      onHide={onHide}
      {...rest}>
      <Form onSubmit={onSubmit_}>
        <FormHeader>
          <FormTitle>
            {title}
          </FormTitle>
          <FormDescription>
            {description}
          </FormDescription>
        </FormHeader>
        <FormField
          required
          multiline={multiline}
          name="notes"
          defaultValue={defaultValue}
          label={label}
          placeholder={placeholder}
        />
        <FormFooter>
          <FormFooter.Actions>
            <FormSubmit>
              {submitText}
            </FormSubmit>
          </FormFooter.Actions>
        </FormFooter>
      </Form>
    </Modal>
  );
}

TextInputModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  multiline: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string.isRequired,
  onAfterSubmit: PropTypes.func,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onHide: PropTypes.func.isRequired
};
