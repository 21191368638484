import React, { useCallback, useMemo, useState } from 'react';
import capitalize from 'lodash/capitalize';
import type { RowSelectionState, VisibilityState } from '@tanstack/react-table';

import getLastDateToDropFromCourseEnrollment from 'common-lib/lib/getLastDateToDropFromCourseEnrollment';

import type { ButtonProps, TableExportOptionsProp, TableProps } from '@acadeum/ui';
import {
  Actions,
  BaseButton,
  Button,
  createTableCourseFilter,
  createTableDefaultFilter,
  createTableInstitutionFilter,
  createTableSessionFilter,
  createTableStatusFilter,
  createTableTermFilter,
  createTableYearFilter,
  DataBlock,
  getTableCommonCellStyles,
  HStack,
  Table,
  Tag,
  Text,
  Tooltip
} from '@acadeum/ui';

import { CircleXmarkIcon, NoAccessIcon, NotebookPenIcon, TrashIcon } from '@acadeum/icons';

import { useTranslate } from '@acadeum/translate';

import { getDeepDataFromStorage, saveDeepDataToStorage } from '@acadeum/helpers';

import ChangeStatus from '../ChangeStatus';
import ModalInfo from '../ModalInfo';

import { useRefreshNotifications } from '../../../../api/notifications';

import { formatName } from '../../../../helpers/format';

import actions from '../../../../actions';

import styles from './EnrollmentsTable.module.scss';

const {
  fetchEnrollmentRequests,
  fetchEnrollmentRequestsTM
} = actions;

export interface EnrollmentsTableProps {
  institutionType: 'teaching' | 'home';
  enrollmentType: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sections: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exportDataColumns: any[];
  exportDataFileName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchDataForExport: TableExportOptionsProp<unknown>['fetchDataForExport'];
}

const EnrollmentsTable = ({
  institutionType,
  enrollmentType,
  data,
  sections,
  exportDataColumns,
  exportDataFileName,
  fetchDataForExport
}: EnrollmentsTableProps) => {
  const t = useTranslate('Enrollments');
  const tableId = `Enrollments.${institutionType}.${enrollmentType}`;

  const isTeaching = institutionType === 'teaching';

  const columns = useMemo(() => [
    {
      id: 'student',
      header: t('table.student'),
      enableSorting: true,
      accessorFn: (original) => `${formatName(original.student)} ${original.student.hiStudentId}`,
      cell: ({ row }) => (
        <DataBlock
          type="student"
          student={row.original.student}
          teaching={isTeaching}
        />
      )
    },
    ...(institutionType === 'home' ? [
      {
        id: 'teachingInstitution',
        header: t('table.teachingInstitution'),
        enableSorting: true,
        accessorFn: (original) => original.section.session.course.institution.name,
        cell: ({ row }) => <DataBlock type="institution" institution={row.original.section.session.course.institution}/>
      },
      {
        accessorKey: 'createdAt',
        header: t('table.requested'),
        enableSorting: true,
        cell: ({ getValue }) => (
          <DataBlock
            type="date"
            date={getValue()}
            utc={false}
            month="short"
          />
        )
      }
    ] : []),
    ...(isTeaching ? [
      {
        id: 'homeInstitution',
        header: t('table.homeInstitution'),
        enableSorting: true,
        accessorFn: (original) => original.institution.name,
        cell: ({ row }) => <DataBlock type="institution" institution={row.original.institution}/>
      },
      {
        header: t('table.type'),
        accessorKey: 'type'
      }
    ] : []),
    {
      id: 'course',
      accessorFn: (original) => `${original.section.session.course.code} ${original.section.session.course.title}`,
      header: t('course'),
      enableSorting: true,
      cell: ({ row }) => (
        <DataBlock
          type="course"
          course={row.original.section.session.course}
        />
      )
    },
    {
      id: 'session',
      header: t('table.termSession'),
      enableSorting: true,
      accessorFn: (original) => `${original.section.session.name} ${original.section.session.term}`,
      meta: {
        emptyWhen: ({ row }) => row.section.session.course.onDemand
      },
      cell: ({ row }) => <DataBlock type="term&session" session={row.original.section.session}/>
    },
    {
      header: t('table.section'),
      accessorKey: 'section.number'
    },
    {
      accessorKey: 'dates',
      header: t('table.dates'),
      enableSorting: true,
      meta: {
        emptyWhen: ({ row }) => row.section.session.course.onDemand && !row.startedAt
      },
      cell: ({ row }) => {
        const onDemand = row.original.section.session.course.onDemand;
        if (onDemand) {
          if (row.original.startedAt) {
            const lastDropDate = getLastDateToDropFromCourseEnrollment({
              onDemand: row.original.section.session.course.onDemand,
              onDemandDropDateDaysAfterStartDate: row.original.section.session.course.institution.onDemandCourseDropDateDaysAfterStartDate,
              startedAt: row.original.startedAt,
              lastDropDate: row.original.section.session.lastDropDate
            });
            if (lastDropDate) {
              return (
                <Text as="div" nowrap>
                  <HStack gap="xs">
                    Drop Date:
                    <DataBlock
                      utc
                      type="date"
                      month="short"
                      date={lastDropDate}
                    />
                  </HStack>
                </Text>
              );
            }
          }
          return null;
        }

        return (
          <DataBlock
            type="courseSessionDates"
            withLastDropDate
            session={row.original.section.session}
          />
        );
      }
    },
    {
      header: t('table.status'),
      accessorKey: 'status',
      cell: ({ row }) => {
        if (sections) {
          const tableSection = sections.find(section => section.filter(row.original));
          if (tableSection) {
            return (
              <Tooltip placement="left" content={tableSection.tooltip}>
                <Tag variant={row.original.status}/>
              </Tooltip>
            );
          }
        }
        return (
          <Tag variant={row.original.status}/>
        );
      }
    },
    {
      id: 'actions',
      size: 60,
      cell: ({ row, downloadRow }) => (
        <ActionsCell
          row={row}
          downloadRow={downloadRow}
          sections={sections}
          t={t}
          isTeaching={isTeaching}
          institutionType={institutionType}
          onAfterStatusChange={onAfterStatusChange}
        />
      )
    }
  ], [sections]);

  const filters = useMemo(() => [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createTableInstitutionFilter<any>(institutionType === 'home' ? {
      label: t('table.teachingInstitution'),
      getInstitution: row => row.section.session.course.institution
    } : {
      label: t('table.homeInstitution'),
      getInstitution: row => row.institution
    }),
    ...(isTeaching ? [
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      createTableDefaultFilter<any>({
        id: 'type',
        label: t('table.type'),
        getFilterValue: row => row.type
      })
    ] : []),
    createTableCourseFilter(),
    createTableYearFilter(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createTableTermFilter<any>({
      getTerm: row => row.section.session.term
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createTableSessionFilter<any>({
      getSession: row => row.section.session
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createTableDefaultFilter<any>({
      id: 'section',
      label: t('table.section'),
      getFilterValue: row => row.section.number
    }),
    createTableStatusFilter({
      sort: (a, b) => {
        const orderA = STATUS_FILTER_OPTIONS_ORDER.indexOf(a);
        const orderB = STATUS_FILTER_OPTIONS_ORDER.indexOf(b);
        if (orderA > orderB) {
          return 1;
        } else if (orderA < orderB) {
          return -1;
        } else {
          return 0;
        }
      }
    })
  ], []);

  const refreshNotifications = useRefreshNotifications();

  const [tableDataStorage, setTableDataStorage] = useState<VisibilityState>({ closeAlert: false, ...getDeepDataFromStorage(`AcadeumTable.${tableId}`) });
  const [selectedEnrollmentsHaveDifferentStatus, setSelectedEnrollmentsHaveDifferentStatus] = useState<boolean>(false);

  const onCloseSelectedEnrollmentsHaveDifferentStatus = () => {
    setSelectedEnrollmentsHaveDifferentStatus(false);
    setTableDataStorage(prevState => ({ ...prevState, closeAlert: true }));
    saveDeepDataToStorage(`AcadeumTable.${tableId}.closeAlert`, true);
  };

  const onAfterStatusChange = async () => {
    if (isTeaching) {
      fetchEnrollmentRequestsTM();
    } else {
      fetchEnrollmentRequests();
    }
    refreshNotifications();
    setRowSelection({});
  };

  const renderTopRow: TableProps<unknown>['renderTopRow'] = useCallback(({ table }) => {
    const columns = table.getVisibleFlatColumns();
    const actionsStyles = getTableCommonCellStyles({ table, column: columns[columns.length - 1] });

    const rows = table.getFilteredRowModel().rows;
    const selectedRows = table.getSelectedRowModel().rows;
    const referenceStatus = selectedRows[0]?.original.status;

    if (
      !referenceStatus ||
      selectedRows.length < 2 ||
      !selectedRows.every(row => row.original.status === referenceStatus)
    ) {
      return null;
    }

    return (
      <tr className={styles.TopRow}>
        <td colSpan={columns.length}>
          <div className={styles.stickyContent}>
            <p>
              You can
              {' '}
              <BaseButton
                className={styles.BaseButton}
                onClick={() => {
                  table.setRowSelection(rows.reduce((acc, row) => {
                    if (row.original.status === referenceStatus) {
                      acc[row.id] = true;
                    }
                    return acc;
                  }, {}));
                }}
              >
                select all enrollments with "{capitalize(referenceStatus)}"
              </BaseButton>
              {' '}
              status or
              {' '}
              <BaseButton className={styles.BaseButton} onClick={table.resetRowSelection}>
                clear selection
              </BaseButton>
            </p>
            <div className={styles.imitateActionsColumn} style={actionsStyles}/>
          </div>
        </td>
      </tr>
    );
  }, []);

  const renderTopLeftToolbarCustomActions: TableProps<unknown>['renderTopLeftToolbarCustomActions'] = useCallback((props) => {
    return (
      <TopLeftToolbarCustomActions
        downloadRows={props.downloadRows}
        selectedRows={props.selectedRows}
        t={t}
        isTeaching={isTeaching}
        institutionType={institutionType}
        onAfterStatusChange={onAfterStatusChange}
        sections={sections}
      />
    );
  }, [
    t,
    isTeaching,
    institutionType,
    onAfterStatusChange,
    sections
  ]);

  const alertProps = {
    children: t('table.selectedEnrollmentsHaveDifferentStatus'),
    show: selectedEnrollmentsHaveDifferentStatus,
    dismissible: true,
    onClose: onCloseSelectedEnrollmentsHaveDifferentStatus
  };

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const onRowSelectionChange = (updaterOrValue) => {
    setRowSelection(updaterOrValue);
    const enrollmentsChecked = data.filter(_ => updaterOrValue[_.id]);
    if (enrollmentsChecked.length !== 0 && !tableDataStorage.closeAlert) {
      const referenceStatus = enrollmentsChecked[0].status;
      setSelectedEnrollmentsHaveDifferentStatus(enrollmentsChecked.some(_ => _.status !== referenceStatus));
    }
  };

  return (
    <Table
      id={tableId}
      hasColumnVisibility
      enableGlobalFilter
      enableRowSelection
      columns={columns}
      alertProps={alertProps}
      data={data}
      clientFilters={filters}
      renderTopRow={renderTopRow}
      columnPinningRight={['actions']}
      rowSelectionOptions={{
        rowSelection: rowSelection,
        onRowSelectionChange
      }}
      renderTopLeftToolbarCustomActions={renderTopLeftToolbarCustomActions}
      exportOptions={{
        type: 'xlsx',
        fileName: exportDataFileName,
        exportDataColumns,
        fetchDataForExport
      }}
    />
  );
};
export default EnrollmentsTable;

const STATUS_FILTER_OPTIONS_ORDER = [
  'SENT',
  'REGISTRATION_REQUESTED',
  'REMOVED'
];

function TopLeftToolbarCustomActions({
  downloadRows,
  selectedRows,
  sections,
  t,
  isTeaching,
  institutionType,
  onAfterStatusChange
}) {
  const [show, setShow] = useState<boolean>();

  if (selectedRows.length === 0) {
    return null;
  }

  const referenceStatus = selectedRows[0].status;
  const tableSection = sections.find(section => section.filter(selectedRows[0]));

  if (!sections || !tableSection || !tableSection.getStatusTransitions) {
    return null;
  }
  const targetStatuses = tableSection.getStatusTransitions(selectedRows[0]);

  if (
    !sections ||
    !tableSection ||
    !tableSection.getStatusTransitions ||
    !selectedRows.every(row => row.status === referenceStatus) ||
    targetStatuses.length === 0
  ) {
    return null;
  }

  return (
    <>
      <ModalInfo
        show={show}
        onHide={setShow}
        enrollments={selectedRows}
        downloadRow={downloadRows}
        institutionType={institutionType}
      />
      <Button
        variant="tertiary"
        onClick={() => {
          setShow(true);
        }}
      >
        {t('seeDetails')}
      </Button>
      <ChangeStatus
        isTeaching={isTeaching}
        exportData={downloadRows}
        onAfterSubmit={onAfterStatusChange}
      >
        {({ onChange }) => {
          return (
            <>
              {targetStatuses.map((status, index) => {
                let Icon: ButtonProps['icon'] = undefined;
                switch (status) {
                  case 'REMOVED':
                    Icon = TrashIcon;
                    break;
                  case 'COMPLETE':
                    Icon = NotebookPenIcon;
                    break;
                  case 'DROPPED':
                    Icon = NoAccessIcon;
                    break;
                  case 'WITHDRAWN':
                    Icon = CircleXmarkIcon;
                    break;
                }
                return (
                  <Button
                    key={index}
                    variant="tertiary"
                    onClick={() => onChange(selectedRows, status)}
                    icon={Icon}
                  >
                    {t(`status.transitionTo.${status.toLowerCase()}`)}
                  </Button>
                );
              })}
            </>
          );
        }}
      </ChangeStatus>
    </>
  );
}

function ActionsCell({
  row,
  downloadRow,
  sections,
  t,
  isTeaching,
  institutionType,
  onAfterStatusChange
}) {
  const [show, setShow] = useState<boolean>();

  const optionActions = [
    {
      title: 'Download',
      onClick: downloadRow
    }
  ];

  const tableSection = sections.find(section => section.filter(row.original));
  if (sections && tableSection && tableSection.getStatusTransitions) {
    const targetStatuses = tableSection.getStatusTransitions(row.original);
    return (
      <ChangeStatus
        isTeaching={isTeaching}
        exportData={downloadRow}
        onAfterSubmit={onAfterStatusChange}>
        {({ onChange }) => {
          return (
            <Actions
              variant="kebab"
              actions={[
                ...targetStatuses
                  .map((status) => ({
                    title: t(`status.transitionTo.${status.toLowerCase()}`),
                    danger: status === 'REMOVED',
                    onClick: () => onChange([row.original], status)
                  })),
                ...optionActions
              ]}
            />
          );
        }}
      </ChangeStatus>
    );
  }

  if (typeof tableSection.title === 'string') {
    optionActions.unshift({
      title: t('seeDetails'),
      onClick: () => setShow(true)
    });
  }

  return (
    <>
      {typeof tableSection.title === 'string' && (
        <ModalInfo
          show={show}
          onHide={setShow}
          enrollments={[row.original]}
          downloadRow={downloadRow}
          institutionType={institutionType}
        />
      )}

      <Actions
        variant="kebab"
        actions={optionActions}
      />
    </>
  );
}
