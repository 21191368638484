import { writeXlsx as writeXlsx_ } from '@acadeum/helpers';

import {
  formatGender,
  formatRaces,
  formatEthnicity,
  formatPhone
} from './format';

const writeXlsxFile = writeXlsx_({
  formatGender,
  formatRaces,
  formatEthnicity,
  formatPhone
});

export default writeXlsxFile;
