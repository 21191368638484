import React from 'react';
import { useSelector } from 'react-redux';

import Course from '../../components/Course';
import WithPublicMarketingPageBanner from '../../components/WithPublicMarketingPageBanner';

import actions from '../../actions';

const {
  fetchCourse
} = actions;

function CoursePage() {
  const { course } = useSelector(state => state.courses);

  return (
    <Course course={course}/>
  );
}

CoursePage.meta = ({ useSelector }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const course = useSelector(state => state.courses.course);
  return {
    title: `${course.code} ${course.title}`
  };
};

CoursePage.breadcrumbs = () => [
  'Course'
];

CoursePage.load = async ({ user, params: { id } }) => {
  try {
    await fetchCourse(id, { user });
  } catch (error) {
    // If the course doesn't exist
    // then redirect to "Not found" page.
    if (error.statusCode === 404) {
      return {
        redirect: {
          url: '/not-found'
        }
      };
    }
    throw error;
  }
};

export default WithPublicMarketingPageBanner(CoursePage, { margin: true });
