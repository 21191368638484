import React, { useMemo } from 'react';

import type { OnSubmit } from '@acadeum/ui';
import { Form } from '@acadeum/ui';

import type { CourseMaterialsSectionValues } from './ui/CourseMaterialsSection';
import { CourseMaterialsSection } from './ui/CourseMaterialsSection';
import type { AdditionalCostsSectionValues } from './ui/AdditionalCostsSection';
import { AdditionalCostsSection } from './ui/AdditionalCostsSection';
import type { CertificatesSectionValues } from './ui/CertificatesSection';
import { CertificatesSection } from './ui/CertificatesSection';
import type { DescriptionSectionValues } from './ui/DescriptionSection';
import { DescriptionSection } from './ui/DescriptionSection';
import type { EditScheduleSectionValues } from './ui/ScheduleSection';
import { ScheduleSection } from './ui/ScheduleSection';
import type { EditAcademicSectionValues } from './ui/AcademicSection';
import { AcademicSection } from './ui/AcademicSection';
import type { CategorySectionValues } from './ui/CategorySection';
import { CategorySection } from './ui/CategorySection';
import type { GeneralSectionValues } from './ui/GeneralSection';
import { GeneralSection } from './ui/GeneralSection';
import type { NotesSectionValues } from './ui/NotesSection';
import { NotesSection } from './ui/NotesSection';
import { CourseFormFooter } from './ui/CourseFormFooter';
import { OnDemandDatesSection } from './ui/OnDemandDatesSection';
import { Mode } from './helpers';

type EditCourseFormValues =
  GeneralSectionValues
  & DescriptionSectionValues
  & EditAcademicSectionValues
  & CategorySectionValues
  & EditScheduleSectionValues
  & CourseMaterialsSectionValues
  & AdditionalCostsSectionValues
  & CertificatesSectionValues
  & NotesSectionValues;

export interface EditCourseFormProps {
  cancelUrl?: string;
  onBack?: () => void;
  onSubmit: OnSubmit<EditCourseFormValues>;
  // eslint-disable-next-line
  course?: any;
  submitText: string;
  onDemandCourseDropDateDaysAfterStartDate?: number;
  onDemandCourseEndDateDaysAfterStartDate?: number;
}

export const EditCourseForm: React.FC<EditCourseFormProps> = ({
  cancelUrl,
  submitText,
  onBack,
  onSubmit,
  course,
  onDemandCourseDropDateDaysAfterStartDate,
  onDemandCourseEndDateDaysAfterStartDate
}) => {
  const defaultValues = useMemo(() => {
    if (!course) {
      return;
    }
    const { resources } = course;
    let syllabusResource = resources.find(_ => _.type === 'SYLLABUS');
    if (syllabusResource) {
      // The API won't accept an `id` in an element of `resources[]` array in the input.
      syllabusResource = { ...syllabusResource };
      // The `(syllabusResource as any)` construct works around a strange error message:
      // "The operand of a 'delete' operator must be optional".
      delete (syllabusResource as any).id;
    }
    return {
      ...course,
      // The "Create Course"/"Update Course" form, with its current design,
      // supports only one type of resource being added when creating a course: a `SYLLABUS`.
      // Any other resources are supposed to be added on a separate tab of the "Course" page.
      // Therefore, only select a SYLLABUS from the existing `course.resources`.
      resources: syllabusResource ? [syllabusResource] : [],
      // Convert optional booleans to explicit ones.
      onDemand: Boolean(course?.onDemand),
      additionalCosts: Boolean(course?.additionalCosts),
      zeroTextbookCost: Boolean(course?.zeroTextbookCost)
    };
  }, [course]);

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={onSubmit}
    >
      <GeneralSection mode={Mode.EDIT} />
      <DescriptionSection />
      <AcademicSection mode={Mode.EDIT} />
      <OnDemandDatesSection
        onDemandCourseDropDateDaysAfterStartDate={onDemandCourseDropDateDaysAfterStartDate}
        onDemandCourseEndDateDaysAfterStartDate={onDemandCourseEndDateDaysAfterStartDate}
      />
      <CategorySection />
      <ScheduleSection mode={Mode.EDIT} />
      <CourseMaterialsSection />
      <AdditionalCostsSection />
      <CertificatesSection />
      <NotesSection />

      <CourseFormFooter
        cancelUrl={cancelUrl}
        submitText={submitText}
        onBack={onBack}
        mode={Mode.EDIT}
      />
    </Form>
  );
};
