import type { CSSProperties } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './FormFooter.module.scss';

export interface FormFooterProps {
  children: React.ReactNode;
  align?: 'left' | 'center' | 'between';
  marginTop?: 'large' | 'medium' | 'none' | 'small';
  className?: string;
  style?: CSSProperties;
}

export const FormFooter: React.FC<FormFooterProps> = ({
  align,
  children,
  marginTop = 'small',
  className,
  style
}) => {
  return (
    <div
      style={style}
      className={classNames(styles.FormFooter, className,
        [styles[`FormFooter--marginTop-${marginTop}`]],
        {
          [styles['FormFooter--alignLeft']]: align === 'left',
          [styles['FormFooter--alignCenter']]: align === 'center',
          [styles['FormFooter--alignBetween']]: align === 'between'
        }
      )}
    >
      {children}
    </div>
  );
};

FormFooter.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'between']),
  marginTop: PropTypes.oneOf(['large', 'medium', 'none', 'small']),
  children: PropTypes.node,
  className: PropTypes.string
};
