import React from 'react';
import PropTypes from 'prop-types';

import Link from '../Link';

import getInstitutionLink from '../../helpers/getInstitutionLink';

export default function InstitutionLink({
  Component: Link_ = Link,
  institution,
  children,
  ...rest
}) {
  if (!institution) {
    if (typeof children !== 'object') {
      throw new Error('`<InstitutionLink/>` is supposed to receive either an `institution` property or an `institution` children.');
    }
    institution = children;
    children = undefined;
  }
  if (!children) {
    children = institution.name;
  }
  return (
    <Link_
      to={getInstitutionLink(institution)}
      {...rest}
    >
      {children}
    </Link_>
  );
}

const institutionShape = PropTypes.shape({
  id: PropTypes.any.isRequired,
  vanityUrl: PropTypes.string,
  name: PropTypes.string.isRequired
});

InstitutionLink.propTypes = {
  Component: PropTypes.elementType,
  institution: institutionShape,
  children: PropTypes.oneOfType([
    institutionShape,
    PropTypes.node
  ])
};
