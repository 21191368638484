import type { FC } from 'react';
import React, { useState } from 'react';

import type { OnSubmit } from '@acadeum/ui';
import { Alert, SuccessModal, toast } from '@acadeum/ui';
import { ArrowUpRightFromSquareIcon } from '@acadeum/icons';
import { useTranslate } from '@acadeum/translate';
import type { Id } from '@acadeum/types';
import type { UseStepResult } from '@acadeum/hooks';

import type { AddCourseMethod } from '../../types';

import type { AdvancedCourseSettingsFormProps, FormValues } from '../../../../components/AdvancedCourseSettingsForm';
import { AdvancedCourseSettingsForm } from '../../../../components/AdvancedCourseSettingsForm';

import styles from './AdvancedSettingsStep.module.scss';

export interface AdvancedSettingsStepProps {
  method: AddCourseMethod;
  navigation: UseStepResult<unknown>['navigation'];
  onAdvancedSettingsSubmit: OnSubmit<FormValues, Id[]>;
  goToCoursePage: (courseId: number) => void;
  goToCoursesPage: () => void;
  goToFinishPage: () => void;
}

export const AdvancedSettingsStep: FC<AdvancedSettingsStepProps> = ({
  method,
  navigation,
  onAdvancedSettingsSubmit,
  goToCoursesPage,
  goToCoursePage,
  goToFinishPage
}) => {
  const [showAlert, setShowAlert] = useState(true);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [courseIds, setCourseIds] = useState<Id[]>();

  const t = useTranslate('shared-admin-ui.AdvancedSettingsStep');

  const onSubmit: AdvancedCourseSettingsFormProps['onSubmit'] = async (values, options) => {
    try {
      const ids = await onAdvancedSettingsSubmit(values, options);
      setCourseIds(ids);
      setShowSuccessModal(true);
    } catch (error: unknown) {
      console.error(error);
      toast.error(t('error'));
      throw error;
    }
  };

  const goToOverview = () => {
    if (courseIds?.length === 1) {
      goToCoursePage(courseIds[0]);
    } else {
      goToCoursesPage();
    }
    setShowSuccessModal(false);
  };

  const onFinishClick = () => {
    goToFinishPage();
    setShowSuccessModal(false);
  };

  return (
    <>
      <Alert
        show={method === 'upload' && showAlert}
        dismissible
        onClose={() => setShowAlert(false)}
        className={styles.Alert}
      >
        {t('uploadCoursesNotification')}
      </Alert>

      <AdvancedCourseSettingsForm
        onBackProps={{
          onClick: navigation.previous,
          children: t('back')
        }}
        mode="create"
        onSubmit={onSubmit}
        submitProps={{
          children: t('upload')
        }}
        onCancelProps={{
          children: t('skipAndFinish')
        }}
      />

      <SuccessModal
        show={showSuccessModal}
        onHide={onFinishClick}
        children={t('message')}
        action={{
          onClick: onFinishClick,
          content: t('finishAndClose')
        }}
        secondaryAction={{
          onClick: goToOverview,
          suffixIcon: ArrowUpRightFromSquareIcon,
          content: t('goToOverview', { count: courseIds?.length })
        }}
      />
    </>
  );
};
