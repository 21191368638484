import { useEffect, useState } from 'react';

export function useFade(initial) {
  const [show, setShow] = useState(initial);
  const [isVisible, setVisible] = useState(show);

  useEffect(() => {
    if (show) {
      setVisible(true);
    }
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) {
      setVisible(false);
    }
  };

  const animationClass = show ? 'show' : 'hide';

  return [isVisible, setShow, animationClass, onAnimationEnd];
}
