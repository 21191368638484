import type { FC } from 'react';

import type { FetchApprovedCoursesWithPricingOutput, SetHomeStudentCourseEnrollmentPricingInput } from '@acadeum/api';
import type { UserProfile } from '@acadeum/types';

import { ApprovedCoursesSection } from './ui/ApprovedCoursesSection';
import { PricingSection } from './ui/PricingSection';

export interface StudentPortalPageProps extends Pick<FetchApprovedCoursesWithPricingOutput, 'courses' | 'homeStudentCourseEnrollmentPricing'> {
  user: Pick<UserProfile, 'id' | 'institution' | 'roles'>;
  formatCurrency: (value: number, options?: { currency: string, cents: boolean }) => string;
  setHomeStudentCourseEnrollmentPricing: (value: SetHomeStudentCourseEnrollmentPricingInput) => void;
  onAfterSubmitEditPricing?: () => void;
}

export const StudentPortalPage: FC<StudentPortalPageProps> = ({
  user,
  courses,
  homeStudentCourseEnrollmentPricing,
  formatCurrency,
  setHomeStudentCourseEnrollmentPricing,
  onAfterSubmitEditPricing
}) => {
  return (
    <>
      <ApprovedCoursesSection courses={courses}/>
      <br/>
      <PricingSection
        user={user}
        homeStudentCourseEnrollmentPricing={homeStudentCourseEnrollmentPricing}
        formatCurrency={formatCurrency}
        onAfterSubmit={onAfterSubmitEditPricing}
        setHomeStudentCourseEnrollmentPricing={setHomeStudentCourseEnrollmentPricing}
      />
    </>
  );
};
