import React from 'react';
import classNames from 'classnames';

import { Title } from '@acadeum/ui';

import './Subsection.sass';

interface SubsectionProps {
  title: string;
  description?: string;
  marginTop?: 'large' | 'medium';
  marginBottom?: 'large';
  className?: string;
  children: React.ReactNode;
  heading?: 'new'
}

const Subsection = React.forwardRef<HTMLDivElement, SubsectionProps>(({
  title,
  description,
  marginTop,
  marginBottom,
  className,
  children,
  heading
}, ref) => {
  return (
    <section ref={ref} className={classNames('Subsection', className, {
      'Subsection--marginTopLarge': marginTop === 'large',
      'Subsection--marginTopMedium': marginTop === 'medium',
      'Subsection--marginBottomLarge': marginBottom === 'large'
    })}>
      <header className={classNames('Subsection-header', {
        'Subsection-header--marginBottomLarge': !description
      })}>
        <Title level={2} className={classNames('Subsection-heading', {
          'Subsection-heading--new': heading === 'new'
        })}>
          {title}
        </Title>
        <div className="Subsection-headingLine" />
      </header>
      {description && (
        <p className="Subsection-copy">
          {description}
        </p>
      )}
      {children}
    </section>
  );
});

export default Subsection;
