/**
 * Compares two strings.
 * A helper for `Array.sort()`.
 * @param {string} a — First string.
 * @param {string} b — Second string.
 * @param {(string[]|string)} [locales] — The `locales` argument of `String.localeCompare`.
 */
export function compareStrings(a: string, b: string, locales?: string | string[]) {
  // Use `String.localeCompare` if it's available.
  // https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
  // Which means everyone except IE <= 10 and Safari <= 10.
  // `localeCompare()` is available in latest Node.js versions.
  if (typeof String.prototype.localeCompare === 'function') {
    return a.localeCompare(b, locales);
  }
  return a < b ? -1 : (a > b ? 1 : 0);
}

