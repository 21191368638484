import type { FC } from 'react';
import React from 'react';

import formatUserName from 'common-lib/lib/formatUserName';

import { UserPicture, Text, Email } from '@acadeum/ui';
import type { User } from '@acadeum/types';

import styles from './UserBadge.module.scss';

export interface UserBadgeProps {
  user: Pick<User, 'firstName' | 'lastName' | 'middleName' | 'title' | 'email' | 'userPictureUrl'>;
}

const UserBadge: FC<UserBadgeProps> = ({
  user
}) => {
  return (
    <div className={styles.UserBadge}>
      <UserPicture size={32} user={user}/>
      <div>
        <Text color="black">
          {formatUserName(user)}
        </Text>
        <Text variant="bodyMd">
          <Email address={user.email}/>
        </Text>
      </div>
    </div>
  );
};

export default UserBadge;
