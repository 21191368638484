import React from 'react';

import { Button } from '../Button';

import styles from './SkipToMainContent.module.scss';

export const SkipToMainContent: React.FC = () => {
  const onKeyDown = (event) => {
    if (
      event.ctrlKey || // Open In New Tab
      event.altKey || // Download
      event.shiftKey || // Open In New Window
      event.metaKey // What is this?
    ) {
      return event.preventDefault();
    }
  };

  return (
    <Button url="#main" onKeyDown={onKeyDown} className={styles.SkipToMainContent}>
      Skip to content
    </Button>
  );
};
