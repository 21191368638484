import type { FC } from 'react';
import parseTerm from 'common-lib/lib/parseTerm';

import { Text, Title } from '@acadeum/ui';
import type { FetchApprovedCoursesWithPricingOutput } from '@acadeum/api';

import styles from './ApprovedCoursesSection.module.scss';

interface ApprovedCoursesSectionProps {
  courses: FetchApprovedCoursesWithPricingOutput['courses'];
}

export const ApprovedCoursesSection: FC<ApprovedCoursesSectionProps> = ({ courses }) => {
  const sessionsByTerm = {};
  const coursesByTerm = {};

  // "Earliest timestamps" are only used for sorting terms.
  const earliestSessionTimestampsByTerm = {};

  for (const course of courses) {
    // `course.sessions` are already filtered on the server side for being enrollable.
    // for (const session of getEnrollableSessions(course)) {
    for (const session of course.sessions) {
      const term = parseTerm(session.term)[0];
      earliestSessionTimestampsByTerm[term] = Math.min(
        earliestSessionTimestampsByTerm[term] || Infinity,
        session.startDate.getTime()
      );
      sessionsByTerm[term] = (sessionsByTerm[term] || []);
      const existingSession = sessionsByTerm[term].find(_ => _.name === session.name);
      if (existingSession) {
        existingSession.sectionsCount += session.sections.length;
      } else {
        sessionsByTerm[term].push({
          name: session.name,
          sectionsCount: session.sections.length
        });
      }
      // Add the course to the list of courses having this term.
      if (!coursesByTerm[term]) {
        coursesByTerm[term] = [];
      }
      if (!coursesByTerm[term].includes(course)) {
        coursesByTerm[term].push(course);
      }
    }
  }

  const terms = Object.keys(sessionsByTerm).sort((a, b) => {
    return earliestSessionTimestampsByTerm[a] - earliestSessionTimestampsByTerm[b];
  });

  const maxSessionsCount = Math.max(...terms.map(term => sessionsByTerm[term].length).concat(0));

  return (
    <section>
      <header>
        <Title level={2}>
          Scheduling
        </Title>
        <Text variant="headingMd" mb="sm">
          These are the terms and sessions of approved courses made available to your students, if they are offered by the Teaching Institution during that term.
        </Text>
      </header>
      <div className={styles['table-background']}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Terms</th>
              <th>Courses</th>
              <th>Sections:</th>
              {times(maxSessionsCount).map((i) => (
                <th key={i}>
                  Session {i + 1}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {terms.map((term) => (
              <tr key={term}>
                <td>{term}</td>
                <td>{coursesByTerm[term].length}</td>
                <td>{sessionsByTerm[term].reduce((count, session) => count + session.sectionsCount, 0)}</td>
                {times(maxSessionsCount).map((i) => (
                  <td key={i}>
                    {sessionsByTerm[term].length > i ? sessionsByTerm[term][i].sectionsCount : null}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

const times = (n) => {
  const times = new Array(n);
  let i = 0;
  while (i < n) {
    times[i] = i;
    i++;
  }
  return times;
};
