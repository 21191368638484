import React from 'react';

import { LogoutPage } from '@acadeum/shared-admin-ui';

import actions from '../../actions';

const {
  signOutClearCookie,
  signOutClearState,
  goBack,
  goto
} = actions;

export default function LogOut() {
  return (
    <LogoutPage
      isCourseShare
      logOut={() => {
        signOutClearCookie();
        signOutClearState();
      }}
      navigateBack={goBack}
      redirectTo={goto}
    />
  );
}
