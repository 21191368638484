import type { FC } from 'react';
import React from 'react';
import type { InferProps } from 'prop-types';
import PropTypes from 'prop-types';
import Card from 'react-credit-cards';

const propTypes = {
  card: PropTypes.shape({
    brand: PropTypes.string,
    expiresMonth: PropTypes.number,
    expiresYear: PropTypes.number,
    lastFourDigits: PropTypes.string,
    name: PropTypes.string
  }).isRequired
};

import {
  formatCreditCardPreviewNumber,
  convertCardBrandNameToId,
  getCreditCardExpiresDate
} from '../../utils/creditCard';

export const CreditCard: FC<InferProps<typeof propTypes>> = ({
  card
}) => {
  const { lastFourDigits, brand  } = card;

  return (
    <Card
      preview
      name=" "
      cvc=" "
      number={formatCreditCardPreviewNumber(lastFourDigits, convertCardBrandNameToId(brand))}
      expiry={getCreditCardExpiresDate(card)}
      issuer={convertCardBrandNameToId(brand)}
    />
  );
};

CreditCard.propTypes = propTypes;
