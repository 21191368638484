import React, { memo } from 'react';
import type { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './ErrorMessage.module.scss';
import { Icon } from '../Icon';
import { CircleExclamationSolidIcon } from '@acadeum/icons';

export interface ErrorMessageProps {
  id?: string;
  className?: string;
  children: ReactNode;
  withIcon?: boolean;
}

export const ErrorMessage = memo<ErrorMessageProps>(({
  id,
  className,
  children,
  withIcon = true
}) => {
  return (
    <p id={id} className={classNames(className, styles.root, {
      [styles.withIcon]: withIcon
    })}>
      {withIcon && (
        <Icon icon={CircleExclamationSolidIcon} className={styles.icon}/>
      )}
      {children}
    </p>
  );
});
