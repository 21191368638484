import React from 'react';

import type { FormRef } from '@acadeum/core-ui';
import type { FormProps } from '@acadeum/ui';
import { Button, Form, FormField, FormFooter, ContentSection, FormSubmit } from '@acadeum/ui';
import type { UserRole } from '@acadeum/types';
import { useTranslate } from '@acadeum/translate';

interface FormValues {
  name: UserRole['name'];
  description: UserRole['description'];
}

export interface GeneralRoleInfoFormProps {
  defaultValues?: FormValues;
  mode: 'readOnly' | 'edit' | 'create';
  onSubmit: FormProps<FormValues>['onSubmit'];
  onCancel?: () => void;
  submitText: string;
}

export const GeneralRoleInfoForm = React.forwardRef<FormRef, GeneralRoleInfoFormProps>(({
  defaultValues,
  onSubmit,
  onCancel,
  mode,
  submitText
}, ref) => {
  const t = useTranslate('shared-admin-ui.GeneralRoleInfoForm');

  return (
    <Form
      ref={ref}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      style={{ maxWidth: '58rem' }}
    >
      {({ isDirty }) => {
        const showFooter = mode === 'create' ? true : mode === 'edit' ? isDirty : false;
        return (
          <>
            <ContentSection title={t('roleInformation')}>
              <FormField
                required={mode !== 'readOnly'}
                type="text"
                name="name"
                readOnly={mode === 'readOnly'}
                label={t('name.label')}
                placeholder={t('name.placeholder')}
              />
              <FormField
                required={mode !== 'readOnly'}
                multiline
                type="text"
                name="description"
                readOnly={mode === 'readOnly'}
                label={t('description.label')}
                placeholder={t('description.placeholder')}
              />
            </ContentSection>
            {showFooter && (
              <FormFooter>
                <Button onClick={onCancel} variant="secondary">
                  {t('cancel')}
                </Button>
                <FormSubmit>
                  {submitText}
                </FormSubmit>
              </FormFooter>
            )}
          </>
        );
      }}
    </Form>
  );
});
