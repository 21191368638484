import type { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';

import type { MarginProp } from '@acadeum/hooks';
import { useMargin } from '@acadeum/hooks';

import type { AsProp } from '@acadeum/types';

import styles from './Flex.module.scss';

export type FlexJustify = 'start' | 'center' | 'end' | 'between' | 'around';
export type FlexAlign = 'start' | 'center' | 'end' | 'stretch' | 'none';
export type FlexDirection = 'row' | 'column';
export type FlexWrap = 'nowrap' | 'wrap';
export type FlexGap = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';

const justifyClasses: Record<FlexJustify, string> = {
  around: styles.justifyAround,
  start: styles.justifyStart,
  center: styles.justifyCenter,
  end: styles.justifyEnd,
  between: styles.justifyBetween
};

const alignClasses: Record<FlexAlign, string> = {
  start: styles.alignStart,
  center: styles.alignCenter,
  end: styles.alignEnd,
  stretch: styles.alignStretch,
  none: styles.alignNone
};

const directionClasses: Record<FlexDirection, string> = {
  row: styles.directionRow,
  column: styles.directionColumn
};

const gapClasses: Record<FlexGap, string> = {
  xs: styles.gapXs,
  sm: styles.gapSm,
  md: styles.gapMd,
  lg: styles.gapLg,
  xl: styles.gapXl,
  '2xl': styles.gap2xl,
  '3xl': styles.gap3xl
};

type DivProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export interface FlexProps extends DivProps, Pick<MarginProp, 'ml' | 'mb' | 'mt' | 'mr'>, AsProp {
  className?: string;
  children: ReactNode;
  justify?: FlexJustify;
  align?: FlexAlign;
  direction: FlexDirection;
  wrap?: FlexWrap;
  gap?: FlexGap;
  max?: boolean;
  inline?: boolean;
}

export const Flex = ({
  as: Component = 'div',
  className,
  children,
  justify = 'start',
  align = 'center',
  direction = 'row',
  wrap = 'nowrap',
  inline,
  gap,
  max,
  mb,
  mt,
  ml,
  mr,
  ...rest
}: FlexProps) => {
  const { marginClassNames } = useMargin({ mt, ml, mr, mb });

  return (
    <Component
      className={classNames(
        styles.Flex,
        className,
        justifyClasses[justify],
        alignClasses[align],
        directionClasses[direction],
        styles[wrap],
        gap && gapClasses[gap],
        marginClassNames,
        {
          [styles.max]: max,
          [styles.inline]: inline
        }
      )}
      {...rest}
    >
      {children}
    </Component>
  );
};
