import { rtkApi } from './rtkApi';

import { getAuthenticationSettingsApi } from '@acadeum/api';

const authenticationSettingsApi = getAuthenticationSettingsApi(rtkApi);

export const {
  useFetchSingleSignOnConfigQuery,
  useResetSingleSignOnConfigMutation
} = authenticationSettingsApi;
