/* eslint-disable */
import React, { useCallback, useRef, useId, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useComboboxOption } from '../../Select/Combobox/hooks';
import { useChoiceListOption } from '../../ChoiceList/context';
import { useListbox } from '../hooks';

import Checkmark from './checkmark.svg';
import MultipleCheckmark from './multipleCheckmark.svg';

import styles from './Option.module.scss';

interface OptionProps {
  value?: any;
  children: string | React.ReactNode[];
  selected?: boolean;
  disabled?: boolean;
  className?: string;
  description?: React.ReactNode;
  count?: number;
}

const Option: React.FC<OptionProps> = memo(({
  value,
  children,
  selected,
  disabled = false,
  className,
  description,
  count
}) => {
  // @ts-ignore
  const { multiple: comboboxMultiple } = useComboboxOption();
  const { multiple: choiceListMultiple } = useChoiceListOption();

  const multiple = comboboxMultiple || choiceListMultiple;

  const { onOptionSelect } = useListbox();

  const listItemRef = useRef(null);
  const id = useId() + 'ListboxOption';

  const handleOptionSelect = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    if (listItemRef.current) {
      onOptionSelect({
        domId: id,
        value,
        element: listItemRef.current,
        disabled
      });
    }
  }, [id, onOptionSelect, value, disabled]);

  // prevents lost of focus on input
  const onMouseDown = (event) => {
    event.preventDefault();
  };

  return (
    <li
      data-listbox-option
      data-listbox-option-value={value}
      data-listbox-option-value-type={value === null ? 'null' : typeof value}
      ref={listItemRef}
      id={id}
      className={classNames(className, styles.Option, {
        [styles['Option--hasDescription']]: description
      })}
      role="option"
      aria-label={typeof children === 'string' ? children : ''}
      aria-selected={selected}
      aria-disabled={disabled}
      title={typeof children === 'string' ? children : ''}
      onClick={disabled ? undefined : handleOptionSelect}
      onKeyDown={disabled ? undefined : handleOptionSelect}
      onMouseDown={onMouseDown}
    >
      <div className={styles.Option__container}>
        <div className={styles['Option__container-header']}>
          {multiple && (
            <span className={styles.Option__checkbox}>
              {selected && (
                <MultipleCheckmark className={styles.icon} />
              )}
            </span>
          )}
          <span className={styles.Option__label}>
            <span className={styles.Option__labelContentWrapper}>
              <span className={styles.Option__labelContent}>
                {children}
              </span>
              {typeof count === 'number' && (
                <span className={styles.Option__labelCount}>
                  {/** Wrapped without className for correct parent `flex` element work */}
                  {count.toLocaleString()}
                </span>
              )}
            </span>
          </span>
          {!multiple && selected && (
            <Checkmark className={styles.Option__checkmark}/>
          )}
        </div>
        {description && (
          <span className={styles.Option__description}>
            {description}
          </span>
        )}
      </div>
    </li>
  );
});

Option.propTypes = {
  value: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  description: PropTypes.node,
  count: PropTypes.number
};

export default Option;
