import React, { useId } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Field as Field_ } from 'easy-react-form';

import { useTranslate } from '@acadeum/translate';

import Input from '../Input';
import { onField } from '../FormField';

export default function Field({
  id,
  col,
  type: _type,
  validate: _validate,
  className,
  component = Input,
  ...rest
}) {
  const t = useTranslate('Form');
  const newId = `Field-${useId()}`;

  const { type, validate } = onField({
    type: _type,
    validate: _validate,
    t
  });

  return (
    <Field_
      {...rest}
      id={id || newId}
      type={type}
      validate={validate}
      component={component}
      className={classNames(
        className,
        col && `col-${col}`,
        col && 'col-xs-12',
        'form__field'
      )}
    />
  );
}

Field.propTypes = {
  component: PropTypes.elementType,
  type: PropTypes.string,
  validate: PropTypes.func,
  col: PropTypes.number,
  className: PropTypes.string
};
