// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck TODO: Fix All types
import React, { Children, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';

import { useUpdateEffect } from '@acadeum/hooks';

import CreateNewOptionButton from './CreateNewOptionButton';
import { ReactPortal } from '../../ReactPortal';
import { Form } from '../../Form';
import { useModalSelectCombobox } from '../../Modal';

import {
  ComboboxListboxContext,
  ComboboxListboxOptionContext,
  ComboboxTextFieldContext,
  ComboboxToggleContext
} from './context';

import styles from './Combobox.module.scss';

const Combobox = ({
  id,
  hidden,
  toggleButton,
  textField,
  multiple,
  children,
  createNewOptionProps,
  createCustomOptionProps,
  onCreateNewOption,
  onCustomOptionClick: onCustomOptionClick_,
  sameWidth
}) => {
  const [expanded, setExpanded] = useState(false);
  const [expandedForm, setExpandedForm] = useState(false);
  const [activeOptionId, setActiveOptionId] = useState();
  const [listboxId, setListboxId] = useState();
  const [textFieldFocused, setTextFieldFocused] = useState(false);
  const shouldOpen = Boolean(!expanded && Children.count(children) > 0);

  const disableCloseOnSelect = useRef(false);

  const toggleRef = useRef();

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [formWrapperElement, setFormWrapperElement] = useState();

  const modalContext = useModalSelectCombobox();

  const modifiers = useMemo(() => [
    {
      name: 'sameWidth',
      enabled: true,
      phase: 'beforeWrite',
      requires: ['computeStyles'],
      fn: ({ state }) => {
        state.styles.popper.minWidth = `${state.rects.reference.width}px`;
        if (sameWidth) {
          state.styles.popper.width = `${state.rects.reference.width}px`;
        }
      }
    },
    {
      name: 'offset',
      enabled: true,
      options: {
        offset: [0, 4]
      }
    }
  ], [sameWidth]);

  const {
    styles: popperStyles,
    attributes: popperAttributes,
    update: updatePopperPosition
  } = usePopper(referenceElement, popperElement, {
    strategy: 'fixed',
    placement: 'bottom-start',
    modifiers
  });

  useUpdateEffect(() => {
    if (expanded) {
      void updatePopperPosition?.();
    }
  }, [expanded, updatePopperPosition]);

  const handleClose = useCallback(({ preventFocus } = {}) => {
    if (!disableCloseOnSelect.current) {
      setExpanded(false);
      if (!preventFocus) {
        toggleRef.current?.focus();
      }
      modalContext?.onCloseCombobox();
    }

    setActiveOptionId(undefined);
  }, []);

  const handleOpen = useCallback(() => {
    setExpanded(true);
    setActiveOptionId(undefined);
    modalContext?.onOpenCombobox();
  }, []);

  // TODO: Fix
  // temporary bug fix
  // The popup does not close when the element is deleted
  // https://www.loom.com/share/8e51654c713c4b73987e2ad77ecd4024
  useEffect(() => {
    if (hidden) {
      setExpanded(false);
    }
  }, [hidden]);

  const onOptionSelected = useCallback(() => {
    if (multiple) {
      disableCloseOnSelect.current = true;
    } else {
      handleClose();
      setActiveOptionId(undefined);
      if (createCustomOptionProps && typeof createCustomOptionProps.onDeactivateCustomOption === 'function') {
        createCustomOptionProps.onDeactivateCustomOption();
      }
    }

    if (typeof updatePopperPosition === 'function') {
      void updatePopperPosition();
    }
  }, [multiple, handleClose, updatePopperPosition]);

  const handleFocus = useCallback(() => {
    if (shouldOpen) {
      handleOpen();
    }
  }, [shouldOpen, handleOpen]);

  const handleChange = useCallback(() => {
    if (shouldOpen) {
      handleOpen();
    }
  }, [shouldOpen, handleOpen]);

  const handleBlur = useCallback((event) => {
    disableCloseOnSelect.current = false;
    if (expanded) {
      // Prevent close combobox when focus gets to ListBox or combobox dropdown. Also they should prevent click
      if (
        event?.relatedTarget?.getAttribute('id') === listboxId ||
        event?.relatedTarget === popperElement
      ) {
        event?.preventDefault();
        event?.target?.focus();
        return;
      }
      handleClose({ preventFocus: true });
    }
  }, [expanded, handleClose, listboxId]);

  const preventClick = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const onKeyDown = useCallback((event) => {
    if (event.keyCode === 27) { // Escape
      if (expanded) {
        disableCloseOnSelect.current = false;
        event.preventDefault();
        event.stopPropagation();
        handleClose();
      }
    }
  }, [expanded, handleClose]);

  const onToggleClick = useCallback(() => {
    if (expandedForm) {
      setExpandedForm(false);
    }
    (expanded ? handleClose : handleOpen)();
  }, [expanded, handleClose, handleOpen, expandedForm]);

  const toggleContextValue = useMemo(() => ({
    listboxId,
    expanded: expanded || expandedForm,
    multiple,
    toggleRef,
    referenceElement,
    setReferenceElement,
    onToggleClick: onToggleClick,
    onToggleBlur: textField ? undefined : handleBlur,
    onToggleKeyDown: onKeyDown
  }), [
    listboxId,
    expanded,
    expandedForm,
    multiple,
    onKeyDown,
    onToggleClick,
    referenceElement,
    setReferenceElement
  ]);

  const textFieldContextValue = useMemo(() => ({
    activeOptionId,
    expanded,
    listboxId,
    setTextFieldFocused,
    onTextFieldFocus: handleFocus,
    onTextFieldChange: handleChange,
    onTextFieldBlur: handleBlur,
    onTextFieldKeyDown: onKeyDown
  }), [
    activeOptionId,
    expanded,
    listboxId,
    setTextFieldFocused,
    handleFocus,
    handleChange,
    handleBlur,
    onKeyDown
  ]);

  const listboxOptionContextValue = useMemo(() => ({
    multiple
  }), [multiple]);

  const listboxContextValue = useMemo(() => ({
    listboxId,
    textFieldFocused,
    onOptionSelected,
    setActiveOptionId,
    setListboxId,
    onListboxClick: preventClick,
    onKeyDown,
    handleFocus,
    handleBlur
  }), [
    listboxId,
    textFieldFocused,
    onOptionSelected,
    setActiveOptionId,
    setListboxId
  ]);

  const onSubmitCreateNewOption = useCallback(async (values, { clearForm }) => {
    try {
      const option = await createNewOptionProps.onSubmit(values);
      if (typeof option === 'object' && option !== null) {
        if (!Object.hasOwn(option, 'value') || !Object.hasOwn(option, 'label')) {
          throw new Error('option must have "value" and "label" keys');
        }
      } else {
        throw new Error('option should be object');
      }
      await onCreateNewOption(option);
      toggleRef.current?.focus();
      setExpandedForm(false);
    } catch (error) {
      clearForm();
      throw error;
    }
  }, [createNewOptionProps, onCreateNewOption]);

  const onCustomOptionClick = useCallback(async () => {
    if (typeof createCustomOptionProps.onActivateCustomOption === 'function') {
      createCustomOptionProps.onActivateCustomOption();
    }

    if (createCustomOptionProps.option) {
      await onCustomOptionClick_(createCustomOptionProps.option);
    }
    handleClose();
  }, [createCustomOptionProps]);

  const onMouseDown = useCallback((event) => {
    event.preventDefault();
  }, []);

  const getFormSubmitProps = () => ({ onMouseDown });

  let createNewOptionFormChildren;
  if (createNewOptionProps) {
    if (typeof createNewOptionProps.children === 'function') {
      createNewOptionFormChildren = createNewOptionProps.children({ getFormSubmitProps });
    } else {
      throw new Error('[Select/Combobox]`createNewOptionProps.children` should be function');
    }
  }

  const onBlurCreateNewOptionForm = useCallback((event) => {
    const focusedNode = event.relatedTarget;
    if (!formWrapperElement.contains(focusedNode)) {
      setExpandedForm(false);
    }
  }, [setExpandedForm, formWrapperElement]);

  const popperChildren = expandedForm ? (
    <div
      ref={setFormWrapperElement}
      style={{ padding: '.75rem .75rem 1rem' }}
      onBlur={onBlurCreateNewOptionForm}
    >
      <Form
        isNestedForm
        autoFocus
        onSubmit={onSubmitCreateNewOption}
      >
        {createNewOptionFormChildren}
      </Form>
    </div>
  ) : (
    expanded && Children.count(children) > 0 && (
      <>
        <ComboboxTextFieldContext.Provider value={textFieldContextValue}>
          {textField && (
            <div style={{ margin: '.5rem .75rem' }}>
              {textField}
            </div>
          )}
        </ComboboxTextFieldContext.Provider>
        <ComboboxListboxContext.Provider value={listboxContextValue}>
          <ComboboxListboxOptionContext.Provider value={listboxOptionContextValue}>
            {children}
          </ComboboxListboxOptionContext.Provider>
        </ComboboxListboxContext.Provider>

        {createNewOptionProps && (
          <CreateNewOptionButton onClick={() => {
            handleClose();
            setExpandedForm(true);
          }}>
            {createNewOptionProps.label}
          </CreateNewOptionButton>
        )}

        {createCustomOptionProps && (
          <CreateNewOptionButton onClick={onCustomOptionClick}>
            {createCustomOptionProps.label}
          </CreateNewOptionButton>
        )}
      </>
    )
  );

  useEffect(() => {
    if (popperElement) {
      const searchField = popperElement.querySelector('[role="combobox"]');
      if (searchField) {
        searchField.focus();
        return;
      }

      const list = popperElement.querySelector('[role="listbox"]');
      list?.focus();
    }
  }, [popperElement]);

  return (
    <div className={styles.Combobox}>
      <ComboboxTextFieldContext.Provider value={textFieldContextValue}>
        <ComboboxToggleContext.Provider value={toggleContextValue}>
          {toggleButton}
        </ComboboxToggleContext.Provider>
      </ComboboxTextFieldContext.Provider >
      <ReactPortal id="acadeum-combobox" layer="top">
        {popperChildren && (
          <div
            id={`${id}ListboxContainer`}
            ref={setPopperElement}
            style={popperStyles.popper}
            className={styles.Popper}
            onClick={expandedForm ? undefined : preventClick}
            {...popperAttributes.popper}
          >
            {popperChildren}
          </div>
        )}
      </ReactPortal>
    </div>
  );
};

Combobox.propTypes = {
  id: PropTypes.string.isRequired,
  toggleButton: PropTypes.node.isRequired,
  textField: PropTypes.node,
  children: PropTypes.node.isRequired,
  multiple: PropTypes.bool,
  createNewOptionProps: PropTypes.shape({
    label: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.node
    ]).isRequired,
    // Should return object { value: [SOME_VALUE], label: [SOME LAbel] }, or throw error
    onSubmit: PropTypes.func.isRequired
  }),
  onCreateNewOption: PropTypes.func.isRequired
};

export default Combobox;
