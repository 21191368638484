import { getUsersApi } from '@acadeum/api';

import { rtkApi } from './rtkApi';

export const {
  useFetchUsersQuery,
  useFetchUserQuery,
  useDeactivateUserMutation,
  useDeleteUserMutation,
  useActivateUserMutation,
  useSetUsersRolesForUsersMutation,
  useCreateUserMutation,
  useUpdateUserMutation,
  useFetchUserLogQuery
} = getUsersApi(rtkApi);
