import COURSE_ENROLLMENT_GRADE_STATUSES from 'common-lib/constants/courseEnrollmentGradeStatuses.json';
import COURSE_ENROLLMENT_ACCEPTED_STATUSES from 'common-lib/constants/courseEnrollmentAcceptedStatuses.json';

export function getStudentEnrollments(enrollmentRequests) {
  let studentEnrollments = [];
  for (const enrollmentRequest of enrollmentRequests) {
    const enrollments = enrollmentRequest.enrollments;
    studentEnrollments = studentEnrollments.concat(enrollments.map((studentEnrollment) => ({
      ...studentEnrollment,
      institution: (enrollmentRequest.institution || studentEnrollment.student.institution),
      // `enrollments` objects don't have a `.section` property
      // because it would be duplicated across all enrollments of a given enrollment request.
      // This way, the response payload size is smaller.
      section: enrollmentRequest.section,
      // `courseSubstitute` is fetched when exporting data
      // in student enrollments tables (EM/TM).
      courseSubstitute: enrollmentRequest.courseSubstitute
    })));
  }
  return studentEnrollments;
}

export const LIVE_ENROLLMENT_STATUSES_GROUPED_BY_TYPE = {
  'Requested': ['REQUESTED'],
  'Accepted': COURSE_ENROLLMENT_ACCEPTED_STATUSES
};

export function isGradeEnrollment(studentEnrollment) {
  return COURSE_ENROLLMENT_GRADE_STATUSES.includes(studentEnrollment.status) &&
    Boolean(studentEnrollment.letterGrade);
}

// These conditions must be equal to the ones in
// `ASP-API/counts/teachingEnrollments/index.js`.
export function isAwaitingGrade(studentEnrollment) {
  return studentEnrollment.status === 'DUE' && (studentEnrollment.type === 'CONSORTIAL' || studentEnrollment.type === 'DUAL');
}

// function isGradeStatus(studentEnrollment) {
//   return COURSE_ENROLLMENT_GRADE_STATUSES.includes(studentEnrollment.status);
// }
