import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { RefinementList } from 'react-instantsearch-dom';
import RefinementList from './react-instantsearch-dom/widgets/RefinementList';
import { NumericMenu } from 'react-instantsearch-dom';
import classNames from 'classnames';

import instantSearchCollapsible from './instantSearchCollapsible';

import './InstantSearchMultiSelectList.sass';

// One can pass `defaultRefinement` array property to this component
// to pre-select some of the options from the list (e.g. `['a', 'b', ...]`).
// React will throw a warning about that due to a bug in `react-instantsearch`:
// https://github.com/algolia/react-instantsearch/issues/351
// The warning thrown is not an error and can be ignored (so far).
// The `react-instantsearch` library is clearly not being actively maintained anymore.

class InstantSearchMultiSelectList extends Component {
  static propTypes = {
    showMore: PropTypes.bool.isRequired
  };

  static defaultProps = {
    showMore: true
  };

  // https://github.com/algolia/react-instantsearch/blob/master/packages/react-instantsearch-dom/src/components/Panel.js
  static childContextTypes = {
    setCanRefine: PropTypes.func.isRequired
  };

  state = {
    canRefine: true
  };

  getChildContext() {
    return {
      setCanRefine: this.setCanRefine
    };
  }

  setCanRefine = (canRefine) => {
    this.setState({ canRefine });
  };

  render() {
    const { numeric, className } = this.props;
    const { canRefine } = this.state;

    const List = numeric ? NumericMenu : RefinementList;

    return (
      <div className={classNames('InstantSearch-MultiSelectList', className)}>
        <List
          translations={translations}
          showMoreLimit={1000}
          {...this.props}/>

        {!canRefine &&
          <div className="InstantSearch-MultiSelectList-empty">
            Nothing found
          </div>
        }
      </div>
    );
  }
}

InstantSearchMultiSelectList.propTypes = {
  numeric: PropTypes.bool,
  className: PropTypes.string
};

const translations = {
  placeholder: 'Search',
  showMore: (showingMore) => showingMore ? '(-) See Less' : '(+) See More'
};

export default instantSearchCollapsible(InstantSearchMultiSelectList);
