import React, { useId } from 'react';
import type { FC } from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';

import { Select } from '@acadeum/ui';

interface InstantSearchMultiSelectListDropdownProps {
  attribute: string;
  defaultRefinement?: string[];
  transformItems?: () => void;
  title: string;
  className?: string;
}

const InstantSearchMultiSelectListDropdown_ = connectRefinementList(({
  title,
  items,
  refine,
  hiddenSelect,
  className
}) => {
  const id = useId();
  const selectId = id + 'MultiSelectList';

  const value = items.reduce((selectedValues, item) => {
    if (item.isRefined) {
      selectedValues.push(item.value);
    }
    return selectedValues;
  }, []);

  return (
    <Select
      isFilter
      multiple
      hiddenSelect={hiddenSelect}
      className={className}
      id={selectId}
      label={title}
      value={value}
      onChange={(values, items, prevValues) => {
        // Potentially, `value` could be `undefined`, so it uses `.findIndex()` instead of `.find()`.
        const addedValueIndex = values?.findIndex(value => !prevValues?.includes(value));
        const removedValueIndex = prevValues?.findIndex(value => !values?.includes(value));
        if (addedValueIndex >= 0) {
          refine(values[addedValueIndex]);
        } else if (removedValueIndex >= 0) {
          refine(prevValues[removedValueIndex]);
        }
      }}
      options={items}
    />
  );
});

const InstantSearchMultiSelectListDropdown: FC<InstantSearchMultiSelectListDropdownProps> = ({ transformItems, ...rest }) => {
  return (
    <InstantSearchMultiSelectListDropdown_
      showMore={false}
      limit={1000}
      transformItems={transformItems || sortItemsByCountDescending}
      {...rest}
    />
  );
};

export default InstantSearchMultiSelectListDropdown;

// Mutates the `items`.
function sortItemsByCountDescending(items) {
  return items.sort((a, b) => b.count - a.count);
}
