import convertLocalDateToUtcDateWithSameTime from 'common-lib/lib/convertLocalDateToUtcDateWithSameTime';
import convertUtcDateToLocalDateWithSameTime from 'common-lib/lib/convertUtcDateToLocalDateWithSameTime';

const ONE_DAY = 24 * 60 * 60 * 1000;

function normalizeSelectedDate(date, { endOfDay = false } = {}) {
  if (date) {
    date = new Date(date);
    // Set the time to `00:00` in the user's timezone.
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    if (endOfDay) {
      // Set the time to `23:59:59` in the user's timezone.
      date = new Date(date.getTime() + ONE_DAY - 1);
    }
  }
  return date;
}

export function normalizeInboundDate(date, { utc }) {
  if (date) {
    date = new Date(date);
    if (utc) {
      return convertUtcDateToLocalDateWithSameTime(date);
    }
  }
  return date;
}

export function normalizeOutboundDate(date, { utc }) {
  if (date) {
    const normalizeDate = normalizeSelectedDate(date);
    if (utc) {
      return convertLocalDateToUtcDateWithSameTime(normalizeDate);
    } else {
      return normalizeDate;
    }
  }
  return date;
}
