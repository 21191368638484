import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const fetchDashboardData = redux.action(
  (data) => (http) => http.post('/dashboard', data),
  'dashboard'
);

export default redux.reducer();
