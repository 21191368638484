import React, { useId } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Group from './Group';
import { Radio } from '../Radio';

import { useRadioCardGroupRadioCard } from './Group/hooks';

import styles from './RadioCard.module.scss';

export interface RadioCardProps {
  label: string;
  value: string;
  children?: React.ReactNode;
  disabled?: boolean;
}

export const RadioCard: React.FC<RadioCardProps> & {
  Group: typeof Group
} = ({
  label,
  value,
  children,
  disabled
}) => {
  const id = 'RadioCard-' + useId();

  const { name, selectedValue, onSelectValue, disabled: disabledGroup } = useRadioCardGroupRadioCard();

  return (
    <div className={styles.RadioCard}>
      <Radio
        id={id}
        name={name}
        label={label}
        value={value}
        disabled={disabled || disabledGroup}
        checked={value === selectedValue}
        onChange={() => onSelectValue(value)}
        className={classNames(styles.RadioCard__radio, {
          [styles['RadioCard__radio--active']]: value === selectedValue
        })}
      />
      {value === selectedValue && children && (
        <div className={styles.RadioCard__content}>
          {children}
        </div>
      )}
    </div>
  );
};

RadioCard.Group = Group;

RadioCard.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  children: PropTypes.node
};
