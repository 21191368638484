import React from 'react';
import PropTypes from 'prop-types';
import Pagination from './react-instantsearch-dom/widgets/Pagination';

// import classNames from 'classnames';
// import { Pagination as AlgoliaInstantSearchPagination } from 'react-instantsearch-dom';
// import has from 'lodash/has';
import BackToTop from './InstantSearchBackToTop';

import './InstantSearchPagination.sass';

export default function InstantSearchPagination({
  floatingHeaderHeight
}) {
  return (
    <div className="InstantSearch-PaginationContainer">
      <Pagination
        padding={2}
        showLast />
      <BackToTop
        scrollToTopAnchorClassName="InstantSearch"
        scrollToTopAnchorMarginTop={floatingHeaderHeight}
      />
    </div>
  );
}

InstantSearchPagination.propTypes = {
  floatingHeaderHeight: PropTypes.number
};

// function Pagination() {
//   return (
//     <AlgoliaInstantSearchPagination
//       showLast
//       listComponent={List}
//       className="InstantSearch-Pagination" />
//   );
// }

// function List({
//   createURL,
//   items,
//   onSelect,
//   canRefine
// }) {
//   return (
//     <div className={classNames('list', !canRefine && 'list--noRefinement')}>
//       {items.map((item) => (
//         <span
//           key={item.key}
//           className={classNames(
//             'item',
//             item.selected && !item.disabled && 'item--selected',
//             item.disabled && 'item--disabled',
//             item.modifier
//           )}>
//           {item.disabled ? (
//             <span className="link">
//               {has(item, 'label') ? item.label : item.value}
//             </span>
//           ) : (
//             <Link
//               className={classNames('link', item.selected && 'link--selected')}
//               aria-label={item.ariaLabel}
//               href={createURL(item.value)}
//               onClick={() => onSelect(item.value)}>
//               {has(item, 'label') ? item.label : item.value}
//             </Link>
//           )}
//         </span>
//       ))}
//     </div>
//   );
// }

// // Copy-pasted from `react-instantsearch@5.5.0`
// // https://github.com/algolia/react-instantsearch/blob/81a6f1856ae28b4ab00ba660c548037818749220/packages/react-instantsearch-dom/src/components/Pagination.js

// import { has, range } from 'lodash-es';
// import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';
// import { translatable } from 'react-instantsearch-core';
// import { createClassNames, capitalize } from './react-instantsearch-dom/core/utils';

// const cx = createClassNames('Pagination');

// // Determines the size of the widget (the number of pages displayed - that the user can directly click on)
// function calculateSize(padding, maxPages) {
//   return Math.min(2 * padding + 1, maxPages);
// }

// function calculatePaddingLeft(currentPage, padding, maxPages, size) {
//   if (currentPage <= padding) {
//     return currentPage;
//   }

//   if (currentPage >= maxPages - padding) {
//     return size - (maxPages - currentPage);
//   }

//   return padding + 1;
// }

// // Retrieve the correct page range to populate the widget
// function getPages(currentPage, maxPages, padding) {
//   const size = calculateSize(padding, maxPages);
//   // If the widget size is equal to the max number of pages, return the entire page range
//   if (size === maxPages) return range(1, maxPages + 1);

//   const paddingLeft = calculatePaddingLeft(
//     currentPage,
//     padding,
//     maxPages,
//     size
//   );
//   const paddingRight = size - paddingLeft;

//   const first = currentPage - paddingLeft;
//   const last = currentPage + paddingRight;
//   return range(first + 1, last + 1);
// }

// class Pagination extends Component {
//   static propTypes = {
//     nbPages: PropTypes.number.isRequired,
//     currentRefinement: PropTypes.number.isRequired,
//     refine: PropTypes.func.isRequired,
//     createURL: PropTypes.func.isRequired,
//     canRefine: PropTypes.bool.isRequired,

//     translate: PropTypes.func.isRequired,
//     listComponent: PropTypes.func,

//     showFirst: PropTypes.bool,
//     showPrevious: PropTypes.bool,
//     showNext: PropTypes.bool,
//     showLast: PropTypes.bool,
//     padding: PropTypes.number,
//     totalPages: PropTypes.number,
//     className: PropTypes.string,
//   };

//   static defaultProps = {
//     listComponent: LinkList,
//     showFirst: true,
//     showPrevious: true,
//     showNext: true,
//     showLast: false,
//     padding: 3,
//     totalPages: Infinity,
//     className: '',
//   };

//   getItem(modifier, translationKey, value) {
//     const { nbPages, totalPages, translate } = this.props;
//     return {
//       key: `${modifier}.${value}`,
//       modifier,
//       disabled: value < 1 || value >= Math.min(totalPages, nbPages),
//       label: translate(translationKey, value),
//       value,
//       ariaLabel: translate(`aria${capitalize(translationKey)}`, value),
//     };
//   }

//   render() {
//     const {
//       listComponent: ListComponent,
//       nbPages,
//       totalPages,
//       currentRefinement,
//       padding,
//       showFirst,
//       showPrevious,
//       showNext,
//       showLast,
//       refine,
//       createURL,
//       canRefine,
//       translate,
//       className,
//       ...otherProps
//     } = this.props;

//     const maxPages = Math.min(nbPages, totalPages);
//     const lastPage = maxPages;

//     let items = [];
//     if (showFirst) {
//       items.push({
//         key: 'first',
//         modifier: 'item--firstPage',
//         disabled: currentRefinement === 1,
//         label: translate('first'),
//         value: 1,
//         ariaLabel: translate('ariaFirst'),
//       });
//     }
//     if (showPrevious) {
//       items.push({
//         key: 'previous',
//         modifier: 'item--previousPage',
//         disabled: currentRefinement === 1,
//         label: translate('previous'),
//         value: currentRefinement - 1,
//         ariaLabel: translate('ariaPrevious'),
//       });
//     }

//     items = items.concat(
//       getPages(currentRefinement, maxPages, padding).map(value => ({
//         key: value,
//         modifier: 'item--page',
//         label: translate('page', value),
//         value,
//         selected: value === currentRefinement,
//         ariaLabel: translate('ariaPage', value),
//       }))
//     );
//     if (showNext) {
//       items.push({
//         key: 'next',
//         modifier: 'item--nextPage',
//         disabled: currentRefinement === lastPage || lastPage <= 1,
//         label: translate('next'),
//         value: currentRefinement + 1,
//         ariaLabel: translate('ariaNext'),
//       });
//     }
//     if (showLast) {
//       items.push({
//         key: 'last',
//         modifier: 'item--lastPage',
//         disabled: currentRefinement === lastPage || lastPage <= 1,
//         label: translate('last'),
//         value: lastPage,
//         ariaLabel: translate('ariaLast'),
//       });
//     }

//     return (
//       <div
//         className={classNames(cx('', !canRefine && '-noRefinement'), className)}
//       >
//         <ListComponent
//           {...otherProps}
//           cx={cx}
//           items={items}
//           onSelect={refine}
//           createURL={createURL}
//           canRefine={canRefine}
//         />
//       </div>
//     );
//   }
// }

// export default translatable({
//   previous: '‹',
//   next: '›',
//   first: '«',
//   last: '»',
//   page: currentRefinement => currentRefinement.toString(),
//   ariaPrevious: 'Previous page',
//   ariaNext: 'Next page',
//   ariaFirst: 'First page',
//   ariaLast: 'Last page',
//   ariaPage: currentRefinement => `Page ${currentRefinement.toString()}`,
// })(Pagination);

// class LinkList extends Component {
//   static propTypes = {
//     cx: PropTypes.func.isRequired,
//     createURL: PropTypes.func.isRequired,

//     items: PropTypes.arrayOf(
//       PropTypes.shape({
//         value: PropTypes.oneOfType([
//           PropTypes.string,
//           PropTypes.number,
//           PropTypes.object,
//         ]).isRequired,

//         key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

//         label: PropTypes.node,

//         modifier: PropTypes.string,
//         ariaLabel: PropTypes.string,
//         disabled: PropTypes.bool,
//       })
//     ),
//     onSelect: PropTypes.func.isRequired,
//     canRefine: PropTypes.bool.isRequired,
//   };

//   render() {
//     const { cx, createURL, items, onSelect, canRefine } = this.props;
//     return (
//       <ul className={cx('list', !canRefine && 'list--noRefinement')}>
//         {items.map(item => (
//           <li
//             key={has(item, 'key') ? item.key : item.value}
//             className={cx(
//               'item',
//               item.selected && !item.disabled && 'item--selected',
//               item.disabled && 'item--disabled',
//               item.modifier
//             )}
//           >
//             {item.disabled ? (
//               <span className={cx('link')}>
//                 {has(item, 'label') ? item.label : item.value}
//               </span>
//             ) : (
//               <Link
//                 className={cx('link', item.selected && 'link--selected')}
//                 aria-label={item.ariaLabel}
//                 href={createURL(item.value)}
//                 onClick={() => onSelect(item.value)}
//               >
//                 {has(item, 'label') ? item.label : item.value}
//               </Link>
//             )}
//           </li>
//         ))}
//       </ul>
//     );
//   }
// }
