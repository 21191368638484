import React from 'react';
import PropTypes from 'prop-types';

import { ContentSection } from '../ContentSection';

export interface FormSectionProps {
  title: string;
  children: React.ReactNode;
  bordered?: boolean;
  className?: string;
}

export const FormSection: React.FC<FormSectionProps> = ({
  className,
  title,
  bordered,
  children
}) => {
  if (process.env.NODE_ENV !== 'production') {
    console.error('<FormSection /> is deprecated. Use <ContentSection /> instead.');
  }

  return (
    <ContentSection
      className={className}
      title={title}
      border={bordered}
    >
      {children}
    </ContentSection>
  );

  // return (
  //   <>
  //     <Layout
  //       gap="0 1rem"
  //       cols={24}
  //       as="section"
  //       className={classNames(styles.FormSection, className, {
  //         [styles['FormSection--bordered']]: bordered
  //       })}
  //     >
  //       <Layout.Cell span={7}>
  //         <Title level={3} style={{ marginBottom: 0 }}>
  //           {title}
  //         </Title>
  //       </Layout.Cell>
  //       <Layout.Cell span={17}>
  //         {children}
  //       </Layout.Cell>
  //     </Layout>
  //   </>
  // );
};

FormSection.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  bordered: PropTypes.bool,
  children: PropTypes.node.isRequired
};
