// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck TODO: Fix All types

import React, { useCallback, useRef, useImperativeHandle } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { CaretDownIcon, CaretUpIcon } from '@acadeum/icons';
import { LoadingEllipsis } from '@acadeum/core-ui';

import { useComboboxToggle, useComboboxTextField } from '../hooks';

import styles from './Toggle.module.scss';

const Toggle = React.forwardRef(({
  id,
  appearance,
  disabled,
  readOnly,
  error,
  valueLabel,
  placeholder,
  children,
  isLoading,
  isFilter
}, ref) => {
  const {
    activeOptionId
  } = useComboboxTextField();

  const {
    toggleRef,
    setReferenceElement,
    listboxId,
    expanded,
    onToggleClick,
    onToggleBlur,
    onToggleKeyDown
  } = useComboboxToggle();

  const internalRef = useRef();

  const setRef = useCallback((instance) => {
    internalRef.current = instance;
    if (ref) {
      if (typeof ref === 'function') {
        ref(instance);
      } else {
        ref.current = instance;
      }
    }
  }, [ref]);

  useImperativeHandle(ref, () => ({
    focus: () => internalRef.current.focus()
  }));

  useImperativeHandle(toggleRef, () => ({
    focus: () => internalRef.current.focus()
  }));

  const onClick = useCallback(() => {
    onToggleClick?.();
  }, [onToggleClick]);

  const onBlur = useCallback(() => {
    onToggleBlur?.();
  }, [onToggleBlur]);

  const onKeyDown = useCallback((event) => {
    onToggleKeyDown?.(event);
  }, [onToggleKeyDown]);

  // prevents lost of focus on Textfield
  const onMouseDown = (event) => {
    event.preventDefault();
  };

  if (typeof children === 'function') {
    children = children({ expanded });
  }

  const content = valueLabel || children;
  const CaretIcon = expanded ? CaretUpIcon : CaretDownIcon;

  return (
    <>
      <button
        id={id}
        ref={setRef}
        type="button"
        role="combobox"
        aria-haspopup="listbox"
        aria-autocomplete="list"
        aria-activedescendant={activeOptionId}
        aria-expanded={expanded}
        aria-controls={listboxId}
        aria-labelledby={isFilter ? `${id}Filter` : `${id}LabelContainer ${id}Label`}
        disabled={disabled || readOnly || (isLoading && !expanded)}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        className={styles.ToggleButton}
      />
      <div
        id={`${id}Filter`}
        ref={setReferenceElement}
        onMouseDown={onMouseDown}
        disabled={disabled || readOnly}
        onClick={() => {
          internalRef.current.click();
          internalRef.current.focus();
        }}
        className={classNames(styles.ToggleElement, {
          [styles['ToggleElement--hasError']]: Boolean(error),
          [styles['ToggleElement--hasValue']]: Boolean(content),
          [styles['ToggleElement--white']]: appearance === 'white'
        })}
      >
        <span className={styles.ToggleElement__content}>
          {content || placeholder}
        </span>
        {!content && isLoading && (
          <LoadingEllipsis/>
        )}
        <CaretIcon/>
      </div>
    </>
  );
});

Toggle.propTypes = {
  appearance: PropTypes.oneOf(['white']),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  valueLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  placeholder: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node
  ]),
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  isLoading: PropTypes.bool,
  isFilter: PropTypes.bool
};

export default Toggle;
