import { PERMISSION_OPERATIONS } from './consts';
import { preparePermissionsToObjects } from './preparePermissionsToObjects';

import type { FormPermissionValues, FormPermissionsOperations } from './types';
import type { UserRole } from '@acadeum/types';

export function prepareApiPermissionsToFormPermissionsStandard(permissions: UserRole['permissions']): FormPermissionValues {
  const objectPermissions = preparePermissionsToObjects(permissions);
  return Object.values(objectPermissions).reduce<FormPermissionValues>(
    (result, objectPermission) => {

      result[objectPermission.subject] = PERMISSION_OPERATIONS.reduce<FormPermissionsOperations>((
        result,
        permissionOperation
      ) => {
        result[permissionOperation] = objectPermission[permissionOperation];
        return result;
      }, {} as FormPermissionsOperations);

      return result;
    }, {});
}
