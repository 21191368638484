import { array, number, object, string } from './shared';

export const coursePricingSchema = object({
  model: string().equal(
    'per-credit',
    'percent-fee',
    'fixed',
    'fixed-fee',
    'as-is'
  ).required(),
  value: number(),
  // oneOfType
  level: [
    string(),
    array().items(string())
  ],
  // oneOfType
  teachingInstitutionId: [
    number(),
    array().items(number()),
    object({
      '>': number(),
      '<': number(),
      '>=': number(),
      '<=': number()
    })
  ]
});
