import { useState } from 'react';

interface Step<StepId> {
  id: StepId;

  // eslint-disable-next-line
  [key: string]: any;
}

export interface UseStepOptions<StepId> {
  initialStep?: StepId;
  steps: Step<StepId>[];
}

export interface UseStepResult<StepId> {
  index: number;
  step: Step<StepId>;
  navigation: {
    next: () => void;
    previous: () => void;
    go: (newStep: StepId) => void;
  };
}

function getIndexById<StepId>(arr: Step<StepId>[], matchId: string): number {
  return arr.findIndex(({ id }) => id === matchId);
}

export function useStep<StepId extends string>({
  initialStep,
  steps
}: UseStepOptions<StepId>): UseStepResult<StepId> {
  const initialStepIndex = initialStep ? getIndexById(steps, initialStep) : 0;

  const [index, setStepIndex] = useState(initialStepIndex);
  const deltaSetStep = (delta = 1) => setStepIndex((index + steps.length + delta) % steps.length);

  const step = steps[index];

  return {
    index,
    step,
    navigation: {
      next: () => deltaSetStep(1),
      previous: () => deltaSetStep(-1),
      go: (newStep) => {
        const newStepId = getIndexById(steps, newStep);
        if (process.env.NODE_ENV !== 'production') {
          if (newStepId === -1) {
            throw new Error(`useStep: go("${newStep}") not found in steps`);
          }
        }
        setStepIndex(newStepId);
      }
    }
  };
}
