import React from 'react';

import Tab from './Tab';

import useLocation from '../../hooks/useLocation';

export default function SectionTab({ section }) {
  const location = useLocation();

  return (
    <Tab
      key={section.key}
      link={section.link}
      alias={section.alias}
      location={location}
      icon={section.icon}
      showWarning={section.showWarning}
      count={section.count && section.count()}
      notificationsCount={section.notificationsCount && section.notificationsCount()}>
      {section.title}
    </Tab>
  );
}
