import { object, phone, phoneExt, string } from './shared';

export const contactSchema = object({
  firstName: string().required(),
  lastName: string().required(),
  email: string().email({
    tlds: false
  }).required(),
  title: string(),
  phone: phone(),
  phoneExt: phoneExt()
});
