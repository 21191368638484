import React, { useState } from 'react';
import { Select } from 'react-responsive-ui';
import { useSelector } from 'react-redux';
import { Line } from 'react-chartjs-2';
import { sortBy, uniq } from 'lodash-es';

import '../../helpers/initializeCharts';
import actions from '../../actions';
import { getGraphOptions } from './helpers';

const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'Septmber', 'October', 'November', 'December'];

const { fetchMonthlyEnrollments } = actions;

export default function MonthlyEnrollments() {
  const [institutionId, setInstitutionId] = useState();

  const onSelectInstitution = (institutionId) => {
    setInstitutionId(institutionId);
    fetchMonthlyEnrollments(institutionId);
  };

  const monthlyEnrollmentsReport = useSelector(state => state.reports.monthlyEnrollments);

  if (!monthlyEnrollmentsReport) {
    return null;
  }

  const monthlyEnrollments = Object.values(monthlyEnrollmentsReport[0]);
  const institutions = Object.values(monthlyEnrollmentsReport[1]);

  const now = new Date();
  const currentYear = (now).getFullYear();
  const currentMonth = (now).getMonth() + 1;

  const years = uniq(monthlyEnrollments.map(_ => _.year)).sort().reverse();

  const datasets = years.map(year => {
    const data = sortBy(monthlyEnrollments.filter(_ => _.year === year), 'month');
    const returnData = [];
    for (let i = 1; i <= 12; i++) {
      const d = data.filter(_ => _.month === i)[0];
      if (d) {
        returnData.push(d.count);
      } else if (year === currentYear && i > currentMonth) {
        returnData.push(null);
      }
      else {
        returnData.push(0);
      }
    }
    return getGraphOptions(year, returnData);
  });

  const data = {
    labels: MONTHS,
    datasets
  };

  const datasetsSum = years.map(year => {
    const data = sortBy(monthlyEnrollments.filter(_ => _.year === year), 'month');
    const returnData = [];
    let sum = 0;
    for (let i = 1; i <= 12; i++) {
      const d = data.filter(_ => _.month === i)[0];
      if (d) {
        sum += d.count;
      }
      if (year === currentYear && i > currentMonth) {
        returnData.push(null);
      } else {
        returnData.push(sum);
      }
    }
    return getGraphOptions(year, returnData);
  });

  const dataSum = {
    labels: MONTHS,
    datasets: datasetsSum
  };

  const datasetsDerivative = years.map(year => {
    const data = sortBy(monthlyEnrollments.filter(_ => _.year === year), 'month');
    const returnData = [];
    let pastChange = 0;
    for (let i = 1; i <= 12; i++) {
      const d = data.filter(_ => _.month === i)[0];
      if (d) {
        returnData.push(d.count-pastChange);
        pastChange = d.count;
      } else if (year === currentYear && i > currentMonth) {
        returnData.push(null);
        pastChange = 0;
      }
      else {
        returnData.push(0);
        pastChange = 0;
      }
    }
    return getGraphOptions(year, returnData);
  });

  const dataDerivative = {
    labels: MONTHS,
    datasets: datasetsDerivative
  };

  return (
    <div>
      <h1>Enrolling Institution</h1>
      <Select
        options={[{ label: 'All' }].concat(institutions.map((institution) => ({
          value: institution.id,
          label: institution.name
        })))}
        onChange={onSelectInstitution}
        value={institutionId}
      />
      <br/>
      <h1>Monthly Enrollment Activity: Cumulative</h1>
      <Line data={dataSum} />
      <h1>Monthly Enrollment Activity: 1st Derivative</h1>
      <Line data={data} />
      <h1>Monthly Enrollment Activity: 2nd Derivative</h1>
      <Line data={dataDerivative} />
    </div>
  );
}

MonthlyEnrollments.meta = () => ({
  title: 'Monthly Enrollments'
});

MonthlyEnrollments.load = async () => {
  await fetchMonthlyEnrollments();
};

MonthlyEnrollments.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Monthly Enrollments'
];
