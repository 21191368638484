import { useState } from 'react';

export default function useFilters(filters, search, rows, onChange) {
  const [searchQuery, setSearchQuery] = useState();
  const [filterValues, _setFilterValues] = useState({});

  const filter = (row, filterValues) => {
    // If any of the filters returns `false` for this `row`
    // then hide the `row`.
    if (filters) {
      for (const filter of filters) {
        const value = filterValues[filter.id];
        if (value !== undefined && !filter.filter(row, value)) {
          return false;
        }
      }
    }
    // If search query has been entered and this row
    // doesn't satisfy the query then hide the row.
    if (search && searchQuery && searchQuery.trim() && !search(row, searchQuery.trim())) {
      return false;
    }
    // Show this row.
    return true;
  };

  const onFiltersChange = (newFilterValues) => {
    // Activating a filter may filter out some of the checked rows
    // therefore uncheck those rows which got hidden
    // as a result of applying the filter.
    if (onChange) {
      onChange(rows.filter(row => filter(row, newFilterValues)));
    }
  };

  // const getFilterValue = (id) => filterValues[id];
  //
  // const setFilterValue = (id, value) => {
  //   setFilterValues({
  //     [id]: value
  //   });
  // };

  const setFilterValues = (values) => {
    const newFilterValues = {
      ...filterValues,
      ...values
    };
    _setFilterValues(newFilterValues);
    onFiltersChange(newFilterValues);
  };

  const unfilteredRows = rows;
  const filteredRows = rows.filter(_ => filter(_, filterValues));

  return [
    unfilteredRows,
    filteredRows,
    filterValues,
    setFilterValues,
    searchQuery,
    (value) => {
      setSearchQuery(value);
      onFiltersChange(filterValues);
    }
  ];
}
