import { ReduxModule } from 'react-pages';

// `ReduxModule` event namespace "PASSWORD_RESET" is used when
// listening to Redux events via `redux.on(namespace, event)`.
const redux = new ReduxModule('PASSWORD_RESET');

export const resetPassword = redux.action(
  (payload) => http => http.post('/password-reset', payload)
);

export const requestPasswordReset = redux.action(
  (payload) => http => http.post('/request-password-reset', payload)
);

export const checkPasswordResetToken = redux.action(
  // The Redux action name is required here
  // so that the corresponding Redux events would be called:
  // * `CHECK_PASSWORD_RESET_TOKEN_PENDING`
  // * `CHECK_PASSWORD_RESET_TOKEN_ERROR`
  // * `CHECK_PASSWORD_RESET_TOKEN_SUCCESS`
  'CHECK_PASSWORD_RESET_TOKEN',
  (payload) => http => http.get('/check-password-reset-token', payload)
);

export const showRequestPasswordResetModal = redux.simpleAction(
  (state) => ({
    ...state,
    requestPasswordResetModalIsShown: true
  })
);

export const hideRequestPasswordResetModal = redux.simpleAction(
  (state) => ({
    ...state,
    requestPasswordResetModalIsShown: false
  })
);

redux.on('PASSWORD_RESET: CHECK_PASSWORD_RESET_TOKEN_PENDING', (state) => ({
  ...state,
  isPasswordResetTokenBeingValidated: true
}));

redux.on('PASSWORD_RESET: CHECK_PASSWORD_RESET_TOKEN_ERROR', (state) => ({
  ...state,
  isPasswordResetTokenBeingValidated: false
}));

redux.on('PASSWORD_RESET: CHECK_PASSWORD_RESET_TOKEN_SUCCESS', (state) => ({
  ...state,
  isPasswordResetTokenBeingValidated: false
}));

export default redux.reducer();
