import formatName from './formatName.js';

export default function formatUserName(user) {
  // If it's a person.
  if (user.firstName) {
    return formatName(user);
  }
  // If it's a "Group User".
  return user.title;
}
