import React, { memo } from 'react';
import {
  getLastDropDateMessageForOnDemandCourseEnrollment,
  getEndDateMessageForOnDemandCourseEnrollment
} from '@acadeum/helpers';

import { formatCurrency } from '../../../../utils/format';

import { Col } from '../../../Col';
import { Row } from '../../../Row';

import { SectionContent } from '../SectionContent';
import { SectionCardSeparator } from '../SectionCardSeparator';

import styles from '../../SectionCard.module.scss';

interface OnDemandRowProps {
  cost?: number;
  costActionsElement?: React.ReactNode;
  onDemandCourseDropDateDaysAfterStartDate?: number;
  onDemandCourseEndDateDaysAfterStartDate?: number;
}

export const OnDemandRow = memo<OnDemandRowProps>(({
  cost,
  costActionsElement,
  onDemandCourseDropDateDaysAfterStartDate,
  onDemandCourseEndDateDaysAfterStartDate
}) => {
  return (
    <>
      <Row as="dl" className={styles.row}>
        <Col lg={3} col={6}>
          {(typeof cost === 'number') && (
            <SectionContent
              label={(
                <>
                  Cost
                  {costActionsElement}
                </>
              )}
              value={formatCurrency(cost)}
            />
          )}
        </Col>
        <Col lg={3} col={6}>
          <SectionContent
            label="Schedule"
            value="On-Demand"
          />
        </Col>
        <Col lg={6} col={12}>
          <SectionContent
            label="Drop Date"
            value={getLastDropDateMessageForOnDemandCourseEnrollment(onDemandCourseDropDateDaysAfterStartDate)}
          />
        </Col>
      </Row>
      <SectionCardSeparator/>
      <Row>
        <Col col={12}>
          <SectionContent
            label="End Date"
            value={getEndDateMessageForOnDemandCourseEnrollment(onDemandCourseEndDateDaysAfterStartDate)}
          />
        </Col>
      </Row>
    </>
  );
});
