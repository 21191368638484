import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const fetchUsersAdmin = redux.action(
  (parameters) => http => http.get('/admin/users', parameters),
  'users'
);

export const fetchUserAdmin = redux.action(
  (id) => http => http.get(`/admin/users/${id}`),
  'user'
);

export const userResetPasswordAdmin = redux.action(
  (id, password) => http => http.post(`/admin/users/${id}/reset-password`, { password })
);

export const userRequestPasswordResetAdmin = redux.action(
  (id) => http => http.post(`/admin/users/${id}/request-password-reset`)
);

export const userVerifyEmailAdmin = redux.action(
  (id) => http => http.post(`/admin/users/${id}/verify-email`)
);

export const userRequestVerifyEmailAdmin = redux.action(
  (id) => http => http.post(`/admin/users/${id}/request-verify-email`)
);

export const updateCurrentUserInstitution = redux.action(
  (data) => http => http.put('/admin/users/institution', data)
);

export const updateUserAdmin = redux.action(
  (id, data) => http => http.put(`/users/${id}`, data)
);

export default redux.reducer();
