import type { FC, ReactNode } from 'react';
import React from 'react';

import type { BreadcrumbsProps } from '../Breadcrumbs';
import { Breadcrumbs } from '../Breadcrumbs';
import { PageHeader } from '../PageHeader';
import { Text } from '../Text';

export interface PageProps {
  title: string;
  description?: string;
  children: ReactNode;
  actions?: ReactNode;
  breadcrumbs?: BreadcrumbsProps['children'];
}

export const Page: FC<PageProps> = ({
  title,
  description,
  breadcrumbs,
  children,
  actions
}) => {
  return (
    <>
      {breadcrumbs && (
        <Breadcrumbs children={breadcrumbs}/>
      )}
      <PageHeader
        title={title}
        actions={actions}
      />
      {description && (
        <Text color="grey" style={{ maxWidth: '37.5rem', marginBottom: '2rem' }}>
          {description}
        </Text>
      )}
      {children}
    </>
  );
};
