import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getAuthSelector } from '@acadeum/auth';
import { useTranslate } from '@acadeum/translate';
import { userHasPermission } from '@acadeum/helpers';
import type { UseFetchContactsQuery } from '@acadeum/api';
import { Loader, Page } from '@acadeum/ui';
import {
  AlertIcon,
  ArrowLeftToBracketIcon,
  BookOpenIcon,
  CreditCardIcon,
  DashboardKeyIcon,
  DocumentViewIcon,
  FileClockIcon,
  FileInvoiceIcon,
  FileLinesIcon,
  GroupIcon,
  IntegrationHubIcon,
  ListNumberIcon,
  MedalStarIcon,
  MeshIcon,
  MoneyIcon,
  PersonStarIcon,
  PhoneIcon,
  StudentIcon
} from '@acadeum/icons';

import type { MenuSectionsProps } from '../../components/MenuSections';
import { MenuSections } from '../../components/MenuSections';
import { useSettingsRoutes } from '../../hooks/useSettingsRoutes';
import { useApp } from '../../providers/useApp';

export interface GeneralSettingsPageProps {
  useFetchContactsQuery: UseFetchContactsQuery;
}

export const GeneralSettingsPage: FC<GeneralSettingsPageProps> = ({
  useFetchContactsQuery
}) => {
  const t = useTranslate('shared-admin-ui.GeneralSettingsPage');
  const { app } = useApp();
  const identityAndAccessManagementSection = useIdentityAndAccessManagementSection({
    useFetchContactsQuery
  });
  const walletSection = useWalletSection();
  const authenticationSection = useAuthenticationSection();
  const dataManagementSection = useDataManagementSection();
  const legalSection = useLegalSection();

  const sections = useMemo(() => {
    const sections: MenuSectionsProps['sections'] = [];

    if (identityAndAccessManagementSection) {
      sections.push(identityAndAccessManagementSection);
    }

    if (walletSection) {
      sections.push(walletSection);
    }

    if (authenticationSection) {
      sections.push(authenticationSection);
    }

    if (dataManagementSection) {
      sections.push(dataManagementSection);
    }

    sections.push(legalSection);

    return sections;
  }, [
    identityAndAccessManagementSection,
    walletSection,
    authenticationSection,
    dataManagementSection,
    legalSection
  ]);

  return (
    <Page title={app === 'admin' ? t('general') : t('settings')}>
      <MenuSections sections={sections}/>
    </Page>
  );
};

function useIdentityAndAccessManagementSection({
  useFetchContactsQuery
}) {
  const t = useTranslate('shared-admin-ui.GeneralSettingsPage');
  const { getUserManagementUrl, getUserRoleManagementUrl, getContactsUrl } = useSettingsRoutes();

  const user = useSelector(getAuthSelector('user'));

  const canReadContacts = userHasPermission(
    user,
    'homeContact:read',
    { orgId: user?.institution.id, ownerId: null }
  );

  const {
    data: contacts,
    isLoading: isLoadingContacts
  } = useFetchContactsQuery(undefined, {
    skip: !canReadContacts
  });

  const ContactsIcon = useMemo(() => {
    if (!canReadContacts) {
      return undefined;
    }

    if (isLoadingContacts) {
      return Loader;
    }

    if (contacts && contacts.length > 0) {
      const isPrimaryContactExists = contacts.find(contact => contact.type === 'PRIMARY_CONTACT');
      if (isPrimaryContactExists) {
        return PhoneIcon;
      }
    }

    return AlertIcon;
  }, [contacts, isLoadingContacts, canReadContacts]);

  return useMemo(() => {
    const section: MenuSectionsProps['sections'][number] = {
      icon: <GroupIcon/>,
      title: t('identityAndAccessManagement'),
      menu: []
    };

    if (userHasPermission(user, 'user:read', {
      ownerId: null,
      orgId: user?.institution.id
    }) && userHasPermission(user, 'userRole:read', {
      orgId: user?.institution.id,
      ownerId: null
    })
    ) {
      section.menu.push({
        icon: <PersonStarIcon/>,
        title: t('userManagement'),
        url: getUserManagementUrl()
      });
    }

    if (userHasPermission(user, 'userRole:read', {
      ownerId: null,
      orgId: user?.institution.id
    })) {
      section.menu.push({
        icon: <MedalStarIcon/>,
        title: t('roleManagement'),
        url: getUserRoleManagementUrl()
      });
    }

    if (ContactsIcon) {
      section.menu.push({
        icon: <ContactsIcon/>,
        title: t('contacts'),
        url: getContactsUrl()
      });
    }

    return section.menu.length > 0 ? section : null;
  }, [user, ContactsIcon]);
}

function useWalletSection() {
  const t = useTranslate('shared-admin-ui.GeneralSettingsPage');
  const user = useSelector(getAuthSelector('user'));
  const { getFinancialSettingsUrl, getInvoiceHistoryUrl, getPaymentHistoryUrl } = useSettingsRoutes();

  return useMemo(() => {
    if (userHasPermission(user, 'paymentMethod:read', {
      ownerId: null,
      orgId: user?.institution.id
    })) {
      return {
        icon: <MoneyIcon/>,
        title: t('acadeumWallet'),
        menu: [
          { icon: <CreditCardIcon/>, title: t('paymentSettings'), url: getFinancialSettingsUrl() },
          { icon: <FileInvoiceIcon/>, title: t('invoices'), url: getInvoiceHistoryUrl() },
          { icon: <FileClockIcon/>, title: t('paymentHistory'), url: getPaymentHistoryUrl() }
        ]
      };
    }

    return null;
  }, [user]);
}

function useAuthenticationSection() {
  const t = useTranslate('shared-admin-ui.GeneralSettingsPage');
  const user = useSelector(getAuthSelector('user'));
  const { getAuthenticationSettingsUrl, getUserRequestsUrl } = useSettingsRoutes();

  return useMemo(() => {
    const section: MenuSectionsProps['sections'][number] = {
      icon: <DashboardKeyIcon/>,
      title: t('authentication'),
      menu: []
    };

    if (userHasPermission(user, 'authenticationSettings:read', {
      ownerId: null,
      orgId: user?.institution.id
    })) {
      section.menu.push({
        icon: <ArrowLeftToBracketIcon/>,
        title: t('authenticationType'),
        url: getAuthenticationSettingsUrl()
      });
    }

    if (userHasPermission(user, 'user:create', {
      ownerId: null,
      orgId: user?.institution.id
    })) {
      section.menu.push({
        icon: <ListNumberIcon/>,
        title: t('userAccountRequests'),
        url: getUserRequestsUrl()
      });
    }

    return section.menu.length > 0 ? section : null;
  }, [user]);
}

function useLegalSection() {
  const t = useTranslate('shared-admin-ui.GeneralSettingsPage');

  return useMemo(() => {
    return {
      icon: <FileLinesIcon/>,
      title: t('legal'),
      menu: [
        {
          icon: <BookOpenIcon/>,
          title: t('termsOfUse'),
          external: true,
          url: 'https://acadeum.com/terms-of-use/'
        },
        {
          icon: <DocumentViewIcon/>,
          title: t('privacyPolicy'),
          external: true,
          url: 'https://acadeum.com/privacy-policy/'
        }
      ]
    };
  }, []);
}

function useDataManagementSection() {
  const t = useTranslate('shared-admin-ui.GeneralSettingsPage');
  const user = useSelector(getAuthSelector('user'));
  const { getCourseMappingsUrl, getStudentPortalUrl } = useSettingsRoutes();

  return useMemo(() => {
    const section: MenuSectionsProps['sections'][number] = {
      icon: <IntegrationHubIcon/>,
      title: t('dataManagementAndIntegrations'),
      menu: []
    };

    if (userHasPermission(user, 'courseMapping:read', {
      orgId: user?.institution.id,
      ownerId: null
    })) {
      section.menu.push({ icon: <MeshIcon/>, title: t('courseMappings'), url: getCourseMappingsUrl() });
    }
    section.menu.push({ icon: <StudentIcon/>, title: t('studentPortal'), url: getStudentPortalUrl() });

    return section.menu.length > 0 ? section : null;
  }, []);
}
