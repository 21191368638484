import React, { memo, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Actions, Table, Text, EmptyState } from '@acadeum/ui';
import { BookOpenHalfFilledIcon } from '@acadeum/icons';
import { useTranslate } from '@acadeum/translate';

import { getStudentEnrollments, isGradeEnrollment } from '../../../../helpers/enrollments';
import type { ReduxState } from '../../../../helpers/app.types';

import { useExportDataColumns } from '../../../../pages/Enrollments';

import actions from '../../../../actions';

import { useGradesColumns, useGradesFilters } from '../../hooks';
import { EnrollingGradeModal } from '../EnrollingGradeModal';

const { fetchEnrollmentRequestsEMForExport } = actions;

export const EnrollingGrades = memo(() => {
  const t = useTranslate('EnrollingGrades');

  const exportDataColumns = useExportDataColumns({ finalized: true, grades: true });
  const filters = useGradesFilters('enrolling');
  const columns = useGradesColumns({
    institutionType: 'enrolling',
    actionsColumn: useMemo(() => ({
      id: 'actions',
      size: 60,
      cell: ({ row, downloadRow }) => <ActionsCell row={row} downloadRow={downloadRow} />
    }), [])
  });

  const enrollmentRequests = useSelector((state: ReduxState) => state.enrollmentRequests.enrollmentRequests);
  const data = getStudentEnrollments(enrollmentRequests).filter(isGradeEnrollment);

  if (data.length === 0) {
    return (
      <EmptyState
        icon={BookOpenHalfFilledIcon}
        children={t('emptyState')}
      />
    );
  }

  return (
    <>
      <Text variant="headingSm" mb="lg">
        {t('description')}
      </Text>

      <Table
        id="enrollingGrades"
        hasColumnVisibility
        enableRowSelection
        enableGlobalFilter
        data={data}
        columns={columns}
        clientFilters={filters}
        columnPinningRight={['actions']}
        exportOptions={{
          type: 'xlsx',
          fileName: 'Grades - HI - Enrolling Grades',
          exportDataColumns,
          fetchDataForExport: async (selectedRowsIds, { rowsIds }) => {
            const ids = selectedRowsIds.length > 0 ? selectedRowsIds : rowsIds;
            return getStudentEnrollments(await fetchEnrollmentRequestsEMForExport(ids.map(Number)));
          }
        }}
      />
    </>
  );
});

function ActionsCell({
  row,
  downloadRow
}) {
  const [show, setShow] = useState(false);
  return (
    <>
      <Actions
        variant="kebab"
        actions={[
          { title: 'See details', onClick: () => setShow(true) },
          { title: 'Download', onClick: downloadRow }
        ]}
      />
      <EnrollingGradeModal
        onHide={setShow}
        show={show}
        gradeId={row.original.id}
        downloadRow={downloadRow}
      />
    </>
  );
}
