import { isObject } from './isObject';

function removePropertyFromArrayOrObject(values, key) {
  if (Array.isArray(values)) {
    values.splice(Number(key), 1);
  } else {
    values[key] = null;
  }
}

export function normalizeFormValues(values: Record<string, unknown> | unknown[]) {
  for (const key of Object.keys(values)) {
    // `react-hook-form` is a really retarded one:
    // among other things, it forcefully converts
    // `undefined` or `null` values into empty strings.
    // https://github.com/react-hook-form/react-hook-form/issues/656
    if (typeof values[key] === 'string') {
      // Remove leading and trailing whitespaces.
      // https://acadeum.atlassian.net/browse/CS-390
      values[key] = values[key].trim();

      if (values[key] === '') {
        // Turns out that just deleting empty values results in a bug
        // when clearing a form field doesn't send `null` to the backend
        // for that field value, and this results in Sequelize not writing
        // `null` to the SQL column because Sequelize doesn't write `undefined`s.
        // https://github.com/Acadeum/Students-site/issues/572
        // delete values[key];
        removePropertyFromArrayOrObject(values, key);
      }
    } else if (isObject(values[key]) || Array.isArray(values[key])) {
      normalizeFormValues(values[key]);
    }
  }
}

// Submits the form on `Ctrl` + `Enter` (or `Cmd` + `Enter`).
export function submitFormOnCtrlEnter(event, component) {
  if ((event.ctrlKey || event.metaKey) && event.keyCode === 13) {
    if (submitContainingForm(component)) {
      event.preventDefault();
      return true;
    }
  }
}

export function submitContainingForm(node) {
  while (node.parentElement) {
    node = node.parentElement;
    if (node instanceof HTMLFormElement) {
      // Won't use `node.submit()` because it bypasses `onSubmit`.
      // Will click the submit button instead.
      const submit: HTMLButtonElement | null = node.querySelector('button[type=submit]');
      if (submit) {
        submit.click();
        return true;
      }
    }
  }
}
