import formatGender from 'common-lib/lib/formatGender.js';

import type { Language } from '@acadeum/types';

export function getGenderOptions({ language }: { language: Language }) {
  return [true, false, null].map((value) => ({
    value,
    label: formatGender(value, { language })
  }));
}
