import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const fetchTeachingGrade = redux.action(
  (id) => async (http) => {
    const studentEnrollment = await http.get(`/grades-TM/${id}`, { includeStudentContact: true });
    if (studentEnrollment.enrollmentRequest) {
      studentEnrollment.section = studentEnrollment.enrollmentRequest.section;
      delete studentEnrollment.enrollmentRequest;
    }
    return studentEnrollment;
  },
  'studentEnrollmentTM'
);

export const fetchEnrollingGrade = redux.action(
  (id) => async (http) => {
    const studentEnrollment = await http.get(`/grades-EM/${id}`, { includeStudentContact: true });
    if (studentEnrollment.enrollmentRequest) {
      studentEnrollment.section = studentEnrollment.enrollmentRequest.section;
      delete studentEnrollment.enrollmentRequest;
    }
    return studentEnrollment;
  },
  'studentEnrollmentEM'
);

//  Grades should be an array sent in the following format:
//
// const grades = {
//   1: {
//     letterGrade: 'A+',
//     numericalGrade: '100',
//     gradeNotes: 'Great Job!'
//   }
// };

export const editGrade = redux.action(
  (id, grade) => async (http) => {
    Intercom('trackEvent', 'edit grades');
    await http.put(`/grades/${id}`, grade);
  }
);

export default redux.reducer();
