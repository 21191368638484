import React from 'react';
import classNames from 'classnames';

import { CaretLeftIcon } from '@acadeum/icons';

import type { ButtonProps } from '../Button';
import { Button } from '../Button';

import styles from './BackButton.module.scss';

export type BackButtonProps = Pick<ButtonProps, 'className' | 'children' | 'url' | 'onClick'>

export const BackButton: React.FC<BackButtonProps> = ({
  children = 'Go Back',
  className,
  ...rest
}) => {
  return (
    <Button
      variant="text"
      icon={CaretLeftIcon}
      className={classNames(className, styles.BackButton)}
      {...rest}
    >
      {children}
    </Button>
  );
};
