import React from 'react';

import { ContactsPage } from '@acadeum/shared-admin-ui';
import { userHasPermission } from '@acadeum/helpers';

import {
  useCreateContactMutation,
  useFetchContactsQuery,
  useRemoveContactMutation,
  useUpdateContactMutation
} from '../../api/contacts';
import { useCreateUserMutation, useFetchUserQuery, useFetchUsersQuery } from '../../api/users';
import { useFetchUserRolesQuery } from '../../api/userRoles';
import type { AppPage } from '../../helpers/app.types';

const Contacts: AppPage = () => {
  return (
    <ContactsPage
      useCreateContactMutation={useCreateContactMutation}
      useCreateUserMutation={useCreateUserMutation}
      useFetchContactsQuery={useFetchContactsQuery}
      useFetchUserQuery={useFetchUserQuery}
      useFetchUserRolesQuery={useFetchUserRolesQuery}
      useFetchUsersQuery={useFetchUsersQuery}
      useRemoveContactMutation={useRemoveContactMutation}
      useUpdateContactMutation={useUpdateContactMutation}
    />
  );
};

Contacts.meta = () => ({
  title: 'Contacts'
});

Contacts.when = ({ user }) => userHasPermission(user, 'homeContact:read', {
  orgId: user?.institution.id,
  ownerId: null
});

export default Contacts;
