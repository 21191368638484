import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Checkbox } from 'react-responsive-ui';

import InstitutionLink from '../InstitutionLink';

import './InstitutionSearchResultRow.sass';

export default class InstitutionSearchResultRow extends React.Component {
  static defaultProps = {
    instantBack: true
  };

  onPick = (value) => {
    const { children: institution, onPick } = this.props;
    onPick(institution, value);
  };

  render() {
    const {
      onPick,
      pickedIds,
      children: institution,
      instantBack,
      className
    } = this.props;

    const isPicked = pickedIds && pickedIds.indexOf(institution.id) >= 0;

    return (
      <div className={classNames('institution-search-result-row', className, {
        'institution-search-result-row--picked': onPick && isPicked
      })}>
        {onPick &&
          <Checkbox
            className="institution-search-result-row__pick"
            onChange={this.onPick}
            label={institution.name}
            value={isPicked}>
            {institution.name}
          </Checkbox>
        }
        <div
          className="institution-search-result-row__name">
          {onPick ? null : institution.name}
        </div>
        <InstitutionLink
          target="_blank"
          institution={institution}
          className="institution-search-result-row__details"
          gaLabel={institution.name}
          instantBack={instantBack}>
          Details
        </InstitutionLink>
      </div>
    );
  }
}

InstitutionSearchResultRow.propTypes = {
  children: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  instantBack: PropTypes.bool,
  onPick: PropTypes.func,
  pickedIds: PropTypes.arrayOf(PropTypes.any),
  className: PropTypes.string
};
