import React from 'react';
import type { FC } from 'react';

import { useTranslate } from '@acadeum/translate';
import { ContentSection, DataBlock, Modal, Text } from '@acadeum/ui';
import type { ModalProps } from '@acadeum/ui';

import styles from './ModalInfo.module.scss';

export interface ModalInfoProps extends Pick<ModalProps, 'onHide' | 'show'> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  enrollments: any[];
  institutionType: 'teaching' | 'home';
  downloadRow: () => Promise<void>;
}

const ModalInfo: FC<ModalInfoProps> = ({
  enrollments,
  onHide,
  institutionType,
  show,
  downloadRow
}) => {
  const t = useTranslate('Enrollments');

  return (
    <Modal
      onHide={onHide}
      show={show}
      status={enrollments[0].status}
      title={t('enrollment')}
      actions={[
        {
          title: 'Download',
          onClick: downloadRow
        }
      ]}
    >
      <Modal.Body>
        {enrollments.map(data => (
          <div key={data.id} className={styles.ModalInfo__body}>
            <ContentSection padding={false} border={false} title={t('table.student')}>
              <DataBlock
                type="student"
                student={data.student}
              />
            </ContentSection>
            <ContentSection padding={false} border={false} title={institutionType === 'teaching' ? t('homeInstitution') : t('teachingInstitution')}>
              <DataBlock
                hideLogo
                type="institution"
                institution={institutionType === 'teaching' ? data.institution : data.section.session.course.institution}
              />
            </ContentSection>
            <ContentSection padding={false} border={false} title={t('enrollmentType')}>
              <Text>
                {data.type}
              </Text>
              {data.letterGrade && (
                <Text color="grey" variant="bodySm">
                  {data.letterGrade}
                </Text>
              )}
            </ContentSection>
            <ContentSection padding={false} border={false} title={t('course')}>
              <DataBlock
                type="course"
                course={data.section.session.course}
              />
            </ContentSection>
            <ContentSection padding={false} border={false} title={t('table.dates')}>
              <DataBlock
                type="courseSessionDates"
                withLastDropDate={institutionType === 'home'}
                session={data.section.session}
              />
            </ContentSection>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
};
export default ModalInfo;
