import type { ReactNode } from 'react';
import React, { memo } from 'react';
import classNames from 'classnames';

import { Title } from '../Title';

import styles from './PageHeader.module.scss';

export interface PageHeaderProps {
  className?: string;
  title: string;
  actions?: ReactNode;
}

export const PageHeader = memo<PageHeaderProps>(({
  className,
  actions,
  title
}) => {
  return (
    <div className={classNames(styles.PageHeader, className)}>
      <Title style={{ marginBottom: 0 }}>
        {title}
      </Title>
      {actions && (
        <div className={styles.actions}>
          {actions}
        </div>
      )}
    </div>
  );
});
