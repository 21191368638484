import React from 'react';

import { useTranslate } from '@acadeum/translate';

import LockIcon from './LockIcon.svg';

import './SingleSignOnEnabled.scss';

export default function SingleSignOnEnabled() {
  const t = useTranslate('SingleSignOnEnabled');
  return (
    <span className="SingleSignOnEnabled">
      {t('label')}
      <LockIcon className="SingleSignOnEnabled-icon"/>
    </span>
  );
}
