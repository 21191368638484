import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connectStateResults } from 'react-instantsearch-dom';

/**
 * Fixes the "Back" button scroll issue for Algolia search:
 * https://github.com/algolia/react-instantsearch/issues/1061
 *
 * Usage:
 *
 * <InstantSearch resultsState={resultsState}>
 *   <InstantSearchResultsState setResultsState={setResultsState}/>
 * </InstantSearch>
 *
 * `setResultsState()` is supposed to store Algolia Search Results
 * somewhere in RAM (e.g. a global variable or Redux state).
 * When a user clicks the "Back" button those stored search results
 * are passed to `<InstantSearch/>` as the `resultsState` property
 * preventing it from rendering an empty output before the first search finishes.
 */

class InstantSearchResultsState extends Component {
  static propTypes = {
    setResultsState: PropTypes.func,
    searchResults: PropTypes.object
  };

  componentDidUpdate() {
    const { searchResults, setResultsState } = this.props;

    // For some reason `searchResults` (an object of class `SearchResults)
    // are re-created on each re-render,
    // so just calling Redux'es `setResultsState(results)` wouldn't work here
    // because it would loop indefinitely (because `results` would always be different
    // causing a new Redux state to be created which would cause a new re-render).
    // Therefore Redux'es `setResultsState(results)` doesn't modify Redux state object reference.
    // Instead it just modifies a property of state: `state.resultsState = results; return state;`.
    if (searchResults && setResultsState) {
      setResultsState(searchResults);
    }
  }

  render() {
    return null;
  }
}

export default connectStateResults(InstantSearchResultsState);

// export default class InstantSearchResultsState extends Component {
//   static contextTypes = {
//     ais: PropTypes.object.isRequired
//   };

//   getSearchResults() {
//     const {
//       ais: { store },
//     } = this.context;
//     const {
//       results,
//       searching,
//       error,
//       widgets,
//       metadata,
//       resultsFacetValues,
//       searchingForFacetValues,
//       isSearchStalled,
//     } = store.getState();
//     const searchResults = {
//       results,
//       searching,
//       error,
//       searchingForFacetValues,
//       isSearchStalled,
//     };
//     return searchResults;
//   }

//   render() {
//     return null;
//   }
// }
