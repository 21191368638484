import type { FC, ReactNode } from 'react';
import { createContext, useContext } from 'react';

import type { UseOnError } from '@acadeum/types';

const OnErrorContext = createContext<UseOnError | undefined>(undefined);

export const useOnError: UseOnError = () => {
  const useOnError_ = useContext(OnErrorContext);

  if (!useOnError_) {
    throw new Error('useOnError must be used within a OnErrorProvider');
  }

  return useOnError_();
};

interface OnErrorProviderProps {
  useOnError: UseOnError;
  children: ReactNode;
}

export const OnErrorProvider: FC<OnErrorProviderProps> = ({ useOnError, children }) => {
  return (
    <OnErrorContext.Provider value={useOnError}>
      {children}
    </OnErrorContext.Provider>
  );
};
