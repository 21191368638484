import { injectInvoiceEndpoints } from '@acadeum/api';

import { rtkApi } from './rtkApi';

export const {
  useFetchInvoicesQuery,
  useFetchInvoiceQuery,
  useLazyFetchInvoicesQuery,
  useLazyFetchInvoiceQuery
} = injectInvoiceEndpoints(rtkApi);
