import type React from 'react';

export function getFilesFromEvent(event: React.ChangeEvent<HTMLInputElement>): FileList {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return [...(event.dataTransfer ? event.dataTransfer.files : event.target.files)];
}

// Copy-pasted from:
// https://github.com/okonet/attr-accept
export function validateFileAccepted(file, acceptedFiles) {
  if (file && acceptedFiles) {
    const acceptedFilesArray = Array.isArray(acceptedFiles) ? acceptedFiles : acceptedFiles.split(',');
    const fileName = file.name || '';
    const mimeType = (file.type || '').toLowerCase();
    const baseMimeType = mimeType.replace(/\/.*$/, '');

    if (!acceptedFilesArray.some(type => {
      const validType = type.trim().toLowerCase();
      if (validType.charAt(0) === '.') {
        return fileName.toLowerCase().endsWith(validType);
      } else if (validType.endsWith('/*')) {
        // This is something like a image/* mime type
        return baseMimeType === validType.replace(/\/.*$/, '');
      }
      return mimeType === validType;
    })) {
      return 'Couldn\'t read the file. Try to upload it again in other format';
    }
  }
}

export function validateFileSize(file, { minSize, maxSize }) {
  if (file) {
    const fileSizeKiloBytes = file.size / 1024;

    const formatSize = (size) => {
      const formattedSize = (size / 1024).toFixed(1); // Convert to MB and format
      return formattedSize.endsWith('.0') ? formattedSize.slice(0, -2) : formattedSize;
    };

    if (minSize && (fileSizeKiloBytes < minSize)) {
      return `Please upload a picture greater than ${formatSize(minSize)} MB.`;
    }

    if (maxSize && (fileSizeKiloBytes > maxSize)) {
      return `Please upload a picture smaller than ${formatSize(maxSize)} MB.`;
    }
  }
}

export function getFileNames(files): string | undefined {
  if (files) {
    if (files instanceof File) {
      return files.name;
    } else {
      return [...files].map(_ => _.name).join(', ');
    }
  }
}
