import React from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from '@acadeum/translate';

import ConfirmationModal from '../ConfirmationModal';

export default function EmailAlreadyVerifiedModal ({
  show,
  onHide
}) {
  const t = useTranslate('EmailAlreadyVerifiedModal');
  return (
    <ConfirmationModal
      show={show}
      onHide={onHide}
      title={t('title')}
      text={t('text')}
    />
  );
}

EmailAlreadyVerifiedModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired
};
