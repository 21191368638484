import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { TabsProps } from '@acadeum/ui';
import { Button, EmptyState, Link, QualityMattersBadge, Tabs, Tag, Title } from '@acadeum/ui';
import { Avatar } from '@acadeum/core-ui';
import { useTranslate } from '@acadeum/translate';
import { CourseIcon, TeachingTagIcon } from '@acadeum/icons';
import type { FetchInstitutionOutput } from '@acadeum/api';

import GradingScale from '../../components/GradingScale';
import CourseSearch from '../../components/CourseSearch';
import WithPublicMarketingPageBanner from '../../components/WithPublicMarketingPageBanner';

import type { AppPage, ReduxState } from '../../helpers/app.types';
import isAcadeumAdministrator from '../../helpers/isAcadeumAdministrator';

import useLocation from '../../hooks/useLocation';

import actions from '../../actions';

import GeneralInfo from './ui/GeneralInfo';

import styles from './Institution.module.scss';

const { fetchInstitution } = actions;

const Institution: AppPage = () => {
  const t = useTranslate('Institution');
  const location = useLocation();

  const initialActiveTab = location?.query?.initialActiveTab;

  const institution: FetchInstitutionOutput = useSelector((state: ReduxState) => state.institutions.institution);
  const user = useSelector((state: ReduxState) => state.auth.user);

  const accreditations = institution.data.accreditations && institution.data.accreditations.map((accreditation, i) => {
    return (
      <span key={i}>
        {t('organizationName', {
          name: () => accreditation.link ? (
            <Link
              rel="noreferrer"
              target="_blank"
              to={accreditation.link}
            >
              {accreditation.name}
            </Link>
          ) : (
            accreditation.name
          )
        })}
      </span>
    );
  });

  const membership = institution.data.membership && institution.data.membership.map((membership, i) => {
    let member: React.ReactNode = membership.name;
    if (membership.link) {
      member = <Link target="_blank" to={membership.link}>asd{member}</Link>;
    }
    return (
      <span key={i}>
        {t('organizationName', {
          name: () => member
        })}
      </span>
    );
  });

  const partnership = institution.data.partnership && institution.data.partnership.map((partnership, i) => {
    let partner: React.ReactNode = partnership.name;
    if (partnership.link) {
      partner = <Link target="_blank" to={partnership.link}>{partner}</Link>;
    }
    return (
      <span key={i}>
        {t('organizationName', {
          name: () => partner
        })}
      </span>
    );
  });

  const tabs: TabsProps['tabs'] = useMemo(() => [
    {
      title: t('generalInfo'),
      key: 'general',
      body: (
        <GeneralInfo
          institution={institution}
          accreditations={accreditations}
          membership={membership}
          partnership={partnership}
        />
      )
    },
    {
      title: t('courses.title'),
      key: 'courses',
      body: (
        <>
          {institution.isTeaching ? (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <CourseSearch institutionName={institution.name}/>
          ) : (
            <EmptyState title={t('courses.noResults')} icon={CourseIcon}/>
          )}
        </>
      )
    },
    {
      title: t('gradingScale'),
      key: 'gradingScale',
      body: (
        <GradingScale
          institutionName={institution.name}
          gradingScale={institution.data.gradingScale}
          gradingScaleUrl={institution.data.gradingScaleUrl}
          gradingScaleNotes={institution.data.gradingScaleNotes}
        />
      )
    }
  ], []);

  return (
    <>
      <header className={styles.Institution__header}>
        <Avatar
          size={96}
          type="institution"
          url={institution.logoUrl}
          name={`${institution.name} logo`}
        />
        {user && isAcadeumAdministrator(user) && (
          <Button
            className={styles['Institution__header-buttons-wrapper']}
            url={`/institutions/${institution.vanityUrl || institution.id}/edit`}
          >
            {t('edit', { global: true })}
          </Button>
        )}
        <Title className={styles['Institution__header-title']}>
          {institution.fullName || institution.name}
        </Title>
        <Link
          to={institution.url}
          className={styles['Institution__header-link']}
          suffixIcon="external"
        >
          {t('visitWebsite')}
        </Link>
      </header>

      <div className={styles['Institution__tags-wrapper']}>
        {institution.data.levels && (
          <Tag>
            {institution.data.levels}
          </Tag>
        )}
        {institution.funding && (
          <Tag>
            {institution.funding}
          </Tag>
        )}
        {institution.data.affiliations && (
          <>
            {Array.isArray(institution.data.affiliations) && (
              <>
                {institution.data.affiliations.map((affiliation, index) => (
                  <Tag key={index}>
                    {affiliation}
                  </Tag>
                ))}
              </>
            )}
          </>
        )}
        {institution.isTeaching && (
          <Tag className={styles['Institution__tag--teaching']}>
            <TeachingTagIcon className={styles['Institution__tag--teaching-icon']}/>
            {t('teaching')}
          </Tag>
        )}

        {institution.qualityMattersDesignation && (
          <QualityMattersBadge variant="full"/>
        )}
      </div>
      <Tabs
        title="Institution"
        tabs={tabs}
        initialActiveTab={initialActiveTab}
      />
    </>
  );
};

Institution.meta = ({ useSelector }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const institution = useSelector(state => state.institutions.institution);
  return {
    title: institution.shortName || institution.name
  };
};

Institution.load = async ({ params: { id } }) => {
  await fetchInstitution(id);
};

Institution.breadcrumbs = (state) => [
  ['Institutions', '/institutions'],
  state.institutions.institution.name
];

export default WithPublicMarketingPageBanner(Institution, { margin: true });
