import React from 'react';
import PropTypes from 'prop-types';

import './ListPageResultsLabel.sass';

export default function ListPageResultsLabel({
  hasLoadedInitially,
  loading,
  totalCount,
  error,
  singularLabel,
  pluralLabel,
  resultsLabel
}) {
  resultsLabel = resultsLabel || pluralLabel;
  return (
    <div>
      <strong>{resultsLabel}:</strong>
      {' '}
      {hasLoadedInitially && (
        loading
          ? 'Loading…'
          : (
            totalCount === 0 ? (
              'No Results'
            ) : (
              `${totalCount} All Time ${totalCount === 1 ? singularLabel : pluralLabel}`
            )
          )
      )}

      {!hasLoadedInitially && (
        error ? (
          <span className="ListPageResultsLabel-error">
            Error
          </span>
        ) : (
          'Loading…'
        )
      )}
    </div>
  );
}

ListPageResultsLabel.propTypes = {
  hasLoadedInitially: PropTypes.bool,
  loading: PropTypes.bool,
  totalCount: PropTypes.number,
  error: PropTypes.object,
  singularLabel: PropTypes.string.isRequired,
  pluralLabel: PropTypes.string.isRequired,
  resultsLabel: PropTypes.string
};
