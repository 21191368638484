import type { FC } from 'react';
import React, { useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import type { Stripe } from '@stripe/stripe-js';

import { Button, ConfirmActionModal, Form, FormFooter, FormSubmit, toast } from '@acadeum/ui';
import { useTranslate } from '@acadeum/translate';
import type { UseCreatePrimaryPaymentSourceMutation, UseUpdatePrimaryPaymentSourceMutation } from '@acadeum/api';

import { BankAccountFormFields } from '../BankAccountFormFields';

export interface BankAccountFormProps {
  type: 'current' | 'new';
  cancelText: string;
  onAfterSubmit: () => void;
  onCancel?: () => void;
  useCreatePrimaryPaymentSourceMutation: UseCreatePrimaryPaymentSourceMutation;
  useUpdatePrimaryPaymentSourceMutation: UseUpdatePrimaryPaymentSourceMutation;
}

export const BankAccountForm: FC<BankAccountFormProps> = ({
  type,
  onAfterSubmit,
  cancelText,
  onCancel,
  useUpdatePrimaryPaymentSourceMutation,
  useCreatePrimaryPaymentSourceMutation
}) => {
  const [createPrimaryPaymentSourceMutation] = useCreatePrimaryPaymentSourceMutation();
  const [updatePrimaryPaymentSourceMutation] = useUpdatePrimaryPaymentSourceMutation();

  const [showConfirmCancel, setShowConfirmCancel] = useState(false);

  const stripe = useStripe() as Stripe;
  const t = useTranslate('shared-admin-ui.BankAccountForm');

  const onCancel_ = () => {
    if (type === 'current') {
      return setShowConfirmCancel(true);
    }
    if (typeof onCancel === 'function') {
      onCancel();
    }
  };

  const onSubmit = async (values) => {
    const { holderName, holderType, accountNumber, routingNumber } = values;
    const result = await stripe.createToken('bank_account', {
      country: 'US',
      currency: 'usd',
      routing_number: routingNumber,
      account_number: accountNumber,
      account_holder_name: holderName,
      account_holder_type: holderType
    });
    const { token, error } = result;

    if (error) {
      return toast.error(result.error.message);
    }

    const action = type === 'current' ? createPrimaryPaymentSourceMutation : updatePrimaryPaymentSourceMutation;
    await action({ sourceId: token.id }).unwrap();
    toast.success(t('successMessage'));

    await onAfterSubmit?.();
  };

  return (
    <Form onSubmit={onSubmit}>
      <BankAccountFormFields/>

      <FormFooter>
        {onCancel && (
          <>
            <Button variant="secondary" onClick={onCancel_}>
              {t(cancelText, { global: true })}
            </Button>

            <ConfirmCancelModal
              show={showConfirmCancel}
              onHide={() => setShowConfirmCancel(false)}
              onCancel={onCancel}
            />
          </>
        )}
        <FormSubmit>
          {t('save', { global: true })}
        </FormSubmit>
      </FormFooter>
    </Form>
  );
};

interface ConfirmCancelModalType {
  show: boolean;
  onHide: () => void;
  onCancel: () => void;
}

const ConfirmCancelModal: FC<ConfirmCancelModalType> = ({
  show,
  onHide,
  onCancel
}) => {
  const t = useTranslate('shared-admin-ui.BankAccountForm');

  return (
    <ConfirmActionModal
      danger
      show={show}
      title={t('cancelSetupModal.title')}
      description={t('cancelSetupModal.description', {
        danger: text => <><br/> <span className="danger">{text}</span></>
      })}
      submitText={t('skip', { global: true })}
      cancelText={t('continue', { global: true })}
      onCancel={onHide}
      onHide={onHide}
      onSubmit={onCancel}
    />
  );
};
