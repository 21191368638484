import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import { Table } from '@acadeum/ui';
// import ReactTableSelectRows from '../RawDataTable.SelectRows';
// import ReactTableSelectRows from 'react-table/lib/hoc/selectTable'
// import { Checkbox } from 'react-responsive-ui';
import { downloadCSV as downloadCsv } from '@acadeum/helpers';

import { formatCurrency, formatDate } from '../../helpers/format';

import TableAction, { ActionPropType } from '../TableAction';

import './RawDataTable.sass';

// const CheckboxTable = ReactTableSelectRows(ReactTable);

// https://react-table.js.org/#/story/readme
export default function RawDataTable({
  title,
  actions,
  // defaultPageSize,
  // pageSize,
  // showPagination,
  totalCount,
  // Used properties.
  className,
  columns,
  data,
  selectableRows = true
}) {
  const [rowSelection, setRowSelection] = useState({});
  const selectedRowIds = Object.keys(rowSelection);

  const dataWithRowIds = useMemo(() => {
    return setRowIds(data);
  }, [data]);

  return (
    <section className={classNames('raw-data-table', className)}>
      {title &&
        <h1 className="raw-data-table__title">
          {title}
        </h1>
      }

      {totalCount !== undefined &&
        <p>
          {totalCount} row{totalCount === 1 ? '' : 's'} total
        </p>
      }

      <p>
        {data.length} row{data.length === 1 ? '' : 's'} {totalCount ? 'fetched' : 'total'}
      </p>

      {selectableRows &&
        <p>
          {selectedRowIds.length} row{selectedRowIds.length === 1 ? '' : 's'} selected
        </p>
      }

      <Table
        hideTotalResultsCount
        className={className}
        columns={columns}
        data={dataWithRowIds}
        rowSelectionOptions={{ rowSelection, onRowSelectionChange: setRowSelection }}
      />

      {actions &&
        <div className="raw-data-table__actions">
          {actions.map((action, id) => (
            <TableAction
              key={id}
              action={normalizeAction(action, { columns })}
              data={data}
              selectedRowIds={selectedRowIds}
              className="raw-data-table__action"/>
          ))}
        </div>
      }
    </section>
  );
}

function normalizeAction(option, { columns }) {
  const { type, options, ...rest } = option;
  if (type === 'download') {
    return {
      ...rest,
      title: 'Download',
      onClick: (rows) => download(rows, { columns }, options)
    };
  }
  return option;
}

function download(selectedRows, { columns }, { fileName } = {}) {
  downloadCsv({
    fileName: `${fileName || 'data'}.csv`,
    columns: convertColumnFormatForDownload(columns),
    data: selectedRows
  });
}

export function compileColumns(columns) {
  // const ids = [];
  return columns.map((column) => {
    // // Set "id".
    // const id = column.id || column.accessor || column.title;
    // if (ids.indexOf(id) >= 0) {
    //   throw new Error(`Duplicate column id was passed. Specify a unique "id" for column "${column.title}"`);
    // }
    // ids.push(id);
    // // Set "format".
    // let format = column.format;
    // if (typeof format === 'string') {
    //   switch (format) {
    //     case 'boolean':
    //       format = _ => _ ? 'Yes' : 'No';
    //     default:
    //       throw new RangeError(`Unknown column format: "${format}`);
    //   }
    // }
    // if (typeof format !== 'function') {
    //   throw new TypeError('Column "format" must be a function');
    // }
    // Set "accessor".
    if (typeof column.accessor !== 'string') {
      throw new TypeError('Column "accessor" is required and must be a string');
    }
    // Return column definition.
    return {
      id: column.accessor,
      header: column.title,
      accessorFn: row => formatValue(get(row, column.accessor), column)
    };
  });
}

RawDataTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    Header: PropTypes.string,
    accessor: PropTypes.func.isRequired
  })),
  actions: PropTypes.arrayOf(PropTypes.oneOfType([
    ActionPropType,
    PropTypes.shape({
      type: PropTypes.oneOf(['download'])
    })
  ])),
  defaultPageSize: PropTypes.number.isRequired,
  pageSize: PropTypes.number,
  showPagination: PropTypes.bool,
  selectableRows: PropTypes.bool,
  totalCount: PropTypes.number,
  className: PropTypes.string
};

function formatValue(value, { type }) {
  switch (type) {
    case 'currency':
      // Currency is stored in DECIMAL columns which is are read as strings in Sequelize.
      return formatCurrency(parseFloat(value));
  }
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  if (value instanceof Date) {
    return formatDate(value, { utc: true });
  }
  return value;
}

function setRowIds(data) {
  return data.map((row) => {
    row._id = row.id;
    return row;
  });
}

function convertColumnFormatForDownload(columns) {
  return columns.map((column) => ({
    title: column.Header,
    value: column.accessor
  }));
}
