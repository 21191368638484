import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onWait: PropTypes.func,
  waitForIdle: PropTypes.bool,
  Component: PropTypes.elementType.isRequired
};

interface SearchInputProps extends Omit<React.HTMLAttributes<HTMLInputElement>, 'onChange'> {
  value?: string;
  onChange: (value: string) => void;
  onWait?: (value: boolean) => void
  waitForIdle?: boolean;
  // eslint-disable-next-line
  Component: any
}

export const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(({
  value,
  onChange,
  onWait,
  waitForIdle,
  Component,
  ...rest
}, ref) => {
  const [searchValue, setSearchValue] = useState('');
  const onSearchChange = (event) => setSearchValue(event.target.value);

  if (!onChange) {
    value = searchValue;
    onChange = onSearchChange;
  }

  if (value === undefined) {
    value = '';
  }

  const latestValue = useRef(value);

  const setValue = (value) => {
    if (value !== latestValue.current) {
      latestValue.current = value;
      setImmediateValue(value);
      onChange(value);
    }
  };

  const [immediateValue, setImmediateValue] = useState(value);

  useEffect(() => {
    if (value === undefined) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      value = '';
    }
    if (value !== latestValue.current) {
      latestValue.current = value;
      setImmediateValue(value);
    }
  }, [value]);

  const timer = useRef<NodeJS.Timeout>();

  const onImmediateValueChange = (value) => {
    setImmediateValue(value);
    if (onWait) {
      onWait(true);
    }
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setValue(value);
      if (onWait) {
        onWait(false);
      }
    }, 300);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const _value = waitForIdle ? immediateValue : value;

  const _onChange = (event) => {
    let value;
    if (typeof event === 'object' && typeof event.target === 'object') {
      value = event.target.value;
    } else {
      value = event;
    }
    (waitForIdle ? onImmediateValueChange : onChange)(value);
  };

  const _clear = () => {
    (waitForIdle ? setValue : onChange)('');
  };

  return (
    <Component
      {...rest}
      ref={ref}
      value={_value}
      onChange={_onChange}
      clear={_clear}
      waitForIdle={waitForIdle}
    />
  );
});

SearchInput.propTypes = propTypes;
