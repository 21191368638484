import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import getTermsAndSessions from './getTermsAndSessions.js';

export default function useTermsAndSessions({ courseApproval }) {
  const termsAndSessions = useSelector(state => state.courses.termsAndSessions);

  return useMemo(() => {
    if (!termsAndSessions) {
      return {};
    }
    return {
      ...getTermsAndSessions({ termsAndSessions, courseApproval }),
      existingTermsAndSessions: termsAndSessions
    };
  }, [
    termsAndSessions,
    courseApproval
  ]);
}
