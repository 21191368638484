import type { FC, ReactNode } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useTranslate } from '@acadeum/translate';
import { CircleExclamationSolidIcon, CircleInfoIcon } from '@acadeum/icons';

import type { UnstyledTooltipProps } from '../UnstyledTooltip';
import { UnstyledTooltip } from '../UnstyledTooltip';

import styles from './InfoTooltip.module.scss';

export interface InfoTooltipProps extends Pick<UnstyledTooltipProps, 'className' | 'placement'> {
  children?: ReactNode;
  variant?: 'info' | 'error';
  ariaTitle: string;
}

export const InfoTooltip: FC<InfoTooltipProps> = ({
  className,
  ariaTitle,
  variant,
  children,
  placement
}) => {
  const t = useTranslate('ui.InfoTooltip');
  ariaTitle = ariaTitle || t('defaultProps.ariaTitle');

  const Icon = variant === 'error' ? CircleExclamationSolidIcon : CircleInfoIcon;

  return (
    <UnstyledTooltip
      arrow
      placement={placement}
      ariaTitle={ariaTitle}
      triggerClassName={styles.trigger}
      tooltipClassName={styles.tooltip}
      arrowClassName={styles.arrow}
      className={classNames(styles.Tooltip, styles[`Tooltip--${variant}`], className)}
      tooltipContent={children}
    >
      <Icon className={styles.icon}/>
    </UnstyledTooltip>
  );
};

InfoTooltip.displayName = 'InfoTooltip';
InfoTooltip.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['info', 'error']),
  ariaTitle: PropTypes.string.isRequired
};
