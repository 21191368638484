import React, { useEffect, useRef, useState } from 'react';
import { Autocomplete, Select } from 'react-responsive-ui';
import { useSelector } from 'react-redux';
import * as d3 from 'd3';
import _groupBy from 'lodash/groupBy';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';

import { useUpdateEffect } from '@acadeum/hooks';

import Field from '../../components/Field';
import Form from '../../components/Form';
import Row from '../../components/Row';

// Josh said:
//
// "I'd really like the spider graph to have a group filter.
//  So now it's grouped by state.  I'd like to be able to toggle
//  between grouping by state, accreditor, consortium, private v
//  public v for profit v other, etc."
import './Viz.sass';

import actions from '../../actions';

const {
  setAppLoading,
  fetchVizData
} = actions;

export default function Viz() {
  const chartNode = useRef();

  const data = useSelector(state => state.viz.vizData);

  const [groupBy, setGroupBy] = useState('State');

  const [state, setState] = useState({
    institutionId: 'All',
    startDate: null,
    endDate: null,
    state: 'All',
    accreditor: 'All',
    consortium: 'All',
    funding: 'All'
  });

  const getTeachingInstitutionKey = (d) => {
    switch (groupBy) {
      case 'Accreditor':
        return `${d.TMAccred}*${d.TM}`;
      case 'Control':
        return `${d.TMControl}*${d.TM}`;
      default:
        return `${d.TMState}*${d.TM}`;
    }
  };

  const getHomeInstitutionKey = (d) => {
    switch (groupBy) {
      case 'Accreditor':
        return `${d.EMAccred}*${d.EM}`;
      case 'Control':
        return `${d.EMControl}*${d.EM}`;
      default:
        return `${d.EMState}*${d.EM}`;
    }
  };

  const drawChart = (data) => {
    console.log('Draw Chart');

    data = Object.values(data);
    console.log('Data', data);

    if (data.length === 0) {
      return;
    }

    const enrollmentsData = data.map(d => ({
      enrollmentsCount: d.count,
      teachingInstitutionKey: getTeachingInstitutionKey(d),
      homeInstitutionKey: getHomeInstitutionKey(d)
    }));

    const dataByTeachingInstitutionKey = _groupBy(enrollmentsData, 'teachingInstitutionKey');
    const dataByHomeInstitutionKey = _groupBy(enrollmentsData, 'homeInstitutionKey');

    const enrollments = [];

    data.forEach(d => {
      let homeInstitutions = [];
      let enrollmentsCount = 0;
      const key = getTeachingInstitutionKey(d);
      const enrollmentsData = dataByTeachingInstitutionKey[key];
      if (enrollmentsData) {
        homeInstitutions = enrollmentsData.map(_ => _.homeInstitutionKey);
        enrollmentsCount = enrollmentsData.map(_ => _.enrollmentsCount).reduce((sum, count) => sum + count, 0);
      }
      enrollments.push({
        key,
        enrollmentsCount,
        homeInstitutions
      });
    });

    data.forEach(d => {
      const key = getHomeInstitutionKey(d);
      var enrollmentsData = dataByTeachingInstitutionKey[key];
      if (!enrollmentsData) {
        enrollments.push({
          key,
          enrollmentsCount: 0,
          homeInstitutions: []
        });
      }
    });

    const start = '#29b34a';
    const stop = '#6199d1';

    const sizes = enrollments.map(_ => _.enrollmentsCount).filter(_ => _);

    function getSize(node) {
      const target = node.target.data.key.split('*')[1];
      const source = node.source.data.key.split('*')[1];
      if (!target || !source) {
        return 0;
      }
      return data.filter(datum => datum.TM === source && datum.EM === target)[0].count;
    }

    // const color = d3.interpolateRainbow;

    const logScale = d3
      .scaleLog()
      .domain([d3.min(sizes), d3.max(sizes)])
      .range([1, 25]);

    const diameter = 800,
      radius = diameter / 2,
      innerRadius = radius - 120;

    const cluster = d3.cluster()
      .size([360, innerRadius]);

    const line = d3.radialLine()
      .curve(d3.curveBundle.beta(0.95))
      .radius(d => d.y)
      .angle(d => d.x / 180 * Math.PI);

    d3.selectAll('svg > *').remove();

    const svg = d3.select(chartNode.current)
      .attr('width', diameter + 400)
      .attr('height', diameter + 400)
      .append('g')
      .attr('transform', 'translate(' + (radius + 200) + ',' + (radius + 200) + ')');

    const defs = svg.append('defs');

    const gradient = defs.append('linearGradient')
      .attr('id', 'svgGradient')
      .attr('x1', '0%')
      .attr('x2', '100%')
      .attr('y1', '0%')
      .attr('y2', '100%');

    gradient.append('stop')
      .attr('class', 'start')
      .attr('offset', '0%')
      .attr('stop-color', stop)
      .attr('stop-opacity', 1);

    gradient.append('stop')
      .attr('class', 'end')
      .attr('offset', '100%')
      .attr('stop-color', start)
      .attr('stop-opacity', 1);

    let links = svg.append('g').selectAll('.viz__link');
    let nodes = svg.append('g').selectAll('.viz__node');

    const onClick = (event, node) => {
      let fromEnrollmentsCount = 0;
      if (dataByHomeInstitutionKey[node.data.key]) {
        fromEnrollmentsCount = dataByHomeInstitutionKey[node.data.key].reduce((sum, { enrollmentsCount }) => sum + enrollmentsCount, 0);
      }
      alert(`Enrollments to this institution: ${node.data.enrollmentsCount}\nEnrollments from this institution: ${fromEnrollmentsCount}`);
    };

    const onMouseOver = (event, node) => {
      chartNode.current.classList.add('viz__chart--hover');

      links
        // Set `source` flags.
        .classed('viz__link--target', (link) => {
          if (link.target === node) {
            return link.source.source = true;
          }
        })
        // Set `target` flags.
        .classed('viz__link--source', (link) => {
          if (link.source === node) {
            return link.target.target = true;
          }
        })
        // Raise the links related to the hovered node.
        .filter((link) => {
          return link.target === node || link.source === node;
        })
        .raise();

      nodes
        .classed('viz__node--target', node => node.target)
        .classed('viz__node--source', node => node.source)
        // Raise the nodes related to the hovered node.
        .filter((node) => {
          return node.target || node.source;
        })
        .raise();
    };

    const onMouseOut = () => {
      chartNode.current.classList.remove('viz__chart--hover');

      // Clear `source`/`target` flags.
      nodes
        .each(node => node.target = node.source = false);

      // Remove CSS classes from all links.
      links
        .classed('viz__link--target', false)
        .classed('viz__link--source', false);

      // Remove CSS classes from all nodes.
      nodes
        .classed('viz__node--target', false)
        .classed('viz__node--source', false);
    };

    const root = createHierarchy(enrollments).sum(node => node.enrollmentsCount);

    cluster(root);

    links = links
      .data(createInterNodeLinks(root.leaves()))
      .enter().append('path')
      .each((node) => {
        node.source = node[0];
        node.target = node[node.length - 1];
      })
      .attr('class', 'viz__link')
      .style('stroke-width', node => logScale(getSize(node)) + 'px')
      .attr('d', line);

    nodes = nodes
      .data(root.leaves())
      .enter().append('text')
      .attr('class', 'viz__node')
      .attr('dy', '0.31em')
      .attr('transform', (node) => {
        return 'rotate(' + (node.x - 90) + ')translate(' + (node.y + 8) + ',0)' + (node.x < 180 ? '' : 'rotate(180)');
      })
      .attr('text-anchor', node => node.x < 180 ? 'start' : 'end')
      .text(node => node.data.institutionName)
      .on('click', onClick)
      .on('mouseover', onMouseOver)
      .on('mouseout', onMouseOut);

    // Lazily construct the package hierarchy from class names.
    function createHierarchy(enrollments) {
      const nodesByKey = {};

      function findNodeByKey(key, data) {
        if (nodesByKey[key]) {
          return nodesByKey[key];
        }
        const node = nodesByKey[key] = data || { key, children: [] };
        if (key.length > 0) {
          const i = key.lastIndexOf('*');
          node.parent = findNodeByKey(key.substring(0, i));
          node.parent.children.push(node);
          node.institutionName = key.substring(i + 1);
        }
        return node;
      }

      for (const enrollmentsData of enrollments) {
        findNodeByKey(enrollmentsData.key, enrollmentsData);
      }

      return d3.hierarchy(nodesByKey['']);
    }

    // Returns a list of inter-link "path"s for the given list of nodes.
    function createInterNodeLinks(nodes) {
      const nodesByKey = {};
      const homeInstitutions = [];

      // Compute a map of nodes by key.
      for (const node of nodes) {
        nodesByKey[node.data.key] = node;
      }

      // For each node, create a link from the source to target node.
      for (const node of nodes) {
        if (node.data.homeInstitutions) {
          for (const enrollingNodeKey of node.data.homeInstitutions) {
            homeInstitutions.push(
              nodesByKey[node.data.key].path(nodesByKey[enrollingNodeKey])
            );
          }
        }
      }

      return homeInstitutions;
    }

    // // At some point we render a child, say a tooltip
    // const tooltipData = ...
    // renderTooltip([50, 100], tooltipData);
  };

  const onChangeOptions = (stateUpdate) => {
    setState({
      ...state,
      ...stateUpdate
    });
  };

  useEffect(() => {
    drawChart(data[5]);
  }, [
    data,
    groupBy
  ]);

  useUpdateEffect(() => {
    async function onStateChange() {
      try {
        setAppLoading(true);
        await fetchVizData(state);
      } finally {
        setAppLoading(false);
      }
    }
    onStateChange();
  }, [state]);

  return (
    <div>
      <section className="Viz__section">
        <h1>Institution</h1>
        <Autocomplete
          options={[{ label: 'All', value: 'All' }].concat(generateInstitutionList(data))}
          onChange={val => onChangeOptions({ institutionId: val })}
          value={state.institutionId}
        />
      </section>

      <section className="Viz__section">
        <h1>State/Province</h1>
        <Select
          options={[{ label: 'All', value: 'All' }].concat(generateStateList(data))}
          onChange={val => onChangeOptions({ state: val })}
          value={state.state}
        />
      </section>

      <section className="Viz__section">
        <h1>Accreditor</h1>
        <Select
          options={[{ label: 'All', value: 'All' }].concat(generateAccreditorList(data))}
          onChange={val => onChangeOptions({ accreditor: val })}
          value={state.accreditor}
        />
      </section>

      <section className="Viz__section">
        <h1>Consortium</h1>
        <Select
          options={[{ label: 'All', value: 'All' }].concat(generateConsortiumList(data))}
          onChange={val => onChangeOptions({ consortium: val })}
          value={state.consortium}
        />
      </section>

      <section className="Viz__section">
        <h1>Control</h1>
        <Select
          options={[{ label: 'All', value: 'All' }].concat(generateControlList(data))}
          onChange={val => onChangeOptions({ funding: val })}
          value={state.funding}
        />
      </section>

      <section className="Viz__section Viz__section--form">
        <h1>Dates</h1>
        <Row>
          <Form
            values={{
              startDate: state.startDate,
              endDate: state.endDate
            }}
            onSubmit={() => { }}>
            <Field
              name="startDate"
              onChange={val => onChangeOptions({ startDate: val })}
              label="START DATE"
              type="date"
              col={6}/>
            <Field
              name="endDate"
              onChange={val => onChangeOptions({ endDate: val })}
              label="END DATE"
              type="date"
              col={6}/>
          </Form>
        </Row>
      </section>

      <section className="Viz__section">
        <h1>Group By</h1>
        <Select
          options={['State', 'Control', 'Accreditor'].map(_ => ({ label: _, value: _ }))}
          onChange={setGroupBy}
          value={groupBy}
        />
      </section>

      <svg ref={chartNode} className="viz__chart"/>
    </div>
  );
}

Viz.meta = () => ({
  title: 'Viz'
});

Viz.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Viz'
];

Viz.load = async () => {
  await fetchVizData();
};

function generateInstitutionList(data) {
  return Object.values(data[0]).map(_ => ({ label: _.name, value: _.id }));
}

function generateStateList(data) {
  return Object.values(data[1]).map(_ => ({ label: _.state, value: _.state }));
}

function generateConsortiumList(data) {
  return Object.values(data[2]).map(_ => ({ label: _.name, value: _.id }));
}

function generateControlList(data) {
  return Object.values(data[3])
    .map(_ => _['funding'])
    .filter(_ => _)
    .sort()
    .map(_ => ({ label: _, value: _ }));
}

function generateAccreditorList(data) {
  const res = Object
    .values(data[4])
    .map(_ => _['data->\'$.accreditations\'']);
  const values = uniq(flatten(res)
    .filter(_ => _)
    .map(_ => _.name))
    .sort()
    .map(_ => ({ label: _, value: _ }));
  return values;
}
