/* eslint-disable */

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import classNames from 'classnames';

import { FilterIcon, PinIcon, XMarkIcon } from '@acadeum/icons';
import { useTranslate } from '@acadeum/translate';
import { Button, Filter, Title, useScreenSize } from '@acadeum/ui';
import { getDataFromStorage, getDeepDataFromStorage, saveDataToStorage } from '@acadeum/helpers';

import { useClientSettingsCourseSearchFilters } from '../../providers/clientSettings';

import InstantSearch from '../InstantSearch';
import CourseSearchResult from './CourseSearchResult';
import CreateSavedSearchModal from './CreateSavedSearchModal';
import SavedSearches from './SavedSearches';
import SearchBoxNew from '../InstantSearch/react-instantsearch-dom/widgets/SearchBoxNew';

import useCourseSearchFilters from './useCourseSearchFilters';
import { getCourseSearchAttributeName } from './getCourseSearchAttributeName';
import { decodeSearchState, encodeSearchState, getAttributesHavingNonDefaultFilterValues } from './searchStateUtils';

import {
  COURSE_ATTRIBUTES,
  COURSE_INDEXES,
  COURSE_INDEXES_WITH_PRICE,
  FILTER_NAMES,
  SECTION_INDEXES,
  SECTION_INDEXES_WITH_PRICE,
  SECTION_RANGES
} from './constants';

import './CourseSearch_.sass';

export const DRAFT_SAVED_SEARCH_NAME = 'LATEST';

const getSavedSearchLocalStorageKey = (name) => {
  return `courseshare.lastActiveSavedSearch.${name}`;
};

export default function CourseSearch({
  view = 'sections',
  scope = 'all',
  showPrices = true,
  institutionName,
  courseSharingGroupIds,
  viewerCourseSharingGroupIds,
  preApproved,
  consortiumName,
  newCourseSearchDesign,
  searchFieldBanner,
  searchFieldLabel,
  userInstitutionId,
  className,
  // TODO: Remove this `enableSavedSearches` prop after one month. Planning next release on 2024-07-16 so this can be removed after 2024-08-16.
  // As alternative, we can use `clientSettingsSavedSearchesKey` prop to enable/disable saved searches.
  enableSavedSearches,
  // TODO: Remove this `savedSearchesLocalStorageKey` prop after one month. Planning next release on 2024-07-16 so this can be removed after 2024-08-16.
  // It's will be replaced by `clientSettingsSavedSearchesKey` prop.
  savedSearchesLocalStorageKey,
  clientSettingsSavedSearchesKey,
  isHS4CC,
  ...rest
}) {
  const { isSmallScreen } = useScreenSize();
  const getAttributeName = (name) => {
    return getCourseSearchAttributeName(name, view);
  };

  const canFilterBeCleared = (item) => {
    switch (item.attribute) {
      case getAttributeName('session.course.institution.name'):
        // Won't allow clearing institution refinement
        // if it has been explicitly set
        if (institutionName) {
          return false;
        }
        break;
      case getAttributeName('session.course.courseSharingGroups.id'):
        // Won't allow clearing `courseSharingGroupIds` refinement
        // if it has been explicitly set
        if (courseSharingGroupIds || viewerCourseSharingGroupIds) {
          return false;
        }
        break;
      case 'approvedBy':
        // Won't allow clearing "Approved Course" refinement
        // if it has been explicitly set
        if (preApproved) {
          return false;
        }
        break;
      // case 'session.scheduledBy':
      //   // Won't allow clearing "Scheduled Session" refinement
      //   // if it has been explicitly set
      //   if (preApproved) {
      //     return false;
      //   }
      //   break;
      case getAttributeName('session.course.institution.consortiaNames'):
        // If consortium name is pre-defined then it's unclearable.
        if (consortiumName) {
          return false;
        }
        break;
      case getAttributeName('session.course.institution.id'):
        if (scope === 'own') {
          return false;
        }
        break;
    }

    return true;
  };

  // Renders search filters.
  //
  // Previously, it was being passed to `<InstantSearch/>` as `useFilters={Filters}`
  // and then rendered as `<Filters/>`.
  // But then it was found out that such `Filters` component
  // was re-created on every render as `const Filters = () => ...`.
  // That lead to the `<Filters/>` element getting unmounted on each render,
  // resulting in all search filter values restored from the URL being reset.
  //
  // The reason is that `createConnector()` function calls `cleanUp()` in `componentWllUnmount()`,
  // and `cleanUp()` clears any pre-set filter values in `searchState`.
  //
  // For example, the Home page would first be rendered without the `user`
  // being authenticated but then it would re-render with the `user` authenticated (Redux state update)
  // which would cause a re-render of the `<CourseSearch/>` component
  // and all filtering toggles would be reset to their default "non-toggled" state
  // almost immediately after the page load.
  // https://acadeum.atlassian.net/browse/CS-271
  //
  // So to fix the "search filters getting reset on page load" bug,
  // search filters are now passed as a function rather than a React component.

  const [latestSavedSearchName, setLatestSavedSearchName] = useState(DRAFT_SAVED_SEARCH_NAME);
  const [showCreateSavedSearchModal, setShowCreateSavedSearchModal] = useState();
  const [draftSearch, setDraftSearch] = useState({});

  const {
    courseSearchFilters,
    saveCourseSearchFilters,
    updateCourseSearchFilter
  } = useClientSettingsCourseSearchFilters();

  const savedSearches = useMemo(() => {
    return get(courseSearchFilters, `${view}.${scope}.savedSearches`) || {};
  }, [courseSearchFilters, view, scope]);

  // Migrate saved searches from local storage to database
  // TODO: Remove this `useEffect` after one month. Planning next release on 2024-07-16 so this can be removed after 2024-08-16.
  useEffect(() => {
    if (savedSearchesLocalStorageKey) {
      const savedSearches_ = getDeepDataFromStorage(savedSearchesLocalStorageKey);
      if (savedSearches_) {
        void saveCourseSearchFilters(view, scope, savedSearches_);
      }
    }
  }, [savedSearchesLocalStorageKey]);

  useEffect(() => {
    if (savedSearchesLocalStorageKey) {
      const latestSavedSearchName_ = getDataFromStorage(getSavedSearchLocalStorageKey(savedSearchesLocalStorageKey), { type: 'string' });
      setLatestSavedSearchName(latestSavedSearchName_ || DRAFT_SAVED_SEARCH_NAME);
    }
  }, [savedSearchesLocalStorageKey]);

  const updateLatestSavedSearchName = (name) => {
    saveDataToStorage(getSavedSearchLocalStorageKey(savedSearchesLocalStorageKey), name);
    setLatestSavedSearchName(name);
  };

  const onCreateSavedSearchClick = () => {
    setShowCreateSavedSearchModal(true);
  };

  const useFilters = ({ instantSearchFilterValuesAndClearAll, searchState, onSearchStateChange }) => {
    const { page, ...restSearchState } = searchState;

    const {
      availableFiltersListOptions,
      onToggleShowFilter,
      onResetShownFilters,
      toggleFilters,
      multiSelectListFilters
    } = useCourseSearchFilters({
      view,
      scope,
      showPrices,
      institutionName,
      courseSharingGroupIds,
      viewerCourseSharingGroupIds,
      preApproved,
      consortiumName,
      newCourseSearchDesign,
      searchFieldBanner,
      searchFieldLabel,
      userInstitutionId,
      className,
      enableSavedSearches,
      savedSearchesLocalStorageKey,
      ...rest
    }, {
      getAttributeName,
      FILTER_NAMES,
      isSmallScreen,
      searchState,
      isHS4CC
    });

    const areNonDefaultSearchFilterValues = useMemo(() => {
      const { page, ...restSearchState } = searchState;
      return getAttributesHavingNonDefaultFilterValues(restSearchState).length > 0;
    }, [searchState]);

    const doesSelectedSavedSearchHaveUnsavedChanges = useMemo(() => {
      const { page, ...restSearchState } = searchState;
      const selectedSavedSearch = savedSearches[latestSavedSearchName];
      return selectedSavedSearch && !isEqual(restSearchState, decodeSearchState(selectedSavedSearch.searchState, view));
    }, [savedSearches, searchState, latestSavedSearchName, view]);

    const onAfterSavedSearchesUpdated = (name) => {
      updateLatestSavedSearchName(name);
      setShowCreateSavedSearchModal(false);
      setDraftSearch({});
    };

    const onUpdateSavedSearchClick = async () => {
      await updateCourseSearchFilter(view, scope, latestSavedSearchName, {
        searchState: encodeSearchState(restSearchState, view)
      });
    };

    const [showFiltersModal, setShowFiltersModal] = useState();

    const hideFiltersModal = () => {
      setShowFiltersModal(false);
    };
    const openShowFiltersModal = () => {
      setShowFiltersModal(true);
    };

    const renderSaveFilter = () => (
      <>
        {enableSavedSearches && (
            latestSavedSearchName === DRAFT_SAVED_SEARCH_NAME
              ? areNonDefaultSearchFilterValues
              : doesSelectedSavedSearchHaveUnsavedChanges)
          && (
            <>
              <Button
                icon={PinIcon}
                className="BlockFilters__saveSearch"
                variant="black-outline"
                onClick={latestSavedSearchName === DRAFT_SAVED_SEARCH_NAME
                  ? onCreateSavedSearchClick
                  : onUpdateSavedSearchClick}
              >
                {t('saveSearch')}
              </Button>
              <CreateSavedSearchModal
                view={view}
                scope={scope}
                searchState={restSearchState}
                onHide={setShowCreateSavedSearchModal}
                show={showCreateSavedSearchModal}
                savedSearches={savedSearches}
                onAfterSavedSearchesUpdated={onAfterSavedSearchesUpdated}
              />
            </>
          )}
      </>
    );

    if (newCourseSearchDesign) {
      return (
        <>
          {isSmallScreen && (
            <div className="BlockFilters__mobile-header">
              <SearchBoxNew placeholder="Search Courses"/>
              <Button
                variant="tertiary"
                icon={FilterIcon}
                onClick={openShowFiltersModal}
              >
                Filters
              </Button>
            </div>
          )}
          {enableSavedSearches && (
            <SavedSearches
              savedSearches={savedSearches}
              view={view}
              scope={scope}
              searchState={restSearchState}
              draftSearch={draftSearch}
              setDraftSearch={setDraftSearch}
              onSearchStateChange={onSearchStateChange}
              latestSavedSearchName={latestSavedSearchName}
              updateLatestSavedSearchName={updateLatestSavedSearchName}
            />
          )}

          <div className="BlockFilters">
            <div
              className={classNames('BlockFilters__FilterList', {
                'active': showFiltersModal
              })}
            >
              {isSmallScreen && (
                <>
                  <div className="BlockFilters__header-modal">
                    <Title marginBottom="none">
                      Filter
                    </Title>
                    <Button
                      icon={XMarkIcon}
                      variant="text"
                      onClick={hideFiltersModal}
                    />
                  </div>
                </>
              )}
              <ul className="FilterListWrapper FilterListWrapper--multiSelect">
                {multiSelectListFilters}
              </ul>
              <ul className="FilterListWrapper FilterListWrapper--toggle">
                {toggleFilters}
              </ul>
              {isSmallScreen && (
                <Button
                  className="BlockFilters__btn-apply-filters"
                  variant="secondary"
                  onClick={hideFiltersModal}
                >
                  Apply Filters
                </Button>
              )}
            </div>
            {!isSmallScreen && (
              <div className="BlockFilters__filter">
                <Filter
                  options={availableFiltersListOptions}
                  onToggleShowFilter={onToggleShowFilter}
                  onResetShownFilters={onResetShownFilters}
                />
                {renderSaveFilter()}
              </div>
            )}
            {instantSearchFilterValuesAndClearAll}
            {isSmallScreen && renderSaveFilter()}
          </div>
        </>
      );
    }

    return (
      <ul className="FilterList">
        {toggleFilters}
        {multiSelectListFilters}
      </ul>
    );
  };

  const indexes = useMemo(() => view === 'sections' ?
      (showPrices ? SECTION_INDEXES_WITH_PRICE : SECTION_INDEXES) :
      (showPrices ? COURSE_INDEXES_WITH_PRICE : COURSE_INDEXES),
    [view, showPrices]
  );

  const t = useTranslate('CourseSearch');

  // `showPrice` property is passed through to `<CourseSearchResultComponent/>`.
  // `showPrice` property is not used in `<InstitutionSearch/>` itself.
  return (
    <InstantSearch
      indexes={indexes}
      searchFieldBanner={searchFieldBanner ? 'What course are you looking for?' : undefined}
      searchFieldLabel={searchFieldLabel === undefined ? 'Course Name' : searchFieldLabel}
      canFilterBeCleared={canFilterBeCleared}
      useFilters={useFilters}
      filterNames={FILTER_NAMES}
      ranges={view === 'sections' ? SECTION_RANGES : COURSE_RANGES}
      className={classNames(className, 'CourseSearch')}
      component={view === 'sections' ? CourseSectionSearchResultComponent : CourseSearchResultComponent}
      showPrice={showPrices}
      userInstitutionId={userInstitutionId}
      translate={t}
      newCourseSearchDesign={newCourseSearchDesign}
      isHS4CC={isHS4CC}
      {...rest} />
  );
}

CourseSearch.propTypes = {
  view: PropTypes.oneOf(['sections', 'courses']).isRequired,
  scope: PropTypes.oneOf(['all', 'own']).isRequired,
  searchFieldBanner: PropTypes.bool,
  searchFieldLabel: PropTypes.string,
  userInstitutionId: PropTypes.number,
  userInstitutionIsTeaching: PropTypes.bool,
  institutionName: PropTypes.string,
  preApproved: PropTypes.bool,
  newCourseSearchDesign: PropTypes.bool,
  enableSavedSearches: PropTypes.bool,
  savedSearchesLocalStorageKey: PropTypes.string,
  consortial: PropTypes.bool,
  consortiumName: PropTypes.string,
  courseSharingGroupIds: PropTypes.arrayOf(PropTypes.number),
  viewerCourseSharingGroupIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  viewerCourseSharingGroupNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  canDebugCourseSharingGroups: PropTypes.bool,
  showPrices: PropTypes.bool.isRequired,
  className: PropTypes.string
};

const CourseSectionSearchResultComponent = ({ children, ...rest }) => (
  <CourseSearchResult
    section={children}
    showSectionNumber
    {...rest} />
);

const CourseSearchResultComponent = ({ children, ...rest }) => (
  <CourseSearchResult
    course={children}
    showSectionNumber
    {...rest} />
);

const COURSE_RANGES = SECTION_RANGES.slice();

if (COURSE_RANGES[0].attribute === 'session.cost') {
  COURSE_RANGES[0] = {
    ...COURSE_RANGES[0],
    attribute: COURSE_ATTRIBUTES['session.cost']
  };
} else {
  throw new Error('Incorrect SECTION_RANGES in <CourseSearch/>');
}

if (COURSE_RANGES[1].fromAttributeName === 'session.startDate') {
  COURSE_RANGES[1] = {
    ...COURSE_RANGES[1],
    fromAttributeName: 'sessions.startDate'
  };
} else {
  throw new Error('Incorrect SECTION_RANGES in <CourseSearch/>');
}

if (COURSE_RANGES[1].toAttributeName === 'session.earliestEndDate') {
  COURSE_RANGES[1] = {
    ...COURSE_RANGES[1],
    toAttributeName: 'sessions.earliestEndDate'
  };
} else {
  throw new Error('Incorrect SECTION_RANGES in <CourseSearch/>');
}
