import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

// import Header from '../Header';
import SecondaryNav from '../SecondaryNav';
import RequestCourseBanner from '../RequestCourseBanner';
import { useNavSection } from '../SideNav';
import Notifications from '../Notifications';
import Breadcrumbs from '../Breadcrumbs';

import useLocation from '../../hooks/useLocation';
import { useCanViewCourses } from '../../hooks/permissions';

import normalizePath from '../../helpers/normalizePath';

import { getStore } from '../../store';

import './DefaultLayout.sass';

export default function DefaultLayout({
  margin,
  marginTop,
  getBreadcrumbs,
  children
}) {
  const location = useLocation();

  // No need to re-render breadcrumbs on Redux state updates.
  // const state = useSelector(state => state);
  const state = useMemo(() => getStore().getState(), []);

  const breadcrumbs = useMemo(() => {
    let breadcrumbs_ = [];
    if (getBreadcrumbs) {
      breadcrumbs_ = [['Home', '/']].concat(
        getBreadcrumbs(state, { location })
      );
    }
    return breadcrumbs_;
  }, [
    getBreadcrumbs,
    location,
    state
  ]);

  // const { institution } = useSelector(state => state.institutions.institution);

  // Get location pathname.
  // Trim trailing slash.
  const path = normalizePath(location.pathname);

  const user = useSelector(state => state.auth.user);

  const canViewCourses = useCanViewCourses();

  // Show "Request course" footer if it's a teaching institution page
  const showRequestCourseBanner = user && canViewCourses ? (
    // If it's the main page
    path === '/'
    ||
    // If it's the "Courses" page
    path === '/courses/'
    // ||
    // // If it's a teaching institution profile page
    // (path !== '/institutions/add/' && /\/institutions\/[^\/]+\/$/.test(path) && institution &&
    // institution.isTeaching)
  ) : undefined;

  const section = useNavSection();

  // Show tab navigation bar, if present on the page.
  if (SecondaryNav.SECTIONS[section]) {
    children = (
      <>
        <SecondaryNav/>
        {children}
      </>
    );
  }

  return (
    <div className={classNames('Layout', 'DefaultLayout')}>
      <div className={classNames('WebpageContent', {
        'WebpageContent--withVerticalMargins': margin !== false,
        'WebpageContent--noTopMargin': marginTop === false
      })}>
        {/* New enrollment requests notifications. */}
        {user && (
          <Notifications/>
        )}

        {/* Breadcrumbs. */}
        {breadcrumbs.length > 0 && (
          <div>
            <Breadcrumbs className="DefaultLayout__breadcrumbs">
              {breadcrumbs}
            </Breadcrumbs>
          </div>
        )}

        {/* Page content. */}
        <div className="main-content">
          {(margin === 'vertical' || margin === false)
            ? children
            : (
              <div>
                {children}
              </div>
            )
          }
        </div>

        {/* Footer ("Request a Course"). */}
        {showRequestCourseBanner && (
          <RequestCourseBanner/>
        )}
      </div>

      {/*<Footer/>*/}
    </div>
  );
}

DefaultLayout.propTypes = {
  margin: PropTypes.oneOf([false, 'vertical']),
  marginTop: PropTypes.oneOf([false]),
  getBreadcrumbs: PropTypes.func,
  children: PropTypes.node
};
