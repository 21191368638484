import React, { memo, useMemo } from 'react';

import { Tabs } from '@acadeum/ui';
import { useTranslate } from '@acadeum/translate';

import actions from '../../actions';

import { EnrollingGrades } from './ui/EnrollingGrades';
import { TeachingGrades } from './ui/TeachingGrades';

import { useUserCanReadHomeCourseEnrollmentGrade, useUserCanReadTeachingCourseEnrollmentGrade } from './hooks';

const { goto } = actions;

export interface GradesProps {
  institutionType: 'enrolling' | 'teaching';
}

export const Grades = memo<GradesProps>(({
  institutionType
}) => {
  const t = useTranslate('Grades');
  const canReadHomeGrades = useUserCanReadHomeCourseEnrollmentGrade();
  const canReadTeachingGrades = useUserCanReadTeachingCourseEnrollmentGrade();
  const tabs = useMemo(() => [
    ...(canReadHomeGrades ? [
      {
        title: t('Enrolling Grades'),
        key: 'enrolling',
        body: institutionType === 'enrolling' ? <EnrollingGrades/> : null
      }
    ] : []),
    ...(canReadTeachingGrades ? [
      {
        title: t('Teaching Grades'),
        key: 'teaching',
        body: institutionType === 'teaching' ? <TeachingGrades/> : null
      }
    ] : [])
  ], [institutionType, canReadHomeGrades, canReadTeachingGrades]);

  return (
    <Tabs
      title={t('Grades')}
      tabs={tabs}
      initialActiveTab={institutionType}
      onChangeTab={({ key }) => goto(`/grades/${key}`)}
    />
  );
});
