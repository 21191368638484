import tinycolor from 'tinycolor2';
import each from 'lodash/each';

import type { ColorState } from '../types';
import { rgbaToHex } from './convert';

export const toState = (data, oldHue): ColorState => {
  const color = data?.hex ? tinycolor(data.hex) : tinycolor(data);
  const hsl = color.toHsl();
  const hsv = color.toHsv();
  const rgb = color.toRgb();
  if (hsl.s === 0) {
    hsl.h = oldHue || 0;
    hsv.h = oldHue || 0;
  }

  return {
    hsl,
    hex: rgbaToHex(rgb),
    rgb,
    hsv,
    oldHue: data?.h || oldHue || hsl.h
  };
};

export const simpleCheckForValidColor = (data) => {
  const keysToCheck = ['r', 'g', 'b', 'a', 'h', 's', 'l', 'v'];
  let checked = 0;
  let passed = 0;
  each(keysToCheck, (letter) => {
    if (data[letter]) {
      checked += 1;
      if (!isNaN(data[letter])) {
        passed += 1;
      }
      if (letter === 's' || letter === 'l') {
        const percentPatt = /^\d+%$/;
        if (percentPatt.test(data[letter])) {
          passed += 1;
        }
      }
    }
  });
  return (checked === passed) ? data : false;
};

export const isValidHex = (hex) => {
  if (hex === 'transparent') {
    return true;
  }
  // disable hex4 and hex8
  const lh = (String(hex).charAt(0) === '#') ? 1 : 0;
  return hex.length !== (4 + lh) && hex.length < (7 + lh) && tinycolor(hex).isValid();
};
