import { getFinancialSettingsApi } from '@acadeum/api';

import { rtkApi } from './rtkApi';

export const {
  useFetchFinancialSettingsStatusQuery,
  useFetchPrimaryPaymentSourceQuery,
  useCreatePrimaryPaymentSourceMutation,
  useUpdatePrimaryPaymentSourceMutation,
  useVerifyPrimaryPaymentSourceMutation,
  useFetchBackupPaymentSourceQuery,
  useCreateBackupPaymentSourceMutation,
  useUpdateBackupPaymentSourceMutation,
  useDeleteBackupPaymentSourceMutation
} = getFinancialSettingsApi(rtkApi);
