import merge from 'lodash/merge.js';

const MESSAGES_CACHE = {};

// Returns messages (with fallback) for a language.
// Caches the response so that it doesn't do `merge()` every time.
export function getMessagesCached(language, getMessagesWithFallback, prefix?: string) {
  if (typeof prefix === 'string') {
    if (!MESSAGES_CACHE[prefix]) {
      MESSAGES_CACHE[prefix] = {};
    }
    if (!MESSAGES_CACHE[prefix][language]) {
      MESSAGES_CACHE[prefix][language] = flattenMessages(getMessagesWithFallback(language));
    }
    return MESSAGES_CACHE[prefix][language];
  }
  if (!MESSAGES_CACHE[language]) {
    MESSAGES_CACHE[language] = flattenMessages(getMessagesWithFallback(language));
  }
  return MESSAGES_CACHE[language];
}

// Returns messages for a language.
// Substitutes all potentially missing ones with the default language messages.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function getMessagesWithFallback(language, defaultLanguage, getMessages) {
  // No need to `merge()` in case of default language messages
  // because they are the "fallback".
  if (language === defaultLanguage) {
    return getMessages(defaultLanguage);
  }
  // Substitute all potentially missing messages with the ones
  // for the default language.
  // https://github.com/formatjs/formatjs/issues/557
  return merge({}, getMessages(language), getMessages(defaultLanguage));
}

// https://formatjs.io/docs/react-intl/upgrade-guide-2x/#flatten-messages-object
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
function flattenMessages(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;
    if (typeof value === 'string' || value === null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }
    return messages;
  }, {});
}
