import type { MutableRefObject } from 'react';
import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import Fuse from 'fuse.js';

import { PlusIcon } from '@acadeum/icons';

import { Icon } from '../Icon';
import { Text } from '../Text';
import { List } from '../List';
import { Button } from '../Button';
import { HStack } from '../Stack';
import { SearchBar } from '../SearchBar';
import { BaseButton } from '../BaseButton';
import { CloseButton } from '../CloseButton';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '../Popover';

import styles from './IconPicker.module.scss';

interface IconType {
  id: string;
  name: string;
  keywords: string[];
}

interface Metadata {
  [iconId: string]: IconType;
}

export interface IconPickerProps {
  metadata: Metadata;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icons: any;
  className?: string;
  value?: string;
  onChange?: (icon: string) => void;
}

interface IconPickerRef {
  focus: () => void;
}

export const IconPicker = React.forwardRef<IconPickerRef, IconPickerProps>(({
  metadata,
  className,
  icons: propsIcons,
  value,
  onChange: propsOnChange
}, ref) => {
  const buttonRef = useRef() as MutableRefObject<HTMLButtonElement>;

  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [icons, setIcons] = useState<IconType[]>([]);
  const [selectedIcon, setSelectedIcon] = useState<string | null>(null);

  const fuse = useMemo(() => {
    return new Fuse(Object.values(metadata), {
      threshold: 0.25,
      keys: [
        { name: 'name', weight: 3 },
        { name: 'id', weight: 2 },
        { name: 'keywords', weight: 2 }
      ]
    });
  }, [metadata]);

  const getIcons = (search: string) => search
    ? fuse.search(search).map((result) => result.item)
    : Object.values(metadata);

  useEffect(() => {
    setIcons(getIcons(searchValue));
  }, [searchValue]);

  useImperativeHandle(ref, () => ({
    focus: () => buttonRef.current.focus()
  }));

  const IconValue = value && value in propsIcons ? propsIcons[value] : null;

  const onSave = () => {
    if (selectedIcon) {
      propsOnChange?.(selectedIcon);
      setOpen(false);
    }
  };

  return (
    <Popover
      open={open}
      onOpenChange={(open) => {
        setSearchValue('');
        setOpen(open);
      }}
    >
      <HStack
        gap="md"
        className={classNames(className, styles.IconPicker)}
      >
        {IconValue ? (
          <div className={styles.preview}>
            <Icon icon={IconValue}/>
          </div>
        ) : (
          <div className={styles.default}>
            <Icon icon={PlusIcon}/>
          </div>
        )}
        <div>
          <Text mb="xs">
            Icon
          </Text>
          <PopoverTrigger asChild>
            <Button variant="secondary">
              Select Icon
            </Button>
          </PopoverTrigger>
        </div>
      </HStack>

      <PopoverContent align="start" className={styles.PopoverContent}>
        <div className={styles.body}>
          <HStack justify="between" gap="md"  mb="md">
            <Text as="h4" variant="subtitle2" color="secondary">
              Select Icon
            </Text>
            <PopoverClose asChild>
              <CloseButton/>
            </PopoverClose>
          </HStack>

          <SearchBar
            value={searchValue}
            onChange={setSearchValue}
          />

          <List unstyled className={styles.iconGrid}>
            {icons.map(({ id, name }) => (
              <List.Item key={id}>
                <BaseButton
                  onClick={() => setSelectedIcon(id)}
                  onDoubleClick={onSave}
                  data-selected={selectedIcon === id}
                  className={styles.button}
                  aria-label={`Select icon ${name}`}
                  title={name}
                >
                  <Icon icon={propsIcons[id]}/>
                </BaseButton>
              </List.Item>
            ))}
          </List>
        </div>
        <HStack justify="end" gap="md" className={styles.footer}>
          <PopoverClose asChild>
            <Button variant="secondary">
              Cancel
            </Button>
          </PopoverClose>
          <Button onClick={onSave}>
            Save
          </Button>
        </HStack>
      </PopoverContent>
    </Popover>
  );
});
