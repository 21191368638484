import type { FC } from 'react';
import React from 'react';

import type { CreateCourseResource, DeepPartial } from '@acadeum/types';
import type { CreateCourseInput } from '@acadeum/api';
import { isObject } from '@acadeum/helpers';
import type { OnSubmit } from '@acadeum/ui';

import type { AddCourseFormProps as CourseFormProps, AddCourseFormValues } from '../../../../components/CourseForm';
import { AddCourseForm as CourseForm } from '../../../../components/CourseForm';

export interface AddCourseFormProps extends Pick<CourseFormProps, 'onBack' | 'cancelUrl' | 'onDemandCourseDropDateDaysAfterStartDate' | 'onDemandCourseEndDateDaysAfterStartDate'> {
  onSuccessfulUpload: () => void;
  onSubmit: OnSubmit<CreateCourseInput>;
  defaultValues?: CreateCourseInput;
}

export const AddCourseForm: FC<AddCourseFormProps> = ({
  onBack,
  defaultValues,
  onSuccessfulUpload,
  cancelUrl,
  onSubmit: onSubmitCourseUploadProps,
  onDemandCourseDropDateDaysAfterStartDate,
  onDemandCourseEndDateDaysAfterStartDate
}) => {

  const onSubmitCourseUpload: CourseFormProps['onSubmit'] = async (values, options) => {
    await onSubmitCourseUploadProps(prepareToCreateCourseValues(values), options);
    onSuccessfulUpload();
  };

  return (
    <CourseForm
      onDemandCourseDropDateDaysAfterStartDate={onDemandCourseDropDateDaysAfterStartDate}
      onDemandCourseEndDateDaysAfterStartDate={onDemandCourseEndDateDaysAfterStartDate}
      onSubmit={onSubmitCourseUpload}
      defaultValues={defaultValues && isObject(defaultValues) ? prepareToFormValues(defaultValues) : undefined}
      onBack={onBack}
      submitText={'Next'}
      cancelUrl={cancelUrl}
    />
  );
};

function prepareToCreateCourseValues({
  facultyCredentials,
  resources,
  categories,
  session,
  ...values
}: AddCourseFormValues): CreateCourseInput {

  resources = resources.concat(facultyCredentials.reduce<CreateCourseResource[]>((result, facultyCredential) => {
    if (facultyCredential.name && facultyCredential.url) {
      result.push({
        name: facultyCredential.name,
        url: facultyCredential.url,
        type: 'FACULTY_CREDENTIAL'
      });
    }
    return result;
  }, []));

  return {
    ...values,
    resources,
    categories: categories.filter(Boolean),
    sections: [
      {
        name: session.section.number,
        cost: values.cost,
        ...(values.onDemand ? null : {
          schedule: session.section.schedule,
          lastDropDate: session.lastDropDate,
          lastAddDate: session.lastAddDate,
          endDate: session.endDate,
          startDate: session.startDate
        })
      }
    ]
  };
}


function prepareToFormValues(values: CreateCourseInput): DeepPartial<AddCourseFormValues> {
  const {
    resources,
    sections,
    ...rest
  } = structuredClone(values);
  const facultyCredentials = resources?.reduce((res, item) => {
    if (item.type === 'FACULTY_CREDENTIAL') {
      res.push({ name: item.name, url: item.url });
    }
    return res;
  }, [] as AddCourseFormValues['facultyCredentials']);

  const section = sections[0];

  return {
    ...rest,
    resources,
    facultyCredentials,
    session: {
      endDate: section.endDate,
      startDate: section.startDate,
      section: {
        number: section.name,
        schedule: section.schedule
      },
      lastDropDate: section.lastDropDate,
      lastAddDate: section.lastAddDate
    }
  };
}
