import qs from 'qs';

// https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/react/
export function writeStateToUrl(state, location) {
  // if (!state || (!anyFiltersAreSet(state) && isFirstPage(state))) {
  if (!state) {
    return location.pathname;
  }
  return `${location.pathname}${writeStateToUrlQuery(state)}`;
}

/**
 * Creates a URL "search" part holding the search state.
 * Example: ?"query"="criminal"&"page"=1&"refinementList%5Bsession.course.categories%5D%5B0%5D"="Criminal%20Justice"
 * The format can't be changed and is something that Algolia InstantSearch enforces:
 * https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/react/
 * Therefore, it overwrites all other URL query parameters if they aren't part of `state`.
 * So, for any custom URL query parameters, they have to be stored in search state too.
 */
export function writeStateToUrlQuery(state) {
  // Removes "query"="" from the resulting URL.
  if (!state.query) {
    // `state` will be mutated
    state = { ...state };
    delete state.query;
  }
  // Removes "page"="1" from the resulting URL.
  if (state.page === 1) {
    // `state` will be mutated
    state = { ...state };
    delete state.page;
  }
  if (Object.keys(state).length === 0) {
    return '';
  }
  // Algolia search URL is JSON-encoded because
  // this way the value type information isn't discarded:
  // whether a value is a string, a number or a boolean.
  return '?' + qs.stringify(state, {
    encoder: object => encodeURI(JSON.stringify(object))
  });
}

/**
 * Reads search state from a URL "search" part.
 * Example: ?"query"="criminal"&"page"=1&"refinementList%5Bsession.course.categories%5D%5B0%5D"="Criminal%20Justice"
 * The format can't be changed and is something that Algolia InstantSearch enforces:
 * https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/react/
 * Therefore, it overwrites any other URL query parameters if they aren't part of `state`.
 * So, for any custom URL query parameters, they have to be stored in search state too.
 */
export function readStateFromUrl(location) {
  // An Algolia URL is a set of URL parameters
  // whose values are `JSON.stringify()`ed search filter values.
  // Alternatively, a URL might not be an Algolia one
  // meaning that `qs.parse()` with `JSON.parse()`
  // would throw a syntax error in those cases.
  // (example: "?login=true")
  // When an Algolia URL is applied it overwrites all existing
  // URL parameters, so it's either an Algolia URL or not an Algolia URL.
  try {
    let search;
    if (typeof location.search === 'string') {
      search = location.search;
    } else {
      // Next.js doesn't provide `location.search`.
      search = '?' + Object.keys(location.query)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(location.query[key]))
        .join('&');
    }
    return qs.parse(search.slice(1), {
      // `decoder` is called both for keys and values.
      // keys are `JSON.stringify(keyName)`,
      // and values are `JSON.stringify(string or boolean or number)`.
      // Algolia search URL is JSON-encoded because
      // this way the value type information isn't discarded:
      // whether a value is a string, a number or a boolean.
      decoder: string => JSON.parse(decodeURI(string))
    });
  } catch (error) {
    console.log('The URL query string is not an Instant Search state JSON object');
    return {};
  }
}
