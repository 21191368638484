import React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import { DatePicker } from '../../../DatePicker';

export const FormDateInput = ({
  name,
  defaultValue,
  rules,
  ...rest
}) => {
  const { field } = useController({ name, defaultValue, rules });

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <DatePicker {...rest} {...field}/>
  );
};

FormDateInput.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.instanceOf(Date),
  rules: PropTypes.object.isRequired
};
