import type { RowData, Row } from '@tanstack/react-table';

import { toast } from '../../toast';

import type { TableProps } from '../Table';
import { exportTableData } from './exportTableData';

export type DownloadRowFn = (options?: { successMessage?: string; errorMessage?: string; }) => Promise<void>

export type ExportRowCb<TData extends RowData> = (row: TData, id: Row<TData>['id']) => DownloadRowFn;

export const getExportRowFn = <TData extends RowData>({ exportOptions }: Pick<TableProps<TData>, 'exportOptions'>): ExportRowCb<TData> =>
  (row, id) =>
    async ({
      errorMessage = 'Couldn\'t download file',
      successMessage = 'Success! Check your downloads folder for your information.'
    } = {}) => {
      if (!exportOptions) {
        return;
      }

      const { type, fileName = 'data', exportDataColumns, fetchDataForExport } = exportOptions;
      const exportData = async () => {
        await exportTableData({
          type,
          data: !fetchDataForExport ? [row] : await fetchDataForExport([id], {
            selectedRows: [row],
            rows: [row],
            rowsIds: [id]
          }),
          fileName: `${fileName} - ${id}`,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          exportDataColumns
        });
      };
      await toast.promise(exportData(), {
        error: errorMessage,
        success: successMessage
      });
    };
