import React, { memo } from 'react';

import type {
  SeparatorProps} from '../../../Separator';
import {
  Separator
} from '../../../Separator';

export const SectionCardSeparator = memo<SeparatorProps>(({
  m = 'md',
  ...rest
}) => {
  return (
    <Separator m={m} {...rest} />
  );
});
