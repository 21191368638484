import React from 'react';

import { JSONEditor as JSONEditor_ } from '@acadeum/ui';

import './JSONEditor.sass';

let JSONEditor = (props, ref) => {
  return (
    <JSONEditor_
      ref={ref}
      className="JSONEditor__editor"
      {...props}
    />
  );
};

JSONEditor = React.forwardRef(JSONEditor);

JSONEditor.propTypes = {
};

export default JSONEditor;
