import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';

import { useTranslate } from '@acadeum/translate';
import { Page, PageLoader, Tabs } from '@acadeum/ui';
import type {
  UseDeleteUserRoleMutation,
  UseDuplicateUserRoleMutation,
  UseFetchUserRoleQuery,
  UseFetchUserRolesQuery,
  UseFetchUsersQuery,
  UseSetUsersRolesForUsersMutation,
  UseUpdateUserRoleMutation
} from '@acadeum/api';

import { useQueryParameters } from '../../../providers/useQueryParameters';
import { useOnError } from '../../../providers/useOnError';

import { DeleteRoleModal } from '../ui/DeleteRoleModal';

import GeneralTab from './ui/GeneralTab';
import PermissionTab from './ui/PermissionTab';
import UsersTab from './ui/UsersTab';
import NotificationsTab from './ui/NotificationsTab';
import { UserRoleActions } from './ui/UserRoleActions';
import { useNavigate } from '../../../providers/useNavigate';
import { useSettingsRoutes } from '../../../hooks/useSettingsRoutes';
import { useApp } from '../../../providers/useApp';

export interface UserRoleDetailsPageProps {
  roleId: number;
  useFetchUserRoleQuery: UseFetchUserRoleQuery;
  useDeleteUserRoleMutation: UseDeleteUserRoleMutation;
  useSetUsersRolesForUsersMutation: UseSetUsersRolesForUsersMutation;
  useDuplicateUserRoleMutation: UseDuplicateUserRoleMutation;
  useFetchUserRolesQuery: UseFetchUserRolesQuery;
  useUpdateUserRoleMutation: UseUpdateUserRoleMutation;
  useFetchUsersQuery: UseFetchUsersQuery;
}

export const UserRoleDetailsPage: FC<UserRoleDetailsPageProps> = ({
  roleId,
  useFetchUserRoleQuery,
  useDeleteUserRoleMutation,
  useSetUsersRolesForUsersMutation,
  useDuplicateUserRoleMutation,
  useFetchUserRolesQuery,
  useUpdateUserRoleMutation,
  useFetchUsersQuery
}) => {
  const t = useTranslate('shared-admin-ui.UserRoleDetailsPage');
  const router = useNavigate();
  const onError = useOnError();
  const { app } = useApp();
  const { getUserRoleManagementUrl, getSettingsUrl } = useSettingsRoutes();

  const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);

  useQueryParameters(({ remove }, { removeQueryParameters }) => {
    if (remove) {
      setShowDeleteRoleModal(true);
      removeQueryParameters({ remove });
    }
  });

  const {
    data: role,
    error,
    isLoading
  } = useFetchUserRoleQuery({
    id: roleId
  });

  const onDeleteRole = useCallback(() => {
    setShowDeleteRoleModal(true);
  }, []);

  const actions = useMemo(() => {
    return (
      <UserRoleActions
        role={role}
        onDeleteRole={onDeleteRole}
        useDuplicateUserRoleMutation={useDuplicateUserRoleMutation}
      />
    );
  }, [role, onDeleteRole]);

  const tabs = useMemo(() => {
    if (!role) {
      return [];
    }

    return [
      {
        key: 'general',
        title: t('general'),
        body: <GeneralTab role={role} useUpdateUserRoleMutation={useUpdateUserRoleMutation}/>
      },
      {
        key: 'permissions',
        title: t('permissions'),
        body: <PermissionTab role={role}/>
      },
      {
        key: 'users',
        title: t('users'),
        body: (
          <UsersTab
            role={role}
            useFetchUsersQuery={useFetchUsersQuery}
            useSetUsersRolesForUsersMutation={useSetUsersRolesForUsersMutation}
            useFetchUserRolesQuery={useFetchUserRolesQuery}
          />
        )
      },
      {
        key: 'notifications',
        title: 'Notifications',
        body: (
          <NotificationsTab
            role={role}
            useUpdateUserRoleMutation={useUpdateUserRoleMutation}
          />
        )
      }
    ];
  }, [role]);


  if (error) {
    return onError(error);
  }

  if (isLoading) {
    return (
      <PageLoader/>
    );
  }

  if (!role) {
    return onError(new Error('Role not found'));
  }

  return (
    <Page
      title={role.name}
      actions={actions}
      breadcrumbs={role ? [
        [t(app === 'admin' ? 'general' : 'settings', { global: true }), getSettingsUrl()],
        [t('roleManagement'), getUserRoleManagementUrl()],
        role.name
      ] : undefined}
    >
      <Tabs
        keepMounted={false}
        title={`${role.name} content`}
        tabs={tabs}
      />
      <DeleteRoleModal
        useSetUsersRolesForUsersMutation={useSetUsersRolesForUsersMutation}
        useFetchUserRolesQuery={useFetchUserRolesQuery}
        useDeleteUserRoleMutation={useDeleteUserRoleMutation}
        onAfterDelete={() => router.push(getUserRoleManagementUrl())}
        show={showDeleteRoleModal}
        onHide={setShowDeleteRoleModal}
        role={role}
      />
    </Page>
  );
};
