import type { FC } from 'react';
import React from 'react';

import { StepsProgress } from '@acadeum/ui';
import { useTranslateDictionary } from '@acadeum/translate';
import type {
  FetchBackupPaymentSourceOutput,
  FetchFinancialSettingsStatusOutput,
  FetchPrimaryPaymentSourceOutput
} from '@acadeum/api';

import { useFinancialNavigationContext } from '../../context';

import type { BankAccountStepProps } from '../BankAccountStep';
import { BankAccountStep } from '../BankAccountStep';
import type { CreditCardStepProps } from '../CreditCardStep';
import { CreditCardStep } from '../CreditCardStep';

interface StepperScreenType extends 
  Pick<BankAccountStepProps, 'useUpdatePrimaryPaymentSourceMutation' | 'useCreatePrimaryPaymentSourceMutation'>,
  Pick<CreditCardStepProps, 'useCreateBackupPaymentSourceMutation' | 'useUpdateBackupPaymentSourceMutation'>
{
  financialSettingsStatus: FetchFinancialSettingsStatusOutput;
  primaryPaymentSource?: FetchPrimaryPaymentSourceOutput;
  backupPaymentSource?: FetchBackupPaymentSourceOutput;
}

export const StepperScreen: FC<StepperScreenType> = ({
  financialSettingsStatus,
  primaryPaymentSource,
  backupPaymentSource,
  useCreatePrimaryPaymentSourceMutation, 
  useUpdatePrimaryPaymentSourceMutation,
  useCreateBackupPaymentSourceMutation,
  useUpdateBackupPaymentSourceMutation
}) => {
  const { activeStep } = useFinancialNavigationContext();

  const TABS = [
    ...(financialSettingsStatus?.paymentMethod === 'ACH' ? [
      {
        component: BankAccountStep,
        title: 'primary'
      }
    ] : []),
    {
      component: CreditCardStep,
      title: 'backup'
    }
  ];

  const STEPS = TABS.map(step => step.title);
  const tab = TABS[activeStep || 0];
  const stepNames = useTranslateDictionary('shared-admin-ui.StepperScreen.steps', STEPS);

  return (
    <>
      {TABS.length > 1 && (
        <StepsProgress
          step={tab.title}
          steps={STEPS}
          stepNames={stepNames}
          marginBottom
        />
      )}
      {tab && (
        <tab.component
          useUpdateBackupPaymentSourceMutation={useUpdateBackupPaymentSourceMutation}
          useCreateBackupPaymentSourceMutation={useCreateBackupPaymentSourceMutation}
          useCreatePrimaryPaymentSourceMutation={useCreatePrimaryPaymentSourceMutation}
          useUpdatePrimaryPaymentSourceMutation={useUpdatePrimaryPaymentSourceMutation}
          primaryPaymentSource={primaryPaymentSource}
          backupPaymentSource={backupPaymentSource}
          financialSettingsStatus={financialSettingsStatus}
        />
      )}
    </>
  );
};
