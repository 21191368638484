import type { ReactNode, ErrorInfo } from 'react';
import React from 'react';

import { Button, Text, Title } from '@acadeum/ui';

import { useNavigate } from '../../providers/useNavigate';

import ErrorImage from './error.svg';

import styles from './ErrorBoundary.module.scss';

interface ErrorBoundaryProps {
  children: ReactNode;
  goHome: () => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary_ extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error): ErrorBoundaryState {
    console.log({ error });
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.log({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.root}>
          <div className={styles.content}>
            <Title className={styles.title}>
              Oops! Something went wrong...
            </Title>
            <Text className={styles.text} color="grey">
              Sorry, something went wrong. Try again or go home.
            </Text>
            <Button
              mr="md"
              variant="secondary"
              onClick={() => this.setState({ hasError: false })}
            >
              Try again?
            </Button>
            <Button onClick={this.props.goHome}>
              Go Home
            </Button>
          </div>
          <ErrorImage className={styles.image}/>
        </div>
      );

      return (
        <div>
          <h2>Oops, there is an error!</h2>
          <button
            type="button"
            onClick={() => this.setState({ hasError: false })}
          >
            Try again?
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

function withNavigateHOC(Component: React.ComponentType<ErrorBoundaryProps>) {
  return function WithNavigateHOC(props: Omit<ErrorBoundaryProps, 'goHome'>) {
    const navigate = useNavigate();
    return (
      <Component
        {...props}
        goHome={() => navigate.push('/')}
      />
    );
  };
}

export const ErrorBoundary = withNavigateHOC(ErrorBoundary_);
