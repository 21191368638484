import type { FC, ReactNode } from 'react';
import React from 'react';

import { IntlProvider } from '@acadeum/translate';
import { LinkProvider } from '@acadeum/core-ui';
import type { LinkLikeComponent } from '@acadeum/core-ui';
import type { Locale } from '@acadeum/types';

import { DEFAULT_LANGUAGE } from '../../translate';

import type { GetCourseLink } from '../../utils/courseLink';
import { GetCourseLinkContext } from '../../utils/courseLink';

import type { GetStudentLink } from '../../utils/studentLink';
import { GetStudentLinkContext } from '../../utils/studentLink';

import type { GetInstitutionLink } from '../../utils/institutionLink';
import { GetInstitutionLinkContext } from '../../utils/institutionLink';

import type { GetInstitutionInputParams } from '../../utils/institutionAutocomplete';
import { GetInstitutionInputParamsContext } from '../../utils/institutionAutocomplete';

import type { GetConsortiumInputParams } from '../../utils/consortiumAutocomplete';
import { GetConsortiumInputParamsContext } from '../../utils/consortiumAutocomplete';

import type { UseUploadImageMutation } from '../../utils/uploadImage';
import { UseUploadImageMutationContext } from '../../utils/uploadImage';

import { RadixTooltipProvider } from '../RadixTooltip';

export interface AppProviderProps {
  children: ReactNode;
  language: Locale;
  messages: Record<string, string>;
  linkComponent: LinkLikeComponent;
  useUploadImageMutation?: UseUploadImageMutation;
  getCourseLink?: GetCourseLink;
  getStudentLink?: GetStudentLink;
  getInstitutionLink?: GetInstitutionLink;
  getInstitutionInputParams?: GetInstitutionInputParams;
  getConsortiumInputParams?: GetConsortiumInputParams;
}

export const AppProvider: FC<AppProviderProps> = ({
  children,
  language,
  messages,
  linkComponent,
  getCourseLink,
  getStudentLink,
  getInstitutionLink,
  getInstitutionInputParams,
  getConsortiumInputParams,
  useUploadImageMutation
}) => {
  return (
    <RadixTooltipProvider>
      <IntlProvider
        locale={language}
        messages={messages}
        defaultLocale={DEFAULT_LANGUAGE}
      >
        <LinkProvider linkComponent={linkComponent}>
          <GetInstitutionLinkContext.Provider value={getInstitutionLink}>
            <GetCourseLinkContext.Provider value={getCourseLink}>
              <GetStudentLinkContext.Provider value={getStudentLink}>
                <GetInstitutionInputParamsContext.Provider value={getInstitutionInputParams}>
                  <GetConsortiumInputParamsContext.Provider value={getConsortiumInputParams}>
                    <UseUploadImageMutationContext.Provider value={useUploadImageMutation}>
                      {children}
                    </UseUploadImageMutationContext.Provider>
                  </GetConsortiumInputParamsContext.Provider>
                </GetInstitutionInputParamsContext.Provider>
              </GetStudentLinkContext.Provider>
            </GetCourseLinkContext.Provider>
          </GetInstitutionLinkContext.Provider>
        </LinkProvider>
      </IntlProvider>
    </RadixTooltipProvider>
  );
};

AppProvider.displayName = 'AppProvider';
