import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  Link,
  Table,
  createTableTermFilter,
  createTableYearFilter,
  createTableSessionFilter
} from '@acadeum/ui';

import {
  getStudentRequestsTableColumns,
  useExportDataColumns
} from '../CourseRegistrationRequestsNew';

import Section from '../../components/Section';
import sortByNameAscending from '../../components/Table/sort/sortByNameAscending';

import { loadStudentRequestsPage } from '../../helpers/routes';

import actions from '../../actions';

const {
  fetchFinalizedStudentRequests,
  fetchStudentRequestsForExport
} = actions;

export default function CourseRegistrationRequestsFinalized() {
  const requests = useSelector(state => state.studentRequests.studentRequests);

  const exportDataColumns = useExportDataColumns({ finalized: true });
  const data = useMemo(() => {
    return requests.sort((a, b) => sortByNameAscending(a.student, b.student));
  }, [requests]);


  if (requests.length === 0) {
    return (
      <Section
        description="There are no finalized course registration requests from students at your institution."
      />
    );
  }

  return (
    <Section
      description="These are requests from students at your institution to take the following courses through Acadeum.">
      <Table
        id="courseRegistrationRequestsFinalized"
        hasColumnVisibility
        enableRowSelection
        enableGlobalFilter
        data={data}
        columns={columns}
        clientFilters={filters}
        exportOptions={{
          type: 'xlsx',
          fileName: 'Student Registration Requests - HI - Finalized',
          exportDataColumns,
          fetchDataForExport: (ids, { rowsIds }) =>  fetchStudentRequestsForExport(ids.length > 0 ? ids : rowsIds, { type: 'finalized' })
        }}
      />
    </Section>
  );
}

CourseRegistrationRequestsFinalized.meta = () => ({
  title: 'Student Requests — Finalized'
});

CourseRegistrationRequestsFinalized.load = async () => {
  await loadStudentRequestsPage(fetchFinalizedStudentRequests());
};

CourseRegistrationRequestsFinalized.breadcrumbs = () => [
  ['Student Requests', '/course-registration-requests'],
  'Finalized'
];

const columns =  [
  ...getStudentRequestsTableColumns(),
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => (
      <Link to={`/course-registration-requests/${row.original.id}`}>
        {row.original.status}
      </Link>
    )
  }
];

const filters = [
  createTableYearFilter(),
  createTableTermFilter(),
  createTableSessionFilter()
];
