import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Email from '../../components/Email';
import FormHeading from '../../components/FormHeading';
import { getRoleIdsFromRoleFormValues } from '../../components/RoleFormField';

import isAcadeumAdministrator from '../../helpers/isAcadeumAdministrator';
import getErrorData from '../../helpers/getErrorData';

import actions from '../../actions';

import UserForm from '../AdminEditUser/UserForm';

const {
  createUser,
  notify,
  notifyError,
  redirect
} = actions;

export default function CreateUser_() {
  const currentUser = useSelector(state => state.auth.user);
  const { userRoles } = useSelector(state => state.users);
  return (
    <CreateUser
      currentUser={currentUser}
      userRoles={userRoles}
    />
  );
}

export function CreateUser({
  userRoles,
  currentUser,
  adminPanel
}) {
  const form = useRef();

  const onSubmit = async (values) => {
    const roleIds = getRoleIdsFromRoleFormValues(values);
    if (roleIds.length < 1) {
      return notifyError('You must choose at least one role');
    }
    values.roleIds = roleIds;

    try {
      const { id } = await createUser(values);
      notify('User has been created!');
      if (adminPanel) {
        // If it's the Admin Panel page, then just reset the form,
        // so that an Acadeum Admin could perhaps add another user.
        form.current.form.current.reset();
      } else {
        redirect(`/admin/users/${id}`);
      }
    }
    catch (error) {
      if (getErrorData(error).status === 409 || getErrorData(error).code === 'email_occupied') {
        return notifyError(
          <>
            This user already exists. If the user's account was deactivated in the past and you would like to reactivate their account please email <Email address="support@acadeum.com"/> to request this.
          </>
        );
      }
      throw error;
    }
  };

  return (
    <section>
      <FormHeading>
        Create a New User
      </FormHeading>

      <UserForm
        ref={form}
        autoFocus
        userRoles={userRoles}
        adminPanel={adminPanel}
        superUser={isAcadeumAdministrator(currentUser)}
        submitText="Create"
        onSubmit={onSubmit}
        cancelLink={'/admin/users'}
      />
    </section>
  );
}

CreateUser.propTypes = {
  userRoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentUser: PropTypes.object,
  adminPanel: PropTypes.bool
};
