import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useTranslate } from '@acadeum/translate';

import TextButton from '../TextButton';
import Modal from '../Modal';

import './ConfirmationModal.scss';

export default function ConfirmationModal ({
  show,
  onHide,
  onOk,
  title,
  text,
  buttonText,
  closeButton = true,
  closeIcon,
  actions,
  overlayClassName,
  className,
  children
}) {
  const t = useTranslate();

  if (closeButton) {
    if (!buttonText) {
      buttonText = t('continue', { global: true });
    }
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      confirmation
      closeIcon={closeIcon}
      overlayClassName={overlayClassName}
      className={classNames(className, 'ConfirmationModal', {
        'ConfirmationModal--noButton': !buttonText
      })}>
      {title &&
        <h1 className="ConfirmationModal__title">
          {title}
        </h1>
      }
      {text &&
        <p className="ConfirmationModal__text">
          {text}
        </p>
      }
      {children}
      {buttonText &&
        <div className="d-flex ConfirmationModal__buttons">
          {actions}
          <TextButton
            caret
            onClick={onOk || onHide}>
            {buttonText}
          </TextButton>
        </div>
      }
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onOk: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.node,
  buttonText: PropTypes.string,
  closeButton: PropTypes.bool,
  closeIcon: PropTypes.bool,
  actions: PropTypes.node,
  overlayClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
};
