import { useSelector } from 'react-redux';
import { Link } from '@acadeum/ui';
import { DocumentViewIcon } from '@acadeum/icons';

export function ViewSyllabus({
  course
}) {
  const { user } = useSelector(state => state.auth);

  if (!user || !course.syllabusUrl) {
    return null;
  }

  return (
    <Link
      external
      to={course.syllabusUrl}
      gaLabel={`Syllabus ${course.title}`}
    >
      <DocumentViewIcon className="control-icon"/>
      View Syllabus
    </Link>
  );
}
