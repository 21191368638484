import React from 'react';
import classNames from 'classnames';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import styles from './RadixTooltip.module.scss';

export const RadixTooltipProvider = TooltipPrimitive.Provider;

export const RadixTooltip: React.FC<React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Root>> = ({
  children,
  delayDuration = 0,
  ...props
}) => (
  <TooltipPrimitive.Root
    {...props}
    delayDuration={delayDuration}
  >
    {children}
  </TooltipPrimitive.Root>
);

export const RadixTooltipTrigger = TooltipPrimitive.Trigger;

export const RadixTooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, children, ...props }, ref) => (
  <TooltipPrimitive.Portal>
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={classNames(className, styles.content)}
      {...props}
    >
      <RadixTooltipArrow/>
      {children}
    </TooltipPrimitive.Content>
  </TooltipPrimitive.Portal>
));
RadixTooltipContent.displayName = TooltipPrimitive.Content.displayName;

export const RadixTooltipArrow = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Arrow>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Arrow>
>(({ className, ...props }, ref) => (
  <TooltipPrimitive.Arrow
    ref={ref}
    className={classNames(className, styles.arrow)}
    {...props}
  />
));
RadixTooltipArrow.displayName = TooltipPrimitive.Arrow.displayName;
