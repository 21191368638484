import type { MutableRefObject } from 'react';
import { createContext } from 'react';

export interface ListboxContextProps {
  // eslint-disable-next-line
  onOptionSelect: (props: { domId: string, value: any, element: MutableRefObject<any>, disabled?: boolean }) => void;
}

export const ListboxContext = createContext<ListboxContextProps | undefined>(undefined);

export const WithinListboxContext = createContext(false);
