import React, { memo, useState } from 'react';
import classNames from 'classnames';

import {
  AcceptIcon,
  CircleXmarkSolidIcon,
  SpinnerThirdIcon,
  CircleExclamationSolidIcon,
  XMarkIcon
} from '@acadeum/icons';
import { useTimeout } from '@acadeum/hooks';

import { BaseButton } from '../../../BaseButton';

import type { ToastProps, ToastVariant } from '../../utils/types';

import styles from './Toast.module.scss';

const ANIMATION_DURATION = 300;

export const Toast: React.FC<ToastProps> = memo(({
  autoClose,
  variant,
  children,
  deleteToast,
  isLoading,
  closeOnClick
}) => {
  const icon = getIcon(variant);
  const [show, setShow] = useState(true);

  const close = () => {
    setShow(false);
    setTimeout(deleteToast, ANIMATION_DURATION);
  };

  const autoHide: number | false = typeof autoClose === 'undefined' ? autoClose : autoClose;
  useTimeout(close, autoHide === false ? null : autoHide);

  return (
    <div
      role="alert"
      aria-live="polite"
      aria-atomic="true"
      className={classNames(styles.Toast, show ? styles.enterAnimation : styles.exitAnimation)}
    >
      {(icon || isLoading) && (
        <span className={styles.iconWrapper}>
          {isLoading ? <SpinnerThirdIcon className={classNames('control-icon', styles.pendingIcon)}/> : icon}
        </span>
      )}
      <div className={styles.content}>
        {children}
      </div>
      {closeOnClick && (
        <BaseButton
          onClick={close}
          className={styles.close}
        >
          <XMarkIcon className="control-icon"/>
        </BaseButton>
      )}
    </div>
  );
});

function getIcon(variant: ToastVariant) {
  switch (variant) {
    case 'success':
      return <AcceptIcon className="control-icon"/>;
    case 'warn':
      return <CircleExclamationSolidIcon className={classNames('control-icon', styles.warnIcon)}/>;
    case 'error':
      return <CircleXmarkSolidIcon className={classNames('control-icon', styles.errorIcon)}/>;
    case 'default':
    default:
      return null;
  }
}
