import type {
  MutableRefObject,
  ReactNode,
  FC
} from 'react';
import React, {
  useId,
  useRef,
  useState,
  useCallback,
  useMemo,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import type { Modifier } from 'react-popper';
import { usePopper } from 'react-popper';
import type { Placement } from '@popperjs/core';

import { KeypressListener } from '../KeypressListener';
import { EventListener } from '../EventListener';
import { ReactPortal } from '../ReactPortal';

import { Code } from '../../types';

import styles from './UnstyledTooltip.module.scss';

export interface UnstyledTooltipProps extends React.HTMLAttributes<HTMLDivElement> {
  placement?: Placement;
  className?: string;
  triggerClassName?: string;
  tooltipClassName?: string;
  arrowClassName?: string;
  children?: ReactNode;
  tooltipContent?: ReactNode;
  arrow?: boolean;
  ariaTitle?: string;
  triggerElement?: React.ElementType;
}


export const UnstyledTooltip: FC<UnstyledTooltipProps> = ({
  placement = 'bottom',
  className,
  triggerClassName,
  tooltipClassName,
  arrowClassName,
  children,
  tooltipContent,
  arrow,
  ariaTitle,
  triggerElement: TriggerElement = 'button',
  ...rest
}) => {
  const id = useId();
  const tooltipId = id + 'description';

  const containerNodeRef = useRef() as MutableRefObject<HTMLDivElement>;
  const triggerNodeRef = useRef() as MutableRefObject<HTMLButtonElement>;
  const tooltipNodeRef = useRef() as MutableRefObject<HTMLParagraphElement>;
  const arrowNodeRef = useRef() as MutableRefObject<HTMLDivElement>;

  const [isShown, setShow] = useState(false);

  const modifiers = useMemo(() => {
    const result: Modifier<'arrow'>[] = [];
    if (arrow) {
      result.push({
        name: 'arrow',
        enabled: true,
        phase: 'main',
        options: { element: arrowNodeRef.current }
      });
    }
    return result;
  }, [arrow]);

  const {
    update: updatePopperPosition,
    styles: popperStyles,
    attributes: popperAttributes
  } = usePopper(triggerNodeRef.current, tooltipNodeRef.current, {
    strategy: 'absolute',
    placement,
    modifiers
  });

  useEffect(() => {
    if (isShown) {
      // Recalculate tooltip position after opening
      void updatePopperPosition?.();
    }
  }, [isShown]);

  const openTooltip = () => setShow(true);
  const closeTooltip = () => setShow(false);

  const onDocumentKeyDown = useCallback(() => closeTooltip(), [closeTooltip]);

  const onPointerdown = useCallback((event: Event) => {
    switch (event.target) {
      case containerNodeRef.current:
      case triggerNodeRef.current:
      case tooltipNodeRef.current:
        event.preventDefault();
        break;
      default:
        closeTooltip();
        triggerNodeRef.current.blur();
    }
  }, [closeTooltip]);

  const listeners = isShown ? (
    <>
      <KeypressListener
        handler={onDocumentKeyDown}
        code={Code.Escape}
        keyEvent="keydown"
      />
      <EventListener
        event="pointerdown"
        handler={onPointerdown}
      />
    </>
  ) : null;

  return (
    <>
      {listeners}
      <div
        {...rest}
        ref={containerNodeRef}
        className={classNames(className, styles.container)}
        onMouseEnter={openTooltip}
        onTouchStart={openTooltip}
        onMouseLeave={closeTooltip}
        data-unstyledtooltip-show={isShown}
      >
        <TriggerElement
          tabIndex={0}
          ref={triggerNodeRef}
          type={TriggerElement === 'button' ? 'button' : undefined}
          aria-describedby={tooltipId}
          aria-label={ariaTitle}
          onFocus={openTooltip}
          onBlur={closeTooltip}
          className={triggerClassName}
        >
          {children}
        </TriggerElement>
        <ReactPortal>
          <div
            ref={tooltipNodeRef}
            role="tooltip"
            id={tooltipId}
            style={{
              ...popperStyles.popper,
              display: isShown ? undefined : 'none'
            }}
            data-placement={popperAttributes?.popper?.['data-popper-placement']}
            className={tooltipClassName}
          >
            {tooltipContent}
            {arrow && (
              <div
                ref={arrowNodeRef}
                className={arrowClassName}
                style={popperStyles.arrow}
                {...popperAttributes.arrow}
                data-popper-arrow="true"
              />
            )}
          </div>
        </ReactPortal>
      </div>
    </>
  );
};

UnstyledTooltip.displayName = 'UnstyledTooltip';
UnstyledTooltip.propTypes = {
  placement: PropTypes.any,
  className: PropTypes.string,
  triggerClassName: PropTypes.string,
  tooltipClassName: PropTypes.string,
  arrowClassName: PropTypes.string,
  children: PropTypes.node,
  tooltipContent: PropTypes.node,
  arrow: PropTypes.bool,
  ariaTitle: PropTypes.string,
  triggerElement: PropTypes.any
};
