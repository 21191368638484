import type { FC } from 'react';
import React, { useEffect, useId, useRef } from 'react';
import { Root, Trigger, Portal, Content } from '@radix-ui/react-popover';
import classNames from 'classnames';

import { CaretDownIcon } from '@acadeum/icons';

import { Radio } from '../Radio';
import { CurrencyInput, formatCurrency, parseCurrency } from '../CurrencyInput/CurrencyInput';

import { useTranslate } from '@acadeum/translate';

import styles from './Price.module.scss';

interface PriceValue {
  min?: number;
  max?: number;
}

type TypePriceMode = 'range' | 'any';

export interface PriceProps extends PriceValue {
  mode: TypePriceMode,
  onChangeMode: (mode: TypePriceMode) => void;
  value: PriceValue;
  onChange: (value: PriceValue) => void;
  className?: string;
  label: string;
}

const ANY_VALUE: PriceValue = { min: undefined, max: undefined };

/**
 * TODO: Remove this component in the future and use `PriceInput` component from `@acadeum/ui` package instead.
 * @deprecated Use `PriceInput` component from `@acadeum/ui` package instead.
 * */
export const Price: FC<PriceProps> = ({
  value = ANY_VALUE,
  mode,
  onChangeMode,
  onChange: onChange_,
  min,
  max,
  className,
  label
}) => {
  const t = useTranslate('ui.Price');
  const id = useId();
  const name = id + 'price';

  const refMin = useRef() as React.MutableRefObject<HTMLInputElement>;
  const refMax = useRef() as React.MutableRefObject<HTMLInputElement>;

  const onChange = () => {
    if (mode === 'any') {
      onChange_(ANY_VALUE);
    } else if (refMin.current) {
      onChange_({
        min: parseCurrency(refMin.current.value),
        max: parseCurrency(refMax.current.value)
      });
    }
  };

  const prevMode = useRef<TypePriceMode>(mode);

  // Calls `onChange()` when `mode` property changes.
  useEffect(() => {
    if (prevMode.current !== mode) {
      if (mode === 'range') {
        if (typeof min === 'number') {
          refMin.current.value = formatCurrency(min, true);
        }
        if (typeof max === 'number') {
          refMax.current.value = formatCurrency(max, true);
        }
      }
      onChange();
      prevMode.current = mode;
    }
  }, [mode]);

  useEffect(() => {
    if (refMin.current) {
      refMin.current.value = formatCurrency(value.min, true);
    }
    if (refMax.current) {
      refMax.current.value = formatCurrency(value.max, true);
    }
  }, [value]);

  const onSelectValue = (event) => {
    onChangeMode(event.target.value);
  };

  const checkingValuesField = () => {
    if (typeof min !== 'number' || typeof max !== 'number') {
      return;
    }
    const minValue = parseCurrency(refMin.current.value);
    const maxValue = parseCurrency(refMax.current.value);

    if (maxValue < minValue && minValue <= max) {
      refMax.current.value = formatCurrency(minValue, true);
      refMin.current.value = formatCurrency(maxValue, true);
    }

    if (parseCurrency(refMax.current.value) > max) {
      refMax.current.value = formatCurrency(max, true);
    } else if (parseCurrency(refMax.current.value) < min) {
      refMax.current.value = formatCurrency(min, true);
    }

    if (parseCurrency(refMin.current.value) < min) {
      refMin.current.value = formatCurrency(min, true);
    } else if (parseCurrency(refMin.current.value) > max) {
      refMin.current.value = formatCurrency(max, true);
    }
  };

  const onBlurMin = (event) => {
    if (!event.target.value) {
      event.target.value = formatCurrency(min, true);
    } else {
      checkingValuesField();
    }
    onChange();
  };

  const onBlurMax = (event) => {
    if (!event.target.value) {
      event.target.value = formatCurrency(max, true);
    } else {
      checkingValuesField();
    }
    onChange();
  };

  return (
    <Root>
      <Trigger className={classNames(styles.button, className)}>
        {label}
        <CaretDownIcon className={styles.icon}/>
      </Trigger>
      <Portal>
        <Content className={styles.body} sideOffset={4}>
          <Radio
            name={name}
            label={t('anyPrice')}
            onChange={onSelectValue}
            value={'any'}
            className={styles.radioWrap}
            checked={mode === 'any'}
          />
          <Radio
            name={name}
            label={t('priceRange')}
            onChange={onSelectValue}
            value={'range'}
            className={styles.radioWrap}
            checked={mode === 'range'}
          />
          {mode === 'range' && (
            <>
              <label htmlFor={`${id}_min`} className={styles['label-field-input']}>
                {t('from')}
              </label>
              <CurrencyInput
                ref={refMin}
                defaultValue={formatCurrency(value.min, true)}
                onBlur={onBlurMin}
                currency="USD"
                id={`${id}_min`}
                placeholder={t('eg')}
                min={min}
                max={max}
              />

              <label htmlFor={`${id}_max`} className={styles['label-field-input']}>
                {t('to')}
              </label>
              <CurrencyInput
                ref={refMax}
                defaultValue={formatCurrency(value.max, true)}
                onBlur={onBlurMax}
                id={`${id}_max`}
                currency="USD"
                placeholder={t('eg')}
                min={min}
                max={max}
              />
            </>
          )}
        </Content>
      </Portal>
    </Root>
  );
};
