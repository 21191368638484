import React, { useState } from 'react';
import type { FC } from 'react';

import type { Institution, Student } from '@acadeum/types';

import { useTranslate } from '@acadeum/translate';

import { Button, DataBlock, Email, Text, Phone } from '@acadeum/ui';

import { formatCountry, formatGender, formatEthnicity, formatRaces } from '../../../../helpers/format';

import SectionDate from '../SectionDate';

import { CaretDownIcon } from '@acadeum/icons';

import styles from './InfoStudent.module.scss';

export interface StudentHaveInstitution extends Student {
  institution: Institution;
}

interface InfoStudentProps {
  data: StudentHaveInstitution;
}

const InfoStudent: FC<InfoStudentProps> = ({ data }) => {
  const t = useTranslate('EnrollmentRequests');

  const [showAllInfo, setShowAllInfo] = useState<boolean>();

  const onShow = () => {
    setShowAllInfo(true);
  };

  const personalInformation = [
    {
      title: t('student'),
      content: (
        <>
          <DataBlock type="user" user={data}/>
          <Text color="grey" variant="bodyMd">
            ID: {data.id}
          </Text>
        </>
      )
    },
    {
      title: t('studentEmail'),
      content: <Email address={data.email}/>
    },
    {
      title: t('dateOfBirth'),
      content: data.dob && <DataBlock type="date" date={data.dob} utc month="long"/>
    },
    {
      title: t('uSCitizen'),
      content: typeof data.citizenship === 'boolean' ? data.citizenship ? t('yes') : t('no') : 'Unknown'
    },
    {
      title: t('gender'),
      content: formatGender(data.gender)
    },
    {
      title: t('ethnicity'),
      content: data.ethnicity ? formatEthnicity(data.ethnicity) : '-'
    },
    {
      title: t('race'),
      content: data.races ? formatRaces(data.races, { separator: '\n' }): '-'
    }
  ];

  const academicInformation = [
    {
      title: t('homeInstitution'),
      content: <DataBlock hideLogo type="institution" institution={data.institution}/>
    },
    {
      title: t('level'),
      content: data.level
    },
    {
      title: t('major'),
      content: data.major
    },
    {
      title: t('startDate'),
      content: data.startDate && <DataBlock type="date" date={data.startDate} utc month="long"/>
    },
    {
      title: t('advisorName'),
      content: data.advisorName
    },
    {
      title: t('advisorEmail'),
      content: data.advisorEmail
    }
  ];

  const contactInformation = [
    {
      title: t('country'),
      content: formatCountry(data.country)
    },
    {
      title: t('telephone'),
      content: data.phone && <Phone number={data.phone}/>
    },
    {
      title: t('streetAddress1'),
      content: data.addressLine1
    },
    {
      title: t('streetAddress2'),
      content: data.addressLine2
    },
    {
      title: t('city'),
      content: data.city
    }, {
      title: t('state'),
      content: data.state
    }, {
      title: t('zip'),
      content: data.postalCode
    }, {
      title: t('residency'),
      content: data.residency
    }
  ];

  return (
    <div className={styles.InfoStudent}>
      <Text variant="subtitle2" className={styles.InfoStudent__title}>
        {t('personalInformation')}
      </Text>
      {personalInformation.map((_, index) => (
        <SectionDate title={_.title} key={index}>
          {_.content}
        </SectionDate>
      ))}
      {showAllInfo ? (
        <>
          <hr/>
          <Text variant="subtitle2" className={styles.InfoStudent__title}>
            {t('academicInformation')}
          </Text>
          {academicInformation.map((_, index) => (
            <SectionDate title={_.title} key={index}>
              {_.content}
            </SectionDate>
          ))}
          <hr/>
          <Text variant="subtitle2" className={styles.InfoStudent__title}>
            {t('contactInformation')}
          </Text>
          {contactInformation.map((_, index) => (
            <SectionDate title={_.title} key={index}>
              {_.content}
            </SectionDate>
          ))}
        </>
      ) : (
        <Button variant="text-inline" onClick={onShow} suffixIcon={CaretDownIcon}>
          {t('seeMore')}
        </Button>
      )}
    </div>
  );
};
export default InfoStudent;
