// Converts `Error` instance into a plain JSON object
// for storing it in Redux state.

type GetErrorData = (error) => ({
  message?: string;
  status?: number;
  code?: string;
  field?: string;
  type?: string;
  // eslint-disable-next-line
  [key: string]: any;
})

export const getErrorData: GetErrorData = (error) => {
  const {
    status,
    message,
    data
  } = error;

  let errorData = {
    status,
    message
  };

  // `data` is a standard property of an HTTP response.
  // It is set by `react-website`'s `httpClient`.
  // When HTTP response content type is `application/json`
  // then `data` is the response JSON object.
  if (data) {
    const {
      // Sometimes AWS Lambda throws its own errors like "response body too large"
      // or code syntax error or rate limit exceeded, etc.
      // Those errors have an "errorMessage" property.
      errorMessage,
      ...rest
    } = data;
    errorData = {
      ...errorData,
      ...rest
    };
    if (errorMessage) {
      errorData.message = errorMessage;
    }
  }

  return errorData;
};
