import React, { memo } from 'react';

import { EyeDropperIcon } from '@acadeum/icons';

import { BaseButton } from '../../../BaseButton';

import styles from './EyeDropperButton.module.scss';

export interface EyeDropperButtonProps {
  onClick: () => void;
}

export const EyeDropperButton = memo<EyeDropperButtonProps>(({
  onClick
}) => {
  return (
    <BaseButton onClick={onClick} className={styles.EyeDropperButton}>
      <EyeDropperIcon className="control-icon"/>
    </BaseButton>
  );
});
