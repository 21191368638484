import React from 'react';
import PropTypes from 'prop-types';

import './FormHeader.scss';

export default function FormHeader ({ children }) {
  return (
    <div className="FormHeader">
      {children}
    </div>
  );
}

FormHeader.propTypes = {
  children: PropTypes.node.isRequired
};
