import { formatContactPhone, formatUserName } from './format';

export function prefillZendeskChatWidget(user) {
  const userInfo = {
    name: {
      value: formatUserName(user),
      readOnly: true
    },
    email: {
      value: user.email,
      readOnly: true
    }
  };

  if (user.phone) {
    userInfo.phone = {
      value: formatContactPhone(user),
      readOnly: true
    };
  }

  zE('webWidget', 'prefill', userInfo);
}

export function unprefillZendeskChatWidget() {
  zE('webWidget', 'prefill', {});
}
