export const getIsNotFoundError = (errorData) => errorData && errorData.status === 404;

export const getIsNotAuthenticatedError = (errorData) => errorData && (
  errorData.status === 401 ||
  errorData.code === 'access_token_expired' ||
  errorData.code === 'access_token_invalid' ||
  (
    // `value` contains the inactive user ID when the action was performed
    // not by the user themselves, but rather by an Acadeum admin.
    !errorData.value &&
    (errorData.code === 'account_inactive' || errorData.code === 'institution_inactive')
  )
);

export const getIsMaintenanceError = (errorData) => errorData && errorData.status === 503;

export const getIsInvalidRequest = (errorData) => {
  return errorData &&
    errorData.status &&
    errorData.status >= 400 &&
    errorData.status < 500 &&
    errorData.status !== 401 &&
    errorData.status !== 404;
};
