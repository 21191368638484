const MARGIN = 16;

export default function scrollTo (element, { margin, duration } = {}) {
  const header = document.querySelector('.Header');
  return _scrollToY(
    (element.getBoundingClientRect().top + window.pageYOffset) - header.clientHeight - (margin ? MARGIN : 0),
    duration
  );
}

function _scrollToY(y, duration) {
  // The built-in `.scrollTo()` function doesn't return a `Promise`.
  // window.scrollTo({
  //   top: y,
  //   behavior: 'smooth'
  // });

  const el = document.documentElement;
  const left = 0;
  const top = y;

  return new Promise((resolve) => {
    smoothScroll(
      el,
      left,
      top,
      duration,
      undefined, // overrides.ease,
      () =>
        resolve()
    );
  });
}

// Copy-pasted from:
// https://github.com/stipsan/smooth-scroll-into-view-if-needed/blob/master/src/index.ts

// Memoize so we're much more friendly to non-dom envs
let memoizedNow;
const now = () => {
  if (!memoizedNow) {
    memoizedNow =
      'performance' in window ? performance.now.bind(performance) : Date.now;
  }
  return memoizedNow();
};

function step(context) {
  const time = now();
  const elapsed = Math.min((time - context.startTime) / context.duration, 1);
  // apply easing to elapsed time
  const value = context.ease(elapsed);

  const currentX = context.startX + (context.x - context.startX) * value;
  const currentY = context.startY + (context.y - context.startY) * value;

  context.method(currentX, currentY);

  // scroll more if we have not reached our destination
  if (currentX !== context.x || currentY !== context.y) {
    requestAnimationFrame(() => step(context));
  } else {
    // If nothing left to scroll lets fire the callback
    context.cb();
  }
}

function smoothScroll(
  el,
  x,
  y,
  duration = 600,
  ease = t => 1 + --t * t * t * t * t,
  cb
) {
  // define scroll context
  const scrollable = el;
  const startX = el.scrollLeft;
  const startY = el.scrollTop;
  const method = (x, y) => {
    // Use Element.scroll if it exists, as it is potentially better performing.
    // Use ceil to include the the fractional part of the number for the scrolling.
    el.scrollLeft = Math.ceil(x);
    el.scrollTop = Math.ceil(y);
  };

  // scroll looping over a frame if needed
  step({
    scrollable,
    method,
    startTime: now(),
    startX,
    startY,
    x,
    y,
    duration,
    ease,
    cb
  });
}
