import React, { memo, useId } from 'react';
import classNames from 'classnames';

import styles from './Loader.module.scss';

export interface LoaderProps {
  className?: string;
  variant?: 'spinner' | 'spinnerSmall' | 'gradientSpinner' | 'logo' | 'linear';
  isDeterminate?: boolean;
  isImposter?: boolean;
  inline?: boolean;
  topOffset?: 'small' | 'large';
}

export const Loader = memo<LoaderProps>(({
  className,
  variant = 'spinner',
  isDeterminate,
  isImposter,
  topOffset,
  inline
}) => {
  return (
    <div
      className={classNames(styles.Loader, className, {
        [styles.Spinner]: ['spinner', 'spinnerSmall'].includes(variant),
        [styles.SpinnerSmall]: variant === 'spinnerSmall',
        [styles.GradientSpinner]: variant === 'gradientSpinner',
        [styles.Logo]: variant === 'logo',
        [styles.Linear]: variant === 'linear',
        [styles[`topOffset-${topOffset}`]]: topOffset,
        [styles.isDeterminate]: isDeterminate,
        [styles.isImposter]: isImposter,
        [styles.isSticky]: !inline
      })}
    >
      {variant === 'logo' && (
        <LogoProgress/>
      )}
      {variant === 'gradientSpinner' && (
        <div className={styles.GradientSpinner__line}/>
      )}
    </div>
  );
});

const LogoProgress = () => {
  const clipPathId = useId();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
      <defs>
        <clipPath id={clipPathId}>
          <rect x="0" y="0" width="52" height="52"/>
        </clipPath>
      </defs>
      <g opacity=".5">
        <g>
          <g opacity=".8">
            <path fill="currentColor" d="M51.276 25.86L25.66.113v25.745z"/>
          </g>
          <g>
            <path fill="currentColor" d="M51.276 25.859L25.66 51.604V25.86z"/>
          </g>
          <g opacity=".3">
            <path fill="currentColor" d="M0 12.986L25.66.114v25.745z"/>
          </g>
          <g opacity=".3">
            <path fill="currentColor" d="M0 38.732l25.66-12.873v25.745z"/>
          </g>
          <g opacity=".3">
            <path fill="currentColor" d="M0 38.732L25.66.114v51.49z"/>
          </g>
          <g opacity=".3">
            <path fill="currentColor" d="M0 12.986l25.66 38.618V.114z"/>
          </g>
        </g>
      </g>
      <g clipPath={`url(#${clipPathId})`}>
        <g>
          <g opacity=".8">
            <path fill="currentColor" d="M51.276 25.86L25.66.113v25.745z"/>
          </g>
          <g>
            <path fill="currentColor" d="M51.276 25.859L25.66 51.604V25.86z"/>
          </g>
          <g opacity=".3">
            <path fill="currentColor" d="M0 12.986L25.66.114v25.745z"/>
          </g>
          <g opacity=".3">
            <path fill="currentColor" d="M0 38.732l25.66-12.873v25.745z"/>
          </g>
          <g opacity=".3">
            <path fill="currentColor" d="M0 38.732L25.66.114v51.49z"/>
          </g>
          <g opacity=".3">
            <path fill="currentColor" d="M0 12.986l25.66 38.618V.114z"/>
          </g>
        </g>
      </g>
    </svg>
  );
};
