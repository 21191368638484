import React, { useEffect, useRef } from 'react';

import { waitForJob } from '@acadeum/helpers';

import Field from '../../components/Field';
import Form from '../../components/Form';
import FormSubmit from '../../components/FormSubmit';
import FormHeading from '../../components/FormHeading';
import Row from '../../components/Row';
import Col from '../../components/Col';

import actions from '../../actions';

const {
  runJob,
  getJobStatus,
  notify,
  notifyError
} = actions;

export default function UpdateAlgolia() {
  const stopPollingJobStatus = useRef();

  useEffect(() => {
    return () => {
      if (stopPollingJobStatus.current) {
        stopPollingJobStatus.current();
      }
    };
  }, []);

  async function onSubmit(values) {
    let ids;
    if (values.ids) {
      ids = values.ids.split(',').map(_ => _.trim()).filter(_ => _);
      if (ids.length === 0) {
        ids = null;
      } else {
        ids = ids.map((id) => {
          const _id = parseInt(id);
          if (id !== String(_id)) {
            throw new Error(`Invalid id: ${id}`);
          }
          if (_id === 0) {
            throw new Error('An ID can\'t be 0');
          }
          return _id;
        });
      }
    }

    try {
      const { coursesCount } = await waitForJob(
        await runJob('updateAlgoliaSectionsAndCourses', {
          courseIds: ids
        }),
        getJobStatus,
        (cancel) => stopPollingJobStatus.current = cancel
      );
      notify(`Updated ${coursesCount} course${coursesCount === 1 ? '' : 's'}`);
    } catch (error) {
      console.error(error);
      notifyError('Couldn\'t update Aloglia courses');
    }
  }

  return (
    <section>
      <FormHeading>
        Update Algolia Courses (along with Sessions and Sections)
      </FormHeading>

      <p style={{ marginBottom: '2rem' }}>
        Note: This page is for "special occasions" when something's not working and there's a need to force a manual course refresh. If you find yourself using this page then you're supposed to create a ticket with a description of why exactly did you have to use the manual course update procedure. All user actions are logged.
      </p>

      <Form
        autoFocus
        className="form"
        onSubmit={onSubmit}>

        <Row alignChildren="bottom">
          <Field
            required
            name="ids"
            label="Course IDs (comma-separated)"
            col={10}/>

          <Col col={2} xs={12}>
            <FormSubmit className="form__button--inline-except-xs form__button--wide">
              Update
            </FormSubmit>
          </Col>
        </Row>
      </Form>
    </section>
  );
}

UpdateAlgolia.meta = () => ({
  title: 'Update Algolia Courses'
});

UpdateAlgolia.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Update Algolia Courses'
];
