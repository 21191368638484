import React, { useMemo } from 'react';

import { useTranslate } from '@acadeum/translate';
import { SUPPORT_EMAIL } from '@acadeum/helpers';
import { Email, Link } from '@acadeum/ui';

import AcadeumLogo from './AcadeumLogo.svg';

import styles from './Footer.module.scss';

export interface AppFooterProps {
  minimized?: boolean;
}

export const Footer: React.FC<AppFooterProps> = ({
  minimized
}) => {
  const t = useTranslate('shared-admin-ui.Footer');

  const menu = useMemo(() => [
    {
      title: t('termsOfUse'),
      url: 'https://acadeum.com/terms-of-use/'
    },
    {
      title: t('privacyPolicy'),
      url: 'https://acadeum.com/privacy-policy/'
    }
  ], []);

  const renderMenu = Array.isArray(menu) && (
    <nav aria-label="Footer menu" className={styles.Footer__menu}>
      {menu.map((item, index) => (
        <React.Fragment key={index}>
          <Link to={item.url} monochrome>
            {item.title}
          </Link>
          {index !== menu.length - 1 && (
            <span>|</span>
          )}
        </React.Fragment>
      ))}
    </nav>
  );

  return (
    <footer className={styles.Footer}>
      {!minimized && (
        <div className={styles.Footer__body}>
          <div>
            <AcadeumLogo className={styles.Footer__logo} aria-label={t('acadeumLogo')}/>
            <p>
              {t('address.line1')} {t('address.line2')}
              <br/>
              {t('address.city')}, {t('address.state')} {t('address.postalCode')}
            </p>
          </div>

          <div className={styles.Footer__columnRight}>
            {renderMenu}
            <Email
              address={SUPPORT_EMAIL}
              gaLabel="Email Info Footer"
              removeUnderline={false}
              monochrome
            />
          </div>
        </div>
      )}

      <div className={styles.Footer__copy}>
        <p>
          {t('copyright', {
            year: new Date().getFullYear()
          })}
        </p>
        {minimized && renderMenu}
      </div>
    </footer>
  );
};
