import { getConsortiumOptionByValue, getConsortiumOptionsByName } from './consortia';

export default function getConsortiumInputParams() {
  return {
    async: true,
    fetchOptions: async (name) => {
      return await getConsortiumOptionsByName(name);
    },
    findOptionByValue: async (id) => {
      return await getConsortiumOptionByValue(id);
    }
  };
}
