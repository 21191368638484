import React from 'react';
import classNames from 'classnames';

import type { Options } from '@acadeum/types';

import { BaseButton } from '../BaseButton';

import styles from './ToggleButton.module.scss';

export interface ToggleButtonProps<T> {
  options: Options<T>;
  label: string;
  value: T;
  className?: string;
  onChange: (value: T) => void;
}

export const ToggleButton = <T extends string>({
  options,
  label,
  value,
  className,
  onChange
}: ToggleButtonProps<T>) => {
  const onClick = (value) => () => {
    onChange(value);
  };

  return (
    <div className={classNames(className, styles.ToggleButton)} role="group" aria-label={label}>
      {options.map(option => (
        <BaseButton
          key={option.value}
          onClick={onClick(option.value)}
          aria-pressed={option.value === value}
          className={styles.ToggleButton__btn}
        >
          {option.label}
        </BaseButton>
      ))}
    </div>
  );
};
