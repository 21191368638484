import React from 'react';

import FormModal from '../FormModal';

export default function ConfirmActionModal(props) {
  return (
    <FormModal {...props}>
      {' '}
    </FormModal>
  );
}
