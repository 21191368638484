import { saveAs } from 'file-saver';

// Excel incorrectly imports UTF8-encoded CSV files as "Western European" ones,
// which results in some characters being weirdly encoded.
// http://www.openforis.org/support/questions/279/wrong-characters-display-when-exporting-files-to-csv-from-collect
// The internet says that adding a "UTF-8 BOM" mark could fix that bug in Excel.
// https://www.ibm.com/support/pages/attempting-view-utf-8-characters-exported-csv-excel-results-garbled-characters
// https://stackoverflow.com/questions/17879198/adding-utf-8-bom-to-string-blob
const UTF8_BOM = '\ufeff';

// Downloads data as a CSV file.
// `items` is an array of JSON objects.
// eslint-disable-next-line
export function downloadCSV({ data, columns, fileName }: { data: any[]; columns: any[]; fileName?: string  }) {
  const rows = [columns.map(_ => _.title)].concat(data.map(row => columns.map(_ => convertValue(_.value(row)))));

  // Generate CSV by concatenating columns with commas
  const csv = rows.map(row => row.join(',')).join('\n');

  // Download CSV as a file
  const blob = new Blob([UTF8_BOM + csv], { type: 'text/plain;charset=utf-8' });
  saveAs(blob, fileName);
}

function quoteIfRequired(value) {
  if (value.indexOf(',') >= 0 || value.indexOf('"') >= 0) {
    return '"' + value.replace(/"/g, '""') + '"';
  }
  return value;
}

function convertValue(value) {
  // Output empty/missing values as empty strings.
  if (value === undefined || value === null) {
    return '';
  }
  if (typeof value === 'number' || typeof value === 'boolean') {
    return String(value);
  }
  if (value instanceof Date) {
    // Convert dates to `yyyy-MM-dd hh:mm:ss` format.
    // https://stackoverflow.com/questions/804118/best-timestamp-format-for-csv-excel
    return value.toISOString().replace('T', ' ').replace(/\..+$/, '');
  }
  // Convert several sequential whitespaces to a single whitespace.
  value = String(value).replace(/\s+/g, ' ');
  // Escape commas and double quotes.
  return quoteIfRequired(value);
}
