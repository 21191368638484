import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ChevronDownIcon } from '@acadeum/icons';

import type { CheckboxProps } from '../Checkbox';
import { Checkbox } from '../Checkbox';

import styles from './ToggleCard.module.scss';

export interface ToggleCardProps extends Pick<CheckboxProps, 'label' | 'onChange' | 'checked' | 'disabled'> {
  children?: React.ReactNode;
}

export const ToggleCard: React.FC<ToggleCardProps> = (({
  children,
  checked,
  ...rest
}) => {
  return (
    <div className={styles.ToggleCard}>
      <div className={styles.header}>
        <Checkbox
          {...rest}
          type="switch"
          checked={checked}
        />
        <ChevronDownIcon className={classNames(styles.ChevronDownIcon, {
          [styles.isOpen]: checked
        })}/>
      </div>
      {children && checked && (
        <div className={styles.body}>
          {children}
        </div>
      )}
    </div>
  );
});

ToggleCard.displayName = 'ToggleCard';
ToggleCard.propTypes = {
  children: PropTypes.node
};
