import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const updateEnrollmentState = redux.simpleAction(
  (state, enrollmentState) => ({
    ...state,
    enrollmentState: {
      ...state.enrollmentState,
      ...enrollmentState
    }
  })
);

export const resetEnrollmentState = redux.simpleAction(
  (state) => ({
    ...state,
    enrollmentState: undefined
  })
);

export const createEnrollmentRequest = redux.action(
  ({
    sectionId,
    courseSubstituteId,
    studentIds,
    reasons
  }) => (http) => {
    Intercom('trackEvent', 'add enrollment request', {
      section: sectionId,
      seats: studentIds.length,
      studentlist: JSON.stringify(studentIds),
      reasons
    });
    return http.post('/enrollment-requests', {
      sectionId,
      courseSubstituteId,
      studentIds,
      reasons
    });
  }
);

export default redux.reducer();
