export default function formatDateYearMonthDayUtc(date) {
  return `${leftPadWithZeroes(date.getFullYear(), 4)}-${leftPadWithZeroes(date.getMonth() + 1, 2)}-${leftPadWithZeroes(date.getDate(), 2)}`;
}

export function leftPadWithZeroes(number, places) {
  let padding = ''
  while (places > 0) {
    if (number < tenInAPowerOf(places - 1)) {
      padding += '0'
    } else {
      break
    }
    places--
  }
  return padding + number
}

/**
 * Calculates 10^p.
 * @param  {number} p
 * @return {number}
 */
function tenInAPowerOf(p) {
  // It's not clear how fast `Math.pow()` is.
  // Perhaps several instructions.
  // Introduced a basic `switch` optimization for the case of date formatting.
  //
  // They also say that:
  // "For powers at 10³³ and above, `Math.pow()` may lose precision"
  // https://stackoverflow.com/questions/6286589/raise-10-to-a-power-in-javascript-are-there-better-ways-than-this
  //
  switch (p) {
    case 1:
      return 10
    case 2:
      return 100
    case 3:
      return 1000
    case 4:
      return 10000
    default:
      return Math.pow(10, p)
  }
}
