import LABELS_EN from '../messages/en/Gender.json' assert { type: 'json' };

export default function formatGender(gender, { language }) {
  const LABELS = LABELS_EN;
  switch (gender) {
    case true:
      return LABELS.male;
    case false:
      return LABELS.female;
    case null:
      return LABELS.other;
  }
}
