import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Loader } from '../Loader';
import type { ModalProps } from '../Modal';
import { Modal } from '../Modal';
import { Title } from '../Title';
import { Text } from '../Text';

import styles from './LoadingModal.module.scss';

export interface LoadingModalProps extends Partial<Pick<ModalProps, 'title'>> {
  show?: boolean;
  children?: React.ReactNode;
  modalBodyClassName?: string;
}

export const LoadingModal: React.FC<LoadingModalProps> = ({
  show,
  title = 'In progress...',
  children = 'Please wait, it can take a few seconds.',
  modalBodyClassName
}) => {
  return (
    <Modal
      centered
      noHeader
      show={show}
      backdrop="static"
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onHide={() => {}}
    >
      <Modal.Body className={classNames(modalBodyClassName, styles.ModalBody)}>
        <Loader variant="gradientSpinner"/>
        <Title level={3} className={styles.Title}>
          {title}
        </Title>
        <Text as="div">
          {children}
        </Text>
      </Modal.Body>
    </Modal>
  );
};

LoadingModal.prototype = {
  show: PropTypes.bool,
  children: PropTypes.node,
  modalBodyClassName: PropTypes.string
};
