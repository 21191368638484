import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useTranslate } from '@acadeum/translate';
import { InfoTooltip } from '@acadeum/ui';

import type { UserRolePermissionSubject } from '@acadeum/types';

interface ErrorLineBadgeProps {
  subject: UserRolePermissionSubject;
  isSelected?: boolean;
}

export const ErrorLineBadge: React.FC<ErrorLineBadgeProps> = ({
  subject,
  isSelected
}) => {
  const t = useTranslate('shared-admin-ui.ErrorLineBadge');

  const { watch } = useFormContext();
  const permissionValues = watch(subject);
  const isEmptyValues = permissionValues && Object.values(permissionValues).filter(_ => _).length === 0;

  if (isSelected && isEmptyValues) {
    return (
      <InfoTooltip
        placement="left"
        variant="error"
        children={t('selectMin1Option')}
        ariaTitle="Row error"
      />
    );
  }

  return null;
};
