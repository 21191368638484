import { userHasPermission } from '@acadeum/helpers';
import { UserDetailsPage } from '@acadeum/shared-admin-ui';

import type { AppPage } from '../../helpers/app.types';

import { useFetchContactsQuery, useUpdateContactMutation } from '../../api/contacts';
import {
  useActivateUserMutation,
  useDeactivateUserMutation,
  useFetchUserLogQuery,
  useFetchUserQuery,
  useFetchUsersQuery,
  useUpdateUserMutation
} from '../../api/users';
import { useFetchUserRolesQuery } from '../../api/userRoles';
import {
  useAdminRequestUserEmailVerificationMutation,
  useAdminRequestUserPasswordResetMutation
} from '../../api/admin/users';

interface UserDetailsProps {
  userId: string;
}

const UserDetails: AppPage<UserDetailsProps> = ({ userId }) => {
  return (
    <UserDetailsPage
      userId={Number(userId)}
      useFetchUsersQuery={useFetchUsersQuery}
      useDeactivateUserMutation={useDeactivateUserMutation}
      useUpdateContactMutation={useUpdateContactMutation}
      useFetchUserLogQuery={useFetchUserLogQuery}
      useUpdateUserMutation={useUpdateUserMutation}
      useFetchContactsQuery={useFetchContactsQuery}
      useFetchUserQuery={useFetchUserQuery}
      useFetchUserRolesQuery={useFetchUserRolesQuery}
      useActivateUserMutation={useActivateUserMutation}
      useAdminRequestUserEmailVerificationMutation={useAdminRequestUserEmailVerificationMutation}
      useAdminRequestUserPasswordResetMutation={useAdminRequestUserPasswordResetMutation}
    />
  );
};

UserDetails.load = async ({ params }) => {
  console.log('params', params);
  return {
    props: {
      userId: params.id
    }
  };
};

UserDetails.meta = () => ({
  title: 'User Details'
});

UserDetails.when = ({ user }) => userHasPermission(user, 'user:read', {
  orgId: user?.institution.id,
  ownerId: null
});

export default UserDetails;
