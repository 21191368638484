import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import DatePicker from '../DatePicker';

let InstantSearchDatePicker = ({
  id,
  label,
  value,
  onChange,
  onOpen,
  tabIndex,
  minDate,
  maxDate,
  'aria-label': ariaLabel
}, ref) => {
  const onToggle = useCallback((on) => {
    if (on) {
      onOpen();
    }
  }, [onOpen]);

  if (!label && !ariaLabel) {
    console.warn('DatePicker: `label` or `aria-label` is required');
  }

  return (
    <DatePicker
      id={id}
      ref={ref}
      label={label}
      aria-label={ariaLabel}
      value={value}
      onChange={onChange}
      onToggle={onToggle}
      tabIndex={tabIndex}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
};

InstantSearchDatePicker = React.forwardRef(InstantSearchDatePicker);

InstantSearchDatePicker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  'aria-label': PropTypes.string,
  value: PropTypes.instanceOf(Date),
  tabIndex: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  minDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(Date)
  ]),
  maxDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(Date)
  ])
};

export default InstantSearchDatePicker;
