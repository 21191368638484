import type { Table, Column, Header, RowData } from '@tanstack/react-table';
import type { CSSProperties } from 'react';
import { EXPANDING_COLUMN_ID, SELECT_COLUMN_ID } from './consts';

export const parseCSSVarId = (id: string) => id.replace(/[^a-zA-Z0-9]/g, '_');

export const getIsLastLeftPinnedColumn = <TData extends RowData>(
  table: Table<TData>,
  column: Column<TData>
) => {
  return (
    column.getIsPinned() === 'left' &&
    table.getLeftLeafHeaders().length - 1 === column.getPinnedIndex()
  );
};

export const getIsFirstRightPinnedColumn = <TData extends RowData>(column: Column<TData>) => {
  return column.getIsPinned() === 'right' && column.getPinnedIndex() === 0;
};

export const getTotalRight = <TData extends RowData>(table: Table<TData>, column: Column<TData>) => {
  return table
    .getRightLeafHeaders()
    .slice(column.getPinnedIndex() + 1)
    .reduce((acc, col) => acc + col.getSize(), 0);
};

export const getCommonCellStyles = <TData extends RowData>({
  column,
  header,
  table
}: {
  column: Column<TData>;
  header?: Header<TData, unknown>;
  table: Table<TData>;
}): CSSProperties => {
  const isSelectColumn = column.id === SELECT_COLUMN_ID;
  const isExpandColumn = column.id === EXPANDING_COLUMN_ID;

  const width = `calc(var(--${header ? 'header' : 'col'}-${parseCSSVarId(header?.id ?? column.id)}-size) * 1px)`;
  return {
    width: column.getIsPinned() || isSelectColumn || isExpandColumn ? width : undefined,
    minWidth: column.getIsPinned() ? (
      `max(calc(var(--${header
        ? 'header'
        : 'col'}-${parseCSSVarId(header?.id ?? column.id)}-size) * 1px), ${column.columnDef.minSize ?? 30}px)`
    ) : undefined,

    boxShadow: isSelectColumn || isExpandColumn ? undefined
      : getIsLastLeftPinnedColumn(table, column) || getIsFirstRightPinnedColumn(column)
        ? '0 0 15px rgba(0, 0, 0, 0.15)'
        : undefined,

    borderRight: getIsLastLeftPinnedColumn(table, column) ? '1px solid var(--color-grayish-100)' : undefined,

    borderLeft: getIsFirstRightPinnedColumn(column) ? '1px solid var(--color-grayish-100)' : undefined,
    clipPath: isSelectColumn || isExpandColumn ? undefined :
      getIsLastLeftPinnedColumn(table, column)
        ? 'inset(0px -15px 0px 0px)'
        : getIsFirstRightPinnedColumn(column) ? 'inset(0px 0px 0px -15px)' : undefined,

    paddingRight: getIsLastLeftPinnedColumn(table, column) ? '2rem' : undefined,
    paddingLeft: getIsFirstRightPinnedColumn(column) ? '1rem' : undefined,

    marginLeft: column.getIsPinned() === 'left' &&
    column.getPinnedIndex() === 0
      ? `-${column.getSize() * (table.getState().columnPinning.left?.length ?? 1)}px`
      : undefined,
    marginRight: column.getIsPinned() === 'right' && column.getPinnedIndex() === table.getVisibleLeafColumns().length - 1
      ? `-${column.getSize() * (table.getState().columnPinning.right?.length ?? 1) * 1.2}px`
      : undefined,
    zIndex: header ? ((isSelectColumn || column.getIsPinned()) ? 4 : 3) : (column.getIsPinned() ? 2 : 1),
    position: column.getIsPinned() ? 'sticky' : undefined,
    left: column.getIsPinned() === 'left' ? `${column.getStart('left')}px` : undefined,
    right: column.getIsPinned() === 'right' ? `${getTotalRight(table, column)}px` : undefined
  };
};
