import React, { memo, useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import mapValues from 'lodash/mapValues';
import unionBy from 'lodash/unionBy';

import type { RowSelectionState } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';

import { useTranslate } from '@acadeum/translate';
import type { OnSubmit } from '@acadeum/ui';
import { Form, StickyFormFooter, Table, toast } from '@acadeum/ui';
import type { UserRolePermissionOperation } from '@acadeum/types';
import { isAcadeumAdministrator } from '@acadeum/helpers';

import { UserRolePermissionsInfoTooltip } from '../UserRolePermissionsInfoTooltip';
import { PermissionsSelect } from '../PermissionsSelect';
import { ErrorLineBadge } from '../ErrorLineBadge';

import { DEFAULT_TABLE_PERMISSIONS_SUBJECT } from '../../lib/consts';

import type { FormPermissionValues, PermissionObjectType } from '../../lib/types';
import { prepareFormPermissionsToObjectPermissions } from '../../lib/prepareFormPermissionsToObjectPermissions';
import { useSelector } from 'react-redux';
import { getAuthSelector } from '@acadeum/auth';

export interface ManageRolePermissionsFormProps {
  type: 'edit' | 'create';
  onBack?: OnSubmit<FormPermissionValues>;
  onSubmit: OnSubmit<FormPermissionValues>;
  onCancel: OnSubmit<FormPermissionValues>;
  defaultValues?: FormPermissionValues;
}

type FormValues = FormPermissionValues & {
  formAction?: 'back' | 'cancel' | 'submit';
}

const columnHelper = createColumnHelper<PermissionObjectType>();

function getRowSelectionInitialState(rows: readonly PermissionObjectType[]): (() => RowSelectionState) {
  return () => rows.filter(row => row.create || row.read || row.update || row.delete).reduce((result, row) => {
    result[row.subject] = true;
    return result;
  }, {});
}

export const ManageRolePermissionsForm = memo<ManageRolePermissionsFormProps>(({
  type,
  defaultValues,
  onSubmit: propsOnSubmit,
  onCancel,
  onBack
}) => {
  const t = useTranslate('shared-admin-ui.ManageRolePermissionsForm');
  const user = useSelector(getAuthSelector('user'));

  const rows = useMemo(() => {
    let rows = defaultValues
      ? unionBy(prepareFormPermissionsToObjectPermissions(defaultValues), DEFAULT_TABLE_PERMISSIONS_SUBJECT, 'subject')
      : DEFAULT_TABLE_PERMISSIONS_SUBJECT;
    // Hide certains permissions:
    // * Acadeum-Admin-only ones like "Course Sharing Groups", if the user is not an Acadeum Admin.
    if (!isAcadeumAdministrator(user)) {
      const hasCourseSharingGroupPermissions = defaultValues && defaultValues.courseSharingGroup && Object.keys(defaultValues.courseSharingGroup).some(_ => defaultValues.courseSharingGroup[_]);
      if (!hasCourseSharingGroupPermissions) {
        rows = rows.filter(_ => _.subject !== 'courseSharingGroup');
      }
    }
    return rows;
  }, [defaultValues, user]);

  const getRowId = useCallback((row) => row.subject, []);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(getRowSelectionInitialState(rows));

  const selectedRows: PermissionObjectType[] = useMemo(() => {
    const selectedRowSubjects = Object.keys(rowSelection);
    return rows.filter(row => selectedRowSubjects.includes(row.subject));
  }, [rowSelection, rows]);

  const onSubmit: OnSubmit<FormValues> = useCallback(async (values, options) => {
    const { formAction, ...permissions } = values;

    const specifiedPermissions = Object.keys(permissions).reduce((result, key) => {
      if (selectedRows.some(_ => _.subject === key)) {
        if (Object.keys(permissions[key]).some(_ => permissions[key][_])) {
          result[key] = permissions[key];
        }
      }
      return result;
    }, {});

    // At least one permission is required.
    if (Object.keys(specifiedPermissions).length === 0) {
      return toast.error(t('permissionsRequired'));
    }

    const formattedPermissions = Object.keys(permissions).reduce((result, key) => {
      result[key] = selectedRows.some(_ => _.subject === key)
        ? permissions[key]
        : mapValues(permissions[key], () => null);
      return result;
    }, {});

    if (formAction) {
      const handler = formAction === 'back' ? onBack : formAction === 'cancel' ? onCancel : propsOnSubmit;
      await handler?.(formattedPermissions, options);
    }
  }, [
    onBack,
    onCancel,
    propsOnSubmit,
    selectedRows
  ]);

  return (
    <Form defaultValues={defaultValues} onSubmit={onSubmit}>
      <FormContent
        type={type}
        rows={rows}
        getRowId={getRowId}
        rowSelectionOptions={{ rowSelection, onRowSelectionChange: setRowSelection }}
      />
    </Form>
  );
});

function useColumns(t) {
  return useMemo(() => {
    const operations: UserRolePermissionOperation[] = ['create', 'read', 'update', 'delete'];
    return [
      columnHelper.accessor('name', {
        header: t('title')
      }),
      ...operations.map(operation => columnHelper.accessor(operation, {
        header: t(operation),
        cell: ({ row }) => (
          <PermissionsSelect
            operation={operation}
            subject={row.original.subject}
            disabled={!row.getIsSelected()} />
        )
      })),
      columnHelper.display({
        id: 'actions',
        cell: ({ row }) => (
          <ErrorLineBadge
            isSelected={row.getIsSelected()}
            subject={row.original.subject}
          />
        )
      })
    ];
  }, []);
}

const FormContent = ({
  rows,
  getRowId,
  type,
  rowSelectionOptions
}) => {
  const t = useTranslate('shared-admin-ui.ManageRolePermissionsForm');
  const { watch } = useFormContext();

  const columns = useColumns(t);

  const getRowHasError = useCallback(({ row }) => {
    const permissionsValues = watch(row.original.subject);
    const isEmptyValues = permissionsValues && Object.values(permissionsValues).filter(_ => _).length === 0;
    return row.getIsSelected() && isEmptyValues;
  }, [watch]);

  return (
    <>
      <Table
        stickyToolbar
        enableGlobalFilter
        hidePagination
        meta={{ getRowHasError }}
        getRowId={getRowId}
        rowSelectionOptions={rowSelectionOptions}
        isSortSelectedFirst
        rowSelectionType="switch"
        enableRowSelection
        columns={columns}
        data={rows}
        renderTopLeftToolbarCustomActions={() => <UserRolePermissionsInfoTooltip />}
        translate={{
          searchPlaceholder: t('searchByPermissionTitle'),
          resultText: ({ totalCount }) => t('resultText', { totalCount }),
          selectedResultText: ({ totalCount, selectedRowsCount }) => t('selectedResultText', {
            totalCount,
            selectedRowsCount
          })
        }}
      />
      <br />
      <StickyFormFooter
        onCancelProps={{
          action: 'cancel'
        }}
        onBackProps={{
          action: 'back'
        }}
        submitProps={{
          action: 'submit',
          children: type === 'edit' ? t('save') : t('next')
        }}
      />
    </>
  );
};
