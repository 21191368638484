import LABELS_EN from '../messages/en/Ethnicity.json' assert { type: 'json' };

export default function formatEthnicity(ethnicity, { language, input, own }) {
  const LABELS = LABELS_EN;
  if (ethnicity === '-') {
    return input && own ? LABELS.preferUnspecified : LABELS.unspecified;
  }
  return LABELS.ethnicity[ethnicity] || ethnicity;
}

export const ETHNICITIES = Object.keys(LABELS_EN.ethnicity);
