import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const editEnrollmentRequest = redux.action(
  (enrollmentRequest) => (http) => {
    return http.put(`/enrollmentrequests/${enrollmentRequest.id}`, enrollmentRequest);
  }
);

export const fetchEnrollmentRequest = redux.action(
  (id) => (http) => http.get(`/enrollmentrequests/${id}`)
);

export const fetchEnrollmentRequests = redux.action(
  () => (http) => http.get('/enrollmentrequests'),
  'enrollmentRequests'
);

export const fetchEnrollmentRequestsEMForExport = redux.action(
  (ids) => (http) => {
    return http.get('/enrollmentrequests', {
      dataExport: '✓',
      // Compress the list of IDs so that it fits into the URL query max length limit.
      // https://github.com/Acadeum/Tickets/issues/1293
      ids: ids && ids.sort((a, b) => a - b).map((id, i) => i === 0 ? id : id - ids[i - 1]).join('.')
    });
  }
);

export const markAsConsortial = redux.action(
  (ids) => (http) => http.post('/enrollmentrequeststudents/admin/markConsortial', { ids })
);

export const markAsUnpaid = redux.action(
  (ids) => (http) => http.post('/enrollments/mark-unpaid', { ids })
);

export const changeEnrollmentStatus = redux.action(
  (id, status) => (http) => http.put('/enrollmentrequeststudents/admin/changestatus', { id, status }),
  'adminEnrollment'
);

export const fetchEnrollment = redux.action(
  (id) => (http) => http.get('/enrollmentrequeststudents/admin/read', { id }),
  'adminEnrollment'
);

export const getEnrollmentsCounts = redux.action(
  () => (http) => http.get('/counts/enrollments'),
  (state, counts) => ({
    ...state,
    ...counts
  })
);

export default redux.reducer();
