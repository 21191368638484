import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-responsive-ui';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import SimpleBar from 'simplebar-react';

import Link from '../Link';

import useNavSection from './useNavSection';

import AdminTools from './icons/AdminTools.svg';
import AdvisorRecommendations from './icons/AdvisorRecommendations.svg';
import ConsortiumAnalytics from './icons/ConsortiumAnalytics.svg';
import Courses from './icons/Courses.svg';
import Dashboard from './icons/Dashboard.svg';
import EnrollmentRequests from './icons/EnrollmentRequests.svg';
import Institutions from './icons/Institutions.svg';
import ManageEnrollments from './icons/ManageEnrollments.svg';
import Network from './icons/Network.svg';
import Settings from './icons/Settings.svg';
import Students from './icons/Students.svg';

import DownArrow from './DownArrow.svg';

import isAcadeumAdministrator from '../../helpers/isAcadeumAdministrator';
import userHasPermission from '../../helpers/userHasPermission';

import './SideNav.sass';

import 'simplebar/dist/simplebar.min.css';

export default function SideNav({
  minimized,
  onToggleMinimize
}) {
  const user = useSelector(state => state.auth.user);
  const newEnrollmentRequestsCount = useSelector(state => state.enrollmentRequestsTM.newEnrollmentRequestsCount);
  const newStudentRequestsCount = useSelector(state => state.studentRequests.newStudentRequestsCount);

  const section = useNavSection();

  const renderStudentsMenuItem = () => {
    const props = {
      selected: section === 'students',
      title: 'Students',
      compact: minimized
    };
    if (userHasPermission(user, 'homeStudent:read', { orgId: user.institution.id })) {
      return (
        <SideNavLink {...props} to="/students">
          <Students className="side-nav__icon" />
        </SideNavLink>
      );
    }
    if (user.institution.teaching && userHasPermission(user, 'teachingStudent:read', { orgId: user.institution.id })) {
      return (
        <SideNavLink {...props} to="/students/teaching">
          <Students className="side-nav__icon" />
        </SideNavLink>
      );
    }
    return null;
  };

  const renderManageEnrollmentsMenuItem = () => {
    const props = {
      selected: section === 'enrollments',
      title: 'Manage Enrollments',
      compact: minimized
    };
    if (userHasPermission(user, 'homeCourseEnrollment:read', { orgId: user.institution.id })) {
      return (
        <SideNavLink {...props} to="/enrollments/live">
          <ManageEnrollments className="side-nav__icon" />
        </SideNavLink>
      );
    }
    if (user.institution.teaching && userHasPermission(user, 'teachingCourseEnrollment:read', { orgId: user.institution.id })) {
      return (
        <SideNavLink {...props} to="/enrollments/teaching">
          <ManageEnrollments className="side-nav__icon" />
        </SideNavLink>
      );
    }
    return null;
  };

  const renderGradesMenuItem = () => {
    const props = {
      selected: section === 'grades',
      title: 'Grades',
      compact: minimized
    };
    if (userHasPermission(user, 'homeCourseEnrollmentGrade:read', { orgId: user.institution.id })) {
      return (
        <SideNavLink {...props} to="/grades/enrolling">
          <ManageEnrollments className="side-nav__icon" />
        </SideNavLink>
      );
    }
    if (user.institution.teaching && userHasPermission(user, 'teachingCourseEnrollmentGrade:read', { orgId: user.institution.id })) {
      return (
        <SideNavLink {...props} to="/grades/teaching">
          <ManageEnrollments className="side-nav__icon" />
        </SideNavLink>
      );
    }
    return null;
  };

  return (
    <nav
      aria-label="Main menu"
      className={classNames('side-nav', {
        'side-nav--minimized': minimized
      })}>
      <SimpleBar className="side-nav__scrollable-list">
        <ul className="side-nav__list">
          {/* Dashboard */}
          {isAcadeumAdministrator(user) &&
            <SideNavLink
              to="/dashboard"
              selected={section === 'dashboard'}
              title="Dashboard"
              compact={minimized}>
              <Dashboard className="side-nav__icon" />
            </SideNavLink>
          }

          {/* "Enrollment Requests" */}
          {user.institution.teaching && userHasPermission(user, 'teachingCourseEnrollment:read', { orgId: user.institution.id }) &&
            <SideNavLink
              to="/enrollment-requests"
              selected={section === 'enrollmentRequests'}
              title="Enrollment Requests"
              notificationCount={newEnrollmentRequestsCount || 0}
              compact={minimized}>
              <EnrollmentRequests className="side-nav__icon" />
            </SideNavLink>
          }

          {/* "Student Requests" */}
          {userHasPermission(user, 'homeStudentCourseRegistrationRequest:read', { orgId: user.institution.id }) &&
            <SideNavLink
              to="/course-registration-requests"
              selected={section === 'courseRegistrationRequests'}
              title="Student Requests"
              notificationCount={newStudentRequestsCount}
              compact={minimized}>
              <EnrollmentRequests className="side-nav__icon" />
            </SideNavLink>
          }

          {/* Advisor Recommendations */}
          {user.institution.studentApp && userHasPermission(user, 'homeCourseAdvisorRecommendation:read', { orgId: user.institution.id }) &&
            <SideNavLink
              to="/advisor-recommendations"
              selected={section === 'advisorRecommendations'}
              title="Advisor Recommendations"
              compact={minimized}>
              <AdvisorRecommendations className="side-nav__icon" />
            </SideNavLink>
          }

          {/* "Manage Enrollments" */}
          {renderManageEnrollmentsMenuItem()}

          {/* Courses */}
          {userHasPermission(user, 'course:read') &&
            <SideNavLink
              to="/"
              selected={section === 'courses'}
              title="Courses"
              compact={minimized}>
              <Courses className="side-nav__icon" />
            </SideNavLink>
          }

          {/* Grades */}
          {renderGradesMenuItem()}

          {/* Students */}
          {renderStudentsMenuItem()}

          {/* Institutions */}
          {userHasPermission(user, 'institution:read') &&
            <SideNavLink
              to="/institutions"
              selected={section === 'institutions'}
              title="Institutions"
              compact={minimized}>
              <Institutions className="side-nav__icon" />
            </SideNavLink>
          }

          {/* Consortia */}
          {userHasPermission(user, 'consortium:read') &&
            <SideNavLink
              to="/consortia"
              selected={section === 'consortia'}
              title="Consortia"
              compact={minimized}>
              <Network className="side-nav__icon" />
            </SideNavLink>
          }

          {/* Settings */}
          <SideNavLink
            to="/settings"
            selected={section === 'settings'}
            title="Settings"
            compact={minimized}>
            <Settings className="side-nav__icon" />
          </SideNavLink>

          {/* Admin Tools */}
          {isAcadeumAdministrator(user) &&
            <SideNavLink
              to="/admin"
              selected={section === 'admin'}
              title="Admin Tools"
              compact={minimized}>
              <AdminTools className="side-nav__icon" />
            </SideNavLink>
          }

          {/* Consortium Analytics */}
          {'user.roles.find(role => role.name === \'Consortium\')' && false && user.consortiumId &&
            <SideNavLink
              to="/consortium-analytics"
              selected={section === 'consortiumAnalytics'}
              title="Consortium Analytics"
              compact={minimized}>
              <ConsortiumAnalytics className="side-nav__icon" />
            </SideNavLink>
          }

          {/* Consortium Analytics (Teaching) */}
          {'user.roles.find(role => role.name === \'Consortium\'' && false && user.consortiumId &&
            <SideNavLink
              to="/consortium-analytics-teaching"
              selected={section === 'consortiumAnalyticsTeaching'}
              title="Consortium Analytics Teaching"
              compact={minimized}>
              <ConsortiumAnalytics className="side-nav__icon" />
            </SideNavLink>
          }
        </ul>
      </SimpleBar>

      {/* Minimize/Expand button. */}
      <button
        type="button"
        title={minimized ? 'Expand menu' : 'Collapse menu'}
        onClick={onToggleMinimize}
        className="side-nav__minimize rrui__button-reset">
        <DownArrow className={classNames('side-nav__minimize-icon', {
          'side-nav__minimize-icon--expand': minimized
        })}/>
      </button>
    </nav>
  );
}

SideNav.propTypes = {
  minimized: PropTypes.bool,
  onToggleMinimize: PropTypes.func.isRequired
};

function SideNavLink({
  to,
  title,
  selected,
  notificationCount,
  compact,
  children
}) {
  return (
    <li className="side-nav__list-item">
      <Tooltip
        inline={false}
        delay={200}
        content={title}
        placement="right"
        className="side-nav__item"
        tooltipClassName={classNames('side-nav__tooltip', {
          'side-nav__tooltip--required': compact
        })}>
        <Link
          to={to}
          aria-current={selected ? 'page' : false}
          className={classNames('side-nav__link', {
            'side-nav__link--selected': selected
          })}>
          <div className="side-nav__icon-container">
            {children}
            {/* Notifications badge */}
            {notificationCount > 0 &&
              <span className="side-nav__notification">
                {notificationCount}
              </span>
            }
          </div>
          {!compact &&
            <div className="side-nav__link-title">
              {title}
            </div>
          }
        </Link>
      </Tooltip>
    </li>
  );
}

SideNavLink.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  compact: PropTypes.bool,
  selected: PropTypes.bool.isRequired,
  notificationCount: PropTypes.number,
  children: PropTypes.node.isRequired
};
