import { useSelector } from 'react-redux';

import useLocation from './useLocation';
import normalizePath from '../helpers/normalizePath';

// Some pages on this website are "public" and are linked to from the marketing website.
// https://github.com/Acadeum/Tickets/issues/655
// This function returns the "type" of the current page as marketing sees it.
export default function useRequestMoreInfoPublicMarketingPageType() {
  const location = useLocation();
  const path = normalizePath(location.pathname);

  const user = useSelector(state => state.auth.user);

  if (user) {
    return;
  }

  if (
    // Course Search page.
    path === '/courses/' ||

    // Course Section page.
    // eslint-disable-next-line
    /\/sections\/[^\/]+\//.test(path) ||

    // Course page.
    // eslint-disable-next-line
    /\/courses\/[^\/]+\//.test(path)
  ) {
    return 'courses';
  }

  if (
    // Institutions Search page.
    path === '/institutions/' ||

    // Institution page.
    // eslint-disable-next-line
    /\/institutions\/[^\/]+\//.test(path)
  ) {
    return 'institutions';
  }

  if (
    // Consortia Search page.
    path === '/consortia/' ||

    // Consortium page.
    // eslint-disable-next-line
    /\/consortia\/[^\/]+\//.test(path)
  ) {
    return 'consortia';
  }
}
