const COUNTRY_POSTAL_CODE_RULES = {
  US: /^\d{5}(-\d{4})?$/,
  CA: /^[A-Z]\d[A-Z] \d[A-Z]\d$/
};

export default function isValidPostalCode(value, country) {
  if (!COUNTRY_POSTAL_CODE_RULES[country]) {
    return true;
  }
  return COUNTRY_POSTAL_CODE_RULES[country].test(value);
}
