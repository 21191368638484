import React, { useState } from 'react';

import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND } from '@lexical/list';
import { $createParagraphNode, $getSelection, $isRangeSelection } from 'lexical';
import { $createHeadingNode } from '@lexical/rich-text';
import { $wrapNodes } from '@lexical/selection';

import {
  ListBulletedIcon,
  ListNumberedIcon,
  TextH1Icon,
  TextH2Icon,
  TextH3Icon,
  TextParagraphIcon
} from '@acadeum/icons';

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../../../../DropdownMenu';
import { BaseButton } from '../../../../../BaseButton';
import { Icon } from '../../../../../Icon';

import styles from './FormattingOptionsButton.module.scss';

const supportedBlockTypes = new Set([
  'paragraph',
  'quote',
  'code',
  'h1',
  'h2',
  'h3',
  'ul',
  'ol'
]);

const blockTypeToBlock = {
  paragraph: {
    icon: TextParagraphIcon,
    title: 'Normal'
  },
  h1: {
    icon: TextH1Icon,
    title: 'Heading 1'
  },
  h2: {
    icon: TextH2Icon,
    title: 'Heading 2'
  },
  h3: {
    icon: TextH3Icon,
    title: 'Heading 3'
  },
  ol: {
    icon: ListNumberedIcon,
    title: 'Numbered List'
  },
  ul: {
    icon: ListBulletedIcon,
    title: 'Bulleted List'
  },
  code: {
    title: 'Code Block'
  },
  quote: {
    title: 'Quote'
  }
};

type Heading = 'h1' | 'h2' | 'h3'

type List = 'ol' | 'ul'

export const getIsSupportedBlockTypes = (blockType) => supportedBlockTypes.has(blockType);

export const FormattingOptionsButton = ({
  editor,
  blockType,
  className,
  disabled
}) => {
  const [show, setShow] = useState(false);

  const formatHeading = (heading: Heading) => () => {
    if (blockType === heading) {
      return setShow(false);
    }
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $wrapNodes(selection, () => $createHeadingNode(heading));
      }
    });
  };

  const formatParagraph = () => {
    if (blockType === 'paragraph') {
      return setShow(false);
    }
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $wrapNodes(selection, () => $createParagraphNode());
      }
    });
  };

  const formatList = (list: List) => () => {
    if (blockType === list) {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    } else {
      const command = list === 'ol' ? INSERT_ORDERED_LIST_COMMAND : INSERT_UNORDERED_LIST_COMMAND;
      editor.dispatchCommand(command);
    }
    setShow(false);
  };
 
  return (
    <DropdownMenu open={show} onOpenChange={setShow}>
      <DropdownMenuTrigger asChild>
        <BaseButton
          aria-label="Formatting Options"
          disabled={disabled}
          className={className}
        >
          {blockTypeToBlock[blockType].icon ? (
            <Icon icon={blockTypeToBlock[blockType].icon}/>
          ) : (
            blockTypeToBlock[blockType].title
          )}
        </BaseButton>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="start" className={styles.content}>
        <DropdownMenuItem
          size="md"
          onClick={formatParagraph}
        >
          <Icon icon={blockTypeToBlock.paragraph.icon}/>
          {blockTypeToBlock.paragraph.title}
        </DropdownMenuItem>

        {(['h1', 'h2', 'h3'] as Heading[]).map((heading) => (
          <DropdownMenuItem
            key={heading}
            size="md"
            onClick={formatHeading(heading)}
          >
            <Icon icon={blockTypeToBlock[heading].icon}/>
            {blockTypeToBlock[heading].title}
          </DropdownMenuItem>
        ))}

        {(['ul', 'ol'] as List[]).map(list => (
          <DropdownMenuItem
            key={list}
            size="md"
            onClick={formatList(list)}
          >
            <Icon icon={blockTypeToBlock[list].icon}/>
            {blockTypeToBlock[list].title}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
