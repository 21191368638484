import formatRace from './formatRace.js';

export default function formatRaces(races, {
  language,
  own,
  separator = ', '
}) {
  if (races === '-') {
    return formatRace('-', { language, own });
  }
  return races.map(race => formatRace(race, { language, own })).join(separator);
}
