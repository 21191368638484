import type { SortByState } from '@acadeum/types';
import type React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export interface FetchOptions extends Record<string, unknown> {
  page?: number;
  pageSize: number;
  sort?: SortByState;
  search?: string;
}

export interface FetchResult {
  results: unknown[];
  page: number;
  pageSize: number;
  totalCount: number;
}

type FetchType = (options: FetchOptions) => Promise<FetchResult>

interface UseFetchWithPaginationOptions<T> {
  pageSizes?: number[];
  defaultPageSize?: number;
  fetch: T;
  onError?: (error: Error) => void;
  defaultSorting?: SortByState;
  defaultFilters?: object;
}

interface PaginationOptions {
  pagination: { pageIndex: number; pageSize: number };
  onPaginationChange: React.Dispatch<React.SetStateAction<{ pageIndex: number, pageSize: number }>>;
  totalCount: number;
  pageCount: number;
}

interface SortingOptions {
  enableSorting: boolean;
  sorting: SortByState;
  onSortingChange?: (sortBy: SortByState) => void;
}

interface GlobalFilterOptions {
  enableGlobalFilter: boolean;
  globalFilter: string;
  onGlobalFilterChange: (searchValue: string) => void;
}

interface ColumnFiltersOptions {
  enableColumnFilters: boolean;
  onColumnFiltersChange: (filters) => void;
}

type GetTableProps<Fetch extends FetchType> = (options?: {
  includeSorting?: boolean;
  includeGlobalFilter?: boolean;
  includeColumnFilters?: boolean;
}) => {
  data?: Awaited<ReturnType<Fetch>>['results']
  loading: boolean;
  loadingInitially: boolean;
  paginationOptions: PaginationOptions;
  sortingOptions?: SortingOptions;
  globalFilterOptions?: GlobalFilterOptions;
  columnFiltersOptions?: ColumnFiltersOptions;
}

interface UseFetchWithPaginationReturn<Fetch extends FetchType> {
  loading: boolean;
  data?: Awaited<ReturnType<Fetch>>['results']
  loadingInitially: boolean;
  fetchParams: object;
  error?: Error
  filters: object;
  totalCount: number;
  pageSizes: number[]
  refresh: () => Promise<void>,
  onSortByChange: (sortBy: SortByState) => void;
  onFiltersChange: (filters: object) => void;
  onSearchQueryChange: (searchQuery: string) => void;
  onResultsPerPageChange: (pageSize: number) => void;
  onPageChange: (page: number) => void;
  getTableProps: any
}

export const useFetchWithPagination = <Fetch extends FetchType>({
  defaultFilters,
  defaultSorting = [],
  defaultPageSize = 10,
  pageSizes = [10, 20, 30, 40],
  onError,
  fetch
}: UseFetchWithPaginationOptions<Fetch>): UseFetchWithPaginationReturn<Fetch> => {
  const [sort, setSort] = useState(defaultSorting);
  const [filters, setFilters] = useState({filters: defaultFilters});
  const [searchQuery, setSearchQuery] = useState('');

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: defaultPageSize
  });

  const [loading, setLoading] = useState(true);
  const [loadingInitially, setLoadingInitially] = useState(true);
  const isInitialLoad = useRef(true);

  const [data, setData] = useState<Awaited<ReturnType<typeof fetch>>['results']>();
  const [totalCount, setTotalCount] = useState(0);

  const [error, setError] = useState<Error>();

  const fetchParams = useMemo(() => ({
    ...filters,
    sort: sort.length === 0 ? undefined : sort,
    search: searchQuery || undefined,
    page: pageIndex + 1,
    pageSize
  }), [
    filters,
    sort,
    searchQuery,
    pageIndex,
    pageSize
  ]);

  const fetcher = useCallback(async () => {
    const result = await fetch(fetchParams);

    if (
      !result
      || !Array.isArray(result.results)
      || typeof result.page !== 'number'
      || result.page < 1
    ) {
      throw new Error('`fetch` must return an object with properties `results` and `page`.');
    }

    if (result.results.length === 0 && result.page > 1) {
      setPagination({
        pageSize,
        pageIndex: pageIndex - 1
      });
    }
    return result;
  }, [fetchParams, setPagination, pageSize, pageIndex, fetch]);

  const discardCurrentSearch = useRef<() => void>();

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(undefined);

    if (discardCurrentSearch.current) {
      discardCurrentSearch.current();
    }

    let discarded;
    discardCurrentSearch.current = () => {
      discardCurrentSearch.current = undefined;
      discarded = true;
    };

    try {
      const result = await fetcher();
      if (discarded) {
        return;
      }
      setData(result.results);
      setTotalCount(result.totalCount);
      setLoading(false);
    } catch (err: unknown) {
      if (discarded) {
        return;
      }
      setLoading(false);
      setError(err as Error);
      if (onError) {
        onError(err as Error);
      } else {
        throw error;
      }
    } finally {
      if (isInitialLoad.current) {
        isInitialLoad.current = false;
        setLoadingInitially(false);
      }
    }
  }, [fetchParams, fetcher]);

  useEffect(() => {
    void fetchData();
  }, [fetchData]);

  const onSortByChange = (sort) => {
    setSort(sort);
  };

  const onFiltersChange = (filters) => {
    setFilters(filters);
  };

  const onSearchQueryChange = (searchValue) => {
    setSearchQuery(searchValue);
  };

  const onResultsPerPageChange = (pageSize) => {
    setPagination({ pageSize, pageIndex });
  };

  const onPageChange = (page) => {
    setPagination({ pageSize, pageIndex: page - 1 });
  };

  const getTableProps: GetTableProps<Fetch> = ({
    includeSorting,
    includeGlobalFilter,
    includeColumnFilters
  } = {}) => {

    return {
      data,
      loading,
      loadingInitially,
      paginationOptions: {
        totalCount,
        pageCount: (totalCount && Math.ceil(totalCount / pageSize)) ?? -1,
        pagination: { pageSize, pageIndex },
        onPaginationChange: setPagination
      },
      sortingOptions: includeSorting ? {
        enableSorting: true,
        manualSorting: true,
        sorting: sort,
        onSortingChange: onSortByChange
      } : undefined,
      globalFilterOptions: includeGlobalFilter ? {
        enableGlobalFilter: true,
        globalFilter: searchQuery,
        onGlobalFilterChange: onSearchQueryChange
      } : undefined,
      columnFiltersOptions: includeColumnFilters ? {
        enableColumnFilters: true,
        onColumnFiltersChange: onFiltersChange
      } : undefined
    };
  };

  return {
    fetchParams,
    loading,
    loadingInitially,
    error,
    filters,
    data,
    totalCount,
    pageSizes,
    refresh: fetchData,
    onSortByChange,
    onFiltersChange,
    onSearchQueryChange,
    onResultsPerPageChange,
    onPageChange,
    getTableProps
  };
};
