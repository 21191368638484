interface ConvertFileToBase64Result {
  data: string;
  name: string;
}

export function convertFileToBase64(file: File): Promise<ConvertFileToBase64Result> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => {
      resolve({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data: btoa(event.target.result),
        name: file.name
      });
    };
    reader.onerror = reject;
  });
}

// TODO: Check it function
// function convertFileToBase64(file: File) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve({
//       data: reader.result,
//       name: file.name
//     });
//     reader.onerror = (error) => reject(error);
//   });
// }
