import React from 'react';

export function firstParagraph(text: string) {
  return text.split('\n\n')[0].trim();
}

export function joinAnd(array: string[]) {
  if (array.length === 1) {
    return array[0];
  }
  const allExceptLast = array.slice(0, -1);
  return (
    <React.Fragment>
      {allExceptLast.map((element, i) => (
        <React.Fragment key={i}>
          {element}
          {i < allExceptLast.length - 1 ? ', ' : ''}
        </React.Fragment>
      ))}
      {' and '}
      {array[array.length - 1]}
    </React.Fragment>
  );
}
