import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const fetchAdvisorRecommendations = redux.action(
  () => (http) => http.get('/advisor-recommendations'),
  'recommendations'
);

export const fetchAdvisorRecommendation = redux.action(
  (id) => (http) => http.get(`/advisor-recommendations/${id}`),
  'recommendation'
);

export const saveAdvisorRecommendationCreationState = redux.simpleAction(
  (state, advisorRecommendationCreationState) => ({
    ...state,
    advisorRecommendationCreationState: {
      ...state.advisorRecommendationCreationState,
      ...advisorRecommendationCreationState
    }
  })
);

export const resetAdvisorRecommendationCreationState = redux.simpleAction(
  (state) => ({
    ...state,
    advisorRecommendationCreationState: undefined
  })
);

export const createAdvisorRecommendation = redux.action(
  (values) => (http) => http.post('/advisor-recommendations', values)
);

export const removeAdvisorRecommendation = redux.action(
  (id, { notes }) => (http) => http.post(`/advisor-recommendations/${id}/remove`, { notes })
);

export default redux.reducer();
