import getLastDateToDropFromCourseEnrollment from './getLastDateToDropFromCourseEnrollment.js';
import validatePropertyValuesNotUndefined from './validatePropertyValuesNotUndefined.js';
import courseEnrollmentDropOrWithdrawFromStatuses from '../constants/courseEnrollmentDropOrWithdrawFromStatuses.json' assert { type: 'json' };

export default function canDropFromCourseEnrollment({
  status,
  onDemand,
  onDemandDropDateDaysAfterStartDate,
  startedAt,
  lastDropDate
}) {
  const error = validateCanDropFromCourseEnrollment({
    status,
    onDemand,
    onDemandDropDateDaysAfterStartDate,
    startedAt,
    lastDropDate
  });
  return !error;
}

export function validateCanDropFromCourseEnrollment({
  status,
  onDemand,
  onDemandDropDateDaysAfterStartDate,
  startedAt,
  lastDropDate
}) {
  // Validate that all properties are passed `undefined`.
  validatePropertyValuesNotUndefined({
    status,
    onDemand,
    onDemandDropDateDaysAfterStartDate,
    startedAt,
    lastDropDate
  });

  if (!courseEnrollmentDropOrWithdrawFromStatuses.includes(status)) {
    return 'invalid_status';
  }

  // `PENDING` enrollments can be dropped at any time with no restrictions.
  if (status === 'PENDING') {
    return;
  }

  if (!canStillDropFromCourseEnrollmentByDate({
    onDemand,
    onDemandDropDateDaysAfterStartDate,
    startedAt,
    lastDropDate
  })) {
    return 'drop_date_has_passed';
  }
}

export function canStillDropFromCourseEnrollmentByDate({
  onDemand,
  onDemandDropDateDaysAfterStartDate,
  startedAt,
  lastDropDate
}) {
  const lastDateToDrop = getLastDateToDropFromCourseEnrollment({
    onDemand,
    onDemandDropDateDaysAfterStartDate,
    startedAt,
    lastDropDate
  });

  if (!lastDateToDrop) {
    return true;
  }

  return Date.now() <= lastDateToDrop.getTime();
}
