import type { ParsedObjectsResult } from 'read-excel-file';
import readXlsxFile from 'read-excel-file';

import readCSVFile from './readCsvFile';
import { removeTrailingAsterisk } from './removeTrailingAsterisk';

type Cell = string | number | boolean | typeof Date;
type Row = Cell[];

export type Error = ParsedObjectsResult<never>['errors'][number];

export interface ParseFileReturn {
  tableData: Row[];
  parsedData: {
    rows: object[];
    errors: Error[];
  };
  headerRow: Row;
}

export async function parseFile(file: File, { schema }): Promise<ParseFileReturn> {
  const fileExt = file.name.split('.').pop();

  if (fileExt === 'xlsx') {
    const tableData = await readXlsxFile(file);
    const parsedData = await readXlsxFile(file, {
      schema,
      includeNullValues: true,
      transformData: (rows) => {
        if (rows.length > 0) {
          rows[0] = removeTrailingAsterisk(rows[0]);
        }
        return rows;
      }
    });

    const headerRow = tableData.length > 0 ? tableData.shift() as Row : [];

    return {
      headerRow: removeTrailingAsterisk(headerRow),
      tableData,
      parsedData
    };
  }

  if (fileExt === 'csv') {
    const tableData = await readCSVFile(file) as ParseFileReturn['tableData'];
    const parsedData = await readCSVFile(file, {
      schema,
      includeNullValues: true
    }) as ParseFileReturn['parsedData'];

    const headerRow = tableData.length > 0 ? tableData.shift() as Row : [];

    return {
      headerRow: removeTrailingAsterisk(headerRow),
      tableData,
      parsedData
    };
  }

  throw new Error('Unsupported file format');
}
