import React from 'react';
import type { FC } from 'react';
import type { RowSelectionState } from '@tanstack/react-table';

import { useTranslate } from '@acadeum/translate';
import { getEnrollmentTypeLabel } from '@acadeum/helpers';

import { DataBlock, FormModal, Tag, Text } from '@acadeum/ui';
import type { FormModalProps } from '@acadeum/ui';

import SectionDate from '../SectionDate';
import { getNonProcessedEnrollments } from '../ModalInfo/ModalInfo';
import ReasonFormFieldUi from '../../../../components/ReasonFormFieldUi';

import styles from '../ModalInfo/ModalInfo.module.scss';

interface ModalDenyProps extends Pick<FormModalProps, 'onHide' | 'onSubmit'> {
  // eslint-disable-next-line
  data: any;
  checkedRows: RowSelectionState[];
}

const ModalDeny: FC<ModalDenyProps> = ({
  onHide,
  onSubmit,
  data,
  checkedRows
}) => {
  const t = useTranslate('EnrollmentRequests');

  const studentEnrollments = getNonProcessedEnrollments(data);

  return (
    <FormModal
      show
      size="wide"
      onHide={onHide}
      onBack={onHide}
      title={t('titleDeny')}
      onSubmit={onSubmit}
      submitText={t('deny')}
      onCancel={onHide}
    >
      <div className={styles.ModalInfo__block}>
        <Text variant="subtitle2" className={styles['ModalInfo__block-title']}>
          {t('titleModal', {
            tag: () => <Tag>{getEnrollmentTypeLabel(data.enrollments[0])}</Tag>
          })}
        </Text>
        <SectionDate title={t('course')}>
          <DataBlock type="course" course={data.section.session.course}/>
        </SectionDate>
        <SectionDate title={t('homeInstitution')}>
          <DataBlock type="institution" hideLogo institution={data.institution}/>
        </SectionDate>
        <SectionDate title={t('denyStudents', {
          checkedStudents: Object.keys(checkedRows).length,
          allStudents: studentEnrollments.length
        })}>
          {studentEnrollments.filter(_ => checkedRows[_.id]).map(_ => (
            <DataBlock showId={false} type="student" student={_.student} key={_.id}/>
          ))}
        </SectionDate>
      </div>
      <ReasonFormFieldUi
        required
        labelsPath="EnrollmentRequest.denyReason"
        options={DENY_REASONS}
      />
    </FormModal>
  );
};
export default ModalDeny;

const DENY_REASONS = [
  'COURSE_AT_CAPACITY',
  'COURSE_CANCELLED',
  'NOT_ALIGN_WITH_REQUIREMENT'
];
