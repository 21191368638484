import type { FC } from 'react';
import React, { useMemo } from 'react';

import { createColumnHelper } from '@tanstack/react-table';

import formatUserName from 'common-lib/lib/formatUserName';

import { useTranslate } from '@acadeum/translate';
import { Separator, Link, Text, Table, Blank, DataBlock } from '@acadeum/ui';
import type { History } from '@acadeum/types';

import {
  AUTH_HISTORY_MAX_ENTRIES,
  getHistoryEventLabel,
  getHistoryEventDescription
} from './historyEvent';

import ShortcutIcon from './shortcut.svg';

import styles from './UserAuthHistoryTable.module.scss';
import { useSettingsRoutes } from '../../hooks/useSettingsRoutes';

interface UserAuthHistoryTableProps {
  history?: History[];
  hideTitle?: boolean;
}

const columnHelper = createColumnHelper<History>();

export const UserAuthHistoryTable: FC<UserAuthHistoryTableProps> = ({
  hideTitle,
  history
}) => {
  const t = useTranslate('shared-admin-ui.UserAuthHistoryTable');
  const { getUserDetailsUrl } = useSettingsRoutes();

  const getCellHasError = ({ row }) => Boolean(row.original.error);

  const columns = useMemo(() => [
    columnHelper.accessor('event', {
      header: t('columns.event'),
      meta: { getCellHasError },
      cell: ({ getValue }) => getHistoryEventLabel(getValue(), { t })
    }),
    columnHelper.display({
      id: 'description',
      header: t('columns.description'),
      meta: { getCellHasError },
      cell: ({ row }) => getHistoryEventDescription(row.original.event, { t })
    }),
    columnHelper.display({
      id: 'initiatedBy',
      header: t('columns.initiatedBy'),
      meta: { getCellHasError },
      cell: ({ row }) => {
        if (row.original.event.automatic) {
          return 'Automatic';
        }
        if (row.original.adminUser) {
          return (
            <span>
              By
              {' '}
              <Link to={getUserDetailsUrl(row.original.adminUser.id)}>
                {formatUserName(row.original.adminUser)}
              </Link>
            </span>
          );
        }
        return <Blank/>;
      }
    }),
    columnHelper.accessor('date', {
      meta: { getCellHasError },
      header: t('columns.date'),
      cell: ({ getValue }) => (
        <DataBlock
          type="date"
          withTime
          date={getValue()}
          month="numeric"
          utc={false}
        />
      )
    }),
    columnHelper.accessor('ip', {
      header: t('columns.ipAddress'),
      meta: { getCellHasError },
      cell: ({ getValue }) => (
        <Link external to={`https://infobyip.com/ip-${getValue()}.html`}>
          {getValue()}
        </Link>
      )
    }),
    columnHelper.accessor('error', {
      header: t('columns.error'),
      meta: { getCellHasError },
      cell: ({ row: { original: { error } } }) => (
        <div>
          {error === true ? 'true' : null}
          {typeof error === 'object' && error !== null && (
            Object.keys(error).map((key, index) => (
              <React.Fragment key={key}>
                {!!index && ' '}
                {!!index && <span style={{ color: 'black' }}>|</span>}
                {!!index && ' '}
                {key}: {error[key]}
              </React.Fragment>
            ))
          )}
        </div>
      )
    })
  ], []);

  return (
    <>
      {!hideTitle && (
        <Separator alignLeft mb="md">
          {t('history')}
        </Separator>
      )}

      <Text className={styles.UserAuthHistoryTable__help}>
        {t('message', {
          count: history?.length === AUTH_HISTORY_MAX_ENTRIES ? AUTH_HISTORY_MAX_ENTRIES : ''
        })}
      </Text>

      {history && history.length > 0 ? (
        <Table
          hidePagination
          hideTotalResultsCount
          columns={columns}
          data={history}
          emptyRows={AUTH_HISTORY_MAX_ENTRIES - history.length}
        />
      ) : (
        <div className={styles.UserAuthHistoryTable__skeleton}>
          <ShortcutIcon/>
          <p>
            {t('emptyTableMessage')}
          </p>
        </div>
      )}
    </>
  );
};
