import React from 'react';

import { CreditCardIcon } from '@acadeum/icons';
import { useTranslate } from '@acadeum/translate';
import { Button, EmptyState } from '@acadeum/ui';

import { FINANCIAL_SCREEN, useFinancialNavigationContext } from '../../context';

import styles from './FinancialStartScreen.module.scss';

export function FinancialStartScreen() {
  const { changeView } = useFinancialNavigationContext();
  const t = useTranslate('shared-admin-ui.FinancialStartScreen');

  const onStart = () => {
    changeView({
      screen: FINANCIAL_SCREEN.STEPPER,
      activeStep: 0
    });
  };

  return (
    <EmptyState icon={CreditCardIcon}>
      <div className={styles.FinancialStartScreen}>
        <h2>
          {t('title')}
        </h2>

        <p>
          {t('description')}
        </p>

        <Button className={styles.FinancialStartScreen__btn} onClick={onStart}>
          {t('start')}
        </Button>
      </div>
    </EmptyState>
  );
}
