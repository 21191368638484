import type { RtkApi } from '@acadeum/helpers';
import type { Id, Student } from '@acadeum/types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CreateStudentUserInput extends Pick<Student, 'firstName'| 'lastName'| 'email'| 'hiStudentId'> {}
export interface CreateStudentUserOutput extends Pick<Student, 'firstName'| 'lastName'| 'email'| 'hiStudentId' | 'id'> {
  knownStudent: {
    id: Id;
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchStudentUserOutput extends Pick<Student, 'firstName' | 'lastName'| 'email'| 'hiStudentId' | 'id'> {}

export interface UpdateStudentUserInput {
  id: Id,
  body: Pick<Student, 'firstName' | 'lastName' | 'hiStudentId'>
} 
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UpdateStudentUserOutput extends Pick<Student, 'firstName' | 'lastName'| 'email'| 'hiStudentId' | 'id'> {}

export const getStudentUsersApi = (rtkApi: RtkApi) => rtkApi
  .enhanceEndpoints({addTagTypes: ['studentUsers']})
  .injectEndpoints({
    endpoints: build =>({
      fetchStudentUser: build.query<FetchStudentUserOutput, Id>({
        query: (id) => ({
          url: `/student-users/${id}`
        })
      }),
      createStudentUser: build.mutation<CreateStudentUserOutput, CreateStudentUserInput>({
        query: body => ({
          url: '/student-users',
          method: 'POST',
          body
        })
      }),
      updateStudentUser: build.mutation<UpdateStudentUserOutput, UpdateStudentUserInput>({
        query: ({ id, body }) => ({
          url: `/student-users/${id}`,
          method: 'PUT',
          body
        }),
        invalidatesTags: ['studentUsers']
      }),
      activateStudentUser: build.mutation<void, Id>({
        query:(id) => ({
          url: `/student-users/${id}/activate`,
          method: 'POST'
        })
      }),
      deactivateStudentUser: build.mutation<void, Id>({
        query:(id) => ({
          url: `/student-users/${id}/deactivate`,
          method: 'POST'
        })
      }),
      deleteStudentUser:  build.mutation<void, Id>({
        query:(id) => ({
          url: `/student-users/${id}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['studentUsers']
      })
    })
  });
