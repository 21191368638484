import { createContext, useContext, useMemo, useState } from 'react';

export enum FINANCIAL_SCREEN {
  FINANCIAL_START = 'FINANCIAL_START',
  STEPPER = 'STEPPER',
  DASHBOARD = 'DASHBOARD',
  MANAGE_BANK_ACCOUNT = 'MANAGE_BANK_ACCOUNT',
  MANAGE_NEW_BANK_ACCOUNT = 'MANAGE_NEW_BANK_ACCOUNT',
  MANAGE_CREDIT_CARD = 'MANAGE_CREDIT_CARD'
}

export interface ChangeViewOptions {
  screen?: FINANCIAL_SCREEN;
  activeStep?: number;
}

interface FinancialNavigationContextType {
  screen: FINANCIAL_SCREEN;
  activeStep?: number;
  changeView: ({ screen, activeStep }: ChangeViewOptions) => void;
}

export const FinancialNavigationContext = createContext<FinancialNavigationContextType | undefined>(undefined);

export function useFinancialNavigationContext() {
  const context = useContext(FinancialNavigationContext);
  if (!context) {
    throw new Error('No PaymentSettingsPage was provided. Your component must be wrapped in a <PaymentSettingsPage/> component.');
  }
  return context;
}

export function FinancialNavigationProvider({ children, initialScreen }) {
  const [screen, setScreen] = useState<FINANCIAL_SCREEN>(initialScreen);
  const [activeStep, setActiveStep] = useState(0);

  const changeView = ({ screen, activeStep = 0 }: ChangeViewOptions) => {
    if (screen) {
      setScreen(screen);
    }
    if (typeof activeStep === 'number') {
      setActiveStep(activeStep);
    }
  };

  const context = useMemo(() => ({
    screen,
    activeStep,
    changeView
  }), [screen, activeStep, changeView]);

  return (
    <FinancialNavigationContext.Provider value={context}>
      {children}
    </FinancialNavigationContext.Provider>
  );
}
