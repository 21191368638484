import { userHasPermission } from '@acadeum/helpers';
import { TeachingPaymentHistoryPage } from '@acadeum/shared-admin-ui';

import { useFetchInvoiceQuery } from '../../api/invoices';
import { useFetchTransfersQuery, useLazyFetchTransfersQuery } from '../../api/stripe';
import type { AppPage } from '../../helpers/app.types';

const TeachingPaymentHistory: AppPage = () => {
  return (
    <TeachingPaymentHistoryPage
      useFetchInvoiceQuery={useFetchInvoiceQuery}
      useFetchTransfersQuery={useFetchTransfersQuery}
      useLazyFetchTransfersQuery={useLazyFetchTransfersQuery}
    />
  );
};

TeachingPaymentHistory.meta = () => ({
  title: 'Payment History'
});

TeachingPaymentHistory.when = ({ user }) => user?.institution.teaching && userHasPermission(user, 'paymentMethod:read', {
  orgId: user?.institution.id,
  ownerId: null
});

export default TeachingPaymentHistory;
