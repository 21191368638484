import PropTypes from 'prop-types';
import { $convertToMarkdownString, TRANSFORMERS } from '@lexical/markdown';
import { useController } from 'react-hook-form';

import { LexicalEditor } from '../../../LexicalEditor';

export function FormMarkdownInput({
  name,
  rules,
  defaultValue,
  ...rest
}) {
  const { field } = useController({
    name,
    rules,
    defaultValue
  });


  const onChange = (editorState) => {
    editorState.read(() => {
      const markdown = $convertToMarkdownString(TRANSFORMERS);
      field.onChange(markdown);
    });
  };

  return (
    <LexicalEditor
      {...rest}
      {...field}
      markdown
      initialValue={field.value}
      onChange={onChange}
    />
  );
}

FormMarkdownInput.propTypes = {
  name: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.string
};
