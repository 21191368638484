import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from '../Link';

import './ConsortiumSearchResult.sass';

export default function ConsortiumSearchResult({
  children: consortium,
  instantBack = true,
  className
}) {
  className = classNames(className, 'InstantSearchResult--card');
  return (
    <Link
      to={`/consortia/${consortium.vanityUrl || consortium.id}`}
      className={classNames(className, 'ConsortiumCard-link')}
      gaLabel={consortium.name}
      instantBack={instantBack}>
      <ConsortiumSearchResultCard
        consortium={consortium}/>
    </Link>
  );
}

ConsortiumSearchResult.propTypes = {
  children: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    shortName: PropTypes.string,
    logoUrl: PropTypes.string.isRequired,
    memberCount: PropTypes.number.isRequired
  }).isRequired,
  instantBack: PropTypes.bool,
  className: PropTypes.string
};

function ConsortiumSearchResultCard({
  consortium: {
    name,
    shortName,
    logoUrl,
    memberCount
  }
}) {
  return (
    <section className="ConsortiumCard-content">
      <div className="ConsortiumCard-logoAndName">
        <div className="ConsortiumCard-logoContainer">
          <img
            src={logoUrl}
            alt={`${name} logo`}
            className="ConsortiumCard-logo" />
        </div>

        <div className="ConsortiumCard-nameContainer">
          <h1 className="ConsortiumCard-name">
            {name.length > 30 && shortName ? shortName : name}
          </h1>
          <span className="ConsortiumCard-memberCount">
            {memberCount} institution{memberCount === 1 ? '' : 's'}
          </span>
        </div>
      </div>
    </section>
  );
}

ConsortiumSearchResultCard.propTypes = {
  // `link` property is just a temporary hack
  // until the Acadeum site has its own institution cards.
  consortium: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    shortName: PropTypes.string,
    logoUrl: PropTypes.string.isRequired,
    memberCount: PropTypes.number.isRequired
  }).isRequired
};
