import type { FC } from 'react';
import React from 'react';

import { formatDate } from '../../../utils/format';

import type { DateRangePickerProps } from '../../DateRangePicker';
import { DateRangePicker } from '../../DateRangePicker';

import type { BaseFilterProps } from '../context';
import { useRegisterFilter } from '../context';

export interface DateRangeFilterProps extends Omit<BaseFilterProps, 'getFilterLabel'>, Omit<DateRangePickerProps, 'name' | 'label' | 'value' | 'onChange'> {
  onChange?: (value: { from: Date | null, to: Date | null }) => void;
  getFilterLabel?: (value: { from: Date | null, to: Date | null }, defaultLabel: string) => string;
}

export const DateRangeFilter: FC<DateRangeFilterProps> = ({
  name,
  label,
  getFilterLabel: propsGetFilterLabel,
  onChange: propsOnChange,
  ...rest
}) => {
  const getFilterLabel = (value) => {
    const defaultLabel = getDefaultLabel(value);
    if (propsGetFilterLabel) {
      return propsGetFilterLabel(value, defaultLabel);
    }
    return defaultLabel;
  };

  const {
    option,
    onChange: onChange_
  } = useRegisterFilter({ name, label, propsOnChange, getFilterLabel });

  const onChange: DateRangePickerProps['onChange'] = (range) => {
    onChange_?.({
      from: range?.[0],
      to: range?.[1]
    });
  };

  return (
    <DateRangePicker
      {...rest}
      isFilter
      utc
      label={label}
      value={option ? [option.value.from, option.value.to] : undefined}
      onChange={onChange}
    />
  );
};

function getDefaultLabel({ from, to }) {
  if (from && to) {
    return `${formatDate(from, { month: 'short', utc: false })} - ${formatDate(to, { month: 'short', utc: false })}`;
  }

  if (from) {
    return `${formatDate(from, { month: 'short', utc: false })} - ∞`;
  }

  if (to) {
    return `∞ - ${formatDate(to, { month: 'short', utc: false })}`;
  }

  return 'Any date';
}
