import React, { useState } from 'react';
import classNames from 'classnames';

import type { RowData, Table } from '@tanstack/react-table';

import { CaretDownIcon } from '@acadeum/icons';
import { useTranslate } from '@acadeum/translate';

import { BaseButton } from '../../../BaseButton';
import type { CheckboxProps } from '../../../Checkbox';
import { Checkbox } from '../../../Checkbox';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../../DropdownMenu';

import styles from './MultiSelectDropdown.module.scss';
import { Icon } from '../../../Icon';

export interface MultiSelectDropdownProps<TData> {
  table: Table<TData>;
  canSelectAll: boolean;
  rowSelectionType?: CheckboxProps['type'];
}

export const MultiSelectDropdown = <TData extends RowData>({
  table,
  canSelectAll,
  rowSelectionType
}: MultiSelectDropdownProps<TData>) => {
  const t = useTranslate('ui.Table');

  const [show, setShow] = useState(false);

  const ariaChecked = canSelectAll ? table.getIsAllRowsSelected() : table.getIsSomeRowsSelected() ? 'mixed' : false;
  const ariaLabel = `Choose selection, ${table.getSelectedRowModel().rows.length} selected rows`;

  return (
    <DropdownMenu open={show} onOpenChange={setShow}>
      <DropdownMenuTrigger asChild>
        <BaseButton
          title={ariaLabel}
          aria-label={ariaLabel}
          aria-checked={ariaChecked}
        >
          {/*
          https://acadeum.atlassian.net/browse/AFV-2
          https://acadeum.atlassian.net/browse/AFV-6
          Checkbox is used here only for its visual representation.
          It's not a real checkbox, so it doesn't have to be focusable or interactive and should not be announced by screen readers.
          */}
          <Checkbox
            tabIndex={-1}
            aria-hidden
            role="presentation"
            type={rowSelectionType}
            checked={canSelectAll ? table.getIsAllRowsSelected() : undefined}
            indeterminate={table.getIsSomeRowsSelected()}
          />
          <Icon icon={CaretDownIcon} className={styles.toggleSvg}/>
        </BaseButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className={styles.content}>
        {canSelectAll && (
          <DropdownMenuItem
            onSelect={() => table.toggleAllRowsSelected(!table.getIsAllRowsSelected())}
          >
            {t('toggleAll')}
          </DropdownMenuItem>
        )}
        <DropdownMenuItem
          onSelect={() => table.toggleAllPageRowsSelected(!table.getIsAllPageRowsSelected())}
        >
          {t('toggleAllOnThisPage')}
        </DropdownMenuItem>
        <DropdownMenuItem
          onSelect={() => table.resetRowSelection(true)}
        >
          {t('none')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

// eslint-disable-next-line
const CustomToggle = React.forwardRef<any, any>(({ children, ...rest }, ref) => {
  return (
    <BaseButton
      {...rest}
      ref={ref}
      className={styles.toggle}
    >
      {children}
      <CaretDownIcon className={classNames(styles.toggleSvg, {
        [styles['toggleSvg--expanded']]: rest['aria-expanded']
      })}/>
    </BaseButton>
  );
});

// eslint-disable-next-line
const CustomItem = React.forwardRef<any, any>(({ children, onClick, ...rest }, ref) => {
  return (
    <BaseButton ref={ref} className={styles.item} onClick={onClick}>
      {children}
    </BaseButton>
  );
});
