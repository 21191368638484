import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const submitHubSpotForm = redux.action(
  (formId, payload) => async (http) => {
    const portalId = 7064296;
    await http.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`, payload);
  }
);

export default redux.reducer();
