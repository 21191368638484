import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'react-responsive-ui';
import classNames from 'classnames';

import {
  getEndDateMessageForOnDemandCourseEnrollment,
  getLastDropDateMessageForOnDemandCourseEnrollment
} from '@acadeum/helpers';

import Outputs from '../Outputs';
import Output from '../Output';
import Row from '../Row';

import './AdvisorRecommendationSection.sass';

export default function AdvisorRecommendationSection({
  section,
  onDemand,
  value,
  onChange,
  selectable,
  error,
  onDemandCourseDropDateDaysAfterStartDate,
  onDemandCourseEndDateDaysAfterStartDate
}) {
  const { session } = section;

  // const [selected, setSelected] = useState();

  // const onSectionToggle = () => {
  //   const value = !selected;
  //   setSelected(value);
  //   onChange(value);
  // };

  return (
    <div className={classNames('AdvisorRecommendationSection', {
      'AdvisorRecommendationSection--selectable': selectable
    })}>
      {selectable &&
        <Checkbox
          value={value}
          onChange={onChange}
          error={error}
          className="form__field AdvisorRecommendationSection-checkbox"
        />
      }

      {onDemand ? (
        <Outputs>
          <Row>
            <Output
              label="Schedule"
              value={'On-Demand'}
            />
            <Output
              label="Drop Date"
              value={getLastDropDateMessageForOnDemandCourseEnrollment(onDemandCourseDropDateDaysAfterStartDate)}
            />
            <Output
              label="End Date"
              value={getEndDateMessageForOnDemandCourseEnrollment(onDemandCourseEndDateDaysAfterStartDate)}
            />
          </Row>
        </Outputs>
      ) : (
        <Outputs>
          <Row>
            <Output
              label="Term"
              value={session.term}
              col={3}
            />
            <Output
              label="Session"
              value={session.name}
              col={3}
            />
            <Output
              label="Dates"
              type="date"
              values={[session.startDate, session.endDate]}
              col={6}
              utc
            />
          </Row>

          <Row>
            <Output
              label="Add Date"
              type="date"
              value={session.lastAddDate}
              col={3}
              utc
            />
            <Output
              label="Drop Date"
              type="date"
              value={session.lastDropDate}
              col={3}
              utc
            />
          </Row>
        </Outputs>
      )}

    </div>
  );
}

AdvisorRecommendationSection.propTypes = {
  section: PropTypes.shape({
    session: PropTypes.object.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  selectable: PropTypes.bool,
  error: PropTypes.bool,
  onDemandCourseDropDateDaysAfterStartDate: PropTypes.number
};
