let isLoggedIn = false;

export function trackHubspotUser(user) {
  // Identify Hubspot user for collecting analytics.
  // https://legacydocs.hubspot.com/docs/methods/tracking_code_api/identify_visitor
  //
  // This endpoint is used to identify a visitor to your site. The unique identifier
  // is an email address. If there is an existing contact record for that email address,
  // it will be updated. Otherwise, a new contact record will be created. In both cases,
  // the analytics data collected for the visitor will be associated with the contact record.
  //
  if (typeof _hsq !== 'undefined') {
    // This function call stores the data in the tracker, but the data is not actually
    // passed to HubSpot with this call. The data will only be passed when tracking a
    // pageview or an event (with either the trackPageView or trackEvent functions).
    _hsq.push(['identify', {
      // You must include an email address to tie the data to a contact.
      email: user.email,
      // In addition to identifying a record by email address,
      // you can also use a custom external ID to identify a visitor,
      // by simply using 'id' as the identifier.  As with email, identifying
      // a record using 'id will associate analytics data for the visitor with that ID.
      // However, unlike with an email address, including an 'id' by itself will not
      // create a contact. Also, this 'id' is treated as a completely external identity,
      // so while analytics data can be associated with a specific contact record by the ID
      // (if, for example, you've previously identified a record by ID and by email, or the
      //  record was previously identified by ID and the visitor also has a form submission),
      // the contact record cannot be looked up by this ID.
      id: user.id
    }]);
    // Set "logged in" flag.
    isLoggedIn = true;
    // Track the initial page view.
    onPageView(window.location.pathname + window.location.search + window.location.hash);
  }
}

export function untrackHubspotUser() {
  // HubSpot API doesn't seem to provide any type of an "uninitialize" method.
  isLoggedIn = false;
}

export function onPageView(url) {
  if (isLoggedIn) {
    if (typeof _hsq !== 'undefined') {
      _hsq.push(['setPath', url]);
      _hsq.push(['trackPageView']);
    }
  }
}
