// Also there're standard Chart.js colors:
// https://www.chartjs.org/docs/3.4.1/samples/utils.html
const COLORS = [
  [255, 99, 132],
  [54, 162, 235],
  [255, 206, 86],
  [108, 66, 222],
  [190, 30, 180],
  [2, 199, 150],
  [208, 0, 0],
  [191, 120, 37],
  [211, 218, 97],
  [122, 153, 116],
  [135, 191, 196],
  [109, 113, 166],
  [151, 102, 155],
  [174, 111, 114]
];

// Generate the list of years.
const MIN_YEAR = 2016;
const MAX_YEAR = new Date().getFullYear();
const YEARS = [];
let year = MIN_YEAR;
while (year <= MAX_YEAR) {
  YEARS.push(year);
  year++;
}

const GRAPH_COLORS_BY_YEAR = {};
for (const year of YEARS) {
  const color = COLORS[(year - MIN_YEAR - 1) % COLORS.length];
  GRAPH_COLORS_BY_YEAR[year] = {
    borderColor: year === MIN_YEAR ? 'black' : `rgb(${color[0]}, ${color[1]}, ${color[2]})`,
    backgroundColor: year === MIN_YEAR ? 'white' : `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.2)`
  };
}

const DEFAULT_COLOR = [135, 179, 2];
const GRAPH_COLOR_DEFAULT = {
  borderColor: `rgb(${DEFAULT_COLOR[0]}, ${DEFAULT_COLOR[1]}, ${DEFAULT_COLOR[2]})`,
  backgroundColor: `rgba(${DEFAULT_COLOR[0]}, ${DEFAULT_COLOR[1]}, ${DEFAULT_COLOR[2]}, 0.2)`
};

export function getGraphOptions(year, data, { backgroundColor, borderColor, stacked } = {}) {
  return {
    label: year,
    yAxisID: stacked ? year.toString() : undefined,
    lineTension: 0.0,
    backgroundColor: backgroundColor || (GRAPH_COLORS_BY_YEAR[year] || GRAPH_COLOR_DEFAULT).backgroundColor,
    borderColor: borderColor || (GRAPH_COLORS_BY_YEAR[year] || GRAPH_COLOR_DEFAULT).borderColor,
    fill: true,
    pointRadius: 1,
    pointHitRadius: 10,
    data
  };
}

// https://www.chartjs.org/docs/3.5.0/samples/line/line.html
export const LINE_CHART_OPTIONS = {
  // Show the tooptip when not only hovering a graph point
  // but instead when hovering the graph canvas themselves.
  // https://www.chartjs.org/docs/3.5.0/samples/line/multi-axis.html
  interaction: {
    mode: 'index',
    intersect: false
  }
};
