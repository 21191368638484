import { UserManagementPage } from '@acadeum/shared-admin-ui';

import type { AppPage } from '../../helpers/app.types';

import {
  useActivateUserMutation,
  useDeactivateUserMutation,
  useDeleteUserMutation,
  useFetchUsersQuery,
  useSetUsersRolesForUsersMutation
} from '../../api/users';
import { useFetchUserRolesQuery } from '../../api/userRoles';
import { useFetchContactsQuery, useUpdateContactMutation } from '../../api/contacts';
import {
  useAdminRequestUserEmailVerificationMutation,
  useAdminRequestUserPasswordResetMutation
} from '../../api/admin/users';
import { userHasPermission } from '@acadeum/helpers';


const UserManagement: AppPage = () => {
  return (
    <UserManagementPage
      useFetchContactsQuery={useFetchContactsQuery}
      useFetchUserRolesQuery={useFetchUserRolesQuery}
      useFetchUsersQuery={useFetchUsersQuery}
      useSetUsersRolesForUsersMutation={useSetUsersRolesForUsersMutation}
      useDeleteUserMutation={useDeleteUserMutation}
      useDeactivateUserMutation={useDeactivateUserMutation}
      useUpdateContactMutation={useUpdateContactMutation}
      useActivateUserMutation={useActivateUserMutation}
      useAdminRequestUserEmailVerificationMutation={useAdminRequestUserEmailVerificationMutation}
      useAdminRequestUserPasswordResetMutation={useAdminRequestUserPasswordResetMutation}
    />
  );
};

UserManagement.meta = () => ({
  title: 'General Settings'
});

UserManagement.when = ({ user }) => (
  userHasPermission(user, 'user:read', { orgId: user?.institution.id, ownerId: null })
  &&
  userHasPermission(user, 'userRole:read', { orgId: user?.institution.id, ownerId: null })
);

export default UserManagement;
