import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const fetchVizData = redux.action(
  data => (http) => data ? http.post('/viz', data):  http.get('/viz'),
  'vizData'
);

export default redux.reducer();
