import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const fetchFinancialBreakdown = redux.action(
  (params) => async (http) => await http.get('/reports/financial-breakdown', params),
  'financialBreakdown'
);

export const fetchMonthlyEnrollments = redux.action(
  (institutionId) => async (http) => await http.get('/reports/monthly-enrollments', { institutionId }),
  'monthlyEnrollments'
);

export const fetchConsortiaMonthlyEnrollments = redux.action(
  (institutionId) => async (http) => await http.get('/consortia-monthly-enrollments', { institutionId }),
  'consortiaMonthlyEnrollments'
);

export const fetchChartConsortiaToken = redux.action(
  () => async (http) => await http.get('/charts/consortia'),
  'chartConsortiaToken'
);

export const fetchChartConsortiaTeachingToken = redux.action(
  () => async (http) => await http.get('/charts/consortia-teaching'),
  'chartConsortiaTeachingToken'
);

export const fetchChartHomeInstitutionalToken = redux.action(
  () => async (http) => await http.get('/charts/home-institutional'),
  'chartHomeInstitutionalToken'
);

export const fetchChartTeachingInstitutionalToken = redux.action(
  () => async (http) => await http.get('/charts/teaching-institutional'),
  'chartTeachingInstitutionalToken'
);

export const fetchWeeklyEnrollments = redux.action(
  (institutionId) => async (http) => await http.get('/reports/weekly-enrollments', { institutionId }),
  'weeklyEnrollments'
);

export const fetchSessionOverSessionReport = redux.action(
  () => async (http) => await http.get('/reports/session-over-session'),
  'sessionOverSession'
);

export const fetchYearlyEnrollments = redux.action(
  (institutionId) => async (http) => await http.get('/reports/yearly-enrollments', { institutionId }),
  'yearlyEnrollments'
);

export const fetchPartnerRevenue = redux.action(
  () => async (http) => await http.get('/reports/partner-revenue'),
  'partnerRevenue'
);

export const fetchWeeklyBreakdown = redux.action(
  () => async (http) => await http.get('/reports/weekly-breakdown'),
  'weeklyBreakdown'
);

export const fetchMapData = redux.action(
  () => async (http) => await http.get('/reports/map-data'),
  'mapData'
);

export const fetchProjections = redux.action(
  () => async (http) => await http.get('/projections'),
  'projections'
);

export const updateProjections = redux.action(
  (data) => http => http.patch('/projections', data)
);

export default redux.reducer();
