import React, { memo, useCallback } from 'react';
import classNames from 'classnames';
import throttle from 'lodash/throttle';

import { useEyeDropper, useStateHistory } from '@acadeum/hooks';

import { HStack, VStack } from '../Stack';

import { EyeDropperButton } from './ui/EyeDropperButton';
import { PresetColors } from './ui/PresetColors';
import { ActiveColor } from './ui/ActiveColor';
import { Saturation } from './ui/Saturation';
import { Header } from './ui/Header';
import { Fields } from './ui/Fields';
import { Alpha } from './ui/Alpha';
import { Hue } from './ui/Hue';

import { simpleCheckForValidColor, toState } from './helpers/color';
import type { ColorState } from './types';

export { toState as colorToState } from './helpers/color';

import styles from './ColorPicker.module.scss';

const colors = [
  '#d51501',
  '#fe5d01',
  '#ffa201',
  '#fff300',
  '#8ee80b',
  '#2bd587',
  '#00b1cb',
  '#0162af',
  '#002c7c',
  '#2b0076',
  '#5000a7',
  '#8600aa'
];

export type ColorValue = Omit<ColorState, 'oldHue'>

export interface ColorPickerProps {
  className?: string;
  onChange?: (color: ColorValue) => void;
  presetColors?: string[];
  value?: ColorValue | string;
  onClose?: (value: false) => void;
  label: string;
}

export const ColorPicker = memo<ColorPickerProps>(({
  className,
  onChange: propsOnChange,
  presetColors = colors,
  value,
  onClose,
  label
}) => {
  const { isSupported, close, open } = useEyeDropper();

  const throttledPropsOnChange = useCallback(throttle(value => {
    propsOnChange?.(value);
  }), [propsOnChange]);

  const [color, setColor, { back, forward }] = useStateHistory<ColorState, ColorState>(
    toState(value, 0),
    {
      onNavigate: (value) => throttledPropsOnChange?.(value)
    }
  );

  const onChange = (data) => {
    const newColor = { ...color.hsv, ...data };
    const updatedValue = simpleCheckForValidColor(newColor) ? toState(newColor, newColor.h) : color;
    throttledPropsOnChange?.(updatedValue);
    setColor(updatedValue);
  };

  const pickColor = async () => {
    try {
      const result = await open();
      if (result) {
        onChange({ hex: result.sRGBHex });
      }
    } catch (error) {
      console.error(error);
      close();
    }
  };

  return (
    <div className={classNames(className, styles.ColorPicker)}>
      <Header
        label={label}
        redo={forward}
        undo={back}
        onClose={onClose}
      />
      <div className={styles.saturation}>
        <Saturation
          hsva={color.hsv}
          onChange={onChange}
        />
      </div>
      <div className={styles.body}>
        <HStack gap="sm" mb="md">
          {isSupported ? (
            <EyeDropperButton onClick={pickColor}/>
          ) : (
            <ActiveColor hex={color.hex}/>
          )}
          <VStack gap="sm" style={{ flex: '1' }}>
            <div className={styles.hue}>
              <Hue
                hsva={color.hsv}
                onChange={onChange}
              />
            </div>

            <div className={styles.alpha}>
              <Alpha
                hsva={color.hsv}
                onChange={onChange}
              />
            </div>
          </VStack>
        </HStack>

        <div style={{ marginBottom: '1.5rem' }}>
          <Fields
            hsl={color.hsl}
            rgb={color.rgb}
            hex={color.hex}
            onChange={onChange}
          />
        </div>

        {presetColors && presetColors.length > 0 && (
          <PresetColors
            colors={presetColors}
            onChange={onChange}
          />
        )}
      </div>
    </div>
  );
});
