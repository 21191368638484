import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connectToggleRefinement } from 'react-instantsearch-dom';

import SwitchComponent from './InstantSearchSwitch.js';

import './InstantSearchToggle.sass';

export default class InstantSearchToggle extends React.Component {
  // Algolia search `<Toggle/>` is supposed to be passed a `value`
  // which is gonna be the refinement for the `attribute`
  // when the checkbox is "on". When the checkbox is "off"
  // no refinement is applied to the `attribute`.
  //
  // ```js
  // value: PropTypes.any,
  // attribute: PropTypes.string,
  // ````
  //
  // It's how it's defined in `connectToggle()` connector in Algolia code:
  // https://github.com/algolia/react-instantsearch/blob/e19f4dc1c40a4cb61f44ae632c8105b2af41fce8/packages/react-instantsearch/src/connectors/connectToggle.js#L55
  // "@propType {string} value - Value of the refinement to apply on `attribute`."  What they meant is that, for
  // example, a toggle could be `color="red"` and the label could be "Show red items". This is used, for example, for
  // "Consortial" toggle where "on" means `institutionId` IN `value`.  `value` is a very stupid and confusing name for
  // such property. Overall Algolia React widget developers are unprofessional. I renamed `value` to `attrubuteValue`.
  static propTypes = {
    attrubuteValue: PropTypes.any,
    title: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string
  };

  static defaultProps = {
    attrubuteValue: true
  };

  render() {
    const {
      attrubuteValue,
      title,
      ...rest
    } = this.props;
    return (
      <InstantSearchToggle_
        label={title}
        {...rest}
        value={attrubuteValue}
      />
    );
  }
}

const InstantSearchToggle__ = ({
  disabled,
  defaultRefinement,
  controlledRefinement,
  refine,
  currentRefinement,
  label,
  icon: Icon,
  className
}) => {
  // `currentRefinement` is just the state of the checkbox.
  // It's not the actual refinement applied to `attribute`.

  const prevControlledRefinement = useRef(controlledRefinement);

  useEffect(() => {
    if (controlledRefinement !== prevControlledRefinement.current) {
      if (controlledRefinement === undefined) {
        // If `controlledRefinement` property was reset to `undefined`
        // then re-apply the `defaultRefinement`.
        if (prevControlledRefinement.current !== undefined) {
          refine(defaultRefinement);
        }
      } else {
        // If `controlledRefinement` property has changed
        // then apply it as a refinement.
        refine(controlledRefinement);
      }
      // Update `prevControlledRefinement`.
      prevControlledRefinement.current = controlledRefinement;
    }
  }, [controlledRefinement]);

  return (
    <SwitchComponent
      disabled={disabled}
      value={currentRefinement}
      onChange={refine}
      className={className}
      label={label}
      Icon={Icon}
    />
  );
};

InstantSearchToggle__.propTypes = {
  refine: PropTypes.func.isRequired,
  defaultRefinement: PropTypes.bool,
  controlledRefinement: PropTypes.bool,
  currentRefinement: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  icon: PropTypes.func,
  className: PropTypes.string
};

const InstantSearchToggle_ = connectToggleRefinement(InstantSearchToggle__);
