import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

// A global utility for tracking the current input method (mouse, keyboard or touch).
// https://github.com/ten1seven/what-input/
import 'what-input';

import { getDeepDataFromStorage, removeDataFromStorage, saveDeepDataToStorage } from '@acadeum/helpers';

import { onNavigate } from './helpers/googleAnalytics';

import { setStore } from './store.js';

// Run tests.
import { render } from 'react-pages/client';
import reactPagesConfig from './react-pages.js';

TimeAgo.addDefaultLocale(en);

// The rest of the code here can be removed after migrating to Next.js.
// https://github.com/Acadeum/Tickets/issues/1009

// TODO: Remove this after one month. Planning next release on 2024-07-16 so this can be removed after 2024-08-16.
// After this date, remove the data update and replace it with a delete,
// as this will be enough time to update the saved states and migrate to save database search.
const updateSearchesLocalStorageData = () => {
  const mainKey = 'Acadeum.Home.CourseSearch';
  const views = ['sections', 'courses'];
  const scopes = ['all', 'own'];

  const keys = views.flatMap(view => scopes.map(scope => `${mainKey}.${view}.${scope}`));

  // TODO: Uncomment this after one month. Planning next release on 2024-07-16 so this can be removed after 2024-08-16.
  // for (const key of keys) {
  //   removeDeepDateFromStorage(key)
  // }

  // TODO: Remove this `for...of` loop after one month. Planning next release on 2024-07-16 so this can be removed after 2024-08-16.
  for (const key of keys) {
    const savedSearches = getDeepDataFromStorage(key);
    if (Array.isArray(savedSearches)) {
      // Convert array to object
      // [{ title: '...', searchState: { ... } }] => { [title]: { searchState: { ... } } }
      const newSavedSearches = savedSearches.reduce((acc, { title, searchState }) => {
        return { ...acc, [title]: { searchState } };
      }, {});
      saveDeepDataToStorage(key, newSavedSearches);
    }
  }

  // Remove old saved searches from local storage
  // TODO: Remove this `removeDataFromStorage` call after one month. Planning next release on 2024-07-16 so this can be removed after 2024-08-16.
  removeDataFromStorage('ActiveSavedSearch');
};

async function run() {
  updateSearchesLocalStorageData();
  // Renders the webpage on the client side
  const { enableHotReload } = await render(reactPagesConfig, {
    // Google Analytics: track page views.
    onPageRendered: onNavigate,
    // The `store` is used in `./src/actions.js`.
    onStoreCreated: setStore
  });

  // Enables hot-reload via Webpack "Hot Module Replacement".
  if (import.meta.webpackHot) {
    enableHotReload();
  }
}

run().catch((error) => {
  console.error(error);
});
