// Pricing Model documentation:
// https://github.com/Acadeum/Tickets/blob/master/Pricing-Model.md

// TypeScript typings could be found in:
// https://github.com/Acadeum/frontend/blob/develop/packages/helpers/src/getCourseSectionPricingInfo.ts

import getLowestPriceAndPricingVariantForCourseSection from './getLowestPriceAndPricingVariantForCourseSection_.js';

/**
 * Returns course section cost for a Home Institution.
 * @param {object} parameters
 * @param {number} parameters.cost
 * @param {object} parameters.course — An object of shape `{ credits: number, level: string, teachingInstitutionId: number }`.
 * @param {object[]} [parameters.courseEnrollmentPricing] — Institution's course enrollment pricing.
 * @param {object} [parameters.courseCustomCosts]
 * @param {object} [parameters.courseSectionCustomCosts]
 * @param {number} [parameters.homeInstitutionId]
 * @param {number[]} [parameters.homeConsortiumIds]
 * @return {object} costWithSource — An object of shape `{ cost: number, source: { type: string, value: any } }`.
 */
export default function getCourseSectionCostForInstitutionWithDetails({
  cost: costParameterAtRootLevel,
  course: {
    // This parameter is deprecated. Use the `cost` parameter at the root level instead.
    cost: costParameterAtCourseLevel,
    credits,
    level,
    teachingInstitutionId
  },
  // A non-default course pricing model for the Home Institution.
  courseEnrollmentPricing,
  // "course-wide" custom costs.
  courseCustomCosts,
  // "section-wide" custom costs.
  courseSectionCustomCosts,
  // The ID of the Home Institution.
  homeInstitutionId,
  // The IDs of the consortia that the Home Institution is a member of.
  homeConsortiumIds
}) {
  // Get the `cost` parameter.
  const cost = typeof costParameterAtRootLevel === 'number' ? costParameterAtRootLevel : costParameterAtCourseLevel;

  // Validate `homeInstitutionId` parameter.
  // It was added when the `cost` parameter was moved to the top level.
  if (typeof costParameterAtRootLevel === 'number') {
    if (!homeInstitutionId) {
      throw new Error('`homeInstitutionId` parameter is required');
    }
  }

  // Validate `teachingInstitutionId` parameter.
  if (!teachingInstitutionId) {
    throw new Error('`teachingInstitutionId` parameter is required');
  }

  // If someone from a teaching instituiton finds their own course
  // then show them the "original" course section cost for this course section
  // without applying any custom home-institution-specific pricing.
  //
  // This makes sense because a teaching institution won't enroll into its own courses,
  // and showing the "original" course section cost is the one
  // that they most likely expect to see.
  //
  // For example, a teaching institution could be a member of some consortium
  // that defines a "free-of-charge" pricing model for all of the members of the consortium,
  // which would result in that teaching institution seeing all of their own courses
  // as "Free of Charge" on the platform, since they're a member of the consortium,
  // which would be confusing to them. So instead, the "original" cost should be shown in such cases.
  //
  if (teachingInstitutionId === homeInstitutionId) {
    return {
      cost,
      source: {
        type: 'cost',
        value: cost
      }
    };
  }

  // Validate custom cost parameters.
  if (courseCustomCosts) {
    if (!courseSectionCustomCosts) {
      throw new Error('When passing `courseCustomCosts` parameter, `courseSectionCustomCosts` parameter must be passed too');
    }

    // Create a "filter" function for the custom costs.
    const isCustomCostForThisHomeInstitution = (customCost) => isCustomCostForHomeInstitution(customCost, { homeInstitutionId, homeConsortiumIds });

    // Select only those `courseCustomCosts` and `courseSectionCustomCosts`
    // that correspond to this Home Institution and the consortia it is a member of.
    courseCustomCosts = courseCustomCosts.filter(isCustomCostForThisHomeInstitution);
    courseSectionCustomCosts = courseSectionCustomCosts.filter(isCustomCostForThisHomeInstitution);

    // If there's custom cost defined for this course section and this Home Institution
    // then return that custom cost, even if it's not the lowest possible one.
    // As per Caleb's comment:
    // "Differential Pricing should take precedence over consortium-based pricing models".
    //
    // First, it looks if there're any "per-section" custom cost defined for this course section.
    // Then, if there's none, it looks for a "course-wide" one.
    //
    if (courseSectionCustomCosts.length > 0) {
      // Select the lowest custom cost from the list of all custom costs.
      const cost = Math.min(...courseSectionCustomCosts.map(_ => _.cost));
      return {
        cost,
        source: {
          type: 'courseSectionCustomCost',
          value: courseSectionCustomCosts.find(_ => _.cost === cost)
        }
      };
    }
    if (courseCustomCosts.length > 0) {
      // Select the lowest custom cost from the list of all custom costs.
      const cost = Math.min(...courseCustomCosts.map(_ => _.cost));
      return {
        cost,
        source: {
          type: 'courseCustomCost',
          value: courseCustomCosts.find(_ => _.cost === cost)
        }
      };
    }
  }

  // If non-default pricing model is defined for the Home Institution,
  // use it to calculate the lowest price available.
  if (courseEnrollmentPricing) {
    const { price, pricingVariant } = getLowestPriceAndPricingVariantForCourseSection(
      cost,
      courseEnrollmentPricing,
      {
        credits,
        level,
        teachingInstitutionId
      }
    );

    if (price !== undefined) {
      return {
        cost: price,
        source: {
          type: 'pricing',
          value: pricingVariant
        }
      };
    }
  }

  // By default, show the original cost of the course section.
  return {
    cost,
    source: {
      type: 'cost',
      value: cost
    }
  };
}

// Returns `true` if the `customCost` record corresponds to the Home Institution.
function isCustomCostForHomeInstitution(customCost, {
  homeInstitutionId,
  homeConsortiumIds
}) {
  // Validate `homeInstitutionId` parameter.
  if (!homeInstitutionId) {
    throw new Error('`homeInstitutionId` parameter is required');
  }

  // Validate `homeConsortiumIds` parameter.
  if (!homeConsortiumIds) {
    throw new Error('`homeConsortiumIds` parameter is required');
  }

  if (customCost.homeInstitutionId) {
    // For an institution-level custom cost, compare the Home Institution ID.
    return customCost.homeInstitutionId === homeInstitutionId;
  } else if (customCost.homeConsortiumId) {
    // For a consortium-level custom cost, compare the Consortium IDs.
    return homeConsortiumIds.includes(customCost.homeConsortiumId);
  } else {
    // This check validates that the custom cost records have the correct structure.
    throw new Error('Each custom cost object must contain either `homeInstitutionId` or `homeConsortiumId`');
  }
}
