import { useMemo } from 'react';
import classNames from 'classnames';

import styles from './useMargin.module.scss';

export type DefaultMarginSizes = 'none' | 'auto' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
export type RevertedMarginSizes = '-xs' | '-sm' | '-md' | '-lg' | '-xl' | '-2xl' | '-3xl';
export type MarginSizes = DefaultMarginSizes | RevertedMarginSizes;
export type MarginTypes = 'mx' | 'my' | 'mt' | 'mb' | 'mr' | 'ml';
export type MarginsProp = Partial<Record<MarginTypes, MarginSizes>>

export const useMargin = (values?: {
  [key in MarginTypes]?: MarginSizes
}) => {
  return useMemo(() => {
    const result: string[] = [];
    for (const type in values) {
      result.push(styles[`${type}-margin-${values[type]}`]);
    }
    return { marginClassNames: classNames(result) };
  }, [values]);
};
