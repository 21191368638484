import { createAction } from '@reduxjs/toolkit';

import type { AuthState } from './state';

function withPayloadType<T>() {
  return (t: T) => ({ payload: t });
}

type RequiredAuthState = Required<AuthState>;

export const setUserAction = createAction('SET USER', withPayloadType<AuthState['user']>());
export const updateUserAction = createAction('UPDATE USER', withPayloadType<Partial<RequiredAuthState['user']>>());
export const loginStartedAction = createAction('LOGIN:STARTED');
export const loginSuccessfulAction = createAction('LOGIN:SUCCEEDED', withPayloadType<{
  user: RequiredAuthState['user'];
  token: RequiredAuthState['accessToken'];
  expiresIn: RequiredAuthState['accessTokenExpiresIn'];
  expiresAt: RequiredAuthState['accessTokenExpiresAt'];
}>());
export const loginFailureAction = createAction('LOGIN:FAILED');
export const notAuthenticatedAction = createAction('NOT AUTHENTICATED');
export const setAccessTokenExpiresAtAction = createAction('SET ACCESS TOKEN EXPIRES AT', withPayloadType<AuthState['accessTokenExpiresAt']>());
export const setIsAuthenticationLoadingAction = createAction('SET IS AUTHENTICATION LOADING', withPayloadType<AuthState['isAuthenticationLoading']>());
