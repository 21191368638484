import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Button, Title, Tabs } from '@acadeum/ui';
import type { TabsProps } from '@acadeum/ui';
import { PlusIcon } from '@acadeum/icons';
import { useTranslate } from '@acadeum/translate';

import { loadHomeStudentsPage } from '../../helpers/routes';

import HomeStudents from './ui/HomeStudents';
import TeachingStudents from './ui/TeachingStudents';

import actions from '../../actions';

const { fetchStudentsNoRace, getStudentsFiltersValues, goto } = actions;

interface StudentsProps {
  type?: 'EM' | 'TM';
}

import styles from './Students.module.scss';

const Students = ({ type = 'EM' }: StudentsProps) => {
  const t = useTranslate('Students');

  const tabs: TabsProps['tabs'] = useMemo(() => [
    {
      title: t('enrollingStudents'),
      key: 'EM',
      body: <HomeStudents />
    },
    {
      title: t('teachingStudents'),
      key: 'TM',
      body: <TeachingStudents />
    }
  ], []);

  return (
    <>
      <div className={styles.header}>
        <Title marginBottom="none">
          {t('title')}
        </Title>
        {type === 'EM' && (
          <Button icon={PlusIcon} variant="secondary" url="/students/add">
            {t('btnCreateStudent')}
          </Button>
        )}
      </div>
      <Tabs
        keepMounted={false}
        title="students"
        initialActiveTab={type}
        onChangeTab={({ key }) => goto(`/students/${key === 'TM' ? 'teaching' : ''}`)}
        tabs={tabs}
      />
    </>
  );
};

Students.propTypes = {
  students: PropTypes.arrayOf(PropTypes.object),
  allStudents: PropTypes.arrayOf(PropTypes.object),
  studentsNoRace: PropTypes.arrayOf(PropTypes.object),
  studentsNoRaceCount: PropTypes.number,
  location: PropTypes.object
};

Students.meta = () => ({
  title: 'Enrolling Students'
});

Students.load = async ({ location, user }) => {
  if (location.query.raceAndEthnicityNotSpecified) {
    await fetchStudentsNoRace();
  }
  await loadHomeStudentsPage({ user });
  await getStudentsFiltersValues(['level', 'major', 'advisorName']);
};

Students.breadcrumbs = () => ['Students'];

export default Students;
