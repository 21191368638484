import { useFindInstitutionByEmailDomain as useFindInstitutionByEmailDomain_ } from '@acadeum/hooks';

import { getAlgoliaIndex } from '../helpers/algolia';

export default function useFindInstitutionByEmailDomain(options) {
  return useFindInstitutionByEmailDomain_({
    ...options,
    getAlgoliaIndex
  });
}
