import type { FC } from 'react';
import React, { useMemo } from 'react';

import formatUserName from 'common-lib/lib/formatUserName';

import type { ModalProps, OnSubmit } from '@acadeum/ui';
import { Alert, ConfirmActionModal, ContentLoading, FormField, FormModal, Text, toast } from '@acadeum/ui';
import type { User } from '@acadeum/types';
import { useTranslate } from '@acadeum/translate';
import type {
  FetchContactsOutput,
  UseDeactivateUserMutation,
  UseFetchUsersQuery,
  UseUpdateContactMutation
} from '@acadeum/api';

interface FormValues {
  [role: string]: User['id'];
}

export interface DeactivateUserModalProps extends Pick<ModalProps, 'show' | 'onHide'> {
  contacts: FetchContactsOutput;
  user: Pick<User, 'id' | 'firstName' | 'middleName' | 'lastName' | 'title'>;
  onSuccessfulSubmit?: () => void;
  useUpdateContactMutation: UseUpdateContactMutation;
  useFetchUsersQuery: UseFetchUsersQuery;
  useDeactivateUserMutation: UseDeactivateUserMutation;
}

export const DeactivateUserModal: FC<DeactivateUserModalProps> = ({
  onHide,
  show,
  user,
  contacts,
  onSuccessfulSubmit,
  useUpdateContactMutation,
  useFetchUsersQuery,
  useDeactivateUserMutation
}) => {
  const t = useTranslate('shared-admin-ui.DeactivateUserModal');
  const tContactsPage = useTranslate('shared-admin-ui.ContactsPage');

  const [updateContactMutation] = useUpdateContactMutation();
  const [deactivateUserMutation] = useDeactivateUserMutation();

  const {
    isLoading: isLoadingUsers,
    data: users
  } = useFetchUsersQuery({
    filters: {
      status: 'ACTIVE'
    }
  });

  const usersOptions = useMemo(() => {
    return users?.filter(_ => _.id !== user.id)
      .map(user => ({ value: user.id, label: formatUserName(user) }));
  }, [users]);

  const assignedContacts = useMemo(() => contacts.filter(contact => contact.user.id === user.id), [contacts, user.id]);

  const onConfirmDeactivate = async () => {
    try {
      await deactivateUserMutation({ id: user.id }).unwrap();
      toast.success(t('successMessage'));
      onHide(false);
      onSuccessfulSubmit?.();
    } catch (error) {
      console.error(error);
      toast.error(t('errorMessage'));
    }
  };

  const onSubmitReassignRoles: OnSubmit<FormValues> = async (values) => {
    console.log('values', values);

    const promises = assignedContacts.map(contact => {
      return updateContactMutation({
        id: contact.id,
        type: contact.type,
        userId: values[contact.type]
      }).unwrap();
    });

    await Promise.all(promises);
    await onConfirmDeactivate();
  };

  if (assignedContacts.length === 0) {
    return (
      <ConfirmActionModal
        show={show}
        onHide={onHide}
        onCancel={onHide}
        onSubmit={onConfirmDeactivate}
        title={t('title')}
        description={t('description', {
          userName: formatUserName(user),
          strong: (text) => <strong>{text}</strong>
        })}
        submitText={t('submitText')}
      />
    );
  }

  return (
    <FormModal
      submitText="Next"
      title={t('title')}
      show={show}
      onHide={onHide}
      onSubmit={onSubmitReassignRoles}
      onCancel={onHide}
      formSubmitProps={{ disabled: isLoadingUsers }}
    >
      <Text mb="md">
        {t('descriptionEditContact', {
          roleContact: (
            <strong>
              {assignedContacts.map((contact, index, array) => {
                const text = tContactsPage(`${contact.type}.title`);
                return (array.length !== index + 1) ? `${text}, ` : text;
              })}
            </strong>
          )
        })}
      </Text>
      {isLoadingUsers ? (
        <ContentLoading/>
      ) : (
        <>
          {assignedContacts.map(roleContact => (
            <FormField
              key={roleContact.id}
              required
              name={roleContact.type}
              type="select"
              options={usersOptions}
              label={tContactsPage(`${roleContact.type}.title`)}
            />
          ))}
          <Alert>
            {t('createUser')}
          </Alert>
        </>
      )}
    </FormModal>
  );
};
