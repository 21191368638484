import Joi from 'joi';

export const object: typeof Joi.object = (schema) => Joi.object(schema);
export const string = () => Joi.string().min(1);
export const number = () => Joi.number();
export const array = () => Joi.array().min(1);
export const bool = () => Joi.bool();
export const date = () => Joi.date();
export const any = () => Joi.any();

export const id = () => Joi.number().integer().positive();
export const phone = () => Joi.string().regex(/^\+\d+$/);
export const phoneExt = () => Joi.string().regex(/^\d+$/);
export const onlyDateString = () => Joi.string().regex(/^\d\d\d\d-\d\d-\d\d$/);
export const nonNegativeNumber = () => Joi.number().min(0);
