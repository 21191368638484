import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import config from 'config';

import { getAccessTokenFromLocalStorage } from '@acadeum/auth';
import getLocationUrl from 'common-lib/lib/getLocationUrl';

import Button from '../../components/Button';
import Loader from '../../components/Loader';

import useLocation from '../../hooks/useLocation';

import './ForumBeeLogin.sass';

// ForumBee redirects to this page when a user tries logging in
// directly on Acadeum ForumBee Website rather than on this website
// through the "Community" link in the top bar.
export default function ForumBeeLogin() {
  const location = useLocation();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const {
    url,
    error: forumbeeLoginError
  } = location.query;

  const singleSignOnUrl = getLocationUrl({
    origin: config.apiUrl,
    pathname: '/forumbee/single-sign-on',
    query: {
      // Even though the Course Share website normally uses cookies for authentication,
      // cookies aren't used when the website is run in development on `localhost`.
      // So local storage is still used for storing access token when running on `localhost`.
      accessToken: getAccessTokenFromLocalStorage(),
      redirectTo: url
    }
  });

  useEffect(() => {
    if (forumbeeLoginError) {
      setLoading(false);
      setError(true);
      setTimeout(() => {
        throw new Error(`Forumbee SSO error: ${forumbeeLoginError}. Url: ${window.location}`);
      }, 0);
    } else {
      window.location = singleSignOnUrl;
    }
  }, []);

  return (
    <section className="ForumBeeLogin">
      {loading &&
        <>
          <Loader isImposter/>
          <br/>
          <br/>
        </>
      }

      <h1>
        {loading && 'Logging you into the Acadeum Community'}
        {error && 'There was an error while logging you into the Acadeum Community.'}
      </h1>

      {error &&
        <>
          <br/>
          <br/>
          <Button onClick={() => {
            setError(false);
            setLoading(true);
            window.location = singleSignOnUrl;
          }}>
            Login to Acadeum Community
          </Button>
        </>
      }
    </section>
  );
}

ForumBeeLogin.propTypes = {
  location: PropTypes.object
};

ForumBeeLogin.meta = () => ({
  title: 'Login to Acadeum Community'
});
