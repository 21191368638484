import type { HTMLAttributes } from 'react';
import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import { CaretDownIcon, SpinnerThirdIcon } from '@acadeum/icons';
import { useIsMounted } from '@acadeum/hooks';
import { isPromise } from '@acadeum/helpers';
import type { IconSource } from '@acadeum/types';

import type { MarginsProp } from '../../utils/useMargin';
import { useMargin } from '../../utils/useMargin';

import { UnstyledLink } from '../UnstyledLink';
import { UnstyledButton } from '../UnstyledButton';
import type { LinkProps } from '../Link';

import styles from './Button.module.scss';

export interface ButtonCoreProps extends Omit<HTMLAttributes<HTMLButtonElement>, 'onClick'> {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'black' | 'black-outline' | 'danger' | 'danger-outline' | 'white' | 'text' | 'textWhite' | 'text-inline' | 'text-with-padding';
  size?: 'small',
  /** Icon to display to the left of the button content */
  icon?: IconSource;
  /** Icon to display to the right of the button content */
  suffixIcon?: IconSource;
  /** Replaces button left icon with a spinner while a background action is being performed */
  loading?: boolean;
  /** Makes `plain` and `outline` Button colors (text, borders, icons) the same as the current text color. Also adds an
   underline to `plain` Buttons */
  monochrome?: boolean;
  /**Displays the button with a disclosure icon. Defaults to `down` when set to true */
  disclosure?: true | 'up' | 'down';
}

// The below code would produce TypeScript errors.
// For example, errors saying that `url` property is not supported on `ButtonPropsButton`.
//
// interface ButtonPropsButton extends ButtonCoreProps, UnstyledButtonProps {}
// interface ButtonPropsLink extends ButtonCoreProps, Omit<UnstyledLinkProps, 'to'> {
//   url: string;
// }
// export type ButtonProps = ButtonPropsButton | ButtonPropsLink;
//
// A workaround would be defining the "missing" properties as `undefined | null`:
//
// type Type1 = {
//   url: string
// }
// type Type2 = {
//   url?: undefined | null
// }
// type PrimaryType = Type1 | Type2

export type ButtonProps =
  MarginsProp
  & ButtonCoreProps
  & Pick<LinkProps, 'external' | 'download' | 'target' | 'useHtmlLink'>
  & {
  disabled?: boolean;
  url?: string;
  onClick?: (event) => void;
  onKeyDown?: (event) => void;
  onBlur?: (event) => void;
  type?: string;
  title?: string;
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
  focusRing?: boolean;
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  variant = 'primary',
  children,
  className,
  type,
  disabled,
  external,
  download,
  target,
  size,
  icon: Icon,
  suffixIcon: SuffixIcon,
  loading,
  onClick: propsOnClick,
  url,
  useHtmlLink,
  disclosure,
  monochrome,
  mb,
  mr,
  ml,
  mt,
  my,
  mx,
  focusRing,
  ...rest
}, ref) => {
  const isMounted = useIsMounted();
  const [isLoading, setLoading] = useState(false);

  const { marginClassNames } = useMargin({ mb, mr, ml, mt, my, mx });

  const formContext = useFormContext();
  const isSubmitting = formContext?.formState.isSubmitting;

  loading = loading || isLoading;
  disabled = disabled || loading || isSubmitting;

  const onClick = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (propsOnClick) {
      const result = propsOnClick(event);
      if (isPromise(result)) {
        setLoading(true);
        const onEnded = () => isMounted() && setLoading(false);
        result.then(onEnded, onEnded);
      }
    }
  }, [isMounted, propsOnClick]);

  let Component;
  let componentProps;
  if (url) {
    Component = UnstyledLink;
    componentProps = {
      to: url,
      external,
      target,
      download,
      useHtmlLink
    };
  } else {
    Component = UnstyledButton;
    componentProps = {
      type: type || 'button',
      loading,
      disabled
    };
  }

  return (
    <Component
      {...rest}
      {...componentProps}
      ref={ref}
      onClick={onClick}
      className={classNames(
        className,
        styles.root,
        marginClassNames,
        [styles[`root--${variant}`]],
        styles[`root--${size}`],
        {
          [styles['root--hasIcon']]: Icon,
          [styles['root--monochrome']]: monochrome,
          [styles['root--noChildren']]: !children,
          [styles.focusRing]: focusRing
        }
      )}
    >
      {loading ? (
        <SpinnerThirdIcon className={styles.Spinner}/>
      ) : typeof Icon === 'function' && (
        <Icon className={classNames(styles.ButtonIcon, {
          [styles['ButtonIcon--left']]: children
        })}/>
      )}

      {children}

      {SuffixIcon && (
        <SuffixIcon className={classNames(styles.ButtonIcon, {
          [styles['ButtonIcon--right']]: children
        })}/>
      )}
      {disclosure && (
        <CaretDownIcon className={classNames(styles.Svg, {
          [styles['Svg--expanded']]: disclosure === 'up'
        })}/>
      )}
    </Component>
  );
});

Button.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'black',
    'black-outline',
    'danger',
    'danger-outline',
    'white',
    'text',
    'textWhite',
    'text-inline',
    'text-with-padding'
  ]),
  size: PropTypes.oneOf(['small']),
  icon: PropTypes.func,
  suffixIcon: PropTypes.func,
  loading: PropTypes.bool,
  disclosure: PropTypes.oneOf([true, 'up', 'down']),
  monochrome: PropTypes.bool,
  url: PropTypes.string,
  target: PropTypes.string,
  external: PropTypes.bool,
  download: PropTypes.bool,
  type: PropTypes.string
};

Button.displayName = 'Button';
