import React from 'react';
import { useController } from 'react-hook-form';

import type { IconPickerProps } from '../../../IconPicker';
import { IconPicker } from '../../../IconPicker';

interface FormIconPickerProps extends IconPickerProps {
  [key: string]: any;
}

export const FormIconPicker = ({
  name,
  rules,
  defaultValue,
  ...rest
}: FormIconPickerProps) => {
  const { field } = useController({ name, rules, defaultValue });

  return (
    <IconPicker
      {...rest}
      {...field}
    />
  );
};
