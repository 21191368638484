// Whether the input is empty
export default function isEmpty(value) {
  // `0` is not an empty value
  if (typeof value === 'number' && value === 0) {
    return false;
  }
  // `false` is not an empty value
  if (typeof value === 'boolean' && value === false) {
    return false;
  }
  // An empty string, `undefined`, `null` –
  // all those are considered an empty value.
  if (!value) {
    return true;
  }
  // Whitespace string is also considered empty
  if (typeof value === 'string' && !value.trim()) {
    return true;
  }
  // Not empty
  return false;
}
