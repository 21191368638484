import React from 'react';
import { useSelector } from 'react-redux';

import { formatName } from '../../helpers/format';
import type { ReduxState, AppPage } from '../../helpers/app.types';

import { Student } from '../Student';
import { useExportDataColumns } from '../Students/ui/TeachingStudents';

import actions from '../../actions';

const { fetchTeachingStudent } = actions;

const TeachingStudent: AppPage = () => {
  const student = useSelector((state: ReduxState) => state.students.teachingStudent);
  return <Student type="TM" student={student} useExportDataColumns={useExportDataColumns} />;
};

TeachingStudent.meta = ({ useSelector }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const teachingStudent = useSelector((state: ReduxState) => state.students.teachingStudent);
  return {
    title: formatName(teachingStudent)
  };
};

TeachingStudent.load = async ({ params: { id } }) => {
  await fetchTeachingStudent(id);
};

TeachingStudent.breadcrumbs = (state) => [
  ['Teaching Students', '/students/teaching'],
  formatName(state.students.teachingStudent)
];

export default TeachingStudent;
