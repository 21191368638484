import React from 'react';
import type { ButtonProps } from '../Button';
import { Button } from '../Button';

export type ActionButtonProps = Omit<ButtonProps, 'children'> & {
  content: React.ReactNode;
}

export const ActionButton: React.FC<ActionButtonProps> = ({
  content,
  ...rest
}) => {

  return (
    <Button {...rest}>
      {content}
    </Button>
  );
};
