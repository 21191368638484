import { useMemo } from 'react';

import normalizePath from '../../helpers/normalizePath';

import useLocation from '../../hooks/useLocation';

export default function useNavSection() {
  const location = useLocation();
  return useMemo(() => {
    const path = normalizePath(location.pathname);
    for (const section of Object.keys(NAV_SECTIONS)) {
      for (const pathStart of NAV_SECTIONS[section]) {
        if (pathStart === '/' ?  path === '/' : path.indexOf(pathStart) === 0) {
          return section;
        }
      }
    }
  }, [location]);
}

const NAV_SECTIONS = {
  enrollments: ['/enrollments/'],
  enrollmentRequests: ['/enrollment-requests/'],
  grades: ['/grades/'],
  courseRegistrationRequests: ['/course-registration-requests/'],
  advisorRecommendations: ['/advisor-recommendations/'],
  institutions: ['/institutions/'],
  consortia: ['/consortia/'],
  settings: ['/settings/'],
  students: ['/students/', '/students/teaching/'],
  courses: ['/', '/courses'],
  admin: ['/admin/'],
  dashboard: ['/dashboard/'],
  consortiumAnalytics: ['/consortium-analytics/'],
  consortiumAnalyticsTeaching: ['/consortium-analytics-teaching/']
};
