import React, { memo } from 'react';
import classNames from 'classnames';

import type { MarginProp } from '@acadeum/hooks';
import { useMargin } from '@acadeum/hooks';

import type { AsProp } from '../../utils/props';

import type { BooleanBlockProps } from './ui/BooleanBlock';
import { BooleanBlock } from './ui/BooleanBlock';
import type { DateBlockProps } from './ui/DateBlock';
import { DateBlock } from './ui/DateBlock';
import type { UserBlockProps } from './ui/UserBlock';
import { UserBlock } from './ui/UserBlock';
import type { RelativeDateBlockProps } from './ui/RelativeDateBlock';
import { RelativeDateBlock } from './ui/RelativeDateBlock';
import type { InstitutionBlockProps } from './ui/InstitutionBlock';
import { InstitutionBlock } from './ui/InstitutionBlock';
import type { CourseBlockProps } from './ui/CourseBlock';
import { CourseBlock } from './ui/CourseBlock';
import type { StudentBlockProps } from './ui/StudentBlock';
import { StudentBlock } from './ui/StudentBlock';
import type { RolesBlockProps } from './ui/RolesBlock';
import { RolesBlock } from './ui/RolesBlock';
import type { ConsortiumBlockProps } from './ui/ConsortiumBlock';
import { ConsortiumBlock } from './ui/ConsortiumBlock';
import type { TermAndSessionBlockProps } from './ui/TermAndSessionBlock';
import { TermAndSessionBlock } from './ui/TermAndSessionBlock';
import type { CourseSessionDateBlockProps } from './ui/CourseSessionDateBlock';
import { CourseSessionDateBlock } from './ui/CourseSessionDateBlock';
import type { ErrorBlockProps } from './ui/ErrorBlock';
import { ErrorBlock } from './ui/ErrorBlock';
import type { SimpleBlockProps } from './ui/SimpleBlock';
import { SimpleBlock } from './ui/SimpleBlock';

type BooleanDataBlockProps = { type: 'boolean' } & BooleanBlockProps;
type DateDataBlockProps = { type: 'date' } & DateBlockProps;
type UserDataBlockProps = { type: 'user' } & UserBlockProps;
type RelativeDateDataBlockProps = { type: 'relativeDate' } & RelativeDateBlockProps;
type InstitutionDataBlockProps = { type: 'institution' } & InstitutionBlockProps;
type CourseDataBlockProps = { type: 'course' } & CourseBlockProps;
type StudentDataBlockProps = { type: 'student' } & StudentBlockProps;
type RolesDataBlockProps = { type: 'roles' } & RolesBlockProps;
type ConsortiumDataBlockProps = { type: 'consortium' } & ConsortiumBlockProps;
type SessionDataBlockProps = { type: 'term&session' } & TermAndSessionBlockProps;
type CourseSessionDateDataBlockProps = { type: 'courseSessionDates' } & CourseSessionDateBlockProps
type ErrorDataBlockProps = { type: 'error' } & ErrorBlockProps
type SimpleDataBlockProps = { type?: undefined } & SimpleBlockProps;

export type DataBlockProps = (
  BooleanDataBlockProps |
  DateDataBlockProps |
  UserDataBlockProps |
  RelativeDateDataBlockProps |
  InstitutionDataBlockProps |
  CourseDataBlockProps |
  StudentDataBlockProps |
  RolesDataBlockProps |
  ConsortiumDataBlockProps |
  SessionDataBlockProps |
  CourseSessionDateDataBlockProps |
  ErrorDataBlockProps |
  SimpleDataBlockProps
  ) & AsProp & Pick<MarginProp, 'mb' | 'mt'> & {
  className?: string;
}

export const DataBlock: React.FC<DataBlockProps> = memo(({
  className,
  type,
  as: ComponentBlock = 'div',
  mb,
  mt,
  ...rest
}) => {
  let Component;
  switch (type) {
    case 'boolean':
      Component = BooleanBlock;
      break;
    case 'date':
      Component = DateBlock;
      break;
    case 'user':
      Component = UserBlock;
      break;
    case 'relativeDate':
      Component = RelativeDateBlock;
      break;
    case 'institution':
      Component = InstitutionBlock;
      break;
    case 'course':
      Component = CourseBlock;
      break;
    case 'term&session':
      Component = TermAndSessionBlock;
      break;
    case 'student':
      Component = StudentBlock;
      break;
    case 'roles':
      Component = RolesBlock;
      break;
    case 'consortium':
      Component = ConsortiumBlock;
      break;
    case 'courseSessionDates':
      Component = CourseSessionDateBlock;
      break;
    case 'error':
      Component = ErrorBlock;
      break;
    case undefined:
      Component = SimpleBlock;
      break;
    default:
      throw new Error(`<DataBlock/>: Unsupported type: ${type}`);
  }

  const { marginClassNames } = useMargin({ mb, mt });

  return (
    <ComponentBlock className={classNames(className, marginClassNames)}>
      <Component {...rest}/>
    </ComponentBlock>
  );
});
