import type { FC } from 'react';
import React, { useEffect, useState } from 'react';

import type { Id, UserRoleWithUsers } from '@acadeum/types';
import { ArrowLeftIcon } from '@acadeum/icons';
import type { OnSubmit } from '@acadeum/ui';
import {
  Alert,
  Button,
  ConfirmActionModal,
  FormField,
  FormModal,
  Modal,
  Separator,
  SuccessModal,
  Text,
  toast,
  waitForModalToClose
} from '@acadeum/ui';
import type { UseDeleteUserRoleMutation, UseFetchUserRolesQuery, UseSetUsersRolesForUsersMutation } from '@acadeum/api';

import UserBadge from '../UserBadge/UserBadge';

interface DeleteRoleModalProps {
  show: boolean;
  onHide: (hide: false) => void;
  role: UserRoleWithUsers;
  onAfterDelete: () => void;
  useDeleteUserRoleMutation: UseDeleteUserRoleMutation;
  useSetUsersRolesForUsersMutation: UseSetUsersRolesForUsersMutation;
  useFetchUserRolesQuery: UseFetchUserRolesQuery;
}

export const DeleteRoleModal: FC<DeleteRoleModalProps> = (props) => {
  if (!props.role || !props.show) {
    return null;
  }
  return (
    <DeleteRoleModalContent {...props} />
  );
};

const DeleteRoleModalContent: FC<DeleteRoleModalProps> = ({
  show,
  onHide,
  role,
  onAfterDelete,
  useSetUsersRolesForUsersMutation,
  useDeleteUserRoleMutation,
  useFetchUserRolesQuery
}) => {
  const [isShownConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const [isShownReassignRoleModal, setShowReassignRoleModal] = useState(false);
  const [isShownUsersAssignedToRoleModal, setShowUsersAssignedToRoleModal] = useState(false);
  const [isShownSuccessReassignModal, setShowSuccessReassignModal] = useState(false);

  const [setUsersRolesForUsersMutation] = useSetUsersRolesForUsersMutation();
  const [deleteUserRoleMutation] = useDeleteUserRoleMutation();

  useEffect(() => {
    if (show) {
      if (role.users.length === 0) {
        setShowConfirmDeleteModal(true);
      } else {
        setShowReassignRoleModal(true);
      }
    }
  }, [show]);

  const onDeleteRole = async () => {
    await deleteUserRoleMutation({ id: role.id }).unwrap();
    await onAfterDelete();
    setShowConfirmDeleteModal(false);
    toast.success(`Custom role for ${role.name} has been deleted successfully`);
  };

  const onCancelDeleteRole = async () => {
    setShowConfirmDeleteModal(false);
    await waitForModalToClose();
    onHide(false);
  };

  const onReassignUsersToRole: OnSubmit<{ roleId: Id }> = async ({ roleId }) => {
    await setUsersRolesForUsersMutation({
      userIds: role.users.map(_ => _.id),
      roleIds: [roleId]
    }).unwrap();
    setShowReassignRoleModal(false);
    setShowSuccessReassignModal(true);
  };

  const onCancelReassignUsersToRole = async () => {
    setShowReassignRoleModal(false);
    await waitForModalToClose();
    onHide(false);
  };

  const onProceedToDelete = () => {
    setShowSuccessReassignModal(false);
    setShowConfirmDeleteModal(true);
  };

  const onHideSuccessModal = async () => {
    setShowSuccessReassignModal(false);
    await waitForModalToClose();
    onHide(false);
  };

  const showAssignedUsersModal = () => {
    setShowReassignRoleModal(false);
    setShowUsersAssignedToRoleModal(true);
  };

  const hideAssignedUsersModal = () => {
    setShowUsersAssignedToRoleModal(false);
    setShowReassignRoleModal(true);
  };

  return (
    <>
      <ConfirmDeleteRoleModal
        role={role}
        onDelete={onDeleteRole}
        show={isShownConfirmDeleteModal}
        onHide={onCancelDeleteRole}
      />
      <ReassignUsersModal
        role={role}
        show={isShownReassignRoleModal}
        showAssignedUsersModal={showAssignedUsersModal}
        onHide={onCancelReassignUsersToRole}
        onReassignUsersToRole={onReassignUsersToRole}
        useFetchUserRolesQuery={useFetchUserRolesQuery}
      />
      <UsersAssignedToRoleModal
        role={role}
        show={isShownUsersAssignedToRoleModal}
        onHide={hideAssignedUsersModal}
      />
      <SuccessModalUsersHaveBeenReassigned
        show={isShownSuccessReassignModal}
        onHide={onHideSuccessModal}
        onProceedToDelete={onProceedToDelete}
      />
    </>
  );
};

function ConfirmDeleteRoleModal({
  role,
  show,
  onHide,
  onDelete
}) {
  return (
    <ConfirmActionModal
      danger
      show={show}
      onHide={onHide}
      onCancel={onHide}
      onSubmit={onDelete}
      title="Delete Custom Role"
      description={(
        <>
          Are you sure you want to delete {role.name} custom role?
          <span className="danger">This action cannot be undone.</span>
        </>
      )}
    />
  );
}

function UsersAssignedToRoleModal({ role, show, onHide }) {
  return (
    <Modal
      title={`Users Assigned to ${role.name} Role`}
      show={show}
      onHide={onHide}
    >
      <Modal.Body>
        {role.users.map((user, index) => (
          <React.Fragment key={user.id}>
            {index > 0 && (
              <Separator m="none"/>
            )}
            <UserBadge user={user}/>
          </React.Fragment>
        ))}
      </Modal.Body>
      <Modal.Footer justify="start" pressed>
        <Button
          variant="text"
          icon={ArrowLeftIcon}
          onClick={() => onHide(false)}
        >
          Go Back
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function SuccessModalUsersHaveBeenReassigned({
  show,
  onHide,
  onProceedToDelete
}) {
  return (
    <SuccessModal
      show={show}
      onHide={onHide}
      secondaryAction={{
        content: 'Finish & close',
        onClick: onHide
      }}
      action={{
        content: 'Proceed to delete',
        onClick: onProceedToDelete
      }}
    >
      All users have been reassigned successfully.
    </SuccessModal>
  );
}

function ReassignUsersModal({
  show,
  onHide,
  showAssignedUsersModal,
  onReassignUsersToRole,
  role,
  useFetchUserRolesQuery
}) {
  const [selectedValue, setSelectedValue] = useState(role.id);

  const {
    data,
    isLoading: loading
  } = useFetchUserRolesQuery({
    pageSize: 1000
  });

  return (
    <FormModal
      title="Delete Custom Role"
      show={show}
      onHide={onHide}
      onCancel={onHide}
      onSubmit={onReassignUsersToRole}
      submitText="Next"
      size="narrow"
      formSubmitProps={{ disabled: role.id === selectedValue }}
    >
      <Text color="black">
        <strong>{role.name}</strong> role cannot be deleted while users are still assigned to it.
        Select a new one to reassign the users to:
      </Text>
      <br/>
      <FormField
        required
        name="roleId"
        label="Role"
        type="select"
        onChange={(event) => setSelectedValue(event.target.value)}
        loading={loading}
        options={loading ? [] : data?.results.map((_) => ({
          value: _.id,
          description: _.description,
          label: _.name
        }))}
      />
      <Alert>
        If you need a new role <Button variant="text" onClick={showAssignedUsersModal}>for these
        users</Button>, create it first and then reassign the users to it.
      </Alert>
    </FormModal>
  );
}
