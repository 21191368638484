import React from 'react';
import { Link } from 'react-pages';

const AppLink = React.forwardRef(({ href, ...rest }, ref) => {
  if (href) {
    return (
      <Link
        {...rest}
        to={href}
        ref={ref}
      />
    );
  }

  return (
    <span {...rest}/>
  );
});

export default AppLink;
