// Course-related dates, such as "Last Drop Date", are stored in the database
// having `00:00` time in UTC+0 time zone. So, if a course's "Last Drop Date" is Dec 30,
// it means that the user should be able to drop starting from Dec 30 00:00 till Dec 30 23:59.
//
// The question is then: how should the app convert a `00:00 UTC+0` date from the database
// into the user's local timezone date?
// For example, when it has a `Dec 30 00:00 UTC+0` date, how should it convert that date
// to `Dec 30 00:00` in the user's time zone?
// It can't convert it differently for different users from different time zones
// because in that case some users will be able to drop from a course after it has already
// stopped accepting drops.
// To prevent that, the date has to be converted to some "universal" "default" time zone
// that doesn't really correspond to a given user's local time zone.
//
// Acadeum has chosen the "Pacific Time Zone" (UTC-8) to be such primary (default) time zone
// when ruling out any time zone ambiguity. The rationale is that the majority of clients
// are US-based, and in the US they're used to having `PST` time zone as the standard one.
//
// https://github.com/Acadeum/Tickets/issues/1439#issuecomment-1485678002
//
export default -1 * 8 * 60 * 60 * 1000;
