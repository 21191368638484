import type { PrimaryPaymentSource } from '@acadeum/api';

export function getBankAccountStatuses(primaryPaymentSource?: PrimaryPaymentSource) {
  const statuses: PrimaryPaymentSource['status'][] = [];
  if (primaryPaymentSource) {
    statuses.push(primaryPaymentSource.status);
    if (primaryPaymentSource.status === 'ACTIVE') {
      // If bank account is active, it is used by default
      statuses.push('DEFAULT');
    }
  }
  return statuses;
}
