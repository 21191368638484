import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { formatUserName } from '../../helpers/format';

import actions from '../../actions';

import UserView from './UserView';

const {
  fetchUser,
  fetchUserRoles
} = actions;

export default function User() {
  const { user, userRoles } = useSelector(state => state.users);
  return (
    <UserView
      user={user}
      userRoles={userRoles}
      superAdmin
      adminPanel
    />
  );
}

User.propTypes = {
  user: PropTypes.object
};

User.meta = ({ useSelector }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const user = useSelector(state => state.users.user);
  return {
    title: formatUserName(user)
  };
};

User.load = async ({ params: { id } }) => {
  await Promise.all([
    fetchUser(id, { allSettings: true }),
    fetchUserRoles()
  ]);
};

User.breadcrumbs = (state) => [
  ['Admin Tools', '/admin'],
  ['User Accounts', '/admin/users'],
  formatUserName(state.users.user)
];
