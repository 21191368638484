// Course-related dates, such as a course's "Last Drop Date", are stored in the database
// having `00:00` time in UTC+0 time zone. So, if a course's "Last Drop Date" is Dec 30,
// it means that the user should be able to drop starting from Dec 30 00:00 till Dec 30 23:59.
//
// In other words, the system provides a one-day "leeway" for students or institutions to drop from an enrollment.
// The reason is that a concept of a "date" involves a concept of a "time zone" which is different
// depending on where the user is at. When in course description it says "Last Drop Date: May 2nd"
// then it means that "in Acadeum's database, the last drop date is set to be May 2nd 00:00 in UTC+0 time zone".
// Now how should the sytem determine if the user is still able to drop from a course based on that date?
// The answer is: it should convert the UTC+0 date to the user's local time zone and then allow for some "leeway"
// in order to allow users to still drop at "the end of May 2nd".
// What would "the end of May 2nd" mean in that case? UX-wise, it should be in the user's time zone.
// If the user is in China then their time zone is UTC+8. If the "leeway" is "24 hours" then the user
// will still be able to drop from the course until May 3rd 08:00 in their local time zone, which is fine.
// If the user is in Hawaii then their time zone is UTC-10. If the "leeway" is "24 hours" then the user
// will only be able to drop from the course until May 2nd 14:00 in their local time zone, which could be considered a bug of its own.
// And that was just the student's time zone. But what about the Teaching Institution's time zone?
// The Teaching Institution could be in Hawaii. It could also be in China. And the same "leeway" logic applies to them too.
// And too much of a "leeway" could become very non-intuitive for both students and Teaching Institutions.
// After some discussion, Acadeum has settled on a "leeway" of "24 hours minus 8 hours as a convenience thing for the Pacific Time Zone".
// https://github.com/Acadeum/Tickets/issues/1439
//
// The 1 millisecond that gets subtracted is just to not "overflow" onto another date.
// I.e. it stays within the bounds of a given "UTC+0 date".
//
export default 24 * 60 * 60 * 1000 - 1;
