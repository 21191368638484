import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { BaseButton as Button } from '@acadeum/ui';

import './TextButton.scss';

export default function TextButton ({
  caret,
  className,
  children,
  ...rest
}) {
  return (
    <Button {...rest} className={classNames('TextButton', className)}>
      {children}
      {caret &&
        <RightCaretIcon className="TextButton__caret"/>
      }
    </Button>
  );
}

TextButton.propTypes = {
  caret: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

function RightCaretIcon(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="4" height="8" viewBox="0 0 4 8">
      <path fill="#5000b2" d="M4 4L0 8V0z"/>
    </svg>
  );
}
