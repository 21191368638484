import { userHasPermission } from '@acadeum/helpers';

import { UserRoleDetailsPage } from '@acadeum/shared-admin-ui';
import {
  useDeleteUserRoleMutation,
  useDuplicateUserRoleMutation,
  useFetchUserRoleQuery,
  useFetchUserRolesQuery,
  useUpdateUserRoleMutation
} from '../../api/userRoles';
import { useFetchUsersQuery, useSetUsersRolesForUsersMutation } from '../../api/users';
import type { AppPage } from '../../helpers/app.types';

interface UserRoleDetailsProps {
  roleId: number;
}

const UserRoleDetails: AppPage<UserRoleDetailsProps> = ({ roleId }) => {
  return (
    <UserRoleDetailsPage
      roleId={roleId}
      useUpdateUserRoleMutation={useUpdateUserRoleMutation}
      useFetchUsersQuery={useFetchUsersQuery}
      useFetchUserRolesQuery={useFetchUserRolesQuery}
      useSetUsersRolesForUsersMutation={useSetUsersRolesForUsersMutation}
      useDeleteUserRoleMutation={useDeleteUserRoleMutation}
      useDuplicateUserRoleMutation={useDuplicateUserRoleMutation}
      useFetchUserRoleQuery={useFetchUserRoleQuery}
    />
  );
};

UserRoleDetails.load = async ({ params }) => {
  return {
    props: {
      roleId: parseInt(params.id)
    }
  };
};

UserRoleDetails.meta = () => ({
  title: 'User Role Details'
});

UserRoleDetails.when = ({ user }) => userHasPermission(user, 'userRole:read', {
  orgId: user?.institution.id,
  ownerId: null
});

export default UserRoleDetails;
