import type { Course, CourseEnrollment, Id, Section as SectionDefault } from '@acadeum/types';

import { rtkApi } from './rtkApi';


interface Section extends Pick<SectionDefault, 'endDate' | 'lastAddDate' | 'lastDropDate' | 'name' |  'startDate' | 'term'>{
  internalName?: string;
  sections: {number: string}[];
}

interface Enrollments extends Pick<CourseEnrollment, 
  'enrollReason' |
  'enrollReasonNotes' |
  'homeInstitutionId' |
  'letterGrade' | 
  'paid' |
  'startedAt' |
  'status'
>{
  course: Pick<Course, 'id' | 'hours' | 'code' | 'title'> &{
    sessions: Section[];
  };
}

export interface FetchStudentCourseEnrollmentsOutput {
  page: number;
  pageSize: number;
  totalCount: number;
  results: Enrollments[];
}

export interface FetchStudentCourseEnrollmentsInput {
  id: Id;
  page?: number;
  pageSize?: number;
  filters?: {
    id?: Id;
    course?: string;
    type?: 'live' | 'finalized';
    teachingInstitution?: string;
    term?: string;
    session?: string;
    section?: string;
    startDate?: Date;
    endDate?: Date;
    grade?: string;
    status?: 'REQUESTED' | 'COMPLETED' | 'REMOVED' | 'DUE' | 'DROPPED' | 'WITHDRAWN' | 'DENIED' | 'ACTIVE' | 'PENDING';
  }
}

export interface BulkActivateOrDeactivateInput {
  action: 'activate' | 'deactivate';
  knownStudentIds: number[];
}

const studentApi = rtkApi
  .enhanceEndpoints({
    addTagTypes: ['student']
  })
  .injectEndpoints({
    endpoints: build => ({
      fetchStudentCourseEnrollments: build.query<FetchStudentCourseEnrollmentsOutput, FetchStudentCourseEnrollmentsInput>({
        query: ({ id, ...params })=>({
          url: `/students/${id}/enrollments`,
          params
        }),
        providesTags: [{type: 'student'}]
      }),
      bulkActivateOrDeactivate: build.mutation<void, BulkActivateOrDeactivateInput>({
        query: (params)=>({
          url: '/students/bulk-activate-or-deactivate',
          method: 'POST',
          params
        })
      })
    })
  });

export const {
  useFetchStudentCourseEnrollmentsQuery,
  useBulkActivateOrDeactivateMutation
} = studentApi;
