import React from 'react';
import type { FC } from 'react';

import { FormField, FormModal } from '@acadeum/ui';
import type { FormModalProps } from '@acadeum/ui';
import { useTranslate } from '@acadeum/translate';

interface FormValues {
  title: string;
}

interface EditSavedSearchModalProps extends Pick<FormModalProps, 'show' | 'onHide'> {
  title: string;
  onRemove: () => void;
  onSubmit: (values: FormValues) => void;
}

const EditSavedSearchModal: FC<EditSavedSearchModalProps> = ({
  show,
  onHide,
  title,
  onRemove,
  onSubmit
}) => {
  const t = useTranslate('CourseSearch');

  return (
    <FormModal
      title={t('editSearch')}
      actions={[
        {
          danger: true,
          title: t('remove', { global: true }),
          onClick: onRemove
        }
      ]}
      onHide={onHide}
      show={show}
      onSubmit={onSubmit}
      submitText={t('update')}
    >
      <FormField
        required
        autoFocus
        name="title"
        defaultValue={title}
        label={t('searchTitle')}
      />
    </FormModal>
  );
};
export default EditSavedSearchModal;
