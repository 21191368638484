import type { FC } from 'react';

import { Accordion, FormModal, Text, toast } from '@acadeum/ui';
import type { FormModalProps } from '@acadeum/ui';
import { getErrorData } from '@acadeum/helpers';

import actions from '../../../../actions';

const {
  deleteStudent
} = actions;

interface ModalDeleteProps extends Pick<FormModalProps, 'onHide' | 'show'> {
  // eslint-disable-next-line
  onAfterSubmit?: () => void;
  studentId: number;
  studentName: string
  studentUserId?: number
}

export const ModalDelete: FC<ModalDeleteProps> = ({ show, onHide, onAfterSubmit, studentId, studentUserId, studentName }) => {
  const onSubmit = async () => {
    try {
      await deleteStudent(studentId);
      onAfterSubmit && onAfterSubmit();
    } catch (error: unknown) {
      const errorData = getErrorData(error);
      toast.warn(errorData.message);
    }
  };

  return (
    <FormModal
      title="Delete Student"
      submitText="delete"
      show={show}
      onHide={onHide}
      onCancel={onHide}
      onSubmit={onSubmit}
    >
      {studentUserId ? (
        <>
          <Text>
            Please confirm that you’d like to delete the Home Institution’s <strong>student record
            for {studentName}</strong>.
            <br/>
            <br/>
            Only the Home Institution's student record will be deactivated. The corresponding student user account
            will remain unaffected.
            <br/>
            <br/>
          </Text>
          <Accordion title="How to delete a student user account" variant="text">
            If you'd like to delete a student user account, go to the "User Accounts" page in the Admin Panel,
            find the student user account there and choose "Deactivate" in the actions menu.
            <br/>
            <br/>
            After the student user has been deactivated, a "Delete" action will appear in the same menu, which can
            be used to delete the student user account.
          </Accordion>
        </>
      ) : (
        <Text>
          Please confirm that you'd like to delete the Home Institution's <strong>student record
          for {studentName}</strong>.
        </Text>
      )}
    </FormModal>
  );
};
