import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

// "Approve Course" page has its own `courseApproval`
// because if it was sourced from `./redux/course.js`
// then navigating from a Course/Section page
// to an "Approve Course" page ("Edit" link)
// would result in "Home Institution Schedule" section content
// disappearing before the navigation is finished
// because `courseApproval` would be re-fetched
// which would also nullify it until the API response.
export const fetchCourseApprovalForApproveCoursePage = redux.action(
  (courseId) => (http) => http.get(`/courses/${courseId}/approval`),
  'courseApproval'
);

export default redux.reducer();
