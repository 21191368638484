import type { MutableRefObject } from 'react';
import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

import BModalBody from 'react-bootstrap/ModalBody';

import { useHasScrollbar } from '@acadeum/hooks';

import { useModalUI } from './context/hooks';

import styles from './Modal.module.scss';

export interface ModalBodyProps {
  children?: React.ReactNode;
  className?: string;
}

export const ModalBody: React.FC<ModalBodyProps> = ({ children, className }) => {
  const { hasScroll, hasFooter, hasHeader } = useModalUI();

  return (
    <BModalBody
      as={Element}
      className={classNames(className, styles.ModalBody, {
        [styles['ModalBody--modalHasScroll']]: hasScroll,
        [styles['ModalBody--footerNone']]: !hasFooter,
        [styles['ModalBody--headerNone']]: !hasHeader
      })}
    >
      {children}
    </BModalBody>
  );
};

// As it turned out in `bootstrap/ModalBody`, you cannot get the Ref of an element.
// It was needed and therefore this wrapper was created
const Element = (props) => {
  const ref = useRef() as MutableRefObject<HTMLDivElement>;
  const { setHasScroll } = useModalUI();
  const { hasVerticalScrollbar } = useHasScrollbar(ref);

  useEffect(() => {
    setHasScroll(hasVerticalScrollbar);
  }, [hasVerticalScrollbar]);

  return (
    <div
      ref={ref}
      {...props}
    />
  );
};
