import isEmpty from './isEmpty.js';

export default function stringifyLocationQuery(query) {
  let result = '';
  for (const key in query) {
    if (!isEmpty(query[key])) {
      if (result) {
        result += '&';
      } else {
        result += '?';
      }
      result += encodeURIComponent(key);
      result += '=';
      result += encodeURIComponent(query[key]);
    }
  }
  return result;
}
