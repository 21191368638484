// Copy-pasted from `react-instantsearch`.
// https://github.com/algolia/react-instantsearch/blob/81a6f1856ae28b4ab00ba660c548037818749220/packages/react-instantsearch-dom/src/components/Pagination.js
// Version 5.5
//
// Changes:
//
// * Added "scroll to top" feature on page button click.
// * Added a `...` item to indicate that there're "more pages" available. Starts at "// BEGIN MODIFIED CODE." and ends at "// END MODIFIED CODE.".
// * Replaced `import { range } from 'lodash'` with `import range from 'lodash/range'`.
// * default props "ariaPage: currentRefinement => `Page ${currentRefinement.toString()}`"

import range from 'lodash/range';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translatable } from 'react-instantsearch-core';
import { capitalize, createClassNames } from '../core/utils';
import LinkListImport from 'react-instantsearch-dom/dist/cjs/components/LinkList';

// For some weird reason, because of `type: "module"`, those imports are wrapped in `{ default }`.
const LinkList = LinkListImport.default ? LinkListImport.default : LinkListImport;

const cx = createClassNames('Pagination');

// Determines the size of the widget (the number of pages displayed - that the user can directly click on)
function calculateSize(padding, maxPages) {
  return Math.min(2 * padding + 1, maxPages);
}

function calculatePaddingLeft(currentPage, padding, maxPages, size) {
  if (currentPage <= padding) {
    return currentPage;
  }

  if (currentPage >= maxPages - padding) {
    return size - (maxPages - currentPage);
  }

  return padding + 1;
}

// Retrieve the correct page range to populate the widget
function getPages(currentPage, maxPages, padding) {
  const size = calculateSize(padding, maxPages);
  // If the widget size is equal to the max number of pages, return the entire page range
  if (size === maxPages) return range(1, maxPages + 1);

  const paddingLeft = calculatePaddingLeft(
    currentPage,
    padding,
    maxPages,
    size
  );
  const paddingRight = size - paddingLeft;

  const first = currentPage - paddingLeft;
  const last = currentPage + paddingRight;
  return range(first + 1, last + 1);
}

class Pagination extends Component {
  static propTypes = {
    nbPages: PropTypes.number.isRequired,
    currentRefinement: PropTypes.number.isRequired,
    refine: PropTypes.func.isRequired,
    createURL: PropTypes.func.isRequired,
    canRefine: PropTypes.bool.isRequired,

    translate: PropTypes.func.isRequired,
    listComponent: PropTypes.func,

    showFirst: PropTypes.bool,
    showPrevious: PropTypes.bool,
    showNext: PropTypes.bool,
    showLast: PropTypes.bool,
    padding: PropTypes.number,
    totalPages: PropTypes.number,
    className: PropTypes.string
  };

  static defaultProps = {
    listComponent: LinkList,
    showFirst: true,
    showPrevious: true,
    showNext: true,
    showLast: false,
    padding: 3,
    totalPages: Infinity,
    className: ''
  };

  getItem(modifier, translationKey, value) {
    const { nbPages, totalPages, translate } = this.props;
    return {
      key: `${modifier}.${value}`,
      modifier,
      disabled: value < 1 || value >= Math.min(totalPages, nbPages),
      label: translate(translationKey, value),
      value,
      ariaLabel: translate(`aria${capitalize(translationKey)}`, value)
    };
  }

  render() {
    const {
      listComponent: ListComponent,
      nbPages,
      totalPages,
      currentRefinement,
      padding,
      showFirst,
      showPrevious,
      showNext,
      showLast,
      refine,
      createURL,
      canRefine,
      translate,
      className,
      ...otherProps
    } = this.props;

    const maxPages = Math.min(nbPages, totalPages);
    const lastPage = maxPages;

    let items = [];
    if (showFirst) {
      items.push({
        key: 'first',
        modifier: 'item--firstPage',
        disabled: currentRefinement === 1,
        label: translate('first'),
        value: 1,
        ariaLabel: translate('ariaFirst')
      });
    }
    if (showPrevious) {
      items.push({
        key: 'previous',
        modifier: 'item--previousPage',
        disabled: currentRefinement === 1,
        label: translate('previous'),
        value: currentRefinement - 1,
        ariaLabel: translate('ariaPrevious')
      });
    }

    // BEGIN MODIFIED CODE.

    const pages =
      getPages(currentRefinement, maxPages, padding).map(value => ({
        key: value,
        modifier: 'item--page',
        label: translate('page', value),
        value,
        selected: value === currentRefinement,
        ariaLabel: translate('ariaPage', value)
      }));

    const previousPagesSkipped = pages[0].value - 1;
    const nextPagesSkipped = maxPages - pages[pages.length - 1].value;

    if (previousPagesSkipped) {
      const value = 1;
      // Copy-pasted from above.
      items.push({
        key: value,
        modifier: 'item--page',
        label: translate('page', value),
        value,
        selected: value === currentRefinement,
        ariaLabel: translate('ariaPage', value)
      });
    }

    if (previousPagesSkipped === 2) {
      const value = 2;
      // Copy-pasted from above.
      items.push({
        key: value,
        modifier: 'item--page',
        label: translate('page', value),
        value,
        selected: value === currentRefinement,
        ariaLabel: translate('ariaPage', value)
      });
    }

    if (previousPagesSkipped > 2) {
      items.push({
        key: 'hasMorePrevious',
        modifier: 'item--hasMorePrevious',
        disabled: true,
        label: '…',
        value: pages[0].value - 1,
        ariaLabel: translate('ariaPrevious')
      });
    }

    items = items.concat(pages);

    if (nextPagesSkipped > 2) {
      items.push({
        key: 'hasMoreNext',
        modifier: 'item--hasMoreNext',
        disabled: true,
        label: '…',
        value: pages[pages.length - 1].value + 1,
        ariaLabel: translate('ariaNext')
      });
    }

    if (nextPagesSkipped === 2) {
      const value = maxPages - 1;
      // Copy-pasted from above.
      items.push({
        key: value,
        modifier: 'item--page',
        label: translate('page', value),
        value,
        selected: value === currentRefinement,
        ariaLabel: translate('ariaPage', value)
      });
    }

    if (nextPagesSkipped) {
      const value = maxPages;
      // Copy-pasted from above.
      items.push({
        key: value,
        modifier: 'item--page',
        label: translate('page', value),
        value,
        selected: value === currentRefinement,
        ariaLabel: translate('ariaPage', value)
      });
    }

    const onSelect = (pageNumber) => {
      refine(pageNumber);
      if (pageNumber !== currentRefinement) {
        const container = document.querySelector('.InstantSearch-Body');
        const header = document.querySelector('header.Header');
        if (container) {
          window.scrollTo({
            top: (container.getBoundingClientRect().top + window.pageYOffset) - (header ? header.clientHeight : 0) - 16,
            behavior: 'smooth'
          });
        }
      }
    };

    // END MODIFIED CODE.

    if (showNext) {
      items.push({
        key: 'next',
        modifier: 'item--nextPage',
        disabled: currentRefinement === lastPage || lastPage <= 1,
        label: translate('next'),
        value: currentRefinement + 1,
        ariaLabel: translate('ariaNext')
      });
    }
    if (showLast) {
      items.push({
        key: 'last',
        modifier: 'item--lastPage',
        disabled: currentRefinement === lastPage || lastPage <= 1,
        label: translate('last'),
        value: lastPage,
        ariaLabel: translate('ariaLast')
      });
    }

    return (
      <div
        className={classNames(cx('', !canRefine && '-noRefinement'), className)}
      >
        <ListComponent
          {...otherProps}
          cx={cx}
          items={items}
          onSelect={onSelect}
          createURL={createURL}
          canRefine={canRefine}
        />
      </div>
    );
  }
}

export default translatable({
  previous: '‹',
  next: '›',
  first: '«',
  last: '»',
  page: currentRefinement => currentRefinement.toString(),
  ariaPrevious: 'Previous page',
  ariaNext: 'Next page',
  ariaFirst: 'First page',
  ariaLast: 'Last page',
  ariaPage: currentRefinement => `Page ${currentRefinement.toString()} selected`
})(Pagination);
