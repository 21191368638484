import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { NoAccessIcon, CircleInfoIcon, CircleExclamationIcon } from '@acadeum/icons';

import { useTranslate } from '@acadeum/translate';

import { CloseButton } from '../CloseButton';
import { BaseButton } from '../BaseButton';
import { Link } from '../Link';

import styles from './Alert.module.scss';

export interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'info' | 'warn' | 'error';
  bordered?: boolean;
  show?: boolean;
  dismissible?: boolean;
  onClose?: (show: false) => void;
  closeLabel?: string;
  action?: {
    onClick?: () => void;
    content: React.ReactNode;
    url?: string;
    external?: boolean;
  };
}

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(({
  variant = 'info',
  bordered = true,
  show = true,
  dismissible,
  onClose,
  className,
  closeLabel,
  children,
  action,
  ...rest
}, ref) => {
  const t = useTranslate('ui.Alert');
  closeLabel = closeLabel || t('defaultProps.closeLabel');

  const Icon = getIcon(variant);

  const alert = (
    <div
      role="alert"
      {...rest}
      ref={ref}
      className={classNames(
        className,
        styles.Alert,
        styles[`Alert--${variant}`], {
          [styles['Alert--borderNone']]: !bordered
        }
      )}
    >
      <Icon aria-hidden="true" className={styles.Alert__icon}/>
      <div className={styles.Alert__content}>
        {children}
      </div>
      {action && (
        action.url ? (
          <Link
            className={styles.action}
            to={action.url}
            external={action.external}
            onClick={action.onClick}
            removeUnderline={false}
          >
            {action.content}
          </Link>
        ) : (
          <BaseButton onClick={action?.onClick} className={styles.action}>
            {action.content}
          </BaseButton>
        )
      )}
      {dismissible && (
        <CloseButton
          size="sm"
          onClick={() => onClose?.(false)}
          aria-label={closeLabel}
          className={styles.CloseButton}
        />
      )}
    </div>
  );

  return show ? alert : null;
});

Alert.displayName = 'Alert';
Alert.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['info', 'warn', 'error']),
  bordered: PropTypes.bool,
  show: PropTypes.bool,
  dismissible: PropTypes.bool,
  onClose: PropTypes.func,
  closeLabel: PropTypes.string,
  action: PropTypes.any
};

function getIcon(variant: AlertProps['variant']) {
  switch (variant) {
    case 'error':
      return NoAccessIcon;
    case 'warn':
      return CircleExclamationIcon;
    case 'info':
    default:
      return CircleInfoIcon;
  }
}
