import DataUploadPage from '../../../components/DataUploadPage/DataUploadPage.en.json';
import CourseMappingsTable from '../../../components/CourseMappingsTable/CourseMappingsTable.en.json';
import CourseForm from '../../../components/CourseForm/CourseForm.en.json';
import AdvancedCourseSettingsForm
  from '../../../components/AdvancedCourseSettingsForm/AdvancedCourseSettingsForm.en.json';
import UserAuthHistoryTable from '../../../components/UserAuthHistoryTable/UserAuthHistoryTable.en.json';
import InstitutionMenu from '../../../components/AppTemplate/InstitutionMenu/InstitutionMenu.en.json';
import Footer from '../../../components/AppTemplate/Footer/Footer.en.json';
import LoggedInAsBanner from '../../../components/AppTemplate/LoggedInAsBanner/LoggedInAsBanner.en.json';

import AuthenticationPage from '../../../pages/AuthenticationPage/AuthenticationPage.en.json';
import SingleSignOnSamlSettingsForm
  from '../../../pages/AuthenticationPage/SingleSignOnSamlSettingsForm/SingleSignOnSamlSettingsForm.en.json';

import ContactsPage from '../../../pages/ContactsPage/ContactsPage.en.json';
import ContactSection from '../../../pages/ContactsPage/ContactSection/ContactSection.en.json';
import ContactCard from '../../../pages/ContactsPage/ContactCard/ContactCard.en.json';

import InvoiceDetails from '../../../pages/financial-management/ui/InvoiceDetails/InvoiceDetails.en.json';

import UploadCoursesPage from '../../../pages/UploadCoursesPage/UploadCoursesPage.en.json';
import AdvancedSettingsStep
  from '../../../pages/UploadCoursesPage/ui/AdvancedSettingsStep/AdvancedSettingsStep.en.json';
import SelectCourseUploadMethodStep
  from '../../../pages/UploadCoursesPage/ui/SelectCourseUploadMethodStep/SelectCourseUploadMethodStep.en.json';
import UploadCourses from '../../../pages/UploadCoursesPage/ui/UploadCourses/UploadCourses.en.json';
import AddBankAccountModal
  from '../../../pages/FinancialSettingsPage/ui/AddBankAccountModal/AddBankAccountModal.en.json';
import AddCreditCardModal from '../../../pages/FinancialSettingsPage/ui/AddCreditCardModal/AddCreditCardModal.en.json';
import BankAccountForm from '../../../pages/FinancialSettingsPage/ui/BankAccountForm/BankAccountForm.en.json';
import BankAccountInfo from '../../../pages/FinancialSettingsPage/ui/BankAccountInfo/BankAccountInfo.en.json';
import CreditCardForm from '../../../pages/FinancialSettingsPage/ui/CreditCardForm/CreditCardForm.en.json';
import FinancialDashboardScreen
  from '../../../pages/FinancialSettingsPage/ui/FinancialDashboardScreen/FinancialDashboardScreen.en.json';
import FinancialStartScreen
  from '../../../pages/FinancialSettingsPage/ui/FinancialStartScreen/FinancialStartScreen.en.json';
import ManageBankAccountScreen
  from '../../../pages/FinancialSettingsPage/ui/ManageBankAccountScreen/ManageBankAccountScreen.en.json';
import ManageCreditCardScreen
  from '../../../pages/FinancialSettingsPage/ui/ManageCreditCardScreen/ManageCreditCardScreen.en.json';
import BankAccountStep from '../../../pages/FinancialSettingsPage/ui/BankAccountStep/BankAccountStep.en.json';
import CreditCardStep from '../../../pages/FinancialSettingsPage/ui/CreditCardStep/CreditCardStep.en.json';
import StepperScreen from '../../../pages/FinancialSettingsPage/ui/StepperScreen/StepperScreen.en.json';
import VerifyBankAccountFormModal
  from '../../../pages/FinancialSettingsPage/ui/VerifyBankAccountFormModal/VerifyBankAccountFormModal.en.json';
import VerifyBankAccountNotification
  from '../../../pages/FinancialSettingsPage/ui/VerifyBankAccountNotification/VerifyBankAccountNotification.en.json';
import BankAccountFormFields
  from '../../../pages/FinancialSettingsPage/ui/BankAccountFormFields/BankAccountFormFields.en.json';
import LogoutPage from '../../../pages/LogoutPage/LogoutPage.en.json';
import ErrorPage from '../../../pages/ErrorPage/ErrorPage.en.json';

import GeneralSettingsPage from '../../../pages/GeneralSettingsPage/GeneralSettingsPage.en.json';

import UserRolesPage from '../../../pages/user-roles/UserRolesPage/UserRolesPage.en.json';
import AssignUsersToRoleModalForm from '../../../pages/user-roles/ui/AssignUsersToRoleModalForm/AssignUsersToRoleModalForm.en.json';
import AddUserRolePage from '../../../pages/user-roles/AddUserRolePage/AddUserRolePage.en.json';
import GeneralRoleInfoForm from '../../../pages/user-roles/ui/GeneralRoleInfoForm/GeneralRoleInfoForm.en.json';
import ManageRolePermissionsForm from '../../../pages/user-roles/ui/ManageRolePermissionsForm/ManageRolePermissionsForm.en.json';
import ErrorLineBadge from '../../../pages/user-roles/ui/ErrorLineBadge/ErrorLineBadge.en.json';
import AssignUsersToRoleForm from '../../../pages/user-roles/ui/AssignUsersToRoleForm/AssignUsersToRoleForm.en.json';
import UserRolePermissionsInfoTooltip from '../../../pages/user-roles/ui/UserRolePermissionsInfoTooltip/UserRolePermissionsInfoTooltip.en.json';
import UserRoleEmailNotificationForm from '../../../pages/user-roles/ui/UserRoleEmailNotificationForm/UserRoleEmailNotificationForm.en.json';
import UserRoleDetailsPage from '../../../pages/user-roles/UserRoleDetailsPage/UserRoleDetailsPage.en.json';
import UserRolePermissionsTable from '../../../pages/user-roles/ui/UserRolePermissionsTable/UserRolePermissionsTable.en.json';

import DeactivateUserModal from '../../../pages/users/ui/DeactivateUserModal/DeactivateUserModal.en.json';
import UserManagementPage from '../../../pages/users/UserManagementPage/UserManagementPage.en.json';
import ReassignUserRoleToUsersOrUserModal
  from '../../../pages/users/UserManagementPage/ui/ReassignUserRoleToUsersOrUserModal/ReassignUserRoleToUsersOrUserModal.en.json';
import AddUserPage from '../../../pages/users/AddUserPage/AddUserPage.en.json';
import AddUserForm from '../../../pages/users/AddUserPage/ui/AddUserForm/AddUserForm.en.json';
import UserDetailsPage from '../../../pages/users/UserDetailsPage/UserDetailsPage.en.json';
import UserLog from '../../../pages/users/UserDetailsPage/ui/UserLog/UserLog.en.json';
import EditUserForm from '../../../pages/users/UserDetailsPage/ui/EditUserForm/EditUserForm.en.json';
import UserRequestsPage from '../../../pages/users/UserRequestsPage/UserRequestsPage.en.json';

import financial from '../../../pages/financial-management/financial.en.json';

const localization = {
  financial,
  Footer,
  LoggedInAsBanner,
  InstitutionMenu,
  AuthenticationPage,
  SingleSignOnSamlSettingsForm,
  ContactsPage, ContactSection, ContactCard,
  CourseMappingsTable,
  ErrorPage,
  LogoutPage,
  BankAccountFormFields,
  AdvancedCourseSettingsForm,
  DataUploadPage,
  CourseForm,
  AdvancedSettingsStep,
  SelectCourseUploadMethodStep,
  UploadCourses,
  UploadCoursesPage,
  AddBankAccountModal,
  AddCreditCardModal,
  BankAccountForm,
  BankAccountInfo,
  CreditCardForm,
  FinancialDashboardScreen,
  FinancialStartScreen,
  ManageBankAccountScreen,
  ManageCreditCardScreen,
  BankAccountStep,
  CreditCardStep,
  StepperScreen,
  VerifyBankAccountFormModal,
  VerifyBankAccountNotification,
  UserAuthHistoryTable,
  InvoiceDetails,
  GeneralSettingsPage,
  DeactivateUserModal,
  UserManagementPage,
  ReassignUserRoleToUsersOrUserModal,
  AddUserPage,
  AddUserForm,
  UserDetailsPage,
  UserLog,
  EditUserForm,
  UserRequestsPage,
  UserRolesPage,
  AssignUsersToRoleModalForm,
  AddUserRolePage,
  GeneralRoleInfoForm,
  ManageRolePermissionsForm,
  ErrorLineBadge,
  AssignUsersToRoleForm,
  UserRolePermissionsInfoTooltip,
  UserRoleEmailNotificationForm,
  UserRoleDetailsPage,
  UserRolePermissionsTable
};

const en = {
  'shared-admin-ui': localization
};

export default en;
