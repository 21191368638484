import type { RowData } from '@tanstack/react-table';
import uniq from 'lodash/uniq';

import parseTerm from 'common-lib/lib/parseTerm';

import { Select } from '../../../Select';

import type { FilterItemObject } from '../types';

export const createTableTermFilter = <TData extends RowData>({
  getTerm = row => row['section'].session.term,
  getTerms
}: {
  getTerm?: (row: TData) => string,
  getTerms?: (row: TData) => string[]
} = {}): FilterItemObject<TData> => {

  const getTermNameIfFits = (term, { year }: { year?: number }) => {
    const [name, _year] = parseTerm(term);
    if (year && _year !== year) {
      return;
    }
    return name;
  };

  const getTermName = (row: TData, { year }: { year?: number } = {}) => {
    return getTermNameIfFits(getTerm(row), { year });
  };

  const getTermNames = (row: TData, { year }: { year?: number } = {}) => {
    if (!getTerms) {
      throw new Error();
    }
    return getTerms(row).map((term) => {
      return getTermNameIfFits(term, { year });
    }).filter(_ => _);
  };

  return {
    id: 'term',
    filter(row, value) {
      if (getTerms) {
        return getTermNames(row).includes(value);
      } else {
        return getTermName(row) === value;
      }
    },
    render({ getValue, rows, getFilterValue, setFilterValues }) {
      // If currently filtering by year, then reduce the available terms
      // to only terms for the selected year.
      const year = getFilterValue('year');

      let terms;
      if (getTerms) {
        terms = rows.reduce<string[]>((all, row) => {
          return all.concat(getTermNames(row, { year }));
        }, []);
      } else {
        terms = rows.map(row => getTermName(row, { year }))
          .filter(_ => _);
      }

      terms = uniq(terms);

      const options = [
        { label: 'All', value: null },
        ...terms.map((term) => ({
          label: term,
          value: term
        }))
      ];

      return (
        <Select
          isFilter
          label="Term"
          value={getValue() ?? null}
          options={options}
          onChange={(value, option) => {
            setFilterValues(prev => ({
              ...prev,
              term: option,
              // Reset filter for session
              session: undefined
            }));
          }}
        />
      );
    }
  };
};
