import React, { memo } from 'react';

import { Button } from '../../../Button';

import { CourseSectionApprovedAndScheduledStatus } from '../CourseSectionApprovedAndScheduledStatus';
import { FormFooter } from '../../../FormFooter';

export interface SectionCardFooterProps {
  hasApprovedIcon?: boolean;
  isScheduled?: boolean;
  isStartingTooLateToRegister?: boolean;
  recommendProps?: {
    onRecommend?: () => void;
    url?: string;
  };
  canEnroll?: boolean;
  onDemand?: boolean;
  enrollProps?: {
    onEnroll?: () => void;
    url?: string;
  },
  courseUrl?: string;
  editScheduleUrl?: string;
  onResetSchedule?: () => void;
}

export const SectionCardFooter = memo<SectionCardFooterProps>(({
  hasApprovedIcon,
  isScheduled,
  isStartingTooLateToRegister,
  recommendProps,
  enrollProps,
  courseUrl,
  editScheduleUrl,
  onResetSchedule,
  canEnroll,
  onDemand
}) => {
  return (
    <FormFooter>
      {!onDemand && hasApprovedIcon && (
        <CourseSectionApprovedAndScheduledStatus
          isScheduled={Boolean(isScheduled)}
          isStartingTooLateToRegister={isStartingTooLateToRegister}
          editScheduleUrl={editScheduleUrl}
          onResetSchedule={onResetSchedule}
        />
      )}

      {recommendProps && (
        <Button
          variant="secondary"
          onClick={recommendProps.onRecommend}
          url={recommendProps.url}
        >
          Recommend
        </Button>
      )}

      {canEnroll && enrollProps && (
        <Button
          onClick={enrollProps.onEnroll}
          url={enrollProps.url}
        >
          Enroll
        </Button>
      )}

      {!onDemand && !canEnroll && courseUrl && (
        <Button url={courseUrl}>
          See Available Sections
        </Button>
      )}
    </FormFooter>
  );
});
