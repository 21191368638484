import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useInnerStyles, useStickyTop, useStickyBottom } from './hooks';

import styles from './Sticky.module.scss';

interface StickyProps {
  position: 'top' | 'bottom';
  children: React.ReactNode | (({ isSticky }: { isSticky: boolean }) => React.ReactNode);
  className?: classNames.ArgumentArray;
  fixed?: boolean;
}

export const Sticky = ({
  children,
  position,
  className,
  fixed
}: StickyProps) => {
  const { isSticky, ref } = (position === 'top' ? useStickyTop : useStickyBottom)();
  const sticky = fixed || isSticky;

  const innerStyles = useInnerStyles();

  return (
    <div
      ref={ref}
      className={classNames(styles.wrapper, styles[`wrapper--${position}`], {
        [styles.fixedWrapper]: fixed,
        [styles.inStream]: !isSticky
      })}
    >
      <div
        style={isSticky ? innerStyles : undefined}
        className={classNames(className, styles.inner, {
          [styles['isSticky']]: sticky,
          [styles.fixedInner]: fixed
        })}
      >
        {typeof children === 'function' ? children({ isSticky: sticky }) : children}
      </div>
    </div>
  );
};

Sticky.propTypes = {
  position: PropTypes.oneOf(['bottom', 'top']).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired
};

