import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  ConfirmActionModal,
  ContentSection,
  Form,
  Separator,
  StickyFormFooter,
  Text
} from '@acadeum/ui';

import { useTranslate } from '@acadeum/translate';

import { formatName } from '../../../../helpers/format';

import CourseOverviewSection from '../CourseOverviewSection';
import StudentOverviewCard from '../../../../components/StudentOverviewCard';

import actions from '../../../../actions';

import './Summary.sass';

const {
  updateEnrollmentState,
  resetEnrollmentState,
  createEnrollmentRequest,
  notify,
  notifyError,
  goto
} = actions;

export default function Summary({ navigation, setShowSuccessModal }) {
  const section = useSelector(state => state.sections.section);
  const enrollmentState = useSelector(state => state.enroll.enrollmentState);

  const [showRemoveStudentConfirmationModal, setShowRemoveStudentConfirmationModal] = useState();
  const [studentToRemove, setStudentToRemove] = useState();

  const t = useTranslate('Summary');

  const onBackAction = async (reasons) => {
    updateEnrollmentState({
      reasons,
      currentStepId: 'enrollStudents'
    });
    navigation.previous();
  };

  const onSubmitAction = async (reasons) => {
    const studentIds = enrollmentState.students.map(_ => _.id);
    const { sectionId, courseSubstituteId } = enrollmentState;

    let result;
    try {
      result = await createEnrollmentRequest({
        sectionId,
        courseSubstituteId,
        studentIds,
        reasons: reasons && reasons.length ? reasons : undefined
      });
    }
    catch (error) {
      console.error(error);
      throw new Error(t('errors.couldNotSubmitEnrollmentRequest'));
    }

    const {
      alreadyEnrolledStudentIds,
      createdCourseApproval
    } = result;

    if (alreadyEnrolledStudentIds.length === studentIds.length) {
      return notify(t('errors.alreadySubmittedRequest'));
    }

    if (alreadyEnrolledStudentIds.length === 0) {
      setShowSuccessModal(true);
    } else {
      notify((
        <div className="Summary">
          <h1 className="Summary__title">
            {t('success.successfullySent')}
          </h1>
          <h2 className="Summary__description">
            {createdCourseApproval && t('notifications.markedAsApproved')}
            {t('notifications.enrollmentsAlreadyRequested')}
          </h2>
          <table className="table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {students.filter((student) => alreadyEnrolledStudentIds.includes(student.id)).map((student) => (
                <tr key={student.id}>
                  <td>{student.hiStudentId}</td>
                  <td>{formatName(student)}</td>
                  <td>{student.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ), {
        contentLabel: t('success.successfullySentExceptDuplicates')
      });
    }
    resetEnrollmentState();
  };

  const onSubmit = async (values) => {
    const { formAction, ...restValues } = values;
    const reasons = matchReasonsAndNotes(restValues);

    switch (formAction) {
      case 'back':
        await onBackAction(reasons);
        break;
      case 'submit':
        await onSubmitAction(reasons);
        break;
    }
  };

  const matchReasonsAndNotes = (values) => {
    const { reasons, reasonNotes } = values;
    const studentIds = enrollmentState.students.map(_ => _.id);

    const matchedReasonsAndNotes = studentIds.reduce((result, id, index) => {
      if (reasons && reasons[index]) {
        result.push({
          studentId: id,
          reason: reasons[index],
          reasonNotes: reasonNotes && reasonNotes[index] ? reasonNotes[index] : null
        });
      }

      return result;
    }, []);

    return matchedReasonsAndNotes;
  };

  const onRemoveStudent = (student) => {
    if (enrollmentState.students.length === 1) {
      return notifyError(t('notifications.atLeastOneStudentLeft'));
    }
    setShowRemoveStudentConfirmationModal(true);
    setStudentToRemove(student);
  };

  const onConfirmRemoveStudent = () => {
    updateEnrollmentState({
      ...enrollmentState,
      students: students.filter(student => student.id !== studentToRemove.id)
    });
    setShowRemoveStudentConfirmationModal(false);
  };

  if (!section || !enrollmentState) {
    return null;
  }

  const { session } = section;
  const { course } = session;
  const { students } = enrollmentState;

  return (
    <ContentSection border={false} padding="none">
      <Form className="form" onSubmit={onSubmit}>
        <ContentSection>
          <CourseOverviewSection
            course={course}
            section={section}
          />
          <Separator/>
          <ContentSection
            border={false}
            padding="none"
            title={t('studentsOverview')}
          >
            <Text className="Summary__subsectionDescription">
              {t('studentsOverviewDescription')}
            </Text>
            {students.map((student, index) => (
              <StudentOverviewCard
                key={index}
                counter={index}
                student={student}
                type="ENROLLMENT"
                onRemove={onRemoveStudent}
              />
            ))}
          </ContentSection>
        </ContentSection>

        <StickyFormFooter
          onCancelProps={{
            onClick: () => goto(`/sections/${enrollmentState.sectionId}`)
          }}
          onBackProps={{
            action: 'back'
          }}
          submitProps={{
            action: 'submit',
            children: t('enroll')
          }}
        />
      </Form>

      {studentToRemove && (
        <ConfirmActionModal
          show={showRemoveStudentConfirmationModal}
          title={t('modal.title')}
          description={t('modal.description', {
            name: <span className="Summary__studentInfo">{formatName(studentToRemove)}</span>
          })}
          submitText={t('remove', { global: true })}
          onSubmit={onConfirmRemoveStudent}
          onHide={() => setShowRemoveStudentConfirmationModal(false)}
          onCancel={() => setShowRemoveStudentConfirmationModal(false)}
        />
      )}
    </ContentSection>
  );
}

Summary.propTypes = {
  navigation: PropTypes.shape({
    previous: PropTypes.func,
    next: PropTypes.func,
    go: PropTypes.func
  }).isRequired,
  setShowSuccessModal: PropTypes.func
};
