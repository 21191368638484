// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';

import { JSONEditorNew } from '../../../JSONEditorNew';

export const FormJSONInput = React.forwardRef(({
  name,
  rules,
  defaultValue,
  ...rest
}, ref) => {
  const { field } = useController({
    name,
    rules,
    defaultValue
  });

  return (
    <JSONEditorNew
      ref={ref}
      mode="json"
      {...rest}
      {...field}
    />
  );
});

FormJSONInput.propTypes = {
  name: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.any
};
