import React, { memo } from 'react';

import { Avatar } from '@acadeum/core-ui';

import styles from './ConsortiumBlock.module.scss';

export interface ConsortiumBlockProps {
  consortium: {
    logoUrl?: string;
    name: string
  };
}

export const ConsortiumBlock = memo<ConsortiumBlockProps>(({
  consortium
}) => {
  return (
    <div className={styles.ConsortiumBlock}>
      <Avatar
        size={32}
        type="institution"
        url={consortium.logoUrl}
      />
      <span>
        {consortium.name}
      </span>
    </div>
  );
});
