import React from 'react';
import PropTypes from 'prop-types';
import ReactTimeAgo from 'react-time-ago';

export default function RelativeDateFormat({ date, locale }) {
  return (
    <ReactTimeAgo date={date} locale={locale}/>
  );
}

RelativeDateFormat.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  locale: PropTypes.string
};

RelativeDateFormat.defaultProp = {
  locale: 'en'
};
