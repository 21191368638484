import React from 'react';
import PropTypes from 'prop-types';

import type { User } from '@acadeum/types';

import formatPhone from 'common-lib/lib/formatPhone.js';

import { Link } from '../Link';
import { Blank } from '../Blank';

export interface PhoneProps {
  number?: string;
  ext?: string;
  contact?: Pick<User, 'phone' | 'phoneExt'>;
}

export const Phone = ({
  contact,
  number,
  ext,
  ...rest
}: PhoneProps) => {
  if (contact) {
    number = contact.phone;
    ext = contact.phoneExt;
  }
  if (!number) {
    return <Blank/>;
  }
  return (
    <Link
      to={formatPhone(number, { ext, format: 'RFC3966' })}
      {...rest}>
      {formatPhone(number, {
        ext,
        // Format US/Canada/etc. phone numbers in national format.
        // Format phone numbers from other countries in international format.
        format: number?.startsWith('+1') ? 'NATIONAL' : 'INTERNATIONAL'
      })}
    </Link>
  );
};

Phone.propTypes = {
  number: PropTypes.string,
  ext: PropTypes.string,
  contact: PropTypes.shape({
    phone: PropTypes.string.isRequired,
    phoneExt: PropTypes.string
  })
};
