import React from 'react';
import type { FC } from 'react';
import classNames from 'classnames';

import { ContentSection, Text, DataBlock, FormField, FormModal, FormRow } from '@acadeum/ui';
import type { ActionsProps } from '@acadeum/ui';

import { useTranslate } from '@acadeum/translate';

import { getStudentLink } from '../../../../helpers/getStudentLink';

import styles from './ChangeEnrollmentStatusModal.module.scss';

interface ChangeEnrollmentStatusModalProps {
  title: React.ReactNode;
  submitText?: string;
  onSubmit: (value) => void;
  show?: boolean;
  onHide: () => void;
  inputGrades?: boolean;
  isTeaching?: boolean;
  // eslint-disable-next-line
  enrollments: any[];
  children?: React.ReactNode;
  danger?: boolean;
  defaultGrade?: string;
  actionsHeader?: ActionsProps['actions'];
}

const ChangeEnrollmentStatusModal: FC<ChangeEnrollmentStatusModalProps> = ({
  title,
  submitText,
  onSubmit: onSubmit_,
  show = false,
  onHide,
  inputGrades,
  enrollments,
  isTeaching = false,
  defaultGrade,
  children,
  danger = false,
  actionsHeader
}) => {
  const t = useTranslate('Enrollments');

  const onSubmit = async (values) => {
    const grades = {};
    // Fill in `grades` from `values`.
    if (inputGrades) {
      for (const key of Object.keys(values)) {
        if (key.includes(':')) {
          const [id, type] = key.split(':');
          if (!grades[id]) {
            grades[id] = {};
          }
          switch (type) {
            case 'letter-grade':
              grades[id].letterGrade = values[key];
              break;
            case 'numerical-grade':
              grades[id].numericalGrade = values[key];
              break;
            case 'notes':
              grades[id].gradeNotes = values[key];
              break;
          }
        }
      }
    }
    // Call `onSubmit()`.
    await onSubmit_({
      grades,
      reason: values.reason,
      reasonNotes: values.reasonNotes
    });
  };

  const textIsTeaching = isTeaching ? t('homeInstitution') : t('teachingInstitution');

  return (
    <FormModal
      show={show}
      onCancel={onHide}
      onHide={onHide}
      title={title}
      submitText={submitText}
      onSubmit={onSubmit}
      danger={danger}
      actions={actionsHeader}
    >
      {enrollments.map(enrollment => {
        const canInputGradesForSomeEnrollments = inputGrades && (enrollment.type === 'CONSORTIAL' || enrollment.type === 'DUAL');
        const designSubmitGrade = enrollment.type === 'CONSORTIAL' || enrollment.type === 'DUAL' || enrollment.type === 'TRANSFER';

        return (
          <div className={classNames(styles.ChangeEnrollmentStatusModal, {
            [styles['ChangeEnrollmentStatusModal--canInputGradesForSomeEnrollments']]: designSubmitGrade
          })} key={enrollment.id}>
            {canInputGradesForSomeEnrollments && (
              <>
                <Text variant="subtitle2" mb="md">
                  {t('grade')}
                </Text>
                <FormRow>
                  <FormField
                    required
                    label={t('letterGrade')}
                    name={`${enrollment.id}:letter-grade`}
                    defaultValue={enrollment.letterGrade || defaultGrade}
                  />
                  <FormField
                    label={t('numericalGrade')}
                    name={`${enrollment.id}:numerical-grade`}
                    defaultValue={enrollment.numericalGrade}
                    type="number"
                  />
                </FormRow>
                <FormField
                  multiline
                  label={t('note')}
                  name={`${enrollment.id}:notes`}
                  defaultValue={enrollment.gradeNotes}
                  placeholder={t('typeHere')}
                />
                <hr/>
              </>
            )}
            {designSubmitGrade && (
              <Text variant="subtitle2" mb="md">
                {t('details')}
              </Text>
            )}
            <ContentSection padding={false} border={false} title={t('studentName')}>
              <DataBlock
                type="student"
                student={enrollment.student}
                url={getStudentLink(enrollment.student)}
              />
            </ContentSection>
            <ContentSection padding={false} border={false} title={textIsTeaching}>
              <Text>
                {isTeaching ? enrollment.student.institution.name : enrollment.section.session.course.institution.name}
              </Text>
            </ContentSection>
            <ContentSection padding={false} border={false} title={t('course')}>
              <Text>
                {enrollment.section.session.course.title}
              </Text>
              <Text color="grey" variant="bodySm">
                {enrollment.section.session.course.code}
              </Text>
            </ContentSection>
            <ContentSection padding={false} border={false} title={t('courseDates')}>
              <Text>
                <DataBlock
                  utc
                  as="span"
                  type="date"
                  month="long"
                  date={enrollment.section.session.startDate}
                />
                {' - '}
                <DataBlock
                  utc
                  as="span"
                  type="date"
                  month="long"
                  date={enrollment.section.session.endDate}
                />
              </Text>
              <Text color="grey" variant="bodySm">
                {t('dropDate', {
                  date: () => (
                    <DataBlock
                      utc
                      as="span"
                      type="date"
                      month="long"
                      date={enrollment.section.session.lastDropDate}
                    />
                  )
                })}
              </Text>
            </ContentSection>
            <ContentSection padding={false} border={false} title={t('requestDate')}>
              <DataBlock
                utc
                type="date"
                month="long"
                date={enrollment.createdAt}
              />
            </ContentSection>
          </div>
        );
      })}
      {children}
    </FormModal>
  );
};
export default ChangeEnrollmentStatusModal;
