import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const notify = redux.simpleAction(
  (state, notification) => ({
    ...state,
    notification
  })
);

// Should be called after displaying a new message.
export const onNotificationDisplayed = redux.simpleAction(
  (state) => ({
    ...state,
    notification: undefined
  })
);

export default redux.reducer();
