import { useTranslate } from '@acadeum/translate';
import { InfoTooltip } from '@acadeum/ui';

export const UserRolePermissionsInfoTooltip = () => {
  const t = useTranslate('shared-admin-ui.UserRolePermissionsInfoTooltip');
  return (
    <InfoTooltip placement="right" ariaTitle={t('permissionTypes')}>
      <b>"None"</b> gives no access. <br/>
      <b>"All"</b> permits access to any object data. <br/>
      <b>"Own"</b> permits access to one’s own institution data. <br/>
      {/*<b>"Own:subsidiary"</b> gives access to one’s own institution data plus the data of any subsidiary institutions. */}
      {/*<br/>*/}
      <b>"Individual"</b> give access to one’s own personal data only.
    </InfoTooltip>
  );
};
