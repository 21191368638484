// TODO: Add more correct types
interface Query {
  // eslint-disable-next-line
  [x: string]: any;
}

type TransformQueryParametersToStringsResult = Query;

type TransformQueryParametersToStrings = (query: Query) => TransformQueryParametersToStringsResult;

export const transformQueryParametersToStrings: TransformQueryParametersToStrings = (query) => {
  const transformedQuery: TransformQueryParametersToStringsResult = {};
  for (const key of Object.keys(query)) {
    if (typeof query[key] === 'boolean') {
      if (query[key]) {
        transformedQuery[key] = '✓';
      } else {
        transformedQuery[key] = '✕';
      }
    } else {
      transformedQuery[key] = query[key];
    }
  }
  return transformedQuery;
};
