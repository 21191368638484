import React, { useEffect, useId, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Configure, InstantSearch } from './react-instantsearch-dom';
import InstantSearchResultsState from './InstantSearchResultsState';
import InstantSearchResultsCount from './InstantSearchResultsCount';
import InstantSearchRefinements, { transformRangeRefinements } from './InstantSearchRefinements';
import InstantSearchResults from './InstantSearchResults';
import InstantSearchScrollToOnPageNavigation, {
  InstantSearchScrollToOnPageNavigationStub
} from './InstantSearchScrollToOnPageNavigation';
import InstantSearchExportData from './InstantSearchExportData';

import { ScreenReaderOnly } from '@acadeum/core-ui';
import { BaseButton as Button } from '@acadeum/ui';

import SearchBox from './react-instantsearch-dom/widgets/SearchBox';
import ClearRefinements from './react-instantsearch-dom/widgets/ClearRefinements';
import HitsPerPage from './react-instantsearch-dom/widgets/HitsPerPage';
import SortBy from './react-instantsearch-dom/widgets/SortBy';
import SearchBoxNew from './react-instantsearch-dom/widgets/SearchBoxNew';

import { readStateFromUrl, writeStateToUrl, writeStateToUrlQuery } from '@acadeum/helpers';

import CloseFiltersModal from './CloseFiltersModal.svg';

import './InstantSearch.sass';
import './InstantSearchBanner.sass';
import './InstantSearchClearRefinements.sass';
import './InstantSearchFilters.sass';
import './InstantSearchHeader.sass';
import './InstantSearchSearchInput.sass';

/**
 * `react-instantsearch` docs:
 * https://github.com/algolia/react-instantsearch/tree/c2dbfe6cdfa89268342fa95ab532063e84f49a57/docgen/src/guide
 */
const CCInstantSearch = ({
  apiKey,
  appId,
  className,
  component: SearchResultComponent,
  filterNames,
  floatingHeaderHeight,
  hideResultsUntilInput,
  indexPrefix,
  isNarrowContent,
  // props new designer Course Search
  newCourseSearchDesign,
  onExportData,
  prefilters,
  useFilters,
  searchFieldBanner,
  searchFieldBannerChildren,
  searchFieldBannerFullWidth,
  searchFieldLabel,
  searchUrlParams,
  id,
  refinementTransforms,
  // transform,
  // Exclude `router` from `...rest`
  // eslint-disable-next-line no-unused-vars
  ranges = [],
  resultsPerPage = [12, 24, 60],
  layout = 'grid',
  url = true,
  showFilters: showFiltersProperty = true,
  isInsideForm = false,
  searchFieldPlaceholder = '',
  resultsCountPlacementLargeScreen = 'header',
  clearRefinementsButtonPlacement = 'header',
  filtersColClassName = classNames(
    'col-xs-12',
    'col-sm-12',
    'col-md-4',
    'col-3'
  ),
  resultsColClassName = classNames(
    'col-xs-12',
    'col-sm-12',
    'col-md-8',
    'col-9'
  ),
  canFilterBeCleared = () => true,
  shouldFilterBeCleared = () => true,
  ...rest
}) => {
  const [showFiltersModal, setShowFiltersModal] = useState();
  const [searchState, setSearchState] = useState({});
  const [hasQueryBeenInput, setHasQueryBeenInput] = useState();
  const [debouncedSetState, setDebouncedSetState] = useState();

  const dataExporterRef = useRef(null);

  useEffect(() => {
    let searchState = {};
    if (url) {
      const location = getLocation();
      if (location) {
        searchState = readStateFromUrl(location);
      }
    }
    setSearchState(searchState);
    setHasQueryBeenInput(Boolean(searchState.query));
    return () => {
      clearTimeout(debouncedSetState);
    };
  }, []);

  const getLocation = () => {
    // Client-side rendering.
    if (typeof window !== 'undefined') {
      return window.location;
    }
    const { location } = rest;
    if (location) {
      return location;
    }
  };

  const hideFiltersModal = () => {
    setShowFiltersModal(false);
  };
  const openShowFiltersModal = () => {
    setShowFiltersModal(true);
  };

  const transformRefinements = (items) => {
    // Modify some of the refinements.
    if (refinementTransforms) {
      items = items.map((item) => {
        if (refinementTransforms[item.attribute]) {
          return refinementTransforms[item.attribute](item);
        }
        return item;
      });
    }

    // Don't display refinements which are not editable (e.g. `institutionId`)
    // const fixedRefinements = items.filter(_ => !canFilterBeCleared(_));
    const restRefinements = items.filter(_ => canFilterBeCleared(_));

    return transformRangeRefinements(restRefinements, ranges);
  };

  const onSearchStateChange = (searchState) => {
    setSearchState(searchState);
    // if (!searchState) {
    //   searchState = {
    //     ...this.state.searchState
    //   };
    // }
    if (url) {
      clearTimeout(debouncedSetState);
      const debouncedSetState_ = setTimeout(() => {
        history.replaceState(null, '', writeStateToUrl(getSearchStateForUrl(searchState), window.location));
      }, 300);
      setDebouncedSetState(debouncedSetState_);
    }
  };

  const getSearchStateForUrl = (searchState) => {
    return {
      ...searchState,
      ...searchUrlParams
    };
  };

  const transformClearRefinementsItems = (items) => {
    return items
      .filter(item => canFilterBeCleared(item))
      .filter(item => shouldFilterBeCleared(item));
  };

  useEffect(() => {
    if (hideResultsUntilInput && !hasQueryBeenInput) {
      if (searchState.query) {
        setHasQueryBeenInput(true);
      }
    }
  }, [searchState, hideResultsUntilInput]);

  const renderClearRefinements = () => {
    return (
      <ClearRefinements
        clearsQuery
        transformItems={transformClearRefinementsItems}
        translations={CLEAR_REFINEMENTS_LABELS}
        className={classNames({
          'InstantSearch-ClearRefinements': !newCourseSearchDesign
        })}/>
    );
  };

  let {
    indexes
  } = rest;

  // `<ScrollTo/>` (which internally uses `@connectScroll()` connector)
  // frequently results in 100% CPU usage and page freezing
  // when `react-hot-loader` is being used.
  // In production mode there seem to be no issues.
  // https://github.com/algolia/react-instantsearch/issues/2396
  const ScrollToOnPageNavigation = process.env.NODE_ENV === 'development'
    ? InstantSearchScrollToOnPageNavigationStub
    : InstantSearchScrollToOnPageNavigation;

  const showFilters = Boolean(useFilters) && showFiltersProperty;

  indexes = indexes.map(index => ({ ...index, value: (indexPrefix || '') + index.value }));

  const searchBoxTranslations = {
    placeholder: searchFieldPlaceholder
  };

  const ariaDescribedbyId = `InstantSearch-${id}-SearchInput-Description`;

  const EnteringDataText = () => (
    <ScreenReaderOnly id={ariaDescribedbyId} as="span">
      Entering data into the form field will update the content below.
    </ScreenReaderOnly>
  );

  const instantSearchFilterValuesAndClearAll = (
    <div className={classNames({
      'InstantSearch-ResultsCount--wrapper-refinements': newCourseSearchDesign
    })}>
      <InstantSearchRefinements
        clearRefinementsButton={showFilters && clearRefinementsButtonPlacement === 'refinements'
          ? renderClearRefinements()
          : undefined}
        transformRefinements={transformRefinements}
        filterNames={filterNames}/>

      {/* "Clear all" (mobile) */}
      {/* anyFiltersAreSet(searchState) && */}
      {showFilters &&
        <div className={classNames({
          'InstantSearch-ClearRefinementsContainer--smallScreen': !newCourseSearchDesign
        })}>
          {renderClearRefinements()}
        </div>
      }
    </div>
  );

  {/*
  Render search filters.

  Previously, it was being rendered as `<Filters/>`
  but then it was found out that `Filters` component
  was re-created on every render as `const Filters = () => ...`.
  That lead to the `<Filters/>` element getting unmounted on each render,
  resulting in all search filter values restored from the URL being reset.
  The reason is that `createConnector()` function calls `cleanUp()` in `componentWllUnmount()`,
  and `cleanUp()` clears any pre-set filter values in `searchState`.

  So to fix the "search filters getting reset on page load" bug,
  search filters are now passed as a function rather than a React component.
  */}
  {/* eslint-disable-next-line react-hooks/rules-of-hooks*/}
  const filters = useFilters && useFilters({
    searchState: searchState,
    onSearchStateChange: onSearchStateChange,
    instantSearchFilterValuesAndClearAll: newCourseSearchDesign
      ? instantSearchFilterValuesAndClearAll
      : undefined
  });

  // `createURL()` is a function that `react-instantsearch` package calls internally in various cases.
  // For example, when rendering `Pagination`, every page number element is rendered a hyperlink.
  // When a user clicks on such hyperlink, it updates the current page URL to be `createURL(newState)`.
  return (
    <InstantSearch
      appId={appId}
      apiKey={apiKey}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={writeStateToUrlQuery}
      indexName={indexes[0].value}>

      {prefilters &&
        <Configure
          filters={prefilters.filters}
          facetFilters={prefilters.facetFilters}
          numericFilters={prefilters.numericFilters}
          tagFilters={prefilters.tagFilters}
        />
      }

      <InstantSearchResultsState/>

      <section
        className={classNames('InstantSearch', {
          'InstantSearch--hideResults': hideResultsUntilInput && !hasQueryBeenInput,
          'InstantSearch--banner': searchFieldBanner
        }, className)}>

        {!newCourseSearchDesign && searchFieldBanner &&
          <section className="InstantSearch-Banner">
            <div className={classNames({
              'container': searchFieldBannerFullWidth
            })}>
              {/* This tag has been changed from `h1` to `p` because of accessibility requirements:
                  https://github.com/Acadeum/Tickets/issues/1148 */}
              <p className="InstantSearch-BannerHeading">
                {searchFieldBanner}
              </p>

              <ScreenReaderOnly as="h1">
                Courses
              </ScreenReaderOnly>

              {/* Search box */}
              <SearchBox
                translations={searchBoxTranslations}
                aria-label="Search"
                aria-describedby={ariaDescribedbyId}
                form={!isInsideForm}
                className="InstantSearch-SearchInput InstantSearch-SearchInput--banner"
              />
              <EnteringDataText/>
              {/* Search box banner children. */}
              {searchFieldBannerChildren}
            </div>
          </section>
        }

        {/* Shows inline search box on mobile devices here */}
        {!searchFieldBanner &&
          <>
            <SearchBox
              translations={searchBoxTranslations}
              aria-label="Search"
              aria-describedby={ariaDescribedbyId}
              form={!isInsideForm}
              className="InstantSearch-SearchInput InstantSearch-SearchInput--smallScreen"
            />
            <EnteringDataText/>
          </>
        }

        <div className="InstantSearch-Body">
          {/* Mobile device header */}
          {!newCourseSearchDesign && (
            <div className="InstantSearch-Header--smallScreen">
              {showFilters &&
                <Button
                  onClick={openShowFiltersModal}
                  className="InstantSearch-ShowFilters">
                  Expand Filters
                </Button>
              }

              <InstantSearchResultsCount className="InstantSearch-ResultsCount--smallScreen"/>

              <div className="InstantSearch-Line"/>
            </div>
          )}


          <ScrollToOnPageNavigation offset={floatingHeaderHeight ? floatingHeaderHeight + 12 : undefined}>
            <div
              className={classNames('InstantSearch-Main', {
                'container': searchFieldBannerFullWidth && !newCourseSearchDesign
              })}>
              <div className="row">
                {!newCourseSearchDesign && onExportData &&
                  <div className="InstantSearch-ExportData">
                    <InstantSearchExportData
                      ref={dataExporterRef}
                      onExportData={onExportData}
                    />
                  </div>
                }
                {filters && (
                  <>
                    {newCourseSearchDesign ? (
                      <div>
                        {filters}
                      </div>
                    ) : (
                      <div
                        className={classNames('InstantSearch-FiltersContainer', {
                          [filtersColClassName]: !newCourseSearchDesign,
                          'InstantSearch-FiltersContainer--hidden': !showFilters,
                          'InstantSearch-FiltersContainer--hiddenModal': !showFiltersModal
                        })}>
                        <aside className="InstantSearch-Filters">
                          {/* Mobile filters header */}
                          <header className="InstantSearch-FiltersHeader--smallScreen">
                            <h2 className="InstantSearch-FiltersHeading">
                              Search Filters
                              <CloseFiltersModal
                                aria-hidden
                                onClick={hideFiltersModal}
                                className="InstantSearch-CloseFilters"/>
                            </h2>

                            <div className="align-children-center-vertically">
                              <InstantSearchResultsCount className="InstantSearch-FiltersResultsCount"/>

                              <div className="InstantSearch-SectionHeadingLine"/>
                            </div>

                            <div className="InstantSearch-HeaderControls">
                              <div className="InstantSearch-HeaderControl">
                                <div
                                  className="InstantSearch-HeaderControlLabel InstantSearch-HeaderControlLabel--smallScreen">
                                  Sort
                                </div>
                                <SortBy
                                  aria-label="Sort By"
                                  items={indexes}
                                  defaultRefinement={indexes[0].value}/>
                              </div>

                              <div className="InstantSearch-HeaderControl">
                                <div
                                  className="InstantSearch-HeaderControlLabel InstantSearch-HeaderControlLabel--smallScreen">
                                  Results Per Page
                                </div>
                                <ResultsPerPage options={resultsPerPage}/>
                              </div>
                            </div>
                          </header>

                          {/* Desktop filters header */}
                          {!newCourseSearchDesign && (
                            <header
                              className="InstantSearch-FiltersHeader--largeScreen align-children-center-vertically">
                              <h2 className="InstantSearch-FiltersHeading">
                                Filter Results
                              </h2>
                              <div className="InstantSearch-SectionHeadingLine"/>
                            </header>
                          )}

                          {filters}

                          {/* "Apply Filters" */}
                          <div className="InstantSearch-FiltersActions">
                            <Button
                              className="InstantSearch-ApplyFilters"
                              onClick={hideFiltersModal}>
                              Apply Filters
                            </Button>
                          </div>
                        </aside>
                      </div>
                    )}
                  </>
                )}

                {/* Search results (with control panel) */}
                <div className={!newCourseSearchDesign && showFilters ? resultsColClassName : 'col-12'}>
                  {/* Search box */}
                  {!newCourseSearchDesign && !searchFieldBanner &&
                    <div className="InstantSearch-SearchInputInlineContainer">
                      <div className="InstantSearch-SearchInputInlineLabel">
                        {searchFieldLabel}
                      </div>
                      <SearchBox
                        translations={searchBoxTranslations}
                        aria-label="Search"
                        aria-describedby={ariaDescribedbyId}
                        form={!isInsideForm}
                        className="InstantSearch-SearchInput"
                      />
                      <EnteringDataText/>
                    </div>
                  }

                  {/* Search results control panel */}
                  <div className="InstantSearch-Header--largeScreen">
                    {newCourseSearchDesign && resultsCountPlacementLargeScreen === 'header' && (
                      <div
                        className="InstantSearch-ResultsCount--header InstantSearch-ResultsCount--header--newCourseSearchDesign">
                        <InstantSearchResultsCount/>
                        {onExportData &&
                          <InstantSearchExportData
                            ref={dataExporterRef}
                            onExportData={onExportData}
                            variant="tertiary"
                          />
                        }
                      </div>
                    )}
                    {!newCourseSearchDesign && resultsCountPlacementLargeScreen === 'header' &&
                      <InstantSearchResultsCount className="InstantSearch-ResultsCount--header"/>
                    }

                    <div className="InstantSearch-HeaderControls">
                      <div className="InstantSearch-HeaderControl align-children-center-vertically">
                        <div className="InstantSearch-HeaderControlLabel">
                          Results Per Page
                        </div>
                        <ResultsPerPage options={resultsPerPage}/>
                      </div>

                      <div className="InstantSearch-HeaderControlSeparator"/>

                      <div className="InstantSearch-HeaderControl align-children-center-vertically">
                        <div className="InstantSearch-HeaderControlLabel">
                          Sort
                        </div>
                        <SortBy
                          aria-label="Sort By"
                          items={indexes}
                          defaultRefinement={indexes[0].value}/>
                      </div>
                      {newCourseSearchDesign && (
                        <>
                          <ScreenReaderOnly as="h1">
                            Courses
                          </ScreenReaderOnly>
                          <div className="InstantSearch-HeaderControlSeparator"/>
                          <SearchBoxNew/>
                        </>
                      )}

                      {!newCourseSearchDesign && showFilters && clearRefinementsButtonPlacement === 'header' &&
                        renderClearRefinements()
                      }
                    </div>
                  </div>

                  <hr className="InstantSearch-Line InstantSearch-Line--header"/>
                  {/* Currently selected filters list */}
                  {!newCourseSearchDesign && (
                    <>
                      <div>
                        {instantSearchFilterValuesAndClearAll}
                      </div>
                      {showFilters && (
                        <div className="InstantSearch-ClearRefinementsContainer--smallScreen InstantSearch-Line"/>
                      )}

                      {resultsCountPlacementLargeScreen === 'above-results' &&
                        <InstantSearchResultsCount hideWhenNoResults className="InstantSearch-ResultsCount--results"/>
                      }
                    </>
                  )}

                  {/* Search results */}
                  <InstantSearchResults
                    floatingHeaderHeight={floatingHeaderHeight}
                    isNarrowContent={isNarrowContent}
                    isWideContent={!showFilters}
                    component={SearchResultComponent}
                    newCourseSearchDesign={newCourseSearchDesign}
                    layout={layout}
                    {...rest} />
                </div>
              </div>
            </div>
          </ScrollToOnPageNavigation>
        </div>
      </section>
    </InstantSearch>
  );
};

CCInstantSearch.propTypes = {
  // Server-side rendered pages can pass `location` manually.
  location: PropTypes.oneOfType([
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string.isRequired
    }),
    // Next.js doesn't provide `location.search`, but provides `query`.
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      query: PropTypes.object.isRequired
    })
  ]),
  // If side bar is expanded in `JITUApp` then `isNarrowContent` is `true`.
  isNarrowContent: PropTypes.bool,
  // `searchFieldBanner: true` means that
  // the full-width free-text search banner
  // will be shown above the search widget.
  searchFieldBanner: PropTypes.string,
  searchFieldPlaceholder: PropTypes.string,
  // Sometimes `searchFieldBanner` needs a
  // different background colorwhich means that
  // this component won't be wrapped in a ".container"
  // otherwise the free-text search banner wouldn't
  // stretch to full page width.
  // Hence, `searchFieldBannerFullWidth` property is checked
  // to determine whether to add ".container" CSS class
  // internally in this component itself.
  // An alternative solution would be
  // splitting this component into three:
  // * `<InstantSearchContainer/>`
  // * `<InstantSearchBanner/>`
  // * `<InstantSearch/>`
  searchFieldBannerFullWidth: PropTypes.bool,
  searchFieldBannerChildren: PropTypes.node,
  searchFieldLabel: PropTypes.string,
  searchUrlParams: PropTypes.object,
  hideResultsUntilInput: PropTypes.bool,
  floatingHeaderHeight: PropTypes.number,
  resultsPerPage: PropTypes.arrayOf(PropTypes.number),
  resultsCountPlacementLargeScreen: PropTypes.oneOf(['header', 'above-results']),
  clearRefinementsButtonPlacement: PropTypes.oneOf(['header', 'refinements']),
  onExportData: PropTypes.func,
  url: PropTypes.bool,
  prefilters: PropTypes.shape({
    filters: PropTypes.string,
    facetFilters: PropTypes.arrayOf(PropTypes.any),
    numericFilters: PropTypes.arrayOf(PropTypes.any),
    tagFilters: PropTypes.arrayOf(PropTypes.any)
  }),
  useFilters: PropTypes.func,
  showFilters: PropTypes.bool,
  ranges: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      attribute: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['currency'])
    }),
    PropTypes.shape({
      type: PropTypes.oneOf(['date']).isRequired,
      fromAttributeName: PropTypes.string.isRequired,
      toAttributeName: PropTypes.string,
      fromPlaceholder: PropTypes.string.isRequired,
      toPlaceholder: PropTypes.string.isRequired
    })
  ])),
  indexes: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,
  filterNames: PropTypes.object,
  canFilterBeCleared: PropTypes.func,
  shouldFilterBeCleared: PropTypes.func,
  refinementTransforms: PropTypes.objectOf(PropTypes.func),
  component: PropTypes.func.isRequired,
  newCourseSearchDesign: PropTypes.bool,
  layout: PropTypes.oneOf(['row', 'grid', 'rowGrid', 'rowGridTwoColumns']),
  appId: PropTypes.string.isRequired,
  apiKey: PropTypes.string.isRequired,
  isInsideForm: PropTypes.bool,
  className: PropTypes.string,
  filtersColClassName: PropTypes.string,
  resultsColClassName: PropTypes.string
};

function ResultsPerPage({ options }) {
  return (
    <HitsPerPage
      aria-label="Results per page"
      defaultRefinement={options[0]}
      items={options.map(_ => ({ value: _, label: String(_) }))}/>
  );
}

// Until it really clears all it's gonna stay "Clear filters"
const CLEAR_REFINEMENTS_LABELS = {
  reset: 'Clear Filters' // 'Clear All'
};

// Turns out `searchState` doesn't remove `false` values for toggles
// and empty string values for "refinement lists",
// so this easy way won't do the task.
// function anyFiltersAreSet(state) {
//   // `state` will be mutated
//   state = { ...state };
//   // Remove empty `query`
//   if (state.query && state.query.length === 0) {
//     delete state.query;
//   }
//   // Remove `page`
//   delete state.page
//   // If there are any filters set, then it will return `true`.
//   return Object.keys(state).length > 0;
// }

// Only supports: query, toggles, ranges and refinement lists.
// It was later found out that not all refinements are `false` by default:
// there might be checkboxes which are `true` by default.
// (for example, "Regionally Accredited" for Course Search)
// So this function might not always work. So it's not used.
// function anyFiltersAreSet({ query, refinementList, toggle, range, excludePast }) {
//   const hasQuery = query && query.length > 0;
//   const isRefined = refinementList && Object.keys(refinementList).map(key => refinementList[key]).filter(value =>
// value).length > 0; const isToggled = toggle && Object.keys(toggle).map(key => toggle[key]).filter(value =>
// value).length > 0; const isRanged = range && Object.keys(range).length > 0; const isExcludePast = excludePast &&
// Object.keys(excludePast).map(key => excludePast[key] !== undefined).length > 0; return hasQuery || isRefined ||
// isToggled || isRanged || isExcludePast; }

// function isFirstPage({ page }) {
//   return !page || page === 1;
// }

const CCInstantSearch_ = (props) => {
  const id = useId();
  return (
    <CCInstantSearch id={id} {...props} />
  );
};

export default CCInstantSearch_;
