import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'react-responsive-ui';

import isAcadeumAdministrator from '../../helpers/isAcadeumAdministrator';

import Field from '../../components/Field';
import Form from '../../components/Form';
import Subsection from '../../components/Subsection';
import FormButtons from '../../components/FormButtons';
import FormSubmit from '../../components/FormSubmit';
import Button from '../../components/Button';
import Row from '../../components/Row';
import RoleFormField, { getRoleIdsFromRoleFormValues } from '../../components/RoleFormField';
import ConfirmActionModal from '../../components/ConfirmActionModal';

import userRoleIds from 'common-lib/constants/userRoleIds.json';

import { YES_OR_NO_OPTIONS } from '../../helpers/select';

import useRolesList from '../../hooks/useRolesList';

import './UserForm.sass';

let UserForm = ({
  userRoles,
  autoFocus,
  adminPanel,
  submitText = 'Submit',
  cancelLink,
  superUser,
  user,
  onSubmit
}, ref) => {
  const rolesList = useRolesList(userRoles);

  const isEmailEditable = adminPanel || !user;

  const isAlreadyAcadeumAdministrator = useMemo(() => {
    return user && isAcadeumAdministrator(user);
  });

  const [groupOrOfficeAccount, setGroupOrOfficeAccount] = useState(user && user.type === 'GROUP');

  const [submitFormValues, setSubmitFormValues] = useState();
  const [confirmAssignRoles, setConfirmAssignRoles] = useState();
  const [showConfirmAssignRolesModal, setShowConfirmAssignRolesModal] = useState();

  const onSubmit_ = async (values) => {
    if (groupOrOfficeAccount) {
      delete values.firstName;
      delete values.middleName;
      delete values.nameName;
      values.type = 'GROUP';
    } else {
      values.type = 'INDIVIDUAL';
    }
    if (!isEmailEditable) {
      delete values.email;
    }
    const roleIds = getRoleIdsFromRoleFormValues(values, { remove: false });
    const willAssignPlatformAdministratorRole = roleIds.some((roleId) => {
      return roleId === userRoleIds['Platform Administrator'];
    });
    const willAssignAcadeumAdministratorRole = roleIds.some((roleId) => {
      return roleId === userRoleIds['Acadeum Administrator'];
    });
    if (!isAlreadyAcadeumAdministrator && (
      willAssignPlatformAdministratorRole || willAssignAcadeumAdministratorRole
    )) {
      setConfirmAssignRoles([
        ...(willAssignPlatformAdministratorRole ? ['Platform Administrator'] : []),
        ...(willAssignAcadeumAdministratorRole ? ['Acadeum Administrator'] : [])
      ]);
      setShowConfirmAssignRolesModal(true);
      setSubmitFormValues(values);
    } else {
      return await onSubmit(values);
    }
  };

  const onProceedWithUpdatingUser = async () => {
    await onSubmit(submitFormValues);
  };

  return (
    <Form
      ref={ref}
      autoFocus={autoFocus}
      className="form"
      onSubmit={onSubmit_}>
      {({ values, set }) => (
        <>
          <Subsection title="Personal Information">
            <Checkbox
              value={groupOrOfficeAccount}
              label="Group or office account"
              onChange={setGroupOrOfficeAccount}>
              Group or office account
            </Checkbox>

            {!groupOrOfficeAccount &&
              <Row>
                <Field
                  required
                  type="name"
                  value={user && user.firstName}
                  label="First Name"
                  name="firstName"
                  col={4} />

                <Field
                  type="name"
                  value={user && user.middleName}
                  label="Middle Name"
                  name="middleName"
                  col={4} />

                <Field
                  required
                  type="name"
                  value={user && user.lastName}
                  label="Last Name"
                  name="lastName"
                  col={4} />
              </Row>
            }

            <Row>
              <Field
                required
                value={user && user.title}
                label="Title"
                name="title"
                col={4} />

              <Field
                required
                disabled={!isEmailEditable}
                value={user && user.email}
                label="Email"
                type="email"
                name="email"
                col={4} />

              <Field
                required
                value={user && user.phone}
                name="phone"
                type="phone"
                label="Phone"
                col={3} />

              <Field
                value={user && user.phoneExt}
                name="phoneExt"
                type="phone-ext"
                label="Phone ext"
                col={1} />
            </Row>

            {superUser && adminPanel &&
              <Row>
                <Field
                  required
                  label="Institution"
                  name="institutionId"
                  value={user && user.institution && user.institution.id}
                  type="institution"
                  superAdmin={adminPanel}
                  col={4} />

                <Field
                  required
                  label="SFTP Access"
                  name="sftp"
                  value={Boolean(user && user.sftp)}
                  options={YES_OR_NO_OPTIONS}
                  col={4} />

                {/*
                <Field
                  required
                  label="API Access"
                  name="api"
                  value={Boolean(user && user.api)}
                  type="checkbox"
                  col={4} />
                */}
              </Row>
            }
          </Subsection>

          <Subsection title="User Roles">
            {rolesList.map((pair, i) => (
              <React.Fragment key={i}>
                {pair.map((role, i) => (
                  <RoleFormField
                    key={i}
                    role={role}
                    getFormValues={() => values}
                    setFieldValue={set}
                    defaultValue={user && user.roles.some(({ id }) => id === role.id)}
                    className="user-form__role user-form-radio"
                  >
                    <h3 className="user-form__role-name">
                      {role.name}
                    </h3>
                    <p className="user-form__role-description">
                      {role.description}
                    </p>
                  </RoleFormField>
                ))}
              </React.Fragment>
            ))}
          </Subsection>

          <FormButtons>
            <Button
              secondary
              link={cancelLink}>
              Cancel
            </Button>

            <FormSubmit>
              {submitText}
            </FormSubmit>
          </FormButtons>

          {confirmAssignRoles &&
            <ConfirmActionModal
              title={`Are you sure you want to ${user ? 'promote the user to' : 'create'} ${confirmAssignRoles.join(' & ')} Role?`}
              description={<>
                Are you sure you want to create {confirmAssignRoles.join(' & ')} User Role? <b>This role has full access.</b> User will be able to
                make changes to crucial functions that may affect the work of the app.
              </>}
              onSubmit={onProceedWithUpdatingUser}
              submitText="Continue"
              show={showConfirmAssignRolesModal}
              onHide={() => setShowConfirmAssignRolesModal(false)}
            />
          }
        </>
      )}
    </Form>
  );
};

UserForm = React.forwardRef(UserForm);

UserForm.propTypes = {
  userRoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  autoFocus: PropTypes.bool,
  adminPanel: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.object,
  submitText: PropTypes.string,
  cancelLink: PropTypes.string.isRequired,
  mode: PropTypes.string,
  superUser: PropTypes.bool
};

export default UserForm;
