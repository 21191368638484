import type { FC } from 'react';
import React, { useMemo, useRef, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useSelector } from 'react-redux';

import userRoleIds from 'common-lib/constants/userRoleIds.json';

import { useTranslate } from '@acadeum/translate';
import { getAuthSelector } from '@acadeum/auth';
import { useQueryWithPagination } from '@acadeum/hooks';
import type {
  FetchUserRolesOutput,
  UseDuplicateUserRoleMutation,
  UseFetchUserRolesQuery,
  UseFetchUsersQuery,
  UseSetUsersRolesForUsersMutation
} from '@acadeum/api';
import { LockIcon, PlusIcon, WrenchIcon } from '@acadeum/icons';
import type { ActionsProps } from '@acadeum/ui';
import { Actions, Button, Page, Table, toast, InfoTooltip, HStack } from '@acadeum/ui';
import { userHasPermission } from '@acadeum/helpers';

import { useOnError } from '../../../providers/useOnError';

import { AssignUsersToRoleModalForm } from '../ui/AssignUsersToRoleModalForm';

import styles from './UserRolesPage.module.scss';
import { useNavigate } from '../../../providers/useNavigate';
import { useSettingsRoutes } from '../../../hooks/useSettingsRoutes';
import { useApp } from '../../../providers/useApp';

type UserRoleItem = FetchUserRolesOutput['results'][number];

const columnHelper = createColumnHelper<UserRoleItem>();

export interface UserRolesPageProps {
  useFetchUserRolesQuery: UseFetchUserRolesQuery;
  useDuplicateUserRoleMutation: UseDuplicateUserRoleMutation;
  useSetUsersRolesForUsersMutation: UseSetUsersRolesForUsersMutation;
  useFetchUsersQuery: UseFetchUsersQuery;
}

export const UserRolesPage: FC<UserRolesPageProps> = ({
  useFetchUserRolesQuery,
  useDuplicateUserRoleMutation,
  useSetUsersRolesForUsersMutation,
  useFetchUsersQuery
}) => {
  const t = useTranslate('shared-admin-ui.UserRolesPage');
  const onError = useOnError();
  const router = useNavigate();
  const { app } = useApp();
  const { getUserRoleDetailsUrl, getSettingsUrl, getCreateUserRoleUrl } = useSettingsRoutes();

  const user = useSelector(getAuthSelector('user'));

  const [showAssignUserToRoleModal, setShowAssignUserToRoleModal] = useState(false);
  const userRole = useRef<UserRoleItem>();

  const [duplicateUserRoleMutation] = useDuplicateUserRoleMutation();

  const {
    data,
    error,
    isLoading,
    isFetching,
    _paginationOptions,
    _globalFilterOptions,
    refetch: refresh
  } = useQueryWithPagination(useFetchUserRolesQuery);

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: t('columns.title'),
        cell: ({ row, getValue }) => (
          <HStack gap="xs">
            {getValue()}
            <InfoTooltip
              ariaTitle={`${row.original.name} description`}
              children={row.original.description}
              placement="right"
            />
          </HStack>
        )
      }),
      columnHelper.accessor('type', {
        header: t('columns.type'),
        cell: ({ row }) => {
          const isDefault = row.original.type === 'DEFAULT';
          const Icon = isDefault ? LockIcon : WrenchIcon;
          return (
            <>
              <Icon className={styles.typeIcon}/>
              {' '}
              {isDefault ? 'Default' : 'Custom'}
            </>
          );
        }
      }),
      columnHelper.display({
        id: 'websites',
        header: t('columns.access'),
        cell: () => 'Course Share, Account Center, Admin Center'

      }),
      columnHelper.display({
        id: 'usersCount',
        header: t('columns.users'),
        cell: ({ row }) => `${row.original.users.length} assigned`
      }),
      columnHelper.display({
        id: 'actions',
        cell: ({ row }) => {
          const actions: ActionsProps['actions'] = [
            ...(user && userHasPermission(user, 'user:update', { orgId: user?.institution.id, ownerId: null }) ? [
              {
                title: t('columns.actions.assignUsers'),
                onClick: () => {
                  userRole.current = row.original;
                  setShowAssignUserToRoleModal(true);
                }
              }
            ] : []),
            ...(user && userHasPermission(user, 'userRole:update', { orgId: user?.institution.id, ownerId: null }) ? [
              {
                title: t('columns.actions.duplicateAndEditRole'),
                disabled: row.original.id === userRoleIds['Platform Administrator'] || row.original.id === userRoleIds['Acadeum Administrator'],
                onClick: () => toast.promise(async () => {
                  const { id } = await duplicateUserRoleMutation({ id: row.original.id }).unwrap();
                  void router.push(getUserRoleDetailsUrl(id));
                })
              }
            ] : []),
            ...(user && userHasPermission(user, 'userRole:read', { orgId: user?.institution.id, ownerId: null }) ? [
              {
                title: t('columns.actions.seeDetails'),
                url: getUserRoleDetailsUrl(row.original.id)
              }
            ] : []),
            ...(user && userHasPermission(user, 'userRole:delete', { orgId: user?.institution.id, ownerId: null }) ? [
              {
                title: t('columns.actions.delete'),
                danger: true,
                disabled: row.original.type === 'DEFAULT',
                url: `${getUserRoleDetailsUrl(row.original.id)}?remove=true`
              }
            ] : [])
          ];

          if (actions.length === 0) {
            return null;
          }

          return (
            <Actions
              variant="kebab"
              actions={actions}
            />
          );
        }
      })
    ];
  }, [user, setShowAssignUserToRoleModal, t]);

  const actions = useMemo(() => {
    if (userHasPermission(user, 'userRole:create', {
      orgId: user?.institution.id,
      ownerId: null
    })) {
      return (
        <Button icon={PlusIcon} url={getCreateUserRoleUrl()}>
          {t('createRole')}
        </Button>
      );
    }
  }, [user]);

  if (error) {
    return onError(error);
  }

  return (
    <Page
      title={t('title')}
      breadcrumbs={[[t(app === 'admin' ? 'general' : 'settings', { global: true }), getSettingsUrl()], t('title')]}
      actions={actions}
    >
      <Table
        enableSorting
        columns={columns}
        data={data?.results}
        loading={isLoading}
        isFetching={isFetching}
        globalFilterOptions={_globalFilterOptions}
        paginationOptions={_paginationOptions}
        translate={{
          searchPlaceholder: t('searchByRoleTitle'),
          resultText: ({ totalCount }) => t('resultText', { totalCount }),
          selectedResultText: ({ totalCount, selectedRowsCount }) => t('selectedResultText', {
            totalCount,
            selectedRowsCount
          })
        }}
      />
      {showAssignUserToRoleModal && userRole.current && (
        <AssignUsersToRoleModalForm
          useSetUsersRolesForUsersMutation={useSetUsersRolesForUsersMutation}
          useFetchUsersQuery={useFetchUsersQuery}
          role={userRole.current}
          show={showAssignUserToRoleModal}
          onHide={setShowAssignUserToRoleModal}
          onAfterSubmit={async () => {
            await refresh();
            setShowAssignUserToRoleModal(false);
            toast.success(t('assignUsersToRoleModalForm.successMessage'));
          }}
        />
      )}
    </Page>
  );
};
