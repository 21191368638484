// import numeral from 'numeral';

// const USD_FORMATTERS = typeof Intl !== 'undefined' && {
//   'en': new Intl.NumberFormat('en', {
//     currency: 'USD',
//     maximumFractionDigits: 0
//   })
// };

// const USD_WITH_CENTS_FORMATTERS = typeof Intl !== 'undefined' && {
//   'en': new Intl.DateTimeFormat('en', {
//     currency: 'USD'
//   })
// };

const DEFAULT_LOCALE = 'en';

const FRACTIONAL_DIGITS_COUNT = {
  USD: 2
};

export default function formatCurrency(value, options = {}) {
  if (isNaN(value)) {
    return;
  }

  if (typeof options === 'string') {
    options = {
      currency: options
    };
  }

  if (!options.currency) {
    options = {
      ...options,
      currency: 'USD'
    };
  }

  // if (options.fractional === undefined) {
  //   options = {
  //     ...options,
  //     fractional: 'auto'
  //   };
  // }

  if (!FRACTIONAL_DIGITS_COUNT[options.currency]) {
    throw new Error(`Unknown currency in \`formatCurrency()\`: "${options.currency}"`);
  }

  // Using `.toLocaleString()` seems to be same as using `Intl.NumberFormat`.
  if (value.toLocaleString) {
    if (options.cents) {
      value /= 100;
    }
    const fractionalDigitsCount = shouldFormatFractionalDigits(value, options)
      ? FRACTIONAL_DIGITS_COUNT[options.currency]
      : 0;
    const formattingOptions = {
      style: 'currency',
      currency: options.currency,
      minimumFractionDigits: fractionalDigitsCount,
      maximumFractionDigits: fractionalDigitsCount
    };
    return value.toLocaleString(options.locale || DEFAULT_LOCALE, formattingOptions);
  }

  // Fallback currency formatting for old web browsers.
  switch (options.currency) {
    case 'USD':
      if (options.cents) {
        const dollars = value / 100;
        const cents = value % 100;
        return '$' + dollars + '.' + cents;
        // return '$' + numeral(dollars).format('0,0') + '.' + cents;
      }
      return '$' + value;
      // return '$' + numeral(value).format('0,0');
    default:
      // A dummy fallback
      if (options.cents) {
        const dollars = value / 100;
        const cents = value % 100;
        return options.currency + ' ' + dollars + '.' + cents;
      }
      return options.currency + ' ' + value;
  }
}

function shouldFormatFractionalDigits(value, options) {
  switch (options.fractional) {
    case true:
      return true;
    case false:
      return false;
    // "auto".
    default:
      if (options.cents) {
        return true;
      }
      if (value > Math.floor(value)) {
        return true;
      }
      return false;
  }
}
