import React, { useState, useEffect, useMemo, useRef } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Joi from 'joi';

import { CloseButton } from '@acadeum/ui';
import { PlusIcon, XMarkIcon } from '@acadeum/icons';

import { useTranslate } from '@acadeum/translate';

import { InfoTooltip } from '../InfoTooltip';
import { Button } from '../Button';
import { Text } from '../Text';

import { getUniqID } from '../../utils/getUniqId';

import { KeyCode } from '../../types';

import { GradingScaleCollection } from './GradingScaleCollection';

import styles from './GradingScale.module.scss';

export interface GradingScaleProps {
  value: {
    grade: string;
    description: string;
    numeric: string;
    points: number;
  }[];
  onChange: (values) => void;
  onRemove: () => void;
}

export const GradingScale: FC<GradingScaleProps> = React.memo(React.forwardRef<HTMLTableElement, GradingScaleProps>(({
  value,
  onChange,
  onRemove
}, ref) => {
  const gradingScale = new GradingScaleCollection(value);
  const [data, setData] = useState<GradingScaleProps['value']>(gradingScale.grades);

  const t = useTranslate('ui.GradingScale');

  const COLUMNS = [
    { name: 'grade', placeholder: 'A', caption: 'Letter Grade', width: '15%' },
    { name: 'description', placeholder: 'Excellent', caption: 'Descriptive Grade', width: '35%' },
    { name: 'numeric', placeholder: '90-100', caption: 'Numeric Grade', width: '35%' },
    { name: 'points', placeholder: '4', caption: 'Grade Points', width: '15%' },
    { name: 'actions', placeholder: '', caption: '', width: '15%' }
  ];

  const handleUpdate = () => {
    setData(gradingScale.grades);
  };

  useEffect(() => {
    onChange(data);
  }, [data]);

  const addNewRow = () => {
    gradingScale.onAddRow();
    handleUpdate();
  };

  const onRemoveGradingScale = () => {
    gradingScale.onRemove();
    onRemove();
  };

  return (
    <div className={styles.Container} tabIndex={0}>
      <div className={styles.Table__container}>
        <Text className={styles.Table__title} mb="md">
          {t('title')}
        </Text>
        <CloseButton
          className={styles.Table__closeIcon}
          onClick={onRemoveGradingScale}
        />
        <table
          ref={ref}
          className={styles.Table}
          tabIndex={0}
        >
          <thead>
            <tr>
              {COLUMNS.map(column => (
                <th
                  key={getUniqID('gc')}
                  style={{ width: column.width }}
                  className={styles.Table__header}
                >
                  {column.caption}
                </th>)
              )}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr className={styles.Table__row} tabIndex={-1} key={getUniqID('gr')}>
                {COLUMNS.map(column => (
                  <TableCell
                    key={getUniqID(column.name)}
                    index={index}
                    column={column.name}
                    placeholder={column.placeholder}
                    value={row[column.name]}
                    onSave={gradingScale.onSave}
                    onUpdate={handleUpdate}
                    onRemoveRow={gradingScale.onRemoveRow}
                  />
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <Button variant="text-with-padding" size="small" onClick={addNewRow}>
          <PlusIcon className="control-icon control-icon--left-small"/>
          {t('new')}
        </Button>
      </div>
    </div>
  );
}));

interface TableCellProps {
  column: string;
  index: number;
  placeholder: string;
  value?: number | string;
  onSave: (index, column, value) => void;
  onUpdate: () => void;
  onRemoveRow: (index: number) => void;
}

const TableCell: FC<TableCellProps> = React.memo(({
  column,
  index,
  placeholder: placeholder_,
  value: value_,
  onSave,
  onUpdate,
  onRemoveRow
}) => {
  const [value, setValue] = useState(value_);
  const [cellError, setCellError] = useState();
  const [editCell, setEditCell] = useState(false);
  const placeholder = useMemo(() => placeholder_, [placeholder_]);

  const cellRef = useRef() as React.MutableRefObject<HTMLTableCellElement>;

  const t = useTranslate('ui.GradingScale');

  useEffect(() => {
    const handleNavigation = (e) => {
      if (cellRef && cellRef.current && !cellRef.current.contains(e.target)) {
        return;
      }

      if (e.keyCode === KeyCode.Enter) {
        e.preventDefault();
        if (editCell) {
          handleBlur(e.target.value);
        } else {
          setEditCell(true);
        }
      }

      if (e.keyCode === KeyCode.Escape) {
        handleBlur(value);
      }

      if (e.keyCode === KeyCode.ArrowLeft) {
        const prevCell = e.target.previousElementSibling;

        if (prevCell) {
          prevCell.focus();
        }
      }

      if (e.keyCode === KeyCode.ArrowRight) {
        const nextCell = e.target.nextElementSibling;

        if (nextCell) {
          nextCell.focus();
        }
      }

      if (e.keyCode === KeyCode.ArrowDown) {
        e.preventDefault();
        const currentRow = e.target.parentElement;
        const currentCellIndex = [...currentRow.getElementsByTagName('td')].indexOf(e.target);

        if (e.target.parentElement.nextElementSibling) {
          const nextRow = e.target.parentElement.nextElementSibling;
          const nextRowCells = [...nextRow.getElementsByTagName('td')];

          if (nextRowCells[currentCellIndex]) {
            nextRowCells[currentCellIndex].focus();
          }
        }
      }

      if (e.keyCode === KeyCode.ArrowUp) {
        e.preventDefault();
        const currentRow = e.target.parentElement;
        const currentCellIndex = [...currentRow.getElementsByTagName('td')].indexOf(e.target);
        const prevRow = e.target.parentElement.previousElementSibling;

        if (prevRow) {
          const prevRowCells = [...prevRow.getElementsByTagName('td')];
          if (prevRowCells[currentCellIndex]) {
            prevRowCells[currentCellIndex].focus();
          }
        }
      }

      if (e.keyCode === KeyCode.Delete && !editCell) {
        handleBlur('');
      }

      if ((e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 65 && e.keyCode <= 90) ||
        (e.keyCode >= 97 && e.keyCode <= 122) ||
        e.keyCode === 32) {
        setEditCell(true);
      }
    };
    document.addEventListener('keydown', handleNavigation);

    return () => {
      document.removeEventListener('keydown', handleNavigation);
    };
  });

  const handleClick = () => {
    cellRef.current.focus();
  };

  const grade = Joi.string()
    // eslint-disable-next-line
    .pattern(/^[a-zA-Z]([a-zA-Z0-9\s\/])*[+-]?$/)
    .required()
    .label('Letter Grade')
    .messages({
      'string.empty': 'The Letter Grade cannot be empty',
      'string.pattern.base': 'The Letter Grade should start with a letter and allow alphanumeric chars, spaces and (+) or (-) sign'
    });
  const description = Joi.string().allow('').label('Description');
  const numeric = Joi.string()
    .pattern(/^([0-9]*[.,]?[0-9]{1,2})(-([0-9]*[.,]?[0-9]{1,2}))?$/)
    .allow('', null)
    .label('Numeric')
    .messages({
      'string.pattern.base': 'The Numeric should contain a number or range of numbers (allow a maximum of 2 digits after the comma)'
    });
  const points = Joi.number().positive().label('Points');

  const schema = {
    grade,
    description,
    numeric,
    points
  };

  useEffect(() => {
    if (value !== '*' && editCell === false && column !== 'actions') {
      const { error } = schema[column].validate(value);
      setCellError(error?.message);
    }
  }, [value, editCell]);

  const handleBlur = (value) => {
    setEditCell(false);

    if (column === 'points') {
      if (isNaN(value)) { // Don't change to Number.isNaN() it doesn't work properly
        value = 0;
      } else {
        value = Number(value);
      }
    }

    if (value) {
      setValue(value);
    } else {
      setValue('');
    }

    onSave(index, column, value);
    onUpdate();
  };

  const onRemove = (index) => {
    onRemoveRow(index);
    onUpdate();
  };

  const ErrorCell = ({ value, message }) => {
    return (
      <div className={styles.Table__cellError}>
        <span style={{ marginRight: '.25rem' }}>
          {value || t('bad')}
        </span>
        <InfoTooltip
          variant="error"
          ariaTitle={message}
        >
          <span>{message}</span>
        </InfoTooltip>
      </div>
    );
  };

  return (
    <td
      ref={cellRef}
      tabIndex={column === 'actions' ? -1 : 0}
      className={classNames(styles.Table__cell, {
        [styles.isActive]: editCell,
        [styles.isValue]: value,
        [styles.hasError]: cellError && column !== 'actions',
        [styles.isPlaceholder]: value === '*'
      })}
      data-column={column}
      data-index={index}
      onClick={handleClick}
    >
      {editCell
        ? (<CellInput value={value} onBlur={handleBlur}/>)
        : cellError ? <ErrorCell value={value} message={cellError}/> : (value
          ? (value === '*' ? placeholder : value)
          : placeholder)}
      {column === 'actions' && (
        <div className={styles.Table__removeIcon} onClick={() => onRemove(index)}>
          <XMarkIcon/>
        </div>
      )}
    </td>
  );
});

function CellInput({ value: value_, onBlur }) {
  const [value, setValue] = useState(value_);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleFocus = (e) => {
    e.target.setSelectionRange(0, e.target.value.length);
  };

  const handleBlur = () => {
    onBlur(value);
  };

  return (
    <input
      autoFocus
      className={styles.Table__cellInput}
      type="text"
      value={value === '*' || value === null ? '' : value}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  );
}

CellInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onBlur: PropTypes.func.isRequired
};
