import { useContext } from 'react';

import { UseUploadImageMutationContext } from './context';

export function useUseUploadImageMutationContext() {
  const context = useContext(UseUploadImageMutationContext);
  if (!context) {
    throw new Error('`useUseUploadImageMutationContext`. useUploadImageMutation is not defined. Please, provide it prop in AppProvider.');
  }
  return context;
}
