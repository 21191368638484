import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Text } from '@acadeum/ui';

import ShowMore from '../ShowMore';

import styles from './WelcomeLetter.module.scss';

export default function WelcomeLetter({
  welcomeLetter,
  loading,
  mode
}) {
  const [expanded, setExpanded] = useState();

  if (loading) {
    return <>Loading...</>;
  }

  if (!welcomeLetter) {
    if (mode === 'institution') {
      return <>This institution doesn't have any welcome letter.</>;
    }
    return <>This course doesn't have any welcome letter.</>;
  }

  const welcomeLetter_ = welcomeLetter.split('\n\n');

  return (
    <>
      <Text className={styles.WelcomeLetterText} markdown allowLinks>
        {expanded ? welcomeLetter : welcomeLetter_[0]}
      </Text>

      {welcomeLetter_.length > 1 && (
        <ShowMore
          className={styles.ShowMore}
          expanded={expanded}
          onClick={() => setExpanded(!expanded)}
        />
      )}
    </>
  );
}

WelcomeLetter.propTypes = {
  welcomeLetter: PropTypes.string,
  loading: PropTypes.bool,
  mode: PropTypes.oneOf(['institution'])
};
