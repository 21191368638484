import { useSelector } from 'react-redux';
import { createContext, useCallback, useContext, useMemo } from 'react';
import set from 'lodash/set';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import unset from 'lodash/unset';

import { getAuthSelector } from '@acadeum/auth';

import { useFetchUserClientSettingsQuery, useUpdateUserClientSettingsMutation } from '../api/userProfile';

export type CourseSearchView = 'sections' | 'courses';
export type CourseSearchScope = 'all' | 'own';

interface ClientSettingsContextProps {
  courseSearchFilters: object;
  saveCourseSearchFilters: (view: CourseSearchView, scope: CourseSearchScope, filters: object) => Promise<void>;
  addNewCourseSearchFilter: (view: CourseSearchView,
    scope: CourseSearchScope,
    name: string,
    value: object) => Promise<void>;
  removeCourseSearchFilter: (view: CourseSearchView, scope: CourseSearchScope, name: string) => Promise<void>;
  renameCourseSearchFilter: (view: CourseSearchView,
    scope: CourseSearchScope,
    oldName: string,
    newName: string) => Promise<void>;
  updateCourseSearchFilter: (view: CourseSearchView,
    scope: CourseSearchScope,
    name: string,
    value: object) => Promise<void>;
}

const ClientSettingsContext = createContext<ClientSettingsContextProps | undefined>(undefined);

export const ClientSettingsContextProvider = ({ children }) => {
  const user = useSelector(getAuthSelector('user'));

  const {
    data,
    refetch: refetchUserClientSettings
  } = useFetchUserClientSettingsQuery(undefined, {
    skip: !user
  });

  const [updateUserClientSettingsMutation] = useUpdateUserClientSettingsMutation();

  const courseSearchFilters = data?.clientSettings?.courseshare?.courseSearchFilters || {};

  const saveCourseSearchFilters = useCallback(async (
    view: CourseSearchView,
    scope: CourseSearchScope,
    filters: object
  ) => {
    const { clientSettings } = await refetchUserClientSettings().unwrap();
    const newClientSettings = clientSettings ? cloneDeep(clientSettings) : {};
    set(newClientSettings, `courseshare.courseSearchFilters.${view}.${scope}.savedSearches`, filters);
    await updateUserClientSettingsMutation({ clientSettings: newClientSettings });
  }, [refetchUserClientSettings, updateUserClientSettingsMutation]);

  const addNewCourseSearchFilter = useCallback( async (view: CourseSearchView, scope: CourseSearchScope, name, value) => {
    const { clientSettings } = await refetchUserClientSettings().unwrap();
    const newClientSettings = clientSettings ? cloneDeep(clientSettings) : {};
    set(newClientSettings, `courseshare.courseSearchFilters.${view}.${scope}.savedSearches.${name}`, value);
    await updateUserClientSettingsMutation({ clientSettings: newClientSettings });
  }, [refetchUserClientSettings, updateUserClientSettingsMutation]);

  const removeCourseSearchFilter = useCallback( async (view: CourseSearchView, scope: CourseSearchScope, name) => {
    const { clientSettings } = await refetchUserClientSettings().unwrap();
    const newClientSettings = clientSettings ? cloneDeep(clientSettings) : {};
    unset(newClientSettings, `courseshare.courseSearchFilters.${view}.${scope}.savedSearches.${name}`);
    await updateUserClientSettingsMutation({ clientSettings: newClientSettings });
  }, [refetchUserClientSettings, updateUserClientSettingsMutation]);

  const renameCourseSearchFilter = useCallback( async (view: CourseSearchView, scope: CourseSearchScope, oldName, newName) => {
    const { clientSettings } = await refetchUserClientSettings().unwrap();
    const newClientSettings = clientSettings ? cloneDeep(clientSettings) : {};
    const oldValue = get(newClientSettings, `courseshare.courseSearchFilters.${view}.${scope}.savedSearches.${oldName}`);
    unset(newClientSettings, `courseshare.courseSearchFilters.${view}.${scope}.savedSearches.${oldName}`);
    set(newClientSettings, `courseshare.courseSearchFilters.${view}.${scope}.savedSearches.${newName}`, oldValue);
    await updateUserClientSettingsMutation({ clientSettings: newClientSettings });
  }, [refetchUserClientSettings, updateUserClientSettingsMutation]);

  const updateCourseSearchFilter = useCallback( async (view: CourseSearchView, scope: CourseSearchScope, name, value) => {
    const { clientSettings } = await refetchUserClientSettings().unwrap();
    const newClientSettings = clientSettings ? cloneDeep(clientSettings) : {};
    set(newClientSettings, `courseshare.courseSearchFilters.${view}.${scope}.savedSearches.${name}`, value);
    void updateUserClientSettingsMutation({ clientSettings: newClientSettings });
  }, [refetchUserClientSettings, updateUserClientSettingsMutation]);

  const contextValue = useMemo<ClientSettingsContextProps>(() => ({
    courseSearchFilters,
    saveCourseSearchFilters,
    addNewCourseSearchFilter,
    removeCourseSearchFilter,
    renameCourseSearchFilter,
    updateCourseSearchFilter
  }), [
    courseSearchFilters,
    saveCourseSearchFilters,
    addNewCourseSearchFilter,
    removeCourseSearchFilter,
    renameCourseSearchFilter,
    updateCourseSearchFilter
  ]);

  return (
    <ClientSettingsContext.Provider value={contextValue}>
      {children}
    </ClientSettingsContext.Provider>
  );
};

export const useClientSettingsCourseSearchFilters = () => {
  const context = useContext(ClientSettingsContext);
  if (!context) {
    throw new Error('useClientSettingsCourseSearchFilters must be used within a ClientSettingsContextProvider');
  }
  return context;
};
