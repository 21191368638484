import userRolePermissionOwnerScopeSubjects from 'common-lib/constants/userRolePermissionOwnerScopeSubjects.json';
import userRolePermissionPublicSubjects from 'common-lib/constants/userRolePermissionPublicSubjects.json';

import { SCOPES } from './consts';
import { getScopeIndex } from './getScopeIndex';
import { getScopeLabel } from './getScopeLabel';
import type { UserRolePermission, UserRolePermissionOperation, UserRolePermissionSubject } from '@acadeum/types';

export function getPermissionOptions(
  subject: UserRolePermissionSubject,
  operation: UserRolePermissionOperation,
  userPermissions: UserRolePermission[]
) {
  const currentUserPermission = userPermissions.filter(userPermission => {
    return userPermission.subject === subject && userPermission.operations.includes(operation);
  })[0];

  const scope = currentUserPermission?.scope;
  const availableScopes = scope ? SCOPES.filter(_ => {
    // Check if the scope is greater than or equal to the current scope
    return getScopeIndex(_) >= getScopeIndex(scope) &&
      // Check if the scope is not owner for subjects that are not owner scope subjects
      (userRolePermissionOwnerScopeSubjects.includes(subject) ? true : _ !== 'owner') &&
      // Check if the scope is have `all` for subjects in public subjects and operation is read
      (userRolePermissionPublicSubjects.includes(subject) && operation === 'read' && _ === '*' ? true : _ !== '*');
  }) : [];

  return [null, ...availableScopes].map(value => ({
    value,
    label: getScopeLabel(value)
  }));
}
