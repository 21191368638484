import { useSelector } from 'react-redux';

import { ToggleButton } from '@acadeum/ui';
import { useTranslate, useTranslateOptions } from '@acadeum/translate';

import { getStudentEnrollments } from '../../../../helpers/enrollments';
import type { ReduxState } from '../../../../helpers/app.types';
import actions from '../../../../actions';

import { useExportDataColumns } from '../../useExportDataColumns';

import EnrollmentsTable from '../EnrollmentsTable';

import canDropFromCourseEnrollment from 'common-lib/lib/canDropFromCourseEnrollment.js';
import canWithdrawFromCourseEnrollment from 'common-lib/lib/canWithdrawFromCourseEnrollment.js';

import styles from '../../Enrollments.module.scss';

const { fetchEnrollmentRequestsTMForExport, goto } = actions;

type EnrollmentType = 'accepted' | 'finalized';

const STATUSES: { [Key in EnrollmentType]: string[] } = {
  accepted: ['PENDING', 'ACTIVE', 'DUE'],
  finalized: ['COMPLETE', 'DENIED', 'WITHDRAWN', 'DROPPED', 'REMOVED']
};

export interface TeachingEnrollmentsProps {
  status: EnrollmentType;
}

export const TeachingEnrollments = ({ status }: TeachingEnrollmentsProps) => {
  const t = useTranslate('Enrollments');

  const enrollmentRequestsTM = useSelector((state: ReduxState) => state.enrollmentRequestsTM.enrollmentRequests);

  const toggleOptions = useTranslateOptions<EnrollmentType>('Enrollments', ['accepted', 'finalized']);

  const exportDataColumnsAccepted = useExportDataColumns({ teaching: true });
  const exportDataColumnsFinalized = useExportDataColumns({ teaching: true, finalized: true });
  const exportDataColumns = status === 'accepted' ? exportDataColumnsAccepted : exportDataColumnsFinalized;
  const exportDataFileName = status === 'accepted' ? t('fileName.TIAccepted') : t('fileName.TIFinalized');

  const sections = getSections(t, status);

  return (
    <>
      <ToggleButton
        value={status}
        onChange={(type) => goto(`/enrollments/teaching/${type}`)}
        label={t('title')}
        options={toggleOptions}
        className={styles.Enrollments__toggle}
      />
      <EnrollmentsTable
        institutionType="teaching"
        enrollmentType={status}
        data={getStudentEnrollments(enrollmentRequestsTM).filter(_ => STATUSES[status].includes(_.status))}
        sections={sections}
        fetchDataForExport={fetchDataForExport(status)}
        exportDataColumns={exportDataColumns}
        exportDataFileName={exportDataFileName}
      />
    </>
  );
};

const fetchDataForExport = (status) => async (ids, { rowsIds }) => {
  return getStudentEnrollments(
    await fetchEnrollmentRequestsTMForExport(ids.length > 0 ? ids : rowsIds)
  ).filter(_ => STATUSES[status].includes(_.status));
};

const getSections = (t, type: EnrollmentType) => {
  if (type === 'accepted') {
    return [
      {
        title: t('status.teaching.accepted.pending.title'),
        tooltip: t('status.teaching.accepted.pending.tooltip', {
          strong: (children) => (<strong>{children}</strong>)
        }),
        filter: _ => _.status === 'PENDING',
        getStatusTransitions: () => statusTransitions.PENDING
      }, {
        title: t('status.teaching.accepted.active.title'),
        tooltip: t('status.teaching.accepted.active.tooltip', {
          strong: (children) => (<strong>{children}</strong>)
        }),
        filter: _ => _.status === 'ACTIVE',
        getStatusTransitions(studentEnrollment) {
          if (canDropFromCourseEnrollment({
            status: studentEnrollment.status,
            onDemand: studentEnrollment.section.session.course.onDemand,
            onDemandDropDateDaysAfterStartDate: studentEnrollment.section.session.course.institution.onDemandCourseDropDateDaysAfterStartDate,
            startedAt: studentEnrollment.startedAt,
            lastDropDate: studentEnrollment.section.session.lastDropDate
          })) {
            return statusTransitions.ACTIVE_CAN_DROP;
          } else if (canWithdrawFromCourseEnrollment({
            status: studentEnrollment.status,
            onDemand: studentEnrollment.section.session.course.onDemand,
            onDemandDropDateDaysAfterStartDate: studentEnrollment.section.session.course.institution.onDemandCourseDropDateDaysAfterStartDate,
            startedAt: studentEnrollment.startedAt,
            lastDropDate: studentEnrollment.section.session.lastDropDate,
            lastWithdrawalDate: studentEnrollment.section.session.lastWithdrawalDate
          })) {
            return statusTransitions.ACTIVE_CAN_WITHDRAW;
          } else {
            return [];
          }
        }
      }, {
        title: t('status.teaching.accepted.due.title'),
        tooltip: t('status.teaching.accepted.due.tooltip', {
          strong: (children) => (<strong>{children}</strong>)
        }),
        filter: _ => _.status === 'DUE',
        getStatusTransitions: () => statusTransitions.DUE
      }
    ];
  }
  if (type === 'finalized') {
    return [
      {
        title: t('status.teaching.finalized.complete.title'),
        tooltip: t('status.teaching.finalized.complete.tooltip'),
        filter: _ => _.status === 'COMPLETE'
      }, {
        title: t('status.teaching.finalized.denied.title'),
        tooltip: t('status.teaching.finalized.denied.tooltip'),
        filter: _ => _.status === 'DENIED'
      }, {
        title: t('status.teaching.finalized.withdrawn.title'),
        tooltip: t('status.teaching.finalized.withdrawn.tooltip'),
        filter: _ => _.status === 'WITHDRAWN'
      }, {
        title: t('status.teaching.finalized.dropped.title'),
        tooltip: t('status.teaching.finalized.dropped.tooltip'),
        filter: _ => _.status === 'DROPPED'
      }, {
        title: t('status.teaching.finalized.removed.title'),
        tooltip: t('status.teaching.finalized.removed.tooltip'),
        filter: _ => _.status === 'REMOVED'
      }
    ];
  }
  return [];
};

const statusTransitions = {
  ACTIVE_CAN_DROP: ['DROPPED'],
  ACTIVE_CAN_WITHDRAW: ['WITHDRAWN'],
  DUE: ['COMPLETE'],
  PENDING: ['DROPPED']
};
