import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useSettingsRoutes } from '@acadeum/shared-admin-ui';

import {
  Blank,
  createTableSessionFilter,
  createTableStatusFilter,
  createTableTermFilter,
  createTableYearFilter,
  DataBlock,
  FormatDate,
  Table
} from '@acadeum/ui';

import Section from '../../components/Section';
import Button from '../../components/Button';
import InfoTooltip from '../../components/InfoTooltip';

import { formatName } from '../../helpers/format';

import actions from '../../actions';

import './AdvisorRecommendations.sass';

const { fetchAdvisorRecommendations } = actions;

export default function AdvisorRecommendations() {
  const recommendations = useSelector(state => state.advisorRecommendations.recommendations);

  const columns = useColumns();

  return (
    <Section
      title="Advisor Recommendations"
      description="These are advisor recommendations sent to your students by your institution to take the following courses through Acadeum."
    >
      {recommendations && (
        <Table
          enableGlobalFilter
          data={recommendations}
          columns={columns}
          clientFilters={filters}
        />
      )}
    </Section>
  );
}

AdvisorRecommendations.propTypes = {
  recommendations: PropTypes.arrayOf(PropTypes.object)
};

AdvisorRecommendations.meta = () => ({
  title: 'Advisor Recommendations'
});

AdvisorRecommendations.load = async () => {
  await fetchAdvisorRecommendations();
};

AdvisorRecommendations.breadcrumbs = () => ['Advisor Recommendations'];

const useColumns = () => {
  const { getUserDetailsUrl } = useSettingsRoutes();
  return useMemo(() => [
    {
      id: 'student',
      enableSorting: true,
      accessorFn: (request) => formatName(request.student || request.studentUser),
      header: 'STUDENT',
      cell: ({ row }) => {
        const { student, studentUser } = row.original;
        if (student) {
          return (
            <DataBlock
              type="student"
              student={student}
            />
          );
        }
        if (studentUser) {
          return formatName(studentUser);
        }
        return <Blank/>;
      }
    },
    {
      accessorKey: 'createdAt',
      header: 'DATE RECOMMENDED',
      enableSorting: true,
      cell: ({ getValue }) => <FormatDate month="long" date={getValue()} utc={false}/>
    },
    {
      accessorKey: 'advisor.email',
      header: 'Submitted by',
      cell: ({ row }) => (
        <DataBlock
          type="user"
          showUserPicture
          user={row.original.advisor}
          email={row.original.advisor.email}
          url={getUserDetailsUrl(row.original.advisor.id)}
        />
      )
    },
    {
      accessorKey: 'course.institution',
      header: 'TEACHING INSTITUTION',
      enableSorting: true,
      cell: ({ getValue }) => <DataBlock type="institution" institution={getValue()}/>
    },
    {
      accessorKey: 'course',
      header: 'COURSE',
      cell: ({ getValue }) => <DataBlock type="course" course={getValue()}/>
    },
    {
      accessorKey: 'status',
      header: 'STATUS',
      cell: ({ row: { original } }) => (
        <div className="AdvisorRecommendations-statusWrapper">
          <div className="AdvisorRecommendations-status">
            {original.status.replaceAll('_', ' ')}
            {/* `white-space: 'nowrap'` on a parent and "&nbsp;" as a child
              fix wrapping of the tooltip icon. Otherwise, it would be on a new line. */}
            <span style={{ whiteSpace: 'nowrap' }}>
            &nbsp;
              <InfoTooltip>
                {STATUS_TOOLTIPS[original.status]}
              </InfoTooltip>
            </span>
          </div>
          <div className="AdvisorRecommendations-statusPaddingRight"/>
        </div>
      )
    },
    {
      id: 'actions',
      cell: ({ row: { original } }) => (
        <Button
          border
          secondary
          link={`/advisor-recommendations/${original.id}`}
          id={`see-details-${original.id}`}
          aria-labelledby={`see-details-${original.id} student-name-${original.id} home-institution-name-${original.id}`}
        >
          Details
        </Button>
      )
    }
  ], [
    getUserDetailsUrl
  ]);
};

const filters = [
  createTableYearFilter({
    getTerms: row => row.sections.map(section => section.session.term)
  }),
  createTableTermFilter({
    getTerms: row => row.sections.map(section => section.session.term)
  }),
  createTableSessionFilter({
    getSessions: row => row.sections.map(section => section.session)
  }),
  createTableStatusFilter({
    sort: (a, b) => {
      const orderA = STATUS_FILTER_OPTIONS_ORDER.indexOf(a);
      const orderB = STATUS_FILTER_OPTIONS_ORDER.indexOf(b);
      if (orderA > orderB) {
        return 1;
      } else if (orderA < orderB) {
        return -1;
      } else {
        return 0;
      }
    }
  })
];

const STATUS_FILTER_OPTIONS_ORDER = [
  'SENT',
  'REGISTRATION_REQUESTED',
  'REMOVED'
];

const STATUS_TOOLTIPS = {
  SENT: 'The student has been sent this recommendation but not yet registered.',
  REGISTRATION_REQUESTED: 'The student has submitted a registration request for this advisor recommended course.',
  REMOVED: 'You decided to cancel the advisor recommendation before the student registered for the course.'
};
