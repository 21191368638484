import type { MutableRefObject} from 'react';
import { useCallback, useState } from 'react';

import { useIsomorphicLayoutEffect } from '../useIsomorphicLayoutEffect';

interface RectResult {
  bottom?: number;
  height?: number;
  left?: number;
  right?: number;
  top?: number;
  width?: number;
  y?: number;
  x?: number;
}

const getRect = (element: HTMLElement | null): RectResult => {
  if (element) {
    return element.getBoundingClientRect();
  }
  return {};
};

export function useRect(ref: MutableRefObject<HTMLElement | undefined | null>): RectResult {
  const current = ref.current || null;

  const [rect, setRect] = useState(getRect(current));

  const handleResize = useCallback(() => {
    if (!ref.current) {
      return;
    }

    setRect(getRect(ref.current));
  }, [ref]);

  useIsomorphicLayoutEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    handleResize();
    if (typeof ResizeObserver === 'function') {
      let resizeObserver: ResizeObserver | null = new ResizeObserver(() => handleResize());
      resizeObserver.observe(element);

      return () => {
        if (!resizeObserver) {
          return;
        }

        resizeObserver.disconnect();
        resizeObserver = null;
      };
    } else {
      // Browser support, remove freely
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [handleResize]);

  return rect;
}
