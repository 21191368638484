import type { Id, SearchInstitutionType } from '@acadeum/types';

import {
  ACADEUM_INSTITUTION_ID,
  ACADEUM_INSTITUTION_OPTION,
  ACADEUM_MARKETPLACE_INSTITUTION_ID,
  ACADEUM_MARKETPLACE_INSTITUTION_OPTION,
  HOME_SCHOOLING_FOR_COLLEGE_CREDIT_INSTITUTION_ID,
  ACADEUM_HS4CC_INSTITUTION_OPTION
} from '@acadeum/consts';

export function getInstitutionInputParams({
  type,
  superAdmin,
  limit = 10,
  variant,
  getMemberInstitutionOptionsByName,
  getMemberInstitutionOptionByValue
}: {
  type: SearchInstitutionType;
  superAdmin?: boolean;
  limit?: number;
  variant?: 'valueAndLabel' | 'valueAndLabelAndLogoUrl';
  getMemberInstitutionOptionsByName: (name: string, parameters: { type: SearchInstitutionType, limit?: number, variant?: 'valueAndLabel' | 'valueAndLabelAndLogoUrl' }) => Promise<{ value: Id, label: string }[]>;
  getMemberInstitutionOptionByValue: (id: number, parameters?: { variant?: 'valueAndLabel' | 'valueAndLabelAndLogoUrl' }) => Promise<{ value: Id, label: string } | undefined>;
}) {
  return {
    async: true,
    fetchOptions: async (name) => {
      const results = await getMemberInstitutionOptionsByName(name, { type, limit, variant });
      if (superAdmin) {
        if (name.length >= 2 && 'acadeum'.startsWith(name.toLowerCase())) {
          results.unshift(ACADEUM_INSTITUTION_OPTION);
          results.unshift(ACADEUM_MARKETPLACE_INSTITUTION_OPTION);
          results.unshift(ACADEUM_HS4CC_INSTITUTION_OPTION);
        }
      }
      return results;
    },
    findOptionByValue: async (id) => {
      if (superAdmin) {
        if (id === ACADEUM_INSTITUTION_ID) {
          return ACADEUM_INSTITUTION_OPTION;
        }
        if (id === ACADEUM_MARKETPLACE_INSTITUTION_ID) {
          return ACADEUM_MARKETPLACE_INSTITUTION_OPTION;
        }
        if (id === HOME_SCHOOLING_FOR_COLLEGE_CREDIT_INSTITUTION_ID) {
          return ACADEUM_HS4CC_INSTITUTION_OPTION;
        }
      }
      return await getMemberInstitutionOptionByValue(id, { variant });
    }
  };
}
