import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { ColumnDef } from '@tanstack/react-table';

import formatName from 'common-lib/lib/formatName';

import { userHasPermission } from '@acadeum/helpers';
import { getUserSelector } from '@acadeum/auth';
import { useTranslate } from '@acadeum/translate';
import {
  createTableDefaultFilter,
  createTableInstitutionFilter,
  createTableSessionFilter,
  createTableTermFilter,
  createTableYearFilter,
  DataBlock
} from '@acadeum/ui';

export const useUserCanReadHomeCourseEnrollmentGrade = () => {
  const user = useSelector(getUserSelector);
  return user && userHasPermission(user, 'homeCourseEnrollmentGrade:read', {
    orgId: user.institution.id
  });
};

export const useUserCanReadTeachingCourseEnrollmentGrade = () => {
  const user = useSelector(getUserSelector);
  return user && user.institution.teaching && userHasPermission(user, 'teachingCourseEnrollmentGrade:read', {
    orgId: user.institution.id
  });
};

export const useCanEditTeachingGrade = () => {
  const user = useSelector(getUserSelector);
  return user && userHasPermission(user, 'teachingCourseEnrollmentGrade:update', {
    orgId: user.institution.id,
    ownerId: null
  });
};

export const useGradesColumns = ({
  institutionType,
  actionsColumn
}: {
  institutionType: 'enrolling' | 'teaching',
  actionsColumn: object
}) => {
  const t = useTranslate('Grades');
  return useMemo(() => [
    {
      id: 'student',
      accessorFn: (row) => formatName(row.student),
      header: t('columns.student'),
      cell: ({ row }) => (
        <DataBlock
          type="student"
          student={row.original.student}
        />
      )
    },
    ...(institutionType === 'enrolling' ? [
      {
        id: 'teachingInstitution',
        accessorFn: (original) => original.section.session.course.institution.name,
        header: t('columns.teachingInstitution'),
        cell: ({ row }) => <DataBlock type="institution" institution={row.original.section.session.course.institution}/>
      }
    ] : []),
    ...(institutionType === 'teaching' ? [
      {
        id: 'homeInstitution',
        accessorFn: (original) => original.institution.name,
        header: t('columns.homeInstitution'),
        cell: ({ row }) => <DataBlock type="institution" institution={row.original.institution}/>
      }
    ] : []),
    {
      id: 'course',
      accessorFn: (original) => `${original.section.session.course.code} ${original.section.session.course.title}`,
      header: t('columns.course'),
      enableSorting: true,
      cell: ({ row }) => <DataBlock type="course" course={row.original.section.session.course}/>
    },
    {
      accessorKey: 'section.session.course.hours',
      header: t('columns.credits'),
      enableSorting: true
    },
    {
      id: 'session',
      accessorFn: (original) => `${original.section.session.name} ${original.section.session.term}`,
      header: t('columns.term&session'),
      meta: {
        emptyWhen: ({ row }) => row.section.session.course.onDemand
      },
      cell: ({ row }) => <DataBlock type="term&session" session={row.original.section.session}/>
    },
    ...(institutionType === 'enrolling' ? [
      {
        header: t('columns.section'),
        accessorKey: 'section.number'
      }
    ] : []),
    {
      accessorKey: 'letterGrade',
      header: t('columns.letterGrade'),
      enableSorting: true
    },
    {
      accessorKey: 'numericalGrade',
      header: t('columns.numericalGrade'),
      enableSorting: true
    },
    actionsColumn
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ], []) as ColumnDef<any, any>[];
};

export const useGradesFilters = (institutionType: 'enrolling' | 'teaching') => {
  const t = useTranslate('Grades');
  return useMemo(() => [
    ...(institutionType === 'enrolling' ? [
      createTableInstitutionFilter({
        label: t('filters.teachingInstitution')
      })
    ] : []),
    ...(institutionType === 'teaching' ? [
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      createTableInstitutionFilter<any>({
        label: t('filters.homeInstitution'),
        getInstitution: row => row.institution
      })
    ] : []),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createTableDefaultFilter<any>({
      id: 'courseId',
      label: t('filters.courseId'),
      getFilterValue: (row) => row.section.session.course.id
    }),
    createTableYearFilter(),
    createTableTermFilter(),
    createTableSessionFilter(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createTableDefaultFilter<any>({
      id: 'letterGrade',
      label: t('filters.letterGrade'),
      getFilterValue: (row) => row.letterGrade
    })
  ], [institutionType]);
};
