import { useTranslate } from '@acadeum/translate';
import React, { useMemo } from 'react';
import { ContentSection, FormField } from '@acadeum/ui';

export interface AdditionalCostsSectionValues {
  additionalCosts: boolean;
}

export function AdditionalCostsSection() {
  const t = useTranslate('shared-admin-ui.CourseForm');

  const additionalCostsOptions = useMemo(() => [
    {
      value: false,
      label: t('no')
    },
    {
      value: true,
      label: t('yes')
    }
  ], []);

  return (
    <ContentSection title={t('additionalCosts.title')}>
      <FormField
        border
        noMargin
        type="radio"
        name="additionalCosts"
        options={additionalCostsOptions}
        label={t('additionalCosts.additionalCosts.label')}
        helpText={t('additionalCosts.additionalCosts.helpText')}
      />
    </ContentSection>
  );
}
