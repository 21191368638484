import { useRef, useEffect, useCallback } from 'react';

import { getSafeTimeoutInterval } from '@acadeum/helpers';
import { getAccessTokenExpiresAt } from './accessToken';
import type { AuthCookieConfig, ExpiresAt, OnAccessTokenExpired } from './types';

// Watches the user's "access token": when it expires, it calls `onAccessTokenExpired()`.
export function useAccessTokenExpiryWatcher({
  onAccessTokenExpired,
  accessTokenExpiresAt,
  authCookieConfig
}: {
  onAccessTokenExpired: OnAccessTokenExpired;
  accessTokenExpiresAt?: ExpiresAt;
  authCookieConfig?: AuthCookieConfig
}) {
  const accessTokenExpiryTimer = useRef();

  const onAccessTokenExpiresAt = useCallback((accessTokenExpiresAt) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    accessTokenExpiryTimer.current = setTimeout(
      () => {
        if (Date.now() < accessTokenExpiresAt) {
          return onAccessTokenExpiresAt(accessTokenExpiresAt);
        }
        // debug('Access Token Expiry Watcher: Expired');
        onAccessTokenExpired();
      },
      getSafeTimeoutInterval(accessTokenExpiresAt - Date.now())
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // As an alternative to listening to Redux state updates,
  // it could listen to `localStorage` key updates.
  useEffect(() => {
    // debug('Access Token Expiry Watcher: Expires at', accessTokenExpiresAt && new Date(accessTokenExpiresAt));
    clearTimeout(accessTokenExpiryTimer.current);
    if (accessTokenExpiresAt) {
      onAccessTokenExpiresAt(accessTokenExpiresAt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessTokenExpiresAt]);

  useEffect(() => {
    const accessTokenExpiresAt = getAccessTokenExpiresAt({ authCookieConfig });
    // debug('Access Token Expiry Watcher: On Mount: Expires at', accessTokenExpiresAt && new Date(accessTokenExpiresAt));
    clearTimeout(accessTokenExpiryTimer.current);
    if (accessTokenExpiresAt) {
      onAccessTokenExpiresAt(accessTokenExpiresAt);
    }
    return () => {
      clearTimeout(accessTokenExpiryTimer.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// function debug(...args) {
//   console.log(...args);
// }
