
const TERMS_ORDER = [
  'January',
  'Spring',
  'May',
  'Summer',
  'Fall',
  'Winter'
];

export default function sortTerms(terms) {
  return terms.sort((a, b) => {
    const aIndex = TERMS_ORDER.indexOf(a);
    const bIndex = TERMS_ORDER.indexOf(b);
    if (aIndex === -1) {
      if (bIndex === -1) {
        return a > b ? 1 : (a < b ? -1 : 0);
      } else {
        return 1;
      }
    } else {
      if (bIndex === -1) {
        return -1;
      } else {
        return aIndex - bIndex;
      }
    }
  });
}
