import type { Dispatch, FC, SetStateAction } from 'react';
import React, { memo, useCallback, useMemo, useState } from 'react';

import { useTranslate, useTranslateDictionary } from '@acadeum/translate';
import type { UseStepResult } from '@acadeum/hooks';
import { useStep } from '@acadeum/hooks';
import type { OnSubmit } from '@acadeum/core-ui';
import type { UserRole } from '@acadeum/types';
import { getErrorData } from '@acadeum/helpers';
import { Page, StepsProgress, SuccessModal, waitForModalToClose } from '@acadeum/ui';
import type {
  UseCreateUserRoleMutation,
  UseSetUsersRolesForUsersMutation,
  UseFetchUsersQuery,
  UseFetchUserRolesQuery,
  UseFetchUserRoleQuery,
  UseUpdateUserRoleMutation
} from '@acadeum/api';

import type { GeneralRoleInfoFormProps } from '../ui/GeneralRoleInfoForm';
import { GeneralRoleInfoForm } from '../ui/GeneralRoleInfoForm';
import { ManageRolePermissionsForm } from '../ui/ManageRolePermissionsForm';
import { AssignUsersToRoleForm } from '../ui/AssignUsersToRoleForm';
import { UserRoleEmailNotificationForm } from '../ui/UserRoleEmailNotificationForm';

import type { FormPermissionValues } from '../lib/types';
import { prepareFormPermissionsToApiStandard } from '../lib/prepareFormPermissionsToApiStandard';
import { useNavigate } from '../../../providers/useNavigate';
import { useSettingsRoutes } from '../../../hooks/useSettingsRoutes';
import { useApp } from '../../../providers/useApp';

interface FormValues {
  name: string;
  description: string;
  permissions: FormPermissionValues;
  userIds: number[];
  notifications: string[];
}

export interface StepProps {
  navigation: UseStepResult<Step['id']>['navigation'];
  formData: FormValues;
  setFormData: Dispatch<SetStateAction<FormValues>>;
  onCancel: () => void;
  onSubmit: OnSubmit<FormValues>;
  useFetchUsersQuery: UseFetchUsersQuery;
}

export interface Step {
  id: 'generalInfo' | 'assignPermissions' | 'assignUsers' | 'notifications';
  component: FC<StepProps>;
}

export interface AddUserRolePageProps {
  useCreateUserRoleMutation: UseCreateUserRoleMutation;
  useSetUsersRolesForUsersMutation: UseSetUsersRolesForUsersMutation;
  useFetchUsersQuery: UseFetchUsersQuery;
  useFetchUserRolesQuery: UseFetchUserRolesQuery;
  useUpdateUserRoleMutation: UseUpdateUserRoleMutation;
  useFetchUserRoleQuery: UseFetchUserRoleQuery;
}

export const AddUserRolePage: FC<AddUserRolePageProps> = ({
  useSetUsersRolesForUsersMutation,
  useCreateUserRoleMutation,
  useFetchUserRoleQuery,
  useFetchUsersQuery,
  useFetchUserRolesQuery,
  useUpdateUserRoleMutation
}) => {
  const t = useTranslate('shared-admin-ui.AddUserRolePage');
  const router = useNavigate();
  const { app } = useApp();
  const { getUserRoleDetailsUrl } = useSettingsRoutes();

  const [createUserRoleMutation] = useCreateUserRoleMutation();
  const [setUsersRolesForUsersMutation] = useSetUsersRolesForUsersMutation();

  const STEPS = useMemo<Step[]>(() => [
    {
      id: 'generalInfo',
      component: GeneralInfo
    },
    {
      id: 'assignPermissions',
      component: AssignPermissions
    },
    {
      id: 'assignUsers',
      component: AssignUsers
    },
    {
      id: 'notifications',
      component: SetUpEmailNotifications
    }
  ], []);

  const STEPS_IDS = useMemo(() => STEPS.map(step => step.id), [STEPS]);

  const { step, navigation } = useStep({ steps: STEPS });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [formData, setFormData] = useState<FormValues>({} as FormValues);
  const [createdRoleId, setCreatedRoleId] = useState<UserRole['id']>();

  const onSubmit: OnSubmit<FormValues> = useCallback(async ({
    name,
    description,
    permissions,
    userIds,
    notifications
  }) => {
    try {
      const { id: roleId } = await createUserRoleMutation({
        name,
        description,
        permissions: prepareFormPermissionsToApiStandard(permissions),
        notifications
      }).unwrap();
      if (userIds) {
        await setUsersRolesForUsersMutation({ roleIds: [roleId], userIds }).unwrap();
      }
      setCreatedRoleId(roleId);
      setShowSuccessModal(true);
    } catch (error) {
      const { code, field } = getErrorData(error);
      if (code === 'occupied' && field === 'name') {
        navigation.go('generalInfo');
      }
      throw error;
    }
  }, [
    navigation,
    setCreatedRoleId,
    setShowSuccessModal
  ]);

  const onCancel = useCallback(() => router.back(), [router]);

  const { getSettingsUrl, getUserRoleManagementUrl } = useSettingsRoutes();

  return (
    <Page
      title={t('title')}
      breadcrumbs={[
        [t(app === 'admin' ? 'general' : 'settings', { global: true }), getSettingsUrl()],
        [t('roleManagement'), getUserRoleManagementUrl()],
        t('title')
      ]}
    >
      <StepsProgress
        step={step.id}
        steps={STEPS_IDS}
        stepNames={useTranslateDictionary('shared-admin-ui.AddUserRolePage.steps', STEPS_IDS)}
      />
      <br/>
      {step && <step.component {...{
        navigation,
        formData,
        setFormData,
        onCancel,
        onSubmit,
        useFetchUsersQuery,
        useFetchUserRolesQuery,
        useFetchUserRoleQuery,
        useUpdateUserRoleMutation
      }} />}
      <SuccessModal
        backdrop="static"
        size="narrow"
        show={showSuccessModal}
        onHide={async () => {
          setShowSuccessModal(false);
          await waitForModalToClose();
          void router.push(getUserRoleDetailsUrl(createdRoleId!));
        }}
        secondaryAction={{
          content: t('goToOverview'),
          url: getUserRoleDetailsUrl(createdRoleId!)
        }}
      >
        {t('successMessage')}
      </SuccessModal>
    </Page>
  );
};


const GeneralInfo = memo<StepProps>(({
  navigation,
  onCancel,
  formData,
  setFormData
}) => {
  const t = useTranslate('shared-admin-ui.AddUserRolePage');

  const onSubmit: GeneralRoleInfoFormProps['onSubmit'] = async (values) => {
    navigation.next();
    setFormData(prev => ({ ...prev, ...values }));
  };

  return (
    <GeneralRoleInfoForm
      mode="create"
      onSubmit={onSubmit}
      onCancel={onCancel}
      defaultValues={formData}
      submitText={t('next')}
    />
  );
});

GeneralInfo.displayName = 'GeneralInfo';

const AssignPermissions = memo<StepProps>(({
  onCancel,
  navigation,
  formData,
  setFormData
}) => {
  const onSubmit = async (permissions) => {
    setFormData({ ...formData, permissions });
    navigation.next();
  };

  const onBack = async (permissions) => {
    setFormData({ ...formData, permissions });
    navigation.previous();
  };

  return (
    <ManageRolePermissionsForm
      type="create"
      defaultValues={formData.permissions}
      onCancel={onCancel}
      onSubmit={onSubmit}
      onBack={onBack}
    />
  );
});

AssignPermissions.displayName = 'AssignPermissions';

const AssignUsers = memo<StepProps>(({
  onCancel,
  navigation,
  formData,
  setFormData,
  useFetchUsersQuery
}) => {
  const t = useTranslate('shared-admin-ui.AddUserRolePage');

  const onSubmit = async (values) => {
    setFormData({ ...formData, ...values });
    navigation.next();
  };

  const onBack = async (values) => {
    const result = { ...formData, ...values };
    setFormData(result);
    navigation.previous();
  };

  return (
    <AssignUsersToRoleForm
      useFetchUsersQuery={useFetchUsersQuery}
      defaultValues={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onBack={onBack}
      submitText={t('next')}
    />
  );
});

AssignUsers.displayName = 'AssignUsers';

const SetUpEmailNotifications = memo<StepProps>(({
  onCancel,
  navigation,
  formData,
  setFormData,
  onSubmit: propsOnSubmit
}) => {
  const t = useTranslate('shared-admin-ui.AddUserRolePage');

  const onSubmit = async (values, options) => {
    const result = { ...formData, ...values };
    setFormData(result);
    return await propsOnSubmit?.(result, options);
  };

  const onBack = async (values) => {
    setFormData({ ...formData, ...values });
    navigation.previous();
  };

  return (
    <UserRoleEmailNotificationForm
      mode="create"
      onCancel={onCancel}
      onBack={onBack}
      onSubmit={onSubmit}
      defaultValues={{
        notifications: formData.notifications || []
      }}
      submitText={t('createRole')}
    />
  );
});

SetUpEmailNotifications.displayName = 'SetUpEmailNotifications';
