import React from 'react';
import classNames from 'classnames';

import type { CheckboxProps } from '../Checkbox';
import { Checkbox } from '../Checkbox';

import { SectionCardHeader } from './ui/SectionCardHeader';
import { TermSessionStartEndDateRow } from './ui/TermSessionStartEndDateRow';
import { AddAndDropDateCostRow } from './ui/AddAndDropDateCostRow';
import { ScheduleRow } from './ui/ScheduleRow';
import { SectionCardFooter } from './ui/SectionCardFooter';
import { SectionCardSeparator } from './ui/SectionCardSeparator';
import { OnDemandRow } from './ui/OnDemandRow';

import styles from './SectionCard.module.scss';

export interface SectionCardProps {
  className?: string;
  children?: React.ReactNode;
  checkboxProps?: CheckboxProps;
}

export const SectionCard: React.FC<SectionCardProps> & {
  Header: typeof SectionCardHeader;
  TermSessionStartEndDateRow: typeof TermSessionStartEndDateRow;
  AddAndDropDateCostRow: typeof AddAndDropDateCostRow;
  ScheduleRow: typeof ScheduleRow;
  Footer: typeof SectionCardFooter;
  Separator: typeof SectionCardSeparator;
  OnDemandRow: typeof OnDemandRow;
} = ({
  className,
  children,
  checkboxProps
}) => {
  return (
    <article className={classNames(className, styles.SectionCard, {
      [styles.hasCheckbox]: checkboxProps
    })}>
      {checkboxProps && (
        <div className={styles.checkboxWrapper}>
          <Checkbox {...checkboxProps}/>
        </div>
      )}
      <div className={styles.content}>
        {children}
      </div>
    </article>
  );
};

SectionCard.Header = SectionCardHeader;
SectionCard.TermSessionStartEndDateRow = TermSessionStartEndDateRow;
SectionCard.AddAndDropDateCostRow = AddAndDropDateCostRow;
SectionCard.ScheduleRow = ScheduleRow;
SectionCard.Footer = SectionCardFooter;
SectionCard.Separator = SectionCardSeparator;
SectionCard.OnDemandRow = OnDemandRow;
