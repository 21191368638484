import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { waitForJob } from '@acadeum/helpers';

import InstitutionForm from '../../components/InstitutionForm';
import Section from '../../components/Section';

import isAcadeumAdministrator from '../../helpers/isAcadeumAdministrator';
import getErrorData from '../../helpers/getErrorData';

import actions from '../../actions';

const {
  fetchInstitutionForEditing,
  notifyError,
  goto,
  runJob,
  getJobStatus
} = actions;

export default function EditInstitution() {
  const institution = useSelector(state => state.institutions.institution);

  const stopPollingJobStatus = useRef();

  useEffect(() => {
    return () => {
      if (stopPollingJobStatus.current) {
        stopPollingJobStatus.current();
      }
    };
  }, []);

  async function onSubmit(values) {
    // If no `state` was selected then set the value to "N/A".
    // That's a legacy value that has been historically used for "State is unknown".
    if (!values.state) {
      values.state = 'N/A';
    }
    // `minorityServingInstitutionTypes` field is not editable in `ASP-site`.
    values.minorityServingInstitutionTypes = institution.minorityServingInstitutionTypes;
    const onUpdated = () => goto(`/institutions/${values.vanityUrl || institution.id}`);
    const version = institution.version || 0;
    values.version = version + 1;
    try {
      await waitForJob(
        await runJob('institutions-update', {
          id: institution.id,
          body: values
        }),
        getJobStatus,
        (cancel) => stopPollingJobStatus.current = cancel
      );
      onUpdated();
    } catch (error) {
      if (getErrorData(error).message === 'copper_update_error') {
        notifyError('The update has happened successfully, but you need to go to copper and add the correct institution id: ' + institution.id);
        return onUpdated();
      }
      if (getErrorData(error).code === 'version_mismatch' ||
        getErrorData(error).message === 'Version.Mismatch') {
        notifyError('Someone seems to have edited institution data while you were doing the same. Save your changes somewhere, refresh the page, reapply the changes, and submit again.');
        return;
      }
      throw error;
    }
  }

  return (
    <Section title={institution.name}>
      <InstitutionForm
        onSubmit={onSubmit}
        submitTitle="Update"
        institution={institution}
      />

      {/* {institution.stripeCustomerId &&
        <AnnualFeesForm institution={institution} />
      } */}
    </Section>
  );
}

EditInstitution.meta = ({ useSelector }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const institution = useSelector(state => state.institutions.institution);
  return {
    title: institution.name
  };
};

EditInstitution.breadcrumbs = (state) => {
  const { institution } = state.institutions;
  return [
    ['Institutions', '/institutions'],
    [institution.name, `/institutions/${institution.vanityUrl || institution.id}`],
    'Edit'
  ];
};

EditInstitution.load = async ({ user, params: { id } }) => {
  if (!isAcadeumAdministrator(user)) {
    const error = new Error('Unauthorized');
    error.data = {
      type: 'unauthorized'
    };
    throw error;
  }
  await fetchInstitutionForEditing(id);
};
