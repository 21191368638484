import canEditUser_ from 'common-lib/lib/canEditUser.js';

import userHasPermission from './userHasPermission.js';

export default function canEditUser(operation, { user, userInstitutionId, byUser }) {
  return canEditUser_({
    user,
    userInstitutionId: userInstitutionId || (user.institution ? user.institution.id : user.institutionId),
    byUser,
    userHasPermission,
    operation
  });
}
