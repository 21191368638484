import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const getSingleSignOnConfig = redux.action(
  ({ domain }) => async http => ({
    singleSignOn: await http.get(`/authentication-settings/single-sign-on/${domain}`),
    domain
  }),
  (state, result) => ({
    ...state,
    ...result
  })
);

export const resetSingleSignOnConfig = redux.action(
  ({ domain }) => http => http.delete(`/authentication-settings/single-sign-on/${domain}`)
);

export const setSingleSignOnInitialValues = redux.simpleAction(
  (state, { singleSignOn, domain }) => ({
    ...state,
    singleSignOn,
    domain
  })
);

export default redux.reducer();
