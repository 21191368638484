import React from 'react';

export interface OnFocusOutProps {
  children: JSX.Element;
  containerRef: React.MutableRefObject<HTMLElement>;
  toggleRef: React.MutableRefObject<HTMLElement>;
  onFocusOut?: (event: React.FocusEvent) => void;
}

export const OnFocusOut: React.FC<OnFocusOutProps> = ({
  children,
  onFocusOut,
  containerRef,
  toggleRef
}) => {
  const onBlur = (event: React.FocusEvent) => {
    const containerNode = containerRef.current;
    const toggleNode = toggleRef.current;

    if (!containerNode) {
      return false;
    }

    const focusedNode = event.relatedTarget;
    if (focusedNode && (containerNode.contains(focusedNode) || toggleNode?.contains(focusedNode))) {
      return false;
    }

    if (typeof onFocusOut === 'function') {
      onFocusOut(event);
    }
    return true;
  };

  return React.cloneElement(children, { onBlur });
};
