import userRoleIds from '../constants/userRoleIds.json' assert { type: 'json' };

// import checkUserPermissions from './checkUserPermissions.js';

export default function canEditUser({
  user,
  userInstitutionId,
  byUser,
  userHasPermission,
  operation,
  permissionParameters
}) {
  userInstitutionId = userInstitutionId || ('institution' in user ? user.institution.id : user.institutionId);
  // Validate arguments.
  if (!userInstitutionId) {
    throw new Error('`canEditUser()` requires a `userInstitutionId` parameter');
  }

  // Check permissions.
  if (!userHasPermission(
    byUser,
    `user:${operation}`,
    {
      orgId: userInstitutionId,
      ownerId: user.id
    },
    permissionParameters
  )) {
    // console.log('* No permission to edit this user');
    // console.log('Operation', operation)
    // console.log('Permission Parameters', permissionParameters)
    return false;
  }

  // Can edit self.
  if (user.id === byUser.id) {
    return true;
  }

  // A "Platform Administrator" can edit any user.
  //
  // An "Acadeum Administrator" can edit any "regular" user:
  // not an "Acadeum Administrator", not a "Platform Administrator".
  //
  // A "regular" user can only edit another "regular" user:
  // not an "Acadeum Administrator", not a "Platform Administrator".

  const isByPlatformAdministrator = byUser.roles.some(role => role.id === userRoleIds['Platform Administrator']);
  const isByAcadeumAdministrator = byUser.roles.some(role => role.id === userRoleIds['Acadeum Administrator']);

  const isPlatformAdministrator = user.roles.some(role => role.id === userRoleIds['Platform Administrator']);
  const isAcadeumAdministrator = user.roles.some(role => role.id === userRoleIds['Acadeum Administrator']);

  if (isByPlatformAdministrator) {
    return true;
  }

  if (isPlatformAdministrator || isAcadeumAdministrator) {
    // console.log('* Only a Platform Administrator can edit Acadeum Administrator or Platform Administrator users');
    return false;
  }

  return true;
}
