import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Accordion } from '@acadeum/ui';

import WelcomeLetter from '../../../../WelcomeLetter';

import actions from '../../../../../actions';

const { getCourseAdditionalInfo } = actions;

export function WelcomeLetterSection({
  course
}) {
  const [isLoadingWelcomeLetter, setIsLoadingWelcomeLetter] = useState(true);
  const courseAdditionalInfo = useSelector(state => state.institutions.courseAdditionalInfo);
  const welcomeLetter = courseAdditionalInfo && courseAdditionalInfo.welcomeLetter;

  const hasWelcomeLetter = !isLoadingWelcomeLetter && Boolean(welcomeLetter);

  useEffect(() => {
    const fetchData = async () => {
      await getCourseAdditionalInfo({
        institutionId: course.institution.id,
        courseId: course.id
      });
      setIsLoadingWelcomeLetter(false);
    };
    fetchData();
  }, []);

  return (
    <Accordion title="Welcome Letter" defaultOpen={hasWelcomeLetter}>
      <WelcomeLetter
        welcomeLetter={welcomeLetter}
        loading={isLoadingWelcomeLetter}
      />
    </Accordion>
  );
}
