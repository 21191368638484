import { ACADEUM_INSTITUTION_ID, ACADEUM_MARKETPLACE_INSTITUTION_ID, HOME_SCHOOLING_FOR_COLLEGE_CREDIT_INSTITUTION_ID } from 'common-lib/constants/institutionIds';

export { ACADEUM_INSTITUTION_ID, ACADEUM_MARKETPLACE_INSTITUTION_ID, HOME_SCHOOLING_FOR_COLLEGE_CREDIT_INSTITUTION_ID } from 'common-lib/constants/institutionIds';

export const ACADEUM_INSTITUTION_OPTION = {
  value: ACADEUM_INSTITUTION_ID,
  label: 'Acadeum / Test'
};

export const ACADEUM_MARKETPLACE_INSTITUTION_OPTION = {
  value: ACADEUM_MARKETPLACE_INSTITUTION_ID,
  label: 'Acadeum Marketplace'
};

export const ACADEUM_HS4CC_INSTITUTION_OPTION = {
  value: HOME_SCHOOLING_FOR_COLLEGE_CREDIT_INSTITUTION_ID,
  label: 'HS4CC'
};
