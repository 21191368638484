import { userHasPermission } from '@acadeum/helpers';
import { EnrollingPaymentHistoryPage } from '@acadeum/shared-admin-ui';

import { useFetchInvoiceQuery } from '../../api/invoices';
import { useFetchPaymentIntentsQuery, useLazyFetchPaymentIntentsQuery } from '../../api/stripe';
import type { AppPage } from '../../helpers/app.types';

const PaymentHistory: AppPage = () => {
  return (
    <EnrollingPaymentHistoryPage
      useFetchInvoiceQuery={useFetchInvoiceQuery}
      useFetchPaymentIntentsQuery={useFetchPaymentIntentsQuery}
      useLazyFetchPaymentIntentsQuery={useLazyFetchPaymentIntentsQuery}
    />
  );
};

PaymentHistory.meta = () => ({
  title: 'Payment History'
});

PaymentHistory.when = ({ user }) => userHasPermission(user, 'paymentMethod:read', {
  orgId: user?.institution.id,
  ownerId: null
});

export default PaymentHistory;
