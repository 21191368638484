import formatTerm from 'common-lib/lib/formatTerm.js';

import type { Language } from '@acadeum/types';

export function getTermOptions({ language }: { language: Language; }) {
  let terms = getTermsStartingFromPresent(4);
  // if (withOther) {
  terms = terms.concat('Other');
  // }
  return terms.map((term) => ({
    value: term,
    label: formatTerm(term, { language })
  }));
}

// When changing anything here, also change in
// `Acadeum-Students-Portal/translation/locales/xx/Term.json`.
const TERMS = [
  'Winter',
  'Spring',
  'Summer',
  'Fall'
];

function getTermIndexByMonthIndex(index) {
  switch (index) {
    case 0:  // January
    case 1: // February
      return 0; // Winter
    case 2: // March
    case 3: // April
    case 4: // May
      return 1; // Spring
    case 5: // June
    case 6: // July
    case 7: // August
      return 2; // Summer
    case 8: // June
    case 9: // July
    case 10: // August
      return 3; // Autumn
    case 11: // December
      return 4; // Winter
  }
}

export function getTermsStartingFromPresent(count, date = new Date()) {
  const currentTerm = getTermIndexByMonthIndex(date.getMonth()) as number;
  const currentYear = date.getFullYear();

  let term = currentTerm;
  let year = currentYear;

  if (currentTerm === TERMS.length) {
    term = 0;
    year++;
  }

  const results: [string, number][] = [];

  while (count > 0) {
    results.push([TERMS[term], year]);
    if (term < TERMS.length - 1) {
      term++;
    } else {
      term = 0;
      year++;
    }
    count--;
  }

  if (currentTerm === TERMS.length) {
    results[0][1]--;
  }

  return results.map(([term, year]) => `${term} ${year}`);
}
