// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';

import 'brace/mode/json';
import 'brace/snippets/json';
import 'brace/ext/language_tools';
import 'brace/theme/dreamweaver';

const AceEditorComponent = React.forwardRef(({
  height = 512,
  // required,
  disabled,
  readOnly,
  value,
  onChange,
  // className,
  ...rest
}, ref) => {
  const editor = useRef();

  // `<AceEditor/>` doesn't accept a `ref`.
  // https://github.com/securingsincity/react-ace/blob/master/src/ace.tsx
  useImperativeHandle(ref, () => ({
    // * `focus()` focuses the field if it's not valid.
    focus: () => editor.current.editor.focus()
  }), [editor]);

  // In order to reset the value, an empty string has to be passed.
  // The editor won't reset its content if `undefined` or `null` is passed.
  // https://github.com/securingsincity/react-ace/issues/894
  // In this case, `react-hook-form` passes `undefined` when calling `clearForm()`.
  if (value === undefined) {
    value = '';
  }

  return (
    <AceEditor
      ref={editor}
      {...rest}
      width="100%"
      height={`${height}px`}
      theme="dreamweaver"
      fontSize={16}
      showPrintMargin
      showGutter
      highlightActiveLine
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
        showLineNumbers: true,
        tabSize: 2
      }}
      editorProps={{ $blockScrolling: true }}
      value={value}
      disabpled={disabled}
      readOnly={readOnly}
      onChange={onChange}
    />
  );
});

AceEditorComponent.propTypes = {
  height: PropTypes.number,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default AceEditorComponent;
