import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';

import { useTranslate } from '@acadeum/translate';
import { DownloadIcon } from '@acadeum/icons';
import { Button } from '../Button';
import type { ButtonProps } from '../Button';

import { toast } from '../toast';

export interface DownloadButtonProps extends ButtonProps {
  successMessage?: string;
  errorMessage?: string;
  children?: ReactNode;
}

export const DownloadButton: FC<DownloadButtonProps> = ({
  onClick: propsOnClick,
  successMessage = 'Success! Check your downloads folder for your information.',
  errorMessage = 'Couldn\'t download file',
  children,
  ...rest
}) => {
  const t = useTranslate('ui.DownloadButton');

  const onClick = async (event) => {
    try {
      if (typeof propsOnClick === 'function') {
        await propsOnClick(event);
      }
      toast.success(successMessage);
    } catch (error) {
      console.error(error);
      toast.error(errorMessage);
    }
  };

  return (
    <Button
      {...rest}
      download
      icon={DownloadIcon}
      onClick={onClick}
      variant="tertiary"
    >
      {children || t('download')}
    </Button>
  );
};

DownloadButton.propTypes = {
  onClick: PropTypes.func,
  url: PropTypes.string,
  disabled: PropTypes.bool,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  children: PropTypes.node
};
