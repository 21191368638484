import React, { useEffect, useRef } from 'react';

import { COURSE_APPROVAL_SCHEMA, waitForJob } from '@acadeum/helpers';

import Link from '../../components/Link';
import DataUploadPage from '../../components/DataUploadPage';

import DocumentIcon from '../../../assets/images/icons/document.svg';

import actions from '../../actions';

const {
  runJob,
  getJobStatus
} = actions;

export default function UploadCourseApprovals() {
  const stopPollingJobStatus = useRef();

  useEffect(() => {
    return () => {
      if (stopPollingJobStatus.current) {
        stopPollingJobStatus.current();
      }
    };
  }, []);

  const getColumnSchema = (column) => COURSE_APPROVAL_SCHEMA[column];

  async function uploadApprovals(rows) {
    return await waitForJob(
      await runJob('uploadCourseApprovals', { rows }),
      getJobStatus,
      (cancel) => stopPollingJobStatus.current = cancel
    );
  }

  return (
    <section>
      <h1>Upload Approvals</h1>

      <Link
        to="https://dliov3t26vp8o.cloudfront.net/Data Upload Templates/Course.Approvals.xlsx"
        target="_blank"
        className="upload-data-page__download-template">
        <DocumentIcon className="upload-data-page__download-template-icon" />
        Download Course Approval Upload Template
      </Link>

      <DataUploadPage
        schema={COURSE_APPROVAL_SCHEMA}
        getColumnSchema={getColumnSchema}
        onUpload={uploadApprovals}
        successMessage="{count} course approvals have been updated"/>
    </section>
  );
}

UploadCourseApprovals.meta = () => ({
  title: 'Upload Approvals'
});

UploadCourseApprovals.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Upload Approvals'
];
