import React from 'react';

import formatDateApproximate from 'common-lib/lib/formatDateApproximate';

import type { FormatDateProps } from '../FormatDate';
import { FormatDate } from '../FormatDate';

export type HumanReadableDateProps = FormatDateProps;

export const HumanReadableDate = ({ date, utc, locale = 'en', ...rest }: HumanReadableDateProps) => {
  return (
    <FormatDate date={date} utc={utc} locale={locale} {...rest}>
      {(formattedDate) => {
        return `${formattedDate} (${formatDateApproximate(date, { utc, locale })})`;
      }}
    </FormatDate>
  );
};

HumanReadableDate.propTypes = FormatDate.propTypes;
