import formatYesOrNo from 'common-lib/lib/formatYesOrNo.js';

import type { Language, Options } from '@acadeum/types';

export function getYesOrNoOptions({ language }: { language: Language }): Options<boolean> {
  return [true, false].map((value) => ({
    value,
    label: formatYesOrNo(value, { language })
  }));
}
