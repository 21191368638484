import type { FC } from 'react';
import React, { useId, useRef, useState } from 'react';
import classNames from 'classnames';

import { Popover, PopoverContent, PopoverTrigger } from '../Popover';

import styles from './PriceInput.module.scss';
import { CaretDownIcon } from '@acadeum/icons';
import { Radio } from '../Radio';
import { useTranslate } from '@acadeum/translate';
import { CurrencyInput } from '../CurrencyInput';

interface PriceValue {
  from?: number;
  to?: number;
}

export interface PriceInputProps {
  className?: string;
  onChange: (value: PriceValue) => void;
  value: PriceValue;
  label: string;
  min?: number;
  max?: number;
}

const ANY_VALUE: PriceValue = { from: undefined, to: undefined };

export const PriceInput: FC<PriceInputProps> = ({
  className,
  onChange,
  value: propsValue = ANY_VALUE,
  label,
  max,
  min
}) => {
  const [show, setShow] = useState(false);

  const [mode, setMode] = useState<'any' | 'range'>(() => {
    return propsValue.from !== undefined || propsValue.to !== undefined ? 'range' : 'any';
  });

  const t = useTranslate('ui.Price');
  const id = useId();
  const name = id + 'price';

  const refMin = useRef() as React.MutableRefObject<HTMLInputElement>;
  const refMax = useRef() as React.MutableRefObject<HTMLInputElement>;

  const onOpenChange = (isOpen) => {
    // Triggering the blur event to update the value when the popover is closed
    if (!isOpen) {
      if (mode === 'range') {
        refMin.current?.blur();
        refMax.current?.blur();
      }
    }
    setShow(isOpen);
  };

  const onChangeMode = (event) => {
    const mode = event.target.value;
    setMode(mode);
    if (mode === 'any') {
      onChange(ANY_VALUE);
    }
  };

  const onFromBlur = (event, from) => {
    const to = propsValue.to;

    if (typeof min === 'number' && from < min) {
      return onChange({
        from: min,
        to: from
      });
    }

    if (typeof max === 'number' && from > max) {
      return onChange({
        from: max,
        to: from
      });
    }

    if (typeof to === 'number' && from > to) {
      return onChange({
        from: to,
        to: from
      });
    }

    onChange({ from, to });
  };

  const onToBlur = (event, to) => {
    const from = propsValue.from;

    if (typeof max === 'number' && to > max) {
      return onChange({
        from,
        to: max
      });
    }

    if (typeof min === 'number' && to < min) {
      return onChange({
        from,
        to: min
      });
    }

    if (typeof from === 'number' && to < from) {
      return onChange({
        from: to,
        to: from
      });
    }

    onChange({ from, to });
  };

  return (
    <Popover open={show} onOpenChange={onOpenChange}>
      <PopoverTrigger className={classNames(className, styles.button)}>
        {label}
        <CaretDownIcon className={styles.icon}/>
      </PopoverTrigger>
      <PopoverContent
        className={styles.body}
        sideOffset={4}
      >
        <Radio
          name={name}
          label={t('anyPrice')}
          onChange={onChangeMode}
          value={'any'}
          className={styles.radioWrap}
          checked={mode === 'any'}
        />
        <Radio
          name={name}
          label={t('priceRange')}
          onChange={onChangeMode}
          value={'range'}
          className={styles.radioWrap}
          checked={mode === 'range'}
        />

        {mode === 'range' && (
          <>
            <label htmlFor={`${id}_min`} className={styles['label-field-input']}>
              {t('from')}
            </label>
            <CurrencyInput
              ref={refMin}
              value={propsValue.from}
              onBlur={onFromBlur}
              currency="USD"
              id={`${id}_min`}
              placeholder={t('eg')}
              min={min}
              max={max}
            />

            <label htmlFor={`${id}_max`} className={styles['label-field-input']}>
              {t('to')}
            </label>
            <CurrencyInput
              ref={refMax}
              value={propsValue.to}
              onBlur={onToBlur}
              id={`${id}_max`}
              currency="USD"
              placeholder={t('eg')}
              min={min}
              max={max}
            />
          </>
        )}
      </PopoverContent>
    </Popover>
  );
};
