import type { FC, MutableRefObject } from 'react';
import React, { useRef, useState } from 'react';

import {
  COURSE_UPLOAD_SCHEMA,
  isAcadeumAdministrator,
  SECTION_SCHEMA,
  SESSION_SCHEMA,
  transformCourseUploadData
} from '@acadeum/helpers';
import type { OnSubmit } from '@acadeum/ui';
import { Form, InstitutionAutocomplete, Modal, Table } from '@acadeum/ui';
import { useTranslate } from '@acadeum/translate';
import type { UseBulkCreateOrUpdate } from '@acadeum/api';
import type { UserProfile } from '@acadeum/types';

import type { DataUploadPageProps, DataUploadPageRef, ParseFileReturn } from '../../../../components/DataUploadPage';
import { DataUploadPage } from '../../../../components/DataUploadPage';

import type { UseStepResult } from '@acadeum/hooks';

import styles from './UploadCourses.module.scss';

export interface UploadCoursesProps extends Pick<DataUploadPageProps, 'cacheOptions' | 'useFileDataImportErrorMutation'> {
  dataUploadPageRef: React.Ref<DataUploadPageRef>;
  navigation: UseStepResult<unknown>['navigation'];
  onCoursesUpload: (options: { courses: object[], institutionId?: number }) => void;
  useBulkCreateOrUpdate: UseBulkCreateOrUpdate;
  user?: UserProfile
}

const COURSE_UPLOAD_SCHEMA_WITHOUT_INSTITUTION: Omit<typeof COURSE_UPLOAD_SCHEMA, 'INSTITUTION ID'> = { ...COURSE_UPLOAD_SCHEMA };
if ('INSTITUTION ID' in COURSE_UPLOAD_SCHEMA_WITHOUT_INSTITUTION) {
  delete COURSE_UPLOAD_SCHEMA_WITHOUT_INSTITUTION['INSTITUTION ID'];
}

interface FormValues {
  institutionId: number;
}

export const UploadCourses: FC<UploadCoursesProps> = ({
  navigation,
  cacheOptions,
  dataUploadPageRef,
  useFileDataImportErrorMutation,
  onCoursesUpload,
  useBulkCreateOrUpdate,
  user
}) => {
  const t = useTranslate('shared-admin-ui.UploadCourses');

  const bulkCreateOrUpdate = useBulkCreateOrUpdate();

  const [show, setShow] = useState(false);
  const [institutionId, setInstitutionId] = useState<number>();

  const formRef = useRef() as MutableRefObject<HTMLFormElement>;

  const getColumnSchema = (columnName) => COURSE_UPLOAD_SCHEMA_WITHOUT_INSTITUTION[columnName] || SESSION_SCHEMA[columnName] || SECTION_SCHEMA[columnName];

  const cache = cacheOptions?.[0];
  let data: ParseFileReturn['tableData'] = [];

  if (cache?.tableData) {
    data = cache.tableData.filter((row, index) => cache?.expectedResult?.[index]?.status === 'UPDATED');
  }

  const onSubmitInstitutionForm: OnSubmit<FormValues> = ({ institutionId }) => {
    setInstitutionId(institutionId);
  };

  const validate: DataUploadPageProps['validate'] = async ({ headerRow, tableData }) => {
    const columns = ['START DATE', 'END DATE', 'ADD DATE', 'DROP DATE'];
    const onDemandIndex = headerRow.indexOf('ON-DEMAND COURSE');

    return columns.reduce<Awaited<ReturnType<Required<DataUploadPageProps>['validate']>>>((acc, column) => {
      const index = headerRow.indexOf(column);

      for (const [tableRowIndex, tableRow] of Object.entries(tableData)) {
        const onDemand = tableRow[onDemandIndex];
        const value = tableRow[index];
        if (onDemand) {
          // Ignore
        } else {
          if (!value) {
            acc.push({
              value,
              column,
              error: 'required',
              row: Number(tableRowIndex) + 2
            });
          }
        }
      }

      return acc;
    }, []);
  };

  const getExpectedResult: DataUploadPageProps['getExpectedResult'] = ({ rows }) => bulkCreateOrUpdate({
    institutionId: isAcadeumAdministrator(user) ? institutionId : undefined,
    dryRun: true,
    courseSections: rows as never
  });

  const onUpload = async (rows) => {
    await onCoursesUpload({
      institutionId: isAcadeumAdministrator(user) ? institutionId : undefined,
      courses: rows
    });
    navigation.next();
  };

  return (
    <div className={styles.AddCourseDetailsFile}>
      {isAcadeumAdministrator(user) && (
        <Form
          ref={formRef}
          onSubmit={onSubmitInstitutionForm}
        >
          <InstitutionAutocomplete
            required
            superAdmin
            type="institution"
            className={styles.InstitutionAutocomplete}
            onChange={() => formRef.current.submit()}
          />
        </Form>
      )}

      {(isAcadeumAdministrator(user) ? institutionId : true) && (
        <DataUploadPage
          ref={dataUploadPageRef}
          preventSuccessToast
          cacheOptions={cacheOptions}
          type="UPLOAD_COURSES"
          templateLink="https://dliov3t26vp8o.cloudfront.net/Data%20Upload%20Templates/Course.Upload.Template.xlsx"
          schema={COURSE_UPLOAD_SCHEMA_WITHOUT_INSTITUTION}
          getColumnSchema={getColumnSchema}
          transformRows={transformCourseUploadData}
          useFileDataImportErrorMutation={useFileDataImportErrorMutation}
          onBack={navigation.previous}
          submitText={t('next')}
          onUpload={onUpload}
          onShowDetailsModal={setShow}
          validate={validate}
          getExpectedResult={getExpectedResult}
        />
      )}

      {cache && (
        <Modal
          show={show}
          title={t('title')}
          size="wide"
          onHide={setShow}
        >
          <Modal.Body>
            <p className={styles.AddCourseDetailsFile__description}>
              {t('description')}
            </p>
            <Table
              className={styles.AddCourseDetailsFile__table}
              columns={cache.columns}
              data={data}
              columnPinningRight={['status']}
            />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};
