import { createContext, useContext } from 'react';

interface OwnFormContextProps {
  forceSubmitWithoutValidation: () => void
}

export const OwnFormContext = createContext<OwnFormContextProps | undefined>(undefined);

export function useOwnFormContext() {
  const context = useContext(OwnFormContext);
  if (!context) {
    throw new Error('No Combobox was provided. Your component must be wrapped in a <Form> component.');
  }
  return context;
}
