import React from 'react';

export interface AspectRatioProps extends React.HTMLAttributes<HTMLDivElement> {
  ratio?: number;
}

export const AspectRatio = React.forwardRef<HTMLDivElement, AspectRatioProps>(({
  ratio = 1 / 1,
  style,
  ...rest
}, ref) => {
  return (
    <div
      data-aspect-ratio-wrapper=""
      style={{
        position: 'relative',
        width: '100%',
        paddingBottom: `${100 / ratio}%`
      }}
    >
      <div
        {...rest}
        ref={ref}
        style={{
          ...style,
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        }}
      />
    </div>
  );
});
