import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import LinearProgress from '../LinearProgress';
import { FadeInOut } from 'react-responsive-ui';

import './LoadingIndicator.sass';

export function LoadingIndicator({
  show,
  fadeOutDuration = 160,
  className,
  Indicator = LinearProgress
}) {
  return (
    <div
      className={classNames('rrui__fixed-full-width', 'react-pages__loading', className, {
        'react-pages__loading--shown': show
      })}>
      <FadeInOut show={show} fadeOutDuration={fadeOutDuration}>
        <Indicator className="react-pages__loading-line"/>
      </FadeInOut>
    </div>
  );
}

LoadingIndicator.propTypes = {
  show: PropTypes.bool,
  fadeOutDuration: PropTypes.number,
  Indicator: PropTypes.func,
  className: PropTypes.string
};
