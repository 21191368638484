import type { FC } from 'react';
import React from 'react';

import type { InstitutionAutocompleteProps } from '../../InstitutionAutocomplete';
import { InstitutionAutocomplete } from '../../InstitutionAutocomplete';

import type { BaseFilterProps } from '../context';
import { useRegisterFilter } from '../context';

export interface InstitutionFilterProps extends BaseFilterProps, Omit<InstitutionAutocompleteProps, 'value' | 'onChange'> {
}

export const InstitutionFilter: FC<InstitutionFilterProps> = ({
  name,
  label,
  getFilterLabel: propsGetFilterLabel,
  onChange: propsOnChange,
  type,
  multiple,
  ...rest
}) => {
  const getFilterLabel = (value, item) => {
    if (propsGetFilterLabel) {
      return propsGetFilterLabel(value, item);
    }
    if (multiple) {
      return item.options.find(_ => _.value === value)?.label;
    }
    return item.option.label;
  };

  const {
    option,
    onChange: onChange_
  } = useRegisterFilter({ name, label, propsOnChange, getFilterLabel });

  const onChange = (value, option) => {
    onChange_(value, {
      [multiple ? 'options' : 'option']: option
    });
  };

  return (
    <InstitutionAutocomplete
      {...rest}
      isFilter
      isFormField={false}
      multiple={multiple}
      type={type}
      label={label}
      value={option ? option.value : undefined}
      onChange={onChange}
    />
  );
};
