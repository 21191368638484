import { ReduxModule } from 'react-pages';

import { getAlgoliaIndex } from '../helpers/algolia';

const redux = new ReduxModule();

export const fetchCourse = redux.action(
  (id, { user }) => async () => {
    const course = await getAlgoliaIndex(user ? 'courses' : 'public-courses').get(id);
    if (!course) {
      const error = new Error(`Course not found: ${id}`);
      error.statusCode = 404;
      throw error;
    }
    // Convert timestamps to `Date`s.
    for (const session of course.sessions) {
      session.startDate = session.startDate ? new Date(session.startDate) : undefined;
      session.endDate = session.endDate ? new Date(session.endDate) : undefined;
      session.lastAddDate = session.lastAddDate ? new Date(session.lastAddDate) : undefined;
      session.lastDropDate = session.lastDropDate ? new Date(session.lastDropDate) : undefined;
    }
    return course;
  },
  'course'
);

export const fetchCourseBase = redux.action(
  (courseId, { include } = {}) => (http) => {
    if (include) {
      return http.get(`/courses/${courseId}?include=${encodeURIComponent(JSON.stringify(include))}`);
    }
    return http.get(`/courses/${courseId}`);
  },
  'courseBase'
);

export const fetchCourseApproval = redux.action(
  (courseId) => (http) => http.get(`/courses/${courseId}/approval`),
  'courseApproval'
);

export const removeCourseApproval = redux.action(
  (courseId, notes) => (http) => {
    return http.delete(`/courses/${courseId}/approval?notes=${encodeURIComponent(notes)}`);
  }
);

export const getApprovedCoursesWithPricing = redux.action(
  (institutionId) => (http) => {
    return http.get('/approved-courses-with-pricing', { institutionId });
  },
  'approvedCoursesWithPricing'
);

export const getTermsAndSessions = redux.action(
  () => (http) => http.get('/terms-and-sessions'),
  'termsAndSessions'
);

export const approveCourse = redux.action(
  (id, courseSubstitutes, schedule) => (http) => {
    return http.post(`/courses/${id}/approve`, {
      courseSubstitutes,
      schedule
    });
  }
);

export const requestCourse = redux.action(
  data => http => {
    Intercom('trackEvent', 'Course Request', data);
    return http.post('/courses/request', data);
  }
);

export const updateCourse = redux.action(
  (id, values) => http => {
    Intercom('trackEvent', 'edit course');
    return http.patch(`/courses/${id}`, values);
  }
);

export const addCourseResource = redux.action(
  (values) => http => {
    Intercom('trackEvent', 'add course resource');
    return http.post('/course-resources/', values);
  }
);

export const updateCourseSyllabus = redux.action(
  (courseId, { file, url }) => http => {
    return http.put(`/courses/${courseId}/syllabus`, { file, url });
  }
);

export const updateCourseResource = redux.action(
  (id, values) => http => {
    Intercom('trackEvent', 'update course resource', id);
    return http.patch(`/course-resources/${id}`, values);
  }
);

export const deleteCourseResource = redux.action(
  (id) => http => {
    Intercom('trackEvent', 'delete course resource', id);
    return http.delete(`/course-resources/${id}`);
  }
);

export const updateCourseSession = redux.action(
  (id, values) => http => {
    Intercom('trackEvent', 'update course session', id);
    return http.patch(`/sessions/${id}`, values);
  }
);

export default redux.reducer();
