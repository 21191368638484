import React from 'react';
import { useSelector } from 'react-redux';

import Action from '../../components/Action';
import ConsortiumSearch from '../../components/ConsortiumSearch';
import FormHeading from '../../components/FormHeading';
import WithPublicMarketingPageBanner from '../../components/WithPublicMarketingPageBanner';

import isAcadeumAdministrator from '../../helpers/isAcadeumAdministrator';

function Consortia() {
  const user = useSelector(state => state.auth.user);

  return (
    <section>
      {user && isAcadeumAdministrator(user) &&
        <div className="instant-search-actions">
          <Action
            link="/consortia/add"
            icon="add"
            iconSize="l">
            Add Consortium
          </Action>
        </div>
      }

      <FormHeading>
        Consortia
      </FormHeading>

      <ConsortiumSearch />
    </section>
  );
}

Consortia.meta = () => ({
  title: 'Consortia'
});

Consortia.breadcrumbs = () => [
  'Consortia'
];

export default WithPublicMarketingPageBanner(Consortia, { margin: true });
