import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { Radio } from '../../../Radio';
import { Checkbox } from '../../../Checkbox';

import styles from './FormRoleInput.module.scss';

export const FormRoleInput = ({
  id,
  label,
  name,
  roles,
  rules,
  multiple,
  defaultValue,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  value,
  ...rest
}) => {
  const { register } = useFormContext();

  return (
    <div
      role={multiple ? 'group' : 'radiogroup'}
      aria-labelledby={id}
      aria-label={label}
      className={styles.FormRoleInput}
    >
      {roles.map((role) => {
        return (
          <React.Fragment key={role.id}>
            {multiple ? (
              <Checkbox
                {...rest}
                border
                required={false}
                label={role.name}
                description={role.description}
                value={String(role.id)}
                defaultChecked={defaultValue ? defaultValue.includes(String(role.id)) : undefined}
                {...register(name, rules)}
              />
            ) : (
              <Radio
                {...rest}
                border
                label={role.name}
                description={role.description}
                value={String(role.id)}
                defaultChecked={defaultValue ? defaultValue.includes(String(role.id)) : undefined}
                {...register(name, rules)}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

FormRoleInput.propTypes = {
  label: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired).isRequired,
  name: PropTypes.string,
  rules: PropTypes.object.isRequired,
  multiple: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ])
};
