import { ReduxModule } from 'react-pages';

import {
  getAccessTokenExpiresAtUpdatedState,
  getAuthenticationStartState,
  getLogInStartState,
  getLogInSuccessState,
  getNotAuthenticatedState,
  onAccessTokenReceived
} from '@acadeum/auth';
import type { UserProfile } from '@acadeum/types';

import getAuthCookieConfig from '../helpers/getAuthCookieConfig.js';

declare module '@acadeum/auth' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface CurrentUser extends UserProfile {
  }
}

// `ReduxModule` event namespace "AUTH" is used when listening to
// Redux events via `redux.on(namespace, event)`.
const redux = new ReduxModule('AUTH');

export const authenticate = redux.action(
  // The Redux action name is required here
  // so that the corresponding Redux events would be called:
  // * `AUTHENTICATE_PENDING`
  // * `AUTHENTICATE_ERROR`
  // * `AUTHENTICATE_SUCCESS` (not used)
  // These event names are used later in `redux.on()` event listeners.
  'AUTHENTICATE',
  (accessToken) => async http => {
    const { user } = await http.get('/authenticate', {}, {
      authentication: accessToken
    });
    return user;
  }
);

function createSignInAction({ as }) {
  return redux.action(
    // Redux action name `SIGN_IN` is required here because somewhere below
    // are listeners for the following Redux events:
    // * `SIGN_IN_PENDING`
    // * `SIGN_IN_ERROR`
    // * `SIGN_IN_SUCCESS` (not used)
    //
    // At the same time, the app should ignore `SIGN_IN_AS_...` events.
    //
    'SIGN_IN' + (as ? '_AS' : ''),
    (parameters) => async http => {
      const {
        user,
        token,
        expiresIn,
        expiresAt
      } = await http.post(as ? '/sign-in-as?setCookies=✓' : '/sign-in', parameters);

      onAccessTokenReceived(token, {
        expiresIn,
        expiresAt
      }, {
        authCookieConfig: getAuthCookieConfig()
      });

      // If cookie-based authentication is used, the access token cookies
      // have been set at this point, so no need to update Redux state,
      // and the app can just refresh the page.
      //
      // In other case, if local storage is used for storing the access token,
      // the token should be updated in the local storage, and then the app
      // can just refresh the page, so no need to update Redux state in this case either.
      //
      if (as) {
        return null;
      }

      return {
        user,
        accessToken: token,
        accessTokenExpiresIn: expiresIn,
        accessTokenExpiresAt: expiresAt
      };
    },
    (state, result) => {
      if (result === null) {
        // If it's a "Log In as" action result
        // then there's no need to update Redux state
        // because the page will be reloaded anyway.
        return state;
      }
      return getLogInSuccessState(result);
    }
  );
}

export const signIn = createSignInAction({ as: false });
export const signInAs = createSignInAction({ as: true });

export const signOutClearCookie = redux.action(
  () => async (http) => {
    // `user` becomes `undefined` after `NOT AUTHENTICATED` action is `dispatched()`,
    // so any `user`-dependent `redirectUrl` should be pre-calculated before it.
    await http.delete('/authentication/cookie');
  }
);

export const signOutClearState = redux.simpleAction(
  () => {
    return getNotAuthenticatedState();
  }
);

export const refreshAccessToken = redux.action(
  ({ expiresIn: shouldExpireIn }) => async (http) => {
    const {
      token,
      expiresIn,
      expiresAt
    } = await http.get('/refresh-access-token', {
      expiresIn: shouldExpireIn
    });
    return {
      token,
      expiresIn,
      expiresAt
    };
  }
);

export const setUser = redux.simpleAction(
  (state, user) => ({
    ...state,
    user
  })
);

export const setAccessTokenExpiresAt = redux.simpleAction(
  (state, accessTokenExpiresAt) => ({
    ...state,
    accessTokenExpiresAt
  })
);

export const setIsAuthenticationLoading = redux.simpleAction(
  (state, isAuthenticationLoading) => ({
    ...state,
    isAuthenticationLoading
  })
);

redux.on('AUTH: AUTHENTICATE_PENDING', getAuthenticationStartState);
redux.on('AUTH: SIGN_IN_PENDING', getLogInStartState);

redux.on('AUTH: AUTHENTICATE_ERROR', getNotAuthenticatedState);
redux.on('AUTH: SIGN_IN_ERROR', getNotAuthenticatedState);

redux.on('SET ACCESS TOKEN EXPIRES AT', (state, action) => getAccessTokenExpiresAtUpdatedState(state, action.payload));

export default redux.reducer(getAuthenticationStartState());
