import React from 'react';

import { BaseButton as Button } from '@acadeum/ui';

import InstantSearchBackToTopArrow from './InstantSearchBackToTopArrow.svg';

import './InstantSearchBackToTop.sass';

export default function InstantSearchBackToTop({
  scrollToTopAnchorClassName,
  scrollToTopAnchorMarginTop
}) {
  return (
    <Button
      className="InstantSearchBackToTop"
      onClick={() => {
        scrollTo(scrollToTopAnchorClassName, {
          topOffset: scrollToTopAnchorClassName === 'InstantSearch'
            ? -1 * ((scrollToTopAnchorMarginTop || 0) + 12)
            : scrollToTopAnchorMarginTop
        });
      }}
    >
      Back To Top
      <InstantSearchBackToTopArrow/>
    </Button>
  );
}

export const scrollTo = (className, { fallbackClassName = null, topOffset = 0 } = {}) => {
  const targetElement = document.querySelector(`.${className}`);
  const fallbackTargetElement = document.querySelector(`.${fallbackClassName}`);

  const element = targetElement || fallbackTargetElement;

  const top = element ? (element.getBoundingClientRect().top + window.pageYOffset) + topOffset : topOffset;

  window.scrollTo({
    behavior: 'smooth',
    top
  });
};
