import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useTranslate } from '@acadeum/translate';

import Row from '../../components/Row';
import Col from '../../components/Col';
import SignUpForm from '../../components/SignUp';
import Section from '../../components/Section';

import useLocation from '../../hooks/useLocation';

import actions from '../../actions';

const {
  redirect
} = actions;

export default function SignUp() {
  const location = useLocation();
  const t = useTranslate('SignUp');

  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    if (user) {
      redirect('/');
    }
  }, [user]);

  return (
    <Section title={t('title')}>
      <Row>
        <Col col={12} sm={8} md={6} lg={6}>
          <SignUpForm
            singleSignOn={location.query.singleSignOn ? true : false}
            firstName={location.query.firstName}
            lastName={location.query.lastName}
            institutionId={+location.query.institutionId || undefined}
            email={location.query.email}
          />
        </Col>
      </Row>
    </Section>
  );
}

SignUp.propTypes = {
  location: PropTypes.object
};

SignUp.meta = () => ({
  title: 'Sign Up'
});
