import { useDispatch } from 'react-redux';

import { rtkApi } from './rtkApi';

interface GetNotificationsOutput {
  newEnrollmentRequestsCount: number;
  newStudentRequestsCount: number;
  awaitingGradesCount: number;
}

const notificationsApi = rtkApi
  .enhanceEndpoints({ addTagTypes: ['notifications'] })
  .injectEndpoints({
    endpoints: build => ({
      getNotifications: build.query<GetNotificationsOutput, null>({
        query: () => '/notifications',
        providesTags: ['notifications']
      })
    })
  });

export const {
  useGetNotificationsQuery
} = notificationsApi;

export const useNotifications = (): GetNotificationsOutput | undefined => {
  const state = notificationsApi.endpoints.getNotifications.useQueryState(null);
  return state.data;
};

export const useRefreshNotifications = () => {
  const dispatch = useDispatch();
  return () => {
    dispatch(notificationsApi.util.invalidateTags(['notifications']));
  };
};
