import type { MutableRefObject } from 'react';
import React, { forwardRef, useState } from 'react';
import classNames from 'classnames';

import { useForwardedRef, useUpdateEffect } from '@acadeum/hooks';
import { parseCurrencyInput } from '@acadeum/helpers';

import type { InputProps } from '../Input';
import { Input } from '../Input';

import styles from './CurrencyInput.module.scss';
import PropTypes from 'prop-types';

type Currency = 'USD';

export interface CurrencyInputProps extends Omit<InputProps, 'onChange' | 'onBlur' | 'value' | 'defaultValue'> {
  defaultValue?: string | number;
  onChange?: (value: number | null) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>, value: number | null) => void;
  currency: Currency;
  value?: string | number;
}

const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(({
  value: propsValue,
  className,
  onChange: propsOnChange,
  onBlur: propsOnBlur,
  name,
  currency,
  ...rest
}, ref) => {
  const { setRef, internalRef } = useForwardedRef<MutableRefObject<HTMLInputElement>>(ref);

  const [value, setValue] = useState(() => {
    const isFocused = document.activeElement === internalRef.current;
    return formatCurrency(propsValue, !isFocused);
  });

  useUpdateEffect(() => {
    const isFocused = document.activeElement === internalRef.current;
    const newValue = formatCurrency(propsValue, !isFocused);
    setValue(prevState => {
      if (value !== newValue) {
        return newValue;
      }
      return prevState;
    });
    // Update only if external value changed
  }, [propsValue]);

  const onChange = () => {
    const value = formatCurrencyInput(internalRef);
    setValue(value);
    propsOnChange?.(value ? parseCurrency(value) : null);
  };

  const onBlur = (event) => {
    const value = formatCurrencyInput(internalRef, 'blur');
    setValue(value);
    const newValue = value ? parseCurrency(value) : null;
    propsOnChange?.(newValue);
    propsOnBlur?.(event, newValue);
  };

  return (
    <Input
      {...rest}
      ref={setRef}
      type="text"
      name={name}
      className={classNames(className, styles.CurrencyInput, getCurrencySymbolClassName(currency))}
      onBlur={onBlur}
      onChange={onChange}
      // defaultValue={formatCurrency(defaultValue)}
      value={value}
    />
  );
});

CurrencyInput.propTypes = {
  currency: PropTypes.oneOf<Currency>(['USD']).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export { CurrencyInput };

function getCurrencySymbolClassName(currency: Currency) {
  switch (currency) {
    case 'USD':
      return [styles['CurrencyInput--USD']];
    default:
      throw new Error(`Unsupported currency: ${currency}`);
  }
}

export function parseCurrency(value: string): number {
  value = value.replace(/,/g, ''); // remove comma
  return parseCurrencyInput(value); // string to number
}

export function formatCurrency(
  value?: string | number,
  addCentsOnEnd?: boolean
): string {
  if (typeof value === 'number') {
    value = String(value);
  } else if (!value) {
    return '';
  }

  // format number 1234567 to 1,234,567
  const formatNumber = (v) => v.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (value.includes('.')) {
    // eslint-disable-next-line prefer-const
    let [leftSide, rightSide] = value.split('.').map(text => formatNumber(text));

    // Add end cents
    if (addCentsOnEnd) {
      rightSide += '00';
    }

    rightSide = rightSide.slice(0, 2);

    return `${leftSide}.${rightSide}`;
  }

  return `${formatNumber(value)}${addCentsOnEnd ? '.00' : ''}`;
}

function formatCurrencyInput(
  inputRef: MutableRefObject<HTMLInputElement>,
  blur?: 'blur'
): string {
  const input = inputRef.current;

  if (input.value === '') {
    return '';
  }

  const originalLength = input.value.length;
  const caretPosition = input.selectionStart as number;

  // Update input value
  input.value = formatCurrency(input.value, blur === 'blur');

  const updatedCaretPosition = input.value.length - originalLength + caretPosition;
  input.setSelectionRange(updatedCaretPosition, updatedCaretPosition);

  return input.value;
}
