import { rtkApi } from '../api/rtkApi';

// reducer name(`api`) must be the same as `reducerPath` in `rtkApi.tsx`
export const api = rtkApi.reducer;

export { default as advisorRecommendations } from './advisorRecommendations';
export { default as auth } from './auth';
export { default as authenticationSettings } from './authenticationSettings';
export { default as contacts } from './contacts';
export { default as courses } from './courses';
export { default as courseApproval } from './courseApproval';
export { default as courseSharingGroups } from './courseSharingGroups';
export { default as courseSubstitutes } from './courseSubstitutes';
export { default as uploadApprovals } from './uploadApprovals';
export { default as uploadCourses } from './uploadCourses';
export { default as uploadStudents } from './uploadStudents';
export { default as notifications } from './notifications';
export { default as app } from './app';
export { default as grades } from './grades';
export { default as financialSettings } from './financialSettings';
export { default as hubspot } from './hubspot';
export { default as students } from './students';
export { default as institutions } from './institutions';
export { default as consortia } from './consortia';
export { default as sections } from './sections';
export { default as sessions } from './sessions';
export { default as studentRequests } from './studentRequests';
export { default as studentEnrollments } from './studentEnrollments';
export { default as enroll } from './enroll';
export { default as enrollmentRequests } from './enrollmentRequests';
export { default as enrollmentRequestsTM } from './enrollmentRequestsTM';
export { default as coursePricing } from './coursePricing';
export { default as notificationModal } from './notificationModal';
export { default as users } from './users';
export { default as userAccountRequests } from './userAccountRequests';
export { default as usersAdmin } from './usersAdmin';
export { default as studentUsers } from './studentUsers';
export { default as studentUsersAdmin } from './studentUsersAdmin';
export { default as invoices } from './invoices';
export { default as payments } from './payments';
export { default as paymentSources } from './paymentSources';
export { default as charge } from './charge';
export { default as analytics } from './analytics';
export { default as viz } from './viz';
export { default as reports } from './reports';
export { default as dashboard } from './dashboard';
export { default as stripe } from './stripe';
export { default as passwordReset } from './passwordReset';
export { default as signInModal } from './signInModal';
export { default as searchIndexUpdates } from './searchIndexUpdates';
