import React from 'react';
import classNames from 'classnames';

import styles from '../List.module.scss';

interface ListItemProps {
  children: React.ReactNode;
  className?: string;
}

export const Item: React.FC<ListItemProps> = ({ children, className }) => {
  return (
    <li className={classNames(styles.Item, className)}>
      {children}
    </li>
  );
};
