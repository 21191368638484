import React from 'react';

import { BaseButton } from '../BaseButton';

import styles from './HamburgerButton.module.scss';

export interface HamburgerButtonProps {
  expanded?: boolean;
  onClick?: () => void;
}

export const HamburgerButton = React.forwardRef<HTMLButtonElement, HamburgerButtonProps>(({
  expanded,
  onClick
}, ref) => {
  return (
    <BaseButton
      ref={ref}
      aria-label="Menu"
      aria-expanded={expanded}
      onClick={onClick}
      className={styles.HamburgerButton}
    >
      <span/>
      <span/>
      <span/>
      <span/>
    </BaseButton>
  );
});
