import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DropFileUpload } from 'react-responsive-ui';
import classNames from 'classnames';

import './FileUpload.sass';

import actions from '../../actions';

const {
  notifyError
} = actions;

let FileUpload = ({
  description,
  required,
  ext,
  onChange,
  className,
  ...rest
}, ref) => {
  const ownRef = useRef();
  ref = ref || ownRef;

  const onChooseFile = useCallback(() => {
    ref.current.click();
  }, []);

  const [file, setFile] = useState();

  const onFileChosen = useCallback((file, { isAccepted }) => {
    if (isAccepted) {
      setFile(file);
      if (onChange) {
        onChange(file);
      }
    } else if (ext) {
      notifyError(`Only the following file types are accepted: ${toArray(ext).join(', ')}`);
    } else {
      notifyError('File not accepted');
    }
  }, []);

  return (
    <div className={classNames(className, 'FileUpload')}>
      <DropFileUpload
        ref={ref}
        className="FileUploadFile"
        required={required}
        ext={ext}
        onChange={onFileChosen}
        {...rest}>
        {file &&
          <span className="FileUploadFileName">
            {file.name}
          </span>
        }
        {!file &&
          <div style={{ textAlign: 'center' }}>
            Drop File Here{required ? ' *' : ''}
            {description}
          </div>
        }
      </DropFileUpload>
      <button
        type="button"
        onClick={onChooseFile}
        className="rrui__button-reset FileUploadButton">
        Upload From Files
      </button>
    </div>
  );
};

FileUpload = React.forwardRef(FileUpload);

FileUpload.propTypes = {
  required: PropTypes.bool,
  description: PropTypes.node,
  ext: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  onChange: PropTypes.func,
  className: PropTypes.string
};

export default FileUpload;

function toArray(any) {
  return Array.isArray(any) ? any : [any];
}
