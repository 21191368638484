import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import PhoneInput_ from 'react-phone-number-input/react-hook-form-input';
import { isSupportedCountry } from 'react-phone-number-input/input';

export const FormPhoneInput = ({
  name,
  rules,
  defaultCountry = 'US',
  inputComponent: Input,
  ...rest
}) => {
  const { control } = useFormContext();

  return (
    <PhoneInput_
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      inputComponent={Input}
      defaultCountry={isSupportedCountry(defaultCountry) ? defaultCountry : undefined}
      useNationalFormatForDefaultCountryValue
      name={name}
      control={control}
      rules={rules}
      {...rest}
      type="phone"
    />
  );
};

FormPhoneInput.propTypes = {
  defaultCountry: PropTypes.oneOf(['US', 'CA']),
  name: PropTypes.string,
  rules: PropTypes.shape({
    required: PropTypes.string,
    validate: PropTypes.func
  })
};
