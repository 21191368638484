import { SCOPES } from './consts';

export function getScopeIndex(scope) {
  if (scope === null) {
    return Number.MAX_SAFE_INTEGER;
  }
  const index = SCOPES.indexOf(scope);
  if (index < 0) {
    throw new Error(`Scope not found: "${scope}"`);
  }
  return index;
}
