import { useFormContext } from 'react-hook-form';

import { useUpdateEffect } from '@acadeum/hooks';

// https://github.com/react-hook-form/react-hook-form/discussions/2993

export function useRHFResetListener(callback) {
  const formContext = useFormContext();
  if (!formContext) {
    return;
  }

  const {
    formState: { isDirty, isSubmitting }
  } = formContext;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useUpdateEffect(() => {
    if (!isSubmitting && !isDirty) {
      callback();
    }
  }, [isDirty]);
}
