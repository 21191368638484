import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Scrollable.module.scss';

export interface ScrollableProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  variant?: 'grey' | 'black';
  paddingRight?: number | string;
}

export const BLACK_SCROLLBAR_WIDTH = '6px';
export const GREY_SCROLLBAR_WIDTH = '12px';

export const Scrollable = React.forwardRef<HTMLDivElement, ScrollableProps>(({
  className,
  variant = 'grey',
  paddingRight,
  style,
  ...rest
}, ref) => {
  const scrollbarWidth = variant === 'grey' ? GREY_SCROLLBAR_WIDTH : BLACK_SCROLLBAR_WIDTH;

  return (
    <div
      {...rest}
      ref={ref}
      className={classNames(className, styles.Scrollable, styles[variant])}
      style={{
        ...style,
        ...{ paddingRight: paddingRight ? `calc(${paddingRight} - ${scrollbarWidth})` : undefined }
      }}
    />
  );
});

Scrollable.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['grey', 'black'])
};
