import type { ButtonHTMLAttributes } from 'react';
import React, { memo } from 'react';
import classNames from 'classnames';

import { ChevronDownIcon, ChevronUpIcon } from '@acadeum/icons';
import { BaseButton } from '../../../BaseButton';
import { Icon } from '../../../Icon';

import styles from './ExpandButton.module.scss';

export interface ExpandButtonProps extends Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  isExpanded: boolean;
}

export const ExpandButton = memo<ExpandButtonProps>(({
  isExpanded,
  onClick
}) => {
  return (
    <BaseButton
      aria-label={isExpanded ? 'Collapse nested rows' : 'Expand nested rows'}
      title={isExpanded ? 'Collapse nested rows' : 'Expand nested rows'}
      onClick={onClick}
      className={classNames(styles.root)}
    >
      <Icon
        size="md"
        icon={isExpanded ? ChevronUpIcon : ChevronDownIcon}
      />
    </BaseButton>
  );
});
