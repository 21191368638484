import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import StudentForm from '../../components/StudentForm';
import Section from '../../components/Section';

import type { ReduxState } from '../../helpers/app.types';
import useLocation from '../../hooks/useLocation';

import actions from '../../actions';

const {
  notify,
  addStudent,
  updateEnrollmentState,
  goto
} = actions;

const CreateStudent = () => {
  const { query } = useLocation();
  const { from } = query;
  const valuesString = query.values;
  const values = valuesString ? JSON.parse(valuesString) : null;

  const enrollmentState = useSelector((state: ReduxState) => state.enroll.enrollmentState);
  const { advisorRecommendationCreationState } = useSelector((state: ReduxState) => state.advisorRecommendations);

  let returnToLocation;
  if (from === 'enrollment' && enrollmentState) {
    returnToLocation = `/sections/${enrollmentState.sectionId}/enroll`;
  } else if (from === 'advisorRecommendation') {
    returnToLocation = `/courses/${advisorRecommendationCreationState.courseId}/recommend`;
  } else {
    returnToLocation = '/students';
  }

  const onSubmit = async (values) => {
    const student = await addStudent(values);
    const addedStudent = {
      id: student.id,
      firstName: student.firstName,
      lastName: student.lastName,
      hiStudentId: student.hiStudentId,
      email: student.email
    };

    if (from) {
      if (from === 'enrollment' && enrollmentState) {
        const { students = [] } = enrollmentState;
        updateEnrollmentState({
          students: students.concat(addedStudent)
        });
      }
      notify('Student successfully added and marked as selected.');
      goto(returnToLocation);
    } else {
      notify('Student successfully added!');
      goto(returnToLocation);
    }
  };

  return (
    <Section title="Add a Student">
      <StudentForm
        values={values}
        cancelLocation={returnToLocation}
        submitText="Add Student"
        onSubmit={onSubmit}
      />
    </Section>
  );
};

CreateStudent.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      from: PropTypes.oneOf(['enrollment', 'advisorRecommendation']),
      values: PropTypes.string
    }).isRequired
  }),
  enrollmentState: PropTypes.shape({
    sectionId: PropTypes.number.isRequired,
    students: PropTypes.arrayOf(PropTypes.object)
  })
};

CreateStudent.meta = () => ({
  title: 'Add a Student'
});

CreateStudent.breadcrumbs = (state, {
  location: {
    query: {
      from
    }
  }
}) => {
  const { enrollmentState } = state.enroll;
  if (from === 'enrollment' && enrollmentState) {
    const { sectionId } = enrollmentState;
    return [
      ['Section', `/sections/${sectionId}`],
      ['Course Enrollment', `/sections/${sectionId}/enroll`],
      ['Add Students', `/sections/${sectionId}/enroll`],
      'New Student'
    ];
  } else {
    return [
      ['Students', '/students'],
      'Add a Student'
    ];
  }
};

export default CreateStudent;
