import type React from 'react';
import { useCallback, useEffect, useRef } from 'react';

export interface OnTapOutsideProps {
  children: JSX.Element;
  containerRef: React.RefObject<HTMLElement>;
  toggleRef: React.RefObject<HTMLElement>;
  onTapOutside?: (event: TouchEvent) => void;
  moveThreshold?: number;
}

export const OnTapOutside: React.FC<OnTapOutsideProps> = ({
  children,
  containerRef,
  toggleRef,
  onTapOutside,
  moveThreshold = 5
}) => {
  const initialTouchX = useRef<number>();
  const initialTouchY = useRef<number>();
  const touchId = useRef();
  const tapping = useRef(false);

  // On mobile devices "blur" event isn't triggered
  // when a user taps outside. This is to allow touch scrolling
  // while not losing focus on an input field or a button.
  // Adding a manual "on click" listener to emulate
  // "on blur" event when user taps outside (to collapse the expandable).
  const onTap = useCallback((event: TouchEvent, target) => {
    const containerNode = containerRef.current;
    const toggleNode = toggleRef.current;

    if (containerNode?.contains(target)) {
      return;
    }
    if (toggleNode && toggleNode.contains(target)) {
      return;
    }

    if (typeof onTapOutside === 'function') {
      onTapOutside(event);
    }
  }, [containerRef, onTapOutside, toggleRef]);

  const onTouchCancel = useCallback(() => {
    initialTouchX.current = undefined;
    initialTouchY.current = undefined;
    touchId.current = undefined;
    tapping.current = false;
  }, []);

  const onTouchStart = useCallback((event) => {
    // Ignore multi touch.
    if (event.touches.length > 1) {
      // Reset.
      return onTouchCancel();
    }
    const touch = event.changedTouches[0];
    initialTouchX.current = touch.clientX;
    initialTouchY.current = touch.clientY;
    touchId.current = touch.identifier;
    tapping.current = true;
  }, [onTouchCancel]);

  const onTouchMove = useCallback((event) => {
    if (tapping.current) {
      return;
    }

    let x;
    let y;
    for (const touch of event.changedTouches) {
      if (touch.identifier === touchId.current) {
        x = touch.clientX;
        y = touch.clientY;
        break;
      }
    }

    // If not the touch.
    if (x === undefined) {
      return;
    }

    const deltaX = Math.abs(x - (initialTouchX.current as number));
    const deltaY = Math.abs(y - (initialTouchY.current as number));

    // Reset on touch move.
    if (deltaX > moveThreshold || deltaY > moveThreshold) {
      onTouchCancel();
    }
  }, [moveThreshold, onTouchCancel]);

  const onTouchEnd = useCallback((event: TouchEvent) => {
    if (!tapping.current) {
      return;
    }

    for (const touch of event.changedTouches) {
      if (touch.identifier === touchId.current) {
        // Reset.
        onTouchCancel();
        // Handle the tap.
        // https://developer.mozilla.org/en-US/docs/Web/API/Touch
        onTap(event, touch.target);
        break;
      }
    }
  }, [onTap, onTouchCancel]);

  useEffect(() => {
    document.addEventListener('touchstart', onTouchStart);
    document.addEventListener('touchmove', onTouchMove);
    document.addEventListener('touchend', onTouchEnd);
    document.addEventListener('touchcancel', onTouchCancel);

    return () => {
      document.removeEventListener('touchstart', onTouchStart);
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('touchend', onTouchEnd);
      document.removeEventListener('touchcancel', onTouchCancel);
    };
  }, [onTapOutside, onTouchCancel, onTouchEnd, onTouchMove, onTouchStart]);

  return children;
};
