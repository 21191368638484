import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Title.module.scss';

type FontFamily = 'base' | 'add' | 'mono';

export interface TitleProps extends React.HTMLAttributes<HTMLTitleElement> {
  level?: 1 | 2 | 3;
  ellipsis?: boolean;
  marginBottom?: 'none' | 'small';
  fontFamily?: FontFamily;
}

export const Title: React.FC<TitleProps> = ({
  level = 1,
  ellipsis,
  marginBottom,
  className,
  fontFamily = 'base',
  ...rest
}) => {
  return React.createElement(`h${level}`, {
    ...rest,
    className: classNames(className, styles.Title, styles[`Title__h${level}`], styles[`fontFamily-${fontFamily}`], {
      [styles['Title--marginBottomNone']]: marginBottom === 'none',
      [styles['Title--marginBottomSmall']]: marginBottom === 'small',
      [styles.ellipsis]: ellipsis
    })
  });
};

Title.propTypes = {
  level: PropTypes.oneOf([1, 2, 3]),
  ellipsis: PropTypes.bool,
  marginBottom: PropTypes.oneOf(['none', 'small']),
  fontFamily: PropTypes.oneOf(['base', 'add', 'mono'])
};
