// Returns HubSpot cookie value.
//
// "To retrieve the HubSpot tracking cookie (hutk) parameter using JavaScript,
//  you can use the document.cookie method with RegEx to retrieve that particular cookie:"
// https://www.stephanieogaygarcia.com/hubspot-website-development/get-hubspot-cookie-hubspotutk-using-javascript
//
export default function getHubSpotCookieValue() {
  // eslint-disable-next-line
  const value = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, '$1');
  // `value` is an empty string when there's no HubSpot cookie.
  if (value) {
    return value;
  }
}
