import { createContext } from 'react';

export interface ChoiceListListboxOptionContextProps {
  multiple?: boolean;
}

export const ChoiceListListboxOptionContext = createContext<ChoiceListListboxOptionContextProps>({});

export interface ChoiceListListboxContextProps {
  listboxId: string | undefined;
  setListboxId: (listboxId: string | undefined) => void;
  textFieldFocused?: boolean;
  setActiveOptionId: (domId: string) => void;
}

export const ChoiceListListboxContext = createContext<ChoiceListListboxContextProps>({} as ChoiceListListboxContextProps);
