import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { StaticMap } from 'react-map-gl';
import DeckGL from 'deck.gl';
import { ArcLayer } from '@deck.gl/layers';
import * as d3 from 'd3';

import Section from '../../components/Section';

import 'mapbox-gl/dist/mapbox-gl.css';

import actions from '../../actions';

const { fetchMapData } = actions;

export default function Map({
  loopLength = 1800,
  animationSpeed = 30,
  viewState,
  controller = true,
  baseMap = true
}) {
  const mapData = useSelector(state => state.reports.mapData);

  //eslint-disable-next-line
  const [time, setTime] = useState(0);
  const animationFrame = useRef();

  useEffect(() => {
    // animate();
    return () => {
      window.cancelAnimationFrame(animationFrame.current);
    };
  }, []);

  const animate = useCallback(() => {
    const timestamp = Date.now() / 1000;
    const loopTime = loopLength / animationSpeed;
    setTime(((timestamp % loopTime) / loopTime) * loopLength);
    animationFrame.current = window.requestAnimationFrame(animate);
  }, [
    setTime,
    loopLength,
    animationSpeed
  ]);

  const layers = useMemo(() => {
    if (!mapData) {
      return;
    }

    const data = mapData.map(_ => ({
      to: [_.homeInstitutionLongitude, _.homeInstitutionLatitude],
      from: [_.teachingInstitutionLongitude, _.teachingInstitutionLatitude],
      count: _.count
    }));

    const sizes = mapData.map(_ => _.count);

    const logScale = d3
      .scaleLog()
      .domain([d3.min(sizes), d3.max(sizes)])
      .range([1, 255]);

    return [
      new ArcLayer({
        id: 'arc-layer',
        data,
        pickable: true,
        getWidth: () => 1,
        getHeight: () => 1,
        getSourcePosition: _ => _.from,
        getTargetPosition: _ => _.to,
        getSourceColor: _ => [0, 140, 255, logScale(_.count)],
        getTargetColor: _ => [0, 255, 140, logScale(_.count)],
        coef: 1,
        strokeWidth: 1
        // onHover: ({ object, x, y }) => {
        //   // const tooltip = `${object.from} to ${object.to}`;
        //   /* Update tooltip
        //      http://deck.gl/#/documentation/developer-guide/adding-interactivity?section=example-display-a-tooltip-for-hovered-object
        //   */
        // }
      })
    ];
  }, [mapData]);

  return (
    <Section
      title="Course Enrollments Map"
      description="The arcs are course enrollments between institutions: from Home Institution (green) to Teaching Institution (blue). The more prominent the arc colors, the more enrollments are there.">

      <br/>

      <div style={FULL_SIZE_STYLE}>
        <DeckGL
          layers={layers}
          initialViewState={INITIAL_VIEW_STATE}
          viewState={viewState}
          controller={controller}
        >
          {baseMap &&
            <StaticMap
              reuseMaps
              mapStyle="mapbox://styles/mapbox/dark-v8"
              preventStyleDiffing
              mapboxApiAccessToken={MAPBOX_TOKEN}
            />
          }
        </DeckGL>
      </div>
    </Section>
  );
}

Map.propTypes = {
  mapData: PropTypes.arrayOf(PropTypes.object),
  // https://github.com/uber/deck.gl/blob/master/docs/api-reference/view-state-transitions.md
  viewState: PropTypes.object,
  controller: PropTypes.bool,
  baseMap: PropTypes.bool,
  loopLength: PropTypes.number,
  animationSpeed: PropTypes.number
};

Map.meta = () => ({
  title: 'Course Enrollments Map'
});

Map.load = async () => {
  await fetchMapData();
};

Map.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Course Enrollments Map'
];

const FULL_SIZE_STYLE = {
  width: '100%',
  height: '100vh',
  position: 'relative'
};

// Set your mapbox token here
const MAPBOX_TOKEN = 'pk.eyJ1Ijoib3NlaWJvbnN1IiwiYSI6ImNqemFoc2s3cDAwajIzZWxpNnQyYWh1ODQifQ.9w3z3IQuVX4Pi5JJRJpfsQ'; // eslint-disable-line

// const LIGHT_SETTINGS = {
//   lightsPosition: [-74.05, 40.7, 8000, -73.5, 41, 5000],
//   ambientRatio: 0.05,
//   diffuseRatio: 0.6,
//   specularRatio: 0.8,
//   lightsStrength: [2.0, 0.0, 0.0, 0.0],
//   numberOfLights: 2
// };

const INITIAL_VIEW_STATE = {
  longitude: -97.74306,
  latitude: 30.26715,
  zoom: 5,
  maxZoom: 16,
  pitch: 45,
  bearing: 0
};
