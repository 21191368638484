import React, { useId } from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from '@acadeum/translate';
import { ScreenReaderOnly } from '@acadeum/core-ui';

import InstantSearchToggle from '../InstantSearch/InstantSearchToggle';
import InstantSearchMultiSelectList from '../InstantSearch/InstantSearchMultiSelectList';
import InstantSearchRange from '../InstantSearch/InstantSearchRange';
import InstantSearchDateRange from '../InstantSearch/InstantSearchDateRange';
import InstantSearchExcludePast from '../InstantSearch/InstantSearchExcludePast';
import InstantSearchMultiSelectListDropdown from '../InstantSearch/InstantSearchMultiSelectListDropdown';
import DropdownContainer from '../InstantSearch/DropdownContainer';

function SearchFilterItem({
  type,
  title,
  searchable,
  className,
  ...rest
}) {
  const t = useTranslate('SearchFilterItem');
  const descriptionElementId = `FilterDescription-${useId()}`;

  let Component;

  switch (type) {
    case 'range':
      Component = InstantSearchRange;
      break;
    case 'multiSelectList':
      Component = InstantSearchMultiSelectList;
      break;
    case 'multiSelectListDropdown':
      Component = InstantSearchMultiSelectListDropdown;
      break;
    case 'dateRange':
      Component = InstantSearchDateRange;
      break;
    case 'dateRangeDropdown':
      Component = DropdownContainer;
      break;
    case 'toggle':
      Component = InstantSearchToggle;
      break;
    case 'excludePast':
      Component = InstantSearchExcludePast;
      break;
    default:
      throw new Error(`Unknown course search filter type: ${type}`);
  }

  return (
    <>
      <Component
        className={className}
        searchable={searchable}
        title={title}
        aria-label={title ? t('search', { subject: title }) : undefined}
        aria-describedby={searchable && descriptionElementId}
        {...rest}
      />
      {searchable && (
        <ScreenReaderOnly id={descriptionElementId} as="span">
          {t('searchDescription')}
        </ScreenReaderOnly>
      )}
    </>
  );
}

export default SearchFilterItem;

SearchFilterItem.propTypes = {
  type: PropTypes.oneOf([
    'dateRange',
    'dateRangeDropdown',
    'multiSelectList',
    'multiSelectListDropdown',
    'range',
    'toggle',
    'excludePast'
  ]).isRequired,
  title: PropTypes.string.isRequired,
  searchable: PropTypes.bool
};
