import defaultOnDemandCourseDropDateDaysAfterStartDate from 'common-lib/constants/onDemandCourseDropDateDaysAfterStartDate.json';
import defaultOnDemandCourseEndDateDaysAfterStartDate from 'common-lib/constants/onDemandCourseEndDateDaysAfterStartDate.json';

export function getLastDropDateMessageForOnDemandCourseEnrollment(onDemandCourseDropDateDaysAfterStartDate?: number) {
  const days = onDemandCourseDropDateDaysAfterStartDate ?? defaultOnDemandCourseDropDateDaysAfterStartDate;
  return `${days} day${days === 1 ? '' : 's'} from the day of enrollment`;
}

export function getEndDateMessageForOnDemandCourseEnrollment(onDemandCourseEndDateDaysAfterStartDate?: number) {
  const days = onDemandCourseEndDateDaysAfterStartDate ?? defaultOnDemandCourseEndDateDaysAfterStartDate;
  return `${days} day${days === 1 ? '' : 's'} from the day of enrollment`;
}
