import React, { useMemo } from 'react';

import { useTranslate } from '@acadeum/translate';
import { Tabs } from '@acadeum/ui';

import { loadEnrollmentsPage } from '../../helpers/routes';

import actions from '../../actions';

import { HomeEnrollments } from './ui/HomeEnrollments';
import { TeachingEnrollments } from './ui/TeachingEnrollments';
import { useSelector } from 'react-redux';
import type { ReduxState } from '../../helpers/app.types';
import { userHasPermission } from '@acadeum/helpers';

const { goto } = actions;

interface EnrollmentsProps {
  type?: 'teaching' | 'home';
  status?: 'live' | 'finalized' | 'accepted';
}

export default function Enrollments({
  type = 'home',
  status = 'live'
}: EnrollmentsProps) {
  const t = useTranslate('Enrollments');
  const user = useSelector((state: ReduxState) => state.auth.user);

  const tabs = useMemo(() => [
    ...(userHasPermission(user, 'homeCourseEnrollment:read', { orgId: user.institution.id }) ? [
      {
        title: t('homeEnrollments'),
        key: 'home',
        body: <HomeEnrollments status={status === 'accepted' ? 'live' : status}/>
      }
    ] : []),
    ...(user.institution.teaching && userHasPermission(user, 'teachingCourseEnrollment:read', { orgId: user.institution.id }) ? [
      {
        title: t('teachingEnrollments'),
        key: 'teaching',
        body: <TeachingEnrollments status={status === 'live' ? 'accepted' : status}/>
      }
    ] : [])
  ], [user]);

  return (
    <Tabs
      keepMounted={false}
      title={t('title')}
      onChangeTab={({ key }) => goto(`/enrollments${key === 'teaching' ? '/teaching/accepted' : '/live'}`)}
      initialActiveTab={type}
      tabs={tabs}
    />
  );
}

Enrollments.meta = () => ({
  title: 'Manage Enrollments'
});

Enrollments.load = async ({ user }) => {
  await loadEnrollmentsPage({ user });
};

Enrollments.breadcrumbs = () => [
  'Manage Enrollments'
];
