import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replaceLocation } from 'react-pages';

import { getErrorData, userHasPermission } from '@acadeum/helpers';
import type { TabsProps } from '@acadeum/ui';
import { Tabs, Title } from '@acadeum/ui';

import type { AppPage, ReduxState } from '../../helpers/app.types';

import useLocation from '../../hooks/useLocation';
import useQueryParameters from '../../hooks/useQueryParameters';

import actions from '../../actions';

import { LearningAssessmentsTab } from './ui/LearningAssessmentsTab';
import { FacultyCredentialsTab } from './ui/FacultyCredentialsTab';
// import { AdvancedSettingsTab } from './ui/AdvancedSettingsTab';
import { CourseSectionsTab } from './ui/CourseSectionsTab';
import { CourseDetailsTab } from './ui/CourseDetailsTab';

const {
  fetchCourseBase
} = actions;

type TabKey =
  'details' |
  // 'settings' |
  'sections' |
  'assessments' |
  'credentials';

const TabMap: Record<TabKey, number> = {
  details: 0,
  // settings: 1,
  sections: 1,
  assessments: 2,
  credentials: 3
};

const EditCourse: AppPage = () => {
  const course = useSelector((state: ReduxState) => state.courses.courseBase);

  const dispatch = useDispatch();
  const location = useLocation();

  const refreshCourse = useCallback(async () => {
    await fetchCourseBase(course.id, {
      include: [
        'sections',
        'resources',
        'institution'
        // 'settings'
      ]
    });
  }, [course]);

  const [activeTab, setActiveTab] = useState<number>(0);

  useQueryParameters(({ tab }) => {
    if (tab && TabMap[tab] !== undefined) {
      setActiveTab(TabMap[tab]);
    }
  });

  const onChangeTab = useCallback(({ key, index }) => {
    dispatch(replaceLocation({
      pathname: location.pathname,
      hash: location.hash,
      query: { tab: key }
    }));
    setActiveTab(index);
  }, [location]);

  const tabs: TabsProps['tabs'] = useMemo(() => {
    const learningAssessments = course.resources.filter(resource => resource.type === 'LEARNING_ASSESSMENT');
    const facultyCredentials = course.resources.filter(resource => resource.type === 'FACULTY_CREDENTIAL');
    return [
      {
        key: 'details',
        title: 'Course Details',
        body: <CourseDetailsTab course={course} refresh={refreshCourse}/>
      },
      // {
      //   key: 'settings',
      //   title: 'Advanced Settings',
      //   body: <AdvancedSettingsTab course={course} refresh={refreshCourse}/>
      // },
      {
        key: 'sections',
        title: 'Course Sections',
        body:  (
          <CourseSectionsTab
            course={course}
            refresh={refreshCourse}
          />
        )
      },
      {
        key: 'assessments',
        title: 'Learning Assessments',
        body: (
          <LearningAssessmentsTab
            assessments={learningAssessments}
            refresh={refreshCourse}
            courseId={course.id}
          />
        )
      },
      {
        key: 'credentials',
        title: 'Faculty Credentials',
        body: (
          <FacultyCredentialsTab
            credentials={facultyCredentials}
            refresh={refreshCourse}
            courseId={course.id}
          />
        )
      }
    ];
  }, [course, refreshCourse]);

  return (
    <div>
      <Title>{getCourseName(course)}</Title>
      <Tabs
        keepMounted={false}
        theme="black"
        title="Edit Course"
        tabs={tabs}
        activeTab={activeTab}
        onChangeTab={onChangeTab}
      />
    </div>
  );
};

EditCourse.when = ({ user }) => userHasPermission(user, 'course:update', {
  orgId: user.institution.id,
  ownerId: null
});

EditCourse.meta = ({ useSelector }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const courseBase = useSelector(state => state.courses.courseBase);
  return {
    title: 'Edit ' + getCourseName(courseBase)
  };
};

EditCourse.breadcrumbs = (state) => {
  const course = state.courses.courseBase;
  return [
    ['Courses', '/'],
    [getCourseName(course), `/courses/${course.id}`],
    'Edit'
  ];
};

EditCourse.load = async ({ params: { id } }) => {
  try {
    await fetchCourseBase(id, {
      include: [
        'sections',
        'resources',
        'institution'
        // 'settings'
      ]
    });
  } catch (error) {
    if (getErrorData(error).statusCode === 404) {
      return {
        redirect: {
          url: '/not-found'
        }
      };
    }
    throw error;
  }
};

export default EditCourse;

function getCourseName(course) {
  return `${course.code}: ${course.title}`;
}
