import { useMemo } from 'react';

import { useTranslate } from './useTranslate';

/**
 * Returns a list of translated `<Select/>` options.
 *
 * @param {string} path
 * @param {string[]} optionValues
 * @return {object[]} Translated `<Select/>` options.
 */
export function useTranslateOptions<V extends string = string>(path: string, optionValues: V[]) {
  const t = useTranslate();
  return useMemo(() => {
    return optionValues.map((value) => ({
      value,
      label: t(path + '.' + value)
    }));
  }, [
    t,
    path,
    optionValues
  ]);
}
