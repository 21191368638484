// Pricing Model documentation:
// https://github.com/Acadeum/Tickets/blob/master/Pricing-Model.md

// TypeScript typings could be found in:
// https://github.com/Acadeum/frontend/blob/develop/packages/helpers/src/getCourseSectionPricingInfo.ts

import getCourseSectionPriceForStudentBasedOnCostForInstitutionWithDetails from './getCourseSectionPriceForStudentBasedOnCostForInstitutionWithDetails.js';

/**
 * Returns course section price for student.
 * @param {object} parameters
 * @param {object} parameters.course
 * @param {object[]} [parameters.homeStudentCourseEnrollmentPricing]
 * @param {number} parameters.costForInstitution
 * @return {number} [price] — `undefined` means "don't show the price to students".
 */
export default function getCourseSectionPriceForStudentBasedOnCostForInstitution(parameters) {
  const { price } = getCourseSectionPriceForStudentBasedOnCostForInstitutionWithDetails(parameters);
  return price;
}
