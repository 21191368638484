import type { MutableRefObject } from 'react';
import { useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import debounce from 'lodash/debounce';

import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

export function useHasScrollbar(
  target: MutableRefObject<HTMLElement>,
  dependentTarget?: MutableRefObject<HTMLElement>
) {
  const [hasHorizontalScrollbar, setHasHorizontalScrollbar] = useState(false);
  const [hasVerticalScrollbar, setHasVerticalScrollbar] = useState(false);

  const handler = debounce(() => {
    if (!target.current) {
      setHasHorizontalScrollbar(false);
      setHasVerticalScrollbar(false);
    } else {
      setHasHorizontalScrollbar(target.current.scrollWidth > target.current.clientWidth);
      setHasVerticalScrollbar(target.current.scrollHeight > target.current.clientHeight);
    }
  }, 100);

  const depend = dependentTarget || target;
  useIsomorphicLayoutEffect(handler, [depend]);
  useResizeObserver(depend, handler);

  return {
    hasHorizontalScrollbar,
    hasVerticalScrollbar,
    hasScrollbar: hasVerticalScrollbar || hasHorizontalScrollbar
  };
}
