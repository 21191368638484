import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const createStripeAccount = redux.action(
  (data) => (http) => http.post('/payments/account', data)
);

export const getStripeTermsOfServiceAcceptedStatus = redux.action(
  () => async (http) => {
    try {
      return await http.get('/stripe/agreement');
    } catch (error) {
      if (error.status !== 404) {
        throw error;
      }
    }
  },
  'termsOfService'
);

export const acceptStripeTermsOfService = redux.action(
  () => (http) => http.post('/stripe/agreement')
);

export default redux.reducer();
