import React from 'react';

import type { Institution, User } from '@acadeum/types';

import type { AvatarProps } from '@acadeum/core-ui';
import { Avatar } from '@acadeum/core-ui';

export interface UserPictureProps extends Pick<AvatarProps, 'size' | 'className'>{
  user?: Pick<User, 'userPictureUrl'> & {
    institution?: Pick<Institution, 'logoUrl'>
  };
}

export const UserPicture: React.FC<UserPictureProps> = ({ user, size, className }) => {
  return (
    <Avatar
      type="user"
      size={size}
      className={className}
      url={user?.userPictureUrl ? user.userPictureUrl : user?.institution?.logoUrl}
    />
  );
};
