import React, { useRef } from 'react';

import Field from '../../components/Field';
import Form from '../../components/Form';
import FormHeading from '../../components/FormHeading';
import FormButtons from '../../components/FormButtons';
import FormSubmit from '../../components/FormSubmit';
import Row from '../../components/Row';

import actions from '../../actions';

const {
  changeStudentEmail,
  notify
} = actions;

export default function ChangeStudentEmail() {
  const form = useRef();

  async function onSubmit(values) {
    await changeStudentEmail(values);
    notify('Student email address has been updated');
    // form.current.form.current.reset();
  }

  return (
    <section>
      <FormHeading>
        Change Student Email
      </FormHeading>

      <Form
        ref={form}
        autoFocus
        className="form"
        onSubmit={onSubmit}>

        <Row>
          <Field
            required
            name="email"
            type="email"
            label="Student Email"
            col={4}/>

          <Field
            required
            name="newEmail"
            type="email"
            label="New Student Email"
            col={4}/>

          <Field
            required
            name="institutionId"
            label="Institution"
            type="institution"
            superAdmin
            col={8} />
        </Row>

        <FormButtons>
          <FormSubmit>
            Save
          </FormSubmit>
        </FormButtons>
      </Form>
    </section>
  );
}

ChangeStudentEmail.meta = () => ({
  title: 'Change Student Email'
});

ChangeStudentEmail.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Change Student Email'
];
