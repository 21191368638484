import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CircleExclamationSolidIcon } from '@acadeum/icons';

import { Icon } from '../Icon';
import { Label } from '../Label';
import { Text } from '../Text';

import styles from './FormFieldWrapper.module.scss';

// eslint-disable-next-line
export const FormFieldWrapper: React.FC<Record<any, any>> = ({
  id,
  error,
  label,
  labelAction,
  children,
  required,
  className,
  width,
  password,
  noMargin,
  style,
  helpText,
  labelTooltip,
  hidden
}) => {
  return (
    <div hidden={hidden} style={style} className={classNames(styles.FormFieldWrapper, className, {
      [styles['FormFieldWrapper--wide']]: width === 'wide',
      [styles['FormFieldWrapper--noMargin']]: noMargin,
      [styles['FormFieldWrapper--password']]: password
    })}>
      {(label || labelAction) && (
        <Label
          htmlFor={id}
          action={labelAction}
          tooltip={labelTooltip}
          children={label}
          required={required}
          mb="sm"
        />
      )}
      {children}
      <FormFieldFooter id={id} error={error} helpText={helpText}/>
    </div>
  );
};

FormFieldWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  width: PropTypes.oneOf(['wide']),
  noMargin: PropTypes.bool,
  password: PropTypes.bool,
  labelAction: PropTypes.shape({
    content: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  })
};

const FormFieldFooter = ({ error, id, helpText }) => {
  return (
    <>
      {error && (
        <div className={styles.FormFieldFooter}>
          <Icon className={styles.FormFieldFooter__icon} icon={CircleExclamationSolidIcon }/>
          <span id={id + '-hint'}>
            {error}
          </span>
        </div>
      )}
      {helpText && (
        <Text style={{ marginTop: '.5rem' }} color="grey" variant="bodyMd">
          {helpText}
        </Text>
      )}
    </>
  );
};
