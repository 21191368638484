import LABELS_EN from '../messages/en/CourseLevel.json' assert { type: 'json' };

export default function formatCourseLevel(level, { language }) {
  const LABELS = LABELS_EN;
  if (LABELS[level]) {
    return LABELS[level]
  }
  console.error(`Message for course level "${level}" for language "${language}" not found`);
  return level;
}
