import type { MutableRefObject } from 'react';
import React, { useImperativeHandle, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { CircleCheckIcon, UploadIcon } from '@acadeum/icons';

import { FileUploadButton } from '../FileUploadButton';
import { Button } from '../Button';
import { Loader } from '../Loader';
import type { AlertProps } from '../Alert';
import { Alert } from '../Alert';

import { useOnChange, useDragging } from './hooks';
import { getFileNames, getFilesFromEvent } from './utils';

import styles from './FileUpload.module.scss';

export interface FileUploadProps {
  maxSize?: number;
  minSize?: number;
  disabled?: boolean;
  className?: string;
  accept?: string | string[];
  multiple?: boolean;
  children?: React.ReactNode | false;
  fileName?: string;
  uploadButtonTitle?: string;
  removeButtonTitle?: string;
  onChange: (files?: File | FileList) => void;
  onReset?: () => void;
  alertProps?: AlertProps;
}

export interface FileUploadRef {
  reset: () => void;
}

export const FileUpload = React.forwardRef<FileUploadRef, FileUploadProps>(({
  disabled,
  className,
  accept,
  multiple,
  children = `Drag & Drop a file${multiple ? 's' : ''} here`,
  fileName,
  alertProps,
  uploadButtonTitle,
  removeButtonTitle,
  onChange,
  onReset,
  minSize,
  maxSize
}, ref) => {
  const [currFiles, setFiles] = useState<File | FileList>();
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const uploaded = Boolean(currFiles);

  useImperativeHandle(ref, () => ({
    reset: () => setFiles(undefined)
  }));

  const dropzoneRef = useRef() as MutableRefObject<HTMLDivElement>;

  const handleChanges = useOnChange({
    minSize,
    maxSize,
    accept,
    onChange,
    multiple,
    setFiles,
    setError,
    setIsLoading
  });

  const { dragging } = useDragging({
    dropzoneRef,
    handleChanges,
    isLoading
  });

  const onInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    await handleChanges(getFilesFromEvent(event));
  };

  const onResetClick = () => {
    setFiles(undefined);
    setError(undefined);

    if (onReset) {
      onReset();
    }
  };

  const renderAlert = () => {
    if (error) {
      return (
        <Alert className={styles.Alert} variant="error">
          {error}
        </Alert>
      );
    }

    if (alertProps) {
      return (
        <Alert {...alertProps} className={styles.Alert}/>
      );
    }

    return null;
  };

  return (
    <div
      ref={dropzoneRef}
      className={classNames(className, styles.FileUpload, {
        [styles.disabled]: disabled,
        [styles.dragging]: dragging
      })}
    >
      {renderAlert()}

      {uploaded || fileName ? (
        <div className={styles.FileUpload__uploadedContent}>
          {isLoading ? (
            <Loader className={styles.FileUpload__loader}/>
          ) : (
            <CircleCheckIcon className={styles.FileUpload__checkmarkIcon}/>
          )}

          <div className={styles.FileUpload__fileNames}>
            {fileName ? (
              <span>{fileName}</span>
            ) : (
              <span>{getFileNames(currFiles)}</span>
            )}
            {typeof onReset === 'function' && (
              <Button
                className={styles.RemoveButton}
                variant="secondary"
                onClick={onResetClick}
                disabled={isLoading}
              >
                {removeButtonTitle || 'Remove File'}
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.FileUpload__uploadContent}>
          <UploadIcon/>
          {dragging || children && (
            <p>
              {dragging ? (
                <>Drop files to upload</>
              ) : (
                <>
                  {children}
                  <br/>
                  or
                </>
              )}
            </p>
          )}
          {!dragging && (
            <FileUploadButton
              children={uploadButtonTitle}
              error={error}
              onChange={onInputChange}
              accept={accept}
              multiple={multiple}
            />
          )}
        </div>
      )}
    </div>
  );
});

FileUpload.propTypes = {
  className: PropTypes.string,
  uploadButtonTitle: PropTypes.string,
  removeButtonTitle: PropTypes.string,
  multiple: PropTypes.bool,
  accept: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string.isRequired)
  ]),
  children: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func
};
