import React, { useCallback, useMemo } from 'react';

import { createColumnHelper } from '@tanstack/react-table';

import { useTranslate } from '@acadeum/translate';
import type { UserRole } from '@acadeum/types';
import { Button, Table } from '@acadeum/ui';
import { userHasPermission } from '@acadeum/helpers';
import { getAuthSelector } from '@acadeum/auth';

import { preparePermissionsToObjects } from '../../lib/preparePermissionsToObjects';
import { getScopeLabel } from '../../lib/getScopeLabel';
import type { PermissionObjectType } from '../../lib/types';

import { UserRolePermissionsInfoTooltip } from '../UserRolePermissionsInfoTooltip';
import { useSelector } from 'react-redux';
import { useSettingsRoutes } from '../../../../hooks/useSettingsRoutes';

interface UserRolePermissionsTableProps {
  userRole: UserRole;
}

const columnHelper = createColumnHelper<PermissionObjectType>();

export const UserRolePermissionsTable: React.FC<UserRolePermissionsTableProps> = ({
  userRole
}) => {
  const { getEditUserRolePermissionsUrl } = useSettingsRoutes();
  const t = useTranslate('shared-admin-ui.UserRolePermissionsTable');

  const userProfile = useSelector(getAuthSelector('user'));

  const columns = useMemo(() => [
    columnHelper.accessor('name', {
      header: t('title')
    }),
    columnHelper.accessor('create', {
      header: t('permissions.create'),
      cell: ({ getValue }) => getScopeLabel(getValue())
    }),
    columnHelper.accessor('read', {
      header: t('permissions.read'),
      cell: ({ getValue }) => getScopeLabel(getValue())
    }),
    columnHelper.accessor('update', {
      header: t('permissions.update'),
      cell: ({ getValue }) => getScopeLabel(getValue())
    }),
    columnHelper.accessor('delete', {
      header: t('permissions.delete'),
      cell: ({ getValue }) => getScopeLabel(getValue())
    })
  ], [t]);
  const data = useMemo(() => preparePermissionsToObjects(userRole.permissions), [userRole]);

  const renderTopLeftToolbarCustomActions = useCallback(() => <UserRolePermissionsInfoTooltip/>, []);
  const renderTopRightToolbarCustomActions = useCallback(() => {
    if (
      userRole.type === 'DEFAULT' ||
      (userProfile && !userHasPermission(userProfile, 'userRole:update', {
        orgId: userProfile.institution.id,
        ownerId: null
      }))
    ) {
      return null;
    }
    return (
      <Button url={getEditUserRolePermissionsUrl(userRole.id)}>
        {t('manage')}
      </Button>
    );
  }, [userRole, userProfile]);

  return (
    <Table
      id="userRolePermissions"
      hidePagination
      enableGlobalFilter
      columns={columns}
      data={data}
      renderTopLeftToolbarCustomActions={renderTopLeftToolbarCustomActions}
      renderTopRightToolbarCustomActions={renderTopRightToolbarCustomActions}
      translate={{
        searchPlaceholder: t('searchByPermissionTitle'),
        resultText: ({ totalCount }) => t('resultText', { totalCount }),
        selectedResultText: ({ totalCount, selectedRowsCount }) => t('selectedResultText', { totalCount, selectedRowsCount })
      }}
    />
  );
};
