import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const adminFetchStudentUsers = redux.action(
  (parameters) => http => http.get('/admin/student-users', parameters),
  'studentUsers'
);

export const adminFetchStudentUser = redux.action(
  (id) => http => http.get(`/admin/student-users/${id}`),
  'studentUser'
);

export const studentUserResetPassword = redux.action(
  (id, password) => http => http.post(`/admin/student-users/${id}/reset-password`, { password })
);

export const studentUserRequestPasswordReset = redux.action(
  (id) => http => http.post(`/admin/student-users/${id}/request-password-reset`)
);

export const studentUserVerifyEmail = redux.action(
  (id) => http => http.post(`/admin/student-users/${id}/verify-email`)
);

export const studentUserRequestVerifyEmail = redux.action(
  (id) => http => http.post(`/admin/student-users/${id}/request-verify-email`)
);

export const studentUserChangeInstitution = redux.action(
  (data) => http => http.post('/admin/student-users/change-institution', data)
);

export default redux.reducer();
