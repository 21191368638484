import { array, id, nonNegativeNumber, object, onlyDateString, string, bool, number } from './shared';
import { contactSchema } from './contact';
import { coursePricingSchema } from './coursePricing';

const consortialEnrollmentHomeInstitutionIds = array().items(id());

export function validateInstitutionConsortialEnrollmentHomeInstitutionIds(value) {
  // It says: "object(...).nullable is not a function".
  // So validating manually instead.
  if (value === null) {
    return;
  }
  // Empty arrays are allowed.
  if (Array.isArray(value) && value.length === 0) {
    return;
  }
  const { error } = consortialEnrollmentHomeInstitutionIds.validate(value, { convert: false });
  if (error) {
    console.error(error);
    return `Invalid "Consortial Enrollment Home Institution IDs": ${error.message}`;
  }
}

const institutionData = object({
  levels: string(),
  accreditations: array().items(object({
    link: string().uri(),
    name: string().required()
  })),
  membership: array().items(object({
    link: string().uri(),
    name: string().required()
  })),
  partnership: array().items(object({
    link: string(),
    name: string().required()
  })),
  academicCalendarUrl: string().uri(),
  academicCalendar: object({
    terms: array().items(object({
      name: string().required(),
      sessions: array().items(object({
        name: string(),
        startDate: onlyDateString().required(),
        endDate: onlyDateString().required(),
        addDropEnds: onlyDateString()
      }))
    }))
  }),
  gradingScale: array().items(object({
    grade: string().required(),
    description: string(),
    // `numeric` can be a range, not just a number.
    // Examples: `70-79`, `< 60.00%`, '59.9% or below', `Below 60`, `<60`, `0 to 59`, `93% - 100%`, `93+`, `Less than
    // 60`, `Withdrew from an non credit course`. Therefore it's a `string`. Alternatively it could be just a `from`
    // number for each step (exluding the first one).
    // https://github.com/oseibonsu/CollegeConsortiumTickets/issues/127#issuecomment-585398752
    numeric: string(),
    points: nonNegativeNumber()
  })),
  gradingScaleNotes: string(),
  gradingScaleUrl: string().uri(),
  affiliations: array().items(string())
});

export function validateInstitutionData(value) {
  const { error } = institutionData.validate(value, { convert: false });
  if (error) {
    console.error(error);
    return `Invalid "Data": ${error.message}`;
  }
}

const sftp = object({
  path: string(),
  exportCourses: bool(),
  exportDegrees: bool()
});

export function validateInstitutionSftpConfiguration(value) {
  // It says: "object(...).nullable is not a function".
  // So validating manually instead.
  if (value === null || value === undefined) {
    return;
  }
  const { error } = sftp.validate(value, { convert: false });
  if (error) {
    console.error(error);
    return `Invalid "SFTP" configuration: ${error.message}`;
  }
}

export function validateInstitutionSystemAdministratorContact(value) {
  // It says: ""value" must be of type object".
  // So validating manually instead.
  if (value === null) {
    return;
  }
  const { error } = contactSchema.validate(value, { convert: false });
  if (error) {
    console.error(error);
    return `Invalid "System Administrator Contact": ${error.message}`;
  }
}

const webhooks = object({
  accessToken: string(),
  enrollments: string().uri(),
  enrollmentsByStatus: object({
    REQUESTED: string().uri(),
    REMOVED: string().uri(),
    DENIED: string().uri(),
    PENDING: string().uri(),
    WITHDRAWN: string().uri(),
    ACTIVE: string().uri(),
    DROPPED: string().uri(),
    DUE: string().uri(),
    COMPLETE: string().uri()
  })
});

export function validateInstitutionWebhooksConfiguration(value) {
  // It says: "object(...).nullable is not a function".
  // So validating manually instead.
  if (value === null) {
    return;
  }
  const { error } = webhooks.validate(value, { convert: false });
  if (error) {
    console.error(error);
    return `Invalid "Webhooks" configuration: ${error.message}`;
  }
}

const gradingScale = array().items(object({
  grade: string()
    .pattern(/^[a-zA-Z]([a-zA-Z0-9\s/])*[+-]?$/)
    .required()
    .label('Letter Grade')
    .messages({
      'string.empty': 'The Letter Grade cannot be empty',
      'string.pattern.base': 'The Letter Grade should start with a letter and allow alphanumeric chars, spaces and (+) or (-) sign'
    }),
  description: string().allow('').label('Description'),
  numeric: string().label('Numeric').allow('', null),
  points: number().positive().label('Points')
}));

export function validateInstitutionGradingScale(value) {
  const { error } = gradingScale.validate(value, { convert: false });
  if (error) {
    return error.message;
  }
}

const institutionHomeStudentCourseEnrollmentPricing = array().items(coursePricingSchema);

export function validateInstitutionHomeStudentCourseEnrollmentPricing(value) {
  // They say using `.nullable()` on arrays is buggy.
  // https://github.com/jquense/yup/issues/1497
  // So validating an empty value case here manually instead.
  if (value === null || value === undefined) {
    return;
  }
  const { error } = institutionHomeStudentCourseEnrollmentPricing.validate(value, { convert: false });
  if (error) {
    return `Invalid "Home Student Course Enrollment Pricing": ${error.message}`;
  }
}
