import React, { memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import isUndefined from 'lodash/isUndefined';

import { ChevronDownIcon, ChevronUpIcon } from '@acadeum/icons';

import { BaseButton } from '../../../BaseButton';
import { HStack, VStack } from '../../../Stack';
import { Text } from '../../../Text';

import type { HEX, HSLA, RGBA } from '../../types';
import { isValidHex } from '../../helpers/color';

import { EditableInput } from '../EditableInput';

import styles from './Fields.module.scss';

export interface FieldsProps {
  rgb: RGBA;
  hsl: HSLA;
  hex: HEX,
  // eslint-disable-next-line
  onChange?: (color: any) => void;
}

export const Fields = memo<FieldsProps>(({
  rgb,
  hsl,
  hex,
  onChange: propsOnChange
}) => {
  const [view, setView] = useState<'rgb' | 'hex' | 'hsl'>(() => hsl.a !== 1 ? 'rgb' : 'hex');

  useEffect(() => {
    if (hsl.a !== 1 && view === 'hex') {
      setView('rgb');
    }
  }, [hsl]);

  const toggleView = () => {
    if (view === 'hex') {
      setView('rgb');
    } else if (view === 'rgb') {
      setView('hsl');
    } else if (view === 'hsl') {
      if (hsl.a === 1) {
        setView('hex');
      } else {
        setView('rgb');
      }
    }
  };

  const onChange = (data) => {
    if (data.hex) {
      if (isValidHex(data.hex)) {
        propsOnChange?.({
          hex: data.hex
        });
      }
    } else if (data.r || data.g || data.b) {
      propsOnChange?.({
        r: data.r || rgb.r,
        g: data.g || rgb.g,
        b: data.b || rgb.b
      });
    } else if (data.a) {
      const appearance = Math.round(data.a < 0 ? 0 : data.a > 100 ? 100 : data.a) / 100;

      propsOnChange?.({
        h: hsl.h,
        s: hsl.s,
        l: hsl.l,
        a: appearance
      });
    } else if (data.h || data.s || data.l) {
      // We store HSL as a unit interval so we need to override the 1 input to 0.01
      if (data.s === 1) {
        data.s = 0.01;
      } else if (data.l === 1) {
        data.l = 0.01;
      }

      propsOnChange?.({
        h: data.h || hsl.h,
        s: Number(isUndefined(data.s) ? hsl.s : Math.round(data.s) / 100),
        l: Number(isUndefined(data.l) ? hsl.l : Math.round(data.l) / 100)
      });
    }
  };

  let fields;
  if (view === 'hex') {
    fields = (
      <div className={styles.inputGroup}>
        <EditableInput label="hex" onChange={onChange} value={hex}/>
      </div>
    );
  } else if (view === 'rgb') {
    fields = (
      <div className={styles.inputGroup}>
        <EditableInput
          style={{ flexBasis: '2rem' }}
          label="r"
          onChange={onChange}
          min={0}
          max={255}
          value={rgb.r}
        />
        <EditableInput
          style={{ flexBasis: '2rem' }}
          label="g"
          onChange={onChange}
          min={0}
          max={255}
          value={rgb.g}
        />
        <EditableInput
          style={{ flexBasis: '2rem' }}
          label="b"
          onChange={onChange}
          min={0}
          max={255}
          value={rgb.b}
        />
        <EditableInput
          style={{ flexBasis: '2.5625rem' }}
          min={0}
          max={100}
          label="a"
          onChange={onChange}
          value={`${Math.round(rgb.a * 100)}%`}
        />
      </div>
    );
  } else if (view === 'hsl') {
    fields = (
      <div className={styles.inputGroup}>
        <EditableInput
          style={{ flexBasis: '2rem' }}
          label="h"
          min={0}
          max={360}
          onChange={onChange}
          value={Math.round(hsl.h)}
        />
        <EditableInput
          style={{ flexBasis: '2rem' }}
          label="s"
          min={0}
          max={100}
          onChange={onChange}
          value={Math.round(hsl.s * 100)}
        />
        <EditableInput
          style={{ flexBasis: '2rem' }}
          label="l"
          min={0}
          max={100}
          onChange={onChange}
          value={Math.round(hsl.l * 100)}
        />
        <EditableInput
          style={{ flexBasis: '2.5625rem' }}
          min={0}
          max={100}
          label="a"
          onChange={onChange}
          value={`${Math.round(hsl.a * 100)}%`}
        />
      </div>
    );
  }
  return (
    <HStack gap="xs" className={classNames(styles.Fields)}>
      <BaseButton onClick={toggleView}>
        <HStack style={{ width: '2.625rem' }} justify="between">
          <Text as="span" textTransform="uppercase">
            {view}
          </Text>
          <VStack>
            <ChevronUpIcon className={styles.icon}/>
            <ChevronDownIcon className={styles.icon}/>
          </VStack>
        </HStack>
      </BaseButton>
      {fields}
    </HStack>
  );
});
