import userRoleIds from '../constants/userRoleIds.json' assert { type: 'json' };

const userRoleNamesById = Object.keys(userRoleIds).reduce((all, roleName) => ({
  ...all,
  [userRoleIds[roleName]]: roleName
}), {});

export default function getGenericUserRoleNameById(roleId) {
  return userRoleNamesById[roleId];
}
