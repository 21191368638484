import stringifyLocationQuery from './stringifyLocationQuery.js';

export default function getLocationUrl(location, { relative } = {}) {
  if (location.hasOwnProperty('search')) {
    throw new Error('A `location` argument having a `search` property was passed to `getLocationUrl()`. Instead of passing a `location` having a `search` string, pass a `location` having a `query` object.');
  }

  const origin = location.origin || '';
  const pathname = location.pathname;
  // `query` has higher priority than `search`.
  const search = stringifyLocationQuery(location.query || {});
  const hash = location.hash || '';

  if (relative) {
    return `${pathname}${search}${hash}`;
  }

  return `${origin}${origin && pathname === '/' ? '' : pathname}${search}${hash}`;
}
