import type { Dispatch, Middleware } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';

import { notAuthenticatedAction } from '@acadeum/auth';

import { getErrorData } from './getErrorData';

import { getIsNotAuthenticatedError, getIsMaintenanceError } from './errors';

type GetErrorHandlerMiddleware = ({
  redirectToErrorPage,
  notify
}: {
  notify: (content: string) => void
  redirectToErrorPage: ({ params }: { params: object; pathname: string, dispatch: Dispatch }) => void
}) => Middleware

export const getErrorHandlerMiddleware: GetErrorHandlerMiddleware =
  ({ redirectToErrorPage, notify }) =>
    ({ dispatch }) =>
      (next) =>
        (action) => {
          if (!isRejectedWithValue(action)) {
            return next(action);
          }

          // console.log(action);

          // TODO: Add Logger (Sentry, etc)

          const errorData = getErrorData(action.payload);
          console.error('Async error: ', errorData);

          if (getIsNotAuthenticatedError(errorData)) {
            dispatch(notAuthenticatedAction());
            redirectToErrorPage({
              dispatch,
              pathname: '/error',
              params: errorData
            });
            return next(action);
          }

          if (getIsMaintenanceError(errorData)) {
            notify('The servers are under maintenance. Check back again soon.');
            return next(action);
          }

          return next(action);
        };
