import { useContext } from 'react';

import { LinkContext } from './context';

export function useLinkComponent() {
  const context = useContext(LinkContext);
  if (!context) {
    throw new Error('No LinkContext was provided. Your App must be wrapped in a <LinkContextProvider/> component.');
  }
  return context;
}
