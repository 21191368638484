import React from 'react';

import Field from '../../components/Field';
import Form from '../../components/Form';
import FormHeading from '../../components/FormHeading';
import FormSubmit from '../../components/FormSubmit';
import Row from '../../components/Row';
import Col from '../../components/Col';

import actions from '../../actions';

const {
  markAsUnpaid,
  notify,
  notifyError
} = actions;

export default function MarkAsUnpaid() {
  const onSubmit = async (values) => {
    let enrollmentIds;
    if (values.enrollmentIds) {
      enrollmentIds = parseCommaSeparatedIds(values.enrollmentIds);
    }
    if (!enrollmentIds) {
      return notifyError('Enter enrollment IDs');
    }
    await markAsUnpaid(enrollmentIds);
    notify('The enrollments have been marked as unpaid');
  };

  return (
    <section>
      <FormHeading>
        Mark Enrollments as Unpaid
      </FormHeading>

      <Form
        autoFocus
        className="form"
        onSubmit={onSubmit}>

        <Row alignChildren="bottom">
          <Field
            required
            name="enrollmentIds"
            label="Enrollment IDs (comma-separated)"
            col={10}/>

          <Col col={2} xs={12}>
            <FormSubmit className="form__button--inline-except-xs form__button--wide">
              Update
            </FormSubmit>
          </Col>
        </Row>
      </Form>
    </section>
  );
}

MarkAsUnpaid.meta = () => ({
  title: 'Mark Enrollments as Unpaid'
});

MarkAsUnpaid.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Mark as Unpaid'
];

function parseCommaSeparatedIds(value) {
  const ids = value.split(',').map(_ => _.trim()).filter(_ => _);
  if (ids.length > 0) {
    return ids.map((id) => {
      const _id = +id;
      if (id !== String(_id)) {
        throw new Error(`Invalid ID: ${id}`);
      }
      return _id;
    });
  }
}
