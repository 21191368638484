import React from 'react';

import { LockIcon, LockOpenIcon, KeyIcon, CheckIcon, PaperPlaneIcon, CircleXmarkIcon } from '@acadeum/icons';

import type { History } from '@acadeum/types';

// Must be equal to the limit set in `server-lib/lib/auth/addAuthenticationHistoryEvent.js`.
export const AUTH_HISTORY_MAX_ENTRIES = 12;

export function getHistoryEventLabel(event: History['event'], { t }) {
  switch (event.type) {
    case 'login':
      return t('logIn');
    case 'email_verification':
      return t('emailVerificationLetter');
    case 'email_change':
      if (event.stage === 'auth') {
        return t('emailChangeRequested');
      }
      if (event.stage === 'cancel') {
        return t('emailChangeCanceled');
      }
      return t('emailChangeFinished');
    case 'password_update':
      return t('passwordUpdate');
    case 'password_reset':
      if (event.initial) {
        if (event.stage === 'auth') {
          return t('initialPasswordResetRequested');
        }
        return t('initialPasswordReset');
      }
      if (event.stage === 'auth') {
        return t('passwordResetRequested');
      }
      return t('passwordReset');
  }
  throw new Error(`Unsupported event:\n${JSON.stringify(event, null, 2)}`);
}

export function getHistoryEventDescription(event: History['event'], { t }) {
  switch (event.type) {
    case 'login':
      switch (event.method) {
        case 'as':
          return (
            <>
              <LockIcon className="control-icon control-icon--left-small"/>
              {t('as')}
            </>
          );
        case 'sso':
          return (
            <>
              <LockOpenIcon className="control-icon control-icon--left-small"/>
              {t('sso')}
            </>
          );
        case 'password':
          return (
            <>
              <KeyIcon className="control-icon control-icon--left-small"/>
              {t('password')}
            </>
          );
      }
      break;
    case 'password_update':
      return null;
    case 'password_reset':
    case 'email_verification':
    case 'email_change':
      switch (event.stage) {
        case 'auth':
          return (
            <>
              <PaperPlaneIcon className="control-icon control-icon--left-small"/>
              {t('send')}
            </>
          );
        case 'finish':
          return (
            <>
              <CheckIcon className="control-icon control-icon--left-small"/>
              {t('finished')}
            </>
          );
        case 'cancel':
          return (
            <>
              <CircleXmarkIcon className="control-icon control-icon--left-small"/>
              {t('cancel', { global: true })}
            </>
          );
      }
      break;
  }
  throw new Error(`Unsupported event:\n${JSON.stringify(event, null, 2)}`);
}
