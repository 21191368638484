import React, { useMemo } from 'react';

import { useTranslate } from '@acadeum/translate';
import { ContentSection, FormField } from '@acadeum/ui';

export interface CourseMaterialsSectionValues {
  zeroTextbookCost: boolean;
  courseMaterialISBNs?: string;
  courseMaterialTitles?: string;
}

export function CourseMaterialsSection() {
  const t = useTranslate('shared-admin-ui.CourseForm');

  const zeroTextbookCostOptions = useMemo(() => [
    {
      value: false,
      label: t('no')
    },
    {
      value: true,
      label: t('yes')
    }
  ], []);

  return (
    <ContentSection title={t('courseMaterials.title')}>
      <FormField
        border
        type="radio"
        name="zeroTextbookCost"
        options={zeroTextbookCostOptions}
        label={t('courseMaterials.zeroTextbookCost.label')}
        helpText={t('courseMaterials.zeroTextbookCost.helpText')}
      />
      <FormField
        name="courseMaterialISBNs"
        label={t('courseMaterials.courseMaterialISBNs.label')}
        placeholder={t('courseMaterials.courseMaterialISBNs.placeholder')}
        helpText={t('courseMaterials.courseMaterialISBNs.helpText')}
      />
      <FormField
        noMargin
        name="courseMaterialTitles"
        label={t('courseMaterials.courseMaterialTitles.label')}
        placeholder={t('courseMaterials.courseMaterialTitles.placeholder')}
      />
    </ContentSection>
  );
}
