import { useScreenSize as useScreenSize_ } from '@acadeum/hooks';

// Must match breakpoints in "styles/common/_variables.scss"
const SCREEN_SIZES = {
  xs: 0,
  sm: 414,
  md: 768,
  lg: 1280,
  xl: 1440,
  xxl: 1800,
  xxxl: 2600 // Not bootstrap breakpoint
};

export default function useScreenSize() {
  return useScreenSize_(SCREEN_SIZES);
}
