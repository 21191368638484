import React from 'react';
import { useController } from 'react-hook-form';

import type { ColorInputProps } from '../../../ColorInput';
import { ColorInput } from '../../../ColorInput';

interface FormColorFieldProps extends ColorInputProps {
  rules: object;
  name: string;
  defaultValue?: string;
}

export const FormColorField = ({
  name,
  defaultValue,
  rules,
  ...rest
}: FormColorFieldProps) => {
  const { field } = useController({ name, defaultValue, rules });

  return (
    <ColorInput
      {...rest}
      {...field}
    />
  );
};
