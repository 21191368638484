import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tooltip } from 'react-responsive-ui';
import { QualityMattersBadge } from '@acadeum/ui';

import InstitutionLink from '../InstitutionLink';

import './InstitutionSearchResult.sass';

export default function InstitutionSearchResult({
  children: institution,
  instantBack = true,
  className
}) {
  className = classNames(className, 'InstantSearchResult--card');
  return (
    <InstitutionLink
      institution={institution}
      className={classNames(className, 'InstitutionCard-link')}
      gaLabel={institution.name}
      instantBack={instantBack}
    >
      <InstitutionSearchResultCard
        institution={institution}
      />
    </InstitutionLink>
  );
}

InstitutionSearchResult.propTypes = {
  children: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    shortName: PropTypes.string,
    logoUrl: PropTypes.string.isRequired,
    teaching: PropTypes.bool
  }).isRequired,
  instantBack: PropTypes.bool,
  className: PropTypes.string
};

function InstitutionSearchResultCard({
  institution: {
    name,
    shortName,
    logoUrl,
    teaching,
    qualityMattersDesignation
  }
}) {
  return (
    <section className="InstitutionCard-content">
      <div className="InstitutionCard-logoAndName">
        <div className="InstitutionCard-logoContainer">
          <img
            src={logoUrl}
            // Be unnecessary by users with visual impairments as similar information
            // was already provided in their surrounding text. https://github.com/Acadeum/ASP-site/pull/950
            alt=""
            className="InstitutionCard-logo"
          />
        </div>

        <h1
          className={classNames('InstitutionCard-name', {
            'InstitutionCard-name--teaching': teaching
          })}
        >
          {name.length > 30 && shortName ? shortName : name}
        </h1>

        <div className="InstitutionCard-badges">
          {qualityMattersDesignation && (
            <QualityMattersBadge variant="icon"/>
          )}

          {teaching &&
            <Tooltip content="Teaching Institution">
              <div className="InstitutionCard-teachingIcon" aria-hidden="true"/>
            </Tooltip>
          }
        </div>
      </div>
    </section>
  );
}

InstitutionSearchResultCard.propTypes = {
  // `link` property is just a temporary hack
  // until the Acadeum site has its own institution cards.
  institution: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    shortName: PropTypes.string,
    logoUrl: PropTypes.string.isRequired,
    teaching: PropTypes.bool
  }).isRequired
};
