import { connectSearchBox } from 'react-instantsearch-core';

import { SearchBar } from '@acadeum/ui';
import { useId } from 'react';

const SearchBoxNew = ({ refine, className, placeholder, currentRefinement }) => {
  const id = useId();
  const onChange = (value) => {
    refine(value);
  };

  return (
    <SearchBar
      value={currentRefinement}
      id={id}
      placeholder={placeholder}
      className={className}
      onChange={onChange}
    />
  );
};


export default connectSearchBox(SearchBoxNew);
