/* eslint-disable */

import { useRef, useEffect } from 'react';

type GetAlgoliaIndex = any;

export interface UseFindInstitutionByEmailDomainInterfaceProps<T, R> {
  getInstitutionResultObject: (institution: T) => R;
  onInstitutionFound: (institution: R) => void;
  onInstitutionNotFound: () => void;
  onSearchStart: () => void;
  onSearchEnd: ({ error }: { error: unknown }) => void;
  getAlgoliaIndex: GetAlgoliaIndex;
}

type Search<T, R> = { domain: string; cancel?: boolean; finished?: boolean; institution?: R }

// In case of setting any state variables in `onInstitutionFound()`,
// it should use the passed `institution` argument
// instead of creating a new object from it:
// it if created a new object from it
// then the component would re-render indefinitely.
// Use `getInstitutionResultObject()` function
// to transform the received Algolia institution object.
export function useFindInstitutionByEmailDomain<T extends object, R extends object>({
  getInstitutionResultObject,
  onInstitutionFound,
  onInstitutionNotFound,
  onSearchStart,
  onSearchEnd,
  getAlgoliaIndex
}: UseFindInstitutionByEmailDomainInterfaceProps<T, R>) {
  const isMounted = useRef(false);
  const currentSearch = useRef<Search<T, R>>();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      if (currentSearch.current) {
        currentSearch.current.cancel = true;
      }
    };
  }, []);

  return async function findInstitutionByEmailDomain(email: string) {
    const domain = email && getEmailDomain(email);

    if (!isPossibleDomain(domain)) {
      return;
    }

    // @ts-ignore
    const runImmediatelyAfter = (func, argument?: any) => {
      // `setTimeout()` is used to delay a "set state" call
      // so that it doesn't run during `render()`.
      // Prevents warning:
      // "Warning: Cannot update a component (`Login`)
      //  while rendering a different component (`Form`).
      //  To locate the bad setState() call inside `Form`".
      setTimeout(() => {
        if (isMounted.current) {
          func(argument);
        }
      }, 0);
    };

    const onInstitutionChange = (institution?: R) => {
      if (institution) {
        runImmediatelyAfter(onInstitutionFound, institution);
      } else if (onInstitutionNotFound) {
        runImmediatelyAfter(onInstitutionNotFound);
      }
    };

    const prevSearch = currentSearch.current;
    if (prevSearch) {
      if (prevSearch.finished) {
        if (domain === prevSearch.domain) {
          return onInstitutionChange(prevSearch.institution);
        }
      } else {
        prevSearch.cancel = true;
      }
    }

    const search: Search<T, R> = { domain };
    currentSearch.current = search;
    try {
      runImmediatelyAfter(onSearchStart);
      const institution = await getInstitutionByEmailDomain(domain, { getAlgoliaIndex });
      search.institution = institution ? getInstitutionResultObject(institution) : undefined;
      search.finished = true;
      if (!search.cancel) {
        runImmediatelyAfter(onSearchEnd);
        onInstitutionChange(search.institution);
      }
    } catch (error: unknown) {
      console.error(error);
      if (!search.cancel) {
        runImmediatelyAfter(onSearchEnd, { error });
        currentSearch.current = undefined;
      }
    }
  };
}

function isPossibleDomain(domain: string) {
  if (domain) {
    const dotIndex = domain.indexOf('.');
    if (dotIndex > 0) {
      // At least two characters after a dot.
      return domain.length > dotIndex + 2;
    }
  }
}

function getEmailDomain(email: string) {
  return email.trim().split('@')[1];
}

/**
 * Finds an Algolia institution object by email domain.
 * @param  {string} emailDomain
 * @param {function} options.getAlgoliaIndex
 * @return {object} institution
 */
async function getInstitutionByEmailDomain(
  emailDomain: string,
  { getAlgoliaIndex }: { getAlgoliaIndex: GetAlgoliaIndex }
) {
  return await getAlgoliaIndex('institutions').findOne({
    where: {
      emailDomains: emailDomain
    }
  });
}
