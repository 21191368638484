import React from 'react';

import ErrorSection from '../../components/Error';
import useLocation from '../../hooks/useLocation';

import '../Error/Error.sass';

export default function NotFound() {
  const {
    query: {
      url
    }
  } = useLocation();

  return (
    <ErrorSection type="not_found" url={url}/>
  );
}

NotFound.meta = () => ({
  title: 'Not Found'
});
