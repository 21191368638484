import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Item } from './ui/Item';

import styles from './List.module.scss';

export interface ListProps {
  children: React.ReactNode;
  type?: 'bullet' | 'number';
  className?: string;
  unstyled?: boolean;
}

export const List: React.FC<ListProps> & {
  Item: typeof Item
} = ({
  children,
  type= 'bullet',
  className,
  unstyled
}) => {
  const ListElement = type === 'bullet' ? 'ul' : 'ol';
  return (
    <ListElement className={classNames(className, styles.List, {
      [styles.numbered]: type === 'number',
      [styles.unstyled]: unstyled
    })}>
      {children}
    </ListElement>
  );
};

List.propTypes = {
  type: PropTypes.oneOf(['bullet', 'number']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  unstyled: PropTypes.bool
};

List.Item = Item;
