import React from 'react';
import PropTypes from 'prop-types';

import { Title } from '@acadeum/ui';

import styles from './InfoSection.module.scss';

export default function InfoSection({
  title,
  children
}) {
  return(
    <section className={styles.Info}>
      <Title level={3} className={styles.Info__title}>
        {title}
      </Title>
      {children}
    </section>
  );
}

InfoSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};
