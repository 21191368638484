import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './InstantSearchResult.sass';

export default function InstantSearchResult({
  isNarrowContent,
  isWideContent,
  component: Component,
  resultsColumnComponent: Column_ = Column,
  layout,
  hit,
  ...rest
}) {
  return (
    <Column_
      layout={layout}
      isNarrowContent={isNarrowContent}
      isWideContent={isWideContent}>
      <Component
        layout={layout}
        className="InstantSearchResult-component"
        {...rest}>
        {hit}
      </Component>
    </Column_>
  );
}

InstantSearchResult.propTypes = {
  isNarrowContent: PropTypes.bool,
  isWideContent: PropTypes.bool,
  layout: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  resultsColumnComponent: PropTypes.elementType,
  hit: PropTypes.object.isRequired
};

function Column({
  isNarrowContent,
  isWideContent,
  layout,
  children
}) {
  const cls = ['InstantSearchResult'];
  switch (layout) {
    case 'grid':
      cls.push('InstantSearchResult--grid');
      cls.push('col-xs-12');
      cls.push('col-sm-6');
      if (isWideContent) {
        cls.push('col-md-4');
      } else {
        cls.push('col-md-6');
      }
      if (isNarrowContent) {
        if (isWideContent) {
          cls.push('col-4');
          cls.push('col-xl-3');
        } else {
          cls.push('col-6');
          cls.push('col-xl-4');
        }
      } else {
        if (isWideContent) {
          cls.push('col-3');
        } else {
          cls.push('col-4');
        }
      }
      break;
    case 'rowGrid':
      cls.push('InstantSearchResult--rowGrid');
      cls.push('col-12');
      break;
    case 'rowGridTwoColumns':
      cls.push('InstantSearchResult--rowGrid');
      cls.push('col-6 col-xs-12');
      break;
    case 'row':
      cls.push('InstantSearchResult--row');
      cls.push('col-12');
      break;
  }
  return (
    <li className={classNames(cls)}>
      {children}
    </li>
  );
}

Column.propTypes = {
  isNarrowContent: PropTypes.bool,
  isWideContent: PropTypes.bool,
  layout: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};
