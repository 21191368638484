import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import connect from './instantSearchConnectExcludePast';
import SwitchComponent from './InstantSearchSwitch.js';

import './InstantSearchToggle.sass';

const InstantSearchExcludePast = ({
  defaultRefinement,
  controlledRefinement,
  inverseIndication,
  currentRefinement,
  refine,
  label,
  className
}) => {
  const getRefinementValue = (switchValue) => {
    return getRefinementForToggleStatus(switchValue, { inverseIndication });
  };

  const getSwitchValue = (refinementValue) => {
    return inverseIndication ? !refinementValue : refinementValue;
  };

  const prevControlledRefinement = useRef(controlledRefinement);

  useEffect(() => {
    if (controlledRefinement !== prevControlledRefinement.current) {
      if (controlledRefinement === undefined) {
        // If `controlledRefinement` property was reset to `undefined`
        // then re-apply the `defaultRefinement`.
        if (prevControlledRefinement.current !== undefined) {
          refine(defaultRefinement);
        }
      } else {
        // If `controlledRefinement` property has changed
        // then apply it as a refinement.
        refine(controlledRefinement);
      }
      // Update `prevControlledRefinement`.
      prevControlledRefinement.current = controlledRefinement;
    }
  }, [controlledRefinement]);

  const onChange = (value) => {
    refine(getRefinementValue(value));
  };

  return (
    <SwitchComponent
      label={label}
      className={className}
      value={getSwitchValue(currentRefinement)}
      onChange={onChange}/>
  );
};

InstantSearchExcludePast.propTypes = {
  // `step` property is used in `connectInstantSearchExcludePast()`
  // in order to "round" the current timestamp (`Date.now()`) when getting
  // filter value. That works around an issue when it sends a lot of
  // HTTP queries to Algolia because on each re-render the filter value
  // (which is `Date.now()`) is a bit different than the previous one.
  step: PropTypes.oneOf(['minute']),
  defaultRefinement: PropTypes.bool,
  controlledRefinement: PropTypes.bool,
  inverseIndication: PropTypes.bool,
  currentRefinement: PropTypes.bool,
  refine: PropTypes.func.isRequired,
  label: PropTypes.string,
  className: PropTypes.string
};

export default connect(InstantSearchExcludePast);

/**
 * Returns `refinement` value for Switch toggle status (true/false).
 * @param  {boolean} toggleStatus
 * @param  {boolean} options.inverseIndication
 * @return {boolean}
 */
export function getRefinementForToggleStatus(toggleStatus, { inverseIndication }) {
  return inverseIndication ? !toggleStatus : toggleStatus;
}
