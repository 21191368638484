import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const changeSessionsTerm = redux.action(
  (ids, term) => async (http) => {
    await http.post('/sessions/change-term', { ids, term });
  }
);

export default redux.reducer();
