import type { FC } from 'react';

import type { FormModalProps } from '@acadeum/ui';
import { FormField, FormModal, toast } from '@acadeum/ui';
import { useTranslate } from '@acadeum/translate';

import { useClientSettingsCourseSearchFilters } from '../../../providers/clientSettings';

import { encodeSearchState } from '../searchStateUtils';
import { DRAFT_SAVED_SEARCH_NAME } from '../CourseSearch_';

import type { SavedSearchesProps } from '../SavedSearches/SavedSearches';

interface CreateSavedSearchModalProps extends Pick<FormModalProps, 'show' | 'onHide'> {
  // eslint-disable-next-line
  searchState: any;
  savedSearches: SavedSearchesProps['savedSearches'];
  scope: 'all' | 'own';
  view: 'sections' | 'courses';
  onAfterSavedSearchesUpdated: (name: string) => void;
}

const CreateSavedSearchModal: FC<CreateSavedSearchModalProps> = ({
  show,
  onHide,
  searchState,
  savedSearches,
  view,
  scope,
  onAfterSavedSearchesUpdated
}) => {
  const t = useTranslate('CourseSearch');

  const { addNewCourseSearchFilter } = useClientSettingsCourseSearchFilters();

  const onSubmit = async (values) => {
    if (savedSearches[values.title] || values.title === DRAFT_SAVED_SEARCH_NAME) {
      return toast.warn(t('error.duplicateTitle'));
    }
    await addNewCourseSearchFilter(view, scope, values.title, {
      searchState: encodeSearchState(searchState, view)
    });
    onAfterSavedSearchesUpdated(values.title);
  };

  return (
    <FormModal
      onHide={onHide}
      show={show}
      title={t('saveSearch')}
      onSubmit={onSubmit}
      submitText={t('save', { global: true })}
    >
      <FormField
        required
        autoFocus
        name="title"
        label={t('searchTitle')}
      />
    </FormModal>
  );
};
export default CreateSavedSearchModal;
