import { useId } from 'react';
import { createConnector } from 'react-instantsearch-dom';
import { Select } from '@acadeum/ui';

import { OldDesigner } from './OldDesigner';

export const division = 'session.course.division';

function _refine(props, searchState, nextRefinement) {
  return {
    ...searchState,
    [division]: nextRefinement
  };
}

const connectWithQuery = createConnector({
  displayName: 'WidgetWithQuery',
  getProvidedProps(props, searchState) {
    const currentRefinement = searchState[division] || [];
    return { currentRefinement };
  },
  refine(props, searchState, nextRefinement) {
    return _refine(props, searchState, nextRefinement);
  },
  getSearchParameters(searchParameters, props, searchState) {
    const filters = (searchState[division] || [])
      .sort((a, b) => a.from - b.from)
      .map(({ from, to }) => {
        if (to === undefined) {
          return `session.course.division >= ${from}`;
        }
        return `session.course.division >= ${from} AND session.course.division <= ${to}`;
      })
      .join(' OR ');

    return searchParameters.setQueryParameter('filters', filters);
  },
  cleanUp(props, searchState) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [division]: _attributeForMyQuery, ...nextSearchState } = searchState;
    return nextSearchState;
  },
  getMetadata(props, searchState) {
    return {
      id: division,
      items: (searchState[division] || []).map(({ from, to }) => ({
        label: `Course Division: ${to === undefined ? `${from} - ` : `${from} - ${to}`}`,
        attribute: division,
        value: function (nextState) {
          // Remove item from the list
          const nextRefinement = (nextState[division] || []).filter(
            refinement => refinement.from !== from || refinement.to !== to
          );
          return _refine(props, nextState, nextRefinement);
        }
      }))
    };
  }
});

const items = [
  { label: '0 - 99', from: 0, to: 99 },
  { label: '100 - 199', from: 100, to: 199 },
  { label: '200 - 299', from: 200, to: 299 },
  { label: '300 - 399', from: 300, to: 399 },
  { label: '400 - 499', from: 400, to: 499 },
  { label: '500 - 999', from: 500, to: 999 },
  { label: '1000 - 1999', from: 1000, to: 1999 },
  { label: '2000 - 3999', from: 2000, to: 3999 },
  { label: '4000 - 5999', from: 4000, to: 5999 },
  { label: '6000+', from: 6000 }
];

const options = items.map(({ label, from, to }) => ({
  label,
  value: to === undefined ? `${from} - ` : `${from} - ${to}`,
  from,
  to
}));

const DivisionSelect = ({ currentRefinement = [], refine, hiddenSelect, newDesigner }) => {
  const id = useId();

  const onChange = (values) => {
    const selectedOptions = values?.map(value => options.find(option => option.value === value));
    refine(selectedOptions);
  };

  const selectedValues = currentRefinement.map(({ from, to }) => to === undefined ? `${from} - ` : `${from} - ${to}`);
  if (newDesigner) {
    return (
      <Select
        hiddenSelect={hiddenSelect}
        isFilter
        multiple
        id={id}
        label="Course Division"
        value={selectedValues}
        onChange={onChange}
        options={options}
      />
    );
  }

  return (
    <OldDesigner
      value={selectedValues}
      options={options}
      onChange={onChange}
    />
  );
};

export const DivisionFilter = connectWithQuery(DivisionSelect);
