import React from 'react';

import type { RowData, Table } from '@tanstack/react-table';

import { SearchBar } from '../../../SearchBar';

import styles from './GlobalFilter.module.scss';

export interface GlobalFilterProps<TData> {
  table: Table<TData>;
  placeholder?: string;
  ariaLabel?: string;
}

export const GlobalFilter = <TData extends RowData>({
  placeholder,
  table,
  ariaLabel
}: GlobalFilterProps<TData>) => {
  return (
    <SearchBar
      waitForIdle
      aria-label={ariaLabel}
      className={styles.GlobalFilter}
      placeholder={placeholder}
      value={table.getState().globalFilter}
      onChange={(value) => table.setGlobalFilter(value)}
    />
  );
};
