import React, { useMemo, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from '@acadeum/translate';

import { FormField, ContentSection } from '@acadeum/ui';

export interface ReasonFormFieldProps {
  required?: boolean;
  other?: boolean;
  label: string;
  labelsPath: string;
  defaultValue?: string;
  notesDefaultValue?: string;
  options: Array<string | undefined | null>;
}

const ReasonFormField: FC<ReasonFormFieldProps> = ({
  required,
  defaultValue,
  notesDefaultValue,
  other = true,
  label,
  labelsPath,
  options
}) => {
  const t = useTranslate('ReasonFormField');

  const options_ = useMemo(() => {
    const options_ = options.map((value) => ({
      value,
      label: t(`${labelsPath}.options.${value}`, { global: true })
    }));
    if (other) {
      options_.push({
        label: t('other'),
        value: 'OTHER'
      });
    }
    return options_;
  }, [options]);

  const [reason, setReason] = useState(defaultValue);

  const onChangeReason = (event) => {
    setReason(event.target.value);
  };
  const reasonFormField = (

    <FormField
      required={required}
      name="reason"
      type="select"
      defaultValue={defaultValue}
      options={options_}
      onChange={onChangeReason}
    />
  );

  const reasonNotesFormField = (
    <FormField
      required
      multiline
      name="reasonNotes"
      defaultValue={notesDefaultValue}
      label="Notes"
    />
  );

  return (
    <ContentSection padding={false} border={false} title={label}>
      {reasonFormField}
      {reason === 'OTHER' &&
        reasonNotesFormField
      }
    </ContentSection>
  );
};
export default ReasonFormField;

ReasonFormField.propTypes = {
  required: PropTypes.bool,
  other: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelsPath: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  notesDefaultValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired
};
