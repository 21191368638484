import type { RtkApi } from '@acadeum/helpers';
import type { Institution, User, UserClientSettingsSchema } from '@acadeum/types';
import { updateUserAction } from '@acadeum/auth';

interface FetchUserProfileOutput extends Pick<User, 'id' | 'firstName' | 'middleName' | 'lastName' | 'title' | 'email' | 'phone' | 'phoneExt'> {
  roles: string[];
  singleSignOn: boolean;
  institution: Pick<Institution, 'id' | 'name' | 'logoUrl'>;
}

type UpdateUserProfileInput = Pick<User, 'firstName' | 'middleName' | 'lastName' | 'title' | 'phone' | 'phoneExt'>

interface UploadUserProfileAvatarInput {
  file: {
    data: string;
    name: string;
  };
}

interface UploadUserProfileAvatarOutput {
  userPictureUrl: string;
}

export interface FetchUserClientSettingsOutput {
  clientSettings: UserClientSettingsSchema | null;
}

export interface UpdateUserClientSettingsInput {
  clientSettings: UserClientSettingsSchema;
}

export const injectUserProfileEndpoints = (rtkApi: RtkApi) => {
  const userProfileApi = rtkApi
    .enhanceEndpoints({
      addTagTypes: [
        'userProfile',
        'userProfileNotificationSettings',
        'userClientSettings'
      ]
    })
    .injectEndpoints({
      endpoints: build => ({
        fetchUserProfile: build.query<FetchUserProfileOutput, void>({
          query: () => '/user-profile',
          providesTags: ['userProfile']
        }),
        updateUserProfile: build.mutation<void, UpdateUserProfileInput>({
          query: (body) => ({
            url: '/user-profile',
            method: 'PATCH',
            body
          }),
          invalidatesTags: ['userProfile']
        }),
        uploadUserProfileAvatar: build.mutation<UploadUserProfileAvatarOutput, UploadUserProfileAvatarInput>({
          query: (body) => ({
            url: '/user-profile/picture',
            method: 'POST',
            body
          }),
          invalidatesTags: ['userProfile']
        }),
        deleteUserProfileAvatar: build.mutation<void, void>({
          query: () => ({
            url: '/user-profile/picture',
            method: 'DELETE'
          }),
          invalidatesTags: ['userProfile'],
          async onQueryStarted(arg, { dispatch, queryFulfilled }) {
            await queryFulfilled;
            dispatch(updateUserAction({ userPictureUrl: undefined }));
          }
        }),
        fetchUserProfileEmailNotificationsSettings: build.query<string[], void>({
          query: () => '/user-profile/notifications/email',
          providesTags: ['userProfileNotificationSettings']
        }),
        updateUserProfileEmailNotificationSettings: build.mutation<void, string[]>({
          query: (body) => ({
            url: '/user-profile/notifications/email',
            method: 'PATCH',
            body
          }),
          invalidatesTags: ['userProfileNotificationSettings']
        }),
        resetUserProfileEmailNotificationSettings: build.mutation<void, void>({
          query: () => ({
            url: '/user-profile/notifications/email',
            method: 'DELETE'
          }),
          invalidatesTags: ['userProfileNotificationSettings']
        }),
        fetchUserClientSettings: build.query<FetchUserClientSettingsOutput, void>({
          query: () => '/user-profile/client-settings',
          providesTags: ['userClientSettings']
        }),
        updateUserClientSettings: build.mutation<void, UpdateUserClientSettingsInput>({
          query: (body) => ({
            url: '/user-profile/client-settings',
            method: 'PATCH',
            body
          }),
          async onQueryStarted(arg, { dispatch, queryFulfilled }) {
            const patchResult = dispatch(
              userProfileApi.util.updateQueryData('fetchUserClientSettings', undefined, (draft) => {
                Object.assign(draft, arg.clientSettings);
              })
            );
            try {
              await queryFulfilled;
            } catch (error) {
              patchResult.undo();
            }
          },
          invalidatesTags: ['userClientSettings']
        })
      })
    });

  return userProfileApi;
};
