import type { CSSProperties } from 'react';
import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { flexRender } from '@tanstack/react-table';
import type { RowData, Table, Header } from '@tanstack/react-table';

import { ArrowDownIcon, ArrowUpDownIcon, ArrowUpIcon } from '@acadeum/icons';

import { BaseButton } from '../../../BaseButton';
import { Input } from '../../../Input';

import { SELECT_COLUMN_ID, EXPANDING_COLUMN_ID } from '../../utils/consts';
import { getCommonCellStyles } from '../../utils/column';

import styles from './THead.module.scss';
import { HStack } from '@acadeum/core-ui';
import { InfoTooltip } from '../../../InfoTooltip';

interface THeadProps<TData> {
  table: Table<TData>;
}

export const THead = <TData extends RowData>({ table }: THeadProps<TData>) => {
  return (
    <thead className={styles.THead}>
      {table.getHeaderGroups().map(headerGroup => (
        <tr key={headerGroup.id} className={styles.tr}>
          {headerGroup.headers.map(header => {

            const style: CSSProperties = {
              ...getCommonCellStyles({
                table,
                header,
                column: header.column
              })
            };

            return (
              <th
                style={style}
                key={header.id}
                className={classNames(styles.th, {
                  [styles.expand]: header.id === EXPANDING_COLUMN_ID,
                  [styles.select]: header.id === SELECT_COLUMN_ID
                })}
              >
                {!header.isPlaceholder && (
                  <>
                    <ColumnLabel table={table} header={header}/>
                    {header.column.getCanFilter() && (
                      <ColumnFilter table={table} header={header}/>
                    )}
                  </>
                )}
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};

interface ColumnLabelProps<TData> {
  header: Header<TData, unknown>;
  table: Table<TData>;
}

const ColumnLabel = <TData extends RowData>({
  header,
  table
}: ColumnLabelProps<TData>) => {
  const columnName = (
    <span className={styles.columnName}>
      {flexRender(
        header.column.columnDef.header,
        header.getContext()
      )}
    </span>
  );

  const headerTooltip = header.column.columnDef.meta?.headerTooltip;

  const content = headerTooltip ? (
    <HStack gap="sm">
      {columnName}
      <InfoTooltip ariaTitle="">
        {headerTooltip}
      </InfoTooltip>
    </HStack>
  ) : columnName;

  if (header.column.getCanSort()) {
    const sortTooltip = header.column.getIsSorted() ? (
      header.column.getIsSorted() === 'desc'
        ? `Sorted by ${header.column.columnDef.header} descending`
        : `Sorted by ${header.column.columnDef.header} ascending`
    ) : 'Unsorted';
    const SortIcon = header.column.getIsSorted()
      ? (header.column.getIsSorted() === 'desc' ? ArrowDownIcon : ArrowUpIcon)
      : ArrowUpDownIcon;

    return (
      <BaseButton
        title={sortTooltip}
        className={classNames(styles.CellLabel, styles.canSort, {
          [styles.isSorted]: header.column.getIsSorted()
        })}
        onClick={(event) => {
          event.stopPropagation();
          table.resetRowSelection();

          // header.column.getToggleSortingHandler()?.(event);
          // Workaround to fix https://github.com/TanStack/table/issues/5147
          const next = header.column.getIsSorted() === 'desc'
            ? false
            : header.column.getIsSorted() === 'asc'
              ? undefined
              : true;
          // Multiple columns sorting not supported
          header.column.toggleSorting(next);
        }}
      >
        {content}
        <SortIcon className={styles.sortIcon}/>
      </BaseButton>
    );
  }

  return (
    <div
      className={styles.CellLabel}
      title={typeof header.column.columnDef.header === 'string' ? header.column.columnDef.header : undefined}
    >
      {content}
    </div>
  );
};

interface ColumnFilterProps<TData> {
  header: Header<TData, unknown>;
  table: Table<TData>;
}

const ColumnFilter = <TData extends RowData>({
  header
}: ColumnFilterProps<TData>) => {
  const CustomFilterComponent = header.column.columnDef.meta?.FilterComponent;

  const timer = useRef<NodeJS.Timeout>();
  const [value, setValue] = useState((header.column.getFilterValue() ?? '') as string);

  const onChange = (event) => {
    setValue(event.target.value);
    clearTimeout(timer.current);

    timer.current = setTimeout(() => {
      header.column.setFilterValue(event.target.value);
    }, 300);
  };

  return (
    <div className={styles.ColumnFilter}>
      {CustomFilterComponent ? (
        <CustomFilterComponent
          getFilterValue={header.column.getFilterValue}
          setFilterValue={header.column.setFilterValue}
        />
      ) : (
        <Input
          appearance="white"
          style={{ height: '1.625rem', minWidth: '3.5rem' }}
          value={value}
          onChange={onChange}
        />
      )}
    </div>
  );
};
