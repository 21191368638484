import React from 'react';
import PropTypes from 'prop-types';

import styles from './CreateNewOptionButton.module.scss';

const CreateNewOptionButton = ({ onClick, children }) => {
  // prevents lost of focus on Textfield
  const onMouseDown = (event) => {
    event.preventDefault();
  };

  return (
    <button
      type="button"
      tabIndex={0}
      onClick={onClick}
      onMouseDown={onMouseDown}
      className={styles.CreateNewOptionButton}
    >
      {children}
    </button>
  );
};

CreateNewOptionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default CreateNewOptionButton;
