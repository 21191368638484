import type { InferProps } from 'prop-types';
import PropTypes from 'prop-types';

import { useGetConsortiumInputParams } from '../../utils/consortiumAutocomplete';

import { useTranslate } from '@acadeum/translate';
import { FormField } from '@acadeum/ui';

export function ConsortiumAutocomplete({
  className,
  noMargin,
  required,
  onChange,
  label,
  multiple,
  name,
  placeholder,
  notFoundLabel,
  noInputLabel,
  ...rest
}: InferProps<typeof ConsortiumAutocomplete.propTypes>): JSX.Element {
  const getConsortiumInputParams = useGetConsortiumInputParams();

  if (!getConsortiumInputParams) {
    throw new Error('<ConsortiumAutocomplete/>: getConsortiumInputParams is not defined. Please, provide it prop in AppProvider.');
  }

  const consortiumInputParams = getConsortiumInputParams();

  const t = useTranslate('ui.ConsortiumAutocomplete');

  return (
    <FormField
      {...rest}
      {...consortiumInputParams}
      className={className}
      noMargin={noMargin}
      multiple={multiple}
      required={required}
      name={name}
      type="select"
      placeholder={placeholder || t('placeholder')}
      label={label || t('label')}
      notFoundLabel={notFoundLabel || t('notFound')}
      noInputLabel={noInputLabel || t('noInput')}
      onChange={onChange}
    />
  );
}

ConsortiumAutocomplete.propTypes = {
  className: PropTypes.string,
  noMargin: PropTypes.bool,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  notFoundLabel: PropTypes.string,
  noInputLabel: PropTypes.string,
  superAdmin: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  defaultValue: PropTypes.any
};
