const PERCENT_FORMATTERS = {
  'en': new Intl.NumberFormat('en', {
    style: 'percent',
    // minimumFractionDigits: 2,
    // maximumFractionDigits: 2
  })
};

/**
 * Formats a number in percents.
 * @param  {Number} number
 * @param  {string} [options.locale] — Is "en" by default.
 * @return {string}
 */
export default function formatPercent(number, options = {}) {
  if (typeof number !== 'number') {
    throw new Error('No number supplied to `formatPercent()`');
  }

  const { locale } = options;
  switch (locale) {
    case 'en':
      if (PERCENT_FORMATTERS[locale]) {
        return PERCENT_FORMATTERS[locale].format(number);
      }
      return Math.round(number * 100) + '%';
    default:
      // Fall back to "en" locale.
      return formatPercent(number, { ...options, locale: 'en' });
  }
}
