import formatStudentLevel from 'common-lib/lib/formatStudentLevel.js';

import type { Language } from '@acadeum/types';

export function getStudentLevelOptions({ language, other }: { language: Language; other?: boolean }) {
  const levels = ['Undergraduate', 'Graduate', 'High School'];
  if (other) {
    levels.push('Other');
  }
  return levels.map((value) => ({
    value,
    label: formatStudentLevel(value, { language })
  }));
}
