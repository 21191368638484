import React from 'react';

import { Input } from '../Input';

export interface PercentInputProps {
  value?: number;
  onChange: (value?: number | null) => void;
}

export const PercentInput = React.forwardRef<HTMLInputElement, PercentInputProps>(({
  value: propsValue,
  onChange: propsOnChange,
  ...rest
}, ref) => {
  const onChange = (event) => {
    const newValue = valueToPercent(event.target.value);
    propsOnChange?.(newValue);
  };

  return (
    <Input
      addonSuffix="%"
      {...rest}
      ref={ref}
      type="number"
      value={percentToNumber(propsValue) ?? ''}
      onChange={onChange}
    />
  );
});

function percentToNumber(value) {
  if (typeof value === 'number') {
    return Number((value * 100).toFixed(2));
  }
  return value;
}

function valueToPercent(value) {
  if (typeof value === 'string') {
    const num = parseFloat(value);
    if (!isNaN(num)) {
      // No adjustment needed here as we're converting from a string to a float,
      // but be mindful of how this value is used in calculations later.
      return num / 100;
    }
  }
  return null;
}

