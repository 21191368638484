import reportError from './reportError.js';
import convertUtcDateToLocalDateWithSameTime from './convertUtcDateToLocalDateWithSameTime.js';
import { isCurrentMonth, isPrevMonth, isToday, isYesterday } from './dateCompare.js';
import getDateDiff from './getDateDiff.js';

export default function formatDateApproximate(date, { utc, locale } = {}) {
  if (!date) {
    throw new Error('date not supplied to "dateDefinition"');
  }

  if (utc === undefined) {
    reportError('`utc` parameter is required when calling `dateDefinition');
  }

  const LOCALES = {
    en: {
      today: 'today',
      yesterday: 'yesterday',
      x_days_ago: '{0} days ago',
      last_month: 'last month',
      x_months_ago: '{0} months ago',
      year_ago: 'year ago',
      x_years_ago: '{0} years ago'
    }
  };

  if (!Object.keys(LOCALES).includes(locale)) {
    throw new Error(`Unsupported locale: ${locale}`);
  }

  date = utc ? convertUtcDateToLocalDateWithSameTime(date) : date;
  const now = new Date();

  if (isToday(date)) {
    return LOCALES[locale].today;
  } else if (isYesterday(date)) {
    return LOCALES[locale].yesterday;
  } else if (isCurrentMonth(date)) {
    const diff_days = getDateDiff(now, date, 'days');
    const res = LOCALES[locale].x_days_ago;
    return res.replace('{0}', String(diff_days));
  } else if (isPrevMonth(date)) {
    return LOCALES[locale].last_month;
  }

  const diff_months = getDateDiff(now, date, 'months');
  if (diff_months <= 11) {
    return LOCALES[locale].x_months_ago.replace('{0}', String(diff_months));
  }

  const diff_years = getDateDiff(now, date, 'years');
  if (diff_years === 1) {
    return LOCALES[locale].year_ago;
  } else if (diff_years > 1) {
    return LOCALES[locale].x_years_ago.replace('{0}', String(diff_years));
  }
}
