import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLoading } from 'react-pages';

import config from 'config';

import { AppTemplate } from '@acadeum/shared-admin-ui';

import useLocation from '../../hooks/useLocation';

import { useAdminChangeOwnInstitutionMutation } from '../../api/admin/users';

import { useMainMenu } from './hooks';
import Logo from './Logo.svg';

import styles from './DefaultTemplate.module.scss';

export function DefaultTemplate({ children }) {
  const { isAppLoading } = useSelector((state) => state.app);
  const isLoadingPage = useLoading();
  const user = useSelector((state) => state.auth.user);

  const location = useLocation();

  const isFullSizePage = ['/error', '/not-found', '/404', '/logout'].includes(location.pathname);
  const isHS4CCFlow = ['/hs4cc'].includes(location.pathname) || location.query.hs4cc === '✓';

  const menu = useMainMenu({ user });

  const fullScreen = isFullSizePage || !user;

  return (
    <div className={styles.root}>
      <AppTemplate
        headerSlot={
          <AppTemplate.Header
            Logo={Logo}
            narrow={fullScreen}
            renderStudentLoginLinkForHS4CC={isHS4CCFlow}
            isLoading={isLoadingPage || isAppLoading}
          />
        }
        footerSlot={<AppTemplate.Footer/>}
        menu={menu}
        fullScreen={fullScreen}
        apiUrl={config.apiUrl}
        useAdminChangeOwnInstitutionMutation={useAdminChangeOwnInstitutionMutation}
      >
        {children}
      </AppTemplate>
    </div>
  );
}

DefaultTemplate.propTypes = {
  children: PropTypes.node
};
