export function getValueFromJsonEditor(value) {
  // `easy-react-form` sets `value` to `null` when the input field text is erased.
  if (value === null) {
    return null;
  }
  // If no inital `value` was provided, then `value` will be `undefined`.
  if (value === undefined) {
    return undefined;
  }
  // Sometimes, for some weird reason, it's `undefined` rather than `null`.
  return JSON.parse(value);
}

export function getValueForJsonEditor(value) {
  // `easy-react-form` sets `value` to `null` when the input field text is erased.
  // Also, Sequelize returns `null` value for `NULL` database fields.
  if (value === null) {
    return '';
  }
  // If `value` property wasn't provided, it'll be `undefined`.
  if (value === undefined) {
    return '';
  }
  return JSON.stringify(value, null, 2);
}
