import React, { memo, useMemo } from 'react';
import ReactIs from 'react-is';
import classNames from 'classnames';

import { elementChildren } from '@acadeum/helpers';

import type { MarginsProp } from '../../utils/useMargin';
import { useMargin } from '../../utils/useMargin';

import styles from './FormRow.module.scss';

interface FormRowProps extends Pick<MarginsProp, 'mb'> {
  className?: string;
  children: React.ReactNode;
}

export const FormRow: React.FC<FormRowProps> = ({
  className,
  children,
  mb = 'lg'
}) => {
  const { marginClassNames } = useMargin({ mb });

  const contents = useMemo(() => elementChildren(children).map((child, index) => {
    if (ReactIs.isFragment(child)) {
      return elementChildren(child.props.children).map((child, index) => {
        return (
          <Item key={index} item={child}/>
        );
      });
    }

    return (
      <Item key={index} item={child}/>
    );
  }), [children]);

  return (
    <div className={classNames(className, styles.FormRow, marginClassNames)}>
      {contents}
    </div>
  );
};

interface ItemProps {
  item: React.ReactNode;
}

const Item = memo<ItemProps>(({ item }) => {
  return (
    <div className={styles.Item}>
      {React.cloneElement(item as React.ReactElement, {
        noMargin: true
      })}
    </div>
  );
});
