import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import DownArrow from './DownArrowIcon.svg';

export default function CollapsibleSectionTitle({
  title,
  rowsCount,
  toggleSection,
  collapsedSections
}) {
  return (
    <button
      type="button"
      onClick={() => toggleSection(title)}
      disabled={rowsCount === 0}
      aria-expanded={!collapsedSections[title]}
      className="rrui__button-reset data-table__expandable-section-button">
      <span className="display-block align-children-center-vertically">
        <DownArrow
          className={classNames('data-table__expandable-section-bullet', {
            'data-table__expandable-section-bullet--open': !collapsedSections[title]
          })} />
        {' '}
        {title}
        {rowsCount > 0 &&
          <span
            className="data-table__expandable-section-count"
            aria-label={`Has ${rowsCount} row${rowsCount === 1 ? '' : 's'}`}>
            {rowsCount}
          </span>
        }
      </span>
    </button>
  );
}

CollapsibleSectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  rowsCount: PropTypes.number.isRequired,
  toggleSection: PropTypes.func.isRequired,
  collapsedSections: PropTypes.object.isRequired
};
