import React, { useRef } from 'react';

import Field from '../../components/Field';
import Form from '../../components/Form';
import FormHeading from '../../components/FormHeading';
import FormButtons from '../../components/FormButtons';
import FormSubmit from '../../components/FormSubmit';
import Row from '../../components/Row';

import actions from '../../actions';

const {
  studentUserChangeInstitution,
  notify
} = actions;

export default function ChangeStudentUserInstitution() {
  const form = useRef();

  async function changeInstitution(values) {
    await studentUserChangeInstitution(values);
    notify('Student user\'s Home Institution has been updated');
    // form.current.form.current.reset();
  }

  return (
    <section>
      <FormHeading>
        Change Student Institution
      </FormHeading>

      <div>
        <strong>Warning</strong>
        <br/>
        <p>
          Some data that is associated to the student user will be <strong>deleted</strong> in order to maintain database consistency:
        </p>
        <ul style={{ listStyleType: 'circle', paddingLeft: '2rem' }}>
          <li>Course Requests</li>
          <li>Registration Requests</li>
          <li>Advisor Recommendations</li>
        </ul>
      </div>

      <br/>

      <Form
        ref={form}
        autoFocus
        className="form"
        onSubmit={changeInstitution}>

        <Row>
          <Field
            required
            name="email"
            type="email"
            label="Student Email"
            col={4}/>

          <Field
            required
            name="institutionId"
            label="New Institution"
            type="institution"
            superAdmin
            col={8} />
        </Row>

        <FormButtons>
          <FormSubmit>
            Save
          </FormSubmit>
        </FormButtons>
      </Form>
    </section>
  );
}

ChangeStudentUserInstitution.meta = () => ({
  title: 'Change Student Institution'
});

ChangeStudentUserInstitution.breadcrumbs = () => [
  ['Admin Tools', '/admin'],
  'Change Student Institution'
];

