import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './SearchFilterListItem.module.scss';

interface SearchFilterListItemProps {
  children: React.ReactNode;
  hidden?: boolean;
  className?: string;
}

const SearchFilterListItem: FC<SearchFilterListItemProps> = ({
  className,
  hidden = false,
  children
}) => {
  return (
    <li
      className={classNames(className, {
        [styles.hidden]: hidden
      })}
    >
      {children}
    </li>
  );
};

export default SearchFilterListItem;

SearchFilterListItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hidden: PropTypes.bool
};
