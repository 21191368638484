// This regular expression allows lowercase letters and numbers,
// but does not allow the subdomain to start with numbers or hyphens
// as that is a subdomain requirement.
//
// /^[a-z][a-z0-9] - Must start with a lowercase letter, allows lowercase letters and numbers.
// *(?:-[a-z0-9]+)*$/ - Hyphens cannot start, end, or be consecutive in the string.
//
const SUBDOMAIN_REG_EXP = /^[a-z][a-z0-9]*(?:-[a-z0-9]+)*$/;

export default function isValidUsername(value) {
  // A username could be used as a subdomain so same restrictions should apply.
  if (!SUBDOMAIN_REG_EXP.test(value)) {
    return false;
  }
  // Redundant check, but don't remove this.
  // This is just in case someone changes the regular expression in some future.
  // A username can't contain just digits in order to be discernable from a numeric ID:
  // this way, the code would know how to fetch the record from the database
  // when receiving URLs like `/institutions/123` or `/institutions/jacksonville`:
  // * "123" — SELECT * WHERE id = 123
  // * "jacksonville" — SELECT * WHERE vanityUrl = 'jacksonville'
  if (String(Number(value)) === value) {
    return false;
  }
  return true;
}
