import type { ComponentPropsWithoutRef } from 'react';
import React, { useCallback, useImperativeHandle, useRef, useState } from 'react';
import classNames from 'classnames';
import type { DrawOptions } from 'react-advanced-cropper';

import { DEFAULT_ERROR_MESSAGE, HStack } from '@acadeum/core-ui';
import { isStringAssert } from '@acadeum/helpers';
import type { DataURL } from '@acadeum/types';
import { PictureIcon } from '@acadeum/icons';

import { useUseUploadImageMutationContext } from '../../utils/uploadImage';

import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '../Popover';
import { ErrorMessage } from '../ErrorMessage';
import { PictureCrop } from '../PictureCrop';
import { FileUpload } from '../FileUpload';
import { Separator } from '../Separator';
import { Button } from '../Button';
import { Label } from '../Label';
import { Input } from '../Input';
import { Modal } from '../Modal';
import { toast } from '../toast';
import { Icon } from '../Icon';
import { Text } from '../Text';

import styles from './PictureUpload.module.scss';

const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/webp', 'image/svg+xml'];
const MAX_FILE_SIZE = 5120; // 5MB

export interface PictureUploadProps {
  id?: string;
  className?: string;
  size?: 'xl' | 'lg' | 'md';
  shape?: 'square' | 'circle';
  label?: string;
  canRemove?: boolean;
  value: string;
  onChange: (pictureUrl: string) => void | Promise<void>;
  drawOptions?: DrawOptions;
  aspectRatio?: number;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  error?: boolean | string;
  helpText?: string;
}

interface PictureUploadRef {
  focus: () => void;
}

export const PictureUpload = React.forwardRef<PictureUploadRef, PictureUploadProps>(({
  id,
  className,
  label,
  size = 'lg',
  shape = 'square',
  canRemove = true,
  value,
  onChange,
  drawOptions,
  aspectRatio,
  required,
  error,
  disabled,
  readOnly,
  helpText
}, ref) => {
  const useUploadImageMutation = useUseUploadImageMutationContext();

  const fileUploadButtonRef = useRef<HTMLButtonElement>(null);

  useImperativeHandle(ref, () => ({
    focus: () => fileUploadButtonRef.current?.focus()
  }));

  const [uploadImageMutation, { isLoading }] = useUploadImageMutation();

  const [showPopover, setShowPopover] = useState(false);

  const togglePopover = (open) => {
    if (open) {
      if (!disabled && !readOnly) {
        setShowPopover(true);
      }
    } else {
      setShowPopover(false);
    }
  };

  const [showCropperModal, setShowCropperModal] = useState(false);

  const [fileDataUrl, setFileDataUrl] = useState<DataURL | null>(null);
  const [imageDataUrl, setImageDataUrl] = useState<DataURL>();

  const [inputValue, setInputValue] = useState(value);

  const onOpenChange: ComponentPropsWithoutRef<typeof Popover>['onOpenChange'] = (open) => {
    togglePopover(open);
    setInputValue(value);
  };

  const onFileUpload: ComponentPropsWithoutRef<typeof FileUpload>['onChange'] = useCallback((file) => {
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file as never);
    reader.addEventListener('load', () => {
      setFileDataUrl(reader.result as string);
      setShowCropperModal(true);
      togglePopover(false);
    }, false);
  }, [setFileDataUrl]);

  const onCloseEditModal = () => {
    setShowCropperModal(false);
    setFileDataUrl(null);
  };

  const onConfirmUploadPicture = async () => {
    isStringAssert(imageDataUrl);
    try {
      const { url } = await uploadImageMutation({ imageDataUrl }).unwrap();
      await onChange?.(url);
      onCloseEditModal();
    } catch (error) {
      console.error(error);
      toast.error(DEFAULT_ERROR_MESSAGE);
    }
  };

  const onSaveImageUrl = async () => {
    if (inputValue) {
      await onChange?.(inputValue);
      togglePopover(false);
    }
  };

  const onRemove = () => {
    fileUploadButtonRef.current?.focus();
    return onChange?.('');
  };

  const inputId = id + '-input';
  const buttonId = id + '-button';
  const messageId = id + '-message';
  const descriptionId = id + '-description';

  return (
    <Popover open={showPopover} onOpenChange={onOpenChange}>
      <div
        data-picture-upload-error={error ? true : undefined}
        data-picture-upload-size={size}
        data-picture-upload-shape={shape}
        data-picture-upload-aspect-ratio={aspectRatio}
        className={classNames(className, styles.root)}
      >
        {helpText && (
          <Text
            id={descriptionId}
            variant="bodyLg"
            color="secondary"
            mb="sm"
            className={styles.helpText}
          >
            {helpText}
          </Text>
        )}
        <div className={styles.inner}>
          {value ? (
            <img
              alt={typeof label === 'string' ? label : ''}
              src={value}
              className={styles.picture}
            />
          ) : (
            <div
              className={styles.placeholder}
              onClick={() => togglePopover(prevState => !prevState)}
            >
              <Icon icon={PictureIcon} className={styles.placeholderIcon}/>
            </div>
          )}
          <div className={styles.rightColumn}>
            {label && (
              <Label
                required={required}
                htmlFor={buttonId}
                className={styles.label}
              >
                {label}
              </Label>
            )}
            <HStack gap="sm">
              <PopoverTrigger asChild>
                <Button
                  ref={fileUploadButtonRef}
                  id={buttonId}
                  variant="secondary"
                  aria-invalid={!!error}
                  aria-describedby={error ? `${messageId} ${descriptionId}` : descriptionId}
                  disabled={disabled || readOnly}
                >
                  {value ? 'Replace' : 'Upload'}
                </Button>
              </PopoverTrigger>
              {canRemove && value && (
                <Button
                  variant="danger-outline"
                  aria-label={`Remove image ${label}`}
                  onClick={onRemove}
                  disabled={disabled || readOnly}
                >
                  Remove
                </Button>
              )}
            </HStack>

            {error && (
              <ErrorMessage id={messageId} className={styles.ErrorMessage}>
                {typeof error === 'string' ? error : 'Error'}
              </ErrorMessage>
            )}
          </div>
        </div>
      </div>

      <PopoverContent
        align="center"
        side="right"
        className={styles.PopoverContent}
      >
        <Text as="h4" variant="subtitle2" color="secondary" mb="md">
          Select Picture
        </Text>
        <FileUpload
          children={false}
          onChange={onFileUpload}
          className={styles.FileUpload}
        />
        <Separator children="or"/>
        <Label htmlFor={inputId}>
          Picture URL
        </Label>
        <Input
          id={inputId}
          value={inputValue}
          placeholder="https://..."
          className={styles.pictureUrlInput}
          onChange={(event) => setInputValue(event.currentTarget.value)}
        />
        <HStack justify="end" gap="md">
          <PopoverClose asChild>
            <Button variant="secondary">
              Cancel
            </Button>
          </PopoverClose>
          <Button onClick={onSaveImageUrl}>
            Save
          </Button>
        </HStack>
      </PopoverContent>

      <Modal
        title="Edit Picture"
        show={showCropperModal}
        onHide={onCloseEditModal}
        backdrop={(fileDataUrl || isLoading) ? 'static' : undefined}
      >
        <Modal.Body>
          {fileDataUrl ? (
            <PictureCrop
              variant={shape}
              image={fileDataUrl}
              onChange={setImageDataUrl}
              aspectRatio={aspectRatio}
              drawOptions={drawOptions}
            />
          ) : (
            <FileUpload
              maxSize={MAX_FILE_SIZE}
              accept={ALLOWED_IMAGE_TYPES}
              onChange={onFileUpload}
              uploadButtonTitle="Upload From Computer"
            />
          )}
        </Modal.Body>
        {fileDataUrl && (
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={onCloseEditModal}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              onClick={onConfirmUploadPicture}
              disabled={isLoading}
            >
              Upload
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </Popover>
  );
});
