import type { MutableRefObject } from 'react';
import { memo, useRef } from 'react';

import { toast } from '@acadeum/ui';
import { useTranslate } from '@acadeum/translate';
import type { UserRole } from '@acadeum/types';

import type { UseUpdateUserRoleMutation } from '@acadeum/api';

import { GeneralRoleInfoForm } from '../../ui/GeneralRoleInfoForm';

interface GeneralTabProps {
  role: UserRole;
  useUpdateUserRoleMutation: UseUpdateUserRoleMutation;
}

const GeneralTab = memo(({
  role,
  useUpdateUserRoleMutation
}: GeneralTabProps) => {
  const t = useTranslate('shared-admin-ui.UserRoleDetailsPage.GeneralTab');

  const [updateUserRoleMutation] = useUpdateUserRoleMutation();

  const formRef = useRef() as MutableRefObject<HTMLFormElement>;

  const onSubmit = async (values) => {
    await updateUserRoleMutation({
      id: role.id,
      ...values
    }).unwrap();
    toast.success(t('successMessage'));
  };

  const onCancel = () => formRef.current.reset();

  return (
    <GeneralRoleInfoForm
      mode={role.type === 'DEFAULT' ? 'readOnly' : 'edit'}
      defaultValues={{
        name: role.name,
        description: role.description
      }}
      onSubmit={onSubmit}
      onCancel={onCancel}
      submitText={t('submitText')}
    />
  );
});

GeneralTab.displayName = 'GeneralTab';

export default GeneralTab;
