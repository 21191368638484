import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ArrowUpRightFromSquareIcon, DocumentViewIcon, LinkIcon } from '@acadeum/icons';
import type { IconSource } from '@acadeum/types';

import type { UnstyledLinkProps } from '../UnstyledLink';
import { UnstyledLink } from '../UnstyledLink';

import styles from './Link.module.scss';

type LinkIconType = IconSource | 'external' | 'file' | 'link';

export interface LinkProps extends UnstyledLinkProps {
  monochrome?: boolean;
  removeUnderline?: boolean;
  icon?: LinkIconType;
  suffixIcon?: LinkIconType;
}

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(({
  className,
  removeUnderline = true,
  monochrome,
  children,
  icon,
  suffixIcon,
  ...rest
}, ref) => {
  const Icon = icon && getIcon(icon);
  const SuffixIcon = suffixIcon && getIcon(suffixIcon);

  return (
    <UnstyledLink
      {...rest}
      ref={ref}
      className={classNames(styles.Link, className, {
        [styles.monochrome]: monochrome,
        [styles.removeUnderline]: removeUnderline
      })}
    >
      {Icon && (
        <Icon className="control-icon"/>
      )}
      {children}
      {SuffixIcon && (
        <SuffixIcon className="control-icon"/>
      )}
    </UnstyledLink>
  );
});

Link.displayName = 'Link';
Link.propTypes = {
  monochrome: PropTypes.bool,
  removeUnderline: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.oneOf(['external', 'file', 'link'])
  ]),
  suffixIcon: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.oneOf(['external', 'file', 'link'])
  ])
};

function getIcon(icon: LinkIconType) {
  switch (icon) {
    case 'external':
      return ArrowUpRightFromSquareIcon;
    case 'file':
      return DocumentViewIcon;
    case 'link':
      return LinkIcon;
    default:
      return icon;
  }
}
