import { Accordion, Text } from '@acadeum/ui';

export function AdditionalCostsSection({
  course
}) {
  const hasAdditionalCosts = course.additionalCosts;
  return (
    <Accordion title="Additional Costs" defaultOpen={hasAdditionalCosts}>
      <Text color="grey">
        {hasAdditionalCosts
          ? 'Additional costs are associated with this course. Please view the syllabus for more information.'
          : 'No additional costs are associated with this course.'}
      </Text>
    </Accordion>
  );
}
