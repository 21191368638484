import React, { useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';

import {
  Alert,
  Button,
  Col,
  ContentSection,
  Form,
  FormField,
  FormFieldArray,
  FormFooter,
  FormSubmit,
  Modal,
  Row,
  Separator,
  Sticky,
  SuccessModal,
  Text,
  Title
} from '@acadeum/ui';

import ApproveSessionsSwitcher from '../../components/ApproveSessionsSwitcher';

import useTermsAndSessions from './useTermsAndSessions.js';
import { useTranslate } from '@acadeum/translate';

import checkUserPermissions from '../../helpers/checkUserPermissions';

import actions from '../../actions';

import './ApproveCourse.sass';

const {
  fetchCourse,
  fetchCourseApprovalForApproveCoursePage,
  getTermsAndSessions,
  approveCourse,
  goto
} = actions;

export default function ApproveCourse() {
  const user = useSelector(state => state.auth.user);
  const course = useSelector(state => state.courses.course);
  const courseApproval = useSelector(state => state.courseApproval.courseApproval);

  const [courseMapping, setCourseMapping] = useState();
  const [showAlert, setShowAlert] = useState(true);
  const [customScheduleWarningTermBeingUnselected, setCustomScheduleWarningTermBeingUnselected] = useState(false);
  const [showCustomScheduleWarningModal, setShowCustomScheduleWarningModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [hasCustomSchedule, setHasCustomSchedule] = useState();

  const approveAllSessionsRef = useRef();

  const t = useTranslate('ApproveCourse');

  const {
    terms,
    termSessions
  } = useTermsAndSessions({
    courseApproval
  });

  useEffect(() => {
    const mapping = course.substitutes
      .filter(_ => _.institutionId === user.institution.id)
      .map(({ code, title }) => ({ code, title }));

    // At least one mapping is required.
    if (mapping.length === 0) {
      mapping.push({ code: '', title: '' });
    }

    setCourseMapping(mapping);
  }, []);

  useEffect(() => {
    setHasCustomSchedule(courseApproval && courseApproval.schedule ? true : false);
  }, []);

  // Hooks must come before this condition.
  if (!course || !terms) {
    return null;
  }

  function onShowCustomScheduleWarningModal() {
    setShowCustomScheduleWarningModal(true);
  }

  const convertSessionsDataToFormValue = () => {
    const result = [];

    if (courseApproval && courseApproval.schedule) {
      terms.map((term, termIndex) => {
        const selectedTerm = courseApproval.schedule.find(item => item.term === term);

        result[termIndex] = [];

        if (selectedTerm) {
          if (selectedTerm.sessions) {
            termSessions[termIndex].map((session, sessionIndex) => {
              const selectedSession = selectedTerm.sessions.find(_ => _ === session);

              if (selectedSession) {
                result[termIndex][sessionIndex] = { session };
              } else {
                result[termIndex][sessionIndex] = { session: false };
              }
            });
          } else {
            termSessions[termIndex].map((session, sessionIndex) => {
              result[termIndex][sessionIndex] = { session };
            });
          }
        } else {
          termSessions[termIndex].map((session, sessionIndex) => {
            result[termIndex][sessionIndex] = { session: false };
          });
        }
      });
    } else {
      termSessions.map((sessions, groupIndex) => {
        result[groupIndex] = [];
        sessions.map((session, sessionIndex) => {
          result[groupIndex][sessionIndex] = [];
          result[groupIndex][sessionIndex] = {session};
        });
      });
    }

    return result;
  };

  const onSubmit = async (values) => {
    const { courseSubstitutes, schedule } = values;
    // Filter out possible course mapping duplicates (human factor).
    const courseMapping = uniqWith(courseSubstitutes, isEqual);
    let parsedSchedule;

    if (schedule && schedule.length) {
      parsedSchedule = [];

      for (let i = 0; i < schedule.length; i++) {
        let term = '';
        const sessions = [];

        for (const prop in schedule[i]) {
          if (prop === 'term') {
            term = terms[i];
          } else {
            if (schedule[i][prop]?.session !== false) {
              sessions.push(schedule[i][prop]?.session);
            }
          }
        }

        if (term && sessions.length > 0) {
          parsedSchedule.push({
            term,
            sessions
          });
        }
      }
    }

    await approveCourse(course.id, courseMapping, parsedSchedule);
    setShowSuccessModal(true);
  };

  const onApproveAllSessionsOn = () => {
    approveAllSessionsRef.current.checked = true;
    setCustomScheduleWarningTermBeingUnselected(false);
    setHasCustomSchedule(false);
  };

  return (
    <>
      <Title children={t('title', { course: `${course.code} ${course.title}` })}/>
      <Text className="approve-course__description">
        {t('description')}
      </Text>
      {courseMapping && (
        <Form
          defaultValues={{
            courseSubstitutes: courseMapping,
            schedule: convertSessionsDataToFormValue(),
            approveAllSessions: courseApproval && courseApproval.schedule ? false : true
          }}
          pendingMessage={t('form.pendingMessage')}
          onSubmit={onSubmit}
        >
          <FormContextConsumer
            ref={approveAllSessionsRef}
            course={course}
            courseApproval={courseApproval}
            customScheduleWarningTermBeingUnselected={customScheduleWarningTermBeingUnselected}
            hasCustomSchedule={hasCustomSchedule}
            onApproveAllSessionsOn={onApproveAllSessionsOn}
            onShowCustomScheduleWarningModal={onShowCustomScheduleWarningModal}
          />
        </Form>
      )}

      <Modal
        show={showCustomScheduleWarningModal}
        size="narrow"
        title={t('modal.title')}
        onHide={() => setShowCustomScheduleWarningModal(false)}
      >
        <Modal.Body>
          <Alert
            dismissible
            className="approve-course__alert"
            show={showAlert}
            variant="warn"
            onClose={() => setShowAlert(false)}
          >
            {t('modal.alert')}
          </Alert>
          <Text className="approve-course__description">
            {t('modal.description')}
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowCustomScheduleWarningModal(false)}
          >
            {t('goBack', { global: true })}
          </Button>
          <Button
            onClick={() => {
              setShowCustomScheduleWarningModal(false);
              setCustomScheduleWarningTermBeingUnselected(true);
              approveAllSessionsRef.current.checked = false;
            }}
          >
            {t('continue', { global: true })}
          </Button>
        </Modal.Footer>
      </Modal>

      <SuccessModal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        size="narrow"
        action={{
          content: t('successModal.primaryAction'),
          onClick: () => goto(`/courses/${course.id}`)
        }}
      >
        {t('successModal.message')}
      </SuccessModal>
    </>
  );
}

ApproveCourse.meta = () => ({
  title: 'Approve Course'
});

ApproveCourse.breadcrumbs = (state) => {
  const course = state.courses.course;
  return [
    [course.code + ' ' + course.title, `/courses/${state.courses.course.id}`],
    'Approve Course'
  ];
};

ApproveCourse.load = async ({ user, params: { id } }) => {
  checkUserPermissions(user, 'courseApproval:create', {
    orgId: user.institution.id
  });
  try {
    await Promise.all([
      getTermsAndSessions(),
      fetchCourse(id, { user }),
      fetchCourseApprovalForApproveCoursePage(id)
    ]);
  } catch (error) {
    // If the course doesn't exist
    // then redirect to "Not found" page.
    // (Algolia errors have `.statusCode`)
    if (error.statusCode === 404) {
      return goto('/not-found');
    }
    throw error;
  }
};

const FormContextConsumer = React.forwardRef(({
  courseApproval,
  course,
  hasCustomSchedule,
  customScheduleWarningTermBeingUnselected,
  onApproveAllSessionsOn,
  onShowCustomScheduleWarningModal
}, ref) => {
  const {
    reset,
    setValue,
    getValues,
    formState: { isDirty, isSubmitSuccessful }
  } = useFormContext();

  const { onDemand } = course;

  const disabledSessions = useRef();

  const t = useTranslate('ApproveCourse');

  const {
    terms,
    termSessions
  } = useTermsAndSessions({
    courseApproval
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues());
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (customScheduleWarningTermBeingUnselected) {
      setValue('approveAllSessions', false, { shouldDirty: true });
    }
  }, [customScheduleWarningTermBeingUnselected]);

  const onActivate = () => {
    setValue('approveAllSessions', true, { shouldDirty: true });
    onApproveAllSessionsOn();
  };

  const onDeactivate = () => {
    onShowCustomScheduleWarningModal();
  };

  return (
    <>
      <div className="approve-course__form-content">
        <ContentSection border={false} title={t('courseMapping')}>
          <div className="approve-course__section-description">
            {t('courseMappingDescription')}
          </div>
          <FormFieldArray
            name="courseSubstitutes"
            addButtonText={t('form.button.label')}
            border={false}
          >
            {({ index }) => (
              <Row key={index}>
                <Col md={6}>
                  <FormField
                    required
                    noMargin
                    name={`courseSubstitutes.${index}.code`}
                    label={t('form.course.code.label')}
                    placeholder={t('form.course.code.placeholder')}
                  />
                </Col>
                <Col md={6}>
                  <FormField
                    required
                    noMargin
                    name={`courseSubstitutes.${index}.title`}
                    label={t('form.course.title.label')}
                    placeholder={t('form.course.title.placeholder')}
                  />
                </Col>
              </Row>
            )}
          </FormFieldArray>
        </ContentSection>
        {!onDemand && (
          <>
            <Separator/>
            <ContentSection border={false} title={t('schedule.title')}>
              <div className="approve-course__section-description">
                {t('schedule.description')}
              </div>
              <ApproveSessionsSwitcher
                ref={ref}
                label={t('schedule.label')}
                name="approveAllByDefault"
                defaultValue={!hasCustomSchedule}
                onActivate={onActivate}
                onDeactivate={onDeactivate}
              />
              {(hasCustomSchedule || customScheduleWarningTermBeingUnselected) && (
                <>
                  <Separator/>
                  <Text className="approve-course__sessions-description">
                    {t('schedule.sessions.description')}
                  </Text>
                  <Schedule ref={disabledSessions} terms={terms} termSessions={termSessions}/>
                </>
              )}
            </ContentSection>
          </>
        )}
      </div>
      {isDirty && (
        <Sticky position="bottom">
          <FormFooter marginTop="none">
            <Button variant="secondary" onClick={() => goto(`/courses/${course.id}`)}>
              {t('cancel', { global: true })}
            </Button>
            <FormSubmit action="submit">
              {t('submit')}
            </FormSubmit>
          </FormFooter>
        </Sticky>
      )}
    </>
  );
});

const Schedule = React.forwardRef(({ terms, termSessions }, ref) => {
  const { fields: checkboxGroupFields } = useFieldArray({ name: 'schedule' });

  const { setValue, getValues, watch } = useFormContext();

  const checkboxes = watch('schedule');

  useEffect(() => {
    checkIsLeftSelectedItems();
  }, []);

  const checkIsLeftSelectedItems = () => {
    const schedule = getValues().schedule;
    const selected = {};

    schedule.map((item, index) => {
      const sessions = [];

      for (const prop in item) {
        if (prop !== 'term' && item[prop]?.session !== false) {
          sessions.push(item[prop]?.session);
        }
      }

      if (sessions.length > 0) {
        selected[terms[index]] = sessions;
      }
    });

    const leftTerms = Object.keys(selected);

    if (leftTerms.length === 1) {
      ref.current = selected;
    } else {
      ref.current = undefined;
    }
  };

  const getTermCheckboxState = (groupIndex) => {
    let state = false;

    if (checkboxes) {
      for (const prop in checkboxes[groupIndex]) {
        if (prop !== 'term' && checkboxes[groupIndex][prop].session !== false) {
          state = true;
        }
      }
    }

    return state;
  };

  const getIndeterminateState = (groupIndex) => {
    if (!checkboxes) {
      return;
    }

    // eslint-disable-next-line
    const {term, ...sessions} = checkboxes[groupIndex];

    if (sessions) {
      const values = Object.values(sessions);
      return values.every(item => item.session !== false) ? false : values.some(item => item.session !== false);
    }
  };

  const onChangeCommon = (event, groupIndex) => {
    const indeterminate = getIndeterminateState(groupIndex);

    for (let i = 0; i < termSessions[groupIndex].length; i++) {
      if (indeterminate || event.target.checked) {
        setValue(`schedule.${groupIndex}.${i}.session`, termSessions[groupIndex][i]);
      } else {
        setValue(`schedule.${groupIndex}.${i}.session`, event.target.checked);
      }
    }

    checkIsLeftSelectedItems();
  };

  const onChangeSingle = () => {
    checkIsLeftSelectedItems();
  };

  const onTermDisable = (term) => {
    const keys = typeof ref.current === 'object' ? Object.keys(ref.current) : undefined;
    if (keys && keys.length === 1 && keys[0] === term) {
      return true;
    }

    return false;
  };

  const onSessionDisable = (term, session) => {
    const keys = typeof ref.current === 'object' ? Object.keys(ref.current) : undefined;
    const isCurrentTerm = keys && keys.length ? keys.find(_ => _ === term) : false;

    if (!isCurrentTerm) {
      return;
    }

    const values = typeof ref.current === 'object' ? Object.values(ref.current) : undefined;

    if (values && values[0]?.length === 1 && values[0][0] === session) {
      return true;
    }

    return false;
  };

  return (
    <>
      {
        checkboxGroupFields.map((groupField, groupIndex) => {
          // eslint-disable-next-line
          const { fields: checkboxFields } = useFieldArray({ name: `schedule.${groupIndex}` });
          const term = terms[groupIndex];

          return (
            <React.Fragment key={groupIndex}>
              <FormField
                noMargin
                key={`term-${groupIndex}`}
                className="approve-course__term"
                type="checkbox"
                name={`schedule.${groupIndex}.term`}
                label={term}
                value={term}
                checked={getTermCheckboxState(groupIndex)}
                indeterminate={getIndeterminateState(groupIndex)}
                disabled={onTermDisable(term)}
                onChange={(event) => onChangeCommon(event, groupIndex)}
              />
              <div className="approve-course__sessions">
                {checkboxFields.map((checkboxField, checkboxIndex) => (
                  <FormField
                    noMargin
                    key={`session-${groupIndex}-${checkboxIndex}`}
                    type="checkbox"
                    label={termSessions[groupIndex][checkboxIndex]}
                    value={termSessions[groupIndex][checkboxIndex]}
                    name={`schedule.${groupIndex}.${checkboxIndex}.session`}
                    disabled={onSessionDisable(term, termSessions[groupIndex][checkboxIndex])}
                    onChange={() => onChangeSingle()}
                  />
                ))}
              </div>
            </React.Fragment>
          );
        })
      }
    </>
  );
});

Schedule.propTypes = {
  terms: PropTypes.arrayOf(PropTypes.string),
  termSessions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};
