import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const getFinancialSettingsStatus = redux.action(
  () => (http) => http.get('/financial-settings-status'),
  (state, result) => {
    const {
      backupPaymentSourceStatus: creditCardStatus,
      primaryPaymentSourceStatus: bankAccountStatus,
      ...rest
    } = result;

    return {
      ...state,
      bankAccountStatus,
      creditCardStatus,
      ...rest
    };
  }
);

export default redux.reducer();
