import React, { memo, useCallback, useState } from 'react';


import type { OnSubmit } from '@acadeum/ui';
import {
  Actions,
  Button,
  ButtonGroup,
  Col,
  ContentSection,
  FormField,
  FormModal,
  Link,
  Row,
  Separator,
  Text
} from '@acadeum/ui';
import type { LearningAssessment } from '@acadeum/types';
import { PlusIcon } from '@acadeum/icons';
import { convertFileToBase64 } from '@acadeum/helpers';

import actions from '../../../../actions';

import styles from './LearningAssessmentsTab.module.scss';
import { toast } from '@acadeum/ui';
import { ALGOLIA_UPDATE_PENDING_NOTE } from '../../../../helpers/algolia';

const {
  addCourseResource,
  deleteCourseResource,
  updateCourseResource
} = actions;

const TYPE_OPTIONS = [
  'Annual Assessment Plan and Report',
  'Grade Distribution',
  'Student Evaluations'
].map(_ => ({ value: _, label: _ }));

const FILE_UPLOAD_EXTENSIONS = [
  '.pdf'
];

interface LearningAssessmentsProps {
  assessments: LearningAssessment[];
  courseId: number;
  refresh: () => Promise<void>;
}

export const LearningAssessmentsTab = memo<LearningAssessmentsProps>(({
  courseId,
  refresh,
  assessments
}) => {
  const [selectedAssessment, setSelectedAssessment] = useState<LearningAssessment | null>(null);

  const [showAddLearningAssessmentModal, setShowAddLearningAssessmentModal] = useState(false);
  const [showEditLearningAssessmentModal, setShowEditLearningAssessmentModal] = useState(false);
  const [showRemoveLearningAssessmentModal, setShowRemoveLearningAssessmentModal] = useState(false);

  const onCloseEditLearningAssessmentModal = () => {
    setSelectedAssessment(null);
    setShowEditLearningAssessmentModal(false);
  };

  const onCloseRemoveLearningAssessmentModal = () => {
    setSelectedAssessment(null);
    setShowRemoveLearningAssessmentModal(false);
  };

  return (
    <>
      <ContentSection title="Learning Assessments">
        <Text variant="bodyLg">
          Assessment provides important data on the breadth and depth of student learning.
          Once added, learning assessment will be displayed on the course page.
        </Text>
        <Separator mt="md" mb="lg" />
        <div className={styles.header}>
          <Text as="h3" variant="subtitle2" color="graphiteDark">
            {assessments.length === 0 ? (
              'No Learning Assessments'
            ) : (
              `${assessments.length} Learning Assessment${assessments.length > 1 ? 's' : ''}`
            )}
          </Text>
          <Button
            icon={PlusIcon}
            variant="secondary"
            onClick={() => setShowAddLearningAssessmentModal(true)}
          >
            Add Learning Assessment
          </Button>
        </div>
        {assessments.map((assessment) => (
          <Row key={assessment.id} className={styles.assessment}>
            <Col col={2}>
              Document
            </Col>
            <Col col={10} className={styles.assessment__header}>
              <Link external icon="file" to={assessment.url}>
                {assessment.name}
              </Link>
              <Actions
                variant="kebab"
                actions={[
                  {
                    title: 'Edit',
                    onClick: () => {
                      setSelectedAssessment(assessment);
                      setShowEditLearningAssessmentModal(true);
                    }
                  },
                  {
                    title: 'Remove',
                    onClick: () => {
                      setSelectedAssessment(assessment);
                      setShowRemoveLearningAssessmentModal(true);
                    }
                  }
                ]}
              />
            </Col>
          </Row>
        ))}
      </ContentSection>

      <AddLearningAssessmentModal
        refresh={refresh}
        courseId={courseId}
        show={showAddLearningAssessmentModal}
        onHide={() => setShowAddLearningAssessmentModal(false)}
      />

      {selectedAssessment && (
        <EditLearningAssessmentModal
          refresh={refresh}
          assessment={selectedAssessment}
          show={showEditLearningAssessmentModal}
          onHide={onCloseEditLearningAssessmentModal}
        />
      )}

      {selectedAssessment && (
        <RemoveLearningAssessmentModal
          refresh={refresh}
          assessment={selectedAssessment}
          show={showRemoveLearningAssessmentModal}
          onHide={onCloseRemoveLearningAssessmentModal}
        />
      )}
    </>
  );
});

interface AddLearningAssessmentValues {
  name: string;
  file: File;
}

function AddLearningAssessmentModal({
  show,
  onHide,
  courseId,
  refresh
}) {
  const onSubmit: OnSubmit<AddLearningAssessmentValues> = useCallback(async ({
    name,
    file
  }) => {
    await addCourseResource({
      name,
      courseId,
      type: 'LEARNING_ASSESSMENT',
      file: await convertFileToBase64(file)
    });
    await refresh();
    toast.success('Learning Assessment added successfully! ' + ALGOLIA_UPDATE_PENDING_NOTE);
  }, [courseId]);

  return (
    <FormModal
      title="Add Learning Assessment"
      submitText="Add"
      show={show}
      onHide={onHide}
      onSubmit={onSubmit}
    >
      <FormField
        required
        name="name"
        type="select"
        label="Learning Assessment Type"
        placeholder="Choose a type"
        options={TYPE_OPTIONS}
      />
      <FormField
        required
        name="file"
        placeholder="Choose a file"
        type="file"
        accept={FILE_UPLOAD_EXTENSIONS}
      />
    </FormModal>
  );
}

interface EditAssessmentsFormValues {
  name: string;
  file?: File;
}

function EditLearningAssessmentModal({
  show,
  onHide,
  assessment,
  refresh
}) {
  const [uploadNewFile, setUploadNewFile] = useState(false);

  const onSubmit: OnSubmit<EditAssessmentsFormValues> = useCallback(async ({ name, file }) => {
    await updateCourseResource(assessment.id, {
      name,
      type: 'LEARNING_ASSESSMENT',
      file: file ? await convertFileToBase64(file) : undefined
    });
    await refresh();
    toast.success('Learning Assessment updated successfully! ' + ALGOLIA_UPDATE_PENDING_NOTE);
  }, []);

  return (
    <FormModal
      title="Edit Learning Assessment"
      submitText="Save"
      show={show}
      onHide={onHide}
      onSubmit={onSubmit}
    >
      <FormField
        required
        name="name"
        type="select"
        label="Learning Assessment Type"
        placeholder="Choose a type"
        defaultValue={assessment.name}
        options={TYPE_OPTIONS}
      />
      {!uploadNewFile && (
        <ButtonGroup fullWidth>
          <Button
            variant="text"
            onClick={() => setUploadNewFile(true)}
          >
            Change the File
          </Button>
        </ButtonGroup>
      )}

      {uploadNewFile && (
        <FormField
          required
          name="file"
          type="file"
          accept={FILE_UPLOAD_EXTENSIONS}
        />
      )}
    </FormModal>
  );
}

function RemoveLearningAssessmentModal({
  show,
  onHide,
  assessment,
  refresh
}) {
  const onRemove = useCallback(async () => {
    await deleteCourseResource(assessment.id);
    await refresh();
    toast.success('Learning Assessment removed successfully! ' + ALGOLIA_UPDATE_PENDING_NOTE);
    onHide();
  }, [assessment, onHide]);

  return (
    <FormModal
      danger
      title="Remove Learning Assessment"
      submitText="Remove"
      show={show}
      onHide={onHide}
      onCancel={onHide}
      onSubmit={onRemove}
    >
      <Text variant="bodyLg" color="black">
        Are you sure you want to remove learning assessment <strong>{assessment.name}</strong>?
        {' '}
        <Text as="span" color="danger">
          This action cannot be undone.
        </Text>
      </Text>
    </FormModal>
  );
}
