import React, { memo } from 'react';
import classNames from 'classnames';

import { RedoIcon, UndoIcon } from '@acadeum/icons';

import { Text } from '../../../Text';
import { HStack } from '../../../Stack';
import { BaseButton } from '../../../BaseButton';
import { CloseButton } from '../../../CloseButton';

import styles from './Header.module.scss';

export interface HeaderProps {
  label: string;
  redo?: () => void;
  undo?: () => void;
  onClose?: (v: false) => void;
}

export const Header = memo<HeaderProps>(({
  label,
  undo,
  redo,
  onClose
}) => {
  return (
    <HStack
      className={classNames(styles.Header)}
      justify="between"
      gap="md"
    >
      <Text variant="subtitle2" as="h4">
        {label}
      </Text>
      <HStack gap="xs">
        <BaseButton
          className={styles.navButton}
          disabled={!undo}
          onClick={undo}
        >
          <UndoIcon className="control-icon"/>
        </BaseButton>
        <BaseButton
          className={styles.navButton}
          disabled={!redo}
          onClick={redo}
        >
          <RedoIcon className="control-icon"/>
        </BaseButton>
        <CloseButton onClick={() => onClose?.(false)}/>
      </HStack>
    </HStack>
  );
});
