import React, { useId } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Loader.sass';

export default function Loader({ isDeterminate, isImposter }) {
  return (
    <div
      className={classNames('Loader', 'Loader--logo', {
        isDeterminate: isDeterminate,
        isImposter: isImposter
      })}
    >
      <LogoProgress/>
    </div>
  );
}

Loader.propTypes = {
  isDeterminate: PropTypes.bool,
  isImposter: PropTypes.bool
};

const LogoProgress = () => {
  const clipPathId = useId();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
      <defs>
        <clipPath id={clipPathId}>
          <rect x="0" y="0" width="52" height="52"/>
        </clipPath>
      </defs>
      <g opacity=".5">
        <g>
          <g opacity=".8">
            <path fill="currentColor" d="M51.276 25.86L25.66.113v25.745z"/>
          </g>
          <g>
            <path fill="currentColor" d="M51.276 25.859L25.66 51.604V25.86z"/>
          </g>
          <g opacity=".3">
            <path fill="currentColor" d="M0 12.986L25.66.114v25.745z"/>
          </g>
          <g opacity=".3">
            <path fill="currentColor" d="M0 38.732l25.66-12.873v25.745z"/>
          </g>
          <g opacity=".3">
            <path fill="currentColor" d="M0 38.732L25.66.114v51.49z"/>
          </g>
          <g opacity=".3">
            <path fill="currentColor" d="M0 12.986l25.66 38.618V.114z"/>
          </g>
        </g>
      </g>
      <g clipPath={`url(#${clipPathId})`}>
        <g>
          <g opacity=".8">
            <path fill="currentColor" d="M51.276 25.86L25.66.113v25.745z"/>
          </g>
          <g>
            <path fill="currentColor" d="M51.276 25.859L25.66 51.604V25.86z"/>
          </g>
          <g opacity=".3">
            <path fill="currentColor" d="M0 12.986L25.66.114v25.745z"/>
          </g>
          <g opacity=".3">
            <path fill="currentColor" d="M0 38.732l25.66-12.873v25.745z"/>
          </g>
          <g opacity=".3">
            <path fill="currentColor" d="M0 38.732L25.66.114v51.49z"/>
          </g>
          <g opacity=".3">
            <path fill="currentColor" d="M0 12.986l25.66 38.618V.114z"/>
          </g>
        </g>
      </g>
    </svg>
  );
};
