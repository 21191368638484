import React from 'react';

export interface UnstyledButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Replaces button text with a spinner while a background action is being performed */
  loading?: boolean;
}

export const UnstyledButton = React.forwardRef<HTMLButtonElement, UnstyledButtonProps>(({
  disabled,
  loading,
  ...rest
}, ref) => {
  return (
    <button
      type="button"
      {...rest}
      ref={ref}
      disabled={disabled}
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : undefined}
      aria-busy={loading ? true : undefined}
    />
  );
});
