import React, { memo } from 'react';
import classNames from 'classnames';

import { Loader } from '../Loader';

import styles from './AppLoading.module.scss';

export interface AppLoadingProps {
  initial?: boolean;
  loading?: boolean;
  overlay?: boolean;
  circle?: boolean;
  inline?: boolean | 'small' | 'medium' | 'large' | 'huge';
}

export const AppLoading = memo<AppLoadingProps>(({
  /**
   * When set true. Shows a completely white screen with logo. Used when loading app
   * */
  initial,
  /**
   * When set true. Shows the linear loader under the Header and screen is not available for actions, cursor in wait
   * state
   * */
  loading,
  /**
   * When set true. Shown spinner on container, height is the same as in small
   * */
  inline,
  /**
   * When set true. Screen is not available for actions, cursor in wait
   * */
  overlay,
  /**
   * When set true. Shows the loading circle
   * */
  circle
}) => {
  if (inline || initial || loading || overlay || circle) {
    return (
      <>
        {inline && (
          <div className={classNames(styles.Inline, {
            [styles[`Inline--${inline}`]]: typeof inline === 'string'
          })}>
            <Loader variant="spinner"/>
          </div>
        )}
        {circle && (
          <Loader variant="gradientSpinner"/>
        )}
        <div className={classNames(styles.Overlay, {
          [styles.Initial]: initial
        })}>
          {initial && (
            <Loader variant="logo"/>
          )}
        </div>
      </>
    );
  }

  return null;
});
