import React from 'react';
import PropTypes from 'prop-types';
import ReactTimeAgo from 'react-time-ago';

// For some weird reason, in Admin Center, locale data wasn't added to `TimeAgo`
// when `TimeAgo.addDefaultLocale(en)` was called in `src/pages/_app.page.tsx`.
// That could happen, for example, if `javascript-time-ago` there was somehow different
// from `javascript-time-ago` here. For example, if those two were the same npm package
// but duplicated at two different paths. I looked briefly and didn't find any duplication.
// The error message was:
// "Error: No locale data has been registered for any of the locales: en, en".
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
TimeAgo.addDefaultLocale(en);

export const RelativeDateFormat = ({ date, locale }) => {
  const _date = date instanceof Date ? date : new Date(date);
  return (
    <ReactTimeAgo date={_date} locale={locale}/>
  );
};

RelativeDateFormat.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string
  ]).isRequired,
  locale: PropTypes.string
};

RelativeDateFormat.defaultProp = {
  locale: 'en'
};
