import { TextInput } from 'react-responsive-ui';

export default function getNumberInputParams({
  onChange,
  step,
  integer
}) {
  return {
    onChange: getNumericOnChange(onChange, { integer }),
    InputComponent: TextInput,
    type: 'number',
    // The `step` attribute of `<input type="number"/>` is `1` by default
    // meaning that it won't allow a user input a floating-point number.
    // In this application, a `type="number"` input is supposed to also
    // allow floating-point number input, so `step` is set to `"any"`.
    step: step || (integer ? 1 : 'any')
  };
}

function getNumericOnChange(onChange, { integer }) {
  return (value) => {
    if (value && typeof value.preventDefault === 'function') {
      value = value.target.value;
    }
    if (value === '') {
      value = undefined;
    }
    if (value) {
      if (integer) {
        value = parseInt(value, 10);
      } else {
        value = parseFloat(value, 10);
      }
      if (isNaN(value)) {
        value = undefined;
      }
    }
    onChange(value);
  };
}
