import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const setNarrowContent = redux.simpleAction(
  (state, isNarrowContent) => ({
    ...state,
    isNarrowContent
  })
);

export const setUserDataLoaded = redux.simpleAction(
  (state, userDataLoaded) => ({
    ...state,
    userDataLoaded
  })
);

export const resetUserDataLoaded = redux.simpleAction(
  (state) => ({
    ...state,
    userDataLoaded: undefined
  })
);

export const setAppLoading = redux.simpleAction(
  (state, isAppLoading) => ({
    ...state,
    isAppLoading
  })
);

export const setShowRequestMoreInfoModal = redux.simpleAction(
  (state, showRequestMoreInfoModal) => ({
    ...state,
    showRequestMoreInfoModal
  })
);

export default redux.reducer({
  // The side nav is expanded by default.
  isNarrowContent: true
});
