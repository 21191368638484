import courseEnrollmentDropOrWithdrawFromStatuses from '../constants/courseEnrollmentDropOrWithdrawFromStatuses.json' assert { type: 'json' };
import validatePropertyValuesNotUndefined from './validatePropertyValuesNotUndefined.js';
import getLastDateToWithdrawFromCourseEnrollment from './getLastDateToWithdrawFromCourseEnrollment.js';
import { canStillDropFromCourseEnrollmentByDate } from './canDropFromCourseEnrollment.js';

export default function canWithdrawFromCourseEnrollment({
  status,
  onDemand,
  onDemandDropDateDaysAfterStartDate,
  startedAt,
  lastDropDate,
  lastWithdrawalDate
}) {
  const error = validateCanWithdrawFromCourseEnrollment({
    status,
    onDemand,
    onDemandDropDateDaysAfterStartDate,
    startedAt,
    lastDropDate,
    lastWithdrawalDate
  });
  return !error;
}

export function validateCanWithdrawFromCourseEnrollment({
  status,
  onDemand,
  onDemandDropDateDaysAfterStartDate,
  startedAt,
  lastDropDate,
  lastWithdrawalDate
}) {
  // Validate that all properties are passed `undefined`.
  validatePropertyValuesNotUndefined({
    status,
    onDemand,
    onDemandDropDateDaysAfterStartDate,
    startedAt,
    lastDropDate,
    lastWithdrawalDate
  });

  if (!courseEnrollmentDropOrWithdrawFromStatuses.includes(status)) {
    return 'invalid_status';
  }

  if (!canAlreadyWithdrawFromCourseEnrollmentByDate({
    onDemand,
    onDemandDropDateDaysAfterStartDate,
    startedAt,
    lastDropDate
  })) {
    return 'drop_date_has_not_passed';
  }

  if (!canStillWithdrawFromCourseEnrollmentByDate({
    onDemand,
    lastWithdrawalDate
  })) {
    return 'withdrawal_date_has_passed';
  }
}

// A student could either "drop" or "withdraw" from a course enrollment,
// depending on the current date:
// * They could drop on or before `lastDropDate`.
// * They could withdraw after `lastDropDate` but before `lastWithdrawalDate` (if defined).
function canAlreadyWithdrawFromCourseEnrollmentByDate({
  onDemand,
  onDemandDropDateDaysAfterStartDate,
  startedAt,
  lastDropDate
}) {
  return !canStillDropFromCourseEnrollmentByDate({
    onDemand,
    onDemandDropDateDaysAfterStartDate,
    startedAt,
    lastDropDate
  });
}

// If `lastWithdrawalDate` is defined, a student can't withdraw from a course after that date.
function canStillWithdrawFromCourseEnrollmentByDate({
  onDemand,
  lastWithdrawalDate
}) {
  const lastDateToWithdrawFromCourseEnrollment = getLastDateToWithdrawFromCourseEnrollment({
    onDemand,
    lastWithdrawalDate
  });

  if (lastDateToWithdrawFromCourseEnrollment) {
    return Date.now() <= lastDateToWithdrawFromCourseEnrollment.getTime();
  }

  return true;
}

function getLastWithdrawalDateForAnyTypeOfCourseEnrollment({
  onDemand,
  lastWithdrawalDate
}) {
  return onDemand
    ? undefined
    : lastWithdrawalDate;
}
