// See "Authentication" section of `README.md` for the description of this hook.

import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import reportError from 'common-lib/lib/reportError.js';

import type { UseQueryParametersCallback } from '@acadeum/hooks';

import {
  clearUserSession,
  getAccessTokenExpiresAt,
  hasAccessToken,
  hasAccessTokenCookie,
  refreshAccessToken,
  setAccessToken
} from './accessToken';
import { useAccessTokenExpiryWatcher } from './useAccessTokenExpiryWatcher';
import type { AuthCookieConfig, ExpiresAt, RefreshAccessToken } from './types';
import type { AuthState } from './state';
import { getAuthSelector } from './selectors';

export function useAuthentication({
  location,
  useQueryParameters,
  authCookieConfig,
  authenticate,
  refreshAccessToken: refreshAccessTokenFunction,
  setUser,
  setAccessTokenExpiresAt,
  setIsAuthenticationLoading,
  onAuthenticated,
  onNotAuthenticated
}: {
  location: { pathname: string }
  useQueryParameters: (cb: UseQueryParametersCallback) => void;
  setUser: (user?: AuthState['user']) => void;
  setAccessTokenExpiresAt: (expiresAt?: ExpiresAt) => void;
  setIsAuthenticationLoading: (isAuthenticationLoading: boolean) => void;
  authenticate: () => Promise<AuthState['user']>;
  refreshAccessToken: RefreshAccessToken
  onAuthenticated?: (user: AuthState['user']) => void;
  onNotAuthenticated?: () => void;
  authCookieConfig?: AuthCookieConfig
}) {
  const { user, accessTokenExpiresAt, isAuthenticationLoading } = useSelector(getAuthSelector);

  // The initial value for `isAccessTokenInUrl` is intentionally left `undefined`.
  // The rationale is that `undefined` means "the router hasn't been loaded yet".
  // That's because in Next.js, `router` shouldn't be used until it's "ready" (weird).
  const [isAccessTokenInUrl, setIsAccessTokenInUrl] = useState<boolean | undefined>();

  const onAccessToken = (token, { expiresIn, expiresAt, userSessionEphemeral }) => {
    // Save access token.
    setAccessToken(token, {
      expiresIn,
      expiresAt,
      userSessionEphemeral,
      authCookieConfig
    });
    // Update `accessTokenExpiresAt` in state.
    setAccessTokenExpiresAt(expiresAt);
  };

  // Refreshes access token.
  const refreshAccessToken_ = () => {
    void refreshAccessToken({
      onAccessToken,
      onAccessTokenExpired: onNotAuthenticated,
      refreshAccessToken: refreshAccessTokenFunction,
      authCookieConfig
    });
  };

  // Refresh access token (if required):
  // * On page load.
  // * On navigation.
  useEffect(() => {
    if (user) {
      // On the student site, the "Log out" button leads to the `/logout` page first,
      // then the `/logout` page performs the actual logout and redirects to the final
      // "logged out" URL. So don't refresh the access token when redirected to the
      // `/logout` page, otherwise it would output an error that the access token refresh
      // has "timed out" (been cancelled by a subsequent "final" redirect).
      if (location.pathname !== '/logout') {
        refreshAccessToken_();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location,
    user
  ]);

  const hasMounted = useRef(false);

  // Calls `onAuthenticated()` when user gets authenticated.
  useEffect(() => {
    if (user) {
      if (onAuthenticated) {
        onAuthenticated(user);
      }
    } else {
      if (hasMounted.current) {
        clearUserSession({ authCookieConfig });
        if (onNotAuthenticated) {
          onNotAuthenticated();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    hasMounted.current = true;
  }, []);

  // Attempts to authenticate user with the `accessToken`
  // from the web browser's `localStorage`.
  const authenticateWithAccessToken = async () => {
    try {
      // Set `user` and `accessTokenExpiresAt` in state.
      setUser(await authenticate());
      setAccessTokenExpiresAt(getAccessTokenExpiresAt({ authCookieConfig }));
    } catch (error) {
      console.error(error);
      if (onNotAuthenticated) {
        onNotAuthenticated();
      }
    }
  };

  // On page load, if `accessToken` URL parameter is present,
  // then authenticate the user. Otherwise, authenticate the user
  // if there's an `accessToken` in the web browser's `localStorage`.
  //
  // `accessToken` parameter is present when the API redirects to the website:
  //
  // * When email address has been verified by a user clicking a "Verify Email"
  //   link in an email message from Acadeum. In that case, the user is redirected
  //   to the website with an `accessToken` parameter so that they don't have to go
  //   through a separate login step. While websites that use cookie-based
  //   authentication wouldn't need that `accessToken` URL parameter, websites that don't
  //   use cookie-based authentication do require it in order to save it to `localStorage`.
  //
  // * When a user has been authenticated via SAML Single Sign On. In that case,
  //   the user is redirected to the website with an `accessToken` parameter so that
  //   they get logged in with that `accessToken`. While websites that use cookie-based
  //   authentication wouldn't need that `accessToken` URL parameter, websites that don't
  //   use cookie-based authentication do require it in order to save it to `localStorage`.
  //
  useQueryParameters(async ({
    accessToken,
    accessTokenExpiresIn,
    accessTokenExpiresAt,
    userSessionEphemeral
  }, { removeQueryParameters }) => {
    setIsAccessTokenInUrl(Boolean(accessToken));
    // Only run this function at application start.
    // For example, Webpack Dev Server error overlay re-runs all `useEffect(..., [])`
    // after a developer has fixed the error.
    // `useQueryParameters()` uses `useRouterReady()` under the hood.
    // The simplest implementation of `useRouterReady()` would be `useEffect(..., [])`
    // which would be re-run after a developer has fixed the error, resulting in
    // performing re-authentication, logging out the current user in the process.
    if (!isAuthenticationLoading) {
      return;
    }
    if (accessToken) {
      const pageUrl = window.location.href;
      // Remove the URL query parameters.
      removeQueryParameters({
        accessToken,
        accessTokenExpiresIn,
        accessTokenExpiresAt,
        userSessionEphemeral
      });
      // Set "ephemeral" auth session flag.
      if (userSessionEphemeral) {
        userSessionEphemeral = true;
      }
      // Convert a `string` to a `number`.
      if (accessTokenExpiresIn) {
        accessTokenExpiresIn = Number(accessTokenExpiresIn);
      }
      // Convert a `string` to a `number`.
      if (accessTokenExpiresAt) {
        accessTokenExpiresAt = Number(accessTokenExpiresAt);
      }
      // During SAML Single Sign On, institution's SAML server redirects
      // to Acadeum API and then Acadeum API redirects back to the website
      // while also setting access token cookies.
      // The issue is that some legacy institutions redirect to an incorrect
      // Acadeum API URL (on an incorrect domain), resulting in not being able
      // to set cookies.
      // https://github.com/Acadeum/Tickets/issues/1254
      // To work around that, the website simply sets access token cookies
      // manually if it finds that those haven't been set for some reason.
      if (authCookieConfig) {
        if (!hasAccessTokenCookie({ authCookieConfig })) {
          const error = new Error('Access token cookie wasn\'t set by the API. Authentication won\'t work. Page URL: ' + pageUrl);
          reportError(error);
          console.log('* ' + error.message);
        }
      }
      onAccessToken(accessToken, {
        expiresIn: accessTokenExpiresIn,
        expiresAt: accessTokenExpiresAt,
        userSessionEphemeral
      });
      await authenticateWithAccessToken();
    } else if (hasAccessToken({ authCookieConfig })) {
      await authenticateWithAccessToken();
    } else {
      if (onNotAuthenticated) {
        onNotAuthenticated();
      }
    }
    setIsAuthenticationLoading(false);
  });

  // Watches the user's "access token": when it expires, it calls `onAccessTokenExpired()`.
  useAccessTokenExpiryWatcher({
    onAccessTokenExpired() {
      // Reset `user` and `accessTokenExpiresAt` in state.
      setUser();
      setAccessTokenExpiresAt();
      if (onNotAuthenticated) {
        onNotAuthenticated();
      }
    },
    accessTokenExpiresAt,
    authCookieConfig
  });

  return { isAccessTokenInUrl };
}
