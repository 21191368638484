import React from 'react';
import { Alert, Text } from '@acadeum/ui';

import { canEnrollInSession } from '../../../../helpers/course';

import { CourseSection } from '../CourseSection';

export function SingleCourseSection({
  section,
  course,
  onRecommend
}) {

  const otherSessions = course.sessions
    .filter(session => session.id !== section.session.id)
    .filter(session => canEnrollInSession(session.lastAddDate, session.startDate));

  return (
    <div>
      <Text as="h4" variant="subtitle1" color="black" mb="sm">
        Course Section
      </Text>

      <CourseSection
        section={section}
        session={section.session}
        course={course}
        onRecommend={onRecommend}
      />

      {otherSessions.length > 0 && (
        <>
          <br/>
          <Alert action={{
            content: 'See All',
            url: `/courses/${course.id}`
          }}>
            This course has {otherSessions.length} more session{otherSessions.length === 1 ? '' : 's'} available.
          </Alert>
        </>
      )}
    </div>
  );
}
