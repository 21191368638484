import { useContext } from 'react';

import { RadioCardGroupContext } from './Group';

export function useRadioCardGroupRadioCard() {
  const context = useContext(RadioCardGroupContext);

  if (!context) {
    throw new Error('No RadioCard.Group was provided. Your component must be wrapped in a <RadioCard.Group> component.');
  }

  return context;
}
