import { addToast, hideToast, updateToast } from './toastObserver';
import type { ToastId, ToastVariant, ToastData, ToastOptions, ToastContent, UpdateToastOptions } from './types';

function dispatchToast<TData extends ToastData>(
  variant: ToastVariant,
  content: ToastContent<TData>,
  options?: ToastOptions<TData>
): ToastId {
  const id = getNextId();
  addToast({ ...options, content, variant: (options && options.variant) || variant, id });
  return id;
}

const createToastByVariant = (variant: ToastVariant) => <TData extends ToastData>(content: ToastContent<TData>,
  options?: ToastOptions<TData>) => dispatchToast<TData>(variant, content, options);

export const toast = <TData extends ToastData>(
  content: ToastContent<TData>,
  options?: ToastOptions<TData>
) => dispatchToast('default', content, options);

toast.update = <TData extends ToastData>(id: ToastId, options: UpdateToastOptions<TData>) => {
  updateToast({ id, content: options.render, ...options });
};
toast.loading = <TData extends ToastData>(
  content: ToastContent<TData>,
  options?: ToastOptions<TData>
) => dispatchToast<TData>('default', content, {
  isLoading: true,
  autoClose: false,
  closeOnClick: false,
  ...options
});
toast.default = createToastByVariant('default');
toast.success = createToastByVariant('success');
toast.warn = createToastByVariant('warn');
toast.error = createToastByVariant('error');
toast.dismiss = hideToast;
toast.promise = handlePromise;

interface ToastPromiseParams<
  TData extends ToastData,
  TError extends ToastData,
  TPending extends ToastData
> {
  pending?: string | UpdateToastOptions<TPending> | false;
  success?: string | UpdateToastOptions<TData>;
  error?: string | UpdateToastOptions<TError>;
}

function handlePromise<TData extends ToastData, TError extends ToastData, TPending extends ToastData>(
  promise: Promise<TData> | (() => Promise<TData>),
  { pending, error, success }: ToastPromiseParams<TData, TError, TPending> = {},
  options?: ToastOptions
) {
  let id: ToastId;

  if (pending !== false) {
    if (pending) {
      id = typeof pending === 'string'
        ? toast.loading(pending, options)
        : toast.loading(pending.render, {
          ...options,
          ...(pending as ToastOptions)
        });
    } else {
      id = toast.loading('The requested action is in progress...', options);
    }
  }

  const resetParams = {
    isLoading: undefined,
    autoClose: undefined,
    closeOnClick: undefined
  };

  const resolver = <T>(
    variant: ToastVariant,
    input: string | UpdateToastOptions<T> | undefined,
    result: T
  ) => {
    // Remove the toast if the input has not been provided. This prevents the toast from hanging
    // in the pending state if a success/error toast has not been provided.
    if (input == null) {
      toast.dismiss(id);
      return;
    }

    const params = typeof input === 'string' ? { render: input } : input;
    const toastOptions = {
      ...resetParams,
      ...options,
      ...params,
      variant,
      data: result
    };

    // if the id is set we know that it's an update
    if (id) {
      toast.update(id, toastOptions as UpdateToastOptions);
    } else {
      // using toast.promise without loading
      toast(params?.render, toastOptions as ToastOptions);
    }

    return result;
  };

  const promise_ = typeof promise === 'function' ? promise() : promise;
  promise_
    .then(result => resolver<TData>('success', success, result))
    .catch(err => resolver<TError>('error', error, err));

  return promise_;
}

const getNextId: () => ToastId = (() => {
  let id = 0;
  return () => id = id === Number.MAX_SAFE_INTEGER ? 0 : ++id;
})();
