import type { RowData } from '@tanstack/react-table';
import uniq from 'lodash/uniq';

import type { Course } from '@acadeum/types';

import { Select } from '../../../Select';
import type { FilterItemObject } from '../types';

export const createTableCourseFilter = <TData extends RowData>({
  getCourses,
  getCourse = row => (row['section']['session']['course'] as Course),
  label = 'Course'
}: {
  getCourse?: (row: TData) => Pick<Course, 'title' | 'id'>,
  getCourses?: (row: TData) => Pick<Course, 'title' | 'id'>[],
  label?: string;
  columnId?: string
} = {}): FilterItemObject<TData> => {
  return {
    id: 'course',
    filter(row, value) {
      if (getCourses) {
        return getCourses(row).some(Course => Course.title === value);
      } else {
        return getCourse(row).title === value;
      }
    },
    render({ getValue, rows, setFilterValues }) {
      function getCourseNameIfFits(course) {
        return course.title;
      }

      let Courses: string[];

      if (getCourses) {
        Courses = rows.reduce<string[]>((all, row) =>
          all.concat(
            getCourses(row)
              .map((course) => getCourseNameIfFits(course))
              .filter(_ => _)
          ), []);
      } else {
        Courses = rows
          .map((row) => getCourseNameIfFits(getCourse(row)))
          .filter(_ => _);
      }

      Courses = uniq(Courses);

      const options = [
        { label: 'All', value: null },
        ...Courses.map((course) => ({
          label: course,
          value: course
        }))
      ];

      return (
        <Select
          isFilter
          label={label}
          value={getValue() ?? null}
          options={options}
          onChange={(value, option) => {
            setFilterValues(prev => ({
              ...prev,
              course: option
            }));
          }}
        />
      );
    }
  };
};
