import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ChevronDownIcon } from '@acadeum/icons';

import { useAccordion } from '../../utils/useAccordion';

import { BaseButton } from '../BaseButton';
import { Text } from '../Text';

import styles from './Accordion.module.scss';

export interface AccordionProps {
  title: string;
  children: React.ReactNode;
  defaultOpen?: boolean;
  variant?: 'text';
}

export const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  variant,
  defaultOpen = false
}) => {
  const { show, onToggle, panelRef, panelStyle, panelId, controlId } = useAccordion({ defaultOpen });

  return (
    <div className={classNames(styles.Accordion)}>
      <Text as="h3" mb="sm">
        <BaseButton
          id={controlId}
          aria-expanded={show}
          aria-controls={panelId}
          onClick={onToggle}
          className={classNames(styles.button)}
        >
          <Text
            variant={variant === 'text' ? 'subtitle2' : 'subtitle1'}
            color={variant === 'text' ? 'accent' : 'black'}
            textTransform={variant === 'text' ? 'uppercase' : undefined}
          >
            {title}
          </Text>
          <ChevronDownIcon className={classNames(styles.icon, {
            [styles.open]: show
          })}/>
        </BaseButton>
      </Text>
      <div
        id={panelId}
        role="region"
        aria-labelledby={controlId}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={panelRef}
        style={panelStyle}
        className={classNames({
          [styles.open]: show
        })}
      >
        {children}
      </div>
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  defaultOpen: PropTypes.bool
};
