// This file must be equal to `JITUApi/helpers/coursePricing.js`.

export const PRICING_MODEL = {
  'fixed-fee': {
    title: 'Fixed markup $',
    valueTitle: 'Your Markup',
    valueType: 'currency',
    validate({ value }) {
      if (!(value && value > 0)) {
        throw new Error(`Incorrect "value" ${value} for "fixed-fee" pricing`);
      }
    }
  },
  'percent-fee': {
    title: 'Fixed markup %',
    valueTitle: 'Your Markup',
    valueType: 'percent',
    validate({ value }) {
      if (!(value && value > 0)) {
        throw new Error(`Incorrect "value" ${value} for "percent-fee" pricing`);
      }
    }
  },
  'per-credit': {
    title: 'Per credit hour rate',
    valueTitle: 'Your rate',
    valueType: 'currency',
    validate({ value }) {
      if (!(value && value > 0)) {
        throw new Error(`Incorrect "value" ${value} for "per-credit" pricing`);
      }
    }
  },
  'as-is': {
    title: 'Pass-through cost',
    validate({ value }) {
      if (value !== undefined) {
        throw new Error('"value" can\'t be present for "as-is" pricing');
      }
    }
  },
  'fixed': {
    title: 'Fixed price for all courses',
    valueTitle: 'Your cost',
    valueType: 'currency',
    validate({ value }) {
      if (!(value && value > 0)) {
        throw new Error(`Incorrect "value" ${value} for "fixed" pricing`);
      }
    }
  }
};

export function validateCoursePricing(pricing) {
  for (const entry of pricing) {
    validateCoursePricingEntry(entry);
  }
}

function validateCoursePricingEntry(pricing) {
  if (!PRICING_MODEL[pricing.model]) {
    throw new Error(`Unsupported pricing model: ${pricing.model}`);
  }
  PRICING_MODEL[pricing.model].validate(pricing);
}

export function formatPricingModelValue(model, value) {
  switch (PRICING_MODEL[model].valueType) {
    case 'percent':
      return value + '%';
    case 'currency':
      return '$' + value;
    default:
      return value;
  }
}
