import reportError from './reportError.js';
import formatDateYearMonthDayUtc from './formatDateYearMonthDayUtc.js';
import convertLocalDateToUtcDateWithSameTime from './convertLocalDateToUtcDateWithSameTime.js';

const NUMERIC_MONTH_FORMATTERS = {
  'en': new Intl.DateTimeFormat('en', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
  })
};

const NUMERIC_MONTH_FORMATTERS_UTC = {
  'en': new Intl.DateTimeFormat('en', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    timeZone: 'UTC'
  })
};

const NUMERIC_MONTH_FORMATTERS_WITH_TIME = {
  'en': new Intl.DateTimeFormat('en', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })
};

const NUMERIC_MONTH_FORMATTERS_WITH_TIME_UTC = {
  'en': new Intl.DateTimeFormat('en', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC'
  })
};

const LONG_MONTH_FORMATTERS = {
  'en': new Intl.DateTimeFormat('en', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  })
};

const LONG_MONTH_FORMATTERS_UTC = {
  'en': new Intl.DateTimeFormat('en', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    timeZone: 'UTC'
  })
};

const LONG_MONTH_FORMATTERS_WITH_TIME = {
  'en': new Intl.DateTimeFormat('en', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })
};

const LONG_MONTH_FORMATTERS_WITH_TIME_UTC = {
  'en': new Intl.DateTimeFormat('en', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC'
  })
};

const LONG_MONTH_FORMATTERS_NO_YEAR = {
  'en': new Intl.DateTimeFormat('en', {
    day: 'numeric',
    month: 'long'
  })
};

const LONG_MONTH_FORMATTERS_NO_YEAR_UTC = {
  'en': new Intl.DateTimeFormat('en', {
    day: 'numeric',
    month: 'long',
    timeZone: 'UTC'
  })
};

const LONG_MONTH_FORMATTERS_WITH_TIME_NO_YEAR = {
  'en': new Intl.DateTimeFormat('en', {
    day: 'numeric',
    month: 'long',
    hour: '2-digit',
    minute: '2-digit'
  })
};

const LONG_MONTH_FORMATTERS_WITH_TIME_NO_YEAR_UTC = {
  'en': new Intl.DateTimeFormat('en', {
    day: 'numeric',
    month: 'long',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC'
  })
};

const SHORT_MONTH_FORMATTERS = {
  'en': new Intl.DateTimeFormat('en', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  })
};

const SHORT_MONTH_FORMATTERS_UTC = {
  'en': new Intl.DateTimeFormat('en', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    timeZone: 'UTC'
  })
};

const SHORT_MONTH_FORMATTERS_NO_YEAR = {
  'en': new Intl.DateTimeFormat('en', {
    day: 'numeric',
    month: 'short'
  })
};

const SHORT_MONTH_FORMATTERS_NO_YEAR_UTC = {
  'en': new Intl.DateTimeFormat('en', {
    day: 'numeric',
    month: 'short',
    timeZone: 'UTC'
  })
};

const SHORT_MONTH_AND_WEEKDAY_FORMATTERS_NO_YEAR = {
  'en': new Intl.DateTimeFormat('en', {
    day: 'numeric',
    weekday: 'short',
    month: 'short'
  })
};

const SHORT_MONTH_AND_WEEKDAY_FORMATTERS_NO_YEAR_UTC = {
  'en': new Intl.DateTimeFormat('en', {
    day: 'numeric',
    weekday: 'short',
    month: 'short',
    timeZone: 'UTC'
  })
};

/**
 * Formats a date.
 * @param  {Date} date
 * @param  {string} [options.month] — One of: "long" (example: "September 4, 1986"), "short" (example: "Sep 4, 1986"), `undefined` (default; example: "12/31/2020").
 * @param  {boolean} [options.utc] — Is `false` by default. When set to `true`, will format the date in UTC+0 timezone rather than the user's "current" timezone. For example, if a `date` is `Jan 1st, 2000, 11:00:00` in UTC+0 timezone, then formatting it with `utc: true` flag will produce "Jan 1st, 2000" regardless of the user's "current" timezone, and formatting it without `utc: true` flag will format it as "Dec 31st, 1999" for a user from United States Minor Outlying Islands (UTC-12), "Jan 1st, 2000" for a user from China (UTC+8), "Jan 2nd, 2000" for a user from Kiribati (UTC+14). So `utc: true` flag should be used when a certain event has happened (or will happen) on a certain date **in a certain timezone**. For example, "The USA were established on July 4, 1776" or "Alice was born on Jan 1st, 1999".
 * @param  {string} [options.locale] — Is "en" by default.
 * @return {string}
 */
export default function formatDate(date, options = {}) {
  if (!(date instanceof Date)) {
    console.error('`formatDate()` argument must be a `Date`');
    // throw new Error('`formatDate()` argument must be a `Date`');
  }

  if (!date) {
    throw new Error('date not supplied to "formatDate"');
  }

  if (options.utc === undefined) {
    // Don't abort the code execution if someone forgot the `utc` parameter:
    // it's considered a better UX to show the user something rather than a blank page.
    if (typeof window !== 'undefined') {
      reportError('`utc` parameter is required when calling `formatDate()');
    } else {
      throw new Error('`utc` parameter is required when calling `formatDate()');
    }
  }

  const formattedDate = formatDateForLocale(date, options);
  if (formattedDate !== undefined) {
    return formattedDate;
  }

  switch (options.locale) {
    case 'en':
      if (typeof window !== 'undefined') {
        reportError(`Couldn't format date using options:\n${JSON.stringify(options, null, 2)}`);
        if (options.utc) {
          return formatDateYearMonthDayUtc(date);
        } else {
          return formatDateYearMonthDayUtc(convertLocalDateToUtcDateWithSameTime(date));
        }
      } else {
        throw new Error(`Couldn't format date using options:\n${JSON.stringify(options, null, 2)}`);
      }
    default:
      // Fall back to "en" locale.
      return formatDate(date, { ...options, locale: 'en' });
  }
}

function formatDateForLocale(date, options) {
  const { locale } = options;

  // "long" month format: "September 4, 1986".
  if (options.month === 'long') {
    if (options.year === false) {
      if (options.time) {
        if (options.utc) {
          if (LONG_MONTH_FORMATTERS_WITH_TIME_NO_YEAR_UTC[locale]) {
            return LONG_MONTH_FORMATTERS_WITH_TIME_NO_YEAR_UTC[locale].format(date);
          }
        }
        else {
          if (LONG_MONTH_FORMATTERS_WITH_TIME_NO_YEAR[locale]) {
            return LONG_MONTH_FORMATTERS_WITH_TIME_NO_YEAR[locale].format(date);
          }
        }
      }
      else {
        if (options.utc) {
          if (LONG_MONTH_FORMATTERS_NO_YEAR_UTC[locale]) {
            return LONG_MONTH_FORMATTERS_NO_YEAR_UTC[locale].format(date);
          }
        } else {
          if (LONG_MONTH_FORMATTERS_NO_YEAR[locale]) {
            return LONG_MONTH_FORMATTERS_NO_YEAR[locale].format(date);
          }
        }
      }
    }
    if (options.time) {
      if (options.utc) {
        if (LONG_MONTH_FORMATTERS_WITH_TIME_UTC[locale]) {
          return LONG_MONTH_FORMATTERS_WITH_TIME_UTC[locale].format(date);
        }
      } else {
        if (LONG_MONTH_FORMATTERS_WITH_TIME[locale]) {
          return LONG_MONTH_FORMATTERS_WITH_TIME[locale].format(date);
        }
      }
    } else {
      if (options.utc) {
        if (LONG_MONTH_FORMATTERS_UTC[locale]) {
          return LONG_MONTH_FORMATTERS_UTC[locale].format(date);
        }
      } else {
        if (LONG_MONTH_FORMATTERS[locale]) {
          return LONG_MONTH_FORMATTERS[locale].format(date);
        }
      }
    }
    // // Fall back to "numeric" month if `Intl` isn't supported.
    // return formatDate(date, { ...options, month: 'numeric' });
  }
  // "short" month format: "Sep 4, 1986".
  else if (options.month === 'short') {
    if (options.year === false) {
      if (options.weekday === 'short') {
        if (options.utc) {
          if (SHORT_MONTH_AND_WEEKDAY_FORMATTERS_NO_YEAR_UTC[locale]) {
            return SHORT_MONTH_AND_WEEKDAY_FORMATTERS_NO_YEAR_UTC[locale].format(date);
          }
        } else {
          if (SHORT_MONTH_AND_WEEKDAY_FORMATTERS_NO_YEAR[locale]) {
            return SHORT_MONTH_AND_WEEKDAY_FORMATTERS_NO_YEAR[locale].format(date);
          }
        }
      }
      if (options.utc) {
        if (SHORT_MONTH_FORMATTERS_NO_YEAR_UTC[locale]) {
          return SHORT_MONTH_FORMATTERS_NO_YEAR_UTC[locale].format(date);
        }
      } else {
        if (SHORT_MONTH_FORMATTERS_NO_YEAR[locale]) {
          return SHORT_MONTH_FORMATTERS_NO_YEAR[locale].format(date);
        }
      }
    }
    if (options.utc) {
      if (SHORT_MONTH_FORMATTERS_UTC[locale]) {
        return SHORT_MONTH_FORMATTERS_UTC[locale].format(date);
      }
    } else {
      if (SHORT_MONTH_FORMATTERS[locale]) {
        return SHORT_MONTH_FORMATTERS[locale].format(date);
      }
    }
    // // Fall back to "numeric" month if `Intl` isn't supported.
    // return formatDate(date, { ...options, month: 'numeric' });
  }

  if (options.time) {
    if (options.utc) {
      if (NUMERIC_MONTH_FORMATTERS_WITH_TIME_UTC[locale]) {
        return NUMERIC_MONTH_FORMATTERS_WITH_TIME_UTC[locale].format(date);
      }
    } else {
      if (NUMERIC_MONTH_FORMATTERS_WITH_TIME[locale]) {
        return NUMERIC_MONTH_FORMATTERS_WITH_TIME[locale].format(date);
      }
    }
  } else {
    // Default format: "12/31/2020".
    if (options.utc) {
      if (NUMERIC_MONTH_FORMATTERS_UTC[locale]) {
        return NUMERIC_MONTH_FORMATTERS_UTC[locale].format(date);
      }
    } else {
      if (NUMERIC_MONTH_FORMATTERS[locale]) {
        return NUMERIC_MONTH_FORMATTERS[locale].format(date);
      }
    }
  }
}
