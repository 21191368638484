import React from 'react';

import Section from '../../components/Section';

import './Help.sass';

export default function Help() {
  return (
    <div className="help-page">
      <Section title="Help">
        <h2 className="help-page__heading">
          Have a question about how to use Acadeum? We are here to help!
        </h2>
        <p className="help-page__descripton">
          We are available through chat Monday - Friday, 8:30 am to 5:30 pm (CST). Simply click the green chat icon in the bottom right hand corner to send us a message! You can also email us your questions or comments at <a href="mailto:support@acadeum.com">support@acadeum.com</a>. If you prefer to speak to us over the phone, you can reach us directly at (754) 799-3950, Monday - Friday, 8:30 am to 5:30 pm (CST).
        </p>
      </Section>
    </div>
  );
}

Help.meta = () => ({
  title: 'Help'
});

Help.breadcrumbs = () => ['Help'];
