import { Accordion, Link, Text } from '@acadeum/ui';

export function CoursePrerequisitesSection({
  course
}) {
  const { prerequisitesText } = course;
  const hasPrerequisitesText = Boolean(prerequisitesText);
  const { hasPrerequisites } = course;

  const hasCoursePrerequisites = Boolean(hasPrerequisites && hasPrerequisitesText);

  let content;
  if (hasCoursePrerequisites) {
    content = (
      <>
        {hasPrerequisitesText && (
          prerequisitesText
        )}

        {course.prerequisites.length > 0 && (
          <div style={{
            overflow: 'hidden',
            marginTop: hasPrerequisitesText ? '1rem' : 0
          }}>
            {course.prerequisites.map((prerequisite, i) => (
              <div key={i}>
                <CoursePrerequisite prerequisite={prerequisite}/>
              </div>
            ))}
          </div>
        )}
      </>
    );
  } else {
    content = 'This course does not specify any prerequisites.';
  }

  return (
    <Accordion title="Prerequisites" defaultOpen={hasCoursePrerequisites}>
      <Text color="grey" as="div">
        {content}
      </Text>
    </Accordion>
  );
}

function CoursePrerequisite({ prerequisite }) {
  if (!prerequisite.course) {
    return prerequisite.code;
  }
  return (
    <>
      <Link
        to={`/courses/${prerequisite.course.id}`}
        gaLabel={`Prereq ${prerequisite.course.code} ${prerequisite.course.title}`}
      >
        {prerequisite.course.code}
      </Link>
      <br/>
      {prerequisite.course.title}
    </>
  );
}
