import { createObserver } from '@acadeum/helpers';

import type { ToastListItem, ToastId, ToastData, UpdateToastListItem } from './types';

interface Events<TData = ToastData> {
  addToast: ToastListItem<TData>;
  updateToast: UpdateToastListItem<TData>;
  hideToast: ToastId | undefined;
}

export const {
  addToast,
  onAddToast,
  hideToast,
  onHideToast,
  updateToast,
  onUpdateToast
} = createObserver<Events>('addToast', 'updateToast', 'hideToast');
