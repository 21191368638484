import type { CountryCode } from 'libphonenumber-js';
import parsePhoneNumber from 'libphonenumber-js';

/**
 * Parses an arbitrarily formatted international phone number
 * to an E.164 phone number value corresponding to a possible
 * phone number.
 * Is used in `ASP-site/src/helpers/dataImportSchema.js`.
 * @param  {string} value — An arbitrarily formatted international phone number.
 * @return {string} E.164 phone number value corresponding to a possible phone number.
 */
export function parsePhone(value: string, { defaultCountry }: { defaultCountry?: CountryCode } = {}) {
  const phoneNumber = parsePhoneNumber(value, { defaultCountry });
  return phoneNumber && phoneNumber.number;
}
