import { ReduxModule } from 'react-pages';

const redux = new ReduxModule();

export const fetchStudentRequest = redux.action(
  (id) => (http) => http.get(`/course-registration-requests/${id}`),
  'studentRequest'
);

export const fetchNewStudentRequests = redux.action(
  () => (http) => http.get('/course-registration-requests', { type: 'requested' }),
  (state, studentRequests) => {
    return {
      ...state,
      studentRequests,
      newStudentRequestsCount: studentRequests.length
    };
  }
);

export const fetchFinalizedStudentRequests = redux.action(
  () => (http) => http.get('/course-registration-requests', { type: 'finalized' }),
  (state, studentRequests) => {
    return {
      ...state,
      studentRequests,
      finalizedStudentRequestsCount: studentRequests.length
    };
  }
);

export const fetchStudentRequestsForExport = redux.action(
  (ids, { type }) => (http) => {
    return http.get('/course-registration-requests', {
      dataExport: '✓',
      // Compress the list of IDs so that it fits into the URL query max length limit.
      // https://github.com/Acadeum/Tickets/issues/1293
      ids: ids && ids.sort((a, b) => a - b).map((id, i) => i === 0 ? id : id - ids[i - 1]).join('.'),
      type
    });
  }
);

export const approveStudentRequest = redux.action(
  (id, extraData) => (http) => {
    Intercom('trackEvent', 'approve student request', { id });
    return http.post(`/course-registration-requests/${id}/approve`, extraData);
  }
);

export const denyStudentRequest = redux.action(
  (id, { reason, reasonNotes }) => (http) => {
    Intercom('trackEvent', 'deny student request', {
      id,
      reason,
      reasonNotes
    });
    return http.post(`/course-registration-requests/${id}/deny`, {
      reason,
      reasonNotes
    });
  }
);

export const getStudentRequestsCounts = redux.action(
  () => (http) => http.get('/counts/student-requests'),
  (state, counts) => ({
    ...state,
    ...counts
  })
);

export const setNewStudentRequestsCount = redux.simpleAction(
  (state, count) => ({
    ...state,
    newStudentRequestsCount: count
  })
);

export const setFinalizedStudentRequestsCount = redux.simpleAction(
  (state, count) => ({
    ...state,
    finalizedStudentRequestsCount: count
  })
);

export default redux.reducer();
