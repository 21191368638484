// This file should be identical to `cc-frontend/src/helpers/formatPhone.js`.

import parsePhoneNumber from 'libphonenumber-js';

// Usage Examples.
//
// String number argument:
//
// formatPhone('+12133734253') === '(213) 373-4253'
// formatPhone('+12133734253', 'INTERNATIONAL') === '+1 213 373 42 53'
// formatPhone('+12133734253', 'URI') === 'tel:+12133734253'
//
// Contact object argument. National format:
//
// formatPhone({ phone: '+12133734253' }) === '(213) 373-4253'
// formatPhone({ phone: '+12133734253', phoneExt: '1234' }) === '(213) 373-4253 ext. 1234'
//
// Contact object argument. International format:
//
// formatPhone({ phone: '+12133734253' }, 'INTERNATIONAL') === '+1 213 373 4253'
// formatPhone({ phone: '+12133734253', phoneExt: '1234' }, 'INTERNATIONAL') === '+1 213 373 4253 ext. 1234'
//
// Contact object argument. URI format (used in hyperlinks):
//
// formatPhone({ phone: '+12133734253' }, 'URI') === 'tel:+12133734253'
// formatPhone({ phone: '+12133734253', phoneExt: '1234' }, 'URI') === 'tel:+12133734253;ext=1234'
//
export default function formatPhone(number, options = {}) {
  if (!number) {
    throw new Error('"formatPhone()" argument is required');
  }

  if (!E164_PHONE_NUMBER.test(number)) {
    throw new Error(`Invalid E.164 phone number: ${number}`);
  }

  // Convert `format` argument to `format` option.
  if (typeof options === 'string') {
    options = {
      format: options
    };
  }

  // "RFC3966" format is aliased as "URI".
  if (options.format === 'URI') {
    options = {
      ...options,
      format: 'RFC3966'
    };
  }

  // Parse the phone number.
  const parsedNumber = parsePhoneNumber(number);
  if (parsedNumber) {
    if (options.ext) {
      parsedNumber.setExt(options.ext);
    }
    return parsedNumber.format(options.format || 'NATIONAL');
  }

  // The phone number was not recognized. Output as is.
  let nonFormattedNumber = number;
  if (options.ext) {
    nonFormattedNumber += ' ext. ' + options.ext;
  }
  return nonFormattedNumber;
}

const E164_PHONE_NUMBER = /^\+\d+$/;
