import type { ReactNode } from 'react';
import { createContext, useContext, useState, useMemo } from 'react';

interface TableContextProps {
  showHorizontalScroll: boolean;
  setShowHorizontalScroll: (showHorizontalScroll: boolean) => void;
}

const TableContext = createContext<TableContextProps | undefined>(undefined);

export const useTableContext = () => {
  const context = useContext(TableContext);
  if (!context) {
    throw new Error('useTableContext must be used within a TableContextProvider');
  }
  return context;
};

interface TableContextProviderProps {
  children: ReactNode;
}

export const TableContextProvider = ({ children }: TableContextProviderProps) => {
  const [showHorizontalScroll, setShowHorizontalScroll] = useState(true);

  const value = useMemo(() => ({
    showHorizontalScroll,
    setShowHorizontalScroll
  }), [showHorizontalScroll]);

  return (
    <TableContext.Provider value={value}>
      {children}
    </TableContext.Provider>
  );
};
