import { useCallback } from 'react';
import { useIntl } from 'react-intl';

/**
 * This React hook uses `react-intl` which means that
 * advanced `react-intl` features are available
 * (such as plurals, number formatting, React "tags").
 * @param  {string} namespace
 * @return {function(string, Object=): string} `t()`
 */

// eslint-disable-next-line
type TParams = Record<string, any> & {
  /** If the value is true namespace is ignored */
  global?: boolean;
  /**
  `required: false` option is used when getting messages for action "reason" selects:
   * If a reason title has a description text, it is returned.
   * If a reason title doesn't have a description text, `null` is returned.
   So some "reasons" do have a description text and others don't.
   Using `required: false`, a common component could be written for "reason" selection:
   `<ReasonFormField labelsPath="EnrollmentsTable.dropReason" options={['REASON_KEY_1', 'REASON_KEY_2', ...]}/>`.
   */
  required?: false;
  /** Default message to be used when translation is not found */
  defaultMessage?: string;
}

type TCallBack = (key: string, params?: TParams) => string;

export function useTranslate(namespace?: string) {
  const intl = useIntl();
  return useCallback<TCallBack>((key, params) => {
    let prefix = namespace ? namespace + '.' : '';
    let defaultMessage = params?.defaultMessage;
    if (params) {
      if (params.global) {
        prefix = '';
        params = {
          ...params,
          global: undefined
        };
      }
      if (params.required === false) {
        defaultMessage = OPTIONAL_MESSAGE_MARKER;
      }
    }
    let result = intl.formatMessage({
      id: prefix + key,
      defaultMessage
    }, params);
    if (defaultMessage === OPTIONAL_MESSAGE_MARKER && result === OPTIONAL_MESSAGE_MARKER) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result = undefined;
    }
    return result;
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    intl,
    namespace,
    // When `t()` is used as a dependency for `useMemo()` when translating
    // some messages, make it change with the selected language.
    intl.locale
  ]);
}

const OPTIONAL_MESSAGE_MARKER = '__OPTIONAL__';
