import { rtkApi } from './rtkApi';

import { getContactsApi } from '@acadeum/api';

export const {
  useFetchContactQuery,
  useFetchContactsQuery,
  useCreateContactMutation,
  useUpdateContactMutation,
  useRemoveContactMutation
} = getContactsApi(rtkApi);
