import { useState, useMemo } from 'react';

import { ModalSelectComboboxContext, ModalUIContext, ModalDatePickerContext, ModalLexicalEditorContext } from './context';

export function ModalSelectComboboxContextProvider({ children }) {
  const [enforceFocus, setEnforceFocus] = useState(true);

  const context = useMemo(() => ({
    enforceFocus,
    onOpenCombobox() {
      setEnforceFocus(false);
    },
    onCloseCombobox() {
      setEnforceFocus(true);
    }
  }), [enforceFocus]);

  return (
    <ModalSelectComboboxContext.Provider value={context}>
      {children}
    </ModalSelectComboboxContext.Provider>
  );
}

export function ModalLexicalEditorContextProvider({ children }) {
  const [enforceFocus, setEnforceFocus] = useState(true);

  const context = useMemo(() => ({
    enforceFocus,
    onShow() {
      setEnforceFocus(false);
    },
    onClose() {
      setEnforceFocus(true);
    }
  }), [enforceFocus]);

  return (
    <ModalLexicalEditorContext.Provider value={context}>
      {children}
    </ModalLexicalEditorContext.Provider>
  );
}

export function ModalUIContextProvider({ children }) {
  const [hasScroll, setHasScroll] = useState(false);
  const [hasFooter, setHasFooter] = useState(false);
  const [hasHeader, setHasHeader] = useState(false);

  const context = useMemo(() => ({
    hasScroll,
    setHasScroll,
    hasFooter,
    setHasFooter,
    hasHeader,
    setHasHeader
  }), [hasScroll, hasFooter, hasHeader]);

  return (
    <ModalUIContext.Provider value={context}>
      {children}
    </ModalUIContext.Provider>
  );
}

export function ModalDatePickerContextProvider({ children }) {
  return (
    <ModalDatePickerContext.Provider value>
      {children}
    </ModalDatePickerContext.Provider>
  );
}
