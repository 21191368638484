// import React from 'react';

import JSONEditor from '../../JSONEditor';

export default function getJsonInputParams({
  required
}) {
  // if (hint !== false) {
  //   description = (
  //     <>
  //       <span>
  //         <a href="https://en.wikipedia.org/wiki/JSON#Example" target="_blank">JSON</a> is a simple nested human-readable structured data format. JSON data is made of keys and their values. Keys are always double-quoted. Values are only double quoted if they're strings. Possible value types: strings, numbers, flags (true/false). A value can also be a nested JSON object and and an array (square brackets) of any values. If a value is missing for a non-required key then simply omit such key — the program will figure it out and will output whatever fits this particular missing key (e.g. <code>"—"</code>, <code>"(empty)"</code>, <code>""</code>, etc). The sole exception for the missing key value rule are arrays which should be explicitly defined as empty arrays (<code>[]</code>) even if there's no data inside them.
  //       </span>
  //       {description && <br/>}
  //       {description}
  //     </>
  //   );
  // }
  return {
    InputComponent: JSONEditor,
    required
  };
}
