import React, { memo } from 'react';

import type { Section } from '@acadeum/types/src/models';

import { Row } from '../../../Row';
import { Col } from '../../../Col';

import { formatCurrency } from '../../../../utils/format';

import { SectionContent } from '../SectionContent';

import styles from '../../SectionCard.module.scss';

export interface AddAndDropDateCostRowProps {
  section: Pick<Section,
    'lastAddDate' |
    'lastDropDate'
  >;
  cost?: Section['cost'];
  costActionsElement?: React.ReactNode;
  addDateActionsElement?: React.ReactNode;
}

export const AddAndDropDateCostRow = memo<AddAndDropDateCostRowProps>(({
  section,
  cost,
  costActionsElement,
  addDateActionsElement
}) => {
  return (
    <Row as="dl" className={styles.row}>
      <Col col={6} md={3}>
        <SectionContent
          type="date"
          label={(
            <>
              Add Date
              {addDateActionsElement}
            </>
          )}
          value={section.lastAddDate}
        />
      </Col>

      <Col col={6} md={3}>
        <SectionContent
          type="date"
          label="Drop Date"
          value={section.lastDropDate}
        />
      </Col>

      {typeof cost === 'number' && (
        <Col md={6}>
          <SectionContent
            label={(
              <>
                Cost
                {costActionsElement}
              </>
            )}
            value={formatCurrency(cost)}
          />
        </Col>
      )}
    </Row>
  );
});
