import type { FC } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { Actions, Button, toast, useScreenSize } from '@acadeum/ui';
import { EditIcon } from '@acadeum/icons';
import { useTranslate } from '@acadeum/translate';

import { useClientSettingsCourseSearchFilters } from '../../../providers/clientSettings';

import EditSavedSearchModal from '../EditSavedSearchModal';
import type { SavedSearchState, SearchStateAlgoliaV6 } from '../searchStateUtils';
import { decodeSearchState } from '../searchStateUtils';
import { DRAFT_SAVED_SEARCH_NAME } from '../CourseSearch_';

import styles from './SavedSearches.module.scss';

interface SavedSearches {
  searchState: SavedSearchState;
}

export interface SavedSearchesProps {
  savedSearches: Record<string, SavedSearches>;
  latestSavedSearchName: string;
  onSearchStateChange: (savedSearches: SearchStateAlgoliaV6) => void;
  view: 'sections' | 'courses';
  scope: 'all' | 'own';
  searchState: SearchStateAlgoliaV6;
  draftSearch: SearchStateAlgoliaV6;
  setDraftSearch: (draftSearch: SearchStateAlgoliaV6) => void;
  updateLatestSavedSearchName: (latestSavedSearchName: string) => void;
}

// Quote from Caleb: "We can add a limit of 6-8 saved filter tabs or however many fit the length of the filter box."
// This value will cover most screens Full HD resolution, but if the name is too long, it will go out of bounds
const MAX_LENGTH_SAVED_SEARCHES = 6;

const SavedSearches: FC<SavedSearchesProps> = ({
  savedSearches,
  latestSavedSearchName,
  onSearchStateChange,
  searchState,
  draftSearch,
  setDraftSearch,
  view,
  scope,
  updateLatestSavedSearchName
}) => {
  const t = useTranslate('CourseSearch');
  const { isSmallScreen } = useScreenSize();

  const { renameCourseSearchFilter, removeCourseSearchFilter } = useClientSettingsCourseSearchFilters();

  useEffect(() => {
    const selectedSavedSearch = savedSearches[latestSavedSearchName];
    if (selectedSavedSearch) {
      onSearchStateChange(decodeSearchState(selectedSavedSearch.searchState, view));
    }
  }, [savedSearches]);

  const onRemove = async (name) => {
    await removeCourseSearchFilter(view, scope, name);
    updateLatestSavedSearchName(name === latestSavedSearchName ? DRAFT_SAVED_SEARCH_NAME : latestSavedSearchName);
    if (name === latestSavedSearchName) {
      onSearchStateChange({});
    }
  };

  const onUpdateSavedSearchTitle = async ({ previousTitle, title }) => {
    if (savedSearches[title] || title === DRAFT_SAVED_SEARCH_NAME) {
      return toast.warn(t('error.duplicateTitle'));
    }
    await renameCourseSearchFilter(view, scope, previousTitle, title);
    updateLatestSavedSearchName(title);
  };

  const onSelect = (name) => {
    if (name === DRAFT_SAVED_SEARCH_NAME) {
      onSearchStateChange(draftSearch);
    } else {
      if (latestSavedSearchName === DRAFT_SAVED_SEARCH_NAME) {
        setDraftSearch(searchState);
      }
      const selectedSavedSearch = savedSearches[name];
      if (selectedSavedSearch) {
        onSearchStateChange(decodeSearchState(selectedSavedSearch.searchState, view));
      }
    }
    updateLatestSavedSearchName(name);
  };

  const savedSearchTitles = useMemo(() => Object.keys(savedSearches), [savedSearches]);

  return (
    <div className={styles.SavedSearches__wraper}>
      {savedSearchTitles.map((title, index) => (
        <SavedSearch
          canEdit
          key={index}
          index={index}
          title={title}
          searchName={title}
          active={latestSavedSearchName === title}
          hideOnSmallScreen={latestSavedSearchName !== title}
          onSelect={onSelect}
          onRemove={onRemove}
          onEditSearch={onUpdateSavedSearchTitle}
        />
      ))}
      {savedSearchTitles.length < MAX_LENGTH_SAVED_SEARCHES && (
        <SavedSearch
          index={savedSearchTitles.length}
          hideOnSmallScreen={savedSearchTitles.length === 0}
          searchName={DRAFT_SAVED_SEARCH_NAME}
          title={t('newSearch')}
          active={latestSavedSearchName === DRAFT_SAVED_SEARCH_NAME}
          onSelect={onSelect}
        />
      )}
      {isSmallScreen && (
        <ChooseSavedSearchKebab
          onSelect={onSelect}
          savedSearchTitles={savedSearchTitles}
          latestSavedSearchName={latestSavedSearchName}
        />
      )}
    </div>
  );
};

function ChooseSavedSearchKebab({ savedSearchTitles, latestSavedSearchName, onSelect }) {
  const actions = useMemo(() => {
    return savedSearchTitles
      .filter(title => title !== latestSavedSearchName && title !== DRAFT_SAVED_SEARCH_NAME)
      .map(title => ({
        title,
        onClick: () => onSelect(title)
      }));
  }, [
    savedSearchTitles,
    latestSavedSearchName
  ]);

  if (actions.length === 0) {
    return null;
  }

  return (
    <Actions
      variant="kebab"
      className={styles.SavedSearches__kebab}
      actions={actions}
    />
  );
}

interface SavedSearchProps {
  title: string;
  active?: boolean;
  onSelect: (name: string) => void;
  onRemove?: (name: string) => void;
  searchName: string;
  index: number;
  canEdit?: boolean;
  onEditSearch?: (data) => void;
  hideOnSmallScreen?: boolean;
}

const SavedSearch: FC<SavedSearchProps> = ({
  title,
  active,
  onSelect,
  searchName,
  canEdit,
  onRemove: onRemove_,
  onEditSearch,
  hideOnSmallScreen
}) => {
  const [showEditModal, setShowEditModal] = useState<boolean>();

  const onRemove = async () => {
    if (onRemove_) {
      await onRemove_(title);
    }
    setShowEditModal(false);
  };

  const onSubmitUpdateFilterTitle = async ({ title: newTitle }) => {
    if (onEditSearch) {
      await onEditSearch({
        previousTitle: searchName,
        title: newTitle
      });
    }
    setShowEditModal(false);
  };

  const onShowEditModal = () => {
    setShowEditModal(true);
  };

  return (
    <div
      className={classNames(styles.SavedSearches, {
        [styles['SavedSearches--active']]: active,
        [styles['SavedSearches--hideOnSmallScreen']]: hideOnSmallScreen
      })}
    >
      <Button
        variant="text"
        className={styles.SavedSearches__button}
        onClick={() => onSelect(searchName)}
      >
        {title}
      </Button>
      {canEdit && (
        <>
          <Button
            icon={EditIcon}
            aria-label="Edit saved searches"
            variant="text"
            className={styles.SavedSearches__button}
            onClick={onShowEditModal}
          />
          <EditSavedSearchModal
            onHide={setShowEditModal}
            show={showEditModal}
            title={title}
            onRemove={onRemove}
            onSubmit={onSubmitUpdateFilterTitle}
          />
        </>
      )}
    </div>
  );
};

export default SavedSearches;
