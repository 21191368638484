import React from 'react';
import PropTypes from 'prop-types';

import { Select } from 'react-responsive-ui';

import SearchInput from '../SearchInput';

import './SearchBarAndResultsPerPageAndSortBy.sass';

export default function SearchBarAndResultsPerPageAndSortBy ({
  searchQuery,
  onSearchQueryChange,
  resultsPerPageOptions,
  resultsPerPage,
  onResultsPerPageChange,
  sortByOptions,
  sortBy,
  onSortByChange
}) {
  return (
    <div className="SearchBarAndResultsPerPageAndSortBy">
      <span className="SortByAndResultsPerPage-label">
        Results Per Page
      </span>

      <Select
        value={resultsPerPage}
        onChange={onResultsPerPageChange}
        options={resultsPerPageOptions}
        className="SortByAndResultsPerPage-item"
      />

      {sortByOptions && (
        <>
          <span className="SortByAndResultsPerPage-label">
            Sort By
          </span>

          <Select
            value={sortBy}
            onChange={onSortByChange}
            options={sortByOptions}
            className="SortByAndResultsPerPage-item"
          />
        </>
      )}

      <SearchInput
        waitForIdle
        value={searchQuery}
        onChange={onSearchQueryChange}
        placeholder="Search"
        className="SortByAndResultsPerPage-item"
      />
    </div>
  );
}

SearchBarAndResultsPerPageAndSortBy.propTypes = {
  searchQuery: PropTypes.string,
  onSearchQueryChange: PropTypes.func.isRequired,
  resultsPerPageOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,
  resultsPerPage: PropTypes.number.isRequired,
  onResultsPerPageChange: PropTypes.func.isRequired,
  sortByOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })),
  sortBy: PropTypes.string,
  onSortByChange: PropTypes.func.isRequired
};
