import groupBy from 'lodash/groupBy';
import type { UserRole } from '@acadeum/types';

import { PERMISSION_OPERATIONS } from './consts';
import type { PermissionObjectType } from './types';
import { getStrongerScope } from './getStrongerScope';
import { formatSubjectName } from './formatSubjectName';

export function preparePermissionsToObjects(permissions: UserRole['permissions']): PermissionObjectType[] {
  const permissionsGroupedBySubject = groupBy(permissions, 'subject');

  return Object.values(permissionsGroupedBySubject).map<PermissionObjectType>((permissions) => {
    return permissions.reduce<PermissionObjectType>((objectPermission, permission) => {
      for (const permissionOperation of PERMISSION_OPERATIONS) {
        objectPermission[permissionOperation] = permission.operations.includes(permissionOperation)
          ? getStrongerScope(objectPermission[permissionOperation], permission.scope)
          : objectPermission[permissionOperation];
      }

      return objectPermission;
    }, {
      subject: permissions[0].subject,
      name: formatSubjectName(permissions[0].subject),
      create: null,
      delete: null,
      read: null,
      update: null
    });
  }, []);
}
