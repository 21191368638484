import Papa from 'papaparse';

import convertToJson from './convertToJson';

interface ReadCSVFileOptions {
  schema?: NonNullable<unknown>;
  includeNullValues?: boolean;
}

export default async function readCSVFile(file, {
  includeNullValues = false,
  schema
}: ReadCSVFileOptions = {}) {
  return new Promise(resolve => {
    Papa.parse(file, {
      skipEmptyLines: true,
      complete: results => {
        removeEmptyStrings(results.data);
        if (schema) {
          resolve(convertToJson(results.data, schema, { includeNullValues }));
        }
        resolve(results.data);
      }
    });
  });
}

function removeEmptyStrings(data) {
  if (!Array.isArray(data)) {
    return;
  }
  for (let i = 0; i < data.length; i++) {
    if (Array.isArray(data[i])) {
      removeEmptyStrings(data[i]);
    }

    if (data[i] === '') {
      data[i] = null;
    }
  }
}
