import React, { memo } from 'react';

import { AcceptIcon, CircleXmarkIcon } from '@acadeum/icons';

import { Text } from '../../../Text';
import { Link } from '../../../Link';
import { Tooltip } from '../../../Tooltip';

import styles from './CourseSectionApprovedAndScheduledStatus.module.scss';

export interface CourseSectionApprovedAndScheduledStatusProps {
  isScheduled: boolean;
  isStartingTooLateToRegister?: boolean;
  editScheduleUrl?: string;
  onResetSchedule?: () => void;
}

export const CourseSectionApprovedAndScheduledStatus: React.FC<CourseSectionApprovedAndScheduledStatusProps> = memo(({
  isScheduled,
  isStartingTooLateToRegister,
  editScheduleUrl,
  onResetSchedule
}) => {
  return (
    <>
      <Tooltip
        className={styles.CourseSectionApprovedAndScheduledStatus}
        content={isScheduled
          ? 'This section has been scheduled and is available for your students'
          : 'This section is not available for your students'}
      >
        {isScheduled ? (
          <div className={styles.content}>
            <AcceptIcon className="control-icon control-icon--left"/>
            <Text as="div" color="black">
              Scheduled
            </Text>
          </div>
        ) : (
          <div className={styles.content}>
            <CircleXmarkIcon className="control-icon control-icon--left"/>
            <Text as="div" color="black">
              Not Available.
            </Text>
          </div>
        )}
      </Tooltip>
      {isStartingTooLateToRegister &&
        <Text as="div" color="black">
          This course section will not be visible within your student portal because its start date is outside of 8 months of the current date.
        </Text>
      }
      {editScheduleUrl && (
        <div style={{ marginRight: 'auto' }}>
          Click
          {' '}
          <Link to={editScheduleUrl}>
            here
          </Link>
          {' '}
          to edit the course scheduling
          {onResetSchedule
            ? (
              <>
                {' '}
                or
                {' '}
                <Link
                  onClick={(event) => {
                    // Prevent hyperlink navigation.
                    event.preventDefault();
                    onResetSchedule();
                  }}
                  to={editScheduleUrl}>
                  use the default settings
                </Link>.
              </>
            )
            : '.'
          }
        </div>
      )}
    </>
  );
});
