import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useTranslate } from '@acadeum/translate';

import Form from '../Form';
import FormField from '../FormField';
import FormDescription from '../FormDescription';
import FormHeader from '../FormHeader';
import FormTitle from '../FormTitle';
import FormSubmit from '../FormSubmit';
import FormFooter from '../FormFooter';

import actions from '../../actions';

const {
  notifyError,
  requestVerifyEmail
} = actions;

export default function VerifyEmail ({
  verificationTokenExpired,
  onAfterSubmit,
  onAlreadyVerified
}) {
  const t = useTranslate('VerifyEmail');
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    try {
      await requestVerifyEmail(dispatch, values);
      onAfterSubmit();
    } catch (error) {
      if (error.data.code === 'already_verified') {
        onAlreadyVerified();
      } else {
        console.error(error);
        notifyError(t('errorVerbose', { global: true }));
      }
    }
  };

  return (
    <div className="VerifyEmail">
      <FormHeader>
        <FormTitle>
          {t('title')}
        </FormTitle>
        {verificationTokenExpired &&
          <FormDescription>
            {t('invalidTokenText')}
          </FormDescription>
        }
      </FormHeader>
      <Form onSubmit={onSubmit} className="Form--alternativeFieldLabelStyle">
        <FormField
          required
          type="email"
          name="email"
          label={t('form.email.label')}
          placeholder={t('form.email.hint')}
        />
        <FormFooter>
          <FormSubmit>
            {t('continue', { global: true })}
          </FormSubmit>
        </FormFooter>
      </Form>
    </div>
  );
}

VerifyEmail.propTypes = {
  verificationTokenExpired: PropTypes.bool,
  onAfterSubmit: PropTypes.func.isRequired,
  onAlreadyVerified: PropTypes.func.isRequired
};
